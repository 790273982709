(function () {
  "use strict";

  angular
    .module('app')
    .factory('$track', Track);

  /**
   *
   * @returns {{length: length}}
   * @constructor
   */
  function Track() {
    var R = 6371;

    if (typeof Number.prototype.toRad == 'undefined') {
      Number.prototype.toRad = function () {
        return this * Math.PI / 180;
      }
    }

    function length(points) {
      var i, d = 0;
      for (i = 0; i < points.length - 1; i++) {
        var lat1 = points[i].latitude;
        var lat2 = points[i + 1].latitude;
        var lon1 = points[i].longitude;
        var lon2 = points[i + 1].longitude;
        var dLat = (lat2 - lat1).toRad();
        var dLon = (lon2 - lon1).toRad();
        lat1 = lat1.toRad();
        lat2 = lat2.toRad();

        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
        d += Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      }
      d = 2 * R * d;
      return d;
    }

    return {
      length: length
    }
  }
})();
