(function () {
  "use strict";

  angular
    .module('app')
    .component('saveBtn', {
      bindings: {
        btnDisabled: '<',
        handler: '&',
        title: '<'
      },
      templateUrl: 'app/component/saveBtn/saveBtn.html',
      controller: function SaveBtnCtrl() {
        this.title = this.title || "action.save";
      },
      controllerAs: 'vm'
    });

})();
