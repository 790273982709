(function () {
  'use strict';

  angular.module('app.vehicles').component('carFuelReport', {
    bindings: {
      data: '<',
    },
    // templateUrl: 'app/vehicles/details/report/vehicles.report.html',
    controller: CarFuelReportCtr,
    controllerAs: 'vm',
  });

  CarFuelReportCtr.$inject = []

  function CarFuelReportCtr() {

  }
});
