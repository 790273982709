(function () {
    "use strict";
  
    angular
      .module('app')
      .component('carInfoChart', {
        bindings: {
        },
        templateUrl: 'app/component/carInfo/carInfoChart.html',
        controllerAs: 'vm',
        controller: CarInfoChartCtrl
      });
  
      CarInfoChartCtrl.$inject = ['$element', '$rootScope', 'BackendApi', 'DateUtils', 'APP_EVENTS'];
  
    function CarInfoChartCtrl($element, $rootScope, BackendApi, DateUtils, APP_EVENTS) {
      var self = this, eventCarInfo;
  
      eventCarInfo = $rootScope.$on(APP_EVENTS.car.carInfoChart, function (e, data) {
        self.rows = null;
        if (data) {
          toggleTable(data.show);
          if (self.showCarInfo) {
            self.carId = data.id;
  
            getFromMega(data.id, data.filter)
              .then(function (result) {
                self.rows = result;
              });
          }
        }
      });
  
      this.$onDestroy = function () {
        eventCarInfo();
      };

      this.formateDate = function (timestamp) {
        return moment.unix(timestamp).format('YYYY-MM-DD HH:mm')
      };

      function toggleTable (value) {
        self.showCarInfo = value;
        if (value) {
          $element[0].classList.add('car-info-container');
        } else {
          $element[0].classList.remove('car-info-container');
        }
      }

      function getFromMega (id, filter) {
        filter = filter || {};
        return BackendApi.getFromMega({
          id: id,
          from: DateUtils.toUnix(filter.from),
          to: DateUtils.toUnix(filter.to),
          onlyCnt: 0,
          onlyData: 1
        });
      }  
    }
  
  })();
  