(() => {
  angular
    .module('app.vehicles')
    .component('fuelReportViewAccordionHeader', {
      controller: Ctrl,
      controllerAs: 'ctrl',
      templateUrl: 'app/vehicles/details/lib/with-fuel-report-view/fuel-report-view/accordion-header/tpl.html',
      bindings: {
        dayDetails: '<'
      }
    });

  Ctrl.$inject = [
    'fuel-consumption-metering-settings'
  ];

  function Ctrl(fuelConsumptionMeteringSettings) {
    this.fuelConsumptionMeteringSettings = fuelConsumptionMeteringSettings;

    this.isDayWithControversialFuelConsumption = () => {
      return fuelConsumptionMeteringSettings.isDayWithControversialFuelConsumption(this.dayDetails.id);
    };

    this.onFuelConsumptionSettingsCheckboxChanged = () => {
      fuelConsumptionMeteringSettings.onSettingsForDayChanged(this.dayDetails.id);
    };

    this.hasPartialSelectedEvents = () => {
      return fuelConsumptionMeteringSettings.doesDayHavePartialSelectedEvents(this.dayDetails.id);
    };

    this.preventClick = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
  }
})();
