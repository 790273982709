(() => {
  angular
    .module('app.vehicles')
    .component('userListSubscribedToNotifications', {
      controller: Ctrl,
      controllerAs: 'ctrl',
      templateUrl:
        'app/vehicles/details/settings/notification-settings/users-subscribed-to-notifications/user-list/tpl.html',
      bindings: {
        vehicleId: '<',
        userList: '<'
      }
    });

  Ctrl.$inject = [
    'usersSubscribedToNotifications.api',
    'VehicleSettingsToast'
  ];

  function Ctrl(api, toast) {
    const self = this;

    this.isUpdatingData = false;

    this.notificationTypes = [
      {
        i18n: 'extendedNotificationSettings.noGpsConnection',
        id: 'noGpsConnection'
      },
      {
        i18n: 'extendedNotificationSettings.noGpsConnectionPl',
        id: 'noGpsConnectionPl'
      },
      {
        i18n: 'extendedNotificationSettings.noGpsCoverage',
        id: 'noGpsCoverage'
      },
      {
        i18n: 'extendedNotificationSettings.speeding',
        id: 'speeding'
      },
      {
        i18n: 'extendedNotificationSettings.movement',
        id: 'movementStarted'
      },
      {
        i18n: 'extendedNotificationSettings.parking',
        id: 'parking'
      },
      {
        i18n: 'extendedNotificationSettings.enterGeozone',
        id: 'enterGeozone'
      },
      {
        i18n: 'extendedNotificationSettings.leaveGeozone',
        id: 'leaveGeozone'
      },
      {
        i18n: 'extendedNotificationSettings.parkingInGeozone',
        id: 'parkingInGeozone'
      }
    ];

    this.checkboxes = this.userList.reduce((acc, user) => {
      acc[user.telegramUserId] = user.shouldReceiveNotifications;
      return acc;
    }, {});

    this.selectedUserPreferences = this.userList.reduce((accByTelegramUserId, user) => {
      const hasInitialSettings = Array.isArray(user.settings)
      const initialSettings = hasInitialSettings ? user.settings : [];

      accByTelegramUserId[user.telegramUserId] = this.notificationTypes.reduce((acc, obj) => {
        const id = obj.id;

        if (!hasInitialSettings) {
          acc.push(id)
          return acc
        }

        if (initialSettings.includes(id)) {
          acc.push(id)
        }

        return acc;
      }, []);
      return accByTelegramUserId;
    }, {});

    this.onUserSelectionChanged = (user) => {
      const telegramUserId = user.telegramUserId;
      const currentNotificationListStatus = this.selectedUserPreferences[telegramUserId];

      updateData(telegramUserId, this.checkboxes[telegramUserId], currentNotificationListStatus);
    };

    this.onNotificationTypeListClosed = (user) => {
      const telegramUserId = user.telegramUserId;
      const currentNotificationListStatus = this.selectedUserPreferences[telegramUserId];
      const previousNotificationListStatus = Array.isArray(user.settings) ? user.settings : []
      const hasChanges = !areEqualArrays(currentNotificationListStatus, previousNotificationListStatus);

      if (!hasChanges) {
        return;
      }

      updateData(telegramUserId, this.checkboxes[telegramUserId], currentNotificationListStatus);
    };

    function updateData(telegramUserId, shouldReceiveNotifications, settings) {
      self.isUpdatingData = true;
      api.updateUserNotificationsPreferences(self.vehicleId, telegramUserId, shouldReceiveNotifications, settings)
        .then(() => {
          const userData = self.userList.find((obj) => obj.telegramUserId === telegramUserId);
          if (!!userData) {
            userData.shouldReceiveNotifications = shouldReceiveNotifications;

            const currentNotificationListStatus = self.selectedUserPreferences[telegramUserId];
            userData.settings = JSON.parse(angular.toJson(currentNotificationListStatus));
          }

          toast.successfulSaving('notifications.successUpdatingOfUsersSubscribedToNotifications');
        })
        .catch(() => {
          self.checkboxes[telegramUserId] = !shouldReceiveNotifications;

          toast.successfulSaving('notifications.failedUpdatingOfUsersSubscribedToNotifications');
        })
        .finally(() => {
          self.isUpdatingData = false;
        });
    }

    // XXX DRY see users-subscribed-to-notifications/index.js
    function areEqualArrays(arr1, arr2) {
      if (arr1.length !== arr2.length) {
        return false;
      }

      for (let i = 0; i < arr1.length; i++) {
        if (!arr2.includes(arr1[i])) {
          return false;
        }
      }

      return true;
    }
  }
})();
