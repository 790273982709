(function () {
  "use strict";

  angular
    .module('app')
    .component('carGroupMenu', {
      bindings: {
        hasBack: '<',
        onChangeCar: '&',
        onChangeGroup: '&'
      },
      templateUrl: 'app/component/carGroupMenu/carGroupMenu.html',
      controllerAs: 'vm',
      controller: ['$timeout', 'Session', 'BackendApi', function ChangeAddressCtrl($timeout, Session, BackendApi) {
        var self = this;

        this.$onInit = function () {
          this.cars = this.allCars = angular.copy(Session.user.cars);
          this.groupId = this.groupId || Session.groupId;
          this.groups = Session.user.groups;
          if (parseInt(this.groupId)) {
            this.onSelectGroup();
          } else {
            _.isFunction(self.onChangeCar) && self.onChangeCar({cars: self.cars});
          }
        };

        this.onSelectCar = function () {
          $timeout(function () {
            _.isFunction(self.onChangeCar) && self.onChangeCar({cars: self.cars});
          }, 100);
        };

        this.onSelectGroup = function () {
          Session.groupId = this.groupId;
          this.showLoader = true;
          BackendApi.getCarByGroup({id: this.groupId})
            .then(function (result) {
              self.cars = self.allCars = result.cars;
              _.isFunction(self.onChangeCar) && self.onChangeCar({cars: self.cars});
            })
            .finally(function () {
              self.showLoader = false;
            })
          ;

          this.cars = [];
        };

        function getCarIds (cars) {
          return _.map(cars, function (el) { return el.id; });
        }
      }]
    });

})();
