(() => {
  angular
    .module('app.settings')
    .component('globalNotificationSettingsInBrowser', {
      controller: Ctrl,
      controllerAs: 'ctrl',
      templateUrl: 'app/settings/global-notification-settings/browser/tpl.html',
      bindings: {
        isEnabled: '<'
      }
    });

  Ctrl.$inject = [
    'Session',
    'VehicleSettingsToast',
    'globalNotificationSettings.api'
  ];

  function Ctrl(session, toast, api) {
    const self = this;

    this.isUpdateRequestActive = false;

    let previousIsEnabledValue = false;
    this.$onChanges = () => {
      previousIsEnabledValue = this.isEnabled;
    };

    this.onIsEnabledChanged = () => {
      saveSettings();
    };

    function saveSettings() {
      self.isUpdateRequestActive = true;

      api.saveNotificationInBrowserMode(session.user.loginName, self.isEnabled)
        .then(() => {
          previousIsEnabledValue = self.isEnabled;

          const i18nKey = self.isEnabled ?
            'settings.notificationsInBrowserEnabled' :
            'settings.notificationsInBrowserDisabled';
          toast.successfulSaving(i18nKey);
        })
        .catch(() => {
          self.isEnabled = previousIsEnabledValue;
          toast.successfulSaving('settings.failedUpdatingNotificationsInBrowserMode');
        })
        .finally(() => {
          self.isUpdateRequestActive = false;
        });
    }
  }
})();
