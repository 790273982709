(function () {
  "use strict";

  angular
    .module('app.vehicles')
    .component('vehiclesNow', {
      bindings: {
        vehicles: '<'
      },
      templateUrl: function () {
        return 'app/vehicles/details/now/vehicles.now.html';
      },
      controller: VehiclesNowCtrl,
      controllerAs: 'vm'
    })
  ;

  VehiclesNowCtrl.$inject = ['$scope', '$localStorage', 'BackendApi', 'APP_EVENTS', 'MapOsmService', 'Refresher', 'FuelService'];

  function VehiclesNowCtrl($scope, $localStorage, BackendApi, APP_EVENTS, MapOsmService, Refresher, FuelService) {
    var self = this,
      hideCol = {},
      refresher = new Refresher.create()
    ;
    $scope.$root.app.title = 'page.now';
    this.carId = self.vehicles[0].id;

    this.$onInit = function () {
      $scope.$emit(APP_EVENTS.ui.tabSelected, 'now');
      MapOsmService.onEnteredIntoNowMode();

      let shouldCenterOnPoint = true

      refresher
        .init({
          onStart: function () {
            MapOsmService.clear();
          },
          onInterval: function () {
            BackendApi.getCarNow(self.carId)
              .then(function (data) {
                self.vehicles = data;
                MapOsmService.appendMarker(self.vehicles[0]);

                if (shouldCenterOnPoint) {
                  MapOsmService.boundToPoint(self.vehicles[0].latitude, self.vehicles[0].longitude);
                  shouldCenterOnPoint = false
                }

                FuelService.setFuel(data[0]);
              });
          }
        })
        .start();
    };

    this.$onDestroy = function () {
      refresher.stop();
      MapOsmService.onExitedFromNowMode();
    };

    this.getFuel = function (vehicle) {
      if ($localStorage.combineFuel && $localStorage.combineFuel[vehicle.id]) {
        return vehicle.combinedFuel;
      }
      return vehicle.fuel;
    };

    this.getPrecision = function (gpsStatus) {
      gpsStatus = parseInt(gpsStatus, 10);
      var units = 'м.';
      var precision = gpsStatus === 1 ? 10 : gpsStatus;

      return (0.6 * precision).toFixed() + ' ' + units;
    };

    this.getPointUrl = (point) => {
      const latitude = point.latitude || point.lat;
      const longitude = point.longitude || point.lng;
      return `https://www.google.com/maps/place/${latitude},${longitude}`;
    };

  }
})();
