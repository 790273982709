(() => {
  angular
    .module('app')
    .service('routeItemUtils', routeItemUtils);

  routeItemUtils.$inject = [
    'Session',
    '$translate',
    'app/main/services/responseParserUtils'
  ];

  function routeItemUtils(Session, $translate, responseParserUtils) {
    const fuelConsumptionMeterTypes = {
      IMPULSES_METER: 1,
      FUEL_CALCULATOR: 2,
      FUEL_FLOW_SENSOR: 3
    };

    return {
      isRouteItemWithParking,
      isRouteItemWithMovement,
      isRouteItemWithNoGPS,
      isRouteItemWithControversialFuelLevel,

      getRouteTotalDistance,
      getRouteMaxSpeed,
      getRouteEngineOperationDuration,

      getRouteTotalParkingDuration,

      getRouteTotalMovementDuration,
      getRouteItemMovementDetails,

      getRouteTotalFuelUsage,
      hasFuelDrain,
      hasRefueling,

      getDistanceFromRouteItem,

      isTradingMan,

      getTypeOfFuelConsumptionMeter,

      isImpulseSensorUsed,
      isFuelCalculatorUsed,

      // ===================

      getRouteItemFuelAmount,
      getEngineOperationTime,
      hasUnchangedFuelAmount,
      hasNormalFuelConsumption,
      hasExceededDowntime,
      getFuelDrainDetails,
      isAnyItemHasMainTankFuelData,
      isAnyItemHasSecondTankFuelData,

      getFuelUsagePer100Km,
      getFuelUsageFromRouteItemWithFuelCalculator,
      getFuelUsageForRouteWithMovement,
      getFuelUsageForRouteWithParking,
      getFuelUsageDetails,
      getMaxSpeed,
      getDistance,
      getFuelLevelDetails,
      getOnWayDetails,
      getParkingDetails,
      getTemperatureDetailsStr,
      getMaxSpeedDetails,
      getMileageDetailsStr,
      getEngineOperationDetailsStr,
      getLocationDetailsStr,
      getFuelDetailsStrForUnchangedFuelAmount,
      getFuelDetailsStrForNormalFuelConsumption,
      getFuelDetailsStrForFuelDraining,
      getFuelDetailsForRefueling,
      getRefuelingDetails,
      getDrainDetails,
      toNumber,
      splitRouteDetailsByDates,
      fuelConsumptionMeterTypes
    };

    function isRouteItemWithParking(routeItem) {
      try {
        return routeItem.status === '2';
      } catch (e) {
        return false;
      }
    }

    function isRouteItemWithMovement(routeItem) {
      try {
        return routeItem.status === '3';
      } catch (e) {
        return false;
      }
    }

    function isRouteItemWithNoGPS(routeItem) {
      try {
        return routeItem.status === '1';
      } catch (e) {
        return false;
      }
    }

    function isRouteItemWithRefill(routeItem) {
      try {
        return routeItem.status === '8';
      } catch (e) {
        return false;
      }
    }

    function isRouteItemWithControversialFuelLevel(routeItem, routeDetailsSummary) {
      const idle = toNumber((routeDetailsSummary.fuelSettings || {}).idle);

      return hasFuelDrain(routeItem, {idle}) || hasRefueling(routeItem);
    }

    function getRouteTotalDistance(routeDetails, includeSummaryCalculations) {
      if (includeSummaryCalculations) {
        const result =
        routeDetails.stops
          .filter((obj) => isRouteItemWithParking(obj) || isRouteItemWithMovement(obj))
          .reduce((acc, obj) => acc + toNumber(obj.distance), 0);

        if (result > 0) {
          return result;
        }
      }

      return toNumber(routeDetails.summary.distanceKm)
        || toNumber(routeDetails.summary.calculatedDistanceKm)
        || 0;
    }

    function getRouteMaxSpeed(routeDetails) {
      return toNumber(routeDetails.summary.maxSpeedKmpH);
    }

    function getRouteEngineOperationDuration(routeDetails) {
      const result =
        routeDetails.stops
          .filter((obj) => isRouteItemWithParking(obj) || isRouteItemWithMovement(obj))
          .reduce((acc, obj) => acc + toNumber(obj.engine), 0);

      if (result > 0) {
        return result;
      }

      return toNumber(routeDetails.summary.engine);
    }

    function getRouteTotalFuelUsage(routeDetails, fuelConsumptionMeteringSettings) {
      if (routeDetails.summary.v2FuelSettings) {
        return routeDetails.summary.totalServerFuelUsage;
      }


      var totalFuelUsage = routeDetails.stops
        .filter((obj) => isRouteItemWithParking(obj) || isRouteItemWithMovement(obj))
        .reduce((acc, obj) => {
          if (isRouteItemWithParking(obj)) {
            // XXX we don't consider the changes of fuel level for events/intervals with parking
            return acc
          }

          if (isRouteItemWithMovement(obj)) {
            if (fuelConsumptionMeteringSettings.isExcludedEventWithControversialFuelLevel(obj)) {
              return acc;
            }
            return acc + -getFuelUsageForRouteWithMovement(obj, routeDetails.summary).total;
          }

          return acc;
        }, 0);

        totalFuelUsage += responseParserUtils.getFuelCorrection(routeDetails);

        return totalFuelUsage;
    }

    function isTradingMan(routeDetails) {
      return !!routeDetails.summary.isTradingMan;
    }

    function getRouteTotalParkingDuration(routeDetails) {
      return routeDetails.stops.reduce((acc, obj) => acc + getParkingDetails(obj).duration, 0);
    }

    // ==================

    function getRouteItemFuelAmount(amount) {
      return toNumber(amount);
    }

    function getEngineOperationTime(routeItem) {
      return toNumber(routeItem.engine);
    }

    /**
     * @param {Object} routeItem
     * @param {Object} params
     * @param {number} params.idle
     * @returns {boolean}
     */
    function hasFuelDrain(routeItem, params) {
      if (isRouteItemWithRefill(routeItem)) {
        return routeItem.refill < 0;
      }

      if (!isRouteItemWithParking(routeItem)) {
        return false;
      }

      const initialFuelAmountInMainTank = toNumber(routeItem.fuelFrom);
      const finalFuelAmountInMainTank = toNumber(routeItem.fuelTo);
      const initialFuelAmountInSecondTank = toNumber(routeItem.secondFuelFrom);
      const finalFuelAmountInSecondTank = toNumber(routeItem.secondFuelTo);

      if (
        initialFuelAmountInMainTank <= finalFuelAmountInMainTank
        &&
        initialFuelAmountInSecondTank <= finalFuelAmountInSecondTank
      ) {
        return false;
      }

      if (
        initialFuelAmountInMainTank > finalFuelAmountInMainTank
        ||
        initialFuelAmountInSecondTank > finalFuelAmountInSecondTank
      ) {
        return true;
      }

      const actualFuelUsage =
        (initialFuelAmountInMainTank - finalFuelAmountInMainTank)
        +
        (initialFuelAmountInSecondTank - finalFuelAmountInSecondTank);

      const expectedFuelUsageDuringStop = (params.idle / 3600) * getEngineOperationTime(routeItem);
      return actualFuelUsage > expectedFuelUsageDuringStop;
    }

    /**
     * @param {Object} routeItem
     * @param {Object} params
     * @param {number} params.idle
     * @returns {Object}
     */
    function getFuelDrainDetails(routeItem, params) {
      if (!hasFuelDrain(routeItem, params)) {
        return {
          main: 0,
          second: 0,
          total: 0
        };
      }

      const previousFuelAmountInMainTank = getRouteItemFuelAmount(routeItem.fuelFrom);
      const currentFuelAmountInMainTank = getRouteItemFuelAmount(routeItem.fuelTo);
      const previousFuelAmountInSecondTank = getRouteItemFuelAmount(routeItem.secondFuelFrom);
      const currentFuelAmountInSecondTank = getRouteItemFuelAmount(routeItem.secondFuelTo);

      const fuelDrainInMainTank = previousFuelAmountInMainTank - currentFuelAmountInMainTank;
      const fuelDrainInSecondTank = previousFuelAmountInSecondTank - currentFuelAmountInSecondTank;

      return {
        main: fuelDrainInMainTank,
        second: fuelDrainInSecondTank,
        total: fuelDrainInMainTank + fuelDrainInSecondTank
      };
    }

    function hasRefueling(rawRouteItem) {
      if (isRouteItemWithRefill(rawRouteItem)) {
        return rawRouteItem.refill > 0;
      }
      
      if (!isRouteItemWithParking(rawRouteItem)) {
        return false;
      }

      const previousFuelAmountInMainTank = getRouteItemFuelAmount(rawRouteItem.fuelFrom);
      const currentFuelAmountInMainTank = getRouteItemFuelAmount(rawRouteItem.fuelTo);
      const previousFuelAmountInSecondTank = getRouteItemFuelAmount(rawRouteItem.secondFuelFrom);
      const currentFuelAmountInSecondTank = getRouteItemFuelAmount(rawRouteItem.secondFuelTo);

      return (
        previousFuelAmountInMainTank < currentFuelAmountInMainTank
        ||
        previousFuelAmountInSecondTank < currentFuelAmountInSecondTank
      );
    }

    function hasUnchangedFuelAmount(routeItem) {
      const previousFuelAmountInMainTank = getRouteItemFuelAmount(routeItem.fuelFrom);
      const currentFuelAmountInMainTank = getRouteItemFuelAmount(routeItem.fuelTo);
      const previousFuelAmountInSecondTank = getRouteItemFuelAmount(routeItem.secondFuelFrom);
      const currentFuelAmountInSecondTank = getRouteItemFuelAmount(routeItem.secondFuelTo);

      return (
        previousFuelAmountInMainTank === currentFuelAmountInMainTank
        &&
        previousFuelAmountInSecondTank === currentFuelAmountInSecondTank
      );
    }

    /**
     * @param {Object} routeItem
     * @param {Object} params
     * @param {number} params.idle
     * @returns {boolean}
     */
    function hasNormalFuelConsumption(routeItem, params) {
      return !hasRefueling(routeItem)
        && !hasFuelDrain(routeItem, params)
        && !hasUnchangedFuelAmount(routeItem);
    }

    function hasExceededDowntime(routeItem) {
      if (!isRouteItemWithParking(routeItem)) {
        return false;
      }

      const downtime = parseFloat(routeItem.engine);
      if (isNaN(downtime)) {
        return false;
      }

      return downtime > 60;
    }

    function isAnyItemHasMainTankFuelData(routeItems) {
      return routeItems.some((obj) => {
        const initialFuelAmount = parseFloat(obj.fuelFrom);
        const endFuelAmount = parseFloat(obj.fuelTo);
        return (!isNaN(initialFuelAmount) && initialFuelAmount > 0)
          || (!isNaN(endFuelAmount) && endFuelAmount > 0);
      });
    }

    function isAnyItemHasSecondTankFuelData(routeItems) {
      return routeItems.some((obj) => {
        const initialFuelAmount = parseFloat(obj.secondFuelFrom);
        const endFuelAmount = parseFloat(obj.secondFuelTo);
        return (!isNaN(initialFuelAmount) && initialFuelAmount > 0)
          || (!isNaN(endFuelAmount) && endFuelAmount > 0);
      });
    }

    /**
     * @param {Object} routeDetails
     * @param {Object} routeDetails.summary
     * @param {*} routeDetails.summary.impulse
     * @param {Object[]} routeDetails.stops
     * @returns {string}
     */
    function getTypeOfFuelConsumptionMeter(routeDetails) {
      switch (true) {
        case isImpulseSensorUsed(routeDetails.summary):
          return fuelConsumptionMeterTypes.IMPULSES_METER;
        case isFuelCalculatorUsed(routeDetails.stops[0], routeDetails.summary):
          return fuelConsumptionMeterTypes.FUEL_CALCULATOR;
        default:
          return fuelConsumptionMeterTypes.FUEL_FLOW_SENSOR;
      }
    }

    function isImpulseSensorUsed(routeSummaryDetails) {
      return parseFloat(routeSummaryDetails.impulse) > 0;
    }

    function isFuelCalculatorUsed(routeItem, routeSummaryDetails) {
      return !isNaN(getFuelUsagePer100Km(routeItem, routeSummaryDetails));
    }

    function getFuelUsagePer100Km(routeItem, routeSummaryDetails = {}) {
      if (typeof routeSummaryDetails.fuelSettings !== 'object') {
        return NaN;
      }

      const seasonName = isSummer(moment.unix(routeItem.timestampFrom).toDate()) ? 'summer' : 'winter';

      const distance = getDistanceFromRouteItem(routeItem);
      const terrainType = distance < 10 ? 'city' : 'road';

      try {
        return parseFloat(routeSummaryDetails.fuelSettings[seasonName][terrainType]);
      } catch (e) {
        return NaN;
      }
    }

    // It's copied from legacy code.
    // @see src/app/main/services/response-parser.service.js#L735
    function isSummer(date) {
      date = date || new Date();
      var season = Session.user.settings.seasons || {"summer": "2000.04.15", "winter": "2000.10.15"};
      var winter = new Date(season.winter);
      var summer = new Date(season.summer);
      var wlts = winter < summer;
      var dlts = date < summer;
      var dltw = date < winter;
      return (!dlts && (dltw || wlts)) || (dltw && wlts);
    }

    function getDistanceFromRouteItem(routeItem) {
      return isRouteItemWithMovement(routeItem) ? toNumber(routeItem.distance) : 0;
    }

    function getFuelUsageFromRouteItemWithFuelCalculator(routeItem, routeSummaryDetails) {
      if (!isFuelCalculatorUsed(routeItem, routeSummaryDetails)) {
        return NaN;
      }

      const distance = parseFloat(routeItem.distance);
      const fuelUsagePer100Km = getFuelUsagePer100Km(routeItem, routeSummaryDetails);

      let fuelUsage = distance / 100 * fuelUsagePer100Km;
      return Math.round(fuelUsage * 100) / 100;
    }

    function getFuelUsageForRouteWithMovement(routeItem, routeSummaryDetails) {
      if (isImpulseSensorUsed(routeSummaryDetails)) {
        let fuelConsumption = routeItem.cnt / 100000000;
        if (isNaN(fuelConsumption)) {
          fuelConsumption = 0;
        }

        return {
          mainTank: fuelConsumption,
          secondTank: 0,
          total: -fuelConsumption, // minus is for UI
          isFuelCalculatorUsed: false
        };
      }

      if (isFuelCalculatorUsed(routeItem, routeSummaryDetails)) {
        const distance = parseFloat(routeItem.distance);
        const fuelUsagePer100Km = getFuelUsagePer100Km(routeItem, routeSummaryDetails);

        let totalFuelUsage = distance / 100 * fuelUsagePer100Km;
        totalFuelUsage = Math.round(totalFuelUsage * 100) / 100;

        return {
          mainTank: 0,
          secondTank: 0,
          total: -totalFuelUsage, // minus is for UI
          isFuelCalculatorUsed: true
        };
      }

      const initialFuelAmountInMainTank = getRouteItemFuelAmount(routeItem.fuelFrom);
      const finalFuelAmountInMainTank = getRouteItemFuelAmount(routeItem.fuelTo);
      const initialFuelAmountInSecondTank = getRouteItemFuelAmount(routeItem.secondFuelFrom);
      const finalFuelAmountInSecondTank = getRouteItemFuelAmount(routeItem.secondFuelTo);

      const mainTankFuelUsage = finalFuelAmountInMainTank - initialFuelAmountInMainTank;
      const secondTankFuelUsage = finalFuelAmountInSecondTank - initialFuelAmountInSecondTank;

      let totalFuelUsage = 0;
      if (mainTankFuelUsage < 0) {
        totalFuelUsage += mainTankFuelUsage;
      }
      if (secondTankFuelUsage < 0) {
        totalFuelUsage += secondTankFuelUsage;
      }

      return {
        mainTank: mainTankFuelUsage,
        secondTank: secondTankFuelUsage,
        // the commented line bellow saved to clarify calculation details
        // total: mainTankFuelUsage + secondTankFuelUsage,
        // total: totalFuelUsage,
        total: mainTankFuelUsage + secondTankFuelUsage,
        isFuelCalculatorUsed: false
      };
    }

    function getFuelUsageForRouteWithParking(routeItem) {
      const initialFuelAmountInMainTank = toNumber(routeItem.fuelFrom);
      const finalFuelAmountInMainTank = toNumber(routeItem.fuelTo);
      const initialFuelAmountInSecondTank = toNumber(routeItem.secondFuelFrom);
      const finalFuelAmountInSecondTank = toNumber(routeItem.secondFuelTo);

      const mainTankFuelUsage = finalFuelAmountInMainTank - initialFuelAmountInMainTank;
      const secondTankFuelUsage = finalFuelAmountInSecondTank - initialFuelAmountInSecondTank;

      let totalFuelUsage = 0;
      if (mainTankFuelUsage < 0) {
        totalFuelUsage += mainTankFuelUsage;
      }
      if (secondTankFuelUsage < 0) {
        totalFuelUsage += secondTankFuelUsage;
      }

      return {
        mainTank: mainTankFuelUsage,
        secondTank: secondTankFuelUsage,
        // the commented line bellow saved to clarify calculation details
        // total: routeItem.fuelUsage,
        // total: totalFuelUsage,
        total: mainTankFuelUsage + secondTankFuelUsage,
        isFuelCalculatorUsed: false
      };
    }

    function getFuelUsageDetails(currentRouteItem, previousRouteItem,
      routeSummaryDetails, isDetailedView, isExcludedRouteItemWithParking) {
      const noDataResult = {
        mainTank: 0,
        secondTank: 0,
        total: 0,
        isFuelCalculatorUsed: false
      };

      if (isDetailedView) {
        if (isRouteItemWithParking(currentRouteItem)) {
          const result = getFuelUsageForRouteWithParking(currentRouteItem);
          if (isExcludedRouteItemWithParking(currentRouteItem)) {
            result.mainTank = 0;
            result.secondTank = 0;
            result.total = 0;
          }

          return result;
        }

        if (isRouteItemWithMovement(currentRouteItem)) {
          // TODO DRY see src/app/vehicles/details/lib/fuel-calculations
          return getFuelUsageForRouteWithMovement(currentRouteItem, routeSummaryDetails);
        }

        return noDataResult;
      }

      if (
        // TODO DRY see src/app/vehicles/details/lib/fuel-calculations
        isRouteItemWithParking(currentRouteItem)
        &&
        // TODO DRY see src/app/vehicles/details/lib/fuel-calculations
        isRouteItemWithMovement(previousRouteItem)
      ) {
        // TODO DRY see src/app/vehicles/details/lib/fuel-calculations
        const fuelUsageForRouteItemWithParking = getFuelUsageForRouteWithParking(currentRouteItem);
        // TODO DRY see src/app/vehicles/details/lib/fuel-calculations
        const fuelUsageForRouteItemWithMovement =
          getFuelUsageForRouteWithMovement(previousRouteItem, routeSummaryDetails);

        let mainTank = fuelUsageForRouteItemWithMovement.mainTank;
        if (!isExcludedRouteItemWithParking(currentRouteItem)) {
          mainTank += fuelUsageForRouteItemWithParking.mainTank;
        }

        let secondTank = fuelUsageForRouteItemWithMovement.secondTank;
        if (!isExcludedRouteItemWithParking(currentRouteItem)) {
          secondTank += fuelUsageForRouteItemWithParking.secondTank;
        }

        let total = fuelUsageForRouteItemWithMovement.total;
        if (!isExcludedRouteItemWithParking(currentRouteItem)) {
          total += fuelUsageForRouteItemWithParking.total;
        }

        return {
          mainTank,
          secondTank,
          total,
          isFuelCalculatorUsed:
            fuelUsageForRouteItemWithParking.isFuelCalculatorUsed
            ||
            fuelUsageForRouteItemWithMovement.isFuelCalculatorUsed
        };
      }

      return noDataResult;
    }

    /**
     * @param {Object} currentRouteItem
     * @param {string} currentRouteItem.status
     * @param {number | string} currentRouteItem.maxSpeed
     * @param previousRouteItem
     * @param {string} previousRouteItem.status
     * @param {number | string} previousRouteItem.maxSpeed
     * @param {boolean} isDetailedView
     * @return {number}
     */
    function getMaxSpeed(currentRouteItem, previousRouteItem, isDetailedView) {
      let maxSpeed = 0;

      switch (true) {
        case isRouteItemWithMovement(currentRouteItem):
          maxSpeed = parseFloat(currentRouteItem.maxSpeed);
          break;
        case !isDetailedView && isRouteItemWithParking(currentRouteItem) && isRouteItemWithMovement(previousRouteItem):
          maxSpeed = parseFloat(previousRouteItem.maxSpeed);
          break;
      }

      return isNaN(maxSpeed) ? 0 : maxSpeed;
    }

    /**
     * @param {Object} currentRouteItem
     * @param {string} currentRouteItem.status
     * @param {number | string} currentRouteItem.distance
     * @param previousRouteItem
     * @param {string} previousRouteItem.status
     * @param {number | string} previousRouteItem.distance
     * @param {boolean} isDetailedView
     * @return {number}
     */
    function getDistance(currentRouteItem, previousRouteItem, isDetailedView) {
      let distance = 0;

      switch (true) {
        case isRouteItemWithMovement(currentRouteItem):
          distance = parseFloat(currentRouteItem.distance);
          break;
        case !isDetailedView && isRouteItemWithParking(currentRouteItem)
        && isRouteItemWithMovement(previousRouteItem):
          distance = parseFloat(previousRouteItem.distance);
          break;
      }

      return isNaN(distance) ? 0 : distance;
    }

    function getFuelLevelDetails(rawRouteItem) {
      if (!rawRouteItem) {
        return {
          initial: {
            mainTank: 0,
            secondTank: 0
          },
          final: {
            mainTank: 0,
            secondTank: 0
          }
        };
      }

      return {
        initial: {
          mainTank: toNumber(rawRouteItem.fuelFrom),
          secondTank: toNumber(rawRouteItem.secondFuelFrom)
        },
        final: {
          mainTank: toNumber(rawRouteItem.fuelTo),
          secondTank: toNumber(rawRouteItem.secondFuelTo)
        }
      };
    }

    function getRouteTotalMovementDuration(routeDetails) {
      return routeDetails.stops
        .filter(isRouteItemWithMovement)
        .reduce((acc, obj) => acc + getRouteItemMovementDetails(obj).duration, 0);
    }

    function getRouteItemMovementDetails(routeItem) {
      if (!isRouteItemWithMovement(routeItem)) {
        return {
          from: null,
          to: null,
          duration: 0
        };
      }

      const startDate = routeItemTimestampToDate(routeItem.timestampFrom);
      const endDate = routeItemTimestampToDate(routeItem.timestampTo);

      return {
        from: startDate,
        to: endDate,
        duration: Math.floor((endDate.getTime() - startDate.getTime()) / 1000)
      };
    }

    /**
     * @param {Object} currentRouteItem
     * @param {number} currentRouteItem.timestampFrom
     * @param {number} currentRouteItem.timestampTo
     * @param {Object} [previousRouteItem]
     * @param {number} previousRouteItem.timestampFrom
     * @param {number} previousRouteItem.timestampTo
     * @param {Object} params
     * @param {boolean} params.shouldUsePreviousRouteItemData
     * @returns {
     *  duration: number,
     *  from: Date | null,
     *  to: Date | null
     *  }
     */
    function getOnWayDetails(currentRouteItem, previousRouteItem, params) {
      let startDate;
      let endDate;

      if (isRouteItemWithMovement(currentRouteItem)) {
        startDate = routeItemTimestampToDate(currentRouteItem.timestampFrom);
        endDate = routeItemTimestampToDate(currentRouteItem.timestampTo);

        return {
          from: startDate,
          to: endDate,
          duration: Math.floor((endDate.getTime() - startDate.getTime()) / 1000)
        };
      }

      const shouldUsePreviousRouteItem =
        isRouteItemWithParking(currentRouteItem)
        && isRouteItemWithMovement(previousRouteItem)
        && params.shouldUsePreviousRouteItemData;

      if (shouldUsePreviousRouteItem) {
        startDate = routeItemTimestampToDate(previousRouteItem.timestampFrom);
        endDate = routeItemTimestampToDate(previousRouteItem.timestampTo);

        return {
          from: startDate,
          to: endDate,
          duration: Math.floor((endDate.getTime() - startDate.getTime()) / 1000)
        };
      }

      return {
        from: null,
        to: null,
        duration: 0
      };
    }

    /**
     * @param {Object} routeItem
     * @param {string} routeItem.status
     * @param {number} routeItem.timestampFrom
     * @param {number} routeItem.timestampTo
     * @returns {
     *  duration: number,
     *  from: null | Date,
     *  to: null | Date
     * }
     */
    function getParkingDetails(routeItem) {
      if (!isRouteItemWithParking(routeItem)) {
        return {
          from: null,
          to: null,
          duration: 0
        };
      }

      const startDate = routeItemTimestampToDate(routeItem.timestampFrom);
      const endDate = routeItemTimestampToDate(routeItem.timestampTo);

      return {
        from: startDate,
        to: endDate,
        duration: Math.floor((endDate.getTime() - startDate.getTime()) / 1000)
      };
    }

    function getTemperatureDetailsStr(routeItem) {
      const initialTemperature = parseFloat(routeItem.tFrom);
      const endTemperature = parseFloat(routeItem.tTo);

      if (isNaN(initialTemperature) || isNaN(endTemperature)) {
        return '';
      }

      const temperatureToStr = (temperature) => temperature > 0 ? `+${temperature}` : `${temperature}`;

      return `t: ${temperatureToStr(initialTemperature)} .. ${temperatureToStr(endTemperature)}`;
    }

    function routeItemTimestampToDate(timestamp) {
      return new Date(parseInt(timestamp, 10) * 1000);
    }

    function getMaxSpeedDetails(routeItem) {
      if (!isRouteItemWithMovement(routeItem)) {
        return '';
      }

      return `${routeItem.maxSpeed}${$translate.instant('global.ci.mile')}`;
    }

    function getMileageDetailsStr(routeItem) {
      if (!isRouteItemWithMovement(routeItem)) {
        return '';
      }

      return `${routeItem.distance}${$translate.instant('reports.stops.distance')}`;
    }

    /**
     * @param {Object} routeItem
     * @param {number} routeItem.engineOperationTime
     * @returns {string}
     */
    function getEngineOperationDetailsStr(routeItem) {
      const operationDurationInSeconds = routeItem.engineOperationTime;
      if (!isRouteItemWithParking(routeItem) || operationDurationInSeconds < 60) {
        return '';
      }

      return `${formatDurationInSeconds(operationDurationInSeconds)}`;
    }

    /**
     * @param data
     * @param {Object} routeItem
     * @param {Object} routeItem.addresses
     * @param {string} routeItem.addresses.google
     * @param {string} routeItem.addresses.yandex
     * @param {number} routeItem.latitude
     * @param {number} routeItem.longitude
     * @param {Object} params
     * @param {Object} params.addressProvider
     * @param {boolean} params.addressProvider.google
     * @param {boolean} params.addressProvider.yandex
     * @returns {string}
     */
    function getLocationDetailsStr(routeItem, params) {
      if (!isNaN(routeItem.latitude) && !isNaN(routeItem.longitude) && Array.isArray(Session.user.zones)) {
        const zoneName = Session.user.zones
          .filter((zone) => {
            return L.polygon(zone.coordinates).contains({
              lat: routeItem.latitude,
              lng: routeItem.longitude
            });
          })
          .map((zone) => zone.name)[0];
        if (typeof zoneName !== 'undefined') {
          return zoneName;
        }
      }

      switch (true) {
        case params.addressProvider.google:
          return routeItem.addresses.google;
        case params.addressProvider.yandex:
          return routeItem.addresses.yandex;
        default:
          return routeItem.addresses.google || routeItem.addresses.yandex;
      }
    }

    /**
     * @param {Object} routeItem
     * @param {Object} params
     * @param {boolean} params.hasTwoTanks
     * @param {boolean} params.shouldShowCombinedFuelTanks
     * @param {string} params.separatorStr
     * @returns {string}
     */
    function getFuelDetailsStrForUnchangedFuelAmount(routeItem, params) {
      const { shouldShowCombinedFuelTanks, hasTwoTanks } = params;

      const liters = $translate.instant('report.liters');

      const mainTank = routeItem.fuelLevel.final.mainTank || -routeItem.totalFuelUsage;
      const secondTank = hasTwoTanks ? routeItem.fuelLevel.final.secondTank : 0;

      let totalFuelUsage = routeItem.fuelLevel.final.mainTank || -routeItem.totalFuelUsage;

      if (shouldShowCombinedFuelTanks) {
        const total = mainTank + secondTank;
        return total > 0 ? `${ total } ${liters}` : '';
      }

      const parts = [
        `${routeItem.fuelLevel.final.mainTank || -routeItem.totalFuelUsage} ${liters}`
      ];

      if (hasTwoTanks) {
        parts.push(`${routeItem.fuelLevel.final.secondTank} ${liters}`);
        totalFuelUsage += routeItem.fuelLevel.final.secondTank;
      }

      return totalFuelUsage > 0 ? parts.join(' + ') : '';
    }

    /**
     * @param {Object} routeItem
     * @param {Object} params
     * @param {boolean} params.hasTwoTanks
     * @param {boolean} params.shouldShowCombinedFuelTanks
     * @param {string} params.separatorStr
     * @returns {string}
     */
    function getFuelDetailsStrForNormalFuelConsumption(routeItem, params) {
      const mainTankUsage =
        routeItem.fuelLevel.initial.mainTank - routeItem.fuelLevel.final.mainTank;
      const secondTankUsage =
        routeItem.fuelLevel.initial.secondTank - routeItem.fuelLevel.final.secondTank;

      const liters = $translate.instant('report.liters');

      if (!params.shouldShowCombinedFuelTanks) {
        let parts = [
          `${$translate.instant('usage')}:`,
          [
            `${mainTankUsage}${liters}`,
            [
              '(',
              [
                routeItem.fuelLevel.initial.mainTank,
                params.separatorStr,
                routeItem.fuelLevel.final.mainTank
              ].join(' '),
              ')'
            ].join('')
          ].join(' ')
        ];

        if (params.hasTwoTanks) {
          parts = parts.concat([
            '+',
            [
              `${secondTankUsage}${liters}`,
              [
                '(',
                [
                  routeItem.fuelLevel.initial.secondTank,
                  params.separatorStr,
                  routeItem.fuelLevel.final.secondTank
                ].join(' '),
                ')'
              ].join('')
            ].join(' ')
          ]);
        }

        return parts.join(' ');
      }

      const totalFuelUsage = mainTankUsage + secondTankUsage;
      const totalInitialFuelAmount =
        routeItem.fuelLevel.initial.mainTank + routeItem.fuelLevel.initial.secondTank;
      const totalEndFuelAmount =
        routeItem.fuelLevel.final.mainTank + routeItem.fuelLevel.final.secondTank;

      return [
        `${$translate.instant('usage')}:`,
        `${totalFuelUsage} ${liters}`,
        `(${totalInitialFuelAmount} ${params.separatorStr} ${totalEndFuelAmount})`
      ].join(' ');
    }

    /**
     * @param {Object} routeItem
     * @param {Object} params
     * @param {boolean} params.hasTwoTanks
     * @param {boolean} params.shouldShowCombinedFuelTanks
     * @param {string} params.separatorStr
     * @returns {string}
     */
    function getFuelDetailsStrForFuelDraining(routeItem, params) {
      const mainTankUsage =
        routeItem.fuelLevel.initial.mainTank - routeItem.fuelLevel.final.mainTank;
      const secondTankUsage =
        routeItem.fuelLevel.initial.secondTank - routeItem.fuelLevel.final.secondTank;

      const liters = $translate.instant('report.liters');

      if (!params.shouldShowCombinedFuelTanks) {
        let parts = [
          `${$translate.instant('report.fuelDrain')}-: `,
          [
            `${mainTankUsage}${liters}`,
            [
              '(',
              [
                routeItem.fuelLevel.initial.mainTank,
                params.separatorStr,
                routeItem.fuelLevel.final.mainTank
              ].join(' '),
              ')'
            ].join('')
          ].join(' ')
        ];

        if (params.hasTwoTanks) {
          parts = parts.concat([
            '+',
            [
              `${secondTankUsage}${liters}`,
              [
                '(',
                [
                  routeItem.fuelLevel.initial.secondTank,
                  params.separatorStr,
                  routeItem.fuelLevel.final.secondTank
                ].join(' '),
                ')'
              ].join('')
            ].join(' ')
          ]);
        }

        return parts.join(' ');
      }

      const totalFuelUsage = mainTankUsage + secondTankUsage;
      const totalInitialFuelAmount =
        routeItem.fuelLevel.initial.mainTank + routeItem.fuelLevel.initial.secondTank;
      const totalEndFuelAmount =
        routeItem.fuelLevel.final.mainTank + routeItem.fuelLevel.final.secondTank;

      const title = totalFuelUsage > 0 ?
        $translate.instant('report.refueling') : $translate.instant('report.fuelDrain');

      return [
        `${$translate.instant('report.fuelDrain')}:`,
        `${totalFuelUsage}${liters}`,
        `(${totalInitialFuelAmount} ${params.separatorStr} ${totalEndFuelAmount})`
      ].join(' ');
    }

    function getRefuelingDetails(routeItem, routeSummaryDetails) {
      if (
        !isRouteItemWithParking(routeItem)
        ||
        isFuelCalculatorUsed(routeItem, routeSummaryDetails)
        ||
        isImpulseSensorUsed(routeSummaryDetails)
      ) {
        return {
          mainTank: 0,
          secondTank: 0
        };
      }

      return {
        mainTank: Math.max(routeItem.fuelLevel.final.mainTank - routeItem.fuelLevel.initial.mainTank, 0),
        secondTank: Math.max(routeItem.fuelLevel.final.secondTank - routeItem.fuelLevel.initial.secondTank, 0)
      };
    }

    function getDrainDetails(routeItem, routeSummaryDetails) {
      if (
        !isRouteItemWithParking(routeItem)
        ||
        isFuelCalculatorUsed(routeItem, routeSummaryDetails)
        ||
        isImpulseSensorUsed(routeSummaryDetails)
      ) {
        return {
          mainTank: 0,
          secondTank: 0
        };
      }

      return {
        mainTank: Math.max(routeItem.fuelLevel.initial.mainTank - routeItem.fuelLevel.final.mainTank, 0),
        secondTank: Math.max(routeItem.fuelLevel.initial.secondTank - routeItem.fuelLevel.final.secondTank, 0)
      };
    }

    function getFuelDetailsForRefueling(routeItem, params) {
      if (!isRouteItemWithParking(routeItem)) {
        return '';
      }

      const mainTankRefueling = routeItem.fuelLevel.final.mainTank - routeItem.fuelLevel.initial.mainTank;
      const secondTankRefueling = routeItem.fuelLevel.final.secondTank - routeItem.fuelLevel.initial.secondTank;

      const liters = $translate.instant('report.liters');

      if (!params.shouldShowCombinedFuelTanks) {
        let parts = [
          `${$translate.instant('report.refueling')}:`,
          [
            `${mainTankRefueling}${liters}`,
            [
              '(',
              [
                routeItem.fuelLevel.initial.mainTank,
                params.separatorStr,
                routeItem.fuelLevel.final.mainTank
              ].join(' '),
              ')'
            ].join('')
          ].join(' ')
        ];

        if (params.hasTwoTanks) {
          parts = parts.concat([
            '+',
            [
              `${secondTankRefueling}${liters}`,
              [
                '(',
                [
                  routeItem.fuelLevel.initial.secondTank,
                  params.separatorStr,
                  routeItem.fuelLevel.final.secondTank
                ].join(' '),
                ')'
              ].join('')
            ].join(' ')
          ]);
        }

        return parts.join(' ');
      }

      const totalRefueling = mainTankRefueling + secondTankRefueling;
      const totalInitialFuelAmount = routeItem.fuelLevel.initial.mainTank + routeItem.fuelLevel.initial.secondTank;
      const totalFinalFuelAmount = routeItem.fuelLevel.final.mainTank + routeItem.fuelLevel.final.secondTank;

      const title = totalRefueling > 0 ?
        $translate.instant('report.refueling') : $translate.instant('report.fuelDrain');

      return [
        `${title}:`,
        `${totalRefueling}${liters}`,
        `(${totalInitialFuelAmount} ${params.separatorStr} ${totalFinalFuelAmount})`
      ].join(' ');
    }

    function formatDurationInSeconds(seconds) {
      const minutes = Math.floor(seconds / 60);
      if (minutes < 60) {
        return `${minutes} ${$translate.instant('global.minutes')}`;
      }

      const hours = `${Math.floor(minutes / 60)}`.padStart(2, '0');
      let extraMinutes = minutes - hours * 60;
      if (extraMinutes === 0) {
        return `${hours} ${$translate.instant('global.hours')}`;
      }

      extraMinutes = `${extraMinutes}`.padStart(2, '0');
      return `${hours}:${extraMinutes} ${$translate.instant('global.hours')}`;
    }

    function toNumber(v) {
      v = parseFloat(v);
      return isNaN(v) || Math.abs(v) === Infinity ? 0 : v;
    }

    function splitRouteDetailsByDates(routeDetails) {
      const setsOfEventsPerDay = [];
      const knownDays = {};
      const startDateToTimestampMap = {};

      routeDetails.stops.forEach((event) => {
        if (typeof knownDays[event.fromDate] === 'undefined') {
          knownDays[event.fromDate] = true;
          knownDays[event.fromDate] = setsOfEventsPerDay.length;
          setsOfEventsPerDay.push([]);
          startDateToTimestampMap[event.fromDate] = event.timestampFrom;
        }

        const setIndex = knownDays[event.fromDate];
        setsOfEventsPerDay[setIndex].push(event);
      });

      return setsOfEventsPerDay.map((stops, index) => {
        const distance = stops
          .filter(isRouteItemWithMovement)
          .reduce((acc, obj) => acc + toNumber(obj.distance), 0)

        return {
          stops,
          summary: routeDetails.summaries ? routeDetails.summaries[index] : routeDetails.summary
        }
      })
    }
  }
})();
