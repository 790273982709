(function () {
  "use strict";

  angular
    .module('app')
    .service('ChartService', ChartService);

  ChartService.$inject = [];

  function ChartService() {
    var self = this, chart;

    this.createCustomChart = function (bind, additionalAxis) {
      return {
        bindto: bind,
        data: {
          keys: {
            x: 'date'
          },
          type: 'line',
          empty: {
            label: {
              text: "No data"
            }
          }
        },
        axis: {
          x: {
            label: {
              position: 'outer-center'
            },
            type: 'timeseries',
            tick: {
              format: '%d.%m.%y %H:%M'
            }
          },
          y: {
            label: {
              position: 'outer-middle'
            },
            tick: {
              format: function(d) { return d.toFixed(2) }
            },
            padding: {
              top: 50,
              bottom: 0
            }
          },
          y2: additionalAxis
        },
        grid: {
          x: {
            show: true
          },
          y: {
            show: true
          }
        },
        size: {},
        padding: {
          top: 16,
          right: additionalAxis ? 50 : 30
        },
        subchart: {
          show: true,
          size: {
            height: 30
          }
        },
        zoom: {
          enabled: true
        }
      };
    };

    this.draw = function (options) {
      chart = c3.generate(options);

      return chart;
    };

    this.drawNew = function (container, options) {
      chart = Highcharts.stockChart(container, options);
    }

    this.changeZoom = function (from, to) {
      if (chart) {
        chart.xAxis[0].setExtremes(from, to);
      }
    }

    this.resize = function () {
      chart && _.isFunction(chart.resize) && chart.resize();
    }

  }

})();
