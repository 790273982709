(function () {
  'use strict';

  angular.module('app.vehicles').component('vehiclesStatistics', {
    bindings: {},
    templateUrl: 'app/vehicles/details/statistics/vehicles.statistics.html',
    controller: VehiclesStatisticsCtrl,
    controllerAs: 'vm'
  });

  VehiclesStatisticsCtrl.$inject = [
    '$scope',
    'BackendApi',
    'MapOsmService',
    'Refresher',
    'Session',
    'PeriodFilters',
    'ChartService'
  ];

  function VehiclesStatisticsCtrl(
    $scope,
    BackendApi,
    MapOsmService,
    Refresher,
    Session,
    PeriodFilters,
    ChartService
  ) {
    this.userId = Session.user.loginName;

    var self = this,
      car = { id: $scope.$parent.$ctrl.carId },
      refresher = new Refresher.create();
    $scope.$root.app.title = 'page.statistics';
    this.car = car;

    this.$onInit = function () {
      self.filter = PeriodFilters.getFilter('statistics');
      self.result = [];
    };

    this.$onDestroy = function () {
      refresher.stop();
      MapOsmService.onExitedFromNowMode();
      $element[0].querySelector('#battery-chart').innerHTML = '';
    };

    self.applyFilter = function () {
      BackendApi.getCarMegaInfo(this.car.id, self.filter).then(function (result) {
        self.result = result;
        console.log(result);
        self.drawChart();
      });
    };

    self.drawChart = function () {
      var data = [];

      for (var info of self.result) {
        if (info.timestamp) {
          data.push([+info.timestamp * 1000, info.v1]);
        }
      }

      var columns = [
        {
          name: 'Заряд',
          data: data,
          color: '#76FF03', // Базовий колір лінії
          tooltip: {
            valueSuffix: ' V'
          },
          zones: [
            {
              value: 11.98, // Для рівня заряду до 20%
              color: '#F44336'
            },
            {
              value: 12.12,
              color: '#E57373'
            },
            {
              value: 12.28,
              color: '#FFEB3B'
            },
            {
              value: 12.46,
              color: '#D4E157'
            },
            {
              value: 12.7, // Для рівня заряду до 100%
              color: '#4CAF50' // Зелений
            }
          ]
        }
      ];

      self.chart = ChartService.drawNew('battery-chart', {
        time: {
          useUTC: false
        },
        xAxis: {
          type: 'datetime',
          ordinal: false,
          title: {
            text: 'Час'
          }
        },
        yAxis: [
          {
            title: {
              text: 'Вольтаж (V)'
            },
            opposite: false
          }
        ],
        rangeSelector: {
          selected: 1
        },
        tooltip: {
          formatter: function () {
            const point = this.points[0]; // Отримуємо точку з даними
            const charge = getBatteryLevel(point.y); // Обчислюємо рівень заряду
            return `
                <b>${Highcharts.dateFormat(
                  '%Y-%m-%d %H:%M:%S',
                  point.x
                )}</b><br>
                Вольтаж: <b>${point.y.toFixed(2)} V</b><br>
                Рівень заряду: <b>${charge}%</b>
            `;
          },
          shared: true,
          xDateFormat: '%Y-%m-%d %H:%M:%S'
        },
        legend: {
          enabled: true,
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'bottom'
        },
        series: columns
      });
    };

    function getBatteryLevel(voltage) {
      if (voltage >= 12.7) {
        return 100;
      } else if (voltage >= 12.64) {
        return 95;
      } else if (voltage >= 12.58) {
        return 90;
      } else if (voltage >= 12.52) {
        return 85;
      } else if (voltage >= 12.46) {
        return 80;
      } else if (voltage >= 12.4) {
        return 75;
      } else if (voltage >= 12.36) {
        return 70;
      } else if (voltage >= 12.32) {
        return 65;
      } else if (voltage >= 12.28) {
        return 60;
      } else if (voltage >= 12.24) {
        return 55;
      } else if (voltage >= 12.2) {
        return 50;
      } else if (voltage >= 12.12) {
        return 40;
      } else if (voltage >= 12.04) {
        return 30;
      } else if (voltage >= 11.98) {
        return 20;
      } else {
        return 10;
      }
    }
  }
})();
