(() => {
  angular
    .module('app')
    .factory('app/main/services/responseParserUtils', utils);

  function utils() {
    const MAXIMUM_PERMISSIBLE_DISTANCE_ERROR_IN_KM = 50;

    return {
      isVehicleWithControversialPositionForResponseAboutCurrentStateOfVehicle,
      getVehiclePositionForResponseAboutCurrentStateOfVehicle,
      getFuelCorrection,
    };

    function isVehicleWithControversialPositionForResponseAboutCurrentStateOfVehicle(csvString = '', isGpsControlActive) {
      const parts = csvString.split(';');
      const status = parts[3];

      if (!parts.length || status !== '1') {
        return false;
      }

      // check distance only when we have this flag on
      if (!isGpsControlActive) {
        return false;
      }

      const distanceDifference = getDistanceBetweenPoints(
        getLatitude1ForResponseAboutCurrentStateOfVehicle(csvString),
        getLongitude1ForResponseAboutCurrentStateOfVehicle(csvString),
        getLatitude2ForResponseAboutCurrentStateOfVehicle(csvString),
        getLongitude2ForResponseAboutCurrentStateOfVehicle(csvString)
      );

      return distanceDifference >= MAXIMUM_PERMISSIBLE_DISTANCE_ERROR_IN_KM;
    }

    function getDistanceBetweenPoints(lat1, lon1, lat2, lon2) {
      const earthRadius = 6371;
      const degreesToRadians = degrees => degrees * (Math.PI / 180);

      lat1 = degreesToRadians(lat1);
      lon1 = degreesToRadians(lon1);
      lat2 = degreesToRadians(lat2);
      lon2 = degreesToRadians(lon2);

      const latitudeDifference = lat2 - lat1;
      const longitudeDifference = lon2 - lon1;

      const a =
        Math.pow(Math.sin(latitudeDifference / 2), 2)
        +
        Math.pow(Math.cos(lat1) * Math.cos(lat2) * Math.sin(longitudeDifference / 2), 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

      return earthRadius * c;
    }

    function getVehiclePositionForResponseAboutCurrentStateOfVehicle(csvString = '', isGpsControlActive = false) {
      const parts = csvString.split(';');
      if (!parts.length) {
        return {
          latitude: 0,
          longitude: 0
        };
      }

      if (isGpsControlActive && isVehicleWithControversialPositionForResponseAboutCurrentStateOfVehicle(csvString)) {
        return {
          latitude: getLatitude2ForResponseAboutCurrentStateOfVehicle(csvString),
          longitude: getLongitude2ForResponseAboutCurrentStateOfVehicle(csvString)
        };
      }

      return {
        latitude: getLatitude1ForResponseAboutCurrentStateOfVehicle(csvString),
        longitude: getLongitude1ForResponseAboutCurrentStateOfVehicle(csvString)
      };
    }
  }

  function getLatitude1ForResponseAboutCurrentStateOfVehicle(csvString = '') {
    const parts = csvString.split(';');
    if (!parts.length) {
      return 0;
    }
    return parseInt(parts[7], 10) / 1000000;
  }

  function getLongitude1ForResponseAboutCurrentStateOfVehicle(csvString = '') {
    const parts = csvString.split(';');
    if (!parts.length) {
      return 0;
    }
    return parseInt(parts[8], 10) / 1000000;
  }

  function getLatitude2ForResponseAboutCurrentStateOfVehicle(csvString = '') {
    const parts = csvString.split(';');
    if (!parts.length) {
      return 0;
    }
    return parseInt(parts[26], 10) / 1000000;
  }

  function getLongitude2ForResponseAboutCurrentStateOfVehicle(csvString = '') {
    const parts = csvString.split(';');
    if (!parts.length) {
      return 0;
    }
    return parseInt(parts[27], 10) / 1000000;
  }

  function getFuelCorrection(route) {
    var firstItem = route.stops.find((item) => (item.status === '2' || item.status === '3') && item.hasGPS);
    var lastItem = route.stops.findLast((item) => (item.status === '2' || item.status === '3') && item.hasGPS);

    if (firstItem &&
        lastItem &&
        route.summary.fuelStart !== undefined &&
        route.summary.fuelEnd !== undefined) {
      var stopsFuelDiff = firstItem.fuelFrom - lastItem.fuelTo;
      var serverFuelDiff = route.summary.fuelStart - route.summary.fuelEnd;
      return serverFuelDiff - stopsFuelDiff;
    }

    return 0;
  }
})();
