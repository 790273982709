(function () {
  'use strict';

  angular.module('app.vehicles').service('FuelUsageService', FuelUsageService);

  FuelUsageService.$inject = ['BackendApi', 'app.vehicles.details.lib.fuel-calculations.fuel-calculations'];

  function FuelUsageService(BackendApi, fuelCalculations) {
    var self = this;

    this.getPeriods2 = (dateFrom, dateTo, id) => {
      const requests = []

      const rangeStartDate = moment(dateFrom)
      const rangeEndDate = moment(dateTo)

      const startOfFirstDayInRange = moment(dateFrom).startOf('day')
      const endOfLastDayInRange = moment(dateTo).endOf('day')

      const countOfDays = Math.ceil(
        moment.duration(endOfLastDayInRange.diff(startOfFirstDayInRange)).asDays())

      for (let i = 0; i < countOfDays; i++) {
        let requestStartDate = moment(dateFrom).startOf('day').add(i, 'days')
        if (requestStartDate.isBefore(rangeStartDate)) {
          requestStartDate = rangeStartDate
        }

        let requestEndDate = moment(dateFrom)
          .startOf('day')
          .add(i, 'days')
          .endOf('day')
          .startOf('second')
        if (requestEndDate.isAfter(rangeEndDate)) {
          requestEndDate = rangeEndDate
        }

        requests.push(BackendApi.getStops(id, {
          from: requestStartDate.toDate(),
          to: requestEndDate.toDate()
        }))
      }

      return requests
    }

    self.getPeriods = function (dateFrom, dateTo, id) {
      var requests = [];

      var start = moment(dateFrom);
      var end = moment(dateTo).endOf('day');
      var dayDuration = moment.duration(end.diff(start)).asDays();

      for (var i = 0; i < dayDuration; i++) {
        var from = moment(dateFrom).add(i, 'days').toDate();
        var to = moment(dateFrom)
          .add(i, 'days')
          .endOf('day')
          .startOf('second')
          .toDate();
        requests.push(BackendApi.getStops(id, { from: from, to: to }));
      }

      return requests;
    };

    self.getFuel = function (datalist, list) {
      var fuels = {};

      _.each(datalist, function (item) {
        fuels[item.date] = getFuelUsage(item, list);
      });

      return fuels;
    };

    function getFuelUsage(dataItem, list) {
      var fuel = {
        totalFuelUsage: '0.0',
        fuelStart: '0.0',
        fuelEnd: '0.0',
        merged: '0.0',
        refilled: '0.0',
        averageFuelUsage: '0.0',
        distanceKm: '0.0',
        maxSpeedKmpH: '0.0',
        date: dataItem.date,
        engine: '0.0'
      };

      _.each(list, function (listItem) {
        if (!listItem.stops.length) return;

        var stopsDate = moment(listItem.stops[0].timestampFrom * 1000).format(
          'YYYY.MM.DD'
        );

        if (stopsDate === dataItem.date) {
          fuel.distanceKm = listItem.summary.distanceKm;
          fuel.maxSpeedKmpH = listItem.summary.maxSpeedKmpH;
          fuel.engine = listItem.summary.engine;

          const timePeriodDetails = fuelCalculations.getDetailsForTimePeriod(listItem);
          fuel.totalFuelUsage = Math.round(timePeriodDetails.totalFuelUsageInAllTanks * 100) / 100;
          fuel.averageFuelUsage = Math.round(timePeriodDetails.averageFuelUsage * 100) / 100;
          fuel.fuelStart = timePeriodDetails.initialFuelAmountInAllTanks;
          fuel.fuelEnd = timePeriodDetails.finalFuelAmountInAllTanks;
          fuel.totalFuelDrainInAllTanks = Math.round(timePeriodDetails.totalFuelDrainInAllTanks * 100) / 100;
          fuel.totalRefuelingInAllTanks = Math.round(timePeriodDetails.totalRefuelingInAllTanks * 100) / 100;

          /*
          if (
            _.has(listItem.summary, 'totalFuelUsage') &&
            +listItem.summary.totalFuelUsage !== 0
          ) {
            fuel.totalFuelUsage = listItem.summary.totalFuelUsage;
            fuel.averageFuelUsage = listItem.summary.averageFuelUsage;
            fuel.fuelStart = fuelForPrint ? fuelForPrint.fuelStart : 0;
            fuel.fuelEnd = fuelForPrint ? fuelForPrint.fuelEnd : 0;
            fuel.merged = fuelForPrint ? fuelForPrint.merged : 0;
            fuel.refilled = fuelForPrint ? fuelForPrint.refilled : 0;

            return;
          }

          fuel.totalFuelUsage = _.has(dataItem, 'fuel') ? dataItem.fuel : '0.0';
          */
        }
      });

      return fuel;
    }
  }
})();
