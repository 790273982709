(function () {
  "use strict";

  angular
    .module('app.logistics')
    .factory('LogisticsService', LogisticsService)
  ;

  LogisticsService.$inject = [];

  function LogisticsService() {
    var service = {};
    var routesFilterState = null;

    service.getRoutesFilterState = function () {
      return routesFilterState;
    };

    service.setRoutesFilterState = function (state) {
      routesFilterState = state;
    };

    return service;
  }
})();
