(() => {
  angular
    .module('app.vehicles')
    .directive('fuelReportViewEventRow', () => {
      return {
        restrict: 'A',
        multiElement: true,
        controller: Ctrl,
        controllerAs: 'ctrl',
        templateUrl: 'app/vehicles/details/lib/with-fuel-report-view/fuel-report-view/accordion-content/event-row/tpl.html',
        scope: {
          event: '<',
          dayDetails: '<',
          routeDetails: '<',
          index: '<',
          withRouteColor: '<',
          getRouteEventColor: '<'
        },
        bindToController: true
      };
    });

  Ctrl.$inject = [
    '$rootScope',
    'fuel-consumption-metering-settings',
    'app.vehicles.details.lib.with-fuel-report-view.fuel-report-view.accordion-content.event-row.events',
    '$scope'
  ];

  function Ctrl($rootScope, fuelConsumptionMeteringSettings, events, $scope) {
    const self = this;

    const unsubscribe = [];

    $scope.$on('$destroy', () => {
      while(unsubscribe.length) {
        unsubscribe.pop()();
      }
    })

    this.fuelConsumptionMeteringSettings = fuelConsumptionMeteringSettings;

    this.onFuelConsumptionSettingsCheckboxChanged = () => {
      fuelConsumptionMeteringSettings.onSettingsForEventChanged({
        dayId: this.dayDetails.id
      });
    };

    this.hasControversialFuelLevel =
      fuelConsumptionMeteringSettings.isEventWithControversialFuelLevel(Object.assign(
        {},
        this.event.routeItem,
        {
          fuelFrom: this.event.routeItem.fuelLevel.initial.mainTank,
          fuelTo: this.event.routeItem.fuelLevel.final.mainTank,
          secondFuelFrom: this.event.routeItem.fuelLevel.initial.secondTank,
          secondFuelTo: this.event.routeItem.fuelLevel.final.secondTank
        }
      ), this.routeDetails.summary);

    this.onParkingDurationPressed = () => {
      $rootScope.$emit(events.PARKING_DURATION_PRESSED, self.event.routeItem);
    };

    this.onMovementDurationPressed = () => {
      const toCompatibleWithLegacyCode = (routeItem) => {
        routeItem.onWayRange = {};

        if (routeItem.onWay.from === null) {
          routeItem.onWayRange.from = 0;
        } else {
          routeItem.onWayRange.from = routeItem.onWay.from.getTime();
        }

        if (routeItem.onWay.to === null) {
          routeItem.onWayRange.to = 0;
        } else {
          routeItem.onWayRange.to = routeItem.onWay.to.getTime();
        }

        return routeItem;
      };

      $rootScope.$emit(events.MOVEMENT_DURATION_PRESSED, {
        routeItem: toCompatibleWithLegacyCode(self.event.routeItem),
        index: self.index
      });
    };

    this.onInfoPressed = () => {
      $rootScope.$emit(events.INFO_PRESSED, self.event.routeItem);
    };
  }
})();
