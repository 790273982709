(function () {
  "use strict";

  angular
    .module('app')
    .service('PermissionService', PermissionService);

  PermissionService.$inject = [];

  function PermissionService() {
    var permissions = {},
      userRole = {
        0: 'isAdmin',
        1: 'isNoname',
        2: 'isUser',
        3: 'isTestUser'
      };

    this.init = function (list, loginType) {
      permissions = {};
      _.each(list, function (el) {
        permissions[toCamelCase(el)] = true;
      });

      permissions[userRole[loginType] || 'isTestUser'] = true;
    };

    this.get = function () {
      return angular.copy(permissions);
    };

    function toCamelCase (str){
      str = str || "";
      return /-/.test(str) ? str.replace(/(\-[a-z])/g, function($1){return $1.toUpperCase().replace('-','');}) : str;
    }
  }

})();
