(() => {
  angular
    .module('app')
    .directive('geoZonesWithStopsTable', geoZonesWithStopsTable);

  function geoZonesWithStopsTable() {
    return {
      templateUrl: 'app/vehicles/details/stops/geo-zones-with-stops-table/tpl.html',
      restrict: 'E',
      scope: {
        vehicleId: '<',
        data: '='
      },
      controller: Ctrl,
      controllerAs: 'ctrl'
    };
  }

  Ctrl.$inject = [
    '$scope',
    'appearanceMode'
  ];

  function Ctrl($scope, appearanceModeFacrory) {
    const vehicleId = parseInt($scope.vehicleId, 10);

    const appearanceModeInstance = appearanceModeFacrory.create(vehicleId);

    let shouldShowCombinedFuelTanks = appearanceModeInstance.shouldShowCombinedFuelTanks();
    const onFuelTanksModeChanged = () => {
      shouldShowCombinedFuelTanks = appearanceModeInstance.shouldShowCombinedFuelTanks();
    };
    appearanceModeInstance.addFuelTanksModeChangeListener(onFuelTanksModeChanged);

    this.getFuelState = (stop) => {
      const tank1InitialAmount = stop.fuelFrom || 0;
      const tank2InitialAmount = stop.secondFuelFrom || 0;

      if (shouldShowCombinedFuelTanks) {
        return (tank1InitialAmount + tank2InitialAmount) || '-';
      }

      const parts = [];
      if (tank1InitialAmount > 0) {
        parts.push(tank1InitialAmount);
      }

      if (tank2InitialAmount > 0) {
        parts.push(tank2InitialAmount);
      }

      if (parts.length === 0) {
        return '-';
      }

      return parts.join(' + ');
    };
  }
})();
