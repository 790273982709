(() => {
  angular
    .module('app')
    .service('vehiclesStatusWatcher', vehiclesStatusWatcher);

  const statusNames = {
    IS_PARKING: 'isParking',
    IS_OVER_SPEED: 'isOverSpeed'
  };

  function vehiclesStatusWatcher() {
    const watchers = {};

    return {
      update,
      stopWatching,
      onShowInfoForAllMarkersValueChanged,
      onMarkerRemoved
    };

    function update(data) {
      // XXX temporarily disabled. See https://gitlab.com/Anatoliy_Golub/trans-gps/-/issues/25#note_1961453798
      return;
      const newWatchersIds = [];

      data
        .filter(({vehicleData}) => !isNaN(parseInt(vehicleData.id, 10)))
        .forEach(({vehicleData, marker}) => {
          const {id: vehicleId} = vehicleData;

          newWatchersIds.push(vehicleId);
          let watcher = watchers[vehicleId];

          if (typeof watcher === 'undefined') {
            watcher = createWatcher();
            watchers[vehicleId] = watcher;
          }

          watcher.update({vehicleData, marker});
        });

      Object.keys(watchers)
        .map((existingWatcherKey) => parseInt(existingWatcherKey, 10))
        .filter((existingWatcherId) => !newWatchersIds.includes(existingWatcherId))
        .forEach((watcherId) => {
          const watcher = watchers[watcherId];
          watcher.destroy();
          delete watchers[watcherId];
        });
    }

    function stopWatching() {
      Object.keys(watchers)
        .forEach((watcherId) => {
          const watcher = watchers[watcherId];
          watcher.destroy();
          delete watchers[watcherId];
        });
    }

    function onShowInfoForAllMarkersValueChanged(shouldShowInfoForAllMarkers) {
      Object.values(watchers)
        .forEach((watcher) => {
          watcher.onShowInfoForAllMarkersValueChanged(shouldShowInfoForAllMarkers);
        });
    }

    function onMarkerRemoved(vehicleId) {
      const watcher = watchers[vehicleId];
      if (typeof watcher === 'undefined') {
        return;
      }

      watcher.destroy();
      delete watchers[vehicleId];
    }
  }

  function createWatcher() {
    let marker = null;
    let previousStatuses = null;
    let isInitialUpdate = true;
    const notifiers = {};
    let isInfoVisibleForAllMarkers = false;

    return {
      update,
      onShowInfoForAllMarkersValueChanged,
      destroy
    };

    function update({vehicleData, marker: newMarker}) {
      if (newMarker !== marker) {
        disposeCurrentMarker();
        marker = newMarker;
      }

      const statuses = getStatusesFromVehicleData(vehicleData);
      if (isInitialUpdate) {
        previousStatuses = statuses;
        isInitialUpdate = false;
        return;
      }

      Object.keys(statuses).forEach((statusName) => {
        if (previousStatuses[statusName] === statuses[statusName]) {
          return;
        }

        const notifier = notifiers[statusName];

        switch (true) {
          case !statuses[statusName] && typeof notifier !== 'undefined':
            notifier.destroy();
            delete notifiers[statusName];
            break;
          case statuses[statusName] && typeof notifier === 'undefined':
            notifiers[statusName] =
              createStatusNotifier(vehicleData, marker, statusName, onStatusNotifierDestroyed);
            break;
        }
      });
    }

    function onShowInfoForAllMarkersValueChanged(newIsInfoVisibleForAllMarkersValue) {
      isInfoVisibleForAllMarkers = newIsInfoVisibleForAllMarkersValue;
    }

    function destroy() {
      disposeCurrentMarker();

    }

    function getStatusesFromVehicleData(vehicleData) {
      const speed = parseInt(vehicleData.speed, 10);
      const speedLimit = Math.min(
        parseInt(vehicleData.speedLimit, 10),
        parseInt(vehicleData.speedLimit2, 10)
      );

      return {
        [statusNames.IS_PARKING]: speed === 0,
        [statusNames.IS_OVER_SPEED]: speed > speedLimit
      };
    }

    function disposeCurrentMarker() {
      if (marker === null) {
        return;
      }

      if (marker.getPopup()) {
        marker.closePopup();
        marker.unbindPopup();
      }

      marker = null;
    }

    function onStatusNotifierDestroyed(statusName) {
      delete notifiers[statusName];

      const hasOtherActiveNotifiers = Object.keys(notifiers).length > 0;
      if (hasOtherActiveNotifiers || isInfoVisibleForAllMarkers) {
        return;
      }

      disposeCurrentMarker();
    }
  }

  function createStatusNotifier(vehicleData, marker, statusName, onDestroyed) {
    const timeoutId = setTimeout(onTimeout, 3000);

    showNotification();

    return {
      destroy
    };

    function destroy() {
      clearTimeout(timeoutId);
      onDestroyed(statusName);
    }

    function onTimeout() {
      destroy();
    }

    function showNotification() {
      const vehicleName = vehicleData.short || vehicleData.name;

      if (!marker.getPopup()) {
        marker.bindPopup(vehicleName, {
          closeButton: false,
          className: 'popup',
          autoPan: false,
          autoClose: false
        });
        marker.openPopup();
      } else {
        marker.setPopupContent(vehicleName);
      }

      let toastrMessage;
      switch (statusName) {
        case statusNames.IS_PARKING:
          toastrMessage = `${vehicleName} is parked`;

          const parkingTime = parkingTimeToString(vehicleData.parkingTime || '');
          if (parkingTime !== '') {
            toastrMessage = `${toastrMessage}. The remaining time is ${parkingTime}`;
          }
          break;
        case statusNames.IS_OVER_SPEED:
          toastrMessage = `${vehicleName} is over speed`;
          break;
      }

      window.toastr.warning(toastrMessage, {
        timeOut: 3000,
        positionClass: 'toast-bottom-right'
      });
    }
  }

  function parkingTimeToString(parkingTime) {
    const parts = /^(\d+)h(\d+)min$/.exec(parkingTime);
    if (!Array.isArray(parts)) {
      return '';
    }

    const hours = parseInt(parts[1], 10);
    const minutes = parseInt(parts[2], 10);

    const resultParts = [];

    if (!isNaN(hours) && hours > 0) {
      resultParts.push(`${hours} hours`);
    }

    if (!isNaN(minutes) && minutes > 0) {
      resultParts.push(`${minutes} minutes`);
    }

    return resultParts.join(' ');
  }
})();
