(function () {
  "use strict";

  angular
    .module('app.vehicles')
    .component('vehiclesRoute', {
      bindings: {
        $transition$: '<',
        route: '<',
        history: '<',
        routeExtended: '<',
        isMultiAccaunt: '<'
      },
      templateUrl: 'app/vehicles/details/route/vehicles.route.html',
      controller: VehiclesRouteCtrl,
      controllerAs: 'vm'
    });

  VehiclesRouteCtrl.$inject = ['$scope', '$stateParams', '$log', '$localStorage', '$translate',
    'Session', 'APP_EVENTS', 'PeriodFilters', 'BackendApi', 'ResponseParser',
    'MapOsmService', 'CarService', 'Utils', '$rootScope', 'app.reports.events',
    'app.reports.exportTypes', 'app.vehicles.route.reportSaver', 'withFuelDrainSettings',
    'app.fuelReport.saver', 'app.vehicles.withFuelReportView',
    'fuel-consumption-metering-settings',
    'app.vehicles.details.lib.with-fuel-report-view.fuel-report-view.accordion-content.event-row.events',
    'withFuelReportView.events', 'appearanceMode',
    '$q', 'FuelUsageService'
  ];

  function VehiclesRouteCtrl($scope, $stateParams, $log, $localStorage, $translate,
    Session, APP_EVENTS, PeriodFilters, BackendApi, ResponseParser,
    MapOsmService, CarService, Utils, $rootScope, exportBtnEvents,
    exportTypes, reportSaver, fuelDrainSettings, fuelReportSaver, withFuelReportView,
    fuelConsumptionMeteringSettings, fuelReportViewEvents, withFuelReportViewEvents, appearanceModeFactory,
    $q, FuelUsageService) {
    var self = this,
      titleFormat = '{time}, {speed}км/ч, {stopTime}{voltage}B'
    ;
    $scope.$root.app.title = 'page.route';
    this.tmp = {
      id: $stateParams.id
    };

    const appearanceModeInstance = appearanceModeFactory.create($stateParams.id);

    this.appearanceModes = appearanceModeInstance.modes;
    this.appearanceMode = appearanceModeInstance.getAppearanceMode();
    const onAppearanceModeChanged = () => {
      self.appearanceMode = appearanceModeInstance.getAppearanceMode();
      this.onChangeAddress();
    }

    let shouldShowCombinedFuelTanks = appearanceModeInstance.shouldShowCombinedFuelTanks();
    const onFuelTanksModeChanged = () => {
      shouldShowCombinedFuelTanks = appearanceModeInstance.shouldShowCombinedFuelTanks();
    };

    this.exportMenuItems = [
      'routeGPX',
      'schedule',
      'separator',
      {
        type: exportTypes.toCSV,
        getTitle: () => {
          return $translate.instant('reports.downloadEntityAs', {
            entityName: $translate.instant('page.route'),
            fileType: 'CSV'
          });
        }
      },
      {
        type: exportTypes.toXLSX,
        getTitle: () => {
          return $translate.instant('reports.downloadEntityAs', {
            entityName: $translate.instant('page.route'),
            fileType: 'XLSX'
          });
        }
      },
      {
        type: exportTypes.toPDF,
        getTitle: () => {
          return $translate.instant('reports.downloadEntityAs', {
            entityName: $translate.instant('page.route'),
            fileType: 'PDF'
          });
        }
      },
      'separator',
      {
        type: exportTypes.fuelReportToXLSX,
        getTitle: () => {
          return $translate.instant('reports.downloadEntityAs', {
            entityName: $translate.instant('exportBtn.printFuel'),
            fileType: 'XLSX'
          });
        }
      },
      {
        type: exportTypes.fuelReportToPDF,
        getTitle: () => {
          return $translate.instant('reports.downloadEntityAs', {
            entityName: $translate.instant('exportBtn.printFuel'),
            fileType: 'PDF'
          });
        }
      },
      {
        type: exportTypes.fuelReportToPrint,
        getTitle: () => {
          return $translate.instant('reports.printAs', {
            entityName: $translate.instant('exportBtn.printFuel')
          });
        }
      },
      // 'fuelPrint'
    ];

    this.showPolilines = true;
    this.colorsFromStops = [];
    this.oneDayMarkers = '';
    this.polilinesWeight = 6;
    this.globalColors = Utils.getColors();
    this.extendedFilter = {};
    this.activeGeoZoneId = null;
    this.geoZoneHoldTime = null;
    this.controls = [];

    const unsubscribe = [];

    this.fuelDrainSettings = fuelDrainSettings;

    this.vehicleId = $stateParams.id;

    this.fuelConsumptionMeteringSettings = fuelConsumptionMeteringSettings;

    self.$onInit = function () {
      unsubscribe.push($rootScope.$on(exportBtnEvents.export, (event, params) => {
        this.onExportBtnPressed(params.type);
      }));

      unsubscribe.push($rootScope.$on(
        fuelReportViewEvents.PARKING_DURATION_PRESSED, (_, routeEvent) => {
          self.showOnStopRange(routeEvent);
        })
      );

      unsubscribe.push($rootScope.$on(
        fuelReportViewEvents.MOVEMENT_DURATION_PRESSED, (_, args) => {
          self.showOnWayRange(args.routeItem, args.index);
        })
      );

      unsubscribe.push($rootScope.$on(
        fuelReportViewEvents.INFO_PRESSED, (_, routeEvent) => {
          self.showStop(routeEvent);
        })
      );

      unsubscribe.push($rootScope.$on(
        withFuelReportViewEvents.toggleRouteVisibilityOnMap, (_, params) => {
          const item = params.item;
          const color = self.colorsFromStops[params.index];
          self.onToggleOneDayRoute(item, color);
        }
      ));

      unsubscribe.push(
        appearanceModeInstance.addAppearanceModeChangeListener(onAppearanceModeChanged)
      )

      unsubscribe.push(
        appearanceModeInstance.addFuelTanksModeChangeListener(onFuelTanksModeChanged)
      )

      fuelDrainSettings.create(self);
      withFuelReportView.create(self);
      fuelConsumptionMeteringSettings.reset();

      self.isToggleMove = false;

      self.addrType = Session.addressType;
      self.filter = PeriodFilters.getFilter('route');
      self.extendedFilter = {
        to: new Date(self.filter.to),
        from: new Date(self.filter.from)
      };
      self.carInfoControl = {};
      $scope.$emit(APP_EVENTS.ui.tabSelected, 'route');

      // XXX It's commented by intention. Now there should not be UI for changing of thickness of polyline
      // self.polilinesWeight = _.has($localStorage, 'routePolilinesWeight') ? $localStorage.routePolilinesWeight : 6;
      self.polilinesWeight = 5;

      self.showPolilines = _.has($localStorage, 'showRoutePolilines') ? $localStorage.showRoutePolilines : true;
      detectStationaryCar();
      MapOsmService.clear();
      this.onChangeAddress();
      if (self.history.points && self.history.points.length) {
        self.history.points = CarService.detectParking(self.history.points, self.route.stops);
        MapOsmService.drawMarkersSet(self.history.points, titleFormat, true);
        self.showPolilines && self.getPolilinesToRoute();
      }

      const isControlPermissionSet = JSON.parse(sessionStorage.getItem('isControlPermissionSet'));

      self.hasControlPermission = (typeof isControlPermissionSet === 'boolean') ?
        isControlPermissionSet :
        Session.user.permissions.indexOf('geo-visits') !== -1;
    };

    this.$onDestroy = function () {
      while (unsubscribe.length) {
        unsubscribe.pop()();
      }

      fuelDrainSettings.destroy();
      withFuelReportView.destroy();
      fuelConsumptionMeteringSettings.reset();

      MapOsmService.clearPolilinesGroup();
      MapOsmService.clearPolilines();
      MapOsmService.clearStartFinishMarkers();
      appearanceModeInstance.turnOffSingleZoneMode();
      clearControls();
    };

    this.onExportBtnPressed = (exportType) => {
      switch (true) {
        case [
          exportTypes.toCSV,
          exportTypes.toPDF,
          exportTypes.toXLSX
        ].includes(exportType):
          this.exportRouteReport(exportType);
          break;
        case [
          exportTypes.fuelReportToXLSX,
          exportTypes.fuelReportToPDF,
          exportTypes.fuelReportToPrint
        ].includes(exportType):
          this.exportFuelReport(exportType);
          break;
      }
    };

    this.exportRouteReport = (exportType) => {
      const currentVehicle = Session.user.cars.filter((vehicle) => `${vehicle.id}` === $stateParams.id)[0];

      const dataForReport = {
        id: $stateParams.id,
        vehicleNumber: currentVehicle.name,
        route: ResponseParser.parseSingleDeviceRoute(self.route, true, self.addrType),
        shouldShowDetailedView: this.isToggleMove,
        addressProvider: {
          google: self.addrType === 'addressGoogle',
          yandex: self.addrType === 'addressYandex'
        },
        shouldShowCombinedFuelTanks
      };

      reportSaver.save(exportType, dataForReport);
    }

    this.exportFuelReport = (exportType) => {
      const currentVehicle = Session.user.cars.filter((vehicle) => `${vehicle.id}` === $stateParams.id)[0];

      const dataForReport = {
        vehicleId: $stateParams.id,
        vehicleNumber: currentVehicle.name,
        route: ResponseParser.parseSingleDeviceRoute(self.route, true, self.addrType),
        excludedEventsWithFuelDrain: fuelConsumptionMeteringSettings.getExcludedEvents(),
        filter: self.filter
      };

      fuelReportSaver.save(exportType, dataForReport);
    };

    self.updateFilter = function (property) {
      if (property === 'dateTo') {
        self.extendedFilter.to = moment(self.filter.to).endOf("day").startOf("second").toDate();
        self.filter.to = self.extendedFilter.to;
        return;
      }
      if (property === 'timeTo') {
        self.extendedFilter.to = self.filter.to;
        return;
      }
      // self.extendedFilter.to = moment(self.filter.to).endOf("day").startOf("second").toDate();
      self.extendedFilter[property] = self.filter[property];
    };

    /**
     * @description
     * Apply filter and get results from server.
     */
    self.applyFilter = function (noRenderChart) {
      delete $localStorage.sensorChunkParts;
      bindLoader(function () {
        MapOsmService.clearPolilinesGroup();
        MapOsmService.clearPolilines();

        const toNumber = (v) => {
          const num = parseFloat(v)
          if (isNaN(num) || Math.abs(num) === Infinity) {
            return 0
          }
          return num
        }

        return $q.all(FuelUsageService.getPeriods2(self.extendedFilter.from, self.extendedFilter.to, self.tmp.id))
          .then((resp) => {
            const acc = _.clone(resp[0].summary);
            acc.distanceKm = 0;
            acc.calculatedDistanceKm = 0;
            acc.totalServerFuelUsage = 0;
            acc.maxSpeedKmpH = 0;
            acc.fuelStart = resp[0].summary.fuelStart;
            acc.fuelEnd = resp[resp.length - 1].summary.fuelEnd;

            const summary = resp
            .map((r) => r.summary)
            .reduce((prev, curr) => {
              prev.distanceKm = (toNumber(prev.distanceKm) * 10 + toNumber(curr.distanceKm) * 10) / 10;
              prev.calculatedDistanceKm = (toNumber(prev.calculatedDistanceKm) * 10 + toNumber(curr.calculatedDistanceKm) * 10) / 10;
              prev.maxSpeedKmpH = Number(prev.maxSpeedKmpH) > Number(curr.maxSpeedKmpH) ? Number(prev.maxSpeedKmpH) : Number(curr.maxSpeedKmpH);
              if (curr.v2FuelSettings) {
                prev.totalServerFuelUsage = (toNumber(prev.totalServerFuelUsage) * 10 + toNumber(curr.totalServerFuelUsage) * 10) / 10;
              }

              return prev;
            }, acc);
            self.route = {
              summary,
              stops: resp.flatMap(obj => obj.stops),
              summaries: resp.map(obj => obj.summary)
            }

            detectStationaryCar();
            self.onChangeAddress();
            noRenderChart || self.onEmitFuelChart();
            self.onEmitCarInfo();
          })
      });

      BackendApi.getHistory(this.tmp.id, self.extendedFilter)
        .then(function (result) {
          // $log.log('> history', result);
          self.history = result;
          return BackendApi.getStopsExtended(self.tmp.id, self.extendedFilter);
        }).then(function (result) {
        self.routeExtended = result;
        MapOsmService.clear();
        if (result.points && result.points.length) {
          self.history.points = CarService.detectParking(self.history.points, self.route.stops);
          MapOsmService.drawMarkersSet(self.history.points, titleFormat, true);
          self.showPolilines && self.getPolilinesToRoute();
        }
      });

      appearanceModeInstance.turnOffSingleZoneMode();
    };

    this.getPolylines = function (fromDate) {
      var dayRoute = [];
      _.each(self.routeExtended.points, function (item, i) {
        var date = moment(item.timestamp * 1000).format('DD.MM.YYYY');
        date === fromDate && dayRoute.push(item);
      });

      return self.getPolylinePoints(dayRoute);
    };

    this.onSetPolilineWeight = function (num) {
      self.polilinesWeight += num;
      $localStorage.routePolilinesWeight = self.polilinesWeight;
    };

    this.onTogglePolilinesToRoute = function () {
      MapOsmService.clearPolilinesGroup();
      self.showPolilines = !self.showPolilines;
      $localStorage.showRoutePolilines = self.showPolilines;
      self.showPolilines && self.getPolilinesToRoute();
    };

    this.onToggleOneDayRoute = function (item, color) {
      MapOsmService.clear();
      MapOsmService.clearPolilines();
      if (self.oneDayMarkers === item.fromDate) {
        self.oneDayMarkers = '';
        MapOsmService.drawMarkersSet(self.history.points, titleFormat, true);
        self.onDrawPolilinesGroup(false);

        $scope.$emit(APP_EVENTS.car.fuelChart, {
          zoomOptions: {
            from: undefined,
            to: undefined
          }
        });
      } else {
        self.oneDayMarkers = item.fromDate;

        if (self.getPolylines(item.fromDate).length === 0) {
          MapOsmService.drawMarkersSet(self.history.points, titleFormat, true);
          self.onDrawPolilinesGroup(false);

          $scope.$emit(APP_EVENTS.car.fuelChart, {
            zoomOptions: {
              from: undefined,
              to: undefined
            }
          });

          return;
        }

        MapOsmService.drawMarkersSet(self.getRoutePoints(item.fromDate), titleFormat, true);
        MapOsmService.drawPolilines(self.getPolylines(item.fromDate), color, self.polilinesWeight);

        $scope.$emit(APP_EVENTS.car.fuelChart, {
          zoomOptions: {
            from: +moment(item.fromDate, 'DD.MM.YYYY').startOf('day'),
            to: +moment(item.fromDate, 'DD.MM.YYYY').endOf('day')
          }
        });
      }
    };

    this.getRoutePoints = function (fromDate) {
      var dayRoute = [];
      _.each(self.history.points, function (item) {
        var date = moment.unix(item.timestamp).format('DD.MM.YYYY');
        if (date === fromDate) {
          dayRoute.push(item);
        }
      });

      return dayRoute;
    };

    this.getPolilinesToRoute = function () {
      self.colorsFromStops = [];
      MapOsmService.clearPolilinesGroup();
      clearControls();
      self.onDrawPolilinesGroup(true);
      self.drawPolilinesGroupWithoutGps();
    };

    this.onDrawPolilinesGroup = function (colorBox) {
      var colorIndex = colorIndexGenerator();
      var date = self.list.stops[0].fromDate,
        color = self.getColor(colorIndex);
      _.each(self.list.stops, function (item, i) {
        var points = self.getRoutePointsFromRange(item.onWayRange);

        if (date !== item.fromDate) {
          date = item.fromDate;
          color = self.getColor(colorIndex);
        }

        colorBox && self.colorsFromStops.push(color);
        MapOsmService.drawPolilinesGroup(self.getPolylinePoints(points), color, self.polilinesWeight);
      });
    };

    this.drawPolilinesGroupWithoutGps = function () {
      var routeStartPoint;
      var routeEndPoint;
      _.each(self.list.stops, function (item, i) {
        var prevItem = i && self.list.stops[i - 1];
        var points = getMovmentPoints(self.getRoutePointsFromRange(item.onWayRange));
        var prevPoints = prevItem ?
          getMovmentPoints(self.getRoutePointsFromRange(prevItem.onWayRange)) :
          [];

        if (!routeStartPoint && !points.length && prevPoints.length) {
          routeStartPoint = prevPoints[prevPoints.length - 1];
        }

        if (routeStartPoint && points.length && !prevPoints.length) {
          routeEndPoint = points[0];
        }
        
        if (routeStartPoint && routeEndPoint) {
          
         const control = MapOsmService.addRoute({
            serviceType: 'trip',
            points: [
              self.getPointByStop(routeStartPoint, true),
              self.getPointByStop(routeEndPoint, false),
            ],
            color: self.colorsFromStops[i],
            shouldDrawMarkers: false,
            fitSelectedRoutes: false,
            
          });
          self.controls.push(control);

          routeStartPoint = undefined;
          routeEndPoint = undefined;
        }
      });
    };

    this.getPointByStop = (item) => {
      return {
        lat: item.latitude,
        lng: item.longitude,
      }
    }

    this.getRoutePointsFromRange = function (range) {
      return _.filter(self.routeExtended.points, function (point) {
        var timestamp = +point.timestamp * 1000;

        return (timestamp >= range.from) && (timestamp <= range.to);
      });
    };

    this.getColor = function (colorIndex) {
      var index = colorIndex.next().value;
      return this.globalColors[index] || Utils.getRandomeColor();
    };

    this.getPolylinePoints = function (stops) {
      var polylines = [];

      _.each(stops, function (stop) {
        var hasCoords = stop.latitude && stop.longitude;
        hasCoords && polylines.push([stop.latitude, stop.longitude]);
      });

      return polylines;
    };

    // Change color to opposite
    this.getReverseColor = function (color) {
      if (!color) {
        return 'transparent';
      }

      var hex = color.substring(1);
      return '#' + (Number('0x1' + hex) ^ 0xFFFFFF).toString(16).substr(1).toUpperCase();
    };

    this.replacePoints = function (newPoints, onWayRange) {
      var firstIndex,
        lastIndex;

      _.each(self.history.points, function (point, i) {
        if (+point.timestamp * 1000 <= onWayRange.from) {
          firstIndex = i;
        }

        if (+point.timestamp * 1000 <= onWayRange.to) {
          lastIndex = i;
        }
      });

      self.history.points.splice(firstIndex, lastIndex, newPoints);
    };

    this.showOnWayRange = function (item, index) {
      if (activateItem(item)) {
        bindLoader(function () {
          return BackendApi.getHistory(self.tmp.id, item.onWayRange)
            .then(function (result) {
              MapOsmService.clear();
              if (result.points && result.points.length) {
                self.history.points = CarService.detectParking(self.history.points, self.route.stops);
                self.replacePoints(result.points, item.onWayRange);
                MapOsmService.drawMarkersSet(result.points, titleFormat, true);
                var points = self.getRoutePointsFromRange(item.onWayRange);
                self.showPolilines && self.getPolilinesToRoute();
                MapOsmService.drawPolilines(
                  self.getPolylinePoints(points),
                  self.getReverseColor(self.colorsFromStops[index]),
                  self.polilinesWeight
                );
              }
            });
        });
        self.onEmitFuelChart({
          filter: item.onWayRange, route: {
            stops: self.route.stops,
            summary: {
              fuelSettings: self.route.summary.fuelSettings,
              distanceKm: item.distance,
              maxSpeedKmpH: item.maxSpeed,
              engine: item.engine
            }
          }
        });
        self.onEmitCarInfo(item.onWayRange);
      } else {
        this.applyFilter(true);
        self.onEmitFuelChart();
      }
    };

    this.showOnStopRange = function (item) {
      if (activateItem(item)) {
        self.onEmitFuelChart({
          filter: {from: +(item.timestampFrom + '000'), to: +(item.timestampTo + '000')},
          route: {
            stops: self.route.stops,
            summary: {
              fuelSettings: self.route.summary.fuelSettings,
              distanceKm: item.distance,
              maxSpeedKmpH: item.maxSpeed,
              engine: item.engine
            }
          }
        });
        MapOsmService.clear();
        MapOsmService.showStop(item.latitude, item.longitude);
        //self.onEmitCarInfo({from: +(item.timestampFrom+'000'), to: +(item.timestampTo+'000')});
      } else {
        this.applyFilter(true);
        self.onEmitFuelChart();
      }
    };

    this.showStop = function (item) {
      if ('2' == item.status) {
        if (activateItem(item)) {
          MapOsmService.clear();
          MapOsmService.showStop(item.latitude, item.longitude);
        } else {
          this.applyFilter(true);
        }
      }
    };

    this.stripSeconds = function (time) {
      return time.slice(0, -3);
    };

    this.stripYear = function (time) {
      return time.slice(0, -5);
    };

    this.onToggleMove = function () {
      this.isToggleMove = !this.isToggleMove;
      this.onChangeAddress();
      self.showPolilines && self.getPolilinesToRoute();
    };

    this.onEmitFuelChart = function (options) {
      options = options || {};
      $scope.$emit(APP_EVENTS.car.fuelChart, _.extend({
        id: self.tmp.id,
        filter: self.extendedFilter,
        route: self.route,
        showDay: true
      }, options));
    };

    this.onEmitCarInfo = function (filter) {
      if (this.carInfoControl.updateCarInfo) {
        this.carInfoControl.updateCarInfo(filter);
      }
    };

    this.onToggleChart = function (isShow) {
      isShow && delete $localStorage.sensorChunkParts;
      this.onEmitFuelChart({show: isShow});
    };

    this.hasDatesWithFuelDrain = false;

    this.onChangeAddress = function (type) {
      const list = ResponseParser.parseSingleDeviceRoute(this.route, this.isToggleMove, type || this.addrType);

      fuelDrainSettings.onRouteDetailsChanged(list);
      fuelConsumptionMeteringSettings.setRouteDetails(list);

      this.list = list;

      if (appearanceModeInstance.getAppearanceMode() === appearanceModeInstance.modes.ALL_GEO_ZONES) {
        const allGeoZones = (Session.user.zones || [])
          .reduce((acc, {id: zoneId, name: zoneName, coordinates: zoneCoordinates}) => {
            acc[zoneId] = {
              id: zoneId,
              name: zoneName,
              polygon: L.polygon(zoneCoordinates)
            };
            return acc;
          }, {});

        let geoZonesWithStops = {};

        this.list.stops
          .filter((stop) => !isNaN(stop.latitude) && !isNaN(stop.longitude))
          .forEach((stop) => {
            Object.values(allGeoZones)
              .filter((geoZone) => geoZone
                .polygon.contains({
                  lat: stop.latitude,
                  lng: stop.longitude
                }))
              .forEach((geoZone) => {
                if (typeof geoZonesWithStops[geoZone.id] === 'undefined') {
                  geoZonesWithStops[geoZone.id] = {
                    id: geoZone.id,
                    name: geoZone.name,
                    stops: []
                  };
                }

                geoZonesWithStops[geoZone.id].stops.push(stop);
              });
          });

        this.list.geoZonesWithStops = Object.values(geoZonesWithStops)
          .map((geoZone) => {
            let totalDuration = 0;

            geoZone.stops = geoZone.stops.map((stop) => {
              stop.duration = secondsToTimeStr(stop.timestampTo - stop.timestampFrom);
              stop.from = timestampToTimeStr(stop.timestampFrom * 1000);
              stop.to = timestampToTimeStr(stop.timestampTo * 1000);
              totalDuration += stop.timestampTo - stop.timestampFrom;
              return stop;
            });

            geoZone.totalDuration = secondsToTimeStr(totalDuration);

            return geoZone;
          });

        fuelConsumptionMeteringSettings.setRouteDetails(this.list);

        function timestampToTimeStr(timestamp) {
          const date = new Date(timestamp);

          const minutes = `${date.getMinutes()}`.padStart(2, '0');
          const seconds = `${date.getSeconds()}`.padStart(2, '0');

          return [
            date.getHours(),
            minutes,
            seconds
          ].join(':');
        }

        function secondsToTimeStr(seconds) {
          const hours = Math.floor(seconds / 3600);
          const minutes = `${Math.floor((seconds - hours * 3600) / 60)}`.padStart(2, '0');
          return `${hours}:${minutes}`;
        }
      }

      this.list = list;
    };

    this.onSetFilter = function (type) {
      this.extendedFilter.to = moment(this.extendedFilter.to).endOf("day").toDate();
      var filter = CarService.setFormFilter(this.extendedFilter, type);
      this.extendedFilter.from = filter.from;
      this.extendedFilter.to = filter.to;
      this.filter.from = filter.from;
      this.filter.to = filter.to;
      this.applyFilter();
    };

    this.getInfo = function (item) {
      return shouldShowCombinedFuelTanks ? item.combinedFuelTankInfo : item.info;
    };

    this.filterByGeo = function (geo) {
      this.activeGeoZoneId = this.activeGeoZoneId === geo.id ? null : geo.id;

      const result = moment.duration();

      this.list.stops.filter(this.isItemVisible).forEach(function (item) {
        result.add(moment.duration(item.stopTime));
      });
      this.geoZoneHoldTime = result.hours() + ':' + result.minutes();
    };

    this.isItemVisible = function (item) {
      if (!self.activeGeoZoneId) {
        return true;
      }

      return item.geo && item.geo.id === self.activeGeoZoneId;
    };

    function * colorIndexGenerator() {
      var index = 0;

      while (index < self.globalColors.length) {
        yield index++;
      }
    }

    function activateItem(item) {
      if (item != self.tmp.item) {
        //previous item
        if (self.tmp.item) {
          self.tmp.item.isActive = false;
        }

        item.isActive = true;
        self.tmp.item = item;
      } else {
        item.isActive = false;
        self.tmp.item = null;
      }
      return item.isActive;
    }

    function detectStationaryCar() {
      if (self.route.summary && self.route.summary && self.route.summary.fuelSettings && !!self.route.summary.impulse) {
        $scope.$emit(APP_EVENTS.car.fuelBtn, {show: true});
      }
    }

    function bindLoader(func) {
      self.showLoader = true;
      func()
        .finally(function () {
          self.showLoader = false;
        });
    }

    function clearControls() {
      _.each(self.controls, (control) => {
        MapOsmService.removeControl(control);
      });
    }

    function getMovmentPoints(points) {
      return points.filter((point) => point.speed > 0);
    }

  }
})();
