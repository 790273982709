(() => {
  angular
    .module('app.tokens')
    .component('tokenName', {
      templateUrl: 'app/tokens/token-name/tpl.html',
      controller: Ctrl,
      controllerAs: 'ctrl',
      bindings: {
        value: '<',
        onChange: '&'
      },
      bindToController: true
    });

  Ctrl.$inject = [
    '$element',
    '$timeout',
    'app.tokens.utils'
  ];

  let idCounter = 0
  const attrName = 'data-token-name-id'

  function Ctrl($el, $timeout, utils) {
    const self = this;

    const el = $el[0];
    const id = idCounter++
    el.setAttribute(attrName, id)

    let initialValue;
    let shouldSkipFirstClick = false;

    this.isEditMode = false;

    this.$onChanges = () => {
      initialValue = this.value;
    }

    this.$onDestroy = () => {
      document.removeEventListener('click', onDocumentPressed);
    };

    this.onStaticTextPressed = () => {
      enterEditMode();
    };

    this.onSaveChangesBtnPressed = () => {
      initialValue = self.value
      self.onChange({
        newName: self.value
      })
      exitEditMode()
    }

    this.onCancelChangesBtnPressed = () => {
      self.value = initialValue;
      exitEditMode();
    }

    function enterEditMode() {
      if (self.isEditMode) {
        return;
      }

      self.isEditMode = true;
      shouldSkipFirstClick = true;
      document.addEventListener('click', onDocumentPressed);
      $timeout(() => {
        $el[0].querySelector('input').focus()
      })
    }

    function exitEditMode() {
      self.isEditMode = false;
      shouldSkipFirstClick = false;
      document.removeEventListener('click', onDocumentPressed);
      utils.syncChangesWithAngular()
    }

    function onDocumentPressed(event) {
      if (shouldSkipFirstClick) {
        shouldSkipFirstClick = false;
        return;
      }

      const isClickOutsideElement = event.target.closest(`[${attrName}="${id}"]`) === null;
      if (isClickOutsideElement) {
        onClickOutside();
      }
    }

    function onClickOutside() {
      self.value = initialValue;
      exitEditMode();
    }
  }
})();
