(function () {
  "use strict";

  angular
    .module('app.logistics')
    .component('logisticsRoutes', {
      bindings: {
        routes: '<',
        cars: '<'
      },
      templateUrl: 'app/logistics/routes/logistics.routes.html',
      controller: LogisticsRoutesCtrl,
      controllerAs: 'vm'
    });

  LogisticsRoutesCtrl.$inject = ['Session', 'LogisticsService', 'SettingsService', 'BackendApi', '$q', '$state', 'MapOsmService'];

  function LogisticsRoutesCtrl(Session, LogisticsService, SettingsService, BackendApi, $q, $state, MapOsmService) {

    var vm = this;

    vm.$onInit = function () {
      MapOsmService.setView();

      vm.showLoader = false;
      vm.dateNow = Math.floor(Date.now() /1000);
      vm.filteredRoutes = [];
      vm.currentDate = moment();
      vm.startDate = null;
      vm.endDate = null;
      vm.startDateString = '';
      vm.endDateString = '';
      vm.periods = {
        day: 'day',
        week: 'week',
        month: 'month',
        all: 'all'
      };
      normalizeRoutes();

      var navigatorState = LogisticsService.getRoutesFilterState();
      if (navigatorState) {
        vm.period = navigatorState.period;
        vm.selectedCarId = navigatorState.selectedCarId;
        vm.startDate = navigatorState.startDate;
        vm.endDate = navigatorState.endDate;
        onPeriodChanged();
      } else {
        vm.period = vm.periods.day;
        vm.selectedCarId = null;
        updatePeriodRange();
      }
    };

    vm.goBack = function () {
      // regular state.go is not worning on mobile devices
      window.location.assign("/vehicles");
    };

    vm.createRoute = function () {
      vm.selectedPage = vm.logisticsPages.createRoute;
    };

    vm.openRoute = function (route) {
      $state.go('logistics.viewRoute', {id: route.id});
    };

    vm.editRoute = function (route) {
      $state.go('logistics.editRoute', {id: route.id});
    };

    vm.cloneRoute = function (route) {
      $state.go('logistics.editRoute', {id: route.id, clone: true});
    };

    vm.deleteRoute = function (ev, route) {
      SettingsService.showConfirmDialog(ev, {title: 'Удаление маршрута', content: 'Вы уверены что хотите удалить ' + route.title + ' ' + route.formattedDateTime + '?'}).then(function () {
        vm.showLoader = true;
        BackendApi.deleteRoute(route).then(function () {
          vm.showLoader = false;
          $state.reload();
        });
      });
    };

    vm.selectCar = function (car) {
      if (vm.selectedCarId === +car.id) {
        vm.selectedCarId = null;
      } else {
        vm.selectedCarId = +car.id;
      }
      filterRoutes();
      updateFilterState();
    };

    vm.setPeriod = function (period) {
      vm.period = period;
      updatePeriodRange();
    };

    vm.movePeriodForward = function () {
      if (vm.period !== vm.periods.all) {
        vm.startDate.add(1, vm.period).startOf(vm.period);
        vm.endDate.add(1, vm.period).endOf(vm.period);
      }
      onPeriodChanged();
    };

    vm.movePeriodBack = function () {
      if (vm.period !== vm.periods.all) {
        vm.startDate.subtract(1, vm.period).startOf(vm.period);
        vm.endDate.subtract(1, vm.period).endOf(vm.period);
      }
      onPeriodChanged();
    };

    vm.isPeriodSelected = function (period) {
      return vm.period === period;
    };

    function updatePeriodRange() {
      if (vm.period === vm.periods.all) {
        vm.startDate = 0;
        vm.endDate = Number.MAX_VALUE;
      } else {
        vm.startDate = vm.currentDate.clone().startOf(vm.period);
        vm.endDate = vm.currentDate.clone().endOf(vm.period);
      }
      onPeriodChanged();
    }

    function onPeriodChanged() {
      filterRoutes();
      updatePeriodStrings();
      updateFilterState();
    }

    function updatePeriodStrings() {
      if (vm.period !== vm.periods.all) {
        vm.startDateString = vm.startDate.format('DD.MM');
        vm.endDateString = vm.endDate.format('DD.MM');
      }
    }

    function filterRoutes() {
      var routes;

      if (vm.selectedCarId) {
        routes = _.filter(vm.routes, function (route) {
          return route.carId === vm.selectedCarId;
        });
      } else {
        routes = vm.routes;
      }

      if (vm.period === vm.periods.all) {
        vm.filteredRoutes = routes;
      } else {
        var startDateTimestamp = vm.startDate.unix(),
          endDateTimestamp = vm.endDate.unix();

        vm.filteredRoutes = _.filter(routes, function (route) {
          return route.dateTime >= startDateTimestamp && route.dateTime <= endDateTimestamp;
        });
      }
    }

    function normalizeRoutes() {
      vm.routes = _.sortBy(vm.routes, 'dateTime');
      _.each(vm.routes, function (route) {
        route.formattedDateTime = moment.unix(route.dateTime).format('DD.MM.YYYY HH:mm');
        route.car = _.find(vm.cars, function (car) {
          return route.carId === +car.id;
        });
      });
    }

    function updateFilterState() {
      LogisticsService.setRoutesFilterState({
        period: vm.period,
        startDate: vm.startDate,
        endDate: vm.endDate,
        selectedCarId: vm.selectedCarId
      });
    }

  }

})();
