(() => {
  angular
    .module('app.vehicles')
    .component('fuelReportView', {
      controller: Ctrl,
      controllerAs: 'ctrl',
      templateUrl: 'app/vehicles/details/lib/with-fuel-report-view/fuel-report-view/tpl.html',
      bindings: {
        routeDetails: '<',
        startDate: '<',
        endDate: '<',
        vehicleId: '<',
        shouldShowDetailedInfo: '<',
        withRouteColor: '<',
        getRouteEventColor: '<'
      }
    });

  Ctrl.$inject = [
    '$rootScope',
    '$q',
    'Session',
    'app.vehicles.details.lib.api',
    'app.vehicles.details.lib.with-fuel-report-view.fuel-report-view.data-builder',
    'withFuelReportView.events',
    'appearanceMode',
    '$stateParams'
  ];

  function Ctrl($rootScope, $q, Session, api, dataBuilder, withFuelReportViewEvents,
    appearanceMode, $stateParams) {
    const self = this;
    const unsubscribe = [];
    let lastResponse;

    let paramsThatLeadToDataReloading = {
      initialDate: null,
      finalDate: null,
      vehicleId: null
    };

    const appearanceModeInstance = appearanceMode.create($stateParams.id);

    this.$onInit = () => {
      unsubscribe.push(
        $rootScope.$on('main.langChanged', onLangChanged)
      );

      unsubscribe.push(
        $rootScope.$on('main.addressProviderChanged', onAddressProviderChanged)
      );

      unsubscribe.push(
        appearanceModeInstance.addFuelTanksModeChangeListener(updateDataFromRouteDetails)
      );
    };

    this.$onDestroy = () => {
      while (unsubscribe.length) {
        unsubscribe.pop()();
      }
    };

    this.$onChanges = () => {
      updateDataFromRouteDetails();
    };

    this.getDayRouteDetails = (dayDetails) => {
      return {
        summary: self.routeDetails.summary,
        stops: self.routeDetails.stops.filter((obj) => obj.fromDate === dayDetails.date)
      };
    };

    this.onDayExpanded = (index) => {
      const itemDetails = getRawRouteItemDetailsByTimestampFrom(self.data[index].events[0].routeItem.timestampFrom);
      $rootScope.$emit(withFuelReportViewEvents.toggleRouteVisibilityOnMap, itemDetails);
    };

    this.onDayCollapsed = (index) => {
      const itemDetails = getRawRouteItemDetailsByTimestampFrom(self.data[index].events[0].routeItem.timestampFrom);
      $rootScope.$emit(withFuelReportViewEvents.toggleRouteVisibilityOnMap, itemDetails);
    };

    function getRawRouteItemDetailsByTimestampFrom (timestampFrom) {
      for (let i = 0; i < self.routeDetails.stops.length; i++) {
        const item = self.routeDetails.stops[i];
        if (item.timestampFrom === timestampFrom) {
          return {
            item,
            index: i
          };
        }
      }

      return {
        item: null,
        index: -1
      };
    }

    function onLangChanged() {
      if (!self.isReady) {
        return;
      }
      updateDataFromRouteDetails();
    }

    function onAddressProviderChanged() {
      if (!self.isReady) {
        return;
      }
      updateDataFromRouteDetails();
    }

    function updateDataFromRouteDetails() {
      self.isReady = true;

      self.data = dataBuilder.processRouteDetails(self.routeDetails, {
        addressProvider: Session.addressType,
        shouldShowDetailedView: self.shouldShowDetailedInfo,
        shouldShowCombinedFuelTanks: appearanceModeInstance.shouldShowCombinedFuelTanks()
      });
    }

    function updateData() {
      if (!areParamsThatLeadToDataReloadingChanged()) {
        if (self.isReady) {
          self.data = getDataFromResponse(lastResponse);
        }
        return;
      }

      cacheParamsThatLeadToDataReloading();

      self.isLoading = true;
      self.isError = false;
      self.isReady = false;
      self.data = [];

      $q.when(
        api.getEventsForInterval({
          initialDate: self.startDate,
          finalDate: self.endDate,
          vehicleId: self.vehicleId
        }),
        (res) => {
          self.isLoading = false;
          self.isError = false;
          self.isReady = true;
          lastResponse = res;
          self.data = getDataFromResponse(res);
        }, () => {
          self.isLoading = false;
          self.isError = true;
          self.isReady = false;
        }
      );
    }

    function getDataFromResponse(response) {
      return dataBuilder.processResponse(response, {
        addressProvider: Session.addressType,
        shouldShowDetailedView: self.shouldShowDetailedInfo
      });
    }

    function cacheParamsThatLeadToDataReloading() {
      paramsThatLeadToDataReloading = {
        initialDate: self.startDate,
        finalDate: self.endDate,
        vehicleId: self.vehicleId
      };
    }

    function areParamsThatLeadToDataReloadingChanged() {
      const newParams = {
        initialDate: self.startDate,
        finalDate: self.endDate,
        vehicleId: self.vehicleId
      };

      return Object.keys(paramsThatLeadToDataReloading)
        .some((key) => newParams[key] !== paramsThatLeadToDataReloading[key]);
    }
  }
})();
