(function () {
  "use strict";

  angular
    .module('app.settings')
    .component('settingsUsers', {
      bindings: {
        users: '<'
      },
      templateUrl: 'app/settings/users/settings.users.html',
      controller: SettingsUsersCtrl,
      controllerAs: 'vm'
    });

  SettingsUsersCtrl.$inject = ['$mdToast', 'BackendApi', 'Session', 'SettingsService', '$filter'];

  function SettingsUsersCtrl($mdToast, BackendApi, Session, SettingsService, $filter) {
    var vm = this;

    vm.$onInit = function () {
      vm.getUserData();

      vm.currentUserName = Session.user.loginName;
      const isControlPermissionSet = JSON.parse(sessionStorage.getItem('isControlPermissionSet'));
      vm.isControlPermissionSet = (typeof isControlPermissionSet === 'boolean') ? isControlPermissionSet : true;
    };

    vm.setControlPermForCurrentUser = function () {
      sessionStorage.setItem('isControlPermissionSet', JSON.stringify(vm.isControlPermissionSet));
    }

    vm.getUserData = function () {
      _.forEach(vm.users, function (item) {
        item.permissions = {
          'beBlocked': false,
          'fuel-calc': false,
          'geo-visits': false,
          'geo-zones': false,
          'logistics': false,
          'reports': false,
          'schedules': false,
          'salesman': false
        };

        BackendApi.getUserPermissions(item).then(function (resp) {
          item.permissions.beBlocked = _.contains(resp, 'beBlocked');
          item.permissions['fuel-calc'] = _.contains(resp, 'fuel-calc');
          item.permissions['geo-visits'] = _.contains(resp, 'geo-visits');
          item.permissions['geo-zones'] = _.contains(resp, 'geo-zones');
          item.permissions.logistics = _.contains(resp, 'logistics');
          item.permissions.reports = _.contains(resp, 'reports');
          item.permissions.schedules = _.contains(resp, 'schedules');
          item.permissions.salesman = _.contains(resp, 'salesman');
        });
      });
    };

    vm.onEdit = function (item) {
      SettingsService.showUserEditDialog(item);
    };

    vm.onBlockUser = function (user) {
      var blocked = user.permissions.beBlocked;

      if (blocked) {
        user.permissions.reports = false;
      }

      BackendApi.setUserPermissions({name: user.name, permissions: user.permissions}).then(function () {
        BackendApi.blockUser({isBlocked: blocked, id: user.name}).then(showToastSuccess, function () {
          user.permissions.beBlocked = !blocked;
        });
      });
    };

    vm.onSavePermissions = function (user) {
      BackendApi.setUserPermissions({name: user.name, permissions: user.permissions}).then(showToastSuccess);
    };

    function showToastSuccess() {
      BackendApi.getProfile();
      var toast = $mdToast.simple().content($filter('translate')('global.savedSuccess')).position('bottom left');
      $mdToast.show(toast);
    }
  }

})();
