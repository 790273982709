(() => {
  angular
    .module('app.vehicles.details.lib.period-report-builder')
    .service('app.vehicles.details.lib.period-report-builder', reportBuilder);

  reportBuilder.$inject = [
    'routeItemUtils',
    'app.vehicles.details.lib.fuel-calculations.fuel-calculations'
  ];

  function reportBuilder(routeItemUtils, fuelCalculations) {
    return {
      create
    };

    function create(data) {
      const intervals = data.route.stops
        .slice()
        .sort((a, b) => a.timestampFrom - b.timestampFrom)
        .filter((obj) =>
          routeItemUtils.isRouteItemWithNoGPS(obj)
          ||
          routeItemUtils.isRouteItemWithParking(obj)
          ||
          routeItemUtils.isRouteItemWithMovement(obj)
        );
      const routeSummaryDetails = data.route.summary;
      const isDetailedView = !!data.shouldShowDetailedView;

      const items = [];

      let index = 0;
      let intervalDetails;
      while (index < intervals.length) {
        intervalDetails = intervals[index];

        switch (true) {
          case routeItemUtils.isRouteItemWithNoGPS(intervalDetails):
            items.push(Object.assign(
              {},
              getIntervalDetailsWithNoGPS(intervalDetails),
              {
                routeSummaryDetails
              }
            ));
            index++;
            break;
          case isDetailedView && routeItemUtils.isRouteItemWithParking(intervalDetails):
            items.push(Object.assign(
              {},
              getIntervalDetailsWithParking(intervalDetails),
              {
                routeSummaryDetails
              }
            ));
            index++;
            break;
          case isDetailedView && routeItemUtils.isRouteItemWithMovement(intervalDetails):
            items.push(Object.assign(
              {},
              getIntervalDetailsWithMovement(intervalDetails, data.route),
              {
                routeSummaryDetails
              }
            ));
            index++;
            break;
          case !isDetailedView:
            let computedIntervalDetails;
            if (index === 0) {
              computedIntervalDetails = getCombinedIntervalsDetails([intervalDetails], data.route);
              index++;
            } else {
              const combinedIntervals = [intervalDetails];
              if (index < intervals.length - 1) {
                const nextInterval = intervals[index + 1]
                if (
                  intervalDetails.status !== nextInterval.status
                  &&
                  !routeItemUtils.isRouteItemWithNoGPS(nextInterval)
                ) {
                  combinedIntervals.push(intervals[index + 1]);
                }
              }

              computedIntervalDetails =
                getCombinedIntervalsDetails(combinedIntervals, data.route);
              index += combinedIntervals.length;
            }

            items.push(Object.assign(
              {},
              computedIntervalDetails,
              {
                routeSummaryDetails
              }
            ));
            break;
        }
      }

      return items;
    }

    function getIntervalDetailsWithNoGPS(interval) {
      if (!routeItemUtils.isRouteItemWithNoGPS(interval)) {
        throw new Error('Provided interval data is not an interval with "No GPS" status');
      }

      return Object.assign(
        {},
        getBaseDetailsForInterval(interval),
        {
          hasNoGPS: true,
          fuelLevel: {
            initial: {
              mainTank: 0,
              secondTank: 0
            },
            final: {
              mainTank: 0,
              secondTank: 0
            }
          }
        }
      );
    }

    function getIntervalDetailsWithParking(interval) {
      if (!routeItemUtils.isRouteItemWithParking(interval)) {
        throw new Error('Provided interval data is not an interval with "Parking" status');
      }

      return Object.assign(
        {},
        getBaseDetailsForInterval(interval),
        {
          parking: routeItemUtils.getParkingDetails(interval),
          engineOperationTime: routeItemUtils.getEngineOperationTime(interval)
        }
      );
    }

    function getIntervalDetailsWithMovement(interval, timePeriodDetails) {
      if (!routeItemUtils.isRouteItemWithMovement(interval)) {
        throw new Error('Provided interval data is not an interval with "Movement" status');
      }

      const fuelUsageDetails =
        fuelCalculations.getFuelAmountChangesForIntervalWithMovement(interval, timePeriodDetails);

      return Object.assign(
        {},
        getBaseDetailsForInterval(interval),
        {
          onWay: routeItemUtils.getOnWayDetails(interval),
          mainTankFuelUsage: fuelUsageDetails.mainTank,
          secondTankFuelUsage: fuelUsageDetails.secondTank,
          totalFuelUsage: fuelUsageDetails.total,
          distance: routeItemUtils.getDistance(interval),
          maxSpeed: routeItemUtils.getMaxSpeed(interval),
          engineOperationTime: routeItemUtils.getEngineOperationTime(interval)
        }
      );
    }

    function getCombinedIntervalsDetails(intervals, timePeriodDetails) {
      if (!Array.isArray(intervals)) {
        throw new Error('It\'s expected that "intervals" should be an array');
      }

      if (intervals.length < 1) {
        throw new Error('It\'s expected that "intervals" should contains at least one item');
      }

      switch (true) {
        case intervals.length === 1:
          if (
            !routeItemUtils.isRouteItemWithParking(intervals[0])
            &&
            !routeItemUtils.isRouteItemWithMovement(intervals[0])
          ) {
            throw new Error('Interval should be the interval with parking or with movement');
          }
          break;
        default:
          if (
            (
              !routeItemUtils.isRouteItemWithParking(intervals[0])
              &&
              !routeItemUtils.isRouteItemWithMovement(intervals[0])
            )
            ||
            (
              !routeItemUtils.isRouteItemWithParking(intervals[1])
              &&
              !routeItemUtils.isRouteItemWithMovement(intervals[1])
            )
          ) {
            throw new Error('Interval should be the interval with parking or with movement');
          }

          if (
            (
              routeItemUtils.isRouteItemWithParking(intervals[0])
              &&
              routeItemUtils.isRouteItemWithParking(intervals[1])
            )
            ||
            (
              (
                routeItemUtils.isRouteItemWithMovement(intervals[0])
                &&
                routeItemUtils.isRouteItemWithMovement(intervals[1])
              )
            )
          ) {
            throw new Error('Intervals should have different types');
          }
      }

      const firstInterval = intervals[0];
      const secondInterval = intervals.length > 1 ? intervals[1] : intervals[0];

      const startDate = toDate(firstInterval.timestampFrom);
      const endDate = toDate(secondInterval.timestampTo);

      let onWay;
      let distance;
      let maxSpeed;
      let fuelUsageDetails;
      let engineOperationTime = routeItemUtils.getEngineOperationTime(firstInterval);
      if (intervals.length > 1) {
        engineOperationTime += routeItemUtils.getEngineOperationTime(secondInterval);
      }

      switch (true) {
        case routeItemUtils.isRouteItemWithMovement(firstInterval):
          onWay = routeItemUtils.getOnWayDetails(firstInterval);
          distance = routeItemUtils.getDistance(firstInterval);
          maxSpeed = routeItemUtils.getMaxSpeed(firstInterval);
          fuelUsageDetails =
            fuelCalculations.getFuelAmountChangesForIntervalWithMovement(firstInterval, timePeriodDetails);
          break;
        case routeItemUtils.isRouteItemWithMovement(secondInterval):
          onWay = routeItemUtils.getOnWayDetails(secondInterval);
          distance = routeItemUtils.getDistance(secondInterval);
          maxSpeed = routeItemUtils.getMaxSpeed(secondInterval);
          fuelUsageDetails =
            fuelCalculations.getFuelAmountChangesForIntervalWithMovement(secondInterval, timePeriodDetails);
          break;
        default:
          onWay = {
            from: 0,
            to: 0,
            duration: 0
          };
          distance = 0;
          fuelUsageDetails = {
            mainTank: 0,
            secondTank: 0,
            total: 0
          };
          maxSpeed = 0;
      }

      let parking;
      switch (true) {
        case routeItemUtils.isRouteItemWithParking(firstInterval):
          parking = routeItemUtils.getParkingDetails(firstInterval);
          break;
        case routeItemUtils.isRouteItemWithParking(secondInterval):
          parking = routeItemUtils.getParkingDetails(secondInterval);
          break;
        default:
          parking = {
            from: 0,
            to: 0,
            duration: 0
          };
      }

      const fuelLevel = routeItemUtils.getFuelLevelDetails({
        fuelFrom: firstInterval.fuelFrom,
        secondFuelFrom: firstInterval.secondFuelFrom,
        fuelTo: secondInterval.fuelTo,
        secondFuelTo: secondInterval.secondFuelTo
      });

      return {
        status: firstInterval.status,
        date: startDate,
        timeFrom: formatDuration(startDate),
        timeTo: formatDuration(endDate),
        timestampFrom: firstInterval.timestampFrom,
        onWay,
        parking,
        distance,
        maxSpeed,
        fuelLevel,
        mainTankFuelUsage: fuelUsageDetails.mainTank,
        secondTankFuelUsage: fuelUsageDetails.secondTank,
        totalFuelUsage: fuelUsageDetails.total,
        engineOperationTime,
        addresses: {
          google: firstInterval.addressGoogle || secondInterval.addressGoogle,
          yandex: firstInterval.addressYandex || secondInterval.addressYandex
        }
      };
    }

    function getBaseDetailsForInterval(interval) {
      const startDate = toDate(interval.timestampFrom);
      const endDate = toDate(interval.timestampTo);

      return {
        status: interval.status,
        date: startDate,
        timestampFrom: interval.timestampFrom,
        latitude: interval.latitude,
        longitude: interval.longitude,
        timeFrom: formatDuration(startDate),
        timeTo: formatDuration(endDate),
        onWay: {
          from: 0,
          to: 0,
          duration: 0
        },
        parking: {
          from: 0,
          to: 0,
          duration: 0
        },
        distance: 0,
        maxSpeed: 0,
        fuelLevel: routeItemUtils.getFuelLevelDetails(interval),
        fuelUsage: {},
        mainTankFuelUsage: 0,
        secondTankFuelUsage: 0,
        totalFuelUsage: 0,
        isFuelCalculatorUsed: false,
        hasRefueling: false,
        hasFuelDrain: false,
        hasNormalFuelConsumption: false,
        hasUnchangedFuelAmount: false,
        hasExceededDowntime: false,
        hasNoGPS: false,
        engineOperationTime: routeItemUtils.getEngineOperationTime(interval),
        addresses: {
          google: interval.addressGoogle,
          yandex: interval.addressYandex
        }
      };
    }

    function toDate(timestamp) {
      return new Date(parseInt(timestamp, 10) * 1000);
    }

    function formatDuration(date) {
      return [
        `${date.getHours()}`.padStart(2, '0'),
        `${date.getMinutes()}`.padStart(2, '0'),
        `${date.getSeconds()}`.padStart(2, '0')
      ].join(':');
    }
  }

})();
