(() => {
  angular
    .module('app.tokens')
    .factory('app.tokens.accessByTokenHttpInterceptor', interceptor);

  interceptor.$inject = [
    '$injector'
  ]

  function interceptor($injector) {
    return {
      request: preprocessRequest
    }

    function preprocessRequest(config) {
      const accessByToken = $injector.get('app.tokens.accessByToken')
      const isRequestToApi = /x\.xx\/?$/.test(config.url)

      if (!accessByToken.isActive || !isRequestToApi) {
        return config
      }

      config.data.token = accessByToken.tokenData.id
      delete config.data.s

      return config;
    }
  }
})();
