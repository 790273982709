(() => {
  angular
    .module('app.vehicles')
    .component('usersSubscribedToNotifications', {
      controller: Ctrl,
      controllerAs: 'ctrl',
      templateUrl: 'app/vehicles/details/settings/notification-settings/users-subscribed-to-notifications/tpl.html',
      bindings: {
        vehicleId: '<'
      }
    });

  Ctrl.$inject = [
    'usersSubscribedToNotifications.api',
    'VehicleSettingsToast'
  ];

  function Ctrl(api, toast) {
    const self = this;

    this.selectedUsers = [];
    let previouslySelectedUsers = [];

    this.userList = [];

    this.isLoadingInitialData = false;
    this.withFailedInitialDataLoading = false;

    this.isUpdatingData = false;

    this.$onInit = () => {
      fetchInitialData();
    };

    this.reload = () => {
      if (this.isLoadingInitialData) {
        return;
      }

      fetchInitialData();
    };

    this.isExpanded = false;
    this.toggleExpandedState = () => {
      this.isExpanded = !this.isExpanded;
    };

    function fetchInitialData() {
      self.isLoadingInitialData = true;
      self.withFailedInitialDataLoading = false;

      api.getUserNotificationsPreferences(self.vehicleId)
        .then((data) => {
          self.userList = data;
          self.selectedUsers = data
            .filter((obj) => obj.shouldReceiveNotifications)
            .map((obj) => obj.telegramUserId);

          previouslySelectedUsers = JSON.parse(angular.toJson(self.selectedUsers));
          self.withFailedInitialDataLoading = false;
        })
        .catch(() => {
          self.withFailedInitialDataLoading = true;
          toast.successfulSaving('notifications.failedLoadingInitialDataForUsersSubscribedToNotifications');
        })
        .finally(() => {
          self.isLoadingInitialData = false;
        });
    }

    function updateData(data) {
      self.isUpdatingData = true;

      api.updateUsersSubscribedToNotificationsData(self.vehicleId, data)
        .then(() => {
          previouslySelectedUsers = JSON.parse(angular.toJson(self.selectedUsers));
          toast.successfulSaving('notifications.successUpdatingOfUsersSubscribedToNotifications');
        })
        .catch((e) => {
          self.selectedUsers = JSON.parse(JSON.stringify(previouslySelectedUsers));
          toast.successfulSaving('notifications.failedUpdatingOfUsersSubscribedToNotifications');
        })
        .finally(() => {
          self.isUpdatingData = false;
        });
    }

    function areEqualArrays(arr1, arr2) {
      if (arr1.length !== arr2.length) {
        return false;
      }

      for (let i = 0; i < arr1.length; i++) {
        if (!arr2.includes(arr1[i])) {
          return false;
        }
      }

      return true;
    }
  }
})();
