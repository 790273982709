(() => {
  angular
    .module('app.logistics')
    .component('viewRouteForToken', {
      controller: Ctrl,
      controllerAs: 'ctrl',
      templateUrl: 'app/logistics/view-route-for-token/tpl.html'
    });

  Ctrl.$inject = [
    '$q',
    '$timeout',
    'app.tokens.accessByToken',
    'logistics/view-route-for-token/api',
    'MapOsmService',
    'ResponseParser'
  ];

  const isDebuggingDuringDevelopment = false;

  function Ctrl($q, $timeout, accessByToken, api, MapOsmService, ResponseParser) {
    const self = this;

    const addressType = 'addressYandex';

    const vehicleId = accessByToken.tokenData.vehicles[0];
    this.hasForwarderRouteForToday = false;
    this.car = null;
    this.forwarderRoute = null;
    this.isLoading = false;
    this.totalDistance = 0;
    this.formattedRealDistance = 0;
    this.formattedStartTime = 0;
    this.formattedEndTime = 0;
    this.formattedTotalTime = 0;
    this.formattedTotalDistance = 0;
    this.routeComplexity = 0;
    this.trafficData = null;
    this.stops = null;
    this.dataReceivedAt = '';
    this.forwarderRoutePoints = []

    let historyData = null;
    let pollingCurrentStatusOfVehicleTimeoutId = NaN;
    let isDestroyed = false;

    const globalClassName = 'with-loading-route-with-token';
    const date = getDateFromLocation(); // yyyy-mm-dd

    this.$onInit = () => {
      if (accessByToken.tokenData.vehicles.length === 0) {
        return;
      }

      this.isLoading = true;
      document.body.classList.add(globalClassName);

      $q
        .resolve({
          hasForwarderRouteForToday: false,
          forwarderRoute: null,
          trafficData: null,
          historyData: null,
          currentStatusOfVehicle: null,
          vehicleDetails: null,
          vehicleCurrentState: null
        })
        .then((results) => {
          return api
            .getLatestRouteOfVehicle(vehicleId, date)
            .then((forwarderRoute) => {
              if (isDestroyed) {
                return results;
              }

              results.forwarderRoute = forwarderRoute;
              if (!!results.forwarderRoute) {
                results.hasForwarderRouteForToday = true;
                /*const routeDate = new Date(forwarderRoute.dateTime * 1000)
                const today = new Date()

                results.hasForwarderRouteForToday =
                  routeDate.getDate() === today.getDate() &&
                  routeDate.getMonth() === today.getMonth() &&
                  routeDate.getFullYear() === today.getFullYear()*/
              }

              if (isDebuggingDuringDevelopment) {
                results.hasForwarderRouteForToday = true;
              }
              return results;
            });
        })
        .then((results) => {
          if (!results.hasForwarderRouteForToday || isDestroyed) {
            return results;
          }

          return api.getVehicleCurrentState(vehicleId)
            .then((vehicleCurrentState) => {
              results.vehicleCurrentState = vehicleCurrentState;
              return results;
            });
        })
        .then((results) => {
          if (!results.hasForwarderRouteForToday || isDestroyed) {
            return results;
          }

          return api.getTrafficDataByDate(vehicleId, date)
            .then((trafficData) => {
              results.trafficData = trafficData;
              return results;
            });
        })
        .then((results) => {
          if (!results.hasForwarderRouteForToday || isDestroyed) {
            return results;
          }

          return api.getHistoryDataByDate(vehicleId, date)
            .then((historyData) => {
              results.historyData = historyData;
              return results;
            });
        })
        .then((results) => {
          if (isDestroyed) {
            return results;
          }

          return api.getCurrentStatusOfVehicle(vehicleId)
            .then((currentStatusOfVehicle) => {
              results.currentStatusOfVehicle = currentStatusOfVehicle;
              return results;
            });
        })
        .then((results) => {
          return api.getVehicleDetails(vehicleId)
            .then((vehicleDetails) => {
              results.vehicleDetails = vehicleDetails;
              return results;
            });
        })
        .then((results) => {
          if (isDestroyed) {
            return;
          }

          this.hasForwarderRouteForToday = results.hasForwarderRouteForToday;
          if (!this.hasForwarderRouteForToday) {
            document.body.classList.remove(globalClassName);
            this.isLoading = false;
          }

          this.forwarderRoute = results.forwarderRoute;
          this.car = {
            name: results.vehicleDetails.info.x
          };
          this.trafficData = results.trafficData;
          historyData = results.historyData;

          if (results.hasForwarderRouteForToday) {
            const processedTrafficData =
              ResponseParser.parseSingleDeviceRoute(results.trafficData, false, addressType);
            this.stops = processedTrafficData.stops;

            this.formattedRealDistance = (processedTrafficData.summary.distanceKm || 0) + ' км.';

            this.formattedStartTime =
              getForwarderRouteStartTime(results.forwarderRoute).format('DD.MM.YYYY HH:mm');

            this.routeComplexity = parseFloat(results.forwarderRoute.routeComplexity);

            this.dataReceivedAt =
              !!results.vehicleCurrentState ? results.vehicleCurrentState.dateView : '';
          }

          applyCurrentStatusOfVehicle(results.currentStatusOfVehicle);
          renderForwarderRoute();
          delayCheckingCurrentStatusOfVehicle();

          // TODO remove it. It's just for debugging during development
          // api.getForwarderRouteDetails(321)
          //   .then((data) => {
          //     // console.log(data);
          //   });
        });
    };

    this.$onDestroy = () => {
      isDestroyed = true;
      stopCheckingCurrentStatusOfVehicle();
    };

    let pointForMapCenter = null;
    this.viewStop = (point) => {
      switch (true) {
        case pointForMapCenter === null || pointForMapCenter !== point:
          pointForMapCenter = point;
          const latitude = point.latitude || point.lat;
          const longitude = point.longitude || point.lng;
          MapOsmService.centerMapOnMarker(latitude, longitude);
          break;
        case pointForMapCenter === point:
          pointForMapCenter = null;
          MapOsmService.centerMapByPoints(this.forwarderRoute.points);
          break;
      }
    };

    this.getPointUrl = (point) => {
      const latitude = point.latitude || point.lat;
      const longitude = point.longitude || point.lng;
      return `https://www.google.com/maps/place/${latitude},${longitude}`;
    };

    function delayCheckingCurrentStatusOfVehicle() {
      pollingCurrentStatusOfVehicleTimeoutId =
        setTimeout(checkCurrentStatusOfVehicle, 10000);
    }

    function stopCheckingCurrentStatusOfVehicle() {
      clearTimeout(pollingCurrentStatusOfVehicleTimeoutId);
    }

    function checkCurrentStatusOfVehicle() {
      api.getCurrentStatusOfVehicle(vehicleId)
        .then((currentStatusOfVehicle) => {
          if (isDestroyed) {
            return;
          }

          applyCurrentStatusOfVehicle(currentStatusOfVehicle);
          delayCheckingCurrentStatusOfVehicle();
        });
    }

    function applyCurrentStatusOfVehicle(currentStateOfVehicle) {
      MapOsmService.drawCurrentPositionOfVehicleForAccessByToken(currentStateOfVehicle);

      if (currentStateOfVehicle !== '2') {
        return;
      }

      MapOsmService
        .updateForwarderRouteMarkersForAccessByToken([currentStateOfVehicle]);

      if (!self.trafficData) {
        return;
      }

      self.trafficData.stops.push(currentStateOfVehicle);
      self.stops = ResponseParser.parseSingleDeviceRoute(self.trafficData, false, addressType).stops;
      applyTrafficDataToForwarderRoute();
    }

    function renderForwarderRoute() {
      if (!self.hasForwarderRouteForToday) {
        return;
      }

      MapOsmService.drawForwarderRouteForAccessByToken(self.forwarderRoute.points, onRoutesFound);

      if (self.trafficData === null) {
        return;
      }

      const alreadyExistingStops = self.trafficData.stops
        .filter(obj => obj.status === '2')
        .map(obj => ({
          latitude: obj.latitude,
          longitude: obj.longitude
        }));
      MapOsmService
        .updateForwarderRouteMarkersForAccessByToken(alreadyExistingStops);
    }

    function onRoutesFound(foundRoutesDetails) {
      const foundRouteDetails = foundRoutesDetails.routes[0];
      const foundRouteSummary = foundRouteDetails.summary;

      self.totalDistance = Math.round(foundRouteDetails.summary.totalDistance / 1000);
      const timeCorrectionInCity = self.forwarderRoute.timeCorrectionInCity || 100;
      const timeCorrectionOutsideCity = self.forwarderRoute.timeCorrectionOutsideCity || 100;
      const totalMinutes = foundRouteSummary.formattedTotalTime / 60;

      // XXX copied from legacy code
      // use city correction if total time less 30m
      const correctionTime =
        totalMinutes > 30 ? timeCorrectionOutsideCity : timeCorrectionInCity;
      const totalTime = moment.utc(foundRouteSummary.formattedTotalTime * 1000 * correctionTime / 100);

      const getParkingTimeFromPoint =
        obj => isNaN(obj.parkingTime) ? defaultParkingDuration : obj.parkingTime;

      const defaultParkingDuration = 10;
      const totalParkingTime = self.forwarderRoute.points.reduce((acc, obj, index) => {
        if (index > 0 && index < self.forwarderRoute.points.length - 1) {
          acc += getParkingTimeFromPoint(obj);
        }
        return acc;
      }, 0);
      totalTime.add(totalParkingTime, 'minutes');

      const endTime = getForwarderRouteStartTime(self.forwarderRoute)
        .add(totalTime.date() - 1, 'days')
        .add(totalTime.hours(), 'hours')
        .add(totalTime.minutes(), 'minutes');
      self.formattedEndTime = endTime.format('DD.MM.YYYY HH:mm');

      self.formattedTotalTime = moment.duration(totalTime).format("h:mm");

      self.formattedTotalDistance = Math.round(foundRouteSummary.totalDistance / 1000) + ' км.';

      let arrivalTime;
      let departureTime;

      self.forwarderRoute.points.forEach((forwarderRoutePoint, index) => {
        switch (true) {
          case index === 0:
            forwarderRoutePoint.type = 'startPoint';
            break;
          case index === self.forwarderRoute.points.length - 1:
            forwarderRoutePoint.type = 'endPoint';
            break;
        }

        switch (true) {
          case index === 0:
            arrivalTime = getForwarderRouteStartTime(self.forwarderRoute);
            departureTime = arrivalTime.clone();
            break;
          default:
            const duration =
              moment.utc(foundRouteDetails.waypointsInfo[index - 1].duration * 1000 * correctionTime / 100);
            const parkingTime = getParkingTimeFromPoint(forwarderRoutePoint);

            arrivalTime = departureTime.clone()
              .add(duration.hours(), 'hours')
              .add(duration.minutes(), 'minutes')
              .add(duration.seconds(), 'seconds');

            departureTime = arrivalTime.clone().add(parkingTime, 'minutes');

            forwarderRoutePoint.formattedDurationTime =
              moment.duration(+duration).format('hh:mm', {trim: false});

            forwarderRoutePoint.formattedParkingTime =
              moment.duration(parkingTime, 'minutes').format("h:mm", {trim: false});
        }

        forwarderRoutePoint.arrivalTime = arrivalTime;
        forwarderRoutePoint.formattedArrivalTime = arrivalTime.format('HH:mm');
        forwarderRoutePoint.departureTime = departureTime;
        forwarderRoutePoint.formattedDepartureTime = departureTime.format('HH:mm');

        forwarderRoutePoint.routeIndex = index;
      });

      applyTrafficDataToForwarderRoute();

      $timeout(() => {
        document.body.classList.remove(globalClassName);
        self.isLoading = false;
      });
    }

    function applyTrafficDataToForwarderRoute() {
      self.forwarderRoutePoints = []
      self.forwarderRoute.points.forEach((forwarderRoutePoint) => {
        if (!forwarderRoutePoint.zoneCoordinates) {
          return;
        }

        const zoneBounds = L.polygon(forwarderRoutePoint.zoneCoordinates).getBounds();
        const stopInsideForwarderRouteZone = self.stops.find((obj) => {
          if (obj.latitude && obj.longitude) {
            return zoneBounds.contains(L.latLng(obj.latitude, obj.longitude));
          }
          return false;
        });

        forwarderRoutePoint.isVisited = !!stopInsideForwarderRouteZone

        let pointInfo = forwarderRoutePoint.name
        if (!!stopInsideForwarderRouteZone) {
          const address = stopInsideForwarderRouteZone[addressType]
          pointInfo = stopInsideForwarderRouteZone.info.replace(address, forwarderRoutePoint.name);
        }

        if (!/car-link/.test(pointInfo)) {
          pointInfo = `<span class="car-link">${pointInfo}</span>`
        }

        const obj = {
          point: forwarderRoutePoint,
          info: pointInfo,
          onWay: !!stopInsideForwarderRouteZone ?
            stopInsideForwarderRouteZone.onWay : forwarderRoutePoint.formattedDurationTime,
          fromTime: !!stopInsideForwarderRouteZone ?
            stopInsideForwarderRouteZone.fromTime : forwarderRoutePoint.formattedArrivalTime,
          stopTime: !!stopInsideForwarderRouteZone ?
            stopInsideForwarderRouteZone.stopTime : forwarderRoutePoint.formattedParkingTime,
          lat: !!stopInsideForwarderRouteZone ?
            stopInsideForwarderRouteZone.latitude : forwarderRoutePoint.lat,
          lng: !!stopInsideForwarderRouteZone ?
            stopInsideForwarderRouteZone.longitude : forwarderRoutePoint.lng,
          isVisited: !!stopInsideForwarderRouteZone,
          isUnVisited: !(!!stopInsideForwarderRouteZone)
        }
        self.forwarderRoutePoints.push(obj)
      });

      self.forwarderRoutePoints.sort((a, b) =>
        moment(a.fromTime, 'h:mm').toDate().getTime() - moment(b.fromTime, 'h:mm').toDate().getTime()
      )
    }

    function getForwarderRouteStartTime(forwarderRoute) {
      if (!forwarderRoute) {
        return null;
      }
      return moment.unix(forwarderRoute.dateTime);
    }

    function getDateFromLocation() {
      return locationSearchToObject().d;
    }

    function locationSearchToObject() {
      return document.location.search
        .replace(/^\?/, '')
        .split('&')
        .reduce((acc, str) => {
          const parts = str.split('=');
          acc[parts[0]] = parts[1];
          return acc;
        }, {});
    }
  }
})();
