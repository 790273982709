(function () {
  "use strict";

  angular
    .module('app.settings')
    .factory('SettingsService', SettingsService)
  ;

  SettingsService.$inject = ['$filter', '$mdDialog', '$mdToast', 'BackendApi', 'Session'];

  function SettingsService($filter, $mdDialog, $mdToast, BackendApi, Session) {
    var service = {};

    service.getUserSubscriberPlanCategories = function() {
      return [
        'monthly',
        'quarterly',
        'semiannual',
        'annual'
      ];
    };

    service.getUserSubscriberPlanGroups = function() {
      return [
        '1',
        '2',
        '3'
      ];
    };

    service.getYears = function () { // generate years range
      var currentYear = (new Date()).getFullYear(),
        years = [
          currentYear - 1,
          currentYear,
          currentYear + 1
        ];

      return years;
    }

    service.showConfirmDialog = function (ev, options) {
      options = options || {};
      var confirm = $mdDialog.confirm({
        multiple : true
      })
        .title(options.title || 'TransGPS')
        .textContent(options.content)
        .targetEvent(ev)
        .ok(options.yes || 'Да')
        .cancel(options.no || 'Нет');

      return $mdDialog.show(confirm);
    };

    service.showToastSuccess = function () {
      var toast = $mdToast.simple().content($filter('translate')('global.savedSuccess')).position('bottom left');
      $mdToast.show(toast);
    };

    service.showZoneEditDialog = function (item) {
      return $mdDialog.show({
        controller: ZoneEditCtrl,
        templateUrl: 'app/settings/view/zone-edit-dialog.html',
        locals: {
          resolveData: item
        }
      });
    };

    service.showUserEditDialog = function (item) {
      return $mdDialog.show({
        controller: UserEditCtrl,
        templateUrl: 'app/settings/view/user-edit-dialog.html',
        locals: {
          user: item
        },
        resolve: {
          permissions: function () {
            return item.permissions;
          },
          userSettings: ['BackendApi', function (BackendApi) {
            return BackendApi.getUserSettings(item);
          }],
          cars: ['$q', 'BackendApi', function ($q, BackendApi) {
            return $q(function (resolve) {
              BackendApi.getCarByGroup({id: item.id}).then(function (groupInfo) {
                $q.all([
                  BackendApi.getCarsSubscriberPlansData({cars: groupInfo.cars}),
                  BackendApi.getCarNow(0)
                ]).then(function (res) {
                  var carsSubscriberPlansData = res[0];
                  var carsData = res[1];

                  var cars = _.map(groupInfo.cars, function (car) {
                    var carSubscriberPlanData = _.find(carsSubscriberPlansData, function (item) {
                      return +item.id === +car.id;
                    });
                    if (carSubscriberPlanData) {
                      car.subscriberPlan = carSubscriberPlanData.subscriberPlan;
                      car.simNumber = carSubscriberPlanData.simNumber;
                    } else {
                      car.subscriberPlan = null;
                      car.simNumber = null;
                    }

                    var carData = _.find(carsData, function (item) {
                      return +item.id === +car.id;
                    });
                    if (carData) {
                      car.fuel = carData.fuel;
                      car.temperatureView = carData.temperatureView;
                      car.temperaturePrioView = carData.temperaturePrioView;
                    }
                    return car;
                  });
                  resolve(cars);
                });
              });
            });
          }],
          carSubscriberPlans: ['BackendApi', function (BackendApi) {
            return BackendApi.getCarsSubscriberPlans();
          }]
        }
      }).catch(function () {
        BackendApi.getCarByGroup({id: Session.groupId});
      });
    };

    return service;
  }

  ZoneEditCtrl.$inject = ['$scope', '$filter', '$mdDialog', '$mdToast', 'BackendApi', 'resolveData'];

  function ZoneEditCtrl($scope, $filter, $mdDialog, $mdToast, BackendApi, resolveData) {

    init();

    $scope.onCancel = function () {
      $mdDialog.cancel();
    };

    $scope.onSave = function () {
      var zone = $scope.elem;
      BackendApi.saveZone({zone: zone})
        .then(function () {
          showToastSuccess();
          $mdDialog.hide(zone);
        });
    };

    function init() {
      var tmpZone = angular.copy(resolveData);
      delete tmpZone.$$hashKey;
      delete tmpZone.isOwn;
      $scope.elem = tmpZone;
    }

    function showToastSuccess() {
      BackendApi.getProfile();
      var toast = $mdToast.simple().content($filter('translate')('global.savedSuccess')).position('bottom left');
      $mdToast.show(toast);
    }
  }

  UserEditCtrl.$inject = ['$scope', '$filter', '$mdDialog', '$mdToast', '$q', 'BackendApi', 'SettingsService', 'user', 'permissions', 'cars', 'carSubscriberPlans', 'userSettings'];

  function UserEditCtrl($scope, $filter, $mdDialog, $mdToast, $q, BackendApi, SettingsService, user, permissions, cars, carSubscriberPlans, userSettings) {

    init();

    $scope.onCancel = function () {
      $mdDialog.cancel();
    };

    $scope.onBlockUser = function (ev) {
      var blocked = $scope.permissions.beBlocked;

      SettingsService.showConfirmDialog(ev, {
        title: 'Блокировка пользователя',
        content: ['Вы уверены что хотите ', (blocked ? 'заблокировать' : 'разблокировать'), ' пользователя "', user.name, '"?'].join("")
      })
        .then(function () {
          BackendApi.blockUser({isBlocked: blocked, id: user.name}).then(function () {
            showToastSuccess();
          });
        }, function () {
          $scope.permissions.beBlocked = !blocked;
        })
      ;
    };

    $scope.onSavePermissions = function () {
      BackendApi.setUserPermissions({name: user.name, permissions: $scope.permissions})
        .then(function () {
          showToastSuccess();
        });
    };

    $scope.onSaveSubscriberPlans = function () {
      $q.all([
        BackendApi.setCarsSubscriberPlans({
          cars: $scope.cars
        }),
        BackendApi.setUserSettings({
          login: user.name,
          category: $scope.userSettings.category,
          year: $scope.userSettings.year,
          sequenceNumber: $scope.userSettings.sequenceNumber,
          group: $scope.userSettings.group,
          loginDescription: $scope.userSettings.loginDescription
        })
      ]).then(function () {
        showToastSuccess();
      });
    };

    $scope.onCommonSubscriberPlanChange = function() {
      _.each($scope.cars, function (car) {
        car.subscriberPlan = $scope.commonSubscriberPlan || null;
      });
    };

    function init() {
      $scope.permissions = permissions;
      $scope.name = user.name;
      $scope.cars = cars;
      $scope.carSubscriberPlans = carSubscriberPlans;
      $scope.commonSubscriberPlan = undefined;
      $scope.userSettings = userSettings || {
        category: null,
        year: (new Date()).getFullYear(),
        sequenceNumber: null,
        group: null,
        loginDescription: ''
      };
      $scope.years = SettingsService.getYears();
      if ($scope.userSettings && $scope.userSettings.year && $scope.years.indexOf($scope.userSettings.year) === -1) {
        $scope.years.unshift($scope.userSettings.year);
      }
      $scope.categories = SettingsService.getUserSubscriberPlanCategories();
      $scope.groups = SettingsService.getUserSubscriberPlanGroups();
    }

    function showToastSuccess() {
      BackendApi.getProfile();
      var toast = $mdToast.simple().content($filter('translate')('global.savedSuccess')).position('bottom left');
      $mdToast.show(toast);
    }
  }

})();
