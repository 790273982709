(function () {
    "use strict";
  
    angular
      .module('app')
      .component('carInfoBtn', {
        bindings: {
          id: '<',
          filter: '=',
          control: '='
        },
        templateUrl: 'app/component/carInfo/carInfoBtn.html',
        controllerAs: 'vm',
        controller: CarInfoBtnCtrl
      });
  
      CarInfoBtnCtrl.$inject = ['$rootScope', 'APP_EVENTS'];
  
    function CarInfoBtnCtrl($rootScope, APP_EVENTS) {
      var self = this;
      this.show = false;
  
      this.$onDestroy = function () {
        $rootScope.$broadcast(APP_EVENTS.car.carInfoChart, {show: false});
      };
  
      this.onClick = function () {
        self.show = !self.show;
        displayData();
      };

      function displayData(filter) {
        $rootScope.$broadcast(APP_EVENTS.car.carInfoChart, {
          id: self.id,
          filter: filter || self.filter,
          showDay: true,
          show: self.show
        });
      }

      if (self.control) {
        self.control.updateCarInfo = displayData;
      }
    }
  
  })();
  