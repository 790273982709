(() => {
  angular
    .module('app')
    .service('app.fuelReport.dataBuilder', dataBuilder);

  dataBuilder.$inject = [
    '$translate',
    'routeItemUtils',
    'fuel-consumption-metering-settings',
    'app.vehicles.details.lib.fuel-calculations.fuel-calculations'
  ];

  function dataBuilder($translate, routeItemUtils, fuelConsumptionMeteringSettings, fuelCalculations) {
    return {
      getFileName,
      getPageHeaderData,
      getMainTableHeaderData,
      getMainTableBodyData,
      getMainTableFooterData,

      getMainTableBodyData2
    };

    function getFileName(data) {
      const startDate = new Date(parseInt(data.route.stops[0].timestampFrom, 10) * 1000);
      const endDate = new Date(parseInt(data.route.stops[data.route.stops.length - 1].timestampTo, 10) * 1000);

      const parts = [
        data.vehicleNumber.split('.').join(' '),
        'fuel',
        formatDate(startDate)
      ];

      if (startDate.getTime() !== endDate.getTime()) {
        parts.push(formatDate(endDate));
      }

      return parts.join('_');
    }

    function getPageHeaderData(data) {
      const dataForFirstDay = data.dataForEachDay[0];
      const dataForLastDay = data.dataForEachDay[data.dataForEachDay.length - 1];

      const firstEvent = dataForFirstDay.stops[0];
      const lastEvent = dataForLastDay.stops[dataForLastDay.stops.length - 1];

      const startDate = new Date(parseInt(firstEvent.timestampFrom, 10) * 1000);
      const endDate = new Date(parseInt(lastEvent.timestampTo, 10) * 1000);

      return {
        title: $translate.instant('car.fuelPrint.title'),
        dates: [
          moment(startDate).format('L HH:mm'),
          moment(endDate).format('L HH:mm')
        ],
        vehicleNumber: data.vehicleNumber,
        hasTwoTanks: hasTwoTanks(data)
      };
    }

    function getMainTableHeaderData(data) {
      return {
        hasTwoTanks: hasTwoTanks(data)
      };
    }

    function getMainTableBodyData2(data) {
      data.dataForEachDay.sort((obj1, obj2) => {
        return parseInt(obj1.stops[0].timestampFrom, 10) - parseInt(obj2.stops[0].timestampFrom, 10);
      });

      const items = data.dataForEachDay
        .map(fuelCalculations.getDetailsForTimePeriod);

      return {
        items,
        hasTwoTanks: items.some(obj => !!obj.hasTwoTanks)
      };
    }

    function getMainTableBodyData(data) {
      let isImpulseSensorUsed = false;
      let isFuelCalculatorUsed = false;

      data.dataForEachDay.sort((obj1, obj2) => {
        return parseInt(obj1.stops[0].timestampFrom, 10) - parseInt(obj2.stops[0].timestampFrom, 10);
      });

      const getSetOfEvents = (record) => {
        const isImpulseSensorUsed = routeItemUtils.isImpulseSensorUsed(record.summary);
        let isFuelCalculatorUsed = false;

        const setOfEvents = record.stops.filter((routeItem) => {
          isFuelCalculatorUsed = routeItemUtils.isFuelCalculatorUsed(routeItem, record.summary);
          return routeItemUtils.isRouteItemWithParking(routeItem)
            ||
            routeItemUtils.isRouteItemWithMovement(routeItem);
        });

        if (isImpulseSensorUsed || isFuelCalculatorUsed) {
          return setOfEvents;
        }

        return setOfEvents.filter(routeItemUtils.isRouteItemWithParking);
      };

      const items = data.dataForEachDay
        .filter((record) => {
          return getSetOfEvents(record).length > 0;
        })
        .map((record) => {
          const setOfEvents = getSetOfEvents(record);

          const firstEvent = setOfEvents[0];

          const date = new Date(parseInt(firstEvent.timestampFrom, 10) * 1000);

          const mileage = routeItemUtils.getRouteTotalDistance(record, false);

          const dateDetails = {
            date: formatDate(date, '.'),
            fuel: {
              initialAmount: {
                total: 0,
                mainTank: 0,
                secondTank: 0
              },
              finalAmount: {
                total: 0,
                mainTank: 0,
                secondTank: 0
              },
              refueling: {
                total: 0,
                mainTank: 0,
                secondTank: 0
              },
              fuelDrain: {
                total: 0,
                mainTank: 0,
                secondTank: 0
              },
              totalFuelUsage: {
                total: 0,
                mainTank: 0,
                secondTank: 0
              },
              averageFuelUsage: 0
            },
            mileage,
            engineOperationDuration: routeItemUtils.getRouteEngineOperationDuration(record)
          };

          const dayEvents = record.stops
            .filter((obj) => routeItemUtils.isRouteItemWithParking(obj) || routeItemUtils.isRouteItemWithMovement(obj));

          const firstRouteItem = dayEvents[0];
          dateDetails.fuel.initialAmount.mainTank = toNumber(firstRouteItem.fuelFrom);
          dateDetails.fuel.initialAmount.secondTank = toNumber(firstRouteItem.secondFuelFrom);
          dateDetails.fuel.initialAmount.total =
            dateDetails.fuel.initialAmount.mainTank + dateDetails.fuel.initialAmount.secondTank;

          const lastRouteItem = dayEvents.slice(-1)[0];
          dateDetails.fuel.finalAmount.mainTank = toNumber(lastRouteItem.fuelTo);
          dateDetails.fuel.finalAmount.secondTank = toNumber(lastRouteItem.secondFuelTo);
          dateDetails.fuel.finalAmount.total =
            dateDetails.fuel.finalAmount.mainTank + dateDetails.fuel.finalAmount.secondTank;

          setOfEvents.forEach((routeItem, index) => {
            if (routeItemUtils.isRouteItemWithParking(routeItem)) {
              isImpulseSensorUsed = routeItemUtils.isImpulseSensorUsed(record.summary);
              isFuelCalculatorUsed = routeItemUtils.isFuelCalculatorUsed(routeItem, record.summary);

              const isExcludedEventWithControversialFuelLevel =
                fuelConsumptionMeteringSettings.isExcludedEventWithControversialFuelLevel(routeItem);

              const fuelUsage = routeItemUtils.getFuelUsageForRouteWithParking(routeItem);

              if (!isExcludedEventWithControversialFuelLevel) {
                if (isImpulseSensorUsed || isFuelCalculatorUsed) {
                  if (fuelUsage.mainTank < 0) {
                    dateDetails.fuel.totalFuelUsage.mainTank += -fuelUsage.mainTank;
                  }

                  if (fuelUsage.secondTank < 0) {
                    dateDetails.fuel.totalFuelUsage.secondTank += -fuelUsage.secondTank;
                  }
                }

                if (fuelUsage.mainTank < 0) {
                  dateDetails.fuel.fuelDrain.mainTank += -fuelUsage.mainTank;
                } else if (fuelUsage.mainTank > 0) {
                  dateDetails.fuel.refueling.mainTank += fuelUsage.mainTank;
                }

                if (fuelUsage.secondTank < 0) {
                  dateDetails.fuel.fuelDrain.secondTank += -fuelUsage.secondTank;
                } else if (fuelUsage.secondTank > 0) {
                  dateDetails.fuel.refueling.secondTank += fuelUsage.secondTank;
                }
              }
            }

            if (routeItemUtils.isRouteItemWithMovement(routeItem)) {
              const fuelUsage = routeItemUtils.getFuelUsageForRouteWithMovement(routeItem, record.summary);

              switch (true) {
                case routeItemUtils.isImpulseSensorUsed(record.summary):
                  dateDetails.fuel.totalFuelUsage.mainTank += fuelUsage.mainTank;
                  break;
                case fuelUsage.isFuelCalculatorUsed:
                  dateDetails.fuel.totalFuelUsage.mainTank += -fuelUsage.total;
                  break;
                default:
                  if (fuelUsage.mainTank < 0) {
                    dateDetails.fuel.totalFuelUsage.mainTank += -fuelUsage.mainTank;
                  }

                  if (fuelUsage.secondTank < 0) {
                    dateDetails.fuel.totalFuelUsage.secondTank += -fuelUsage.secondTank;
                  }
              }
            }
          });

          dateDetails.fuel.refueling.total =
            dateDetails.fuel.refueling.mainTank + dateDetails.fuel.refueling.secondTank;

          dateDetails.fuel.fuelDrain.total =
            dateDetails.fuel.fuelDrain.mainTank + dateDetails.fuel.fuelDrain.secondTank;

          if (!isImpulseSensorUsed && !isFuelCalculatorUsed) {
            dateDetails.fuel.totalFuelUsage.mainTank = calcFuelUsage(
              dateDetails.fuel.initialAmount.mainTank,
              dateDetails.fuel.finalAmount.mainTank,
              dateDetails.fuel.refueling.mainTank,
              dateDetails.fuel.fuelDrain.mainTank
            );

            dateDetails.fuel.totalFuelUsage.secondTank = calcFuelUsage(
              dateDetails.fuel.initialAmount.secondTank,
              dateDetails.fuel.finalAmount.secondTank,
              dateDetails.fuel.refueling.secondTank,
              dateDetails.fuel.fuelDrain.secondTank
            );
          }

          dateDetails.fuel.totalFuelUsage.total =
            dateDetails.fuel.totalFuelUsage.mainTank + dateDetails.fuel.totalFuelUsage.secondTank;

          dateDetails.fuel.averageFuelUsage =
            routeItemUtils.toNumber(dateDetails.fuel.totalFuelUsage.total * 100 / mileage);

          return dateDetails;
        });

      return {
        hasTwoTanks:
          routeItemUtils.isAnyItemHasMainTankFuelData(data.route.stops)
          && routeItemUtils.isAnyItemHasSecondTankFuelData(data.route.stops),
        items
      };
    }

    function getMainTableFooterData(data) {
      let totalRefuelingInMainTank = 0;
      let totalRefuelingInSecondTank = 0;

      let totalFuelDrainInMainTank = 0;
      let totalFuelDrainInSecondTank = 0;

      let totalFuelUsageInMainTank = 0;
      let totalFuelUsageInSecondTank = 0;

      let totalMileage = 0;

      let totalEngineOperationDuration = 0;

      let hasTwoTanks = false

      getMainTableBodyData2(data).items.forEach((dayDetails) => {
        totalRefuelingInMainTank += dayDetails.totalRefuelingInMainTank;
        totalRefuelingInSecondTank += dayDetails.totalRefuelingInSecondTank;

        totalFuelDrainInMainTank += dayDetails.totalFuelDrainInMainTank;
        totalFuelDrainInSecondTank += dayDetails.totalFuelDrainInSecondTank;

        totalFuelUsageInMainTank += dayDetails.totalFuelUsageInMainTank;
        totalFuelUsageInSecondTank += dayDetails.totalFuelUsageInSecondTank;

        totalMileage += dayDetails.mileage;

        totalEngineOperationDuration += dayDetails.engineOperationDuration;

        hasTwoTanks = hasTwoTanks || dayDetails.hasTwoTanks
      });

      const totalRefuelingInAllTanks = totalRefuelingInMainTank + totalRefuelingInSecondTank;
      const totalFuelDrainInAllTanks = totalFuelDrainInMainTank + totalFuelDrainInSecondTank;
      const totalFuelUsageInAllTanks = totalFuelUsageInMainTank + totalFuelUsageInSecondTank;

      const averageFuelUsage = toNumber(totalFuelUsageInAllTanks * 100 / totalMileage);

      return {
        totalRefuelingInMainTank,
        totalRefuelingInSecondTank,
        totalRefuelingInAllTanks,

        totalFuelDrainInMainTank,
        totalFuelDrainInSecondTank,
        totalFuelDrainInAllTanks,

        totalFuelUsageInMainTank,
        totalFuelUsageInSecondTank,
        totalFuelUsageInAllTanks,

        totalMileage,
        totalEngineOperationDuration,
        averageFuelUsage,
        hasTwoTanks
      };
    }

    function hasTwoTanks(data) {
      const allEvents = data.dataForEachDay.map((obj) => obj.stops).flat();
      return routeItemUtils.isAnyItemHasMainTankFuelData(allEvents)
        &&
        routeItemUtils.isAnyItemHasSecondTankFuelData(allEvents);
    }
  }

  function formatDate(date, separator = '-') {
    return [
      `${date.getDate()}`.padStart(2, '0'),
      `${date.getMonth() + 1}`.padStart(2, '0'),
      date.getFullYear()
    ].join(separator);
  }

  function toNumber(v) {
    const result = parseFloat(v);
    return isNaN(result) ? 0 : result;
  }

  function calcFuelUsage(initialFuelAmount, finalFuelAmount, refueling, fuelDrain) {
    return initialFuelAmount + refueling - fuelDrain - finalFuelAmount;
  }
})();
