(() => {
  angular
    .module('app.vehicles')
    .service('VehicleExtraSettingsBackendAPI', VehicleExtraSettingsBackendAPI);

  VehicleExtraSettingsBackendAPI.$inject = [
    'BackendApi'
  ];

  function VehicleExtraSettingsBackendAPI(BackendApi) {
    this.save = (data = {}) => {
      return BackendApi.makeRequest({
        c: 11,
        id: data.vehicleId,
        vehicleTypeId: data.vehicleTypeId,
        iconColor: data.iconColor,
        iconId: data.iconId
      });
    };
  }
})();
