(function () {
  "use strict";

  angular
    .module('app.settings')
    .component('settingsAll', {
      bindings: {
        settings: '<'
      },
      templateUrl: 'app/settings/all/settings.all.html',
      controller: SettingsAllCtrl,
      controllerAs: 'vm'
    });

  SettingsAllCtrl.$inject = ['$filter', '$mdToast', 'CarService', 'BackendApi'];

  function SettingsAllCtrl($filter, $mdToast, CarService, BackendApi) {
    var self = this;
    this.form = {};

    this.$onInit = function () {
      var seasons = {
        summer: "2000-06-01",
        winter: "2000-12-01"
      };
      this.intervals = CarService.getIntervals('time');
      if (this.settings.seasons) {
        seasons = this.settings.seasons;
      }
      this.seasons = {
        summer: moment(seasons.summer.replace(/\./g, "-")).toDate(),
        winter: moment(seasons.winter.replace(/\./g, "-")).toDate()
      };
    };

    this.onSaveAuth = function () {
      bindToast(function () {
        return BackendApi.addTracker(self.auth);
      });
    };

    this.onSaveSettings = function () {
      bindToast(function () {
        return BackendApi.saveSettings(self.contact);
      });
    };

    this.onChangePassword = function () {
      bindToast(function () {
        BackendApi.changePassword(self.pass);
      });
    };

    this.onSetInterval = function () {
      bindToast(function () {
        return BackendApi.setIntervals(self.intervals);
      });
    };

    this.onSetSeasons = function () {
      bindToast(function () {
        return BackendApi.setSeasons(self.seasons);
      });
    };

    function bindToast(func) {
      func()
        .then(BackendApi.getProfile)
        .then(showToastSuccess);
    }

    function showToastSuccess() {
      var toast = $mdToast.simple().content($filter('translate')('global.savedSuccess')).position('bottom left');
      $mdToast.show(toast);
    }
  }

})();
