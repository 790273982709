(function () {
  "use strict";

  angular
    .module('app.settings')
    .component('settingsSubscriptionFee', {
      bindings: {
        carSubscriberPlans: '<'
      },
      templateUrl: 'app/settings/subscription-fee/settings.subscription-fee.html',
      controller: SettingsSubscriptionFeeCtrl,
      controllerAs: 'vm'
    });

  SettingsSubscriptionFeeCtrl.$inject = ['Session', '$q', '$state', 'SettingsService', 'BackendApi', 'PeriodFilters'];

  function SettingsSubscriptionFeeCtrl(Session, $q, $state, SettingsService, BackendApi, PeriodFilters) {

    var vm = this;

    vm.$onInit = function () {
      vm.isTotalAllFee = false;
      vm.size = _.size;
      vm.users = Session.user.groups;
      vm.subscriptionFees = [];
      vm.showLoader = false;
      vm.groups = SettingsService.getUserSubscriberPlanGroups();
      vm.categories = SettingsService.getUserSubscriberPlanCategories();
      vm.years = SettingsService.getYears();
      vm.months = _.range(12);
      vm.quarters = _.range(1, 5);
      vm.semesters = _.range(1, 3);

      vm.filters = {
        group: vm.groups[0],
        category: vm.categories[0],
        year: (new Date()).getFullYear(),
        selectionPeriod: 'previous',
        monthPeriod: vm.months[0],
        quarterPeriod: vm.quarters[0],
        semiannualPeriod: vm.semesters[0]
      };
    };

    vm.$onDestroy = function () {
      BackendApi.getCarByGroup({id: Session.groupId});
    };

    vm.onGoToCarSettings = function (car) {
      $state.go('vehicles.details.settings', {id: car.id}, {reload: true});
    };

    vm.onShowSubscriptionFee = function () {
      vm.showLoader = true;

      //todo: need to get user settings only for current loggined user
      BackendApi.getUsersSettingsByParams({
        group: vm.filters.group,
        category: vm.filters.category
      }).then(function (usersSettings) {
        vm.subscriptionFees = [];
        if (_.isArray(usersSettings) && usersSettings.length) {
          fillSubscriptionFee(usersSettings, 0);
        } else {
          vm.showLoader = false;
        }
      });
    };

    vm.onIsTotalAllFee = function (totallFee) {
      if (totallFee) {
        var grouped = [];
        var sequenceNumber = _.groupBy(vm.subscriptionFees, 'sequenceNumber');
        _.mapObject(sequenceNumber, function(group) {
          if (group.length > 1) {
            var temp = group.shift();
            _.each(group, function(item) {
              temp.total += item.total;
              _.mapObject(item.subscriberPlans, function(plan) {
                if(_.has(temp.subscriberPlans, plan.type)){
                  temp.subscriberPlans[plan.type].carsCount += plan.carsCount;
                } else {
                  temp.subscriberPlans[plan.type] = plan
                }
              })
            });
            grouped.push(temp);
          } else {
            grouped.push(group[0]);
          }
        });
        vm.subscriptionFees = grouped;
      } else {
        vm.subscriptionFees = JSON.parse(vm.deapCloneSubscriptionFees)
      }
    };

    function fillSubscriptionFee(usersSettings, index) {
      if (index >= usersSettings.length) {
        vm.showLoader = false;
        return;
      }
      var filter = getFilter();

      var user = vm.users.find(function (user) {
        return user.name.toLowerCase() === usersSettings[index].login.toLowerCase();
      });
      if (user) {
        BackendApi.getCarByGroup({id: user.id}).then(function (groupInfo) {
          $q.all([
            BackendApi.getCarsSubscriberPlansData({cars: groupInfo.cars}),
            BackendApi.getReport(
              {
                cars: groupInfo.cars,
                type: 'distance'
              },
              filter
            )
          ]).then(function (res) {
            var carsSubscriberPlansData = res[0];
            var carsData = res[1];

            processUserCarsData(usersSettings[index], carsData, carsSubscriberPlansData);
            fillSubscriptionFee(usersSettings, ++index);
          });
        });
      } else {
        fillSubscriptionFee(usersSettings, ++index);
      }
    }

    function processUserCarsData(userSettings, carsData, carsSubscriberPlansData) {
      vm.isTotalAllFee = false;
      userSettings = userSettings || {};
      var categoryMonths = getCategoryMonths();
      var userSubscriptionFee = {
        sequenceNumber: userSettings.sequenceNumber,
        group: userSettings.group,
        loginDescription: userSettings.loginDescription,
        carsWithoutSubscriptionPlan: [],
        subscriberPlans: {},
        total: null
      };
      _.each(vm.carSubscriberPlans, function (subscriberPlan) {
        userSubscriptionFee.subscriberPlans[subscriberPlan.type] = {
          id: subscriberPlan.id,
          type: subscriberPlan.type,
          price: subscriberPlan.price,
          carsCount: 0
        };
      });
      userSubscriptionFee.subscriberPlans['notMoving'] = {
        id: null,
        type: 'notMoving',
        price: 30,
        carsCount: 0
      };

      _.each(carsData, function (carData) {
        var carSubscriberPlansData = _.find(carsSubscriberPlansData, function (item) {
          return +item.id === +carData.id;
        });
        // if plan is not set or outdated
        if (
          !(carSubscriberPlansData && carSubscriberPlansData.subscriberPlan) ||
          !userSubscriptionFee.subscriberPlans[carSubscriberPlansData.subscriberPlan]
        ) {
          if (carSubscriberPlansData && carSubscriberPlansData.subscriberPlan) {
            console.log('no subscriber plan for ' + carSubscriberPlansData.subscriberPlan);
          }
          userSubscriptionFee.carsWithoutSubscriptionPlan.push({
            id: +carData.id,
            name: carSubscriberPlansData ? carSubscriberPlansData.name : carData.id
          });
        } else {
          _.each(categoryMonths, function (categoryMonth) {
            var isCarMoving = false;

            if (Object.keys(carData.points).length) { //check if car was moving or has conection to server in this month
              isCarMoving = true;
            }

            // if car is moving or plan is bpo
            if (
              isCarMoving ||
              carSubscriberPlansData.subscriberPlan === 'bpo' ||
              carSubscriberPlansData.subscriberPlan === '165'
              ) {
              userSubscriptionFee.subscriberPlans[carSubscriberPlansData.subscriberPlan].carsCount++; // set that car is moving this month
            } else {
              userSubscriptionFee.subscriberPlans['notMoving'].carsCount++; // set that car is not moving this month
            }
          });
        }
      });

      _.each(userSubscriptionFee.subscriberPlans, function (subscriberPlan, name) {
        if (subscriberPlan.carsCount) {
          userSubscriptionFee.total += subscriberPlan.price * subscriberPlan.carsCount;
        } else {
          delete userSubscriptionFee.subscriberPlans[name];
        }
      });

      vm.subscriptionFees.push(userSubscriptionFee);
      vm.deapCloneSubscriptionFees = JSON.stringify(vm.subscriptionFees);
    }

    function getFilter() {
      var filter = PeriodFilters.getFilter('report'),
        from = null,
        to = null;

      switch (vm.filters.category) {
        case 'monthly':
          from = moment([vm.filters.year, vm.filters.monthPeriod]).startOf('month');
          to = moment([vm.filters.year, vm.filters.monthPeriod]).endOf("month").startOf("second");
          break;
        case 'quarterly':
          from = moment([vm.filters.year]).quarter(vm.filters.quarterPeriod).startOf('quarter');
          to = moment([vm.filters.year]).quarter(vm.filters.quarterPeriod).endOf("quarter").startOf("second");
          break;
        case 'semiannual':
          from = moment([vm.filters.year]).quarter(vm.filters.semiannualPeriod === 1 ? 1 : 3).startOf('quarter');
          to = moment([vm.filters.year]).quarter(vm.filters.semiannualPeriod === 1 ? 2 : 4).endOf("quarter").startOf("second");
          break;
        case 'annual':
          from = moment([vm.filters.year]).startOf('year');
          to = moment([vm.filters.year]).endOf("year").startOf("second");
          break;
      }

      if (vm.filters.selectionPeriod === 'previous') {
        switch (vm.filters.category) {
          case 'monthly':
            from.subtract(1, 'month');
            to.subtract(1, 'month').endOf("month").startOf("second");
            break;
          case 'quarterly':
            from.subtract(1, 'quarter');
            to.subtract(1, 'quarter').endOf("quarter").startOf("second");
            break;
          case 'semiannual':
            from.subtract(2, 'quarter');
            to.subtract(2, 'quarter').endOf("quarter").startOf("second");
            break;
          case 'annual':
            from.subtract(1, 'year');
            to.subtract(1, 'year').endOf("year").startOf("second");
            break;
        }
      }

      filter.from = from.toDate();
      filter.to = to.toDate();

      return filter;
    }

    function getCategoryMonths() { //get mounth list by category period in format ["01", "02"...]
      var pad = '00',
        months = [];

      if (vm.filters.selectionPeriod === 'previous') { // getting previous period
        switch (vm.filters.category) {
          case 'monthly':
            if (vm.filters.monthPeriod === 0) {
              months = [12];
            } else {
              months = [vm.filters.monthPeriod];
            }
            break;
          case 'quarterly':
            if (vm.filters.quarterPeriod === 1) {
              months = _.range(10, 13);
            } else {
              months = _.range(((vm.filters.quarterPeriod - 2) * 3) + 1, ((vm.filters.quarterPeriod - 1) * 3) + 1);
            }
            break;
          case 'semiannual':
            if (vm.filters.semiannualPeriod === 1) {
              months = _.range(7, 13);
            } else {
              months = _.range(1, 7);
            }
            break;
          case 'annual':
            months = _.range(1, 13);
            break;
        }
      } else {
        switch (vm.filters.category) {
          case 'monthly':
            months = [vm.filters.monthPeriod + 1];
            break;
          case 'quarterly':
            months = _.range(((vm.filters.quarterPeriod - 1) * 3) + 1, (vm.filters.quarterPeriod * 3) + 1);
            break;
          case 'semiannual':
            months = _.range(((vm.filters.semiannualPeriod - 1) * 6) + 1, (vm.filters.semiannualPeriod * 6) + 1);
            break;
          case 'annual':
            months = _.range(1, 13);
            break;
        }
      }

      return _.map(months, function (month) {
        return (pad + month).slice(-pad.length);
      });
    }
  }

})();
