(() => {
  angular
    .module('app.settings')
    .component('globalNotificationSettingsInTelegram', {
      controller: Ctrl,
      controllerAs: 'ctrl',
      templateUrl: 'app/settings/global-notification-settings/telegram/tpl.html',
      bindings: {
        isEnabled: '<'
      }
    });

  Ctrl.$inject = [
    'Session',
    'VehicleSettingsToast',
    'globalNotificationSettings.api'
  ];

  function Ctrl(session, toast, api) {
    const self = this;

    this.telegramUsers = []

    this.isUpdateRequestActive = false;

    this.$onInit = () => {

    };

    let previousIsEnabledValue = false;
    this.$onChanges = () => {
      previousIsEnabledValue = this.isEnabled;
    };

    this.onIsEnabledChanged = () => {
      saveSettings();
    };

    this.getBotUrl = () => {
      let telegramBotName = process.env.NODE_ENV === 'development' ?
        process.env.DEV_TELEGRAM_BOT_NAME :
        process.env.PRODUCTION_TELEGRAM_BOT_NAME;

      if (!!window.extraConfig && !!window.extraConfig.telegramBotName) {
        telegramBotName = window.extraConfig.telegramBotName
      }

      return `https://t.me/${telegramBotName}?start=${session.user.loginName}`;
    };

    function saveSettings() {
      self.isUpdateRequestActive = true;

      api.saveNotificationInTelegramMode(session.user.loginName, self.isEnabled)
        .then(() => {
          previousIsEnabledValue = self.isEnabled;

          const i18nKey = self.isEnabled ?
            'settings.notificationsInTelegramEnabled' :
            'settings.notificationsInTelegramDisabled';
          toast.successfulSaving(i18nKey);
        })
        .catch(() => {
          self.isEnabled = previousIsEnabledValue;
          toast.successfulSaving('settings.failedUpdatingNotificationsInTelegramMode');
        })
        .finally(() => {
          self.isUpdateRequestActive = false;
        });
    }
  }
})();
