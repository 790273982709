(() => {
  angular
    .module('app')
    .value('app.reports.fuel.print-dialog.FuelReportDialogCtrl', Ctrl);

  Ctrl.$inject = [
    '$element',
    '$scope',
    '$filter',
    '$mdDialog',
    'CarService',
    'resolveData',
    'BackendApi',
    '$stateParams',
    'app.reports.fuel.reportDataBuilder',
    'app.reports.fuel.reportSaver'
  ];

  function Ctrl($element, $scope, $filter, $mdDialog, CarService, resolveData, BackendApi,
    $stateParams, reportDataBuilder, reportSaver) {
    const self = this;

    this.data = {};
    this.isDataReady = false;
    this.subHeader = reportDataBuilder.createPageSubHead({
      vehicle: {
        id: resolveData.car.id,
        name: resolveData.car.name
      },
      from: resolveData.filter.from,
      to: resolveData.filter.to
    });

    updateMainTableData();

    this.onCancel = function () {
      $mdDialog.cancel();
    };

    this.onDischargeToUsage = function () {
      updateMainTableData();
    };

    this.onPrintBtnPressed = function () {
      setTimeout(function () {
        const title = reportDataBuilder.createPageHeader();

        var printWindow = window.open('', title);
        printWindow.document.write($element[0].querySelector('.fuel-print-tpl').innerHTML);
        printWindow.document.title = [title, self.data.from, self.data.to].join(' - ');
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
      }, 1000);
    };

    this.onDownloadAsXLSXPressed = () => {
      reportSaver.toXLSX(Object.assign(
        {
          subHeader: self.subHeader,
          vehicle: {
            name: self.subHeader.vehicleName
          }
        },
        self.data
      ));
    };

    function updateMainTableData() {
      self.isDataReady = false;

      reportDataBuilder
        .createMainTableData({
          vehicle: {
            id: resolveData.car.id
          },
          from: resolveData.filter.from,
          to: resolveData.filter.to
        })
        .then((data) => {
          self.data = data;
          self.isDataReady = true;
          $scope.$apply();
        });
    }
  }
})();
