(() => {
  const prefix = 'withFuelReportView.events';

  angular
    .module('app.vehicles')
    .constant(prefix, {
      toggle: `${prefix}.toggle`,
      changed: `${prefix}.changed`,
      toggleRouteVisibilityOnMap: `${prefix}.toggleRouteVisibilityOnMap`
    });
})();
