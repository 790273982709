(function () {
  "use strict";

  angular
    .module('app.vehicles')
    .component('vehicles', {
      bindings: {
        $transition$: '<'
      },
      templateUrl: 'app/vehicles/vehicles.html',
      controller: VehiclesCtrl,
      controllerAs: 'vm'
    });

  VehiclesCtrl.$inject = [
    '$scope',
    '$state',
    '$log',
    'Session',
    'BackendApi',
    'APP_EVENTS',
    'PermissionService',
    'app.tokens.accessByToken'
  ];

  function VehiclesCtrl($scope, $state, $log, Session, BackendApi, APP_EVENTS, PermissionService, accessByToken) {
    $log.log('Logged user', Session.user);
    var self = this;

    this.isAccessByToken = accessByToken.isActive

    this.$onInit = function () {
      this.searchCar = '';
      this.searchGroup = '';
      this.groupId = Session.groupId;
      this.carId = this.$transition$.params().id || 0;

      const isAccessByToken = accessByToken.isActive || accessByToken.isAccessByToken()

      const user = Session.user || {}
      this.vehicles = isAccessByToken ? [] : user.cars
      this.groups = isAccessByToken ? [] : user.groups
      this.perm = PermissionService.get();
      // $scope.$broadcast(APP_EVENTS.car.carSelected, self.carId);
    };

    $scope.$on(APP_EVENTS.car.carSelected, function (e, carId) {
      self.carId = carId;
      $log.log('APP_EVENTS.car.carSelected for VehiclesCtrl', carId);
    });

    $scope.$on('report:close', function () {
      if (Session.groupId != self.groupId) {
        self.groupId = Session.groupId;
        self.onSelectGroup();
      }
    });

    this.searchCarItem = function () {
      if(!this.searchCar) this.vehicles = Session.user.cars;
      this.vehicles = this.vehicles.filter(function(item) {
        if(String(this.searchCar.toLowerCase()).includes(String(item.name.toLowerCase()))) {
          return item;
        }
      });
    };

    this.searchGroupItem = function () {
      if(!this.searchGroup) this.groups = Session.user.groups;
      this.groups = this.groups.filter(function(item) {
        if(String(this.searchGroup.toLowerCase()).includes(String(item.name.toLowerCase()))) {
          return item;
        }
      });
    };

    this.onSelectCar = function () {
      $log.log('selectedCar', this.carId);
      if (this.carId == 0) {
        $state.go('vehicles.all');
      } else {
        var state = $state.current.name == 'vehicles.all' ? 'vehicles.details.now' : $state.current.name;
        $state.go(state, {id: this.carId});
      }
      $scope.$broadcast(APP_EVENTS.car.carSelected, this.carId);
    };

    this.onSelectGroup = function () {
      $log.log('onSelectGroup', this.groupId);
      Session.groupId = this.groupId;
      this.showLoader = true;
      BackendApi.getCarByGroup({id: this.groupId})
        .then(function (result) {
          self.vehicles = result.cars;
          if ($state.current.name == 'vehicles.all') {
            $scope.$broadcast(APP_EVENTS.car.updateCarList);
          } else {
            $state.go('vehicles.all');
          }
        })
        .finally(function () {
          self.showLoader = false;
        })
      ;

      this.carId = 0;
    };
  }
})();
