(() => {
  angular
    .module('app')
    .service('app.fuelReport.saver', saver);

  saver.$inject = [
    '$translate',
    'app.reports.helpers.common',
    'app.reports.exportTypes',
    'app.fuelReport.dataBuilder',
    'app.reports.fileSaver',
    'app.reports.helpers.xlsx',
    'app.reports.helpers.pdf',
    'BackendApi',
    'app.reports.utils',
    'app.vehicles.details.lib.api',
    'routeItemUtils'
  ];

  function saver($translate, commonForReports, exportTypes, dataBuilder, fileSaver, xlsxHelper,
    pdfHelper, backendApi, reportUtils, api, routeItemUtils) {
    return {
      save
    };

    function save(exportType, data) {
      data.dataForEachDay = routeItemUtils.splitRouteDetailsByDates(data.route);
      switch (exportType) {
        case exportTypes.fuelReportToXLSX:
          toXLSX(data);
          break;
        case exportTypes.fuelReportToPDF:
          toPDF(data);
          break;
        case exportTypes.fuelReportToPrint:
          toPrint(data);
          break;
      }

      // The "return" bellow is by intention.
      // The call to this backend resource returns another data that is not always
      // matches the data from another backend resource
      return;
      api
        .getEventsForInterval({
          initialDate: data.filter.from,
          finalDate: data.filter.to,
          vehicleId: data.vehicleId
        })
        .then((dataForEachDay) => {
          console.log(dataForEachDay);
          return;
          data.dataForEachDay = dataForEachDay;

          switch (exportType) {
            case exportTypes.fuelReportToXLSX:
              toXLSX(data);
              break;
            case exportTypes.fuelReportToPDF:
              toPDF(data);
              break;
            case exportTypes.fuelReportToPrint:
              toPrint(data);
              break;
          }
        });
    }

    function toXLSX(data) {
      const workbook = new ExcelJS.Workbook();

      const sheet = workbook.addWorksheet($translate.instant('car.fuelPrint.title'), {
        pageSetup: Object.assign(
          {},
          commonForReports.xlsxPageSetupOptions
        )
      });

      renderPageHeader(sheet, data);
      renderMainTableHeader(sheet, data);
      renderMainTableBody(sheet, data);
      renderMainTableFooter(sheet, data);

      fileSaver.saveXLSX(dataBuilder.getFileName(data), workbook);

      function renderPageHeader(sheet, data) {
        const headerData = dataBuilder.getPageHeaderData(data);

        let row;
        let cell;
        let rowIndex;
        let items;

        // ==================
        // Main page header
        // ==================
        items = [
          $translate.instant('car.fuelPrint.title')
        ];

        const totalColumnsCount = headerData.hasTwoTanks ? 19 : 9;
        for (let i = 0; i < totalColumnsCount; i++) {
          items.push('');
        }

        row = sheet.addRow(items);
        rowIndex = sheet.rowCount;

        cell = row.getCell(1);
        cell.font = {
          bold: true,
          size: 14
        };
        cell.alignment = {
          vertical: 'middle',
          horizontal: 'center'
        };

        sheet.mergeCells(rowIndex, 1, rowIndex, totalColumnsCount);

        sheet.addRow([]);

        // ==================
        // interval
        // ==================
        items = [
          $translate.instant('car.fuelPrint.period.title'),
          $translate.instant('car.fuelPrint.period.interval', {
            from: headerData.dates[0],
            to: headerData.dates[1]
          }),
          '',
          '',
          ''
        ];

        if (headerData.hasTwoTanks) {
          items = items.concat([
            '',
            '',
            ''
          ]);
        }

        row = sheet.addRow(items);
        rowIndex = sheet.rowCount;

        if (headerData.hasTwoTanks) {
          sheet.mergeCells(rowIndex, 2, rowIndex, 8);
        } else {
          sheet.mergeCells(rowIndex, 2, rowIndex, 5);
        }

        xlsxHelper.styleRow(row);
        xlsxHelper.styleCellAsHeader(row.getCell(1));
        xlsxHelper.alignToLeft(row.getCell(1));

        // ==================
        // vehicle name
        // ==================
        const firstCellTitle = `${$translate.instant('car.fuelPrint.carName')}:`;
        items = [
          firstCellTitle,
          headerData.vehicleNumber,
          '',
          '',
          ''
        ];

        if (headerData.hasTwoTanks) {
          items = items.concat([
            '',
            '',
            ''
          ]);
        }

        row = sheet.addRow(items);

        if (firstCellTitle.length > 10) {
          row.height = 30;
        }

        rowIndex = sheet.rowCount;

        if (headerData.hasTwoTanks) {
          sheet.mergeCells(rowIndex, 2, rowIndex, 8);
        } else {
          sheet.mergeCells(rowIndex, 2, rowIndex, 5);
        }

        xlsxHelper.styleRow(row);
        xlsxHelper.styleCellAsHeader(row.getCell(1));
        xlsxHelper.alignToLeft(row.getCell(1));

        cell = row.getCell(1);
        cell.alignment = Object.assign(
          cell.alignment || {},
          {
            wrapText: true
          }
        );

        // ==================
        // empty row
        // ==================
        sheet.addRow([]);
      }

      function renderMainTableHeader(sheet, data) {
        const headerData = dataBuilder.getMainTableHeaderData(data);

        let row;

        // ==================
        // First row
        // ==================
        let firstRowCells = [];

        // "Date" column
        firstRowCells = firstRowCells.concat([
          $translate.instant('reports.date')
        ]);

        // "Initial fuel amount" column
        firstRowCells = firstRowCells.concat([
          $translate.instant('car.fuelPrint.fuelOnStart')
        ]);
        if (headerData.hasTwoTanks) {
          firstRowCells = firstRowCells.concat([
            '',
            ''
          ]);
        }

        // "Refueling" column
        firstRowCells = firstRowCells.concat([
          $translate.instant('report.refueled')
        ]);
        if (headerData.hasTwoTanks) {
          firstRowCells = firstRowCells.concat([
            '',
            ''
          ]);
        }

        // "Fuel drain" column
        firstRowCells = firstRowCells.concat([
          $translate.instant('car.fuelPrint.discharge')
        ]);
        if (headerData.hasTwoTanks) {
          firstRowCells = firstRowCells.concat([
            '',
            ''
          ]);
        }

        // "Final fuel amount" column
        firstRowCells = firstRowCells.concat([
          $translate.instant('car.fuelPrint.fuelOnEnd')
        ]);
        if (headerData.hasTwoTanks) {
          firstRowCells = firstRowCells.concat([
            '',
            ''
          ]);
        }

        // "Fuel usage" column
        firstRowCells = firstRowCells.concat([
          $translate.instant('car.fuelPrint.usage')
        ]);
        if (headerData.hasTwoTanks) {
          firstRowCells = firstRowCells.concat([
            '',
            ''
          ]);
        }

        // other columns
        firstRowCells = firstRowCells.concat([
          $translate.instant('car.mileage'),
          $translate.instant('car.fuelPrint.averageFuelUsage'),
          $translate.instant('car.fuelPrint.engine')
        ]);

        row = sheet.addRow(firstRowCells);

        row.eachCell({includeEmpty: true}, (cell) => {
          xlsxHelper.styleCellAsHeader(cell);

          cell.alignment = Object.assign(
            cell.alignment || {},
            {
              horizontal: 'center',
              vertical: 'middle'
            }
          );
        });

        if (headerData.hasTwoTanks) {
          const rowIndex = sheet.rowCount;

          // "Initial fuel amount" column
          sheet.mergeCells(rowIndex, 2, rowIndex, 4);

          // "Refueling" column
          sheet.mergeCells(rowIndex, 5, rowIndex, 7);

          // "Fuel drain" column
          sheet.mergeCells(rowIndex, 8, rowIndex, 10);

          // "Final fuel amount" column
          sheet.mergeCells(rowIndex, 11, rowIndex, 13);

          // "Fuel usage" column
          sheet.mergeCells(rowIndex, 14, rowIndex, 16);
        }

        xlsxHelper.styleRow(row);
        row.height = 35;

        row.eachCell({includeEmpty: true}, (cell) => {
          xlsxHelper.styleCellAsHeader(cell);

          cell.alignment = Object.assign(
            cell.alignment || {},
            {
              horizontal: 'center',
              vertical: 'middle',
              wrapText: true
            }
          );
        });

        // ==================
        // Second row
        // ==================
        if (headerData.hasTwoTanks) {
          let secondRowItems = [];

          // "Date" column
          secondRowItems.push('');

          // "Initial fuel amount" sub columns
          secondRowItems = secondRowItems.concat([
            $translate.instant('report.tank'),
            $translate.instant('report.number', {
              number: 1
            }),
            $translate.instant('report.number', {
              number: 2
            })
          ]);

          // "Refueling" sub columns
          secondRowItems = secondRowItems.concat([
            $translate.instant('report.tank'),
            $translate.instant('report.number', {
              number: 1
            }),
            $translate.instant('report.number', {
              number: 2
            })
          ]);

          // "Fuel drain" column
          secondRowItems = secondRowItems.concat([
            $translate.instant('report.tank'),
            $translate.instant('report.number', {
              number: 1
            }),
            $translate.instant('report.number', {
              number: 2
            })
          ]);

          // "Final fuel amount" sub columns
          secondRowItems = secondRowItems.concat([
            $translate.instant('report.tank'),
            $translate.instant('report.number', {
              number: 1
            }),
            $translate.instant('report.number', {
              number: 2
            })
          ]);

          // "Fuel usage" sub columns
          secondRowItems = secondRowItems.concat([
            $translate.instant('report.tank'),
            $translate.instant('report.number', {
              number: 1
            }),
            $translate.instant('report.number', {
              number: 2
            })
          ]);

          // other columns
          secondRowItems = secondRowItems.concat([
            '',
            '',
            ''
          ]);

          row = sheet.addRow(secondRowItems);

          xlsxHelper.styleRow(row);

          row.eachCell({includeEmpty: true}, (cell) => {
            xlsxHelper.styleCellAsHeader(cell);

            cell.alignment = Object.assign(
              cell.alignment || {},
              {
                horizontal: 'center',
                vertical: 'middle',
                wrapText: true
              }
            );
          });

          const columnsCount = sheet.columns.length;
          const rowIndex = sheet.rowCount;

          // "Date" column
          sheet.mergeCells(rowIndex - 1, 1, rowIndex, 1);

          // "Mileage" column
          sheet.mergeCells(rowIndex - 1, columnsCount - 3, rowIndex, columnsCount - 3);

          // "Average fuel usage" column
          sheet.mergeCells(rowIndex - 1, columnsCount - 2, rowIndex, columnsCount - 2);

          // "Engine" column
          sheet.mergeCells(rowIndex - 1, columnsCount - 1, rowIndex, columnsCount - 1);
        }

        // ==================
        // column widths
        // ==================
        const columnsCount = sheet.columns.length;
        sheet.columns.forEach((column, index) => {
          column.width = 6;

          // "Date" column
          if (index === 0) {
            column.width = 12;
          }

          // "Mileage" column
          if (index === columnsCount - 4) {
            column.width = 10;
          }

          // "Average Fuel Usage" column
          if (index === columnsCount - 3) {
            column.width = 14;
          }

          // "Engine" column
          if (index === columnsCount - 2) {
            column.width = 12;
          }

          // "Initial fuel amount"
          if (index === 1 && !headerData.hasTwoTanks) {
            column.width = 12;
          }

          // "Refueled" column
          if (index === 2 && !headerData.hasTwoTanks) {
            column.width = 10;
          }

          // "Fuel drain" column
          if (index === 3 && !headerData.hasTwoTanks) {
            column.width = 10;
          }

          // "Final fuel amount"
          if (index === 4 && !headerData.hasTwoTanks) {
            column.width = 12;
          }

          // "Fuel usage" column
          if (index === 5 && !headerData.hasTwoTanks) {
            column.width = 14;
          }
        });
      }

      function renderMainTableBody(sheet, data) {
        const bodyData = dataBuilder.getMainTableBodyData2(data);
        bodyData.items.forEach((dayDetails) => {
          let items = [];

          // date
          items.push(formatDate(dayDetails.startDate));

          // initial fuel amount
          if (bodyData.hasTwoTanks) {
            items = items.concat([
              reportUtils.renderIfNotZero(
                toFixed(dayDetails.initialFuelAmountInAllTanks)
              ),
              reportUtils.renderIfNotZero(
                toFixed(dayDetails.initialFuelAmountInMainTank)
              ),
              reportUtils.renderIfNotZero(
                toFixed(dayDetails.initialFuelAmountInSecondTank)
              )
            ]);
          } else {
            items = items.concat([
              reportUtils.renderIfNotZero(
                toFixed(dayDetails.initialFuelAmountInAllTanks)
              )
            ]);
          }

          // refueling
          if (bodyData.hasTwoTanks) {
            items = items.concat([
              reportUtils.renderIfNotZero(
                toFixed(dayDetails.totalRefuelingInAllTanks)
              ),
              reportUtils.renderIfNotZero(
                toFixed(dayDetails.totalRefuelingInMainTank)
              ),
              reportUtils.renderIfNotZero(
                toFixed(dayDetails.totalRefuelingInSecondTank)
              )
            ]);
          } else {
            items = items.concat([
              reportUtils.renderIfNotZero(
                toFixed(dayDetails.totalRefuelingInAllTanks)
              )
            ]);
          }

          // fuel drain
          if (bodyData.hasTwoTanks) {
            items = items.concat([
              reportUtils.renderIfNotZero(
                toFixed(dayDetails.totalFuelDrainInAllTanks)
              ),
              reportUtils.renderIfNotZero(
                toFixed(dayDetails.totalFuelDrainInMainTank)
              ),
              reportUtils.renderIfNotZero(
                toFixed(dayDetails.totalFuelDrainInSecondTank)
              )
            ]);
          } else {
            items = items.concat([
              reportUtils.renderIfNotZero(
                toFixed(dayDetails.totalFuelDrainInAllTanks)
              )
            ]);
          }

          // final fuel amount
          if (bodyData.hasTwoTanks) {
            items = items.concat([
              reportUtils.renderIfNotZero(
                toFixed(dayDetails.finalFuelAmountInAllTanks)
              ),
              reportUtils.renderIfNotZero(
                toFixed(dayDetails.finalFuelAmountInMainTank)
              ),
              reportUtils.renderIfNotZero(
                toFixed(dayDetails.finalFuelAmountInSecondTank)
              )
            ]);
          } else {
            items = items.concat([
              reportUtils.renderIfNotZero(
                toFixed(dayDetails.finalFuelAmountInAllTanks)
              )
            ]);
          }

          // fuel usage
          if (bodyData.hasTwoTanks) {
            items = items.concat([
              reportUtils.renderIfNotZero(
                toFixed(dayDetails.totalFuelUsageInAllTanks)
              ),
              reportUtils.renderIfNotZero(
                toFixed(dayDetails.totalFuelUsageInMainTank)
              ),
              reportUtils.renderIfNotZero(
                toFixed(dayDetails.totalFuelUsageInSecondTank)
              )
            ]);
          } else {
            items = items.concat([
              reportUtils.renderIfNotZero(
                toFixed(dayDetails.totalFuelUsageInAllTanks)
              )
            ]);
          }

          // mileage
          items.push(reportUtils.renderIfNotZero(
            Math.round(dayDetails.mileage * 10) / 10
          ));

          // average fuel usage
          items.push(
            reportUtils.renderIfNotZero(
              reportUtils.formatFuelAmount(dayDetails.averageFuelUsage)
            )
          );

          // engine
          if (dayDetails.engineOperationDuration > 0) {
            items.push(reportUtils.formatDurationInSeconds(dayDetails.engineOperationDuration));
          } else {
            items.push('');
          }

          const row = sheet.addRow(items);
          xlsxHelper.styleRow(row);
        });
      }

      function renderMainTableFooter(sheet, data) {
        const footerData = dataBuilder.getMainTableFooterData(data);

        let items = [];

        // 1st column under "Date"
        items.push($translate.instant('global.total'));

        // under "Initial fuel amount"
        if (footerData.hasTwoTanks) {
          items = items.concat([
            '',
            '',
            ''
          ]);
        } else {
          items = items.concat([
            ''
          ]);
        }

        // "Refueling" column
        if (footerData.hasTwoTanks) {
          items = items.concat([
            reportUtils.renderIfNotZero(
              toFixed(footerData.totalRefuelingInAllTanks)
            ),
            reportUtils.renderIfNotZero(
              toFixed(footerData.totalRefuelingInMainTank)
            ),
            reportUtils.renderIfNotZero(
              toFixed(footerData.totalRefuelingInSecondTank)
            )
          ]);
        } else {
          items = items.concat([
            reportUtils.renderIfNotZero(
              toFixed(footerData.totalRefuelingInAllTanks)
            )
          ]);
        }

        // "Fuel drain" column
        if (footerData.hasTwoTanks) {
          items = items.concat([
            reportUtils.renderIfNotZero(
              toFixed(footerData.totalFuelDrainInAllTanks)
            ),
            reportUtils.renderIfNotZero(
              toFixed(footerData.totalFuelDrainInMainTank)
            ),
            reportUtils.renderIfNotZero(
              toFixed(footerData.totalFuelDrainInSecondTank)
            )
          ]);
        } else {
          items = items.concat([
            reportUtils.renderIfNotZero(
              toFixed(footerData.totalFuelDrainInAllTanks)
            )
          ]);
        }

        // under "Final fuel amount"
        if (footerData.hasTwoTanks) {
          items = items.concat([
            '',
            '',
            ''
          ]);
        } else {
          items = items.concat([
            ''
          ]);
        }

        // "Fuel usage" column
        if (footerData.hasTwoTanks) {
          items = items.concat([
            reportUtils.renderIfNotZero(
              toFixed(footerData.totalFuelUsageInAllTanks)
            ),
            reportUtils.renderIfNotZero(
              toFixed(footerData.totalFuelUsageInMainTank)
            ),
            reportUtils.renderIfNotZero(
              toFixed(footerData.totalFuelUsageInSecondTank)
            )
          ]);
        } else {
          items = items.concat([
            reportUtils.renderIfNotZero(
              toFixed(footerData.totalFuelUsageInAllTanks)
            )
          ]);
        }

        // "Mileage" column
        items.push(reportUtils.renderIfNotZero(
          Math.round(footerData.totalMileage * 10) / 10
        ));

        // "Average fuel usage" column
        items.push(reportUtils.formatFuelAmount(footerData.averageFuelUsage));

        // "Engine operation duration" column
        items.push(reportUtils.formatDurationInSeconds(footerData.totalEngineOperationDuration));

        const row = sheet.addRow(items);

        if (footerData.hasTwoTanks) {
          const rowIndex = sheet.rowCount;

          // under "Initial fuel amount"
          sheet.mergeCells(rowIndex, 2, rowIndex, 4);

          // under "Final fuel amount"
          sheet.mergeCells(rowIndex, 11, rowIndex, 13);
        }

        xlsxHelper.styleRow(row);
        xlsxHelper.styleRowAsHeader(row);
        xlsxHelper.alignToLeft(row.getCell(1));
      }
    }

    function toPDF(data) {
      fileSaver.savePDF(dataBuilder.getFileName(data), createPdf(data));
    }

    function toPrint(data) {
      fileSaver.printPdf(createPdf(data));
    }

    function createPdf(data) {
      const pageTitleText = $translate.instant('car.fuelPrint.title');

      const doc = {
        header: pdfHelper.createPageTitle(pageTitleText),
        footer: (currentPage) => pdfHelper.createPageFooter(currentPage),
        pageMargins: pdfHelper.pageMargins,
        content: [],
        styles: pdfHelper.defaultStyles,
        pageOrientation: 'landscape'
      };

      renderPageHeader(doc, data);
      renderMainTable(doc, data);

      return pdfMake.createPdf(doc);

      function renderPageHeader(doc, data) {
        const headerData = dataBuilder.getPageHeaderData(data);

        const rows = [];

        // ==================
        // interval
        // ==================
        rows.push([
          $translate.instant('car.fuelPrint.period.title'),
          $translate.instant('car.fuelPrint.period.interval', {
            from: headerData.dates[0],
            to: headerData.dates[1]
          })
        ]);

        // ==================
        // vehicle name
        // ==================
        rows.push([
          $translate.instant('reports.vehicleNumber'),
          headerData.vehicleNumber
        ]);

        doc.content.push(pdfHelper.createHorizontalTable(rows));
      }

      function renderMainTable(doc, data) {
        const headerData = dataBuilder.getMainTableHeaderData(data);
        let rows = [];

        const withHeaderStyles = (v) => {
          if (typeof v !== 'object') {
            return {
              text: v,
              style: 'tableHeader',
              alignment: 'center'
            };
          }

          return Object.assign(
            {},
            v,
            {
              style: 'tableHeader',
              alignment: 'center'
            }
          );
        };

        const withBodyStyles = (v, columnIndex) => {
          if (typeof v !== 'object') {
            return {
              text: v,
              alignment: columnIndex === 0 ? 'left' : 'right'
            };
          }

          return Object.assign(
            {},
            v,
            {
              alignment: columnIndex === 0 ? 'left' : 'right'
            }
          );
        };

        const withFooterStyles = (v, columnIndex) => {
          if (typeof v !== 'object') {
            return {
              text: v,
              style: 'tableHeader',
              alignment: columnIndex === 0 ? 'left' : 'right'
            };
          }

          return Object.assign(
            {},
            v,
            {
              style: 'tableHeader',
              alignment: columnIndex === 0 ? 'left' : 'right'
            }
          );
        };

        rows = rows.concat([
          getFirstRowForHeaderCells(data).map((v) => withHeaderStyles(v))
        ]);

        if (headerData.hasTwoTanks) {
          rows = rows.concat([
            getSecondRowForHeaderCells(data).map((v) => withHeaderStyles(v))
          ]);
        }

        rows = rows.concat(
          getBodyCells(data).map((rowData) => rowData.map((v, index) => withBodyStyles(v, index)))
        );
        rows = rows.concat([
          getFooterCells(data).map((v, index) => withFooterStyles(v, index))
        ]);

        doc.content.push({
          style: 'mainTable',
          table: {
            body: rows,
            widths: getColumnWidths(data)
          }
        });
      }

      function getFirstRowForHeaderCells(data) {
        const headerData = dataBuilder.getMainTableHeaderData(data);

        let items = [];

        // ==================
        // "Date" column
        // ==================
        items = items.concat([
          $translate.instant('reports.date')
        ]);

        if (headerData.hasTwoTanks) {
          items[items.length - 1] = {
            text: items[items.length - 1],
            rowSpan: 2
          };
        }

        // ==================
        // "Initial fuel amount" column
        // ==================
        items = items.concat([
          $translate.instant('car.fuelPrint.fuelOnStart')
        ]);

        if (headerData.hasTwoTanks) {
          items[items.length - 1] = {
            text: items[items.length - 1],
            colSpan: 3
          };

          items = items.concat([
            '',
            ''
          ]);
        }

        // ==================
        // "Refueling" column
        // ==================
        items = items.concat([
          $translate.instant('report.refueled')
        ]);

        if (headerData.hasTwoTanks) {
          items[items.length - 1] = {
            text: items[items.length - 1],
            colSpan: 3
          };

          items = items.concat([
            '',
            ''
          ]);
        }

        // ==================
        // "Fuel drain" column
        // ==================
        items = items.concat([
          $translate.instant('car.fuelPrint.discharge')
        ]);

        if (headerData.hasTwoTanks) {
          items[items.length - 1] = {
            text: items[items.length - 1],
            colSpan: 3
          };

          items = items.concat([
            '',
            ''
          ]);
        }

        // ==================
        // "Final fuel amount" column
        // ==================
        items = items.concat([
          $translate.instant('car.fuelPrint.fuelOnEnd')
        ]);

        if (headerData.hasTwoTanks) {
          items[items.length - 1] = {
            text: items[items.length - 1],
            colSpan: 3
          };

          items = items.concat([
            '',
            ''
          ]);
        }

        // ==================
        // "Fuel usage" column
        // ==================
        items = items.concat([
          $translate.instant('car.fuelPrint.usage')
        ]);

        if (headerData.hasTwoTanks) {
          items[items.length - 1] = {
            text: items[items.length - 1],
            colSpan: 3
          };

          items = items.concat([
            '',
            ''
          ]);
        }

        // ==================
        // other columns
        // ==================
        items = items.concat([
          $translate.instant('car.mileage'),
          $translate.instant('car.fuelPrint.averageFuelUsage'),
          $translate.instant('car.fuelPrint.engine')
        ]);

        if (headerData.hasTwoTanks) {
          items[items.length - 3] = {
            text: items[items.length - 3],
            rowSpan: 2
          };

          items[items.length - 2] = {
            text: items[items.length - 2],
            rowSpan: 2
          };

          items[items.length - 1] = {
            text: items[items.length - 1],
            rowSpan: 2
          };
        }

        return items;
      }

      function getSecondRowForHeaderCells() {
        let items = [];

        // "Date" column
        items.push('');

        // "Initial fuel amount" sub columns
        items = items.concat([
          $translate.instant('report.tank'),
          $translate.instant('report.number', {
            number: 1
          }),
          $translate.instant('report.number', {
            number: 2
          })
        ]);

        // "Refueling" sub columns
        items = items.concat([
          $translate.instant('report.tank'),
          $translate.instant('report.number', {
            number: 1
          }),
          $translate.instant('report.number', {
            number: 2
          })
        ]);

        // "Fuel drain" column
        items = items.concat([
          $translate.instant('report.tank'),
          $translate.instant('report.number', {
            number: 1
          }),
          $translate.instant('report.number', {
            number: 2
          })
        ]);

        // "Final fuel amount" sub columns
        items = items.concat([
          $translate.instant('report.tank'),
          $translate.instant('report.number', {
            number: 1
          }),
          $translate.instant('report.number', {
            number: 2
          })
        ]);

        // "Fuel usage" sub columns
        items = items.concat([
          $translate.instant('report.tank'),
          $translate.instant('report.number', {
            number: 1
          }),
          $translate.instant('report.number', {
            number: 2
          })
        ]);

        // other columns
        items = items.concat([
          '',
          '',
          ''
        ]);

        return items;
      }

      function getColumnWidths(data) {
        const headerData = dataBuilder.getMainTableHeaderData(data);

        if (headerData.hasTwoTanks) {
          const widthOfColumnWithNumber = 23;

          return [
            // "Date" column,
            55,

            // "Initial fuel amount" column
            widthOfColumnWithNumber,
            widthOfColumnWithNumber,
            widthOfColumnWithNumber,

            // "Refueled" column
            widthOfColumnWithNumber,
            widthOfColumnWithNumber,
            widthOfColumnWithNumber,

            // "Fuel drain" column
            widthOfColumnWithNumber,
            widthOfColumnWithNumber,
            widthOfColumnWithNumber,

            // "Final fuel amount" column
            widthOfColumnWithNumber,
            widthOfColumnWithNumber,
            widthOfColumnWithNumber,

            // "Total fuel usage" column
            widthOfColumnWithNumber,
            widthOfColumnWithNumber,
            widthOfColumnWithNumber,

            // "Mileage" column
            'auto',

            // "Average fuel usage" column
            'auto',

            // "Engine operation duration" column
            'auto'
          ];
        }

        return [
          // "Date" column,
          55,

          // "Initial fuel amount" column
          'auto',

          // "Refueled" column
          'auto',

          // "Fuel drain" column
          'auto',

          // "Final fuel amount" column
          'auto',

          // "Total fuel usage" column
          'auto',

          // "Mileage" column
          'auto',

          // "Average fuel usage" column
          'auto',

          // "Engine operation duration" column
          'auto'
        ];
      }

      function getBodyCells(data) {
        const bodyData = dataBuilder.getMainTableBodyData2(data);

        return bodyData.items.map((dayDetails) => {
          let items = [];

          // date
          items.push(formatDate(dayDetails.startDate));

          // initial fuel amount
          if (bodyData.hasTwoTanks) {
            items = items.concat([
              reportUtils.renderIfNotZero(
                toFixed(dayDetails.initialFuelAmountInAllTanks)
              ),
              reportUtils.renderIfNotZero(
                toFixed(dayDetails.initialFuelAmountInMainTank)
              ),
              reportUtils.renderIfNotZero(
                toFixed(dayDetails.initialFuelAmountInSecondTank)
              )
            ]);
          } else {
            items = items.concat([
              reportUtils.renderIfNotZero(
                toFixed(dayDetails.initialFuelAmountInAllTanks)
              )
            ]);
          }

          // refueling
          if (bodyData.hasTwoTanks) {
            items = items.concat([
              reportUtils.renderIfNotZero(
                toFixed(dayDetails.totalRefuelingInAllTanks)
              ),
              reportUtils.renderIfNotZero(
                toFixed(dayDetails.totalRefuelingInMainTank)
              ),
              reportUtils.renderIfNotZero(
                toFixed(dayDetails.totalRefuelingInSecondTank)
              )
            ]);
          } else {
            items = items.concat([
              reportUtils.renderIfNotZero(
                toFixed(dayDetails.totalRefuelingInAllTanks)
              )
            ]);
          }

          // fuel drain
          if (bodyData.hasTwoTanks) {
            items = items.concat([
              reportUtils.renderIfNotZero(
                toFixed(dayDetails.totalFuelDrainInAllTanks)
              ),
              reportUtils.renderIfNotZero(
                toFixed(dayDetails.totalFuelDrainInMainTank)
              ),
              reportUtils.renderIfNotZero(
                toFixed(dayDetails.totalFuelDrainInSecondTank)
              )
            ]);
          } else {
            items = items.concat([
              reportUtils.renderIfNotZero(
                toFixed(dayDetails.totalFuelDrainInAllTanks)
              )
            ]);
          }

          // final fuel amount
          if (bodyData.hasTwoTanks) {
            items = items.concat([
              reportUtils.renderIfNotZero(
                toFixed(dayDetails.finalFuelAmountInAllTanks)
              ),
              reportUtils.renderIfNotZero(
                toFixed(dayDetails.finalFuelAmountInMainTank)
              ),
              reportUtils.renderIfNotZero(
                toFixed(dayDetails.finalFuelAmountInSecondTank)
              )
            ]);
          } else {
            items = items.concat([
              reportUtils.renderIfNotZero(
                toFixed(dayDetails.finalFuelAmountInAllTanks)
              )
            ]);
          }

          // fuel usage
          if (bodyData.hasTwoTanks) {
            items = items.concat([
              reportUtils.renderIfNotZero(
                toFixed(dayDetails.totalFuelUsageInAllTanks)
              ),
              reportUtils.renderIfNotZero(
                toFixed(dayDetails.totalFuelUsageInMainTank)
              ),
              reportUtils.renderIfNotZero(
                toFixed(dayDetails.totalFuelUsageInSecondTank)
              )
            ]);
          } else {
            items = items.concat([
              reportUtils.renderIfNotZero(
                toFixed(dayDetails.totalFuelUsageInAllTanks)
              )
            ]);
          }

          // mileage
          items.push(reportUtils.renderIfNotZero(
            Math.round(dayDetails.mileage * 10) / 10
          ));

          // average fuel usage
          items.push(
            reportUtils.renderIfNotZero(
              reportUtils.formatFuelAmount(dayDetails.averageFuelUsage)
            )
          );

          // engine
          if (dayDetails.engineOperationDuration > 0) {
            items.push(reportUtils.formatDurationInSeconds(dayDetails.engineOperationDuration));
          } else {
            items.push('');
          }

          return items;
        });
      }

      function getFooterCells(data) {
        const footerData = dataBuilder.getMainTableFooterData(data);

        let items = [];

        // ==================
        // 1st column under "Date"
        // ==================
        items.push($translate.instant('global.total'));

        // ==================
        // under "Initial fuel amount"
        // ==================
        items = items.concat([
          ''
        ]);

        if (footerData.hasTwoTanks) {
          items[items.length - 1] = {
            text: items[items.length - 1],
            colSpan: 3
          };

          items = items.concat([
            '',
            ''
          ]);
        }

        // ==================
        // "Refueling" column
        // ==================
        if (footerData.hasTwoTanks) {
          items = items.concat([
            reportUtils.renderIfNotZero(
              toFixed(footerData.totalRefuelingInAllTanks)
            ),
            reportUtils.renderIfNotZero(
              toFixed(footerData.totalRefuelingInMainTank)
            ),
            reportUtils.renderIfNotZero(
              toFixed(footerData.totalRefuelingInSecondTank)
            )
          ]);
        } else {
          items = items.concat([
            reportUtils.renderIfNotZero(
              toFixed(footerData.totalRefuelingInAllTanks)
            )
          ]);
        }

        // ==================
        // "Fuel drain" column
        // ==================
        if (footerData.hasTwoTanks) {
          items = items.concat([
            reportUtils.renderIfNotZero(
              toFixed(footerData.totalFuelDrainInAllTanks)
            ),
            reportUtils.renderIfNotZero(
              toFixed(footerData.totalFuelDrainInMainTank)
            ),
            reportUtils.renderIfNotZero(
              toFixed(footerData.totalFuelDrainInSecondTank)
            )
          ]);
        } else {
          items = items.concat([
            reportUtils.renderIfNotZero(
              toFixed(footerData.totalFuelDrainInAllTanks)
            )
          ]);
        }

        // ==================
        // under "Final fuel amount"
        // ==================
        items = items.concat([
          ''
        ]);

        if (footerData.hasTwoTanks) {
          items[items.length - 1] = {
            text: items[items.length - 1],
            colSpan: 3
          };

          items = items.concat([
            '',
            ''
          ]);
        }

        // ==================
        // "Fuel usage" column
        // ==================
        if (footerData.hasTwoTanks) {
          items = items.concat([
            reportUtils.renderIfNotZero(
              toFixed(footerData.totalFuelUsageInAllTanks)
            ),
            reportUtils.renderIfNotZero(
              toFixed(footerData.totalFuelUsageInMainTank)
            ),
            reportUtils.renderIfNotZero(
              toFixed(footerData.totalFuelUsageInSecondTank)
            )
          ]);
        } else {
          items = items.concat([
            reportUtils.renderIfNotZero(
              toFixed(footerData.totalFuelUsageInAllTanks)
            )
          ]);
        }

        // ==================
        // "Mileage" column
        // ==================
        items.push(reportUtils.renderIfNotZero(
          Math.round(footerData.totalMileage * 10) / 10
        ));

        // ==================
        // "Average fuel usage" column
        // ==================
        items.push(reportUtils.formatFuelAmount(footerData.averageFuelUsage));

        // ==================
        // "Engine operation duration" column
        // ==================
        items.push(reportUtils.formatDurationInSeconds(footerData.totalEngineOperationDuration));

        return items;
      }
    }
  }

  function toFixed(number) {
    return Math.round(number * 100) / 100;
  }

  function formatDate(date, separator = '.') {
    return [
      `${date.getDate()}`.padStart(2, '0'),
      `${date.getMonth() + 1}`.padStart(2, '0'),
      date.getFullYear()
    ].join(separator);
  }
})();
