(function () {
  "use strict";

  angular
    .module('app')
    .factory('LoadScriptService', LoadScriptService)
  ;

  LoadScriptService.$inject = ['$q'];

  function LoadScriptService($q) {
    var service = {},
      loadedScripts = [],
      url = {
        d3: "https://d3js.org/d3.v5.min.js",
        c3: "https://cdnjs.cloudflare.com/ajax/libs/c3/0.7.20/c3.min.js",
        highcharts1: "https://code.highcharts.com/stock/highstock.js",
        highcharts2: "https://code.highcharts.com/stock/modules/data.js",
        highcharts3: "https://code.highcharts.com/stock/modules/exporting.js"
      },
      defer = {
        d3: $q.defer(),
        c3: $q.defer(),
        highcharts1: $q.defer(),
        highcharts2: $q.defer(),
        highcharts3: $q.defer(),
      }
    ;

    function detectScript(url) {
      return loadedScripts.indexOf(url) != -1;
    }

    function loadScript(url, defer) {
      if (detectScript(url)) return;
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = 'async';
      script.src = url;

      script.onload = script.onreadystatechange = function() {
        if (!script.readyState || /loaded|complete/.test(script.readyState)) {
          script.onload = script.onreadystatechange = null;
          defer.resolve();
        }
      };

      script.onerror = function () {
        loadedScripts.splice(loadedScripts.indexOf(url), 1);
        defer.reject({
          msg: "Not load script" + url,
          url: url
        });
      };

      loadedScripts.push(url);
      document.getElementsByTagName('head')[0].appendChild(script);
    }

    service.loadD3 = function () {
      loadScript(url.d3, defer.d3);
      return defer.d3.promise;
    };

    service.loadC3 = function () {
      loadScript(url.c3, defer.c3);
      return defer.c3.promise;
    };

    service.loadHighcharts = function () {
     /* loadScript(url.highcharts1, defer.highcharts1);
      loadScript(url.highcharts2, defer.highcharts2);
      loadScript(url.highcharts3, defer.highcharts3);
      return $q.all([defer.highcharts1.promise, defer.highcharts2.promise, defer.highcharts3.promise]);*/
    };

    return service;
  }

})();
