(function () {
  "use strict";

  angular
    .module('app.logistics')
    .component('logisticsCreateRoute', {
      bindings: {
        route: '<',
        cars: '<'
      },
      templateUrl: 'app/logistics/create-route/logistics.create-route.html',
      controller: LogisticsCreateRouteCtrl,
      controllerAs: 'vm'
    });

  LogisticsCreateRouteCtrl.$inject = ['$scope', '$filter', 'Session', '$q', '$state', '$mdDialog', 'BackendApi', 'MapOsmService'];

  function LogisticsCreateRouteCtrl($scope, $filter, Session, $q, $state, $mdDialog, BackendApi, MapOsmService) {

    var vm = this;
    var DEFAULT_PARKING_TIME = 10;

    vm.$onInit = function () {
      vm.showLoader = false;
      vm.dateNow = Math.floor(Date.now() /1000);
      vm.loadedFile = null;
      vm.routePoints = [];
      vm.drawnPoints = [];
      vm.pointForSettingCoordinates = null;
      vm.mapCenteredPoint = null;
      vm.isOptimalBlocked = true;
      vm.shouldCalculateComplexity = false;
      vm.isComplexityAvailable = false;

      vm.carColors = [
        '#4CAF50',
        '#2196F3',
        '#f44336',
        '#FFEB3B',
        '#795548',
        '#673AB7',
        '#E91E63',
        '#FF5722',
        '#C6FF00',
        '#1DE9B6',
        '#607D8B',
        '#9E9E9E'
      ];

      vm.customPointFocus = null;
      vm.selectionCarIndex = null;
      vm.selectedRouteCarIndex = 0;
      vm.zones = angular.copy(Session.user.zones);
      vm.zonesPolygons = _.map(vm.zones, function (zone) {
        return L.polygon(zone.coordinates);
      });
      vm.canSave = false;
      vm.canBuild = false;
      initCustomPoint();
      MapOsmService.hideZones();
      MapOsmService.clear();
      MapOsmService.onMapClick(handleMapClick);
      MapOsmService.setOnDrawCreatedHandler(onDrawCreated);
      MapOsmService.showEditInterface({
        polyline: false,
        polygon: false,
        circle: false,
        rectangle: true,
        marker: false
      });

    

      // clone route
      if ($state.params.clone === 'true' && vm.dateNow >= vm.route.dateTime) {
        vm.route.dateTime = Math.floor((Date.now() /1000) + 3600);
      }

      // edit route
      if (vm.route) {
        vm.isOptimalRoute = false;
        var car = _.find(vm.cars, function (car) {
          return +car.id === vm.route.carId;
        });
        var points = JSON.parse(vm.route.data);
        _.each(points, function (point, index) {
          point.carIndex = 0;
          point.parkingTime = _.isNumber(point.parkingTime) ? point.parkingTime : DEFAULT_PARKING_TIME;
          point.hasCoordinates = true;
          if (index === 0) {
            point.type = 'startPoint';
          } else if (index === points.length - 1) {
            point.type = 'endPoint';
          }
          setPointMarker(point);
        });
        var startPoint = points.shift();
        var endPoint = points.pop();
        var startDate = new Date(vm.route.dateTime * 1000);
        var startTime = new Date(vm.route.dateTime * 1000);


        vm.routePoints = points;

        vm.routeCars = [
          {
            car: car,
            startPoint: startPoint,
            endPoint: endPoint,
            startDate: startDate,
            startTime: startTime,
            route: null,
            control: null,
            sortedWaypoints: null,
            totalDistance: null,
            formattedTotalTime: null,
            formattedStartTime: null,
            formattedEndTime: null,
            totalPointsWeight: 0,
            totalPointsPrice: 0,
            timeCorrectionInCity: vm.route.timeCorrectionInCity || 100,
            timeCorrectionOutsideCity: vm.route.timeCorrectionOutsideCity || 100,
            routeComplexity: 0,
          }
        ];
        calculateTotalPointsWeight();
        calculateTotalPointsPrice();
        vm.drawPoints(true);

      // creating new route
      } else {
        vm.isOptimalRoute = true;
        vm.routeCars = [getNewRouteCar()];
      }

      vm.onRouteDataChanged();
    };

    vm.$onDestroy = function () {
      MapOsmService.clearRouteMarkers();
      MapOsmService.setOnDrawCreatedHandler(null);
      MapOsmService.hideEditInterface();
      MapOsmService.showZones();
      MapOsmService.offMapClick(handleMapClick);
      removeTempCustomPoints();
      removeCarRoutes();
    };

    vm.addRouteCar = function () {
      vm.routeCars.push(getNewRouteCar());
      vm.onRouteDataChanged();
    };

    vm.drawPoints = function (needToCenterMap) {
      MapOsmService.clearRouteMarkers();

      vm.drawnPoints = [];

      _.each(vm.routePoints, function (point) {
        vm.drawnPoints.push(point);
      });
      _.each(vm.routeCars, function (car) {
        if (car.startPoint) {
          vm.drawnPoints.unshift(car.startPoint);
        }

        if (car.endPoint) {
          vm.drawnPoints.push(car.endPoint);
        }
      });
      MapOsmService.drawLogisticRouteMarkers(vm.drawnPoints, needToCenterMap);
    };

    vm.buildRoutes = function () {
      vm.showLoader = true;

      var iterator = 0;

      _.each(vm.routeCars, function (routeCar, index) {
        iterator++;
        var carPoints = _.filter(vm.routePoints, {carIndex: index});
        carPoints.unshift(routeCar.startPoint);
        carPoints.push(routeCar.endPoint);

        var onTripCreated = function (tripResult) {
          iterator--;
          routeCar.route = tripResult.routes[0];
          routeCar.sortedWaypoints = routeCar.route.inputWaypoints;
          updatedFormattedTime(index);

          _.each(routeCar.sortedWaypoints, function (waypoint, waypointIndex) { // sort-waypoints
            var routePoint = _.find(carPoints, function (point) {
              return waypoint.latLng.lat === point.lat && waypoint.latLng.lng === point.lng && !_.isNumber(point.routeIndex);
            });
            if (routePoint) {
              routePoint.routeIndex = waypointIndex;
              vm.updatePointIcon(routePoint);
            }
          });

          if (iterator === 0) {
            sortRouteAndDrawnPoints();
            vm.showLoader = false;
            vm.canBuild = true;
            vm.canSave = canSaveRoutes();
            $scope.$digest();
          }
        };

        routeCar.control = MapOsmService.addRoute({
          serviceType: vm.isOptimalRoute ? 'trip' : 'route',
          points: carPoints,
          color: vm.carColors[index],
          shouldDrawMarkers: false,
          onTripCreated: onTripCreated
        });
      });
    };

    vm.viewPoint = function (point) {
      if (point === vm.mapCenteredPoint) {
        vm.centerMapOnPoints();
      } else {
        if (vm.mapCenteredPoint) {
          vm.updatePointIcon(vm.mapCenteredPoint);
        }
        vm.mapCenteredPoint = point;
        vm.updatePointIcon(vm.mapCenteredPoint, '#6D0C74');
        MapOsmService.centerMapOnMarker(point.lat, point.lng);
      }
    };

    vm.centerMapOnPoints = function () {
      if (vm.mapCenteredPoint) {
        vm.updatePointIcon(vm.mapCenteredPoint);
        vm.mapCenteredPoint = null;
      }
      MapOsmService.centerMapByPoints(vm.drawnPoints);
    };

    vm.removePoint = function (point) {
      vm.routePoints = _.without(vm.routePoints, point);
      if (vm.isStartOrEndPoint(point)) {
        vm.routeCars[point.carIndex][point.type] = null;
      }
      if (point === vm.pointForSettingCoordinates) {
        vm.cancelSettingPointCoordinates();
      }
      vm.drawPoints();
      vm.onRouteDataChanged();
      calculateTotalPointsWeight();
      calculateTotalPointsPrice();
    };

    vm.removeUndeterminatedPoints = function () {
      vm.routePoints = _.filter(vm.routePoints, function (point) {
        if (!point.hasCoordinates) {
          if (vm.isStartOrEndPoint(point)) {
            vm.routeCars[point.carIndex][point.type] = null;
          }
          if (point === vm.pointForSettingCoordinates) {
            vm.cancelSettingPointCoordinates();
          }
          return false;
        }
        
        return true;
      });

      vm.drawPoints();
      vm.onRouteDataChanged();
      calculateTotalPointsWeight();
      calculateTotalPointsPrice();
    };

    

    vm.updatePointIcon = function (point, color) {
      if (point.hasCoordinates) {
        var text = null;

        switch (point.type) {
          case 'startPoint':
            text = 'S';
            break;
          case 'endPoint':
            text = 'F';
            break;
          default:
            text = point.routeIndex || '';
        }

        var isPointHidden = vm.routeCars[point.carIndex] ? vm.routeCars[point.carIndex].isPointsHidden : false;
        var icon = getMarkerIcon(color || vm.carColors[point.carIndex] || '#BDBDBD', text);
        point.marker.setIcon(icon);

        if (isPointHidden) {
          point.marker.setOpacity(0);
        } else {
          point.marker.setOpacity(100);
        }
      }
    };

    vm.onChangeInput = function (event) {
      if (event.files && event.files.length) {
        vm.showLoader = true;
        Papa.parse(event.files[0], {
          encoding: 'Windows-1251',
          complete: function (result) {
            if (result && result.data && result.data.length) {
              var fileFieldsIndex = {},
                promises = [];
              _.each(result.data[0], function (value, index) { // file header
                fileFieldsIndex[value] = index;
              });
              _.each(result.data.slice(1), function (row) {
                if (row.length > 1) {
                  var curentParkingTime = vm.routeCars[vm.selectedRouteCarIndex].parkingTime;
                  var point = {
                    companyId: row[fileFieldsIndex['Comp_Id']],
                    companyName: row[fileFieldsIndex['Comp_Name']],
                    name: row[fileFieldsIndex['Comp_Name']],
                    address: row[fileFieldsIndex['Address']],
                    lat: normalizeCoordinate(row[fileFieldsIndex['lat']]),
                    lng: normalizeCoordinate(row[fileFieldsIndex['lng']]),
                    requestNumber: row[fileFieldsIndex['Request_Num']],
                    barcode: row[fileFieldsIndex['Bar_Code']],
                    weight: row[fileFieldsIndex['QtyW']],
                    quantity: row[fileFieldsIndex['Qty']],
                    note: row[fileFieldsIndex['Note']],
                    signature: row[fileFieldsIndex['Signature']],
                    price: row[fileFieldsIndex['Price']],
                    isCustom: false,
                    carIndex: vm.selectedRouteCarIndex,
                    parkingTime: _.isNumber(curentParkingTime) ? curentParkingTime : DEFAULT_PARKING_TIME
                  };
                  point.hasCoordinates = !!(point.lat && point.lng);
                  if (point.hasCoordinates) {
                    point.isDraggable = false;
                    setPointMarker(point)
                  } else {
                    point.isDraggable = true;
                    point.isHadNotCoordinates = true;
                    promises.push(detectPointCoordinates(point));
                  }
                  vm.routePoints.push(point);
                }
              });
              $q.all(promises).then(function () {
                vm.drawPoints(true);
                vm.showLoader = false;
                vm.onRouteDataChanged();
                calculateTotalPointsWeight();
                calculateTotalPointsPrice();
              });
            } else {
              vm.showLoader = false;
            }
          }
        });
      }
    };

    vm.selectCarPointsOnMap = function (carIndex) {
      if (vm.selectionCarIndex === carIndex) {
        vm.selectionCarIndex = null;
        document.querySelector('.leaflet-draw-actions li a').click();
      } else {
        vm.selectionCarIndex = carIndex;
        vm.centerMapOnPoints();
        document.querySelector('.leaflet-draw-draw-rectangle').click();
      }
    };

    vm.clearCarPoints = function (carIndex) {
      _.each(vm.routePoints, function (routePoint) {
        if (routePoint.marker && routePoint.carIndex === carIndex) {
          routePoint.carIndex = null;
          vm.updatePointIcon(routePoint);
        }
      });
      vm.onRouteDataChanged();
      calculateTotalPointsWeight();
      calculateTotalPointsPrice();
    };

    vm.removeCar = function (carIndex) {
      vm.clearCarPoints(carIndex);

      vm.routeCars.splice(carIndex, 1);

      // need to change index of points of next cars
      _.each(vm.routePoints, function (routePoint) {
        if (routePoint.marker && routePoint.carIndex > carIndex) {
          routePoint.carIndex--;
          vm.updatePointIcon(routePoint);
        }
      });
      
      vm.selectedRouteCarIndex = 0;
      vm.onRouteDataChanged();
    };

    vm.toggleCarPointsVisibility = function (carIndex) {
      vm.routeCars[carIndex].isPointsHidden = !vm.routeCars[carIndex].isPointsHidden;
      _.each(vm.routePoints, function (routePoint) {
        if (routePoint.marker && routePoint.carIndex === carIndex) {
          vm.updatePointIcon(routePoint);
        }
      });
    };

    vm.setPointCoordinates = function (point) {
      vm.setCustomPointFocus(null);
      vm.pointForSettingCoordinates = point;

      var addressParts = point.address.split(',');
      if (addressParts.length === 10) {
        var city = addressParts[4].trim();
        var street = addressParts[6].trim();

        vm.showLoader = true;
        BackendApi.getLocationsByAddress(city + ' ' + street)
          .then(function (locations) {
            if (locations.length) {
              var location = locations[0];
              MapOsmService.centerMapOnMarker(+location.lat, +location.lon);
              vm.showLoader = false;
            } else {
              BackendApi.getLocationsByAddress(city)
                .then(function (locations) {
                  if (locations.length) {
                    var location = locations[0];
                    MapOsmService.centerMapOnMarker(+location.lat, +location.lon);
                  }
                  vm.showLoader = false;
                });
            }
          });
      }
    };

    vm.cancelSettingPointCoordinates = function () {
      vm.pointForSettingCoordinates = null;
    };

    vm.saveRoutes = function () {
      var promises = [];
      vm.showLoader = true;

      _.each(vm.routeCars, function (routeCar, index) {
        var startDateTime = getStartDateTime(routeCar),
          selectedPoints = _.filter(vm.drawnPoints, {carIndex: index});

        promises.push(addPointsAsZones(selectedPoints));

        selectedPoints = _.map(selectedPoints, function (selectedPoint) {
          return _.pick(selectedPoint, 'companyId', 'companyName', 'name', 'address', 'lat', 'lng', 'requestNumber', 'weight', 'quantity', 'note', 'isCustom', 'zoneCoordinates', 'parkingTime', 'signature', 'price', 'barcode');
        });

        var newRoute = {
          carId: +routeCar.car.id,
          title: startDateTime.format('DD.MM.YYYY') + '_' + routeCar.car.name,
          dateTime: startDateTime.unix(),
          data: JSON.stringify(selectedPoints),
          isAccepted: 0,
          timeCorrectionInCity: routeCar.timeCorrectionInCity,
          timeCorrectionOutsideCity: routeCar.timeCorrectionOutsideCity,
          routeComplexity: vm.shouldCalculateComplexity ? routeCar.routeComplexity : 0,
        };

        if ((vm.route && index === 0) && !$state.params.clone) {
          newRoute.id = vm.route.id;
        }

        promises.push(BackendApi.saveRoute(newRoute));
      });

      $q.all(promises).then(function () {
        BackendApi.getProfile().then(function () {
          vm.showLoader = false;
          $state.go('logistics.routes');
        });
      });
    };

    vm.searchLocations = function (query) {
      return BackendApi.getLocationsByAddress(query);
    };

    vm.setCustomPointFocus = function (name) {
      if (vm.customPointFocus === name) {
        vm.customPointFocus = null;
      } else {
        vm.customPointFocus = name;
      }
      initCustomPoint();
    };

    vm.addZone = function () {
      var coordinates = vm.customPoint.tempZoneMarker.getLatLng();
      var curentParkingTime = vm.routeCars[vm.selectedRouteCarIndex].parkingTime;
      var point = {
        name: vm.customPoint.zone.name,
        isCustom: true,
        carIndex: vm.selectedRouteCarIndex,
        hasCoordinates: true,
        lat: coordinates.lat,
        lng: coordinates.lng,
        isDraggable: false,
        marker: vm.customPoint.tempZoneMarker,
        parkingTime: _.isNumber(curentParkingTime) ? curentParkingTime : DEFAULT_PARKING_TIME
      };
      addPointStringAddress(point);

      if (vm.customPointFocus === null || vm.customPointFocus === 'newPoint') {
        vm.routePoints.push(point);
      } else {
        var customPointInfo = vm.customPointFocus.split('.'),
          carIndex = +customPointInfo[0],
          destination = customPointInfo[1];

        point.type = destination;
        vm.updatePointIcon(point);

        this.routeCars[carIndex][destination] = point;
      }

      vm.setCustomPointFocus(null);
      vm.drawPoints();
      vm.onRouteDataChanged();
    };

    vm.onZoneChanged = function () {
      if (vm.customPoint.tempZoneMarker) {
        MapOsmService.removeMarkerFromMap(vm.customPoint.tempZoneMarker);
        vm.customPoint.tempZoneMarker = null;
      }

      if (vm.customPoint.zone) {
        var coordinates = getCentroid(vm.customPoint.zone.coordinates),
          lat = coordinates[0],
          lng = coordinates[1],
          icon = getCarMarkerIcon(vm.selectedRouteCarIndex);

        vm.customPoint.tempZoneMarker = L.marker([lat, lng], {icon: icon});
        MapOsmService.addMarkerToMap(vm.customPoint.tempZoneMarker);
        MapOsmService.centerMapOnMarker(lat, lng);
      }
    };

    vm.onCustomPointLocationChanged = function () {
      if (vm.customPoint.tempLocationMarker) {
        MapOsmService.removeMarkerFromMap(vm.customPoint.tempLocationMarker);
        vm.customPoint.tempLocationMarker = null;
      }

      if (vm.customPoint.selectedLocation) {
        var lat = vm.customPoint.selectedLocation.lat,
          lng = vm.customPoint.selectedLocation.lon,
          icon = getCarMarkerIcon(vm.selectedRouteCarIndex);

        if (!vm.pointForSettingCoordinates) { //  не додаем маркер если в это время мы уточняем координаты, просто маштабируем и все
          vm.customPoint.tempLocationMarker = L.marker([lat, lng], {icon: icon, draggable: true});
          MapOsmService.addMarkerToMap(vm.customPoint.tempLocationMarker);
        }
        MapOsmService.centerMapOnMarker(lat, lng);
      }
    };

    vm.addCustomPoint = function () {
      var coordinates = vm.customPoint.tempLocationMarker.getLatLng();
      var currentParkingTime = vm.routeCars[vm.selectedRouteCarIndex].parkingTime;

      var point = {
        name: vm.customPoint.name,
        isCustom: true,
        carIndex: vm.selectedRouteCarIndex,
        hasCoordinates: true,
        lat: coordinates.lat,
        lng: coordinates.lng,
        isDraggable: true,
        parkingTime: _.isNumber(currentParkingTime) ? currentParkingTime : DEFAULT_PARKING_TIME
      };
      setPointMarker(point);
      addPointStringAddress(point);

      if (vm.customPointFocus === null || vm.customPointFocus === 'newPoint') {
        vm.routePoints.push(point);
      } else {
        var customPointInfo = vm.customPointFocus.split('.'),
          carIndex = +customPointInfo[0],
          destination = customPointInfo[1];

        point.type = destination;
        vm.updatePointIcon(point);
        this.routeCars[carIndex][destination] = point;
      }

      vm.setCustomPointFocus(null);
      vm.drawPoints();
      vm.onRouteDataChanged();
    };

    vm.setCustomPointMapClickWatcher = function () {
      vm.customPoint.isWatchingMapClick = !vm.customPoint.isWatchingMapClick;
    };

    vm.onCarChanged = function (routeCar) {
      if (routeCar.car) {
        var startPoint, endPoint;
        if (routeCar.car.startZoneId) {
          var startZone = _.find(vm.zones, function (item) {
            return item.id === routeCar.car.startZoneId;
          });
          if (startZone) {
            var startCoordinates = getCentroid(startZone.coordinates);
            startPoint = {
              name: startZone.name,
              isCustom: true,
              carIndex: vm.selectedRouteCarIndex,
              hasCoordinates: true,
              lat: startCoordinates[0],
              lng: startCoordinates[1],
              isDraggable: false,
              type: 'startPoint'
            };
            setPointMarker(startPoint)
            addPointStringAddress(startPoint);

            routeCar.startPoint = startPoint;
          }
        }
        if (routeCar.car.endZoneId) {
          var endZone = _.find(vm.zones, function (item) {
            return item.id === routeCar.car.endZoneId;
          });
          if (endZone) {
            var endCoordinates = getCentroid(endZone.coordinates);
            endPoint = {
              name: endZone.name,
              isCustom: true,
              carIndex: vm.selectedRouteCarIndex,
              hasCoordinates: true,
              lat: endCoordinates[0],
              lng: endCoordinates[1],
              isDraggable: false,
              type: 'endPoint'
            };
            setPointMarker(endPoint)
            addPointStringAddress(endPoint);

            routeCar.endPoint = endPoint;
          }
        }

        if (startPoint || endPoint) {
          vm.drawPoints();
        }
      }
      vm.onRouteDataChanged();
    };

    vm.onRouteDataChanged = function () {
      if (vm.canBuild) {
        vm.canBuild = false;
        vm.canSave = false;
        removeCarRoutes();
        clearRoutesInfo();
        _.each(vm.drawnPoints, function (drawnPoint) {
          drawnPoint.routeIndex = null;
          vm.updatePointIcon(drawnPoint);
        });
      }
      if (canBuildRoutes()) {
        vm.buildRoutes();
      }
    };

    vm.onDateTimeChanged = function (carIndex) {
      vm.canSave = canSaveRoutes();
      updatedFormattedTime(carIndex);
    };

    vm.onCarParkingTimeChange = function (carIndex) {
      var parkingTime = vm.routeCars[carIndex].parkingTime = Number(vm.routeCars[carIndex].parkingTime);
      var carPoints = _.filter(vm.routePoints, {carIndex: carIndex});
      _.each(carPoints, function (point) {
        point.parkingTime = parkingTime;
      });
      updatedFormattedTime(carIndex);
    };

    vm.onPointParkingTimeChange = function (point) {
      point.parkingTime = Number(point.parkingTime);
      updatedFormattedTime(point.carIndex);
    };

    vm.onTimeCorrectionTimeChange = function (carIndex) {
      updatedFormattedTime(carIndex);
    };

    vm.onPointCarChanged = function (point) {
      vm.updatePointIcon(point);
      vm.onRouteDataChanged();
      calculateTotalPointsWeight();
      calculateTotalPointsPrice();
    };

    vm.onIsOptimalChange = function () {
      vm.onRouteDataChanged();
      vm.isOptimalBlocked = true;
    };

    vm.revertCarPoints = function (carIndex) {
      var carPoints = _.filter(vm.routePoints, {carIndex: carIndex});
      vm.routePoints = _.difference(vm.routePoints, carPoints);
      carPoints.reverse();
      vm.routePoints = vm.routePoints.concat(carPoints);
      vm.onRouteDataChanged();
    };

    vm.movePointUp = function (point) {
      var pointIndex = _.findIndex(vm.routePoints, point);
      if (pointIndex) {
        arrayMove(vm.routePoints, pointIndex, pointIndex - 1);
        vm.onRouteDataChanged();
      }
    };

    vm.movePointDown = function (point) {
      var pointIndex = _.findIndex(vm.routePoints, point);
      if (pointIndex < vm.routePoints.length - 1) {
        arrayMove(vm.routePoints, pointIndex, pointIndex + 1);
        vm.onRouteDataChanged();
      }
    };

    vm.movePointToPosition = function (point) {

      if (point.desiredPosition) {
        var pointIndex = _.findIndex(vm.routePoints, point);
        var newPosition = point.desiredPosition - 1; // in programming countiong starts from 0

        if (
          newPosition !== pointIndex &&
          newPosition >= 0 &&
          newPosition < vm.routePoints.length) {

          arrayMove(vm.routePoints, pointIndex, newPosition);
          point.desiredPosition = undefined;
          vm.onRouteDataChanged();
        }
      }
    };

    vm.canMovePoint = function (point) {
      return !vm.isOptimalRoute && !vm.isStartOrEndPoint(point);
    };

    vm.isStartOrEndPoint = function (point) {
      return point.type === 'startPoint' || point.type === 'endPoint';
    };

    vm.switchOptimalBlock = function () {
      vm.isOptimalBlocked = !vm.isOptimalBlocked;
    };

    function updatedFormattedTime(carIndex) {
      var routeCar = vm.routeCars[carIndex];
      if (routeCar.control && routeCar.startDate && routeCar.startTime) {
        var timeCorrectionInCity = routeCar.timeCorrectionInCity || 100;
        var timeCorrectionOutsideCity = routeCar.timeCorrectionOutsideCity || 100;
        var summary = routeCar.route.summary,
          totalMinutes = summary.formattedTotalTime / 60,
          correctionTime = totalMinutes > 30 ? timeCorrectionOutsideCity : timeCorrectionInCity, // use city correction if total time less 30m
          totalTime = moment.utc(summary.formattedTotalTime * 1000 * correctionTime / 100),
          startTime = getStartDateTime(routeCar);
        var parkingTimeMinutes = getParkingTimeMinutes(carIndex);

        totalTime.add(parkingTimeMinutes, 'minutes');
        routeCar.totalDistance = Math.round(summary.totalDistance / 1000);
        routeCar.formattedTotalTime = moment.duration(+totalTime).format("h:mm");
        routeCar.formattedStartTime = startTime.format('DD.MM.YYYY HH:mm');
        routeCar.formattedEndTime = startTime
          .add(totalTime.date() - 1, 'days')
          .add(totalTime.hours(), 'hours')
          .add(totalTime.minutes(), 'minutes').format('DD.MM.YYYY HH:mm');

        var totalHours = moment.duration(+totalTime).asHours();
        var totalPointsCount = routeCar.sortedWaypoints.length - 2;

        routeCar.routeComplexity =
          (
            (totalHours * 0.1) *
            (totalPointsCount * 0.2) *
            (routeCar.totalPointsWeight * 0.4) *
            (routeCar.totalPointsPrice * 0.3) *
            (routeCar.totalDistance * 0.3) /
            100000000
          ).toFixed(0);
      } else {
        routeCar.totalDistance = null;
        routeCar.formattedTotalTime = null;
        routeCar.formattedStartTime = null;
        routeCar.formattedEndTime = null;
        routeCar.routeComplexity = null;
      }
    }

    function getParkingTimeMinutes(carIndex) {
      var carPoints = _.filter(vm.routePoints, {carIndex: carIndex});
      var parkingTime = 0;
      _.each(carPoints, function (point) {
        parkingTime += point.parkingTime;
      });
      return parkingTime;
    }

    function sortRouteAndDrawnPoints() {
      sortPoints(vm.routePoints);
      sortPoints(vm.drawnPoints);
    }

    function sortPoints(points) {
      points.sort(function (a, b) {
        if (a.carIndex < b.carIndex) {
          return -1;
        }
        if (a.carIndex > b.carIndex) {
          return 1;
        }

        if (a.routeIndex < b.routeIndex) {
          return -1;
        }
        if (a.routeIndex > b.routeIndex) {
          return 1;
        }
        return 0;
      });
    }

    function normalizeCoordinate(degree) {
      return +degree.replace(',', '.');
    }

    function setPointMarker(point) {
      var icon = getCarMarkerIcon(point.carIndex);
      var marker = L.marker([point.lat, point.lng],
        {
          icon: icon,
          draggable: point.isDraggable
        });

      if (point.isDraggable) {
        marker.on('dragend', function (event) {
          var marker = event.target;
          var position = marker.getLatLng();
          point.lat = position.lat;
          point.lng = position.lng;
          vm.onRouteDataChanged();
        });
      }
      point.marker = marker;
    }

    function getCarMarkerIcon(carIndex, text) {
      return getMarkerIcon(vm.carColors[carIndex] || '#BDBDBD', text);
    }

    function getMarkerIcon(color, text) {
      return MapOsmService.getColoredIcon(color, text);
    }

    function handleMapClick(e) {
      if (vm.customPoint.isWatchingMapClick) {
        if (vm.customPoint.tempLocationMarker) {
          MapOsmService.removeMarkerFromMap(vm.customPoint.tempLocationMarker);
          vm.customPoint.tempLocationMarker = null;
        }

        var icon = getCarMarkerIcon(vm.selectedRouteCarIndex);

        vm.customPoint.tempLocationMarker = L.marker([e.latlng.lat, e.latlng.lng], {icon: icon, draggable: true});
        MapOsmService.addMarkerToMap(vm.customPoint.tempLocationMarker);
        vm.customPoint.isWatchingMapClick = false;
        vm.onRouteDataChanged();
        $scope.$digest();
      } else if (vm.pointForSettingCoordinates) {

        vm.pointForSettingCoordinates.hasCoordinates = true;
        vm.pointForSettingCoordinates.lat = e.latlng.lat;
        vm.pointForSettingCoordinates.lng = e.latlng.lng;
        setPointMarker(vm.pointForSettingCoordinates);
        vm.drawPoints();
        vm.cancelSettingPointCoordinates();
        vm.onRouteDataChanged();
        $scope.$digest();
      }
    }

    function detectPointCoordinates(point) {
      return BackendApi.getLocationsByAddress(point.address)
        .then(function (locations) {
          if (locations.length) {
            var location = locations[0];
            point.hasCoordinates = true;
            point.lat = +location.lat;
            point.lng = +location.lon;
            setPointMarker(point);
          } else {
            point.hasCoordinates = false;
          }
        });
    }

    function onDrawCreated(rectangle) {
      var carParkingTime = vm.routeCars[vm.selectedRouteCarIndex].parkingTime;
      var parkingTime = _.isNumber(carParkingTime) ? carParkingTime : DEFAULT_PARKING_TIME;
      _.each(vm.routePoints, function (routePoint) {
        if (routePoint.marker && rectangle.getBounds().contains(routePoint.marker.getLatLng())) {
          
          // selected point should not be hidden
          if (!(vm.routeCars[routePoint.carIndex] && vm.routeCars[routePoint.carIndex].isPointsHidden)) {
            routePoint.carIndex = vm.selectedRouteCarIndex;
            routePoint.parkingTime = routePoint.parkingTime || parkingTime;
            vm.updatePointIcon(routePoint);
          }
        }
      });
      vm.selectionCarIndex = null;
      MapOsmService.cancelCurrentZone();
      vm.onRouteDataChanged();
      calculateTotalPointsWeight();
      calculateTotalPointsPrice();
      $scope.$digest();
    }

    function getStartDateTime(routeCar) {
      var startDateTime = moment(routeCar.startDate);
      startDateTime.hours(routeCar.startTime.getHours());
      startDateTime.minutes(routeCar.startTime.getMinutes());

      return startDateTime;
    }

    function getNewRouteCar() {
      var prevCar = _.last(vm.routeCars),
      startDate = null,
      startTime = null,
      parkingTime = null;

      // copy next params from prev car
      if (prevCar) {
        startDate = prevCar.startDate;
        startTime = prevCar.startTime;
        parkingTime = prevCar.parkingTime;
      }
      return {
        car: null,
        points: [],
        startPoint: null,
        endPoint: null,
        startDate: startDate,
        startTime: startTime,
        route: null,
        control: null,
        sortedWaypoints: null,
        totalDistance: null,
        formattedTotalTime: null,
        formattedStartTime: null,
        formattedEndTime: null,
        totalPointsWeight: 0,
        totalPointsPrice: 0,
        parkingTime: parkingTime,
        isPointsHidden: false,
        timeCorrectionInCity: 100,
        timeCorrectionOutsideCity: 100,
        routeComplexity: null,
      };
    }

    function initCustomPoint() {
      removeTempCustomPoints();
      vm.customPoint = {
        zone: null,
        tempZoneMarker: null,
        selectedLocation: null,
        name: '',
        isWatchingMapClick: false,
        tempLocationMarker: null
      };
    }

    function removeTempCustomPoints() {
      if (vm.customPoint) {
        if (vm.customPoint.tempZoneMarker) {
          MapOsmService.removeMarkerFromMap(vm.customPoint.tempZoneMarker);
          vm.customPoint.tempZoneMarker = null;
        }
        if (vm.customPoint.tempLocationMarker) {
          MapOsmService.removeMarkerFromMap(vm.customPoint.tempLocationMarker);
          vm.customPoint.tempLocationMarker = null;
        }
      }
    }

    function getCentroid(arr) {
      return arr.reduce(function (x, y) {
        return [x[0] + y[0] / arr.length, x[1] + y[1] / arr.length];
      }, [0, 0]);
    }

    function addPointStringAddress(point) {
      vm.showLoader = true;
      BackendApi.getAddressByLocation({
        lat: point.lat,
        lng: point.lng
      }).then(function (address) {
        if (address) {
          point.address = address;
          vm.showLoader = false;
        }
      });
    }

    function addPointsAsZones(points) {
      var promises = [];

      _.each(points, function (point) {
        var existingZone = _.find(vm.zonesPolygons, function (zonePolygon) {
          return zonePolygon.getBounds().contains(L.latLng(point.lat, point.lng));
        });

        if (existingZone) {
          point.zoneCoordinates = existingZone.getLatLngs();
        } else {
          var polygon = MapOsmService.getPolygonByLatLng(point.lat, point.lng),
            polygonCoordinates = MapOsmService.getPolygonCoordinates(polygon),
            wkt = MapOsmService.getWkt(polygonCoordinates),
            zone = {
              login: Session.user.loginName,
              name: point.name,
              color: '#28a745',
              wkt: wkt,
              coordinates: polygonCoordinates,
              days: ''
            };
          vm.zonesPolygons.push(polygon);
          point.zoneCoordinates = polygon.getLatLngs();
          promises.push(BackendApi.saveZone({zone: zone}));
        }
      });

      return $q.all(promises);
    }

    function canBuildRoutes() {
      var uncompletedPoint = _.find(vm.routePoints, function (point) {
        return !point.hasCoordinates || point.carIndex === null;
      });
      if (uncompletedPoint) {
        return false;
      }

      var uncompletedCar = _.find(vm.routeCars, function (item) {
        return !item.car || !item.startPoint || !item.endPoint;
      });

      if (uncompletedCar) {
        return false;
      }

      return true;
    }

    function canSaveRoutes() {
      if (canBuildRoutes()) {
        var carWithoutTime = _.find(vm.routeCars, function (item) {
          return !item.startDate || !item.startTime;
        });

        return !carWithoutTime;
      }

      return false;
    }

    function removeCarRoutes() {
      _.each(vm.routeCars, function (routeCar) {
        if (routeCar.control) {
          MapOsmService.removeControl(routeCar.control);
        }
      });
    }

    function clearRoutesInfo() {
      _.each(vm.routeCars, function (routeCar) {
        routeCar.route = null;
        routeCar.control = null;
        routeCar.sortedWaypoints = null;
        routeCar.totalDistance = null;
        routeCar.formattedTotalTime = null;
        routeCar.formattedStartTime = null;
        routeCar.formattedEndTime = null;
        routeCar.routeComplexity = null;
      });
    }

    function calculateTotalPointsWeight() {
      _.each(vm.routeCars, function (routeCar, index) {
        routeCar.totalPointsWeight = 0;
        var carPoints = _.filter(vm.routePoints, {carIndex: index});

        _.each(carPoints, function (carPoint) {
          if (carPoint.weight) {
            var weight = parseFloat(carPoint.weight.replace(/,/g, '.'));
            if (weight) {
              routeCar.totalPointsWeight += weight;
            }
          }
        });
      });
    }

    function calculateTotalPointsPrice() {
      _.each(vm.routeCars, function (routeCar, index) {
        routeCar.totalPointsPrice = 0;
        var carPoints = _.filter(vm.routePoints, {carIndex: index});

        _.each(carPoints, function (carPoint) {
          if (carPoint.price) {
            var price = parseFloat(carPoint.price.replace(',', '.')
              .replace(' ', '')
              .replace(' ', ''));
            if (price) {
              routeCar.totalPointsPrice += price;
            }
          }
        });
      });
    }

    function arrayMove(arr, fromIndex, toIndex) {
      var element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
    }
  }
})();
