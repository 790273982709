(function () {
  "use strict";

  angular
    .module('app')
    .component('exportBtn', {
      bindings: {
        id: '<',
        cars: '<',
        report: '<',
        filter: '<',
        fuels: '<',
        direction: '@',
        route: '<',
        items: '<'
      },
      templateUrl: 'app/component/exportBtn/exportBtn.html',
      controller: ExportBtnCtrl,
      controllerAs: 'vm'
    });

  ExportBtnCtrl.$inject = ['$element', '$mdDialog', '$filter', '$translate',
    'Session', 'CarService', 'DateUtils', '$rootScope', 'app.reports.events',
    'app.reports.exportTypes', 'app.reports.fuel.print-dialog.FuelReportDialogCtrl'];

  function ExportBtnCtrl($element, $mdDialog, $filter, $translate,
    Session, CarService, DateUtils, $rootScope, events, exportTypes, FuelReportPrintDialogCtrl) {
    this.form = {};
    var self = this, car;
    var date = $filter('translate')('logistics.create-route.startDate');
    var fuelUsage = $filter('translate')('car.totalFuelUsage');
    var averageFuelUsage = $filter('translate')('car.fuelPrint.averageFuelUsage');
    var jsonFields = [date, fuelUsage, averageFuelUsage];
    var btns = [
      {
        btnType: "routeXls",
        title: "exportBtn.loadXLS",
        getTitle: () => $translate.instant('exportBtn.loadXLS'),
        type: "stops",
        subtype: "route",
        format: "xls"
      },{
        btnType: "routeGPX",
        title: "GPX",
        getTitle: () => $translate.instant('GPX')
      },{
        btnType: "mileageXls",
        title: "exportBtn.loadXLS",
        getTitle: () => $translate.instant('exportBtn.loadXLS'),
        type: "distance",
        format: "xls"
      },{
        btnType: "stopsXls",
        title: "exportBtn.loadXLS",
        getTitle: () => $translate.instant('exportBtn.loadXLS'),
        type: "stops",
        subtype: "stops",
        format: "xls"
      },{
        btnType: "stopsGPX",
        title: "Tрек в GPX",
        getTitle: () => $translate.instant('exportBtn.stopsGPX')
      },{
        btnType: "schedule",
        title: "exportBtn.saveAsRoute",
        getTitle: () => $translate.instant('exportBtn.saveAsRoute')
      },{
        btnType: "reportXls",
        title: "exportBtn.loadXLS",
        getTitle: () => $translate.instant('exportBtn.loadXLS'),
        type: "report",
        subtype: "route",
        format: "xls"
      },{
        btnType: "fuelXls",
        title: "exportBtn.loadXLSFuel",
        getTitle: () => $translate.instant('exportBtn.loadXLSFuel'),
        type: "fuel",
        format: "xls"
      },{
        btnType: "fuelPrint",
        title: "exportBtn.printFuel",
        getTitle: () => $translate.instant('exportBtn.printFuel')
      },{
        btnType: "fuelCSV",
        title: "exportBtn.loadFuelCSV",
        getTitle: () => $translate.instant('exportBtn.loadFuelCSV'),
        type: "fuel",
        format: "csv"
      }, {
        btnType: exportTypes.toCSV,
        title: 'Export to CSV',
        getTitle: () => $translate.instant('Export to CSV'),
        export: exportTypes.toCSV
      }, {
        btnType: exportTypes.toXLSX,
        title: 'Export to XLSX',
        getTitle: () => $translate.instant('Export to XLSX'),
        export: exportTypes.toXLSX
      }, {
        btnType: exportTypes.toPDF,
        title: 'Export to PDF',
        getTitle: () => $translate.instant('Export to PDF'),
        export: exportTypes.toPDF
      },
      {
        btnType: exportTypes.fuelReportToXLSX,
        getTitle: () => $translate.instant('Export fuel report to XLSX'),
        export: exportTypes.fuelReportToXLSX
      },
      {
        btnType: exportTypes.fuelReportToPDF,
        getTitle: () => $translate.instant('Export fuel report to PDF'),
        export: exportTypes.fuelReportToPDF
      },
      {
        btnType: exportTypes.fuelReportToPrint,
        getTitle: () => $translate.instant('Print fuel report'),
        export: exportTypes.fuelReportToPrint
      }
    ];

    this.$onInit = function () {
      this.id && (car = _.findWhere(Session.user.cars, {id: this.id.toString()}) || {});

      this.menuItems = this.items.map((item) => {
        if (item === 'separator') {
          return {
            menuItemType: 'separator'
          };
        }

        return Object.assign(
          {},
          btns.filter((obj) => {
            if (typeof item === 'string') {
              return obj.btnType === item;
            }
            return obj.btnType === item.type;
          })[0] || {},
          item,
          {
            menuItemType: 'button'
          }
        );
      });
    };

    this.onSubmit = function (btn) {
      const associatedWithButtonExport = btn.export;
      if (typeof associatedWithButtonExport !== 'undefined') {
        $rootScope.$broadcast(events.export, {
          type: associatedWithButtonExport
        });
        return;
      }

      if ('schedule' == btn.btnType) {
        CarService.showSchedulesDialog();
      } else if ('fuelPrint' == btn.btnType) {
        onShowFuelPrintDialog();
      } else {
        var report = {},
          formId = _.contains(['routeGPX', 'stopsGPX'], btn.btnType) ? ".export-form-gpx" : ".export-form";

        if (btn.btnType === 'fuelCSV') {
         var csvStr = jsonToCSV();
          downloadCSV(csvStr);
          return;
        }

        if (btn.btnType == 'reportXls') {
          _.extend(report, {
            id: _.map(this.cars, function (el) {
              return el.id;
            }).join(","),
            names: _.map(this.cars, function (el) {
              return el.name;
            }).join(";"),
            reportName: this.report,
            limits: DateUtils.joinAllDateForPeriod(this.filter)
          });
        }

        if (btn.btnType == 'mileageXls') {
          mileageToXls();
          return;
        }

        this.form = _.extend(btn, car, report, {
          from: moment(this.filter.from).unix(),
          to: moment(this.filter.to).startOf('minute').unix(),
          lang: Session.lang
        });

        setTimeout(function () {
          $element[0].querySelector(formId).submit();
        }, 100);
      }
    };

    function onShowFuelPrintDialog () {
      $mdDialog.show({
        controllerAs: 'vm',
        controller: FuelReportPrintDialogCtrl,
        templateUrl: 'app/component/exportBtn/fuel-report/print-dialog/tpl.html',
        fullscreen: true,
        locals: {
          resolveData: {
            filter: self.filter,
            car: car,
            stops: self.route.stops
          }
        }
      });
    }

    function jsonToCSV() {
     var csvStr = jsonFields.join(",") + "\n";

      _.each(self.fuels, function (element) {
       var date = element.date;
       var totalFuelUsage = element.totalFuelUsage;
       var averageFuelUsage = element.averageFuelUsage;

        csvStr += date + "," + totalFuelUsage + "," + averageFuelUsage + "\n";
      });

      return csvStr;
    }

    function downloadCSV(csvStr) {
      var hiddenElement = document.createElement("a");
      hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csvStr);
      hiddenElement.target = "_blank";
      hiddenElement.download = "fuels.csv";
      hiddenElement.click();
    }

    function mileageToXls() {
      var wb = XLSX.utils.book_new();
      wb.Props = {
        Title: 'Mileage Report',
        Subject: 'Mileage',
        Author: 'TransGPS',
        CreatedDate: new Date()
      };

      wb.SheetNames.push('Mileage Sheet');
      var wsData = _.sortBy(self.report.data, function (i) {
        return new Date(i[0]);
      });
      var ws = XLSX.utils.aoa_to_sheet(wsData);
      wb.Sheets['Mileage Sheet'] = ws;
      var wbout = XLSX.write(wb, {bookType: 'xls', type: 'binary'});

      saveAs(new Blob([s2ab(wbout)], {type: "application/octet-stream"}), self.report.name + '.xls');
    }

    function s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i < s.length; i++) {
        view[i] = s.charCodeAt(i) & 0xff;
      }
      return buf;
    }
  }
})();
