(() => {
  angular
    .module('app.vehicles')
    .component('fuelReportViewAccordionContent', {
      controller: Ctrl,
      controllerAs: 'ctrl',
      templateUrl: 'app/vehicles/details/lib/with-fuel-report-view/fuel-report-view/accordion-content/tpl.html',
      bindings: {
        dayDetails: '<',
        withRouteColor: '<',
        getRouteEventColor: '<',
        getDayRouteDetails: '<'
      }
    });

  function Ctrl() {
    this.$onChanges = () => {
      this.routeDetails = this.getDayRouteDetails(this.dayDetails);
    }
  }
})();
