(function () {
  "use strict";

  angular
    .module('app.vehicles', [
      'fuel-consumption-metering-settings',
      'routeSummaryDetails',
      'app.vehicles.details.lib.fuel-calculations',
      'app.vehicles.details.lib.period-report-builder'
    ])
    .config(vehiclesRoutesConfig);

  vehiclesRoutesConfig.$inject = [
    '$stateProvider'
  ];

  function vehiclesRoutesConfig($stateProvider) {
    $stateProvider
      .state('vehicles', {
        parent: 'main',
        redirectTo: 'vehicles.all',
        component: 'vehicles',
        resolve: {},
      })
      .state('vehicles.all', {
        parent: 'vehicles',
        url: '/vehicles',
        component: 'vehiclesAll',
        /*resolve: {
          vehicles: ['BackendApi', function (BackendApi) {
            return BackendApi.getCarNow(0);
          }]
        }*/
      })
      .state('vehicles.details', {
        parent: 'vehicles',
        component: 'vehiclesDetails',
      })
      .state('vehicles.details.now', {
        parent: 'vehicles.details',
        url: '/vehicles/:id/now',
        resolve: {
          vehicles: [
            'BackendApi',
            '$stateParams',
            function (BackendApi, $stateParams) {
              return BackendApi.getCarNow($stateParams.id);
            },
          ],
        },
        views: {
          now: 'vehiclesNow',
        },
      })
      .state('vehicles.details.route', {
        parent: 'vehicles.details',
        url: '/vehicles/:id/route',
        resolve: {
          route: [
            'BackendApi',
            'PeriodFilters',
            '$stateParams',
            'FuelUsageService',
            '$q',
            function (BackendApi, PeriodFilters, $stateParams, FuelUsageService, $q) {
              const filter = PeriodFilters.getFilter('route');

              const toNumber = (v) => {
                const num = parseFloat(v)
                if (isNaN(num) || Math.abs(num) === Infinity) {
                  return 0
                }
                return num
              }

              return $q.all(FuelUsageService.getPeriods2(filter.from, filter.to, $stateParams.id))
                .then((resp) => {
                  const acc = _.clone(resp[0].summary);
                  acc.distanceKm = 0;
                  acc.calculatedDistanceKm = 0;
                  acc.totalServerFuelUsage = 0;
                  acc.maxSpeedKmpH = 0;
                  acc.fuelStart = resp[0].summary.fuelStart;
                  acc.fuelEnd = resp[resp.length - 1].summary.fuelEnd;

                  const summary = resp
                  .map((r) => r.summary)
                  .reduce((prev, curr) => {
                    prev.distanceKm = (toNumber(prev.distanceKm) * 10 + toNumber(curr.distanceKm) * 10) / 10;
                    prev.calculatedDistanceKm = (toNumber(prev.calculatedDistanceKm) * 10 + toNumber(curr.calculatedDistanceKm) * 10) / 10;
                    prev.maxSpeedKmpH = Number(prev.maxSpeedKmpH) > Number(curr.maxSpeedKmpH) ? Number(prev.maxSpeedKmpH) : Number(curr.maxSpeedKmpH);
                    if (curr.v2FuelSettings) {
                      prev.totalServerFuelUsage = (toNumber(prev.totalServerFuelUsage) * 10 + toNumber(curr.totalServerFuelUsage) * 10) / 10;
                    }


                    return prev;
                  }, acc);

                  return {
                    summary,
                    stops: resp.flatMap(obj => obj.stops),
                    summaries: resp.map(obj => obj.summary)
                  }
                })
            },
          ],
          routeExtended: ['BackendApi', 'PeriodFilters', '$stateParams', function (BackendApi, PeriodFilters, $stateParams) {
            return BackendApi.getStopsExtended($stateParams.id, PeriodFilters.getFilter('route'));
          }],
          history: [
            'BackendApi',
            'PeriodFilters',
            '$stateParams',
            function (BackendApi, PeriodFilters, $stateParams) {
              return BackendApi.getHistory(
                $stateParams.id,
                PeriodFilters.getFilter('route')
              );
            },
          ],
          isMultiAccaunt: ['Session', function (Session) {
            return Session.user.loginType === '0';
          }]
        },
        views: {
          route: 'vehiclesRoute',
        },
      })
      .state('vehicles.details.report', {
        parent: 'vehicles.details',
        url: '/vehicles/:id/report?from&to&ids',
        resolve: {
          data: [
            '$stateParams',
            'FuelReportService',
            function ($stateParams, FuelReportService) {
              var reportText = '';
              var params = {
                id: $stateParams.id,
                ids: $stateParams.ids ? $stateParams.ids.split(',') : [],
                from: $stateParams.from,
                to: $stateParams.to
              };

              if (params.ids.length) {
                // TODO: Generate report with multiple car id's
                FuelReportService.getFuelReportWithIds(params)
                // .then(function (reports) {
                //   reportText = reports.join();
                //   FuelReportService.createReportFile(reportText, params);
                // });
                // FuelReportService.createReportFile(reportText, params);
              } else {
                FuelReportService.getFuelReport($stateParams.id, params)
                .then(
                  function (text) {
                    reportText = text;
                    FuelReportService.createReportFile(reportText, params);
                  }
                );
              };
            },
          ],
        }
      })
      .state('vehicles.details.mileage', {
        parent: 'vehicles.details',
        url: '/vehicles/:id/mileage',
        resolve: {
          data: ['BackendApi', 'PeriodFilters', '$stateParams', function (BackendApi, PeriodFilters, $stateParams) {
            return BackendApi.getMileage({id: $stateParams.id}, PeriodFilters.getFilter('mileage'));
          }]
        },
        views: {
          mileage: 'vehiclesMileage'
        }
      })
      .state('vehicles.details.stops', {
        parent: 'vehicles.details',
        url: '/vehicles/:id/stops?date',
        resolve: {
          route: [
            'BackendApi',
            'PeriodFilters',
            '$stateParams',
            'FuelUsageService',
            '$q',
            function (BackendApi, PeriodFilters, $stateParams, FuelUsageService, $q) {
              const filter = PeriodFilters.getFilter('stops');

              return $q.all(FuelUsageService.getPeriods(filter.from, filter.to, $stateParams.id))
                .then((resp) => {
                  return {
                    summary: resp[0].summary,
                    stops: resp.flatMap(obj => obj.stops)
                  }
                })
          }],
          routeExtended: ['BackendApi', 'PeriodFilters', '$stateParams', function (BackendApi, PeriodFilters, $stateParams) {
            return BackendApi.getStopsExtended($stateParams.id, PeriodFilters.getFilter('stops'));
          }],
          history: ['BackendApi', 'PeriodFilters', '$stateParams', function (BackendApi, PeriodFilters, $stateParams) {
            return BackendApi.getHistory($stateParams.id, PeriodFilters.getFilter('stops'));
          }],
          isMultiAccaunt: ['Session', function (Session) {
            return Session.user.loginType === '0';
          }]
        },
        views: {
          stops: 'vehiclesStops'
        }
      })
      .state('vehicles.details.statistics', {
        parent: 'vehicles.details',
        url: '/vehicles/:id/statistics',
        resolve: {},
        views: {
          statistics: 'vehiclesStatistics'
        }
      })
      .state('vehicles.details.settings', {
        parent: 'vehicles.details',
        url: '/vehicles/:id/settings',
        resolve: {
          settings: ['BackendApi', '$stateParams', function (BackendApi, $stateParams) {
            return BackendApi.getCarSettings({id: $stateParams.id})
          }],
          carSubscriberPlans: ['BackendApi', function (BackendApi) {
            return BackendApi.getCarsSubscriberPlans()
          }]
        },
        views: {
          settings: 'vehiclesSettings'
        }
      });
  }
})();
