(function () {
    "use strict";

    angular
        .module('app.translate', [])
        .config(appConfig)
    ;

    appConfig.$inject = ['$translateProvider'];

    function appConfig($translateProvider) {
        $translateProvider.translations('ru', {
            'global.currentWeek': 'Т.Н',
            'global.currentMonth': 'Т.М',
            'global.type': 'Тип',
            "global.load": "Загрузка",
            "global.loginError": "Неправильный пароль!",
            "global.savedSuccess": "Сохранено успешно!",
            "global.fromDate": "С даты",
            "global.toDate": "По дату",
            "global.from": "с",
            "global.to": "по",
            "global.date": "Дата",
            "global.time": "Время",
            "global.noViewData": "Нет данных для отображения",
            "global.ci.mile": "км/ч",
            "global.day": "x",
            "global.hours": "ч",
            "global.minutes": "мин",
            "global.fullDay": "весь день",
            "global.inWay": "В пути",
            "global.address": "Адрес",
            "global.caption": "Название",
            "global.color": "Цвет",
            "global.logout": "Выйти",
            "global.days": "дней",
            "global.earlier": "Ранее",
            "global.toggleSizeTracker": "Карту уже/шире",
            "global.toggleMap": "Показать/Скрыть карту",
            "global.feedback": "Оставить отзыв",
            "global.copied": "Скопировано",
            "global.sorting": "Сортировка",
            "global.day.0": "Ввоскресенье",
            "global.day.1": "Понедельник",
            "global.day.2": "Вторник",
            "global.day.3": "Среда",
            "global.day.4": "Четверг",
            "global.day.5": "Пятница",
            "global.day.6": "Суббота",
            "global.total": "Итого:",
            "global.parkingShortName": "Стоянка",
            "global.visitWithGoogle": "Визит с Google",

            "page.view": "Обзор",
            "page.now": "Сейчас",
            "page.route": "Маршрут",
            "page.mileage": "Пробег",
            "page.stops": "Остановки",
            "page.settings": "Настройки",
            "page.control": "Управление",
            "page.schedules": "Маршруты",
            "page.cardBalance": "Баланс катр",
            "page.reports": "Отчеты",
            "page.logistics": "Логистика",
            "logistics.routePoint.table.deviation": "Отключения",

            "home.subTitle": "Спутниковый мониторинг",
            "home.login.label": "Логин",
            "home.password.label": "Пароль",
            "home.carId.label": "Код машины",

            "action.enter": "Войти",
            "action.download": "Загрузить",
            "action.save": "Сохранить",
            "action.cancel": "Отменить",
            "action.close": "Закрыть",
            "action.choose": "Выбрать",
            "action.search": "Поиск",
            "action.view": "Показать",
            "action.assign": "Назначить",
            "action.change": "Изменить",
            "action.print": "Распечатать",
            "action.add": "Добавить",
            "action.create": "Создать",

            "validation.required": "Обзательное поле!",
            "validation.passNoEquals": "Пароль не совпадает!",

            "exportBtn.loadXLS": "Загрузить XLS",
            "exportBtn.saveAsRoute": "Сохранить как маршрутный лист",
            "exportBtn.loadXLSFuel": "Сохранить как маршрутный лист",
            "exportBtn.loadFuelCSV": "Сохранить CSV по топливу",
            "exportBtn.printFuel": "Отчет по топливу",
            'exportBtn.stopsGPX': 'Сохранить как GPX',

            "car.all": "Все машины",
            "car.allGroup": "Все группы",
            "car.name": "Имя",
            "car.vehicle": "ТС",
            "car.fuel": "Топливо",
            "car.number": "Номер",
            "car.dataReceived": "Данные получены",
            "car.speed": "Движение",
            "car.voltage": "Аккум.",
            "car.modem": "Модем",
            "car.latitude": "Широта",
            "car.longitude": "Долгота",
            "car.height": "Высота",
            "car.azimuth": "Азимут",
            "car.time": "Время",
            "car.accuracy": "Точность",
            "car.odometer": "Одометр",
            "car.route.viewData": "Показать/Скрыть данные о перемещеннии",
            "car.route.fuelChart": "Показать/Скрыть график топлива",
            "car.route.carInfoChart": "Показать/Скрыть данные по машине",
            "car.noMileage": "Нет перемещений",
            "car.mileage": "Пробег",
            "car.maxSpeed": "Макс. скорость",
            "car.engineWork": "Двигатель",
            "car.usage": "расход",
            "car.totalFuelUsage": "Расход топлива",
            "car.averageFuelUsage": "средний",
            "car.totalWay": "время в пути",
            "car.fuelConsumption": "~Расход",
            "car.maxMile": "Макс. (км/ч)",
            "car.total": "Всего",
            "car.chooseCar": "Выбор авто",
            "car.fuelChart.settingsRedirect": "Необходимо заполнить \"Конрольные параметры\" в настройках ТС",
            "car.fuelPrint.title": "Отчет по топливу",
            "car.fuelPrint.period.title": "Период",
            "car.fuelPrint.period.interval": "с {{from}} по {{to}}",
            "car.fuelPrint.carName": "Название ТС",
            "car.fuelPrint.fuelOnStart": "Топливо в нач.",
            "car.fuelPrint.discharge": "Слито",
            "car.fuelPrint.fuelOnEnd": "Топливо на кон.",
            "car.fuelPrint.usage": "Расход",
            "car.fuelPrint.averageFuelUsage": "Сред. расход на 100км",
            "car.fuelPrint.dischargeToUsage": "Слив учитывать в расходе",
            "car.fuelPrint.dischargeToUsageForAll": "Учитывать все сливы в расходе",
            "car.fuelPrint.excludeAllFuelDrainsFromFuelUsage": "Фильтр колебаний топлива",
            "car.fuelPrint.engine": "Двигатель",

            "marker.stop": "остановка",
            "marker.move": "движение",
            "marker.limitSpeed": "превышена скорость",
            "marker.notReceptionGps": "нет приема GPS",
            "marker.noConnection": "нет связи с маячком",

            "car.settings.simNumber": "Номер SIM",
            "car.settings.smsPassword": "SMS пароль",
            "car.settings.softwareVersion": "Версия ПО",
            "car.settings.view": "Настройка отображения",
            "car.settings.gosNumber": "Гос. номер",
            "car.settings.short": "Кратко",
            "car.settings.notes": "Нотатки",
            "car.settings.speedLimit": "Огрианичение скорости",
            "car.settings.limitation": "Ограничение",
            "car.settings.limit": "Предел",
            "car.settings.totalFuelUsage": "Расход топлива",
            "car.settings.summerCity": "Лето/город",
            "car.settings.summerRoad": "Лето/трасса",
            "car.settings.winterCity": "Зима/город",
            "car.settings.winterRoad": "Зима/трасса",
            "car.settings.filterMarkers": "Фильтр маркеров",
            "car.settings.combineFuelTanks": "Объединить баки",
            "car.settings.simBalance": "Проверка баланса на SIM карте",
            "car.settings.ussdRequest": "USSD запрос",
            "car.settings.viewUssdArchive": "Показать USSD архив",
            "car.settings.response": "Ответ",
            "car.settings.car": "автомобиль",
            "car.settings.specialCar": "спецтехника",
            "car.settings.stationaryCar": "стационарный объект",
            "car.settings.passengerCar": "легковой",
            "car.settings.cargoCar": "грузовой",
            "car.settings.carryingCapacity": "Грузоподъемность",
            "car.settings.amount": "Объем",
            "car.settings.impulseVolume": "Объём импульса",
            "car.settings.odometer": "Одометр",
            "car.settings.reset": "Сбросить",
            "car.settings.reset.confirm": "Вы уверены?",
            "car.settings.fuelCapacity": "Емкость бака {{tank}}, (л)",
            "car.settings.controlParam": "Контрольные параметры",
            "car.settings.usageNorm": "Норма расхода (л.ч)",
            "car.settings.usageIdle": "Расход хол. (л/ч)",
            "car.settings.shutdownLimit": "Пред. откл. (%)",
            "car.settings.deleteDevice": "Удаление трекера из логина",
            "car.settings.deleteDevice.confirm": "Вы уверены что хотите удалить трекер?",
            "car.settings.deleteDevice.success": "Трекер удален!",
            "car.settings.output": "Управляемый выход",
            "car.settings.subscriberPlan": "Абонентский план",
            "car.settings.noSubscriberPlan": "Нет",
            "car.settings.remoteControl": "Дистанционное управление",
            "car.settings.open": "Открыть",
            "car.settings.signal": "Сигнал",
            "car.settings.close": "Закрыть",
            "car.settings.salesMan": "Торговый агент",
            "car.settings.isWorking": "Рабочий",
            "car.settings.timerWakeup": "При пробуждении по таймеру",
            "car.settings.turnOnGPS": "Включать прием GPS",
            "car.settings.connectToServer": "Соединяться с сервером",
            "car.settings.receiveSMS": "Принимать SMS во сне",
            "car.settings.timeout": "Таймаут",
            "car.settings.baterySaving": "Економия батареи",
            'car.settings.gpsControl': 'Контроль GPS',
            'car.settings.v2Fuel': 'Топливо (версия 2)',
            'car.settings.notificationsSectionTitle': 'Уведомления',
            'car.settings.turnOnNotifications': 'Включить уведомления',
            'car.settings.turnOffNotifications': 'Отключить уведомления',
            'car.settings.turnOnGeozonesTransit': 'Включить уведомления о проезде геозон',
            'car.settings.turnOffGeozonesTransit': 'Выключить уведомления о проезде геозон',
            'car.settings.failedFetchingNotificationSettingsMsg': 'Не удалось получить настройки уведомлений',
            'car.settings.notificationsEnabledMsg': 'Уведомления включены',
            'car.settings.notificationsDisabledMsg': 'Уведомления отключены',
            'car.settings.failedSavingNotificationSettingsMsg': 'Не удалось сохранить настройки уведомлений',

            'car.settings.notificationAboutTransitsThroughGeozonesSettingsEnabledMsg': 'Уведомления о проезде геозон включены',
            'car.settings.notificationAboutTransitsThroughGeozonesSettingsDisabledMsg': 'Уведомления о проезде геозон отключены',
            'car.settings.failedSavingNotificationAboutTransitsThroughGeozonesSettingsMsg': 'Не удалось сохранить настройки для уведомлений о проезде геозон',

            'car.settings.addTelegramNotifications': 'Подписаться на уведомления',

            "settings.toTrack": "К трекерам",
            "settings.all": "Все",
            "settings.geozones": "Геозоны",
            "settings.users": "Пользователи",
            "settings.caption": "Панель управление пользователя",
            "settings.addedCar": "Добавление трекера в логин",
            "settings.factoryLogin": "Заводской логин",
            "settings.factoryPass": "Заводской пароль",
            "settings.contacts": "Контакты пользователя",
            "settings.email": "Эл. почта",
            "settings.tel": "Тел. номер",
            "settings.changePass": "Смена пароля",
            "settings.passOld": "Старый пароль",
            "settings.passNew": "Новый пароль",
            "settings.passReNew": "Повторите новый пароль",
            "settings.intervals": "Интервалы дня",
            "settings.night": "Ночь",
            "settings.day": "День",
            "settings.evening": "Вечер",
            "settings.seasons": "Сезоны",
            "settings.summerFrom": "Лето c",
            "settings.winterFrom": "Зима c",

            "settings.subscriptionFee": "Абон. плата",
            "settings.subscriptionFee.allFee": "Все в одном",
            "settings.subscriptionFee.caption": "Абонентская плата",
            "settings.subscriptionFee.period": "Период",
            "settings.subscriptionFee.selectionPeriod": "Период для выборки",
            "settings.subscriptionFee.selectionPeriod.previous": "Предыдущий",
            "settings.subscriptionFee.selectionPeriod.selected": "Относительно указанного",
            "settings.subscriptionFee.table.sequenceNumber": "№ п.п.",
            "settings.subscriptionFee.table.description": "Описание",
            "settings.subscriptionFee.table.price": "Тариф",
            "settings.subscriptionFee.table.count*price": "К-во*Тариф",
            "settings.subscriptionFee.table.amount": "Сумма",
            "settings.subscriptionFee.table.total": "Вместе",
            "settings.subscriptionFee.table.carsWithoutSubscriberPlan": "Машины без абон. планов",
            "settings.tokens": "Токены",
            "settings.tokens.update.success": "Данные токена были успешно обновлены",
            "settings.tokens.update.failed": "Не удалось обновить данные токена",
            "settings.tokens.delete.success": "Токен удалён",
            "settings.tokens.delete.failed": "Не удалось удалить токен",
            "settings.tokens.confirmRemovalDialogTitle": "Удаление токена",
            "settings.tokens.confirmRemovalDialogTextContent": "Токен {{ tokenDetails }} будет удалён",
            "settings.tokens.confirmRemovalDialogConfirmBtnTitle": "Удалить",
            "settings.tokens.confirmRemovalDialogCancelBtnTitle": "Отмена",
            'settings.tokens.copyUrlWithToken.success': 'Ссылка на страницу с токеном скопирована',
            'settings.tokens.copyUrlWithToken.failed': 'Не удалось скопировать ссылку на страницу с токеном',
            'settings.tokens.create.success': 'Токен был успешно создан',
            'settings.tokens.create.failed': 'Не удалось создать токен',
            'settings.tokens.emptyTokenName': 'Название токена не указано',
            'settings.tokens.saveChangesTooltip': 'Сохранить изменения',
            'settings.tokens.cancelChangesTooltip': 'Отменить изменения',
            'settings.tokens.toggleAvailabilityOfTokenTitle': 'Вкл/Выкл',
            'settings.tokens.copyLinkTitle': 'Скопировать ссылку',
            'settings.tokens.deleteTokenTitle': 'Удалить токен',
            'settings.tokens.selectVehiclesTitle': 'Выбрать транспортные средства',
            'settings.tokens.expirationDateTitle': 'Дата окончания срока',
            'settings.tokens.withUnlimitedValidity': 'Бессрочный',
            'settings.tokens.shouldShowShortNamesTitle': 'Коротко',
            'settings.tokens.forwarderTitle': 'Экспедитор',
            'settings.tokens.mapTitle': 'Карта',
            'settings.tokens.canNotTurnOnForwarderModeDialogTitle':
              'Не могу включить режим экспедитора',
            'settings.tokens.canNotTurnOnForwarderModeDialogContent':
              'Режим экспедитора разрешён только для одного автомобиля',
            'settings.tokens.canNotAddVehicleDialogTitle':
              'Не могу добавить еще один автомобиль',
            'settings.tokens.canNotAddVehicleDialogContent':
              'При включенном режиме экспедитора разрешено использовать только один автомобиль',

            "settings.globalNotificationSettingsTabTitle": "Уведомления",
            "settings.globalNotificationSettingsHeader": "Уведомления",
            "settings.failedLoadingAllSettings": "Не удалось загрузить глобальные настройки уведомлений",

            "settings.enableNotifications": "Включить уведомления",
            "settings.disableNotifications": "Отключить уведомления",

            "settings.enableNotificationsInTelegram": "Включить уведомления в Telegram",
            "settings.disableNotificationsInTelegram": "Отключить уведомления в Telegram",
            "settings.failedUpdatingNotificationsInTelegramMode": "Не удалось обновить уведомления в Telegram",
            "settings.notificationsInTelegramEnabled": "Уведомления в Telegram включены",
            "settings.notificationsInTelegramDisabled": "Уведомления в Telegram отключены",

            "settings.enableNotificationsInBrowser": "Включить уведомления в браузере",
            "settings.disableNotificationsInTBrowser": "Отключить уведомления в браузере",
            "settings.failedUpdatingNotificationsInBrowserMode": "Не удалось обновить уведомления в браузере",
            "settings.notificationsInBrowserEnabled": "Уведомления в браузере включены",
            "settings.notificationsInBrowserDisabled": "Уведомления в браузере отключены",

            "settings.saving": "Сохранение...",

            "geozone.caption": "Настройка геозон",
            "user.caption": "Управление дочерними пользователями",
            "user.currentUserCaption": "Управление текущим пользователем",
            "user.settings": "Настройки пользователя",
            "user.blocked": "Блокировка пользователя",
            "user.permissions": "Права пользователя",
            "user.geozone": "Геозоны",
            "user.viewControl": "Контроль посещения геозон",
            "user.routeSheet": "Маршрутный лист",
            "user.reports": "Отчеты",
            "user.logistics": "Логистика",
            "user.settings.subscriberPlan": "Абонентский план",

            "user.settings.plan": "План",
            "user.settings.plan.bp": "БП",
            "user.settings.plan.bp+": "БПД",
            "user.settings.plan.bpr": "БПР",
            "user.settings.plan.bp+r": "БП+Р",
            "user.settings.plan.bprt": "БПРТ",
            "user.settings.plan.bprt+": "БПРТ+",
            "user.settings.plan.bprd": "БПРД",
            "user.settings.plan.bpo": "БПО",
            "user.settings.plan.165": "165",
            "user.settings.plan.200": "200",
            "user.settings.plan.250": "250",
            "user.settings.plan.70": "70",
            "user.settings.plan.notMoving": "НД-30",

            "user.settings.category": "Категория",
            "user.settings.category.monthly": "Месячный",
            "user.settings.category.quarterly": "Квартальный",
            "user.settings.category.semiannual": "Полугодовой",
            "user.settings.category.annual": "Годовой",
            "user.settings.year": "Год",
            "user.settings.sequenceNumber": "ПН",
            "user.settings.loginDescription": "Описание",
            "user.settings.group": "Группа",
            "user.settings.fuel": "Бак",

            "schedule.appointment": "Назначение",
            "schedule.history": "История назначения",
            "schedule.lists": "Маршрутные листы",

            "chart.fuelUsage": "Дополнительный бак",

            "logistics.routes.title": "Маршруты",
            "logistics.routes.createRoute": "Создать",
            "logistics.routes.periods.day": "День",
            "logistics.routes.periods.week": "Неделя",
            "logistics.routes.periods.month": "Месяц",
            "logistics.routes.periods.all": "Все",
            "logistics.routes.table.title": "Название",

            "logistics.create-route.title": "Построение маршрута",
            "logistics.create-route.specify": "Указать",
            "logistics.create-route.cancel": "Отменить",
            "logistics.create-route.startDate": "Дата",
            "logistics.create-route.startTime": "Время",
            "logistics.create-route.parkingTime": "Время стоянки",
            "logistics.create-route.parking": "Стоянка (м)",
            "logistics.create-route.save": "Сохранить",
            "logistics.create-route.redraw": "Перерисовать",
            "logistics.create-route.optimal": "Оптим.",
            "logistics.create-route.showPoint": "Показать",
            "logistics.create-route.deletePoint": "Удалить",
            "logistics.create-route.addPoint": "Добавить точку",
            "logistics.create-route.removeUndeterminated": "Удалить неизвестные",
            "logistics.create-route.build": "Построить",
            "logistics.create-route.loadFile": "Загрузить файл",
            "logistics.create-route.selectPoints": "Выделить точки",
            "logistics.create-route.clearPoints": "Очистить точки",
            "logistics.create-route.togglePointsVisibility": "Скрыть/показать точки",
            "logistics.create-route.removeCar": "Удалить машину",
            "logistics.create-route.start": "Начало",
            "logistics.create-route.end": "Конец",
            "logistics.create-route.zone": "Геозона",
            "logistics.create-route.name": "Название",

            "logistics.view-route.title": "Маршрут",
            "logistics.view-route.accept": "Принять",
            "logistics.view-route.print.title": "Маршрутный лист",

            "logistics.toRoutes": "К маршрутам",
            "logistics.totalDistance": "Длина маршрута",
            "logistics.totalTime": "В пути",
            "logistics.estimatedDistance": "Расчетное расстояние",
            "logistics.distanceTraveled": "Пройденное расстояние",
            "logistics.car": "Машина",
            "logistics.timeCorrectionInCity": ">% город",
            "logistics.timeCorrectionOutsideCity": ">% траса",
            "logistics.weigth": "Масса (кг)",
            "logistics.startDateTime": "Время начала",
            "logistics.edit": "Редактировать",
            "logistics.clone": "Копировать",
            "logistics.delete": "Удалить",
            "logistics.endDateTime": "Время конца",
            "logistics.totalWeight": "Общий вес",
            "logistics.status": "Статус",
            "logistics.accepted": "Принят",
            "logistics.non-accepted": "Не принят",
            "logistics.complexity": "Складность",
            "logistics.routePoint.table.title": "Название",
            "logistics.routePoint.table.address": "Адрес",
            "logistics.routePoint.table.sequenceNumber": "№ п.п.",
            "logistics.routePoint.table.action": "Действие",
            "logistics.routePoint.table.arrival": "Приезд",
            "logistics.routePoint.table.departure": "Выезд",
            "logistics.routePoint.table.requestNumber": "№ док.",
            "logistics.routePoint.table.weight": "Вес",
            "logistics.routePoint.table.quantity": "SKU",
            "logistics.routePoint.table.note": "Комментарий",
            "logistics.routePoint.table.signature": "Подпись",
            "logistics.routePoint.table.price": "Сумма",
            "logistics.routePoint.table.total": "Вместе",
            "logistics.routePoint.table.barcode": "Штрихкод",


            "car-info.timestamp": "Время",
            "car-info.sat": "Sat",
            "car-info.dop": "DOP",
            "car-info.azimut": "Ази",
            "car-info.kmh": "км/ч",
            "car-info.v1": "V1",
            "car-info.v2": "V2",
            "car-info.flags": "Флаги",
            "car-info.gsm": "GSM",
            "car-info.csq": "CSQ",
            "car-info.temperature": "t°",
            "car-info.extra": "Extra",

            "modal.info": "Информация",
            "modal.close": "Закрыть",

            "months.0": "Январь",
            "months.1": "Февраль",
            "months.2": "Март",
            "months.3": "Апрель",
            "months.4": "Май",
            "months.5": "Июнь",
            "months.6": "Июль",
            "months.7": "Август",
            "months.8": "Сентябрь",
            "months.9": "Октябрь",
            "months.10": "Ноябрь",
            "months.11": "Декабрь",

            "reports.stops.car": "Авто",
            "reports.stops.date": "Дата",
            "reports.stops.expenseCalculation": "Способ подщета расхода",
            "reports.stops.mileage": "Пробег (км)",
            "reports.stops.maxSpeed": "Макс скорость (км/ч)",
            "reports.stops.fuelConsumption": "Расход топлива (л)",
            "reports.stops.avFuelConsumption": "Середний расход на 100 км (л)",
            "reports.stops.engineTime": "Время работи двигателя",
            "reports.stops.from": "З",
            "reports.stops.to": "По",
            "reports.stops.parkingTime": "P",
            "reports.stops.onWay": "В пути",
            "reports.stops.distance": "Км",
            "reports.stops.maxSpeedShort": "Макс км/год",
            "reports.stops.fuelConsumptionShort": "Расход",
            "reports.stops.address": "Адрес",
            "reports.vehicleNumber": "Номер автомобиля",
            "reports.dates": "Даты",
            "reports.date": "Дата",
            'reports.powerStatus.noData': 'нет данных',
            'reports.powerStatus.noPowerSupply': 'нет питания',
            'reports.powerStatus.reload': 'перезагрузка модема',
            'reports.noGPS': 'Немає GPS зв\'язку',
            'reports.onIn': 'сработал вход',
            'reports.averageFuelUsage': 'Средний расход топлива',
            'reports.fuelConsumptionMonitoringDevice': 'Средство контроля расхода топлива',
            'reports.fuelCalculator': 'Топливный калькулятор',
            'reports.impulseSensor': 'Датчик импульсов',
            'reports.fuelFlowSensor': 'Датчик уровня топлива',
            'reports.fuelConsumptionMonitoringDeviceName': 'ДРП/Топливный калькулятор/Датчик импульсов',
            'reports.tradingAgent': 'Торговый агент',
            'reports.activeStatusOfTradingAgent': 'Активный',
            'reports.page': 'Страница',
            'reports.downloadEntityAs': 'Загруэить "{{entityName}}" как {{fileType}}',
            'reports.printAs': 'Распечатать "{{entityName}}"',
            "report.fuel.period": "Период",
            "report.fuel.periodStr": "Период: с {{from}} по {{to}}",
            "report.downloadAsXLSX": "Загрузить как XLSX",
            "report.refueled": "Заправлено",
            "report.tank": "Бак",
            "report.number": "№ {{number}}",
            "report.fuelUsage": "Расход топлива",
            "report.liters": "л",
            "report.refueling": "Заправка",
            "report.fuelDrain": "Слив",
            "report.commonReport": "Общий отчёт",

            "vehicle.extra-settings.choose-icon": "Выберите иконку",
            'vehicle.mileage.dayPeriods': 'Промежутки дня',

            'fuel-drain-metering-mode-switch.off': 'Не учитывать слив топлива в расходе',
            'fuel-drain-metering-mode-switch.on': 'Учитывать слив топлива в расходе',

            'units.liters': 'л',
            'units.km': 'км',
            'units.hours': 'ч',
            'units.speed': 'км/ч',

            'currentWeek': 'Т.Н.',
            'currentMonth': 'Т.М.',

            'vehicleExtraSettings.vehicleType': 'Тип транспортного средства',
            'vehicleExtraSettings.vehicleType.any': 'Любой',
            'vehicleExtraSettings.vehicleType.passenger': 'Пассажирский',
            'vehicleExtraSettings.vehicleType.cargo': 'Грузовой',
            'vehicleExtraSettings.vehicleType.technological': 'Технологический',
            'vehicleExtraSettings.selectColorBtnTitle': 'Выбрать цвет',
            'vehicleExtraSettings.selectIconBtnTitle': 'Выбрать иконку',

            'mapControls.toggleCustomIconsForVehicles': 'Показать/скрыть иконки для транспортных средств',
            'mapControls.toggleVehicleNamesForVehicles': 'Показать/скрыть названия авто',
            'mapControls.toggleNamesForGeoZones': 'Показать/скрыть названия геозон',
            'mapControls.toggleMapButtonsVisibility': 'Показать/скрыть кнопки',
            'mapControls.toggleVisibilityOfGeoZones': 'Показать/скрыть геозоны',
            'mapControls.toggleFullScreenMode': 'Полноэкранный режим',
            'mapControls.trafficJams': 'Пробки',
            'mapControls.hybrid': 'Гибрид',

            'general.parking': 'стоянка',
            'cartLegend.limit': 'Предел',

            'vehicleWithControversialPosition': 'Подавление GPS',
            'tokens.isTokenWithoutVehicle': 'Нет транспортных средств для отображения',
            'tokens.noForwarderRouteDate': 'Данные для маршрута экспедитора отсутствуют',

            'notifications.noGpsConnection': 'Нет GPS соединения',
            'notifications.noGpsConnectionPl': 'Нет GPS соединения Pl',
            'notifications.noGpsCoverage': 'Нет GPS покрытия',
            'notifications.speeding': 'Превышение скорости',
            'notifications.movement': 'начал движение',
            'notifications.parking': 'встал на стоянку',
            'notifications.enterGeozone': 'въехал в геозону',
            'notifications.leaveGeozone': 'выехал из геозоны',
            'notifications.parkingInGeozone': 'встал на стоянку в геозоне',
            'notifications.kmPerHour': 'км/ч',
            'notifications.currentSpeed': 'Текущая скорость',
            'notifications.maxSpeed': 'Максимальная скорость',
            'notifications.settingUpNotificationsForUsers': 'Настройка уведомлений для пользователей',
            'notifications.successUpdatingOfGlobalUserSettings': 'Настройки уведомлений для пользователей обновлены',
            'notifications.failedUpdatingOfGlobalUserSettings':
              'Не удалось обновить настройки уведомлений для пользователей',
            'notifications.usersSubscribedToNotifications': 'Пользователи подписанные на уведомления',
            'notifications.userNotificationSettings': 'Настройки уведомлений для пользователей',
            'notifications.usersSubscribedToNotificationsPlaceholder': 'Выберите пользователей',
            'notifications.failedLoadingInitialDataForUsersSubscribedToNotifications':
              'Не удалось загрузить настройки уведомлений для пользователей',
            'notifications.successUpdatingOfUsersSubscribedToNotifications':
              'Настройки уведомлений для пользователей обновлены',
            'notifications.failedUpdatingOfUsersSubscribedToNotifications':
              'Не удалось обновить настройки уведомлений для пользователей',
            'notifications.usersSubscribedToNotifications.reload': 'Загрузить опять',

            'extendedNotificationSettings.noGpsConnection': 'Нет связи',
            'extendedNotificationSettings.noGpsConnectionPl': 'Нет связи Pl',
            'extendedNotificationSettings.noGpsCoverage': 'Нет GPS покрытия',
            'extendedNotificationSettings.speeding': 'Превышение скорости',
            'extendedNotificationSettings.movement': 'Начало движения',
            'extendedNotificationSettings.parking': 'Парковка',
            'extendedNotificationSettings.enterGeozone': 'Въезд в геозону',
            'extendedNotificationSettings.leaveGeozone': 'Выезд из геозоны',
            'extendedNotificationSettings.parkingInGeozone': 'Парковка в геозоне',
        });

        $translateProvider.translations('ua', {
            'global.currentWeek': 'П.Т',
            'global.currentMonth': 'П.М',
            'global.type': 'Тип',
            "global.load": "Завантаження",
            "global.loginError": "Невірний пароль!",
            "global.savedSuccess": "Збережено успішно!",
            "global.fromDate": "З дати",
            "global.toDate": "По дату",
            "global.from": "з",
            "global.to": "по",
            "global.date": "Дата",
            "global.time": "Час",
            "global.noViewData": "Немає даних для відображення",
            "global.ci.mile": "км/год",
            "global.day": "д",
            "global.hours": "г",
            "global.minutes": "хв",
            "global.fullDay": "увесь день",
            "global.inWay": "В дорозі",
            "global.address": "Адреса",
            "global.caption": "Назва",
            "global.color": "Колір",
            "global.logout": "Вийти",
            "global.days": "днів",
            "global.earlier": "Раніше",
            "global.toggleSizeTracker": "Карту вужче/ширше",
            "global.toggleMap": "Показати/Приховати карту",
            "global.feedback": "Залишити відгук",
            "global.copied": "Скопійовано",
            "global.sorting": "Сортування",
            "global.day.0": "Неділя",
            "global.day.1": "Понеділок",
            "global.day.2": "Вівторок",
            "global.day.3": "Середа",
            "global.day.4": "Четвер",
            "global.day.5": "П'ятниця",
            "global.day.6": "Субота",
            "global.total": "Разом:",
            "global.parkingShortName": "Зупинка",
            "global.visitWithGoogle": "Візит з Google",

            "page.view": "Огляд",
            "page.now": "Зараз",
            "page.route": "Маршрут",
            "page.mileage": "Пробіг",
            "page.stops": "Зупинки",
            "page.settings": "Налаштування",
            "page.control": "Управління",
            "page.schedules": "Маршрути",
            "page.cardBalance": "Баланс карт",
            "page.reports": "Звіти",
            "page.logistics": "Логістика",
            "page.statistics": "Статистика",

            "home.subTitle": "Супутниковий моніторинг",
            "home.login.label": "Логін",
            "home.password.label": "Пароль",
            "home.carId.label": "Код машини",

            "action.enter": "Ввійти",
            "action.download": "Завантажити",
            "action.save": "Зберегти",
            "action.cancel": "Скасувати",
            "action.close": "Закрити",
            "action.choose": "Вибрати",
            "action.search": "Пошук",
            "action.view": "Показати",
            "action.assign": "Призначити",
            "action.change": "Змінити",
            "action.print": "Друкувати",
            "action.add": "Добавити",
            "action.create": "Створити",

            "validation.required": "Обов'язкове поле!",
            "validation.passNoEquals": "Пароль не збігається!",

            "exportBtn.loadXLS": "Завантажити XLS",
            "exportBtn.saveAsRoute": "Зберегти як маршрутний лист",
            "exportBtn.loadXLSFuel": "Завантажити XLS по паливу",
            "exportBtn.loadFuelCSV": "Завантажити CSV по паливу",
            "exportBtn.printFuel": "Звіт по паливу",
            'exportBtn.stopsGPX': 'Зберегти як GPX',

            "car.all": "Всі машини",
            "car.allGroup": "Всі групи",
            "car.name": "Iм'я",
            "car.vehicle": "ТЗ",
            "car.fuel": "Паливо",
            "car.number": "Номер",
            "car.dataReceived": "Дані отримані",
            "car.speed": "Рух",
            "car.voltage": "Аккум.",
            "car.modem": "Модем",
            "car.latitude": "Широта",
            "car.longitude": "Довгота",
            "car.height": "Висота",
            "car.azimuth": "Азимут",
            "car.time": "Час",
            "car.accuracy": "Точність",
            "car.odometer": "Одометр",
            "car.route.viewData": "Показати/Приховати дані про переміщення",
            "car.route.fuelChart": "Показати/Приховати графік палива",
            "car.route.carInfoChart": "Показати/Приховати дані по машині",
            "car.noMileage": "Немає переміщень",
            "car.mileage": "Пробіг",
            "car.maxSpeed": "Макс. швидкість",
            "car.engineWork": "Двигун",
            "car.usage": "витрати",
            "car.totalFuelUsage": "Витрати палива",
            "car.averageFuelUsage": "середня",
            "car.totalWay": "час в дорозі",
            "car.fuelConsumption": "~Витрата",
            "car.maxMile": "Макс. (км/год)",
            "car.total": "Всього",
            "car.chooseCar": "Вибір авто",
            "car.fuelChart.settingsRedirect": "Необхідно заповнити \"Контрольні параметри\" в налаштуваннях ТЗ",
            "car.fuelPrint.title": "Звіт по паливу",
            "car.fuelPrint.period.title": "Період",
            "car.fuelPrint.period.interval": "з {{from}} по {{to}}",
            "car.fuelPrint.carName": "Назва ТЗ",
            "car.fuelPrint.fuelOnStart": "Паливо на поч.",
            "car.fuelPrint.discharge": "Злито",
            "car.fuelPrint.fuelOnEnd": "Паливо на кін.",
            "car.fuelPrint.usage": "Розхід",
            "car.fuelPrint.averageFuelUsage": "Сер. розхід на 100км",
            "car.fuelPrint.dischargeToUsage": "Злив враховувати у витраті",
            "car.fuelPrint.dischargeToUsageForAll": "Враховувати всі зливи у розхід",
            "car.fuelPrint.excludeAllFuelDrainsFromFuelUsage": "Фільтр коливань палива",
            "car.fuelPrint.engine": "Двигун",

            "marker.stop": "зупинка",
            "marker.move": "рух",
            "marker.limitSpeed": "перевищена швидкість",
            "marker.notReceptionGps": "немає прийому GPS",
            "marker.noConnection": "немає зв'язку з маячком",

            "car.settings.simNumber": "Номер SIM",
            "car.settings.smsPassword": "SMS пароль",
            "car.settings.softwareVersion": "Версія ПЗ",
            "car.settings.view": "Налаштування відображення",
            "car.settings.gosNumber": "Держ. номер",
            "car.settings.short": "Коротко",
            "car.settings.notes": "Нотатки",
            "car.settings.speedLimit": "Обмеження швидкості",
            "car.settings.limitation": "Обмеження",
            "car.settings.limit": "Межа",
            "car.settings.totalFuelUsage": "Витрати палива",
            "car.settings.summerCity": "Літо/місто",
            "car.settings.summerRoad": "Літо/траса",
            "car.settings.winterCity": "Зима/місто",
            "car.settings.winterRoad": "Зима/траса",
            "car.settings.filterMarkers": "Фільтр маркерів",
            "car.settings.combineFuelTanks": "Об'єднати баки",
            "car.settings.simBalance": "Перевірка балансу на SIM карті",
            "car.settings.ussdRequest": "USSD запит",
            "car.settings.viewUssdArchive": "Показати USSD архів",
            "car.settings.response": "Відповідь",
            "car.settings.car": "автомобіль",
            "car.settings.specialCar": "спецтехніка",
            "car.settings.stationaryCar": "стаціонарний об'єкт",
            "car.settings.passengerCar": "легковий",
            "car.settings.cargoCar": "вантажний",
            "car.settings.carryingCapacity": "Вантажопідйомність",
            "car.settings.amount": "Об `єм",
            "car.settings.impulseVolume": "Обсяг імпульсу",
            "car.settings.odometer": "Одометр",
            "car.settings.reset": "Очистити",
            "car.settings.reset.confirm": "Ви впевнені?",
            "car.settings.fuelCapacity": "Ємність бака {{tank}}, (л)",
            "car.settings.controlParam": "Контрольні параметри",
            "car.settings.usageNorm": "Норма витрат (л/год)",
            "car.settings.usageIdle": "Витрата хол. (л/год)",
            "car.settings.shutdownLimit": "Межа відкл. (%)",
            "car.settings.deleteDevice": "Видалення трекеру з логіна",
            "car.settings.deleteDevice.confirm": "Ви впевнені що хочете видалити трекер?",
            "car.settings.deleteDevice.success": "Трекер видалено успішно!",
            "car.settings.output": "Контрольований вихід",
            "car.settings.subscriberPlan": "Абонентський план",
            "car.settings.noSubscriberPlan": "Немає",
            "car.settings.remoteControl": "Дистанційне управління",
            "car.settings.open": "Відкрити",
            "car.settings.signal": "Сигнал",
            "car.settings.close": "Закрити",
            "car.settings.salesMan": "Торговий агент",
            "car.settings.isWorking": "Робочий",
            "car.settings.timerWakeup": "При пробудженні по таймеру",
            "car.settings.turnOnGPS": "Включати прийом GPS",
            "car.settings.connectToServer": "Звʼязуватись із сервером",
            "car.settings.receiveSMS": "Приймати SMS підчас сну",
            "car.settings.timeout": "Таймаут",
            "car.settings.baterySaving": "Економія батареї",
            'car.settings.gpsControl': 'Контроль GPS',
            'car.settings.v2Fuel': 'Пальне (версія 2)',
            'car.settings.notificationsSectionTitle': 'Повідомлення',
            'car.settings.turnOnNotifications': 'Включити повідомлення',
            'car.settings.turnOffNotifications': 'Вимкнути повідомлення',
            'car.settings.turnOnGeozonesTransit': 'Включити сповіщення про проїзд геозон',
            'car.settings.turnOffGeozonesTransit': 'Вимкнути сповіщення про проїзд геозон',
            'car.settings.failedFetchingNotificationSettingsMsg': 'Не вдалося отримати налаштування сповіщень',

            'car.settings.notificationsEnabledMsg': 'Сповіщеня включени',
            'car.settings.notificationsDisabledMsg': 'Сповіщеня вимкнути',
            'car.settings.failedSavingNotificationSettingsMsg': 'Не вдалося зберегти налаштування сповіщень',

            'car.settings.notificationAboutTransitsThroughGeozonesSettingsEnabledMsg': 'Сповіщеня про проїзд геозон включені',
            'car.settings.notificationAboutTransitsThroughGeozonesSettingsDisabledMsg': 'Сповіщеня про проїзд геозон вимкнуті',
            'car.settings.failedSavingNotificationAboutTransitsThroughGeozonesSettingsMsg': 'Не вдалося зберегти налаштування сповіщень про про проїзд геозон',

            'car.settings.addTelegramNotifications': 'Підписатися на повідомлення',

            "settings.toTrack": "До трекерів",
            "settings.all": "Всi",
            "settings.geozones": "Геозони",
            "settings.users": "Користувачі",
            "settings.caption": "Панель управління користувача",
            "settings.addedCar": "Додавання трекера в логін",
            "settings.factoryLogin": "Заводський логін",
            "settings.factoryPass": "Заводський пароль",
            "settings.contacts": "Контакти користувача",
            "settings.email": "Ел. пошта",
            "settings.tel": "Тел. номер",
            "settings.changePass": "Зміна пароля",
            "settings.passOld": "Старий пароль",
            "settings.passNew": "Новий пароль",
            "settings.passReNew": "Повторіть новий пароль",
            "settings.intervals": "Iнтервали дня",
            "settings.night": "Ніч",
            "settings.day": "День",
            "settings.evening": "Вечір",
            "settings.seasons": "Сезони",
            "settings.summerFrom": "Літо з",
            "settings.winterFrom": "Зима з",
            "settings.tokens": "Токени",
            "settings.tokens.update.success": "Дані токена були успішно оновлені",
            "settings.tokens.update.failed": "Не вдалося оновити дані токена",
            "settings.tokens.delete.success": "Токен видалено",
            "settings.tokens.delete.failed": "Не вдалося вилучити токен",
            "settings.tokens.confirmRemovalDialogTitle": "Видалення токена",
            "settings.tokens.confirmRemovalDialogTextContent": "Токен {{ tokenDetails }} буде видалено",
            "settings.tokens.confirmRemovalDialogConfirmBtnTitle": "Видалити",
            "settings.tokens.confirmRemovalDialogCancelBtnTitle": "Відміна",
            'settings.tokens.copyUrlWithToken.success': 'Посилання на сторінку з токеном скопійовано',
            'settings.tokens.copyUrlWithToken.failed': 'Не вдалося скопіювати посилання на сторінку з токеном',
            'settings.tokens.create.success': 'Токен був успішно створений',
            'settings.tokens.create.failed': 'Не вдалося створити токен',
            'settings.tokens.emptyTokenName': 'Назва токена не вказана',
            'settings.tokens.saveChangesTooltip': 'Зберегти зміни',
            'settings.tokens.cancelChangesTooltip': 'Відмінити зміни',
            'settings.tokens.toggleAvailabilityOfTokenTitle': 'Увімк/Вимк',
            'settings.tokens.copyLinkTitle': 'Копіювати посилання',
            'settings.tokens.deleteTokenTitle': 'Видалити токен',
            'settings.tokens.selectVehiclesTitle': 'Вибрати транспортні засоби',
            'settings.tokens.expirationDateTitle': 'Термін придатності',
            'settings.tokens.withUnlimitedValidity': 'Безстроковий',
            'settings.tokens.shouldShowShortNamesTitle': 'Коротко',
            'settings.tokens.forwarderTitle': 'Експедитор',
            'settings.tokens.mapTitle': 'Карта',
            'settings.tokens.canNotTurnOnForwarderModeDialogTitle':
              'Не можу ввімкнути режим експедитора',
            'settings.tokens.canNotTurnOnForwarderModeDialogContent':
              'Режим експедитора дозволено лише для одного автомобіля',
            'settings.tokens.canNotAddVehicleDialogTitle':
              'Не можу додати ще один автомобіль',
            'settings.tokens.canNotAddVehicleDialogContent':
              'При увімкненому режимі експедитора дозволено використовувати лише один автомобіль',

            "settings.globalNotificationSettingsTabTitle": "Сповіщення",
            "settings.globalNotificationSettingsHeader": "Сповіщення",
            "settings.failedLoadingAllSettings": "Неможливо завантажити глобальні налаштуваня сповіщень",

            "settings.enableNotifications": "Включити сповіщення",
            "settings.disableNotifications": "Вимкнути сповіщення",

            "settings.enableNotificationsInTelegram": "Включити сповіщення у Telegram",
            "settings.disableNotificationsInTelegram": "Вимкнути сповіщення у Telegram",
            "settings.failedUpdatingNotificationsInTelegramMode": "Не вдалося оновити сповіщення у Telegram",
            "settings.notificationsInTelegramEnabled": "Сповіщення у Telegram включені",
            "settings.notificationsInTelegramDisabled": "Сповіщення у Telegram вимкнуті",

            "settings.enableNotificationsInBrowser": "Включити сповіщення у браузері",
            "settings.disableNotificationsInTBrowser": "Вимкнути сповіщення у браузері",
            "settings.failedUpdatingNotificationsInBrowserMode": "Не вдалося оновити сповіщення у браузері",
            "settings.notificationsInBrowserEnabled": "Сповіщення у браузері включені",
            "settings.notificationsInBrowserDisabled": "Сповіщення у браузері вимкнуті",

            "settings.saving": "Збереження...",

            "settings.subscriptionFee": "Абон. плата",
            "settings.subscriptionFee.allFee": "Все в одному",
            "settings.subscriptionFee.caption": "Абонентська плата",
            "settings.subscriptionFee.period": "Період",
            "settings.subscriptionFee.selectionPeriod": "Період для вибірки",
            "settings.subscriptionFee.selectionPeriod.previous": "Попередній",
            "settings.subscriptionFee.selectionPeriod.selected": "Відносно вказаного",
            "settings.subscriptionFee.table.sequenceNumber": "№ п.п.",
            "settings.subscriptionFee.table.description": "Опис",
            "settings.subscriptionFee.table.price": "Тариф",
            "settings.subscriptionFee.table.count*price": "К-ть*Тариф",
            "settings.subscriptionFee.table.amount": "Сума",
            "settings.subscriptionFee.table.total": "Разом",
            "settings.subscriptionFee.table.carsWithoutSubscriberPlan": "Машини без абон. планів",


          "geozone.caption": "Налаштування геозон",
            "user.caption": "Управління дочірніми користувачами",
            "user.currentUserCaption": "Управління даним користувачем",
            "user.settings": "Налаштування користувача",
            "user.blocked": "Блокування користувача",
            "user.permissions": "Права користувача",
            "user.geozone": "Геозони",
            "user.viewControl": "Контроль відвідування геозон",
            "user.routeSheet": "Маршрутний лист",
            "user.reports": "Звіти",
            "user.logistics": "Логістика",
            "user.settings.subscriberPlan": "Абонентський план",

            "user.settings.plan": "План",
            "user.settings.plan.bp": "БП",
            "user.settings.plan.bp+": "БПД",
            "user.settings.plan.bpr": "БПР",
            "user.settings.plan.bp+r": "БП+Р",
            "user.settings.plan.bprt": "БПРТ",
            "user.settings.plan.bprt+": "БПРТ+",
            "user.settings.plan.bprd": "БПРД",
            "user.settings.plan.bpo": "БПО",
            "user.settings.plan.165": "165",
            "user.settings.plan.200": "200",
            "user.settings.plan.250": "250",
            "user.settings.plan.70": "70",
            "user.settings.plan.notMoving": "НР-30",

            "user.settings.category": "Категорія",
            "user.settings.category.monthly": "Місячний",
            "user.settings.category.quarterly": "Квартальний",
            "user.settings.category.semiannual": "Піврічний",
            "user.settings.category.annual": "Річний",
            "user.settings.year": "Рік",
            "user.settings.sequenceNumber": "ПН",
            "user.settings.loginDescription": "Опис",
            "user.settings.group": "Група",
            "user.settings.fuel": "Бак",


            "schedule.appointment": "Призначення",
            "schedule.history": "Історія призначення",
            "schedule.lists": "Маршрутні листи",

            "chart.fuelUsage": "Додатковий бак",

            "logistics.routes.title": "Маршрути",
            "logistics.routes.createRoute": "Створити",
            "logistics.routes.periods.day": "День",
            "logistics.routes.periods.week": "Тиждень",
            "logistics.routes.periods.month": "Місяць",
            "logistics.routes.periods.all": "Все",
            "logistics.routes.table.title": "Назва",

            "logistics.create-route.title": "Побудова маршруту",
            "logistics.create-route.specify": "Вказати",
            "logistics.create-route.cancel": "Відмінити",
            "logistics.create-route.startDate": "Дата",
            "logistics.create-route.startTime": "Час",
            "logistics.create-route.parkingTime": "Час стоянки",
            "logistics.create-route.parking": "Стоянка (м)",
            "logistics.create-route.save": "Зберегти",
            "logistics.create-route.redraw": "Перемалювати",
            "logistics.create-route.optimal": "Оптим.",
            "logistics.create-route.showPoint": "Показати",
            "logistics.create-route.deletePoint": "Видалити",
            "logistics.create-route.addPoint": "Добавити точку",
            "logistics.create-route.removeUndeterminated": "Видалити невідомі",
            "logistics.create-route.build": "Побудувати",
            "logistics.create-route.loadFile": "Загрузити файл",
            "logistics.create-route.selectPoints": "Виділити точки",
            "logistics.create-route.clearPoints": "Очистити точки",
            "logistics.create-route.togglePointsVisibility": "Приховати/показати точки",
            "logistics.create-route.removeCar": "Видалити машину",
            "logistics.create-route.start": "Початок",
            "logistics.create-route.end": "Кінець",
            "logistics.create-route.zone": "Геозона",
            "logistics.create-route.name": "Назва",

            "logistics.view-route.title": "Маршрут",
            "logistics.view-route.accept": "Приняти",
            "logistics.view-route.print.title": "Маршрутний лист",

            "logistics.toRoutes": "До маршрутів",
            "logistics.totalDistance": "Довжина маршруту",
            "logistics.totalTime": "В дорозі",
            "logistics.estimatedDistance": "Розрахункова відстань",
            "logistics.distanceTraveled": "Пройдена відстань",
            "logistics.car": "Машина",
            "logistics.timeCorrectionInCity": ">% місто",
            "logistics.timeCorrectionOutsideCity": ">% траса",
            "logistics.weigth": "Маса (кг)",
            "logistics.startDateTime": "Час початку",
			"logistics.edit": "Редагувати",
			"logistics.clone": "Копіювати",
			"logistics.delete": "Видалити",
            "logistics.endDateTime": "Час кінця",
            "logistics.totalWeight": "Загальна вага",
            "logistics.status": "Статус",
            "logistics.accepted": "Принято",
            "logistics.non-accepted": "Не принято",
            "logistics.complexity": "Складність",
            "logistics.routePoint.table.title": "Назва",
            "logistics.routePoint.table.address": "Адреса",
            "logistics.routePoint.table.sequenceNumber": "№ п.п.",
            "logistics.routePoint.table.action": "Дія",
            "logistics.routePoint.table.arrival": "Приб.",
            "logistics.routePoint.table.departure": "Відїзд",
            "logistics.routePoint.table.deviation": "Відхилення",
            "logistics.routePoint.table.requestNumber": "№ док.",
            "logistics.routePoint.table.weight": "Вага",
            "logistics.routePoint.table.quantity": "SKU",
            "logistics.routePoint.table.note": "Коментар",
            "logistics.routePoint.table.signature": "Підпис",
            "logistics.routePoint.table.price": "Сума",
            "logistics.routePoint.table.total": "Разом",
            "logistics.routePoint.table.barcode": "Штрихкод",

            "car-info.timestamp": "Час",
            "car-info.sat": "Sat",
            "car-info.dop": "DOP",
            "car-info.azimut": "Ази",
            "car-info.kmh": "км/г",
            "car-info.v1": "V1",
            "car-info.v2": "V2",
            "car-info.flags": "Прапорці",
            "car-info.gsm": "GSM",
            "car-info.csq": "CSQ",
            "car-info.temperature": "t°",
            "car-info.extra": "Extra",

            "modal.info": "Інформація",
            "modal.close": "Закрити",

            "months.0": "Січень",
            "months.1": "Лютий",
            "months.2": "Березень",
            "months.3": "Квітень",
            "months.4": "Травень",
            "months.5": "Червень",
            "months.6": "Липень",
            "months.7": "Серпень",
            "months.8": "Вересень",
            "months.9": "Жовтень",
            "months.10": "Листопад",
            "months.11": "Грудень",

            "reports.stops.car": "Авто",
            "reports.stops.date": "Дата",
            "reports.stops.expenseCalculation": "Спосіб розрахунку розходу",
            "reports.stops.mileage": "Пробіг (км)",
            "reports.stops.maxSpeed": "Макс швидкість (км/год)",
            "reports.stops.fuelConsumption": "Витрата палива (л)",
            "reports.stops.avFuelConsumption": "Середній розхід на 100 км (л)",
            "reports.stops.engineTime": "Час роботи двигуна",
            "reports.stops.from": "З",
            "reports.stops.to": "По",
            "reports.stops.parkingTime": "P",
            "reports.stops.onWay": "В дорозі",
            "reports.stops.distance": "Км",
            "reports.stops.maxSpeedShort": "Макс км/год",
            "reports.stops.fuelConsumptionShort": "Розхід",
            "reports.stops.address": "Адреса",
            "reports.vehicleNumber": "Номер автомобіля",
            "reports.dates": "Дати",
            "reports.date": "Дата",
            'reports.powerStatus.noData': 'немає даних',
            'reports.powerStatus.noPowerSupply': 'немає електроживлення',
            'reports.powerStatus.reload': 'перезавантаження модему',
            'reports.noGPS': 'Отсутствует GPS связь',
            'reports.onIn': 'спрацював вхід',
            'reports.averageFuelUsage': 'Середнє споживання палива',
            'reports.fuelConsumptionMonitoringDevice': 'Засіб контролю витрат палива',
            'reports.fuelCalculator': 'Паливний калькулятор',
            'reports.impulseSensor': 'Датчик імпульсів',
            'reports.fuelFlowSensor': 'Датчик рівня палива',
            'reports.fuelConsumptionMonitoringDeviceName': 'ДРП/Паливний калькулятор/Датчик імпульсів',
            'reports.tradingAgent': 'Торговий агент',
            'reports.activeStatusOfTradingAgent': 'Активний',
            'reports.page': 'Сторінка',
            'reports.downloadEntityAs': 'Завантажити "{{entityName}}" як {{fileType}}',
            'reports.printAs': 'Роздрукувати "{{entityName}}"',
            "report.fuel.period": "Період",
            "report.fuel.periodStr": "Період: з {{from}} по {{to}}",
            "report.downloadAsXLSX": "Завантажити як XLSX",
            "report.refueled": "Заправлено",
            "report.tank": "Бак",
            "report.number": "№ {{number}}",
            "report.fuelUsage": "Витрати палива",
            "report.liters": "л",
            "report.refueling": "Заправка",
            "report.fuelDrain": "Злив",
            "report.commonReport": "Загальний звіт",

            "vehicle.extra-settings.choose-icon": "Виберіть іконку",
            'vehicle.mileage.dayPeriods': 'Проміжки дня',

            'fuel-drain-metering-mode-switch.off': 'Не враховувати злив палива у витраті',
            'fuel-drain-metering-mode-switch.on': 'Враховувати злив палива у витраті',

            'units.liters': 'л',
            'units.km': 'км',
            'units.hours': 'г',
            'units.speed': 'км/г',

            'currentWeek': 'П.Т.',
            'currentMonth': 'П.М.',

            'vehicleExtraSettings.vehicleType': 'Тип транспортного засобу',
            'vehicleExtraSettings.vehicleType.any': 'Будь-який',
            'vehicleExtraSettings.vehicleType.passenger': 'Пасажирський',
            'vehicleExtraSettings.vehicleType.cargo': 'Вантажний',
            'vehicleExtraSettings.vehicleType.technological': 'Технологічний',
            'vehicleExtraSettings.selectColorBtnTitle': 'Вибрати колір',
            'vehicleExtraSettings.selectIconBtnTitle': 'Вибрати іконку',

            'mapControls.toggleCustomIconsForVehicles': 'Показати/приховати іконки для транспортних засобів',
            'mapControls.toggleVehicleNamesForVehicles': 'Показати/приховати назви авто',
            'mapControls.toggleNamesForGeoZones': 'Показати/приховати назви геозон',
            'mapControls.toggleMapButtonsVisibility': 'Показати/приховати кнопки',
            'mapControls.toggleVisibilityOfGeoZones': 'Показати/приховати геозони',
            'mapControls.toggleFullScreenMode': 'Повноекранний режим',
            'mapControls.trafficJams': 'Затори',
            'mapControls.hybrid': 'Гибрид',

            'general.parking': 'стоянка',

            'cartLegend.limit': 'Межа',

            'vehicleWithControversialPosition': 'Придушення GPS',

            'tokens.isTokenWithoutVehicle': 'Немає транспортних засобів для відображення',
            'tokens.noForwarderRouteDate': 'Дані для маршруту експедитора відсутні',

            'notifications.noGpsConnection': 'Відсутнє GPS з\'єднання',
            'notifications.noGpsConnectionPl': 'Відсутнє GPS з\'єднання Pl',
            'notifications.noGpsCoverage': 'Відсутнє GPS покриття',
            'notifications.speeding': 'Перевищення швидкості',
            'notifications.movement': 'почав рух',
            'notifications.parking': 'став на стоянку',
            'notifications.enterGeozone': 'в\'їхав у геозону',
            'notifications.leaveGeozone': 'виїхав із геозони',
            'notifications.parkingInGeozone': 'став на стоянку в геозоні',
            'notifications.kmPerHour': 'км/г',
            'notifications.currentSpeed': 'Поточна швидкість',
            'notifications.maxSpeed': 'Максимальна швидкість',
            'notifications.settingUpNotificationsForUsers': 'Налаштування повідомлень для користувачів',
            'notifications.successUpdatingOfGlobalUserSettings': 'Налаштування повідомлень для користувачів оновлено',
            'notifications.failedUpdatingOfGlobalUserSettings':
              'Не вдалося оновити налаштування повідомлень для користувачів',
            'notifications.usersSubscribedToNotifications': 'Користувачі підписані на повідомлення',
            'notifications.userNotificationSettings': 'Налаштування повідомлень для користувачів',
            'notifications.usersSubscribedToNotificationsPlaceholder': 'Виберіть користувачів',
            'notifications.failedLoadingInitialDataForUsersSubscribedToNotifications':
              'Не вдалося завантажити налаштування повідомлень для користувачів',
            'notifications.successUpdatingOfUsersSubscribedToNotifications':
              'Налаштування повідомлень для користувачів оновлено',
            'notifications.failedUpdatingOfUsersSubscribedToNotifications':
              'Не вдалося оновити налаштування повідомлень для користувачів',
            'notifications.usersSubscribedToNotifications.reload': 'Завантажи знову',

            'extendedNotificationSettings.noGpsConnection': 'Відсутність зв\'язку',
            'extendedNotificationSettings.noGpsConnectionPl': 'Відсутність зв\'язку Pl',
            'extendedNotificationSettings.noGpsCoverage': 'Відсутність GPS покриття',
            'extendedNotificationSettings.speeding': 'Перевищення швидкості',
            'extendedNotificationSettings.movement': 'Початок руху',
            'extendedNotificationSettings.parking': 'Паркування',
            'extendedNotificationSettings.enterGeozone': 'В\'їзд у геозону',
            'extendedNotificationSettings.leaveGeozone': 'Виїзд із геозони',
            'extendedNotificationSettings.parkingInGeozone': 'Паркування в геозоні',
        });

        $translateProvider.translations('en', {
            'global.currentWeek': 'C.W.',
            'global.currentMonth': 'C.M.',
            'global.type': 'Type',
            "global.load": "Load",
            "global.loginError": "Wrong password!",
            "global.savedSuccess": "Saved successfully!",
            "global.fromDate": "From",
            "global.toDate": "to",
            "global.from": "from",
            "global.to": "to",
            "global.date": "Date",
            "global.time": "Time",
            "global.noViewData": "No data to show",
            "global.ci.mile": "km/h",
            "global.day": "d",
            "global.hours": "h",
            "global.minutes": "min",
            "global.fullDay": "all day",
            "global.inWay": "path",
            "global.address": "Addresses",
            "global.caption": "Name",
            "global.color": "Color",
            "global.logout": "Exit",
            "global.days": "days",
            "global.earlier": "earlier",
            "global.toggleSizeTracker": "map width",
            "global.toggleMap": "Show/Hide map",
            "global.feedback": "Leave feedback",
            "global.copied": "Copied",
            "global.sorting": "Sorting",
            "global.day.0": "Sunday",
            "global.day.1": "Monday",
            "global.day.2": "Tuesday",
            "global.day.3": "Wednesday",
            "global.day.4": "Thursday",
            "global.day.5": "Friday",
            "global.day.6": "Saturday",
            "global.total": "Total:",
            "global.parkingShortName": "Parking",
            "global.visitWithGoogle": "Visit with Google",

            "page.view": "View",
            "page.now": "Now",
            "page.route": "Trip",
            "page.mileage": "Mileage",
            "page.stops": "Stops",
            "page.settings": "Settings",
            "page.control": "CPanel",
            "page.schedules": "Routes",
            "page.cardBalance": "Card balance",
            "page.reports": "Reports",
            "page.logistics": "Logistics",

            "home.subTitle": "Trans-GPS tracking system",
            "home.login.label": "Login",
            "home.password.label": "Password",
            "home.carId.label": "Car ID",

            "action.enter": "Sign in",
            "action.download": "Download",
            "action.save": "Save",
            "action.cancel": "Cancel",
            "action.close": "Close",
            "action.choose": "Choise",
            "action.search": "Search",
            "action.view": "Viev",
            "action.assign": "Assign",
            "action.change": "Change",
            "action.print": "Print",
            "action.add": "Add",
            "action.create": "Create",

            "validation.required": "Required field!",
            "validation.passNoEquals": "Password no Equals!",

            "exportBtn.loadXLS": "Load XLS",
            "exportBtn.saveAsRoute": "Save as Route sheet",
            "exportBtn.loadXLSFuel": "Save as Route sheet",
            "exportBtn.loadFuelCSV": "Save CSV of fuel",
            "exportBtn.printFuel": "Fuel report",
            'exportBtn.stopsGPX': 'Save as GPX',

            "car.all": "All vehicles",
            "car.allGroup": "All Groups",
            "car.name": "Name",
            "car.vehicle": "Vehicle",
            "car.fuel": "Fuel",
            "car.number": "Number",
            "car.dataReceived": "Data received",
            "car.speed": "Speed",
            "car.voltage": "Voltage",
            "car.modem": "Modem",
            "car.latitude": "Latitude",
            "car.longitude": "Longitude",
            "car.height": "Height",
            "car.azimuth": "Azimuth",
            "car.time": "Time",
            "car.accuracy": "Accuracy",
            "car.odometer": "Odometer",
            "car.route.viewData": "Show/Hide Movement Data",
            "car.route.fuelChart": "Show/Hide Fuel Chart",
            "car.route.carInfoChart": "Show/Hide vehicle Chart",
            "car.noMileage": "No Mileage",
            "car.mileage": "Mileage",
            "car.maxSpeed": "Max. speed",
            "car.engineWork": "Engine operation",
            "car.usage": "consumption",
            "car.totalFuelUsage": "Fuel consumption",
            "car.averageFuelUsage": "average",
            "car.totalWay": "Total Way",
            "car.fuelConsumption": "~Consumption",
            "car.maxMile": "Max. (km/h)",
            "car.total": "Total",
            "car.chooseCar": "Choose vehicle",
            "car.fuelChart.settingsRedirect": "Required \"Control parameters\" in vehicle settings",
            "car.fuelPrint.title": "Fuel report",
            "car.fuelPrint.period.title": "Period",
            "car.fuelPrint.period.interval": "from {{from}} to {{to}}",
            "car.fuelPrint.carName": "Vehicle name",
            "car.fuelPrint.fuelOnStart": "Start fuel amount",
            "car.fuelPrint.discharge": "Drained fuel",
            "car.fuelPrint.fuelOnEnd": "End fuel amount",
            "car.fuelPrint.usage": "Consumption",
            "car.fuelPrint.averageFuelUsage": "Consumption avg. on 100km",
            "car.fuelPrint.dischargeToUsage": "Drain included in consumption",
            "car.fuelPrint.dischargeToUsageForAll": "Take into account all fuel drains in consumption",
            "car.fuelPrint.excludeAllFuelDrainsFromFuelUsage": "Fuel fluctuation filter",
            "car.fuelPrint.engine": "Engine",

            "marker.stop": "Stop",
            "marker.move": "In motion",
            "marker.limitSpeed": "Speed warning",
            "marker.notReceptionGps": "no GPS coverage",
            "marker.noConnection": "no GPRS connection",

            "car.settings.simNumber": "SIM number",
            "car.settings.smsPassword": "SMS password",
            "car.settings.softwareVersion": "SW version",
            "car.settings.view": "View setting",
            "car.settings.gosNumber": "Name/Car number",
            "car.settings.short": "Speed warning",
            "car.settings.notes": "Notes",
            "car.settings.speedLimit": "Speed limit",
            "car.settings.limitation": "Warning",
            "car.settings.limit": "Limit",
            "car.settings.totalFuelUsage": "Fuel consumption",
            "car.settings.summerCity": "Summer/City",
            "car.settings.summerRoad": "Summer/Road",
            "car.settings.winterCity": "Winter/City",
            "car.settings.winterRoad": "Winter/Road",
            "car.settings.filterMarkers": "Markers Filter",
            "car.settings.combineFuelTanks": "Combine tanks",
            "car.settings.simBalance": "Check SIM balanse",
            "car.settings.ussdRequest": "USSD request",
            "car.settings.viewUssdArchive": "Show USSD archive",
            "car.settings.response": "Response",
            "car.settings.car": "car",
            "car.settings.specialCar": "special equipment",
            "car.settings.stationaryCar": "stationary object",
            "car.settings.passengerCar": "passenger car",
            "car.settings.cargoCar": "cargo car",
            "car.settings.carryingCapacity": "Carrying Capacity",
            "car.settings.amount": "Compartment Volume",
            "car.settings.impulseVolume": "Pulse volume",
            "car.settings.odometer": "Odometer",
            "car.settings.reset": "Reset",
            "car.settings.reset.confirm": "Are you sure?",
            "car.settings.fuelCapacity": "Fuel capacity {{tank}}, (L)",
            "car.settings.controlParam": "Control parameters",
            "car.settings.usageNorm": "Consumption rate (L/H)",
            "car.settings.usageIdle": "Idle consumption (L/H)",
            "car.settings.shutdownLimit": "Consumption deviation (%)",
            "car.settings.deleteDevice": "Remove tracker from login",
            "car.settings.deleteDevice.confirm": "Are you sure you want to delete the tracker?",
            "car.settings.deleteDevice.success": "Tracker deleted successfully!",
            "car.settings.output": "Remote output:",
            "car.settings.subscriberPlan": "Subscriber plan",
            "car.settings.noSubscriberPlan": "No plan",
            "car.settings.remoteControl": "Remote control",
            "car.settings.open": "Open",
            "car.settings.signal": "Signal",
            "car.settings.close": "Clouse",
            "car.settings.salesMan": "SalesMan",
            "car.settings.isWorking": "Is Woring?",
            "car.settings.timerWakeup": "Timer Wakeup",
            "car.settings.turnOnGPS": "Turn on GPS",
            "car.settings.connectToServer": "Connect to server",
            "car.settings.receiveSMS": "Receive SMS during sleep",
            "car.settings.timeout": "Timeout",
            "car.settings.baterySaving": "Batery Saving",
            'car.settings.gpsControl': 'Control GPS',
            'car.settings.v2Fuel': 'Fuel Calculate (v2)',
            'car.settings.notificationsSectionTitle': 'Notifications',
            'car.settings.turnOnNotifications': 'Turn on notifications',
            'car.settings.turnOffNotifications': 'Turn off notifications',
            'car.settings.turnOnGeozonesTransit': 'Turn on geozones passage notifications',
            'car.settings.turnOffGeozonesTransit': 'Turn off geozones passage notifications',
            'car.settings.failedFetchingNotificationSettingsMsg': 'Failed to get notification settings',
            'car.settings.notificationsEnabledMsg': 'Notifications enabled',
            'car.settings.notificationsDisabledMsg': 'Notifications disables',
            'car.settings.failedSavingNotificationSettingsMsg': 'Failed to save notification settings',

            'car.settings.notificationAboutTransitsThroughGeozonesSettingsEnabledMsg':
              'Geozones passage notifications enabled',
            'car.settings.notificationAboutTransitsThroughGeozonesSettingsDisabledMsg':
                'Geozones passage notifications disabled',
            'car.settings.failedSavingNotificationAboutTransitsThroughGeozonesSettingsMsg':
              'Failed to save geozones passage notification settings',

            'car.settings.addTelegramNotifications': 'Subscribe to notifications',

            "settings.toTrack": "All vehicles",
            "settings.all": "All",
            "settings.geozones": "GeoZones",
            "settings.users": "Users",
            "settings.caption": "User control panel",
            "settings.addedCar": "Add tracker",
            "settings.factoryLogin": "Factory login",
            "settings.factoryPass": "Factory password",
            "settings.contacts": "User contacts",
            "settings.email": "Email",
            "settings.tel": "Tel",
            "settings.changePass": "Change password",
            "settings.passOld": "Old password",
            "settings.passNew": "New password",
            "settings.passReNew": "Repeat new password",
            "settings.intervals": "Day intervals",
            "settings.night": "Night",
            "settings.day": "Day",
            "settings.evening": "evening",
            "settings.seasons": "Seasons",
            "settings.summerFrom": "Summer from",
            "settings.winterFrom": "Winter c",

            "settings.subscriptionFee": "Subscription plans",
            "settings.subscriptionFee.allFee": "All in one",
            "settings.subscriptionFee.caption": "Subscription fee",
            "settings.subscriptionFee.period": "Period",
            "settings.subscriptionFee.selectionPeriod": "Selection Period",
            "settings.subscriptionFee.selectionPeriod.previous": "Previous",
            "settings.subscriptionFee.selectionPeriod.selected": "current",
            "settings.subscriptionFee.table.sequenceNumber": "Seq. π",
            "settings.subscriptionFee.table.description": "Descriptions",
            "settings.subscriptionFee.table.price": "Price",
            "settings.subscriptionFee.table.count*price": "count*Price",
            "settings.subscriptionFee.table.amount": "Amount",
            "settings.subscriptionFee.table.total": "Total",
            "settings.subscriptionFee.table.carsWithoutSubscriberPlan": "Vehicle without plans",
            "settings.tokens": "Tokens",
            "settings.tokens.update.success": "Token data was updated successfully",
            "settings.tokens.update.failed": "Failed to update token details",
            "settings.tokens.delete.success": "Token removed",
            "settings.tokens.delete.failed": "Failed to delete token",
            "settings.tokens.confirmRemovalDialogTitle": "Deleting a token",
            "settings.tokens.confirmRemovalDialogTextContent": "Token {{ tokenDetails }} will be removed",
            "settings.tokens.confirmRemovalDialogConfirmBtnTitle": "Delete",
            "settings.tokens.confirmRemovalDialogCancelBtnTitle": "Cancel",
            'settings.tokens.copyUrlWithToken.success': 'Link to page with token copied',
            'settings.tokens.copyUrlWithToken.failed': 'Failed to copy link to page with token',
            'settings.tokens.create.success': 'The token was successfully created.',
            'settings.tokens.create.failed': 'Failed to create token',
            'settings.tokens.emptyTokenName': 'The token name is not specified',
            'settings.tokens.saveChangesTooltip': 'Save changes',
            'settings.tokens.cancelChangesTooltip': 'Cancel changes',
            'settings.tokens.toggleAvailabilityOfTokenTitle': 'On/Off',
            'settings.tokens.copyLinkTitle': 'Copy link',
            'settings.tokens.deleteTokenTitle': 'Delete token',
            'settings.tokens.selectVehiclesTitle': 'Select vehicles',
            'settings.tokens.expirationDateTitle': 'Expiration date',
            'settings.tokens.withUnlimitedValidity': 'Indefinite',
            'settings.tokens.shouldShowShortNamesTitle': 'Short',
            'settings.tokens.forwarderTitle': 'Forwarder',
            'settings.tokens.mapTitle': 'Map',
            'settings.tokens.canNotTurnOnForwarderModeDialogTitle':
              'Can\'t turn on the forwarder mode',
            'settings.tokens.canNotTurnOnForwarderModeDialogContent':
              'Forwarder mode is only allowed for one vehicle',
            'settings.tokens.canNotAddVehicleDialogTitle':
              'I can\'t add another car',
            'settings.tokens.canNotAddVehicleDialogContent':
              'When forwarder mode is enabled, only one vehicle is allowed',

            "settings.globalNotificationSettingsTabTitle": "Notifications",
            "settings.globalNotificationSettingsHeader": "Notifications",
            "settings.failedLoadingAllSettings": "Failed to load global settings for notifications",

            "settings.enableNotifications": "Enable notifications",
            "settings.disableNotifications": "Disable notifications",

            "settings.enableNotificationsInTelegram": "Enable notifications in Telegram",
            "settings.disableNotificationsInTelegram": "Disable notifications in Telegram",
            "settings.failedUpdatingNotificationsInTelegramMode": "Failed to update notifications in Telegram",
            "settings.notificationsInTelegramEnabled": "Telegram notifications are enabled",
            "settings.notificationsInTelegramDisabled": "Telegram notifications are disabled",

            "settings.enableNotificationsInBrowser": "Enable notifications in browser",
            "settings.disableNotificationsInTBrowser": "Disable notifications in browser",
            "settings.failedUpdatingNotificationsInBrowserMode": "Failed to update notifications in browser",
            "settings.notificationsInBrowserEnabled": "Browser notifications are enabled",
            "settings.notificationsInBrowserDisabled": "Browser notifications are disabled",

            "settings.saving": "Saving...",

            "geozone.caption": "Setting geozones",
            "user.caption": "Child user management",
            "user.currentUserCaption": "Current user management",
            "user.settings": "User settings",
            "user.blocked": "User blocking",
            "user.permissions": "User permissions",
            "user.geozone": "Geozone",
            "user.viewControl": "Control of visits to geozones",
            "user.routeSheet": "Route sheet",
            "user.reports": "Repors",
            "user.logistics": "Logistics",
            "user.settings.subscriberPlan": "Subscriber plan",

            "user.settings.plan": "Plan",
            "user.settings.plan.bp": "BP",
            "user.settings.plan.bp+": "BPD",
            "user.settings.plan.bpr": "BPR",
            "user.settings.plan.bp+r": "BP+R",
            "user.settings.plan.bprt": "BPRT",
            "user.settings.plan.bprt+": "BPRT+",
            "user.settings.plan.bprd": "BPRD",
            "user.settings.plan.bpo": "BPO",
            "user.settings.plan.165": "165",
            "user.settings.plan.200": "200",
            "user.settings.plan.250": "250",
            "user.settings.plan.70": "70",
            "user.settings.plan.notMoving": "NM-30",

            "user.settings.category": "Category",
            "user.settings.category.monthly": "Monthly",
            "user.settings.category.quarterly": "Quarterly",
            "user.settings.category.semiannual": "Semiannual",
            "user.settings.category.annual": "Annual",
            "user.settings.year": "Year",
            "user.settings.sequenceNumber": "œÕ",
            "user.settings.loginDescription": "Description",
            "user.settings.group": "Group",
            "user.settings.fuel": "Fuel",

            "schedule.appointment": "Appointment",
            "schedule.history": "Appointment history",
            "schedule.lists": "Route sheets",

            "chart.fuelUsage": "Additional tank",

            "logistics.routes.title": "Routes",
            "logistics.routes.createRoute": "Create",
            "logistics.routes.periods.day": "Day",
            "logistics.routes.periods.week": "Week",
            "logistics.routes.periods.month": "Month",
            "logistics.routes.periods.all": "All",
            "logistics.routes.table.title": "Name",

            "logistics.create-route.title": "Route create",
            "logistics.create-route.specify": "Specify",
            "logistics.create-route.cancel": "Cancel",
            "logistics.create-route.startDate": "Date",
            "logistics.create-route.startTime": "Time",
            "logistics.create-route.parkingTime": "Parking time",
            "logistics.create-route.parking": "Parking (min)",
            "logistics.create-route.save": "Save",
            "logistics.create-route.redraw": "Redraw",
            "logistics.create-route.optimal": "Optimal",
            "logistics.create-route.showPoint": "Swow",
            "logistics.create-route.deletePoint": "Delete",
            "logistics.create-route.addPoint": "Add point",
            "logistics.create-route.removeUndeterminated": "Remove unknown",
            "logistics.create-route.build": "Build",
            "logistics.create-route.loadFile": "Load file",
            "logistics.create-route.selectPoints": "Select point",
            "logistics.create-route.clearPoints": "Clear point",
            "logistics.create-route.togglePointsVisibility": "Hide/show points",
            "logistics.create-route.removeCar": "Delete car",
            "logistics.create-route.start": "Start",
            "logistics.create-route.end": "End",
            "logistics.create-route.zone": "Geozone",
            "logistics.create-route.name": "Name",

            "logistics.view-route.title": "Route",
            "logistics.view-route.accept": "Accept Route",
            "logistics.view-route.print.title": "Route sheet",

            "logistics.toRoutes": "To routes",
            "logistics.totalDistance": "Route length",
            "logistics.totalTime": "Trip time",
            "logistics.estimatedDistance": "Estimated distance",
            "logistics.distanceTraveled": "Real distance",
            "logistics.car": "Car",
            "logistics.timeCorrectionInCity": ">% Sity",
            "logistics.timeCorrectionOutsideCity": ">% Road",
            "logistics.weigth": "Weigh (kg)",
            "logistics.startDateTime": "Start time",
            "logistics.edit": "Edit",
            "logistics.clone": "Clone",
            "logistics.delete": "Delete",
            "logistics.endDateTime": "End time",
            "logistics.totalWeight": "Total weight",
            "logistics.status": "Status",
            "logistics.accepted": "Accepted",
            "logistics.non-accepted": "Non accepted",
            "logistics.complexity": "Complexity",
            "logistics.routePoint.table.title": "Name",
            "logistics.routePoint.table.address": "Address",
            "logistics.routePoint.table.sequenceNumber": "Seq. π",
            "logistics.routePoint.table.action": "Action",
            "logistics.routePoint.table.arrival": "Arrival",
            "logistics.routePoint.table.departure": "Departure",
            "logistics.routePoint.table.requestNumber": "Req #",
            "logistics.routePoint.table.weight": "Weight",
            "logistics.routePoint.table.quantity": "SKU",
            "logistics.routePoint.table.note": "Note",
            "logistics.routePoint.table.signature": "Signature",
            "logistics.routePoint.table.price": "Price",
            "logistics.routePoint.table.total": "Total",
            "logistics.routePoint.table.barcode": "Barcode",
            "logistics.routePoint.table.deviation": "Shutdowns",

            "car-info.timestamp": "Time",
            "car-info.sat": "Sat",
            "car-info.dop": "DOP",
            "car-info.azimut": "Azimut",
            "car-info.kmh": "km/h",
            "car-info.v1": "V1",
            "car-info.v2": "V2",
            "car-info.flags": "Flags",
            "car-info.gsm": "GSM",
            "car-info.csq": "CSQ",
            "car-info.temperature": "t∞",
            "car-info.extra": "Extra",

            "modal.info": "Info",
            "modal.close": "Close",

            "months.0": "January",
            "months.1": "February",
            "months.2": "March",
            "months.3": "April",
            "months.4": "May",
            "months.5": "June",
            "months.6": "July",
            "months.7": "August",
            "months.8": "September",
            "months.9": "October",
            "months.10": "November",
            "months.11": "December",

            "reports.stops.car": "Car",
            "reports.stops.date": "Date",
            "reports.stops.expenseCalculation": "Way of Expense Calculation",
            "reports.stops.mileage": "Mileage (km)",
            "reports.stops.maxSpeed": "Max Speed (km/h)",
            "reports.stops.fuelConsumption": "Fuel Usage (l)",
            "reports.stops.avFuelConsumption": "Avarage Fuel Usage for 100 km (l)",
            "reports.stops.engineTime": "Engine Working Time",
            "reports.stops.from": "From",
            "reports.stops.to": "To",
            "reports.stops.parkingTime": "P",
            "reports.stops.onWay": "On Way",
            "reports.stops.distance": "Km",
            "reports.stops.maxSpeedShort": "Max km/h",
            "reports.stops.fuelConsumptionShort": "Fuel Usage",
            "reports.stops.address": "Address",
            "reports.vehicleNumber": "Car number",
            "reports.dates": "Dates",
            "reports.date": "Date",
            'reports.powerStatus.noData': 'no data',
            'reports.powerStatus.noPowerSupply': 'no power supply',
            'reports.powerStatus.reload': 'modem reloading',
            'reports.noGPS': 'No GPS',
            'reports.onIn': 'input triggered',
            'reports.averageFuelUsage': 'Average fuel usage',
            'reports.fuelConsumptionMonitoringDevice': 'Fuel consumption monitoring device',
            'reports.fuelCalculator': 'Fuel calculator',
            'reports.impulseSensor': 'Impulse sensor',
            'reports.fuelFlowSensor': 'Fuel flow sensor',
            'reports.fuelConsumptionMonitoringDeviceName': 'DRP/Fuel calculator/Impulse sensor',
            'reports.tradingAgent': 'Trading agent',
            'reports.activeStatusOfTradingAgent': 'Active',
            'reports.page': 'Page',
            'reports.downloadEntityAs': 'Download "{{entityName}}" as {{fileType}}',
            'reports.printAs': 'Print "{{entityName}}"',
            "report.fuel.period": "Period",
            "report.fuel.periodStr": "Period: from {{from}} to {{to}}",
            "report.downloadAsXLSX": "Download as XLSX",
            "report.refueled": "Refueled",
            "report.tank": "Tank",
            "report.number": "# {{number}}",
            "report.fuelUsage": "Fuel usage",
            "report.liters": "l",
            "report.refueling": "Refueling",
            "report.fuelDrain": "Fuel drain",
            "report.commonReport": "General report",

            "vehicle.extra-settings.choose-icon": "Select an icon",
            'vehicle.mileage.dayPeriods': 'Day intervals',

            'fuel-drain-metering-mode-switch.off': 'Do not take fuel drain into account in consumption',
            'fuel-drain-metering-mode-switch.on': 'Take fuel drain into account in consumption',

            'units.liters': 'l',
            'units.km': 'km',
            'units.hours': 'h',
            'units.speed': 'km/h',

            'currentWeek': 'C.W.',
            'currentMonth': 'C.M.',

            'vehicleExtraSettings.vehicleType': 'Vehicle type',
            'vehicleExtraSettings.vehicleType.any': 'Any',
            'vehicleExtraSettings.vehicleType.passenger': 'Passenger',
            'vehicleExtraSettings.vehicleType.cargo': 'Cargo',
            'vehicleExtraSettings.vehicleType.technological': 'Technological',
            'vehicleExtraSettings.selectColorBtnTitle': 'Select color',
            'vehicleExtraSettings.selectIconBtnTitle': 'Select icon',

            'mapControls.toggleCustomIconsForVehicles': 'Show/hide icons for vehicles',
            'mapControls.toggleVehicleNamesForVehicles': 'Show/hide vehicle names',
            'mapControls.toggleNamesForGeoZones': 'Show/hide geozone names',
            'mapControls.toggleMapButtonsVisibility': 'Show/hide controls',
            'mapControls.toggleVisibilityOfGeoZones': 'Show/hide geozones',
            'mapControls.toggleFullScreenMode': 'Fullscreen mode',
            'mapControls.trafficJams': 'Traffic jams',
            'mapControls.hybrid': 'Hybrid',

            'general.parking': 'parking',

            'cartLegend.limit': 'Limit',

            'vehicleWithControversialPosition': 'GPS jamming',

            'tokens.isTokenWithoutVehicle': 'There are no vehicles to display.',
            'tokens.noForwarderRouteDate': 'Forwarder route data is missing',

            'notifications.noGpsConnection': 'No GPS connection',
            'notifications.noGpsConnectionPl': 'No GPS connection Pl',
            'notifications.noGpsCoverage': 'No GPS coverage',
            'notifications.speeding': 'Speeding',
            'notifications.movement': 'started the movement',
            'notifications.parking': 'stopped at the parking lot',
            'notifications.enterGeozone': 'entered the geozone',
            'notifications.leaveGeozone': 'left the geozone',
            'notifications.parkingInGeozone': 'parked in the geozone',
            'notifications.kmPerHour': 'km/h',
            'notifications.currentSpeed': 'Current speed',
            'notifications.maxSpeed': 'Max speed',
            'notifications.settingUpNotificationsForUsers': 'Setting up notifications for users',
            'notifications.successUpdatingOfGlobalUserSettings': 'Notification settings for users have been updated',
            'notifications.failedUpdatingOfGlobalUserSettings': 'Failed to update notification settings for users',
            'notifications.usersSubscribedToNotifications': 'Users subscribed to notifications',
            'notifications.userNotificationSettings': 'Notification settings for users',
            'notifications.usersSubscribedToNotificationsPlaceholder': 'Select users',
            'notifications.failedLoadingInitialDataForUsersSubscribedToNotifications':
              'Failed to load notification settings for users',
            'notifications.successUpdatingOfUsersSubscribedToNotifications':
              'Notification settings for users have been updated',
            'notifications.failedUpdatingOfUsersSubscribedToNotifications':
              'Failed to update notification settings for users',
            'notifications.usersSubscribedToNotifications.reload': 'Reload',

            'extendedNotificationSettings.noGpsConnection': 'No connection',
            'extendedNotificationSettings.noGpsConnection': 'No connection Pl',
            'extendedNotificationSettings.noGpsCoverage': 'No GPS coverage',
            'extendedNotificationSettings.speeding': 'Speeding',
            'extendedNotificationSettings.movement': 'Start of movement',
            'extendedNotificationSettings.parking': 'Parking',
            'extendedNotificationSettings.enterGeozone': 'Entering the geozone',
            'extendedNotificationSettings.leaveGeozone': 'Exit from geozone',
            'extendedNotificationSettings.parkingInGeozone': 'Parking in a geozone',
        });

        $translateProvider.preferredLanguage('ua');
    }

})();
