(function () {
  "use strict";

  angular
    .module('app')
    .component('login', {
      templateUrl: 'app/main/auth/login.html',
      controller: LoginCtrl
    })

    .component('logout', {
      controller: LogoutCtrl
    })
  ;

  LoginCtrl.$inject = ['$state', '$cookies', '$translate', '$mdToast', '$log', '$localStorage', 'Auth'];

  function LoginCtrl($state, $cookies, $translate, $mdToast, $log, $localStorage, Auth) {
    var self = this;

    self.langs = [{code: 'ua'}, {code: 'en'}, {code: 'ru'}];
    self.langs.code = $localStorage.lang || self.langs[0].code;
    $translate.use(self.langs.code);
    
    self.credentials = {
      login: '',
      password: ''
    };

    self.getLinkToIOs = function () {
      switch (self.langs.code) {
        case 'ua':
          return 'uk';
        case 'en':
          return 'en';
        case 'ru':
          return 'ru';
        default:
          return 'uk';
      }
    };

    self.onSelectLang = function (item) {
      // window.location = '/?lang=' + item.code;
      $localStorage.lang = item.code;
      $cookies.put('lang', item.code);
      self.langs.code = item.code;
      $translate.use(self.langs.code);
    };

    self.submitForm = function () {
      // $log.info('LoginCtrl', self.credentials);

      this.showLoader = true;
      Auth.login(self.credentials)
        .then(function (result) {
          if (result) {
            $state.go('vehicles');
          } else {
            onError();
          }
        }, onError)
        .finally(function () {
          self.showLoader = false;
        })
      ;
    };

    function onError (){
      $translate('global.loginError')
        .then(function (result) {
          $mdToast.show($mdToast.simple().content(result).position('bottom left'));
        });
    }
  }

  LogoutCtrl.$inject = ['$state', 'Auth'];

  function LogoutCtrl($state, Auth) {

    this.$onInit = function () {
      Auth.logout();
      $state.go('login');
    };

  }
})();
