(function () {
  "use strict";

  angular
    .module('app')
    .factory('Auth', Auth)

    .constant('AUTH_EVENTS', {
      loginSuccess: 'auth-login-success',
      loginFailed: 'auth-login-failed',
      logoutSuccess: 'auth-logout-success',
      sessionTimeout: 'auth-session-timeout',
      notAuthenticated: 'auth-not-authenticated',
      notAuthorized: 'auth-not-authorized'
    })

    .constant('USER_ROLES', {
      all: '*',
      admin: 'admin',
      editor: 'operator',
      guest: 'guest'
    })
  ;

  Auth.$inject = [
    'BackendApi',
    'Session',
    '$rootScope',
    'AUTH_EVENTS',
    'Utils'
  ];

  function Auth(BackendApi, Session, $rootScope, AUTH_EVENTS, Utils) {
    return {
      login: function (credentials) {
        return BackendApi.login(credentials.login, credentials.password)
          .then(function (response) {
            if (response.session) {
              if (Utils.isTestAccount(response.loginName)) {
                response.cars = _.map(response.cars, function (car) {
                  return {id: car.id, name: 'TEST #' + car.id};
                });
              }

              Session.create(response.session, response);
              $rootScope.$broadcast(AUTH_EVENTS.loginSuccess, credentials.login);
              return response.session;
            }

            $rootScope.$broadcast(AUTH_EVENTS.loginFailed, credentials.login);
            Session.destroy();
            return null;
          });
      },

      logout: function () {
        return BackendApi.logout()
          .then(function () {
            $rootScope.$emit(AUTH_EVENTS.logoutSuccess)
            Session.destroy();
          });
      },

      /**
       * @param sessionCookie
       * @return {Boolean} Success
       */
      tryToRenew: function (sessionCookie) {
        var success = Session.renew(sessionCookie);

        if (success) {
          $rootScope.$broadcast(AUTH_EVENTS.loginSuccess);
        } else {
          $rootScope.$broadcast(AUTH_EVENTS.sessionTimeout);
        }

        return success;
      },

      /**
       * @return {boolean} Authentication status
       */
      isAuthenticated: function () {
        var authenticated = Boolean(Session.user) && Boolean(Session.user.loginName);

        if (!authenticated) {
          $rootScope.$broadcast(AUTH_EVENTS.notAuthenticated);
        }

        return authenticated;
      }
    };
  }

})();
