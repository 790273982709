(function () {
  "use strict";

  angular
    .module('app', [
      'ngSanitize',
      'ngCookies',
      'ngMessages',
      'ui.router',
      'pascalprecht.translate',
      'ngMaterial',
      'nemLogging',
      'ngStorage',
      'uuid4',
      'app.translate',
      'app.vehicles',
      'app.settings',
      'app.logistics',
      'app.reports',
      'colorpicker.module',
      'fuel-drain-metering-mode-switch',
      'vAccordion',
      'vehicles.commonReportDialog',
      'app.vehicles.details.lib.fuel-calculations',
      'app.tokens',
      'app.notificationWatcher'
    ])
    .config(appConfig)
    .run(appRunOptions);

  appConfig.$inject = ['$compileProvider', '$httpProvider', '$mdThemingProvider', '$mdDateLocaleProvider',
    '$localStorageProvider', '$sessionStorageProvider', '$mdGestureProvider'];

  function appConfig($compileProvider, $httpProvider, $mdThemingProvider, $mdDateLocaleProvider,
                     $localStorageProvider, $sessionStorageProvider, $mdGestureProvider) {
    /**
     * It's a hack, because calendar didn't work on Angular 1.6
     * @see https://github.com/angular/material/issues/10168#issuecomment-268886473
     */
    $compileProvider.preAssignBindingsEnabled(true);

    initHttpProvider($httpProvider);

    initNgStorage($localStorageProvider, $sessionStorageProvider);

    // fixed bug with clicking on iphone devices
    $mdGestureProvider.skipClickHijack();

    $mdThemingProvider.theme('default')
      .primaryPalette('blue')
      .accentPalette('orange');

    moment.locale('ru');
    initDateLocale($mdDateLocaleProvider);
  }

  /**
   * @param $httpProvider
   *
   * @description
   * Forces the $http to behave it like a jQuery.
   * @see common/to-param.js
   */
  function initHttpProvider($httpProvider) {
    $httpProvider.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';

    $httpProvider.defaults.transformRequest = [function (data) {
      return angular.isObject(data) && String(data) !== '[object File]' ? angular.toParam(data) : data;
    }];
  }

  /**
   * @param $localStorageProvider
   * @param $sessionStorageProvider
   *
   * @description
   * Set prefixes and own serializer to compress data into storage.
   */
  function initNgStorage($localStorageProvider, $sessionStorageProvider) {
    $localStorageProvider.setKeyPrefix('tg2-');
    $sessionStorageProvider.setKeyPrefix('tg2-');

    $localStorageProvider.setSerializer(serializeAndCompress);
    $localStorageProvider.setDeserializer(deserializeAndDecompress);
    $sessionStorageProvider.setSerializer(serializeAndCompress);
    $sessionStorageProvider.setDeserializer(deserializeAndDecompress);

    function serializeAndCompress(value) {
      return LZString.compressToUTF16(angular.toJson(value));
    }

    function deserializeAndDecompress(value) {
      return angular.fromJson(LZString.decompressFromUTF16(value));
    }
  }

  /**
   * @param $mdDateLocaleProvider
   *
   * Settings for Angular Material calendar
   */
  function initDateLocale($mdDateLocaleProvider) {
    var locale = moment.localeData();

    $mdDateLocaleProvider.months = locale.months().standalone;
    $mdDateLocaleProvider.shortMonths = locale.monthsShort().standalone;
    $mdDateLocaleProvider.days = locale.weekdays().standalone;
    $mdDateLocaleProvider.shortDays = locale.weekdaysShort();
    $mdDateLocaleProvider.firstDayOfWeek = locale.firstDayOfWeek();

    $mdDateLocaleProvider.parseDate = function (dateString) {
      var m = moment(dateString, 'DD/MM', true);

      return m.isValid() ? m.toDate() : new Date(NaN);
    };

    $mdDateLocaleProvider.formatDate = function (date) {
      var m = moment(date);

      return m.isValid() ? m.format('DD/MM') : '';
    };
  }

  appRunOptions.$inject = [
    '$rootScope',
    'Auth.requireAuthHook',
    'notificationWatcher'
  ];

  function appRunOptions($rootScope, requireAuthHook, notificationWatcher) {
    $rootScope.app = {
      title: 'Dashboard'
    };

    requireAuthHook.init()

    notificationWatcher.start()
  }
})();
