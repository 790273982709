(function () {
  "use strict";

  angular
    .module('app.logistics')
    .component('logistics', {
      templateUrl: 'app/logistics/logistics.html',
      controller: LogisticsCtrl,
      controllerAs: 'vm'
    });

  LogisticsCtrl.$inject = ['$scope', '$state', 'PermissionService'];

  function LogisticsCtrl($scope, $state, PermissionService) {
    $scope.$root.app.title = 'page.logistics';

    var vm = this;

    vm.logisticsPages = {
      routes: 1,
      createRoute: 2,
      viewRoute: 3,
      editRoute: 4
    };

    vm.$onInit = function () {
      vm.selectedPage = vm.logisticsPages[$state.current.name.split(".")[1]];
      vm.perm = PermissionService.get();
    };
  }

})();
