(function () {
  "use strict";

  angular
    .module('app.settings', [])
    .config(SettingsRouteConfig);

  SettingsRouteConfig.$inject = [
    '$stateProvider'
  ];

  function SettingsRouteConfig($stateProvider) {
    $stateProvider
      .state('settings', {
        parent: 'main',
        redirectTo: 'settings.all',
        component: 'settings',
        resolve: {}
      })
      .state('settings.all', {
        parent: 'settings',
        url: '/settings/all',
        views: {
          all: 'settingsAll'
        },
        resolve: {
          settings: ['Session', function (Session) {
            return Session.user ? angular.copy(Session.user.settings) : {};
          }]
        }
      })
      .state('settings.zones', {
        parent: 'settings',
        url: '/settings/zones',
        views: {
          zones: 'settingsZones'
        },
        resolve: {
          resolveData: ['Session', function (Session) {
            return Session.user ? {
              list: angular.copy(Session.user.zones),
              login: Session.user.loginName
            } : {};
          }]
        }
      })
      .state('settings.users', {
        parent: 'settings',
        url: '/settings/users',
        views: {
          users: 'settingsUsers'
        },
        resolve: {
          users: ['Session', function (Session) {
            return Session.user ? angular.copy(Session.user.groups) : {};
          }]
        }
      })
      .state('settings.subscriptionFee', {
        parent: 'settings',
        url: '/settings/subscription-fee',
        views: {
          subscriptionFee: 'settingsSubscriptionFee'
        },
        resolve: {
          carSubscriberPlans: ['BackendApi', function (BackendApi) {
            return BackendApi.getCarsSubscriberPlans();
          }]
        }
      })

      // tokens
      .state('settings.tokens', {
        parent: 'settings',
        url: '/settings/tokens',
        views: {
          tokens: 'settingsTokens'
        },
        resolve: {
          vehicles: [
            'Session',
            (Session) => (Session.user || {}).cars
          ]
        }
      })
      // /tokens

      // global notification settings
      .state('settings.globalNotificationSettings', {
        parent: 'settings',
        url: '/settings/notifications',
        views: {
          globalNotificationSettings: 'globalNotificationSettings'
        }
      })
      // /global notification settings
    ;
  }
})();
