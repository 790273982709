(() => {
  angular
    .module('app.vehicles')
    .service('usersSubscribedToNotifications.api', api);

  api.$inject = [
    'BackendApi'
  ]

  function api(backendApi) {
    return {
      getUsersSubscribedToNotificationsData,
      updateUsersSubscribedToNotificationsData,
      getUserNotificationsPreferences,
      updateUserNotificationsPreferences
    }

    function getUsersSubscribedToNotificationsData(vehicleId) {
      return backendApi
        .makeRequest({
          v: 3,
          vehicleNotificationSettingsCmd: 'getUsersSubscribedToNotificationsData',
          vehicleId
        })
    }

    function getUserNotificationsPreferences(vehicleId) {
      return backendApi
        .makeRequest({
          v: 3,
          vehicleNotificationSettingsCmd: 'getUserNotificationsPreferences',
          vehicleId
        })
    }

    function updateUserNotificationsPreferences(vehicleId, telegramUserId, shouldReceiveNotifications, settings) {
      return backendApi
        .makeRequest({
          v: 3,
          vehicleNotificationSettingsCmd: 'updateUserNotificationsPreferences',
          vehicleId,
          telegramUserId,
          shouldReceiveNotifications,
          settings: angular.toJson(settings)
        })
    }

    function updateUsersSubscribedToNotificationsData(vehicleId, data) {
      return backendApi
        .makeRequest({
          v: 3,
          vehicleNotificationSettingsCmd: 'updateUsersSubscribedToNotificationsData',
          vehicleId,
          data: JSON.stringify(data)
        })
    }
  }
})();
