(function () {
  "use strict";

  angular
    .module('app')
    .component('fuelBtn', {
      bindings: {
        id: '<',
        onToggle: '&'
      },
      templateUrl: 'app/component/fuel/fuelBtn.html',
      controllerAs: 'vm',
      controller: FuelBtnCtrl
    });

  FuelBtnCtrl.$inject = ['$rootScope', 'FuelService', 'APP_EVENTS'];

  function FuelBtnCtrl($rootScope, FuelService, APP_EVENTS) {
    var self = this, showEvent;
    this.show = false;

    showEvent = $rootScope.$on(APP_EVENTS.car.fuelBtn, function (e, data) {
      self.isShow = data && data.show;
    });

    this.$onInit = function () {
      FuelService.getFuel(this.id)
        .then(function (result) {
          self.isShow = result;
        });
    };

    this.$onDestroy = function () {
      $rootScope.$broadcast(APP_EVENTS.car.fuelChart, {show: false});
      showEvent();
    };

    this.onClick = function () {
      self.show = !self.show;
      this.onToggle({isShow: self.show});
    };

  }

})();
