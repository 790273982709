(() => {
  angular
    .module('app.settings')
    .component('globalNotificationSettings', {
      controller: Ctrl,
      controllerAs: 'ctrl',
      bindings: {},
      templateUrl: 'app/settings/global-notification-settings/tpl.html'
    });

  Ctrl.$inject = [
    'Session',
    'globalNotificationSettings.api',
    'VehicleSettingsToast'
  ];

  function Ctrl(session, api, toast) {
    const self = this;
    this.userId = session.user.loginName;

    this.isLoadingAllSettings = false;
    this.isLoadingAllSettingsFailed = false;

    this.useNotificationsInTelegram = false;
    this.useNotificationsInBrowser = false;

    this.$onInit = () => {
      fetchAllSettings();
    };

    function fetchAllSettings() {
      self.isLoadingAllSettings = true;

      api.fetchAllSettings(self.userId)
        .then((result) => {
          self.useNotificationsInTelegram = !!result.useNotificationsInTelegram;
          self.useNotificationsInBrowser = !!result.useNotificationsInBrowser;
        })
        .catch((e) => {
          self.isLoadingAllSettingsFailed = true;
          toast.successfulSaving('settings.failedLoadingAllSettings');
        })
        .finally(() => {
          self.isLoadingAllSettings = false;
        });
    }
  }
})();
