(function () {
  "use strict";

  angular
    .module('app.vehicles')
    .factory('CarService', CarService)
  ;

  CarService.$inject = ['$rootScope', '$parse', '$mdDialog', 'PeriodFilters', 'ResponseParser', 'Session', 'Utils', 'app/main/services/responseParserUtils'];

  function CarService($rootScope, $parse, $mdDialog, PeriodFilters, ResponseParser, Session, Utils, responseParserUtils) {
    var service = {};

    service.showSchedulesDialog = function () {
      $mdDialog.show({
        controller: CarScheduleCtrl,
        templateUrl: 'app/vehicles/view/car-schedule-dialog.html'
      });
    };

    service.showCardBalanceDialog = function () {
      $mdDialog.show({
        controller: CardBalanceCtrl,
        templateUrl: 'app/vehicles/view/card-balance-dialog.html'
      })
        .catch(function (result) {
          $rootScope.$broadcast('report:close');
        })
      ;
    };

    service.showLogisticsDialog = function () {
      $mdDialog.show({
        controller: LogisticsOldCtrl,
        controllerAs: 'vm',
        templateUrl: 'app/vehicles/view/logistics-dialog.html',
        fullscreen: true
      });
    };

    service.setFormFilter = function (filter, type) {
      var from = filter.from,
        to = filter.to,
        tmp, result = {}
      ;

      switch (type) {
        case 'dayLeft':
          tmp = moment(from).subtract(1, 'day');
          result.from = tmp.startOf('day').toDate();
          result.to = tmp.endOf("day").startOf("second").toDate();
          break;
        case 'dayLeftFrom':
          tmp = moment(from).subtract(1, 'day');
          result.from = tmp.startOf('day').toDate();
          result.to = to;
          break;
        case 'dayLeftTo':
          tmp = moment(to).subtract(1, 'day');
          result.from = from;
          result.to = tmp.endOf("day").startOf("second").toDate();
          break;
        case 'dayRight':
          tmp = moment(from).add(1, 'day');
          result.from = tmp.startOf('day').toDate();
          result.to = tmp.endOf("day").startOf("second").toDate();
          break;
        case 'dayRightFrom':
          tmp = moment(from).add(1, 'day');
          result.from = tmp.startOf('day').toDate();
          result.to = to;
          break;
        case 'dayRightTo':
          tmp = moment(to).add(1, 'day');
          result.from = from;
          result.to = tmp.endOf("day").startOf("second").toDate();
          break;
        case 'dayNow':
          result.from = moment().startOf('day').toDate();
          result.to = moment().endOf("day").toDate();
          break;
        case 'weekLeft':
          tmp = moment(from).subtract(1, 'week');
          result.from = tmp.startOf('week').toDate();
          result.to = tmp.endOf("week").toDate();
          break;
        case 'weekNow':
          result.from = moment().startOf('week').toDate();
          result.to = moment().endOf("week").toDate();
          break;
        case 'weekRight':
          tmp = moment(from).add(1, 'week');
          result.from = tmp.startOf('week').toDate();
          result.to = tmp.endOf("week").toDate();
          break;
        case 'monthLeft':
          tmp = moment(from).subtract(1, 'month');
          result.from = tmp.startOf('month').toDate();
          result.to = tmp.endOf("month").toDate();
          break;
        case 'monthNow':
          result.from = moment().startOf('month').toDate();
          result.to = moment().endOf("month").toDate();
          break;
        case 'monthRight':
          tmp = moment(from).add(1, 'month');
          result.from = tmp.startOf('month').toDate();
          result.to = tmp.endOf("month").toDate();
          break;
      }

      return result;
    };

    service.onSetFilterTime = function (time, filterFrom) {
      var filter = {};
      var period = time.split('-'),
        from = period[0].split(':'),
        to = period[1] > '23:59' ? ['23', '59'] : period[1].split(':');

      filter.from = moment(filterFrom).hours(from[0]).minute(from[1]).toDate();
      filter.to = moment(filterFrom).hours(to[0]).minute(to[1]).toDate();
      return filter;
    };

    service.getRoutes = function (request, filter, id) {
      var addrType = Session.addressType;
      var fullDays = PeriodFilters.getFullDays(filter);
      var hasManyDays = fullDays.length > 1;
      var requests = fullDays.map(function (day) {
        return request(id, day);
      });
      if (hasManyDays) {
        requests.push(request(id, filter));
      }
      return Promise.all(requests).then(function (data) {
        if (hasManyDays) {
          var summary = data.pop().summary;
          var stops = _.flatten(
            _.map(data, function (item) {
              return item.stops;
            })
          );
          var parsedDataList = _.map(data, function (item, index) {
            var list = ResponseParser.parseSingleDeviceRoute(item, undefined, addrType);
            if (!item.stops.length) {
              list.date = moment(fullDays[index].from).format('YYYY.MM.DD');
              return list;
            }
            return list;
          });
          return {
            stops: stops,
            summary: summary,
            data: parsedDataList,
            id: id
          };
        }
        return ResponseParser.parseSingleDeviceRoute(data[0], undefined, addrType);
      });
    };

    service.getIntervals = function (type) {
      var intervals = $parse('user.settings.intervals')(Session) || null,
        nFrom = intervals ? formatTimeString($parse('night.from')(intervals)) : '00:00',
        nTo = intervals ? formatTimeString($parse('night.to')(intervals)) : '06:00',
        dFrom = intervals ? formatTimeString($parse('day.from')(intervals)) : '06:00',
        dTo = intervals ? formatTimeString($parse('day.to')(intervals)) : '19:00',
        eFrom = intervals ? formatTimeString($parse('evening.from')(intervals)) : '19:00',
        eTo = intervals ? formatTimeString($parse('evening.to')(intervals)) : '24:00';
      if (type == 'string') {
        return {
          day: [dFrom, dTo].join('-'),
          evening: [eFrom, eTo].join('-'),
          night: [nFrom, nTo].join('-'),
        };
      } else if (type == 'time') {
        return {
          day: parseTime(dFrom, dTo),
          evening: parseTime(eFrom, eTo),
          night: parseTime(nFrom, nTo),
        };
      }

      function formatTimeString(timeString) {
        var lastIndex = timeString.lastIndexOf(':');
        if (lastIndex > 2) {
          return timeString.slice(0, lastIndex);
        }
        return timeString;
      }

      function parseTime(from, to) {
        return {
          from: moment()
            .set({ hour: from.split(':')[0], minute: from.split(':')[1] })
            .startOf('minute')
            .toDate(),
          to: moment()
            .set({ hour: to.split(':')[0], minute: to.split(':')[1] })
            .startOf('minute')
            .toDate(),
        };
      }
    };

    service.detectParking = function (points, stops) {
      points = points || [];
      stops = stops || [];
      return _.map(points, function (point) {
        if (point.speed === 0 && point.gpsStatus > 0) {
          _.find(stops, function (stop) {
            if (stop.status == '2' && point.timestamp >= stop.timestampFrom && point.timestamp < stop.timestampTo) {
              point.isParking = true; // view yellow marker for stop
              point.stopTime = stop.stopTime;
              return true;
            }
          });
        }
        return point;
      });
    };

    /*service.getFuelListForPrint = function (route, daysSummary, isDischargeToUsage) {
      var prevEl, startEl,
        usage = 0, usage1 = 0, usage2 = 0,
        merged = 0, merged1 = 0, merged2 = 0,
        refilled = 0, refilled1 = 0, refilled2 = 0,
        averageUsage = 0, distance = 0, engine = 0, result;

      result = _.reduce((route.stops || []), function (res, el) {
        if (el.status == 2 || el.status == 3) {
          if (!startEl) {
            startEl = el;
            if (daysSummary[startEl.fromDate]) {
              engine = +daysSummary[startEl.fromDate].engine;
            }
          }

          if (moment(startEl.fromDate, 'DD.MM.YYYY') < moment(el.fromDate, 'DD.MM.YYYY')) {
            res.push(getFuelPrintItem());
            startEl = el;
            usage = usage1 = usage2 = merged = merged1 = merged2 = refilled = refilled1 = refilled2 = distance = averageUsage = 0;
            if (daysSummary[startEl.fromDate]) {
              engine = +daysSummary[startEl.fromDate].engine;
            }
          }
          prevEl = el;

          if (el.fuelFrom) {
            if (el.status == 2) {
              if (el.fuelFrom > el.fuelTo) {
                if (Utils.isDraining(route.summary.fuelSettings.idle, el.engine, el.fuelFrom - el.fuelTo)) {
                  merged1 += el.fuelFrom - el.fuelTo;
                }
                isDischargeToUsage && (usage1 += Math.abs(el.fuelFrom - el.fuelTo));
              }
              if (el.fuelFrom < el.fuelTo) {
                refilled1 += el.fuelTo - el.fuelFrom;
              }
            } else {
              // if (el.fuelFrom > el.fuelTo) {
                usage1 += el.fuelFrom - el.fuelTo;
              // }
            }
          }
          if (el.secondFuelFrom) {
            if (el.status == 2) {
              if (el.secondFuelFrom > el.secondFuelTo) {
                merged2 += el.secondFuelFrom - el.secondFuelTo;
                isDischargeToUsage && (usage2 += Math.abs(el.secondFuelFrom - el.secondFuelTo));
              }
              if (el.secondFuelFrom < el.secondFuelTo) {
                refilled2 += el.secondFuelTo - el.secondFuelFrom;
              }
            } else {
              // if (el.secondFuelFrom > el.secondFuelTo) {
                usage2 += el.secondFuelFrom - el.secondFuelTo;
              // }
            }
          }

          el.distance && (distance += el.distance);
        }
        return res;
      }, []);

      startEl && result.push(getFuelPrintItem());
      var fields = ['usage', 'usage1', 'usage2', 'merged', 'merged1', 'merged2', 'refilled', 'refilled1', 'refilled2', 'distance', 'engineSec', 'averageUsage'],
        summary = result.reduce(function (res, el) {
          fields.forEach(function (item) { res[item] += el[item]; });
          return res;
        }, fields.reduce(function (res, el) {
          res[el] = 0;
          return res;
        }, {}))
      ;
      summary.distance = summary.distance.toFixed(1);
      summary.engine = formatPeriod(summary.engineSec);
      summary.averageUsage = (summary.usage * 100 / +summary.distance).toFixed(1);

      return {
        isOneTank: startEl ? !(startEl.secondFuelFrom && startEl.secondFuelTo) : true,
        list: result,
        summary: summary
      };

      function formatPeriod(period) {
        var duration = moment.duration(period, 'seconds'),
          days = duration.days(),
          hours = duration.hours(),
          minutes = ('0' + duration.minutes()).slice(-2)
        ;

        return days * 24 + hours + ':' + minutes;
      }

      function getFuelPrintItem () {
        var fuelStart = route.summary.fuelStart || ((startEl.fuelFrom || 0) + (startEl.secondFuelFrom || 0)),
          fuel1Start = route.summary.fuelStart ||startEl.fuelFrom || 0,
          fuel2Start = startEl.secondFuelFrom || 0,
          fuelEnd = route.summary.fuelEnd || ((prevEl.fuelTo || 0) + (prevEl.secondFuelTo || 0)),
          fuel1End = route.summary.fuelEnd || prevEl.fuelTo || 0,
          fuel2End = prevEl.secondFuelTo || 0
        ;
        
        usage1 += responseParserUtils.getFuelCorrection(route);

        return {
          date: startEl.fromDate,
          fuelStart: fuelStart,
          fuel1Start: fuel1Start,
          fuel2Start: fuel2Start,
          fuelEnd: fuelEnd,
          fuel1End: fuel1End,
          fuel2End: fuel2End,
          usage: usage1 + usage2,
          usage1: usage1,
          usage2: usage2,
          merged: merged1 + merged2,
          merged1: merged1,
          merged2: merged2,
          refilled: refilled1 + refilled2,
          refilled1: refilled1,
          refilled2: refilled2,
          distance: +distance.toFixed(1),
          engine: formatPeriod(engine),
          engineSec: engine,
          averageUsage: distance ? +((usage1 + usage2) * 100 / distance).toFixed(1) : 0
        };
      }
    };*/

    return service;
  }

  CarScheduleCtrl.$inject = ['$scope', '$q', '$filter', '$mdDialog', '$mdToast', 'Session', 'BackendApi'];

  function CarScheduleCtrl($scope, $q, $filter, $mdDialog, $mdToast, Session, BackendApi) {

    init();

    $scope.onCancel = function () {
      $mdDialog.cancel();
    };

    $scope.onSaveSchedule = function () {
      BackendApi.setSchedules($scope.schedule)
        .then(function () {
          showToastSuccess();
        });
    };

    $scope.onSaveAssign = function () {
      BackendApi.setSchedulesAssigned($scope.assign)
        .then(function (result) {
          showToastSuccess();
          $scope.assigns = parseAssigns(result, $scope.tmp.schedules);
        });
    };

    $scope.onRemoveZone = function (item) {
      console.log('--------------- onRemoveZone', item);
    };

    function init() {
      $scope.tmp = {
        zones: angular.copy(Session.user.zones),
        cars: angular.copy(Session.user.cars)
      };
      $scope.assign = {
        date: moment().startOf('day').toDate()
      };
      $scope.schedule = {};
      $q.all([
        BackendApi.getSchedules(),
        BackendApi.getSchedulesAssigned()
        // $q.when([{"carId":1388,"id":61,"date":"2017-07-12","scheduleId":8,"login":"zahidtest"},{"carId":1388,"id":62,"date":"2017-07-12","scheduleId":23,"login":"zahidtest"},{"carId":2966,"id":63,"date":"2017-07-12","scheduleId":23,"login":"zahidtest"}])
      ])
        .then(function (result) {
          console.log('--------------- result', result);
          $scope.assigns = parseAssigns(result[1], result[0]);
          // $scope.assigns = [{"carId":0,"id":53,"date":"2017-07-10","scheduleId":8,"login":"zahidtest"},{"carId":2913,"id":54,"date":"2017-07-10","scheduleId":8,"login":"zahidtest"},{"carId":2913,"id":55,"date":"2017-07-10","scheduleId":23,"login":"zahidtest"},{"carId":1388,"id":56,"date":"2017-07-10","scheduleId":8,"login":"zahidtest"},{"carId":0,"id":57,"date":"2017-07-10","scheduleId":8,"login":"zahidtest"},{"carId":1388,"id":58,"date":"2017-07-10","scheduleId":8,"login":"zahidtest"}];
          $scope.tmp.schedules = result[0] || [];
        });
    }

    function parseAssigns(assigns, schedules) {
      return _.map(assigns, function (el) {
        var findSchedule = _.findWhere(schedules, {id: el.scheduleId.toString()}) || {};
        el.scheduleName = findSchedule.name;
        return el;
      });
    }

    function showToastSuccess() {
      var toast = $mdToast.simple().content($filter('translate')('global.savedSuccess')).position('bottom left');
      $mdToast.show(toast);
    }

  }

  CardBalanceCtrl.$inject = ['$scope', '$state', '$mdDialog', 'BackendApi', '$q'];

  function CardBalanceCtrl($scope, $state, $mdDialog, BackendApi, $q) {

    $scope.showLoader = true;
    $scope.sorting = {
      isAscSorting: true,
      sortingField: 'first',
      sortingParam: 'first'
    };

    $scope.onCancel = function () {
      $mdDialog.cancel();
    };

    $scope.onSelectCar = function (cars) {
      getCardBalance(cars);
    };

    $scope.onGoToCar = function (item) {
      $state.go('vehicles.details.now', {id: item.id}, {reload: true});
      $scope.onCancel();
    };

    function getCardBalance(cars) {
      $q.all([
        BackendApi.getCarNow(0),
        BackendApi.getCardBalance({
          list: _.map(cars, function (el) {
            return el.id;
          }).join(',')
        })
      ]).then(function (result) {
        var carsData = result[0];
        $scope.list = _.map(result[1], function (el) {
          var carData = _.find(carsData, function (item) {
            return +item.id === +el.id;
          });
          if (carData) {
            el.className = carData.className;
            el.dateView = carData.dateView;
            el.timestamp = carData.timestamp;
          }
          el.first = el.summary[0];
          _.each(el.summary, function (val, pos) {
            el.summary[pos] = el.summary[pos] == -1 ? "--" : el.summary[pos];
          });
          return el;
        });
      }).finally(function () {
        $scope.showLoader = false;
      });

      $scope.setSoringField = function (field) {
        if ($scope.sorting.sortingField === field) {
          $scope.sorting.isAscSorting = !$scope.sorting.isAscSorting;
        } else {
          $scope.sorting.sortingField = field;
          $scope.sorting.isAscSorting = true;
        }
        $scope.sorting.sortingParam = ($scope.sorting.isAscSorting ? '' : '-') + $scope.sorting.sortingField;
      };
    }
  }

  LogisticsOldCtrl.$inject = ['scope', '$mdDialog', 'BackendApi', 'Session'];

  function LogisticsOldCtrl(scope, $mdDialog, BackendApi, Session) {
    var self = this;

    this.onCancel = function () {
      $mdDialog.cancel();
    };

    this.onChangeInput = function (ev) {
      console.log('--------------- ev', ev);
      if (ev.files && ev.files.length) {
        self.showLoader = true;
        Papa.parse(ev.files[0], {
          encoding: 'Windows-1251',
          complete: function (result) {
            console.log('--------------- result', result);
            if (result && result.data && result.data.length) {
              self.listHeaders = result.data[0];
              self.list = result.data.slice(1);
            }
            self.showLoader = false;
            scope.$digest();
          }
        });
      }
    };

    this.$onInit = function () {
      this.tmp = {};
      this.zonesOrg = angular.copy(Session.user.zones);
    };

  }

})();
