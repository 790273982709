(() => {
  angular
    .module('app.vehicles')
    .component('toggleFuelReportViewBtn', {
      controller: Ctrl,
      controllerAs: 'ctrl',
      templateUrl: 'app/vehicles/details/lib/with-fuel-report-view/toggle-btn/tpl.html'
    });

  Ctrl.$inject = [
    '$rootScope',
    'withFuelReportView.events',
    'app.vehicles.withFuelReportView'
  ];

  function Ctrl($rootScope, events, withFuelReportView) {
    const unsubscribe = [];

    this.isActive = withFuelReportView.isFuelReportViewActive();

    this.$onInit = () => {
      unsubscribe.push($rootScope.$on(events.changed, this.onAppearanceModeChanged));
    };

    this.$onDestroy = () => {
      while (unsubscribe.length) {
        unsubscribe.pop()();
      }
    };

    this.onBtnPressed = () => {
      $rootScope.$emit(events.toggle);
    };

    this.onAppearanceModeChanged = () => {
      this.isActive = withFuelReportView.isFuelReportViewActive();
    };
  }
})();
