(() => {
  angular
    .module('app.vehicles')
    .service('app.vehicles.withFuelReportView', withFuelReportView);

  withFuelReportView.$inject = [
    '$rootScope',
    'withFuelReportView.events'
  ];

  function withFuelReportView($rootScope, events) {
    let ctrl;
    let unsubscribe = [];

    return {
      create,
      destroy,
      isFuelReportViewActive
    };

    function create(ctrlInstance) {
      ctrl = ctrlInstance;

      ctrl.isFuelReportViewActive = false;
      unsubscribe = [];

      unsubscribe.push(
        $rootScope.$on(events.toggle, onToggleAppearanceOfView)
      );
    }

    function destroy() {
      while (unsubscribe.length) {
        unsubscribe.pop()();
      }
    }

    function onToggleAppearanceOfView() {
      ctrl.isFuelReportViewActive = !ctrl.isFuelReportViewActive;
      $rootScope.$emit(events.changed, ctrl.isFuelReportViewActive);
    }

    function isFuelReportViewActive() {
      return ctrl.isFuelReportViewActive;
    }
  }
})();
