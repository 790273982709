(function () {
  "use strict";

  angular
    .module('app')
    .directive('checkPassword', checkPassword)
    .directive('listLazyLoad', ListLazyLoad)
    .directive('ngEnter', ngEnter)
  ;

  function checkPassword () {
    return {
      scope: {
        pass: "=checkPassword"
      },
      require: 'ngModel',
      link: function (scope, element, attrs, ngModel) {

        element.bind("keyup", function () {
          scope.$apply(function () {
            ngModel.$setValidity('passmatch', ngModel.$viewValue === scope.pass);
            ngModel.$setDirty(true);
            ngModel.$setTouched(true);
          });
        });
      }
    };
  }


  function ListLazyLoad () {
    return {
      restrict: 'E',
      replace: true,
      template: '' +
      '<div class="list-lazy-load layout-align-center-center layout-row" ng-show="vm.showLazyLoad">' +
        '<div class="loader"></div>' +
        '<div class="list-lazy-load-text"><span translate="global.load"></span>...</div>' +
      '</div>',
      link: function (scope, element, attrs) {
        element.ready(function () {
          var $scrollableBlk = element.parent(),
            scrollableBlk = $scrollableBlk[0],
            wHeight = scrollableBlk.clientHeight
          ;

          var windowScroll = function (ev) {
            var scrollTop = scrollableBlk.scrollTop, type;
            if (scrollTop <= 10) {
              type = 'top';
            } else if (wHeight + scrollTop >= scrollableBlk.scrollHeight - 20) {
              type = 'next';
            }
            if (type) {
              scope.vm.onLazyLoad({type: type});
              scope.$digest();
              // scope.$emit('list:lazyLoad:next');
            }
          };

          var windowResize = function (ev) {
            wHeight = scrollableBlk.clientHeight;
            windowScroll(ev);
          };

          var debounceScroll = _.debounce(windowScroll, 200);
          var debounceResize = _.debounce(windowResize, 200);

          if (scope.vm && scope.vm.onLazyLoad) {
            $scrollableBlk.bind("scroll", debounceScroll);
            $scrollableBlk.bind('resize', debounceResize);
          }

          scope.$on('$destroy', function (event) {
            $scrollableBlk.unbind('scroll', debounceScroll);
            $scrollableBlk.unbind('resize', debounceResize);
          });

        });
      }
    };
  }

  function ngEnter() {
    return function (scope, element, attrs) {
      element.bind("keydown keypress", function (event) {
        if (event.which === 13) {
          scope.$apply(function () {
            scope.$eval(attrs.ngEnter, {event: event});
          });

          event.preventDefault();
        }
      });
    };
  }

})();
