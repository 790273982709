(() => {
  angular
    .module('app.vehicles')
    .service('app.reports.fuel.reportDataBuilder', reportDataBuilder);

  reportDataBuilder.$inject = [
    '$translate',
    'BackendApi',
    'app.vehicles.details.lib.fuel-calculations.fuel-calculations',
  ];

  function reportDataBuilder($translate, BackendApi, fuelCalculations) {
    return {
      createFileName,
      createPageHeader,
      createPageSubHead,
      createMainTableData
    };

    function createFileName(data) {
      return [
        data.vehicle.name.split('.').join(' '),
        'fuel',
        data.rawFrom.format('YYYY-MM-DD'),
        data.rawTo.format('YYYY-MM-DD'),
      ].join('_');
    }

    function createPageHeader() {
      return $translate.instant('car.fuelPrint.title');
    }

    /**
     * @param {Object} data
     * @param {number} data.from
     * @param {number} data.to
     * @param {Object} data.vehicle
     * @param {string} data.vehicle.id
     * @param {string} data.vehicle.name
     */
    function createPageSubHead(data) {
      return {
        from: moment(data.from).format('L HH:mm'),
        to: moment(data.to).format('L HH:mm'),
        vehicleName: data.vehicle.name || data.vehicle.id || ''
      };
    }

    /**
     * @param {Object} data
     * @param {Object} data.vehicle
     * @param {number | string} data.vehicle.id
     * @param {number} data.from
     * @param {number} data.to
     */
    function createMainTableData(data) {
      const from = moment(data.from);
      const to = moment(data.to).endOf('day');

      const daysCount = to.diff(from, 'days');
      const requestFilters = [];
      for (let i = 0; i <= daysCount; i++) {
        const dateFrom = i === 0 ? from : from.clone().add(i, 'days').startOf('day');
        const dateTo = i === daysCount ? to : dateFrom.clone().endOf('day');
        requestFilters.push({
          from: dateFrom.toDate(),
          to: dateTo.toDate()
        });
      }

      return Promise
        .all(requestFilters.map((requestFilter) => BackendApi.getStops(data.vehicle.id, requestFilter)))
        .then((results) => {
          /*const daysSummary = results.reduce((acc, result, index) => {
            return Object.assign(
              acc,
              {
                [moment(requestFilters[index].from).format('DD.MM.YYYY')]: result.summary
              }
            );
          }, {});


          const data = CarService.getFuelListForPrint(route, daysSummary);
          if (data.isOneTank) {
            data.list.forEach((obj) => {
              obj.usageWithDischarge = obj.fuelStart + obj.refilled - obj.fuelEnd;
            });
          }*/

          const list = results.map((result) => {
            const timePeriodDetails = fuelCalculations.getDetailsForTimePeriod(result);
            timePeriodDetails.totalFuelUsageInAllTanks = Math.round(timePeriodDetails.totalFuelUsageInAllTanks * 100) / 100;
            timePeriodDetails.averageFuelUsage = Math.round(timePeriodDetails.averageFuelUsage * 100) / 100;
            timePeriodDetails.mileage = Math.round(timePeriodDetails.mileage * 100) / 100;
            return timePeriodDetails;
          });

          return {
            from: from.format('L HH:mm'),
            to: to.format('L HH:mm'),
            rawFrom: from,
            rawTo: to,
            list: list
            //summary
          };
        });
    }
  }
})();
