(function () {
  "use strict";

  angular
    .module('app.vehicles')
    .component('vehiclesDetails', {
      bindings: {
        $transition$: '<'
      },
      templateUrl: 'app/vehicles/details/vehicles.details.html',
      controller: VehiclesDetailsCtrl
    });

  VehiclesDetailsCtrl.$inject = ['$scope', '$state', '$log', '$timeout', 'APP_EVENTS'];

  function VehiclesDetailsCtrl($scope, $state, $log, $timeout, APP_EVENTS) {
    var self = this;
    self.carId = 0;
    self.tabSelected = 0;
    self.mapOpened = false;
    self.tabIndex = {
      now: 0,
      route: 1,
      mileage: 2,
      stops: 3,
      settings: 4,
      statistics: 5,
    };

    self.$onInit = function () {
      self.carId = this.$transition$.params().id;
      self.tabSelected = self.tabIndex[$state.current.name.split(".")[2]];
      $scope.$emit(APP_EVENTS.car.carSelected, self.carId);
    };

   $scope.$on(APP_EVENTS.ui.tabSelected, function (e, tab) {
      //self.tabSelected = self.tabIndex[tab];
      if (!self.mapOpened) {
        self.mapOpened = true;
        $scope.$emit(APP_EVENTS.ui.openMap);
      }
    });

    $scope.$on(APP_EVENTS.car.carSelected, function (e, carId) {
      self.carId = carId;
      $log.log('APP_EVENTS.car.carSelected for VehiclesDetailsCtrl', carId);
    });

    $log.info('VehiclesDetailsCtrl');
  }
})();
