(() => {
  angular
    .module('app.vehicles')
    .service('app.vehicles.details.lib.with-fuel-report-view.fuel-report-view.data-builder', dataBuilder);

  dataBuilder.$inject = [
    'routeItemUtils',
    'app.vehicles.route.reportDataBuilder'
  ];

  function dataBuilder(routeItemUtils, routeReportDataBuilder) {
    return {
      processResponse,
      processRouteDetails
    };

    function processResponse(response, params) {
      const items = response.map((record) => {
        const setOfEvents = record.stops.filter((routeItem) =>
          routeItemUtils.isRouteItemWithParking(routeItem)
          ||
          routeItemUtils.isRouteItemWithMovement(routeItem)
        );

        const firstEvent = setOfEvents[0];

        const date = new Date(parseInt(firstEvent.timestampFrom, 10) * 1000);

        const events = routeReportDataBuilder
          .getMainTableBodyDataForWebUi({
            route: record,
            addressProvider: {
              google: params.addressProvider === 'addressGoogle',
              yandex: params.addressProvider === 'addressYandex'
            },
            shouldShowCombinedFuelTanks: false,
            shouldShowDetailedView: params.shouldShowDetailedView
          })
          .map((obj) => {
            obj.event.info = getInfoStr(obj.event);
            return obj.event;
          });

        return {
          date: [
            `${date.getDate()}`.padStart(2, '0'),
            `${date.getMonth() + 1}`.padStart(2, '0'),
            `${date.getFullYear()}`
          ].join('.'),
          timestampFrom: firstEvent.timestampFrom,
          events
        };
      });

      return items;
    }

    function processRouteDetails(routeDetails, params) {
      const setsOfEventsPerDay = [];
      const knownDays = {};
      const startDateToTimestampMap = {};

      routeDetails.stops.forEach((event) => {
        if (typeof knownDays[event.fromDate] === 'undefined') {
          knownDays[event.fromDate] = true;
          knownDays[event.fromDate] = setsOfEventsPerDay.length;
          setsOfEventsPerDay.push([]);
          startDateToTimestampMap[event.fromDate] = event.timestampFrom;
        }

        const setIndex = knownDays[event.fromDate];
        setsOfEventsPerDay[setIndex].push(event);
      });

      const items = setsOfEventsPerDay
        .filter((routeEvents) => {
          const setOfEvents = routeEvents.filter((routeItem) =>
            routeItemUtils.isRouteItemWithParking(routeItem)
            ||
            routeItemUtils.isRouteItemWithMovement(routeItem)
          );

          return setOfEvents.length > 0;
        })
        .map((routeEvents, index) => {
          const setOfEvents = routeEvents.filter((routeItem) =>
            routeItemUtils.isRouteItemWithParking(routeItem)
            ||
            routeItemUtils.isRouteItemWithMovement(routeItem)
          );

          const firstEvent = setOfEvents[0];
          const dayId = firstEvent.fromDate;

          const date = new Date(parseInt(firstEvent.timestampFrom, 10) * 1000);

          const events = routeReportDataBuilder
            .getMainTableBodyDataForWebUi({
              route: {
                stops: setOfEvents,
                summary: routeDetails.summary
              },
              addressProvider: {
                google: params.addressProvider === 'addressGoogle',
                yandex: params.addressProvider === 'addressYandex'
              },
              shouldShowCombinedFuelTanks: false,
              shouldShowDetailedView: params.shouldShowDetailedView,
              shouldShowCombinedFuelTanks: params.shouldShowCombinedFuelTanks
            })
            .map((obj) => {
              obj.event.info = getInfoStr(obj.event);
              obj.event.dayId = dayId;
              return obj.event;
            });

          return {
            id: dayId,
            date: [
              `${date.getDate()}`.padStart(2, '0'),
              `${date.getMonth() + 1}`.padStart(2, '0'),
              `${date.getFullYear()}`
            ].join('.'),
            events
          };
        });

      return items;
    }
  }

  function getInfoStr(event) {
    let parts = [];

    const fuelDetailsStr = fuelDetailsToHTML(event.fuel);
    if (fuelDetailsStr.length > 0) {
      parts.push(fuelDetailsStr);
    }

    if ((event.locationStr || '').length > 0) {
      parts.push(`<span class="car-link">${event.locationStr}</span>`);
    }

    parts = [parts.join(', ')];

    if (event.engineOperationStr.length > 0) {
      parts.push(`<span class="c-green">+${event.engineOperationStr}</span>`);
    }

    return parts.join(' ');
  }

  function fuelDetailsToHTML(fuelDetails) {
    switch (true) {
      case fuelDetails.refuelingStr.length > 0:
        return `<span class="c-red">${fuelDetails.refuelingStr}</span>`;
      case fuelDetails.fuelDrainStr.length > 0:
        return `<span class="c-red">${fuelDetails.fuelDrainStr}</span>`;
      case fuelDetails.normalUsageStr.length > 0:
        return `<span>${fuelDetails.normalUsageStr}</span>`;
      case fuelDetails.unchangedUsageStr.length > 0:
        return `<span>${fuelDetails.unchangedUsageStr}</span>`;
      default:
        return '';
    }
  }
})();
