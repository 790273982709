(() => {
  'use strict';

  angular
    .module('app.vehicles')
    .component('vehicleExtraSettings', {
      bindings: {
        settings: '<',
        vehicleId: '<'
      },
      controller: Ctrl,
      controllerAs: 'ctrl',
      templateUrl: 'app/vehicles/details/settings/extra-settings/vehicle.extra-settings.html'
    });

  Ctrl.$inject = [
    'VehicleExtraSettingsBackendAPI',
    'VehicleSettingsToast',
    'customVehicleIconParameters'
  ];

  function Ctrl(backendAPI, toast, customVehicleIconParameters) {
    const anyVehicleTypeId = 'any';
    this.vehicleTypes = [
      {
        id: anyVehicleTypeId,
        title: 'Любой',
        i18nTitleKey: 'vehicleExtraSettings.vehicleType.any'
      },
      {
        id: 'passenger',
        title: 'Пассажиркий',
        i18nTitleKey: 'vehicleExtraSettings.vehicleType.passenger'
      },
      {
        id: 'cargo',
        title: 'Грузовой',
        i18nTitleKey: 'vehicleExtraSettings.vehicleType.cargo'
      },
      {
        id: 'technological',
        title: 'Технологический',
        i18nTitleKey: 'vehicleExtraSettings.vehicleType.technological'
      }
    ];
    this.vehicleTypeId = this.settings.vehicleType;
    const isKnownVehicleTypeId =
      this.vehicleTypes.some((type) => type.id === this.vehicleTypeId);
    if (!isKnownVehicleTypeId) {
      this.vehicleTypeId = anyVehicleTypeId;
    }

    this.colors = customVehicleIconParameters.iconColors;
    this.iconColor = this.settings.vehicleIconColor;
    if (!this.colors.includes(this.iconColor)) {
      this.iconColor = customVehicleIconParameters.defaultIconColor;
    }

    this.isSelectedColor = (color) => {
      return color === this.iconColor;
    };

    this.setSelectedColor = (color) => {
      this.iconColor = color;
    };

    this.noIconId = 0;
    this.icons = [
      // hexagon
      {
        id: 1,
        url: customVehicleIconParameters.getCustomIconUrlById(1)
      },

      // circle
      {
        id: 2,
        url: customVehicleIconParameters.getCustomIconUrlById(2)
      },

      // triangle
      {
        id: 3,
        url: customVehicleIconParameters.getCustomIconUrlById(3)
      }
    ];
    this.iconId = this.settings.vehicleIcon;

    this.getSelectedIconUrl = (iconId) => customVehicleIconParameters.getCustomIconUrlById(iconId);

    this.getCustomIconStrokeWidth = (iconId) => customVehicleIconParameters.getStrokeWidth(iconId);

    const isKnownIconId = this.icons.some((icon) => `${icon.id}` === `${this.iconId}`);
    if (!isKnownIconId) {
      this.iconId = this.noIconId;
    }

    this.isSelectedIcon = (iconId) => {
      return iconId == this.iconId;
    };

    this.setSelectedIcon = (iconId) => {
      this.iconId = iconId;
    };

    this.onSaveBtnPressed = () => {
      backendAPI
        .save({
          vehicleId: this.vehicleId,
          vehicleTypeId: this.vehicleTypeId,
          iconColor: this.iconColor,
          iconId: this.iconId
        })
        .then(() => {
          toast.successfulSaving();
        })
        .catch(() => {
        });
    };
  }
})();
