(() => {
  angular
    .module('fuel-drain-metering-mode-switch')
    .component('fuelDrainMeteringModeSwitch', {
      templateUrl: 'app/vehicles/details/lib/with-fuel-drain-settings/fuel-drain-metering-mode-switch/tpl.html',
      controller: Ctrl,
      controllerAs: 'ctrl'
    });

  Ctrl.$inject = [
    '$rootScope',
    'fuel-drain-metering-mode-switch.events'
  ];

  function Ctrl($rootScope, events) {
    this.isActive = false;

    this.toggleMode = () => {
      this.isActive = !this.isActive;

      $rootScope.$emit(events.fuelDrainMeteringModeChanged, {
        isActive: this.isActive
      });
    };
  }
})();
