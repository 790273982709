(() => {
  angular
    .module('app.vehicles')
    .service('app.vehicles.mileage.reportDataBuilder', reportDataBuilder);

  reportDataBuilder.$inject = [
    '$translate',
    'app.reports.utils'
  ];

  function reportDataBuilder($translate, reportUtils) {
    return {
      getPageHeaderData,
      getMainTableHeaderData,
      getMainTableBodyData,
      getMainTableFooter,
      createFileName,
      formatDateForPrint,
      areDayIntervalsHidden
    };

    function createFileName(data) {
      const dates = getDatesRange(data);

      return [
        data.vehicleNumber.split('.').join(' '),
        'mileage',
        reformatDateStr(dates[0]),
        reformatDateStr(dates[1])
      ].join('_');
    }

    function getPageHeaderData(data) {
      data.stops = data.rows;
      const result = {};

      result.vehicle = {
        id: data.id,
        number: data.vehicleNumber
      };

      const totalMileage = calcTotalMileage(data.rows.slice(1, -1));
      const totalFuelUsage = data.summary.totalFuelUsage;

      result.report = {
        dates: [
          dateStrToDate(data.rows[1][0]),
          dateStrToDate(data.rows.slice(-2)[0][0])
        ],
        totalMileage,
        totalFuelUsage,
        averageFuelUsage: calcAverageFuelUsage(totalMileage, totalFuelUsage),
        totalEngineWork: data.summary.totalEngineWork
      };

      const fuelConsumptionMeter = reportUtils.getFuelConsumptionMeterDetails(data);
      result.report.shouldShowFuelConsumptionMeterType = fuelConsumptionMeter.shouldShowFuelConsumptionMeterType;
      result.report.fuelConsumptionMeterTypeI18nKey = fuelConsumptionMeter.fuelConsumptionMeterTypeI18nKey;

      return result;
    }

    function getMainTableHeaderData(data) {
      const rowData = data.rows[0];

      return rowData.map((value, index) => {
        // "Fuel usage" column
        if (index === rowData.length - 4) {
          return $translate.instant('report.fuelUsage');
        }

        // "Average fuel usage" column
        if (index === rowData.length - 3) {
          return $translate.instant('reports.averageFuelUsage');
        }

        return value;
      });
    }

    function getMainTableBodyData(data) {
      const isWeekend = (date) => {
        const weekDay = date.getDay();
        return weekDay === 0 || weekDay === 6;
      };

      return data.rows
        .slice(1, -1)
        .map((rowData) => {
          return rowData
            .map((v, index) => {
              if (index === 0) {
                return dateStrToDate(v);
              }

              if (index === rowData.length - 2) {
                return v === '0:00' ? '' : v;
              }

              v = parseFloat(v);
              return !isNaN(v) ? v : 0;
            });
        })
        .map((rowData) => {
          return {
            rowData,
            isWeekend: isWeekend(rowData[0])
          };
        });
    }

    function getMainTableFooter(data) {
      return data.rows.slice(-1)[0];
    }

    function areDayIntervalsHidden(data) {
      return data.rows[0].length < 9;
    }

    function getDatesRange(data) {
      return [
        reformatDateStr(data.rows[1][0]),
        reformatDateStr(data.rows[data.rows.length - 2][0])
      ];
    }

    function formatDateForPrint(date) {
      return [
        `${date.getDate()}`.padStart(2, '0'),
        `${date.getMonth() + 1}`.padStart(2, '0'),
        `${date.getFullYear()}`
      ].join('.');
    }

    function reformatDateStr(dateStr) {
      return dateStr.replace(/\./g, '-');
    }

    function dateStrToDate(dateStr) {
      const parts = dateStr.split('.').map((v) => parseInt(v, 10));

      const date = new Date();
      date.setFullYear(parts[0]);
      date.setMonth(parts[1] - 1);
      date.setDate(parts[2]);
      return date;
    }

    function calcTotalMileage(items) {
      const mileage = items.reduce((acc, arr) => {
        const hasAllDayIntervals = arr.length === 9;
        return acc + (hasAllDayIntervals ? arr[4] : arr[1]);
      }, 0);
      return Math.round(mileage * 100) / 100;
    }

    function calcAverageFuelUsage(totalMileage, totalFuelUsage) {
      const usage = (totalFuelUsage / totalMileage * 100);
      return Math.round(usage * 100) / 100;
    }
  }
})();
