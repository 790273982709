(function () {
  "use strict";

  angular
    .module('app.vehicles')
    .component('vehiclesStops', {
      bindings: {
        route: '<',
        history: '<',
        routeExtended: '<',
        isMultiAccaunt: '<'
      },
      templateUrl: 'app/vehicles/details/stops/vehicles.stops.html',
      controller: VehiclesStopsCtrl,
      controllerAs: 'vm'
    });

  VehiclesStopsCtrl.$inject = ['$scope', '$stateParams', '$log', '$localStorage', '$translate',
    'Session', 'APP_EVENTS', 'PeriodFilters', 'BackendApi', 'ResponseParser', 'MapOsmService',
    'CarService', '$mdToast', '$filter', 'Utils', 'appearanceMode',
    '$rootScope', 'app.reports.exportTypes', 'app.reports.events',
    'app.vehicles.stops.reportSaver', 'withFuelDrainSettings', 'app.fuelReport.saver',
    'app.vehicles.withFuelReportView',
    'fuel-consumption-metering-settings',
    'app.vehicles.details.lib.with-fuel-report-view.fuel-report-view.accordion-content.event-row.events',
    'withFuelReportView.events',
    'FuelUsageService', '$q'
  ];

  function VehiclesStopsCtrl($scope, $stateParams, $log, $localStorage, $translate,
    Session, APP_EVENTS, PeriodFilters, BackendApi, ResponseParser,
    MapOsmService, CarService, $mdToast, $filter, Utils, appearanceModeFactory,
    $rootScope, exportTypes, exportBtnEvents,
    reportSaver, fuelDrainSettings, fuelReportSaver,
    withFuelReportView, fuelConsumptionMeteringSettings, fuelReportViewEvents, withFuelReportViewEvents,
    FuelUsageService, $q) {
    this.vehicleId = $stateParams.id;

    const appearanceModeInstance = appearanceModeFactory.create($stateParams.id);

    this.appearanceModes = appearanceModeInstance.modes;
    this.appearanceMode = appearanceModeInstance.getAppearanceMode();
    const onAppearanceModeChanged = () => {
      this.appearanceMode = appearanceModeInstance.getAppearanceMode();
      this.onChangeAddress();
    };
    const unsubscribeFromAppearanceModeChange =
      appearanceModeInstance.addAppearanceModeChangeListener(onAppearanceModeChanged);

    let shouldShowCombinedFuelTanks = appearanceModeInstance.shouldShowCombinedFuelTanks();
    const onFuelTanksModeChanged = () => {
      shouldShowCombinedFuelTanks = appearanceModeInstance.shouldShowCombinedFuelTanks();
    };
    const unsubscribeFromFuelTanksModeChange =
      appearanceModeInstance.addFuelTanksModeChangeListener(onFuelTanksModeChanged);

    var self = this,
      titleFormat = '{time}, {speed}км/ч, {stopTime}{voltage}B'
    ;
    $scope.$root.app.title = 'page.stops';
    this.tmp = {
      id: $stateParams.id
    };

    this.exportMenuItems = [
      'schedule',
      'stopsGPX',
      'separator',
      {
        type: exportTypes.toCSV,
        getTitle: () => {
          return $translate.instant('reports.downloadEntityAs', {
            entityName: $translate.instant('page.stops'),
            fileType: 'CSV'
          });
        }
      },
      {
        type: exportTypes.toXLSX,
        getTitle: () => {
          return $translate.instant('reports.downloadEntityAs', {
            entityName: $translate.instant('page.stops'),
            fileType: 'XLSX'
          });
        }
      },
      {
        type: exportTypes.toPDF,
        getTitle: () => {
          return $translate.instant('reports.downloadEntityAs', {
            entityName: $translate.instant('page.stops'),
            fileType: 'PDF'
          });
        }
      },
      'separator',
      {
        type: exportTypes.fuelReportToXLSX,
        getTitle: () => {
          return $translate.instant('reports.downloadEntityAs', {
            entityName: $translate.instant('exportBtn.printFuel'),
            fileType: 'XLSX'
          });
        }
      },
      {
        type: exportTypes.fuelReportToPDF,
        getTitle: () => {
          return $translate.instant('reports.downloadEntityAs', {
            entityName: $translate.instant('exportBtn.printFuel'),
            fileType: 'PDF'
          });
        }
      },
      {
        type: exportTypes.fuelReportToPrint,
        getTitle: () => {
          return $translate.instant('reports.printAs', {
            entityName: $translate.instant('exportBtn.printFuel')
          });
        }
      },
      'fuelPrint'
    ];

    this.showPolilines = true;
    this.colorsFromStops = [];
    this.polilinesWeight = 6;
    this.globalColors = Utils.getColors();

    const unsubscribe = [];

    let currentRouteData = this.route;

    this.fuelDrainSettings = fuelDrainSettings;

    this.fuelConsumptionMeteringSettings = fuelConsumptionMeteringSettings;

    this.$onInit = function () {
      unsubscribe.push($rootScope.$on(exportBtnEvents.export, (event, params) => {
        this.onExportBtnPressed(params.type);
      }));

      unsubscribe.push($rootScope.$on(
        fuelReportViewEvents.PARKING_DURATION_PRESSED, (_, routeEvent) => {
          self.showOnStopRange(routeEvent);
        })
      );

      unsubscribe.push($rootScope.$on(
        fuelReportViewEvents.MOVEMENT_DURATION_PRESSED, (_, args) => {
          self.showOnWayRange(args.routeItem, args.index);
        })
      );

      unsubscribe.push($rootScope.$on(
        fuelReportViewEvents.INFO_PRESSED, (_, routeEvent) => {
          self.showStop(routeEvent);
        })
      );

      unsubscribe.push($rootScope.$on(
        withFuelReportViewEvents.toggleRouteVisibilityOnMap, (_, params) => {
          const item = params.item;
          const index = params.index;
          self.showOnWayRange(item, index);
        }
      ));

      fuelDrainSettings.create(self);
      withFuelReportView.create(self);
      fuelConsumptionMeteringSettings.reset();

      this.addrType = Session.addressType;
      this.filter = PeriodFilters.getFilter('stops');
      this.intervals = CarService.getIntervals('string');
      this.showActions = false;
      this.editingStopIndex = null;
      this.carInfoControl = {};
      this.activeGeoZoneId = null;
      this.geoZoneHoldTime = null;
      this.controls = [];

      // XXX It's commented by intention. Now there should not be UI for changing of thickness of polyline
      // self.polilinesWeight = _.has($localStorage, 'stopsPolilinesWeight') ? $localStorage.stopsPolilinesWeight : 6;
      self.polilinesWeight = 5;

      self.showPolilines = _.has($localStorage, 'showStopsPolilines') ? $localStorage.showStopsPolilines : true;
      $scope.$emit(APP_EVENTS.ui.tabSelected, 'stops');
      detectStationaryCar();
      MapOsmService.clear();
      this.onChangeAddress();
      if (this.history.points && this.history.points.length) {
        self.history.points = CarService.detectParking(self.history.points, self.route.stops);
        MapOsmService.drawMarkersSet(this.history.points, titleFormat, true);
        self.showPolilines && self.getPolilinesToOneDay();
      }

      const isControlPermissionSet = JSON.parse(sessionStorage.getItem('isControlPermissionSet'));

      self.hasControlPermission = (typeof isControlPermissionSet === 'boolean') ?
        isControlPermissionSet :
        Session.user.permissions.indexOf('geo-visits') !== -1;
    };

    this.$onDestroy = function () {
      while (unsubscribe.length) {
        unsubscribe.pop()();
      }

      withFuelReportView.destroy();
      fuelDrainSettings.destroy();
      fuelConsumptionMeteringSettings.reset();

      this.clearStopActions(false);
      MapOsmService.clearPolilinesGroup();
      MapOsmService.clearPolilines();
      MapOsmService.clearStartFinishMarkers();
      unsubscribeFromAppearanceModeChange();
      unsubscribeFromFuelTanksModeChange();
      appearanceModeInstance.turnOffSingleZoneMode();
      clearControls();
    };

    this.onExportBtnPressed = (exportType) => {
      switch (true) {
        case [
          exportTypes.toCSV,
          exportTypes.toPDF,
          exportTypes.toXLSX
        ].includes(exportType):
          this.exportRouteReport(exportType);
          break;
        case [
          exportTypes.fuelReportToXLSX,
          exportTypes.fuelReportToPDF,
          exportTypes.fuelReportToPrint
        ].includes(exportType):
          this.exportFuelReport(exportType);
          break;
      }
    };

    this.exportRouteReport = (exportType) => {
      const currentVehicle = Session.user.cars.filter((vehicle) => `${vehicle.id}` === $stateParams.id)[0];

      const dataForReport = {
        id: $stateParams.id,
        vehicleNumber: currentVehicle.name,
        route: ResponseParser.parseSingleDeviceRoute(self.route, true, self.addrType),
        shouldShowDetailedView: this.isToggleMove,
        addressProvider: {
          google: self.addrType === 'addressGoogle',
          yandex: self.addrType === 'addressYandex'
        },
        shouldShowCombinedFuelTanks
      };

      reportSaver.save(exportType, dataForReport);
    };

    this.exportFuelReport = (exportType) => {
      const currentVehicle = Session.user.cars.filter((vehicle) => `${vehicle.id}` === $stateParams.id)[0];

      const dataForReport = {
        vehicleId: $stateParams.id,
        vehicleNumber: currentVehicle.name,
        route: ResponseParser.parseSingleDeviceRoute(self.route, true, self.addrType),
        excludedEventsWithFuelDrain: fuelConsumptionMeteringSettings.getExcludedEvents(),
        filter: self.filter
      };

      fuelReportSaver.save(exportType, dataForReport);
    };

    self.applyFilter = function (noRenderChart) {
      var filter = self.filter;

      delete $localStorage.sensorChunkParts;
      if (filter.from.getTime() != filter.to.getTime()) {
        filter.to.setFullYear(filter.from.getFullYear());
        filter.to.setMonth(filter.from.getMonth());
        filter.to.setDate(filter.from.getDate());
      }

      bindLoader(function () {
        MapOsmService.clearPolilinesGroup();
        MapOsmService.clearPolilines();

        return $q.all(FuelUsageService.getPeriods2(self.filter.from, self.filter.to, self.tmp.id))
          .then((resp) => {
            self.route = {
              summary: resp[0].summary,
              stops: resp.flatMap(obj => obj.stops)
            }

            detectStationaryCar();
            self.onChangeAddress();
            noRenderChart || self.onEmitFuelChart();
            self.onEmitCarInfo();
          })
      });

      BackendApi.getHistory(this.tmp.id, self.filter)
        .then(function (result) {
          // $log.log('> history', result);
          self.history = result;
          return BackendApi.getStopsExtended(self.tmp.id, self.filter);
        }).then(function (result) {
        self.routeExtended = result;
        MapOsmService.clear();
        if (result && result.points && result.points.length) {
          self.history.points = CarService.detectParking(self.history.points, self.route.stops);
          MapOsmService.drawMarkersSet(self.history.points, titleFormat, true);
          self.showPolilines && self.getPolilinesToOneDay();
        }
      });

      appearanceModeInstance.turnOffSingleZoneMode();
    };

    this.onSetPolilineWeight = function (num) {
      self.polilinesWeight += num;
      $localStorage.stopsPolilinesWeight = self.polilinesWeight;
    };

    this.onTogglePolilinesToOneDay = function () {
      MapOsmService.clearPolilinesGroup();
      self.showPolilines = !self.showPolilines;
      $localStorage.showStopsPolilines = self.showPolilines;
      self.showPolilines && self.getPolilinesToOneDay();
    };

    this.getPolilinesToOneDay = function () {
      self.colorsFromStops = [];
      MapOsmService.clearPolilinesGroup();
      clearControls();
      self.onDrawPolilinesGroup();
      self.drawPolilinesGroupWithoutGps();
    };

    this.onDrawPolilinesGroup = function () {
      var colorIndex = colorIndexGenerator();

      _.each(self.list.stops, function (item, i) {
        var points = self.getRoutePointsFromRange(item.onWayRange);
        var color = self.hasOnWayTime(item.onWay) ? self.getColor(colorIndex) : 'transparent';
        self.colorsFromStops.push(color);
        MapOsmService.drawPolilinesGroup(self.getPolylinePoints(points), color, self.polilinesWeight);
      });
    };

    this.drawPolilinesGroupWithoutGps = function () {
      var routeStartPoint;
      var routeEndPoint;
      _.each(self.list.stops, function (item, i) {
        var prevItem = i && self.list.stops[i - 1];
        var points = getMovmentPoints(self.getRoutePointsFromRange(item.onWayRange));
        var prevPoints = prevItem ?
          getMovmentPoints(self.getRoutePointsFromRange(prevItem.onWayRange)) :
          [];

        if (!routeStartPoint && !points.length && prevPoints.length) {
          routeStartPoint = prevPoints[prevPoints.length - 1];
        }

        if (routeStartPoint && points.length && !prevPoints.length) {
          routeEndPoint = points[0];
        }
        
        if (routeStartPoint && routeEndPoint) {
          
         const control = MapOsmService.addRoute({
            serviceType: 'trip',
            points: [
              self.getPointByStop(routeStartPoint, true),
              self.getPointByStop(routeEndPoint, false),
            ],
            color: self.colorsFromStops[i],
            shouldDrawMarkers: false,
            fitSelectedRoutes: false,
            
          });
          self.controls.push(control);

          routeStartPoint = undefined;
          routeEndPoint = undefined;
        }
      });
    };

    this.getPointByStop = (item) => {
      return {
        lat: item.latitude,
        lng: item.longitude,
      }
    }

    this.getRouteEventColor = (routeEventIndex) => {
      return self.colorsFromStops[routeEventIndex];
    };

    this.getRoutePointsFromRange = function (range) {
      return _.filter(self.routeExtended.points, function (point) {
        var timestamp = +point.timestamp * 1000;

        return (timestamp >= range.from) && (timestamp <= range.to);
      });
    };

    this.getColor = function (colorIndex) {
      var index = colorIndex.next().value;
      return this.globalColors[index] || Utils.getRandomeColor();
    };

    this.hasOnWayTime = function (onWay) {
      if (!onWay) {
        return false;
      }

      var onWayTime = moment(onWay, 'h:mm');
      var hasHour = !!onWayTime.get('hour');
      var hasMinute = !!onWayTime.get('minute');

      return hasHour || hasMinute;
    };

    this.getPolylinePoints = function (stops) {
      var polylines = [];

      _.each(stops, function (stop) {
        var hasCoords = stop.latitude && stop.longitude;

        hasCoords && polylines.push([stop.latitude, stop.longitude]);
      });

      return polylines;
    };

    this.replacePoints = function (newPoints, onWayRange) {
      var firstIndex,
        lastIndex;

      _.each(self.history.points, function (point, i) {
        if (+point.timestamp * 1000 <= onWayRange.from) {
          firstIndex = i;
        }

        if (+point.timestamp * 1000 <= onWayRange.to) {
          lastIndex = i;
        }
      });

      self.history.points.splice(firstIndex, lastIndex, newPoints);
    };

    this.showOnWayRange = function (item, index) {
      if (activateItem(item)) {
        bindLoader(function () {
          return BackendApi.getHistory(self.tmp.id, item.onWayRange)
            .then(function (result) {
              MapOsmService.clear();
              if (result.points && result.points.length) {
                self.history.points = CarService.detectParking(self.history.points, self.route.stops);
                self.replacePoints(result.points, item.onWayRange);
                MapOsmService.drawMarkersSet(result.points, titleFormat, true);
                var points = self.getRoutePointsFromRange(item.onWayRange);
                if (self.showPolilines) {
                  self.getPolilinesToOneDay();
                }
                MapOsmService.drawPolilines(self.getPolylinePoints(points), self.colorsFromStops[index], self.polilinesWeight);
              }
            });
        });
        self.onEmitFuelChart({
          filter: item.onWayRange, route: {
            stops: self.route.stops,
            summary: {
              fuelSettings: self.route.summary.fuelSettings,
              distanceKm: item.distance,
              maxSpeedKmpH: item.maxSpeed,
              engine: item.engine
            }
          }
        });
        self.onEmitCarInfo(item.onWayRange);
      } else {
        this.applyFilter(true);
        self.onEmitFuelChart();
      }
    };

    this.stripSeconds = function (time) {
      return time.slice(0, -3);
    };

    this.showOnStopRange = function (item) {
      if (activateItem(item)) {
        self.onEmitFuelChart({
          filter: {from: +(item.timestampFrom + '000'), to: +(item.timestampTo + '000')},
          route: {
            stops: self.route.stops,
            summary: {
              fuelSettings: self.route.summary.fuelSettings,
              distanceKm: item.distance,
              maxSpeedKmpH: item.maxSpeed,
              engine: item.engine
            }
          }
        });
        MapOsmService.clear();
        MapOsmService.showStop(item.latitude, item.longitude);
        //self.onEmitCarInfo({from: +(item.timestampFrom+'000'), to: +(item.timestampTo+'000')});
      } else {
        this.applyFilter(true);
        self.onEmitFuelChart();
      }
    };

    this.showStop = function (item) {
      if ('2' == item.status) {
        if (activateItem(item)) {
          MapOsmService.clear();
          MapOsmService.showStop(item.latitude, item.longitude);
          // MapOsmService.goToPlace([item.latitude, item.longitude], 16);
        } else {
          this.applyFilter(true);
        }
      }
    };

    this.onToggleMove = function () {
      this.isToggleMove = !this.isToggleMove;
      this.onChangeAddress();
      self.showPolilines && self.getPolilinesToOneDay();
    };

    this.onEmitFuelChart = function (options) {
      options = options || {};
      $scope.$emit(APP_EVENTS.car.fuelChart, _.extend({
        id: self.tmp.id,
        filter: self.filter,
        route: self.route
      }, options));
    };

    this.onEmitCarInfo = function (filter) {
      if (this.carInfoControl.updateCarInfo) {
        this.carInfoControl.updateCarInfo(filter);
      }
    };

    this.onToggleChart = function (isShow) {
      isShow && delete $localStorage.sensorChunkParts;
      this.onEmitFuelChart({show: isShow});
    };

    this.onChangeAddress = function (type) {
      var list = ResponseParser.parseSingleDeviceRoute(this.route, this.isToggleMove, type || this.addrType);

      fuelDrainSettings.onRouteDetailsChanged(list);
      fuelConsumptionMeteringSettings.setRouteDetails(list);

      currentRouteData = list;
      this.list = {summary: list.summary, stops: self.parseOfStopsTime(list.stops)};

      if (appearanceModeInstance.getAppearanceMode() === appearanceModeInstance.modes.ALL_GEO_ZONES) {
        const allGeoZones = (Session.user.zones || [])
          .reduce((acc, {id: zoneId, name: zoneName, coordinates: zoneCoordinates}) => {
            acc[zoneId] = {
              id: zoneId,
              name: zoneName,
              polygon: L.polygon(zoneCoordinates)
            };
            return acc;
          }, {});

        let geoZonesWithStops = {};

        this.list.stops
          .filter((stop) => !isNaN(stop.latitude) && !isNaN(stop.longitude))
          .forEach((stop) => {
            Object.values(allGeoZones)
              .filter((geoZone) => geoZone
                .polygon.contains({
                  lat: stop.latitude,
                  lng: stop.longitude
                }))
              .forEach((geoZone) => {
                if (typeof geoZonesWithStops[geoZone.id] === 'undefined') {
                  geoZonesWithStops[geoZone.id] = {
                    id: geoZone.id,
                    name: geoZone.name,
                    stops: []
                  };
                }

                geoZonesWithStops[geoZone.id].stops.push(stop);
              });
          });

        this.list.geoZonesWithStops = Object.values(geoZonesWithStops)
          .map((geoZone) => {
            let totalDuration = 0;

            geoZone.stops = geoZone.stops.map((stop) => {
              stop.duration = secondsToTimeStr(stop.timestampTo - stop.timestampFrom);
              stop.from = timestampToTimeStr(stop.timestampFrom * 1000);
              stop.to = timestampToTimeStr(stop.timestampTo * 1000);
              totalDuration += stop.timestampTo - stop.timestampFrom;
              return stop;
            });

            geoZone.totalDuration = secondsToTimeStr(totalDuration);

            return geoZone;
          });

        fuelConsumptionMeteringSettings.setRouteDetails(this.list);

        function timestampToTimeStr(timestamp) {
          const date = new Date(timestamp);

          const minutes = `${date.getMinutes()}`.padStart(2, '0');
          const seconds = `${date.getSeconds()}`.padStart(2, '0');

          return [
            date.getHours(),
            minutes,
            seconds
          ].join(':');
        }

        function secondsToTimeStr(seconds) {
          const hours = Math.floor(seconds / 3600);
          const minutes = `${Math.floor((seconds - hours * 3600) / 60)}`.padStart(2, '0');
          return `${hours}:${minutes}`;
        }
      }
    };

    this.parseOfStopsTime = function (list) {
      var parsedStopList = [];

      _.reduce(list, function (memo, next, index) {
        var cloneMemo = _.clone(memo);

        if (memo.status === '4' && next.status === '4') {
          if (memo.timestampFrom === self.getPreviouseTimestamp(parsedStopList)) {
            return next;
          }

          cloneMemo.timestampTo = next.timestampTo;
          cloneMemo.toTime = next.toTime;
        }

        parsedStopList.push(cloneMemo);
        list.length - 1 === index && parsedStopList.push(next);

        return next;
      });

      if (list.length === 1) {
        parsedStopList.push(list[0]);
      }

      return this.filterStops(parsedStopList);
    };

    this.filterStops = function (stops) {
      return _.filter(stops, function (item) {
        if ((item.timestampFrom === item.timestampTo) && item.status === '4') {
          return false;
        }

        return true;
      });
    };

    this.getPreviouseTimestamp = function (stops) {
      return stops.length ? stops[stops.length - 1].timestampTo : false;
    };

    this.getInfo = function (item) {
      if (item.status === '4') {
        var stopTime = moment.utc(moment.duration(item.toTime) - moment.duration(item.fromTime)).format('HH:mm:ss');
        return `${item.info.split(')')[0]}${stopTime})`;
      }

      return shouldShowCombinedFuelTanks ? item.combinedFuelTankInfo : item.info;
    };

    this.onSetFilter = function (type) {
      var filter = CarService.setFormFilter(this.filter, type);
      this.filter.from = filter.from;
      this.filter.to = filter.to;
      this.applyFilter();
    };

    this.onSetFilterTime = function (time) {
      var filter = CarService.onSetFilterTime(time, this.filter.from);
      this.filter.from = filter.from;
      this.filter.to = filter.to;
      this.applyFilter();
    };

    this.onToggleActions = function () {
      self.showActions = !self.showActions;

      if (!self.showActions) {
        self.clearStopActions(true);
      }
    };

    this.saveStopAsGeozone = function (item) {
      if (item.zoneName && item.zoneColor) {
        var zone = {
          login: Session.user.loginName,
          name: item.zoneName,
          color: item.zoneColor,
          days: ''
        };

        MapOsmService.zoneSaved(zone).then(function () {
          var toast = $mdToast.simple().content($filter('translate')('global.savedSuccess')).position('bottom left');
          $mdToast.show(toast);
          self.clearStopActions(true);
        });
      }
    };

    this.editStopAsGeozone = function (item) {
      if (item.geo && item.geo.name && item.geo.color) {
        MapOsmService.zoneSaved(item.geo).then(function () {
          var toast = $mdToast.simple().content($filter('translate')('global.savedSuccess')).position('bottom left');
          $mdToast.show(toast);
          self.clearStopActions(true);
        });
      }
    };

    this.displayStopActions = function (index) {
      this.stopActionsIndex = index;
      var stop = this.list.stops[index];

      if (stop.geo) { // existing zone
        MapOsmService.startEditZone(stop.geo.id);
      } else { // new zone
        MapOsmService.startGeoEdit();
        MapOsmService.centerMapOnMarker(stop.latitude, stop.longitude);
      }
    };

    this.clearStopActions = function (uploadNewPoints) {
      this.stopActionsIndex = null;
      MapOsmService.cancelCurrentZone();
      MapOsmService.stopGeoEdit();
      if (uploadNewPoints) {
        this.applyFilter(true);
      }
    };

    this.onColorChanged = function (color) {
      MapOsmService.zoneColorChanged(color);
    };

    this.copyLocation = function (stop) {
      if (!stop.latitude || !stop.longitude) {
        return;
      }

      var latLon = (stop.latitude + ';' + stop.longitude);
      copyToClipboard(latLon);

      stop.isCopied = true;

      setTimeout(function () {
        stop.isCopied = false;
      }, 1000);

    };

    function * colorIndexGenerator() {
      var index = 0;

      while (index < self.globalColors.length) {
        yield index++;
      }
    }

    function copyToClipboard(str) {
      var el = document.createElement('textarea');  // Create a <textarea> element
      el.value = str;                                 // Set its value to the string that you want copied
      el.setAttribute('readonly', '');                // Make it readonly to be tamper-proof
      el.style.position = 'absolute';
      el.style.left = '-9999px';                      // Move outside the screen to make it invisible
      document.body.appendChild(el);                  // Append the <textarea> element to the HTML document
      var selected =
        document.getSelection().rangeCount > 0        // Check if there is any content selected previously
          ? document.getSelection().getRangeAt(0)     // Store selection if found
          : false;                                    // Mark as false to know no selection existed before
      el.select();                                    // Select the <textarea> content
      document.execCommand('copy');                   // Copy - only works as a result of a user action (e.g. click events)
      document.body.removeChild(el);                  // Remove the <textarea> element
      if (selected) {                                 // If a selection existed before copying
        document.getSelection().removeAllRanges();    // Unselect everything on the HTML document
        document.getSelection().addRange(selected);   // Restore the original selection
      }
    };

    this.filterByGeo = function (geo) {
      this.activeGeoZoneId = this.activeGeoZoneId === geo.id ? null : geo.id;

      const result = moment.duration();

      this.list.stops.filter(this.isItemVisible).forEach(function (item) {
        result.add(moment.duration(item.stopTime));
      });
      this.geoZoneHoldTime = result.hours() + ':' + result.minutes();

      appearanceModeInstance.turnOnSingleZoneMode();
    };

    this.isItemVisible = function (item) {
      if (!self.activeGeoZoneId) {
        return true;
      }

      return item.geo && item.geo.id === self.activeGeoZoneId;
    };

    function activateItem(item) {
      if (item != self.tmp.item) {
        self.tmp.item && (self.tmp.item.isActive = false);
        item.isActive = true;
        self.tmp.item = item;
      } else {
        item.isActive = false;
        self.tmp.item = null;
      }
      return item.isActive;
    }

    function detectStationaryCar() {
      if (self.route.summary && self.route.summary && self.route.summary.fuelSettings && !!self.route.summary.impulse) {
        $scope.$emit(APP_EVENTS.car.fuelBtn, {show: true});
      }
    }

    function bindLoader(func) {
      self.showLoader = true;
      func()
        .finally(function () {
          self.showLoader = false;
        });
    }

    function clearControls() {
      _.each(self.controls, (control) => {
        MapOsmService.removeControl(control);
      });
    }

    function getMovmentPoints(points) {
      return points.filter((point) => point.speed > 0);
    }

    function isMarkerInsidePolygon(point, polygonPoints) {
      const [x, y] = point;

      for (let i = 0, j = polygonPoints.length - 1; i < polygonPoints.length; j = i++) {
        let xi = polygonPoints[i];
        let yi = polygonPoints[i];
        let xj = polygonPoints[j];
        let yj = polygonPoints[j];

        const isIntersected = ((yi > y) !== (yj > y)) && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
        if (isIntersected) {
          return true;
        }
      }
      return false;
    }
  }
})();
