(() => {
  angular
    .module('app')
    .service('appearanceMode', appearanceMode);

  const modes = {
    ALL_STOPS: 'ALL_STOPS',
    ALL_GEO_ZONES: 'ALL_GEO_ZONES',
    SINGLE_GEO_ZONE: 'SINGLE_GEO_ZONE'
  };

  const appearanceModeKeyName = 'appearanceMode';
  const shouldShowCombinedFuelTanksKeyName = 'shouldShowCombinedFuelTanks';

  appearanceMode.$inject = [
    '$localStorage'
  ];

  function appearanceMode($localStorage) {
    const instances = {};

    return {
      create: (vehicleId) => {
        if (typeof instances[vehicleId] === 'undefined') {
          instances[vehicleId] = create(vehicleId);
        }

        return instances[vehicleId];
      }
    };

    function create(vehicleId) {
      let appearanceModeChangeListeners = [];
      let fuelTanksModeChangeListeners = [];

      turnOffSingleZoneMode();

      return {
        modes,
        getAppearanceMode,
        addAppearanceModeChangeListener,
        shouldShowCombinedFuelTanks,
        addFuelTanksModeChangeListener,
        toggleOnlyGeoZonesMode,
        toggleFuelTankMode,
        turnOnSingleZoneMode,
        turnOffSingleZoneMode
      };

      function getAppearanceMode() {
        const hasSavedValue =
          typeof $localStorage[appearanceModeKeyName] !== 'undefined'
          && typeof $localStorage[appearanceModeKeyName][vehicleId] !== 'undefined';
        if (hasSavedValue) {
          return $localStorage[appearanceModeKeyName][vehicleId];
        }

        return modes.ALL_STOPS;
      }

      function saveAppearanceMode(mode) {
        if (typeof $localStorage[appearanceModeKeyName] === 'undefined') {
          $localStorage[appearanceModeKeyName] = {};
        }
        $localStorage[appearanceModeKeyName][vehicleId] = mode;
      }

      function addAppearanceModeChangeListener(fn) {
        appearanceModeChangeListeners.push(fn);

        return () => {
          appearanceModeChangeListeners = appearanceModeChangeListeners.filter((listener) => listener !== fn);
        };
      }

      function notifyAppearanceModeChangeListeners() {
        const currentMode = getAppearanceMode();
        appearanceModeChangeListeners.forEach((fn) => fn(currentMode));
      }

      function shouldShowCombinedFuelTanks() {
        const hasSavedValue =
          typeof $localStorage[shouldShowCombinedFuelTanksKeyName] !== 'undefined'
          && typeof $localStorage[shouldShowCombinedFuelTanksKeyName][vehicleId] !== 'undefined';
        if (hasSavedValue) {
          return $localStorage[shouldShowCombinedFuelTanksKeyName][vehicleId];
        }

        return false;
      }

      function saveCombinedFuelTanksMode(newMode) {
        if (typeof $localStorage[shouldShowCombinedFuelTanksKeyName] === 'undefined') {
          $localStorage[shouldShowCombinedFuelTanksKeyName] = {};
        }
        $localStorage[shouldShowCombinedFuelTanksKeyName][vehicleId] = newMode;
      }

      function toggleFuelTankMode() {
        saveCombinedFuelTanksMode(!shouldShowCombinedFuelTanks());
        notifyFuelTanksModeChangeListeners();
      }

      function addFuelTanksModeChangeListener(fn) {
        fuelTanksModeChangeListeners.push(fn);

        return () => {
          fuelTanksModeChangeListeners = fuelTanksModeChangeListeners.filter((listener) => listener !== fn);
        };
      }

      function notifyFuelTanksModeChangeListeners() {
        const currentMode = shouldShowCombinedFuelTanks();
        fuelTanksModeChangeListeners.forEach((fn) => fn(currentMode));
      }

      function toggleOnlyGeoZonesMode() {
        const currentMode = getAppearanceMode();

        let newMode;
        if (currentMode === modes.ALL_GEO_ZONES) {
          newMode = modes.ALL_STOPS;
        } else {
          newMode = modes.ALL_GEO_ZONES;
        }

        saveAppearanceMode(newMode);
        notifyAppearanceModeChangeListeners();
      }

      function turnOnSingleZoneMode() {
        saveAppearanceMode(modes.SINGLE_GEO_ZONE);
        notifyAppearanceModeChangeListeners();
      }

      function turnOffSingleZoneMode() {
        if (getAppearanceMode() !== modes.SINGLE_GEO_ZONE) {
          return;
        }

        saveAppearanceMode(modes.ALL_STOPS);
        notifyAppearanceModeChangeListeners();
      }
    }
  }
})();
