(function () {
  angular
    .module('app')
    .filter('formatDate', formatDate)
    .filter('formatDuration', formatDuration)
    .filter('formatSignal', formatSignal)
    .filter('formatTemperature', formatTemperature)
    .filter('searchItem', searchItem)
  ;

  /**
   * @desc Returned new array for search
   * @returns {Function}
   */
  function searchItem() {
    return function(arr, query) {
      if (!query) {
        return arr;
      }

      var results = [];
      query = query.toLowerCase();

      angular.forEach(arr,function(item) {
        if (item.name.toLowerCase().indexOf(query) !== -1) {
          results.push(item);
        }
      });

      return results;
    }
  }

  /**
   * @returns {Function}
   * @description
   * Format UNIX timestamp as full date and time string.
   */
  function formatDate() {
    return function (input, format) {
      format = format || 'L LTS';
      return moment.unix(input).format(format);
    };
  }

  /**
   * @returns {Function}
   * @description
   * Format UNIX timestamp as full date and time string.
   */
  function formatDuration() {
    return function (input, format) {
      format = format || 'seconds';

      if (angular.isString(input)) {
        input = parseInt(input, 10);
      }

      var d = moment.duration(input, format);
      return [d.days() * 24 + d.hours(), ('0' + d.minutes()).slice(-2)].join(":");
    };
  }

  /**
   * @return {Function}
   * @description
   * Formats signal strength as bar of five '>' symbols.
   */
  function formatSignal() {
    return function (input) {
      var out = '';

      if (input >= 24) {
        out = '>>>>>';
      } else if (input >= 19) {
        out = '>>>>';
      } else if (input >= 15) {
        out = '>>>';
      } else if (input >= 10) {
        out = '>>';
      } else {
        out = '>';
      }

      return out;
    };
  }

  /**
  * @returns {Function}
  * @description
  * Format temperature as signed integer with explicit sigh.
  */
  function formatTemperature() {
    return function (input) {
      input = parseInt(input, 10);
      var out = input ? '+' : '';

      out += input;

      return out;
    };
  }

})();
