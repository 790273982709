(function () {
  "use strict";

  const modes = {
    SEPARATED_FUEL_TANKS: 'SEPARATED_FUEL_TANKS',
    COMBINED_FUEL_TANKS: 'COMBINED_FUEL_TANKS',
    ONLY_GEOZONES: 'ONLY_GEOZONES'
  };

  const appearanceModeKeyName = 'appearanceMode';

  angular
    .module('app')
    .component('changeAddress', {
      bindings: {
        type: '=',
        onChange: '&',
        showCol: '<',
        showToggleFuelReportViewBtn: '<',
        showFuelTankModeToggle: '<',
        showOnlyGeoZonesModeToggle: '<'
      },
      templateUrl: 'app/component/changeAddress/tpl.html',
      controllerAs: 'vm',
      controller: [
        '$rootScope',
        '$localStorage',
        'Session',
        '$stateParams',
        'appearanceMode',
        'app.tokens.accessByToken',
        function ChangeAddressCtrl($rootScope, $localStorage, Session, $stateParams, appearanceModeFactory,
          accessByToken) {
        var self = this,
          hideCol = {},
          tableHtml = document.querySelector('.table-car-all')
        ;
        this.addrs = [{
          name: 'Google',
          code: 'addressGoogle'
        }, {
          name: 'Yandex',
          code: 'addressYandex'
        }];
        this.type = this.type || Session.addressType;

        this.isAccessByToken = accessByToken.isActive

        const appearanceModeInstance = appearanceModeFactory.create($stateParams.id);

        this.appearanceModes = appearanceModeInstance.modes;
        this.appearanceMode = appearanceModeInstance.getAppearanceMode();
        this.onAppearanceModeChanged = () => {
          this.appearanceMode = appearanceModeInstance.getAppearanceMode();
        };
        appearanceModeInstance.addAppearanceModeChangeListener(this.onAppearanceModeChanged);

        this.shouldShowCombinedFuelTanks = appearanceModeInstance.shouldShowCombinedFuelTanks();
        const onFuelTanksModeChanged = () => {
          this.shouldShowCombinedFuelTanks = appearanceModeInstance.shouldShowCombinedFuelTanks();
        };
        appearanceModeInstance.addFuelTanksModeChangeListener(onFuelTanksModeChanged);

        this.toggleFuelTankMode = () => {
          appearanceModeInstance.toggleFuelTankMode();
        };

        this.onChangeType = function () {
          Session.addressType = this.type;
          _.isFunction(this.onChange) && this.onChange({type: this.type});
          $rootScope.$emit('main.addressProviderChanged', this.type);
        };

        this.onToggleCol = function (pos) {
          if (hideCol.hasOwnProperty(pos)) {
            tableHtml.classList.remove('hide-col-' + pos);
            delete hideCol[pos];
          } else {
            tableHtml.classList.add('hide-col-' + pos);
            hideCol[pos] = true;
          }
          $localStorage.hideCol = hideCol;
        };

        this.showCol && _.each($localStorage.hideCol, function (val, key) {
          self.onToggleCol(key);
        });

        this.toggleOnlyGeoZonesMode = function () {
          appearanceModeInstance.toggleOnlyGeoZonesMode();
        };
      }]
    });

})();
