(() => {
  angular
    .module('app.reports')
    .constant('app.reports.converters.pdf', createConverter());

  function createConverter() {
    return {
      generate
    };

    /**
     * @param {string[]} columnNames
     * @param {array[]} rows
     */
    function generate(columnNames, rows) {
      const doc = {
        content: {
          table: {
            headerRow: 1,
            body: [].concat(columnNames, rows)
          }
        }
      };

      return pdfMake.createPdf(doc)
    }
  }
})();
