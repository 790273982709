(() => {
  angular
    .module('app.logistics')
    .service('logistics/view-route-for-token/api', api);

  api.$inject = [
    '$q',
    '$http',
    'BackendApi'
  ];

  // XXX this value only for development purposes in order to be able to test some routes.
  // It should be false for other cases
  const isDebuggingDuringDevelopment = false;
  const todayTimestampForDevMode = 1596153600000;
  const todayTimestamp = isDebuggingDuringDevelopment ? todayTimestampForDevMode : undefined;

  function api($q, $http, backendApi) {
    return {
      getLatestRouteOfVehicle,
      getTrafficDataByDate,
      getCurrentStatusOfVehicle,
      getHistoryDataByDate,
      getVehicleDetails,
      getForwarderRouteDetails,
      getVehicleCurrentState
    };

    function getLatestRouteOfVehicle(vehicleId, date) {
      return $q
        .resolve({
          hasForwarderRoute: false,
          forwarderRouteId: null
        })
        .then((results) => {
          return $http
            .post('/x.xx', {
              v: 3,
              getRoutesByCarId: vehicleId
            })
            .then((resp) => {
              const parts = resp.data.split('\n');
              if (parts.length < 3) {
                return results;
              }

              const routes = JSON.parse(parts[2])
                .sort((a, b) => a.dateTime - b.dateTime)
                .map(obj => Object.assign(
                  obj,
                  {
                    dateTime: new Date(obj.dateTime * 1000)
                  }
                ));

              if (routes.length === 0) {
                return results;
              }

              let route;

              if (date) { // get for today
                const momentDate = moment(date);
                const from = momentDate.startOf('day').toDate();
                const to = momentDate.endOf('day').toDate()

                route = routes.find((r) => moment(r.dateTime).isBetween(from, to));
              } else { // get for today or for nearest day
                const startOfTYoday = moment(todayTimestamp).startOf('day').toDate();
                
                route = routes.filter((r) => r.dateTime > startOfTYoday)[0];
              }

              if (route) {
                results.hasForwarderRoute = true;
                results.forwarderRouteId = route.id;
              }

              return results;
            });
        })
        .then((results) => {
          if (!results.hasForwarderRoute) {
            return results;
          }

          if (isDebuggingDuringDevelopment) {
            results.forwarderRouteId = 321;
          }

          return getForwarderRouteDetails(results.forwarderRouteId)
            .then((forwarderRoute) => {
              if (!forwarderRoute) {
                results.hasForwarderRoute = false;
                return null;
              }

              return forwarderRoute;
            });
        })
        .catch(console.error);
    }

    function getForwarderRouteDetails(forwarderRouteId) {
      return $http
        .post('/x.xx', {
          v: 3,
          getRoute: forwarderRouteId
        })
        .then((resp) => {
          const parts = resp.data.split('\n');
          if (parts.length < 3) {
            return null;
          }

          const forwarderRoute = JSON.parse(parts[2]);
          if (!forwarderRoute) {
            return null;
          }

          forwarderRoute.points = JSON.parse(forwarderRoute.data);
          return forwarderRoute;
        });
    }

    function getTrafficDataByDate(vehicleId, date) {
      const momentDate = moment(date || todayTimestamp);
      return backendApi.getStops(vehicleId, {
        from: momentDate.startOf('day').toDate(),
        to: momentDate.endOf('day').toDate()
      });
    }


    function getHistoryDataByDate(vehicleId, date) {
      const momentDate = moment(date || todayTimestamp);
      return backendApi.getHistory(vehicleId, {
        from: momentDate.startOf('day').toDate(),
        to: momentDate.endOf('day').toDate()
      });
    }

    function getCurrentStatusOfVehicle(vehicleId) {
      return backendApi.getCarNow(vehicleId)
        .then((data) => {
          return data[0];
        });
    }

    function getVehicleDetails(vehicleId) {
      return backendApi.getCarSettings({id: vehicleId});

    }

    function getVehicleCurrentState(vehicleId) {
      return backendApi.getCarNow(vehicleId)
        .then((data) => {
          if (Array.isArray(data) && data.length > 0) {
            return data[0];
          }
          return null;
        });
    }
  }
})();
