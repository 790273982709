(function () {
  "use strict";

  angular
    .module('app')
    .config(routesConfig);

  /** @ngInject */
  function routesConfig($stateProvider, $urlRouterProvider, $locationProvider) {
    $locationProvider.html5Mode(true).hashPrefix('!');
    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('main', {
        // redirectTo: 'dashboard',
        component: 'main'
      })
      .state('dashboard', {
        parent: 'main',
        url: '/',
        redirectTo: 'vehicles'
      })
      .state('login', {
        // parent: 'main',
        url: '/login',
        component: 'login'
      })
      .state('driverLogin', {
        // parent: 'main',
        url: '/driver-login',
        component: 'driverLogin'
      })
      .state('logout', {
        // parent: 'main',
        url: '/logout',
        component: 'logout'
      })
    ;
  }
})();
