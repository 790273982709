(function () {
  "use strict";

  angular
    .module('app.settings')
    .component('settingsZones', {
      bindings: {
        resolveData: '<'
      },
      templateUrl: 'app/settings/zones/settings.zones.html',
      controller: SettingsZonesCtrl,
      controllerAs: 'vm'
    });

  SettingsZonesCtrl.$inject = ['$scope', '$mdToast', 'SettingsService', 'BackendApi', 'MapOsmService'];

  function SettingsZonesCtrl($scope, $mdToast, SettingsService, BackendApi, MapOsmService) {
    var self = this,
      textDelete = {
        title: 'Удаление геозоны',
        content: 'Вы уверены что хотите удалить геозону?'
      }
    ;

    self.days = {
      workingDays: [
        {
          name: 'Пн',
          checked: true
        },
        {
          name: 'Вт',
          checked: true
        },
        {
          name: 'Ср',
          checked: true
        },
        {
          name: 'Чт',
          checked: true
        },
        {
          name: 'Пт',
          checked: true
        },
        {
          name: 'Сб',
          checked: false
        },
        {
          name: 'Нд',
          checked: false
        },
      ],
      shortenedDays: [
        {
          name: 'Пн',
          checked: false
        },
        {
          name: 'Вт',
          checked: false
        },
        {
          name: 'Ср',
          checked: false
        },
        {
          name: 'Чт',
          checked: false
        },
        {
          name: 'Пт',
          checked: false
        },
        {
          name: 'Сб',
          checked: true
        },
        {
          name: 'Нд',
          checked: false
        },
      ],
      weekend: [
        {
          name: 'Пн',
          checked: false
        },
        {
          name: 'Вт',
          checked: false
        },
        {
          name: 'Ср',
          checked: false
        },
        {
          name: 'Чт',
          checked: false
        },
        {
          name: 'Пт',
          checked: false
        },
        {
          name: 'Сб',
          checked: false
        },
        {
          name: 'Нд',
          checked: true
        },
      ],
      workingHours: {
        from: '08:00',
        to: '17:00'
      },
      shortenedHours: {
        from: '10:00',
        to: '16:00'
      }
    };

    this.$onInit = function () {
      initCustomPoint();
      MapOsmService.startGeoEdit();
      MapOsmService.setOnDrawCreatedHandler(function (item) {
        self.onEdit(item.toGeoJSON(), true);
        $scope.$digest();
      });
      this.zones = parseZones(this.resolveData.list, this.resolveData.login);
    };

    this.$onDestroy = function () {
      MapOsmService.stopGeoEdit();
      MapOsmService.setOnDrawCreatedHandler(null);
    };

    this.onChangeColor = function () {
      MapOsmService.zoneColorChanged(this.zone.color);
    };

    self.changeDayStatus = function(day, type) {
      var dayTypes = ['workingDays', 'shortenedDays', 'weekend'];
      _.forEach (dayTypes, function (dayType) {
        if (dayType !==  type) {
          _.forEach (self.zone.days[dayType], function (wDay) {
              if (wDay.name === day.name) {
                if (day.checked) {
                  wDay.checked = false;
                }
              }
            }
          );
        }
      });
    };

    self.searchLocations = function (query) {
      return BackendApi.getLocationsByAddress(query);
    };

    self.findLocationChange = function () {
      var p = [self.customPoint.selectedLocation.lat, self.customPoint.selectedLocation.lon];
      MapOsmService.goToPlace(p, 16, true);
    };

    function initCustomPoint() {
      self.customPoint = {
        selectedLocation: null
      };
    }

    this.onEdit = function (item, isNew) {
      if (isNew) {
        this.zone = {
          login: this.resolveData.login,
          name: 'Новая геозона',
          color: '#ffffff',
          days: angular.copy(self.days)
        };
      } else {
        this.zone = angular.copy(item);
        if (!this.zone.days) {
          this.zone.days = angular.copy(self.days);
        }
        MapOsmService.startEditZone(item.id);

        MapOsmService.goToPlace(item.coordinates[0]);
        var anchor = document.querySelector('#zones-count');
        anchor.scrollIntoView();
      }
    };

    this.onDelete = function (ev, item) {
      SettingsService.showConfirmDialog(ev, textDelete)
        .then(function () {
          return BackendApi.deleteZone(item);
        })
        .then(function () {
          self.zone = self.zone && self.zone.id == item.id ? null : self.zone;
          _.find(self.zones, function (el, pos) {
            if (el.id == item.id) {
              self.zones.splice(pos, 1);
              return true;
            }
          });
          MapOsmService.zoneDeleted(item.id);
          SettingsService.showToastSuccess();
          BackendApi.getProfile();
        })
      ;
    };

    this.onFormCancel = function () {
      this.zone = null;
      MapOsmService.cancelCurrentZone();
    };

    this.onFromSave = function () {
      this.showLoader = true;
      delete this.zone.isOwn;
      MapOsmService.zoneSaved(this.zone)
        .then(function () {
          self.zone = null;
          SettingsService.showToastSuccess();
        })
        .finally(function () {
          self.showLoader = false;
        });
    };

    function parseZones (list, login) {
      return _.map(list, function (el) {
        el.isOwn = el.login == login;
        return el;
      });
    }
  }

})();
