(() => {
  angular
    .module('app.reports')
    .service('app.reports.helpers.xlsx', create);

  const borderCfg = {
    style: 'thin',
    color: {
      argb: '000000'
    }
  };

  const columnMaxWidth = 12;

  create.$inject = [
    'app.reports.helpers.common'
  ];

  function create(common) {
    return {
      renderHorizontalTable,
      renderVerticalTable,
      renderEmptyRow,
      applyDefaultWidthForColumns,
      styleRowAsHeader,
      styleRow,
      styleCellAsHeader,
      styleCellAsWeekend,
      styleRowAsWeekend,
      alignToLeft,
      styleBorderToCell,
      applyDefaultStylesToCell,
      applyDefaultStylesToCellInCommonReport,
      styleCellForDifferentiationOfLines
    };

    function renderEmptyRow(sheet) {
      sheet.addRow([]);
    }

    function renderHorizontalTable(rowsData, sheet) {
      const preparedRowsData = rowsData.map(prepareRowData);

      const columnsPerCellForWholeTable = [];
      preparedRowsData.forEach((rowData) => {
        rowData.forEach((cellObj, columnIndex) => {
          if (typeof columnsPerCellForWholeTable[columnIndex] === 'undefined') {
            columnsPerCellForWholeTable[columnIndex] = 1;
          }
          columnsPerCellForWholeTable[columnIndex] =
            Math.max(columnsPerCellForWholeTable[columnIndex], cellObj.columnsPerCell);
        });
      });

      const totalColumnsCount =
        preparedRowsData.reduce((acc, preparedRowData) => Math.max(acc, preparedRowData.length), 0);

      preparedRowsData.forEach((preparedRowData) => {
        while (preparedRowData.length < totalColumnsCount) {
          preparedRowData.push({
            value: '',
            columnsPerCell: 1
          });
        }

        preparedRowData.forEach((cellObj, columnIndex) => {
          cellObj.columnsPerCell = columnsPerCellForWholeTable[columnIndex];
        });
      });

      preparedRowsData.forEach((preparedRowData) => {
        const row = renderRow(preparedRowData, sheet);
        styleRow(row, true);

        const fistCell = row.getCell(1);

        styleCellAsHeader(fistCell);
        alignToLeft(fistCell);
      });
    }

    function renderVerticalTable(rowsData, sheet, alignLastColumnToLeft = true) {
      const preparedRowsData = rowsData.map(prepareRowData);

      const columnsPerCellForWholeTable = [];
      preparedRowsData.forEach((rowData) => {
        rowData.forEach((cellObj, columnIndex) => {
          if (typeof columnsPerCellForWholeTable[columnIndex] === 'undefined') {
            columnsPerCellForWholeTable[columnIndex] = 1;
          }
          columnsPerCellForWholeTable[columnIndex] =
            Math.max(columnsPerCellForWholeTable[columnIndex], cellObj.columnsPerCell);
        });
      });

      preparedRowsData.forEach((preparedRowData) => {
        preparedRowData.forEach((cellObj, columnIndex) => {
          cellObj.columnsPerCell = columnsPerCellForWholeTable[columnIndex];
        });
      });

      preparedRowsData.forEach((preparedRowData, rowIndex) => {
        const row = renderRow(preparedRowData, sheet);
        styleRow(row, false, alignLastColumnToLeft);

        if (rowIndex === 0) {
          styleRowAsHeader(row);

          for (let i = 0; i < row.cellCount; i++) {
            alignToLeft(row.getCell(i + 1));
          }
        }

        if (rowIndex === preparedRowsData.length - 1) {
          styleRowAsHeader(row);
          alignToLeft(row.getCell(1));
        }
      });
    }

    function renderRow(rowData, sheet) {
      const cells = rowData.reduce((acc, cellObj) => {
        const result = [cellObj.value];
        for (let i = 1; i < cellObj.columnsPerCell; i++) {
          result.push('');
        }
        return acc.concat(result);
      }, []);

      const row = sheet.addRow(cells);

      mergeRowCells(rowData, sheet, sheet.rowCount);

      return row;
    }

    function mergeRowCells(rowData, sheet, rowIndex) {
      let startColumnIndex = 1;

      rowData.forEach((cellObj) => {
        const columnsPerCell = cellObj.columnsPerCell;
        if (columnsPerCell === 1) {
          startColumnIndex += 1;
          return;
        }

        const endColumnIndex = startColumnIndex + columnsPerCell - 1;
        sheet.mergeCells(rowIndex, startColumnIndex, rowIndex, endColumnIndex);
        startColumnIndex = endColumnIndex + 1;
      });
    }

    function prepareRowData(rowData) {
      return rowData.map((cellValue) => {
        const columnsPerCell = Math.max(
          1,
          Math.ceil((cellValue || '').toString().length / columnMaxWidth)
        );

        return {
          value: cellValue,
          columnsPerCell
        };
      });
    }

    function applyDefaultWidthForColumns(sheet, width) {
      sheet.columns.forEach((column) => {
        column.width = width;
      });
    }

    function styleRowAsHeader(row) {
      for (let i = 0; i < row.cellCount; i++) {
        styleCellAsHeader(row.getCell(i + 1));
      }
    }

    function alignToLeft(cell) {
      cell.alignment = Object.assign(
        cell.alignment || {},
        {
          horizontal: 'left'
        }
      );
    }

    function styleCellAsHeader(cell, color = common.tableHeaderBgColor) {
      cell.font = Object.assign(
        {},
        cell.font || {},
        {
          bold: true
        }
      );

      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: color
        }
      };
    }

    function styleBorderToCell(cell) {
      cell.border = {
        bottom: borderCfg,
        top: borderCfg,
        left: borderCfg,
        right: borderCfg
      };
    }

    function styleCellAsWeekend(cell, color = common.weekendCellBgColor) {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: color
        }
      };
    }

    function styleCellForDifferentiationOfLines(cell) {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: 'eeeeee'
        }
      }
    }

    function applyDefaultStylesToCell(cell) {
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'left'
      };
      styleBorderToCell(cell);
    }

    function applyDefaultStylesToCellInCommonReport(cell) {
      applyDefaultStylesToCell(cell);
      cell.font = {
        size: 11
      }
      cell.alignment = Object.assign(
        {},
        (cell.alignment || {}),
        {
          horizontal: 'right'
        }
      )
    }

    function styleRowAsWeekend(row) {
      row.eachCell({includeEmpty: true}, (cell) => {
        styleCellAsWeekend(cell);
      });
    }

    function styleRow(row, hasHeader = false, isLastColumnAlignedToLeft = false) {
      row.eachCell({includeEmpty: true}, (cell, index) => {
        const shouldAlignToLeft =
          (hasHeader && index === 1) || (isLastColumnAlignedToLeft && index === row.actualCellCount);

        cell.alignment = {
          vertical: 'middle',
          horizontal: shouldAlignToLeft ? 'left' : 'right'
        };

        cell.border = Object.assign(cell.border || {}, {
          bottom: borderCfg,
          top: borderCfg,
          left: borderCfg,
          right: borderCfg
        });
      });
    }
  }
})();
