(() => {
  angular
    .module('app.notificationWatcher')
    .service('notificationUi', notificationUi);

  const statusType = {
    NO_GPS_CONNECTION_PL: 'NO_GPS_CONNECTION_PL',
    NO_GPS_CONNECTION: 'NO_GPS_CONNECTION',
    NO_GPS_COVERAGE: 'NO_GPS_COVERAGE',
    MOVEMENT: 'MOVEMENT',
    PARKING: 'PARKING',
    SPEEDING: 'SPEEDING',
    ENTER_GEOZONE: 'ENTER_GEOZONE',
    LEAVE_GEOZONE: 'LEAVE_GEOZONE',
    PARKING_IN_GEOZONE: 'PARKING_IN_GEOZONE'
  };

  notificationUi.$inject = [
    '$translate'
  ];

  function notificationUi($translate) {
    return {
      show
    };

    function show(event) {
      const message = getMessage(event);
      const options = {
        timeOut: 5000,
        positionClass: 'toast-top-left'
      };

      const statusTypesForWarnings = [
        statusType.SPEEDING,
        statusType.NO_GPS_CONNECTION,
        statusType.NO_GPS_COVERAGE
      ];

      if (statusTypesForWarnings.includes(event.payload.type)) {
        toastr.warning(message, null, options);
      } else {
        toastr.success(message, null, options);
      }
    }

    function getMessage(event) {
      const payload = event.payload;

      switch (payload.type) {
        case statusType.NO_GPS_CONNECTION_PL:
          return getNoGpsConnectionPlMessage(payload);
        case statusType.NO_GPS_CONNECTION:
          return getNoGpsConnectionMessage(payload);
        case statusType.NO_GPS_COVERAGE:
          return getNoGpsCoverageMessage(payload);
        case statusType.MOVEMENT:
          return getMovementStartedMessage(payload);
        case statusType.PARKING:
          return getParkingMessage(payload);
        case statusType.SPEEDING:
          return getSpeedingMessage(payload);
        case statusType.ENTER_GEOZONE:
          return getEnterGeozoneMessage(payload);
        case statusType.LEAVE_GEOZONE:
          return getLeaveGeozoneMessage(payload);
        case statusType.PARKING_IN_GEOZONE:
          return getParkingInGeozoneMessage(payload);
        default:
          return JSON.stringify(payload);
      }
    }

    function getNoGpsConnectionPlMessage(eventPayload) {
      return `
        <div>${eventPayload.vehicleName}</div>
        <div>${$translate.instant('notifications.noGpsConnectionPl')}</div>
      `;
    }

    function getNoGpsConnectionMessage(eventPayload) {
      return `
        <div>${eventPayload.vehicleName}</div>
        <div>${$translate.instant('notifications.noGpsConnection')}</div>
      `;
    }

    function getNoGpsCoverageMessage(eventPayload) {
      return `
        <div>${eventPayload.vehicleName}</div>
        <div>${$translate.instant('notifications.noGpsCoverage')}</div>
      `;
    }

    function getSpeedingMessage(eventPayload) {
      return `
        <div>${eventPayload.vehicleName}</div>
        <div>${$translate.instant('notifications.speeding')}</div>
        <div>
          ${$translate.instant('notifications.currentSpeed')}: 
          ${eventPayload.currentSpeed} 
          ${$translate.instant('notifications.kmPerHour')}
        </div>
        <div>
          ${$translate.instant('notifications.maxSpeed')}: 
          ${eventPayload.maxSpeed}
          ${$translate.instant('notifications.kmPerHour')}
        </div>
      `;
    }

    function getMovementStartedMessage(eventPayload) {
      return `
        <div>${eventPayload.vehicleName}</div>
        <div>${$translate.instant('notifications.movement')}</div>
      `;
    }

    function getParkingMessage(eventPayload) {
      return `
        <div>${eventPayload.vehicleName}</div>
        <div>${$translate.instant('notifications.parking')}</div>
      `;
    }

    function getEnterGeozoneMessage(eventPayload) {
      return `
        <div>${eventPayload.vehicleName}</div>
        <div>${$translate.instant('notifications.enterGeozone')}</div>
        <div>${eventPayload.geozoneName}</div>
      `;
    }

    function getLeaveGeozoneMessage(eventPayload) {
      return `
        <div>${eventPayload.vehicleName}</div>
        <div>${$translate.instant('notifications.leaveGeozone')}</div>
        <div>${eventPayload.geozoneName}</div>
      `;
    }

    function getParkingInGeozoneMessage(eventPayload) {
      return `
        <div>${eventPayload.vehicleName}</div>
        <div>${$translate.instant('notifications.parkingInGeozone')}</div>
        <div>${eventPayload.geozoneName}</div>
      `;
    }
  }
})();
