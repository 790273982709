(function () {
  "use strict";

  var SESSION_COOKIE_NAME = 'MGS';

  angular.module('app')
    .service('Session', Session);

  Session.$inject = ['$log', '$cookies', '$translate', '$localStorage', 'PermissionService'];

  function Session($log, $cookies, $translate, $localStorage, PermissionService) {
    var self = this,
      addressType = 'addressYandex',
      groupId = '0'
    ;
    self.id = null;
    self.lang = $localStorage.lang || 'ua';

    self.create = function (sessionId, user) {
      self.id = sessionId;
      self.user = angular.copy(user);
      self.lang = $localStorage.lang || 'ua';
      $translate.use(self.lang);
      $localStorage.u = self.user;
      $cookies.put(SESSION_COOKIE_NAME, sessionId);
      PermissionService.init(user.permissions, user.loginType);
    };

    self.destroy = function () {
      self.id = null;
      self.user = null;
      delete $localStorage.u;
      $cookies.remove(SESSION_COOKIE_NAME);
    };

    self.renew = function (sessionId) {
      $log.log('- try to renew local session', sessionId);
      var storedUser = $localStorage.u;

      if (storedUser && storedUser.session === sessionId) {
        $log.log('- session restored', sessionId);
        self.create(sessionId, storedUser);

        return true;
      }

      $log.log('- session timeout', sessionId);
      self.destroy();

      return false;
    };

    self.setLang = function (code){
      var mapLangs = ['ru', 'ua', 'en'];
      if (mapLangs.indexOf(code) == -1) {
        code = 'ua';
      }
      self.lang = code;
    };

    Object.defineProperty(self, 'langCode', {
      enumerable: false,
      get: function () {
        var code;

        switch (self.lang) {
          case 'en':
            code = 0;
            break;
          case 'ru':
            code = 1;
            break;
          case 'ua':
            code = 2;
            break;
          default:
            code = 2;
        }

        return code;
      }
    });

    Object.defineProperty(self, 'addressType', {
      enumerable: false,
      get: function () {
        return addressType;
      },
      set: function (value) {
        addressType = value;
      }
    });

    Object.defineProperty(self, 'groupId', {
      enumerable: false,
      get: function () {
        return groupId;
      },
      set: function (value) {
        groupId = value;
      }
    });

    return self;
  }
})();
