(() => {
  angular
    .module('vehicles.commonReportDialog')
    .component('commonReportDialog', {
      templateUrl: 'app/vehicles/services/common-report-dialog/common-report-dialog/tpl.html',
      controller: Ctrl,
      controllerAs: 'ctrl'
    });

  Ctrl.$inject = [
    '$rootScope',
    '$mdDialog',
    '$state',
    '$translate',
    'Session',
    'PeriodFilters',
    'BackendApi',
    'CarService',
    'app.reports.fileSaver',
    'app.reports.helpers.xlsx',
    'dialogControl/events',
    'vehicles.commonReportDialog.events',
    'AUTH_EVENTS'
  ];

  const withCompactModeClassName = 'with-common-report-dialog'

  function Ctrl($rootScope, $mdDialog, $state, $translate, Session, PeriodFilters, BackendApi, CarService,
    fileSaver, xlsxHelper, dialogControlEvents, commonReportDialogEvents, authEvents) {
    const unsubscribe = []

    const self = this;

    const headerColor = 'b4f8c8';
    let weekendBgColor = 'ffaebc';

    const headerBgColor = '999999';
    weekendBgColor = 'dddddd';

    const DISTANCE = 'distance';
    const FUEL_USAGE = 'fuel-usage';
    const DATE_FORMAT = 'YYYY.MM.DD';
    let isInitCars;
    const allCars = angular.copy(Session.user.cars);

    const i18nKeyForTotalValuesColumn = 'car.total';

    this.$onInit = () => {
      const lang = Session.lang;
      self.filter = PeriodFilters.getFilter('report');
      self.tmp = {
        types: [{
          id: DISTANCE,
          name: lang === 'ua' ? 'Пробіг' : 'Пробег',
          i18n: 'page.mileage'
        }, {
          id: FUEL_USAGE,
          name: lang === 'ua' ? 'Витрата' : 'Расход',
          i18n: 'car.fuelPrint.usage'
        }],
        exports: ['reportXls']
      };
      self.form = {
        type: self.tmp.types[0].id,
        includeSalesManWorkingTime: false
      };

      unsubscribe.push(
        $rootScope.$on(dialogControlEvents.ENTER_FULLSCREEN_MODE_PRESSED, () => {
          if (!isDesktopScreen()) {
            return
          }
          document.querySelector('body').classList.remove(withCompactModeClassName)
        })
      )

      unsubscribe.push(
        $rootScope.$on(dialogControlEvents.EXIT_FULLSCREEN_MODE_PRESSED, () => {
          if (!isDesktopScreen()) {
            return
          }
          document.querySelector('body').classList.add(withCompactModeClassName)
        })
      )

      unsubscribe.push(
        $rootScope.$on(authEvents.logoutSuccess, () => {
          this.onCancel()
        })
      )
    };

    this.$onDestroy = () => {
      document.querySelector('body').classList.remove(withCompactModeClassName)
      $rootScope.$emit(commonReportDialogEvents.CLOSED)

      while(unsubscribe.length) {
        unsubscribe.pop()()
      }
    }

    this.onCancel = function () {
      $mdDialog.cancel();
    };

    this.onSelectCar = function (cars) {
      if (isInitCars) {
        self.form.cars = cars;
        isInitCars = true;
      } else {
        self.form.cars = cars;
        self.onShowReport();
      }
    };

    this.onShowReport = function () {
      self.filter.to = moment(self.filter.to).endOf("day").startOf("second").toDate();
      self.showLoader = true;

      BackendApi.getReport(self.form, self.filter)
        .then(function (result) {
          self.days = getDays(self.filter);
          parseReport(self.form.type, result, self.days)
            .then(function (report) {
              self.report = report;
              self.showLoader = false;
              $rootScope.$digest();
            });
        })
        .catch(() => {
          self.showLoader = false;
          $rootScope.$digest();
        });
    };

    this.onDownloadReport = () => {
      render(self.report);
    }

    this.onDownloadReportOldVersion = function () {
      function getCell(value, color, type) {
        if (color) {
          return {
            v: value,
            t: type || 's',
            s: {fill: {fgColor: {rgb: color}}}
          };
        }

        return value;
      }

      var rows = [];
      rows.push([
        '',
        '',
        moment(self.filter.from).format(DATE_FORMAT),
        '',
        moment(self.filter.to).format(DATE_FORMAT)
      ]);
      rows.push([]);
      var header = [getCell('Номер', headerColor), getCell('Назва', headerColor)];

      self.days.forEach(function (day) {
        header.push(getCell(day.formatted, headerColor));
      });
      header.push(getCell('Всього', headerColor));

      rows.push(header);

      self.report.forEach(function (item, index) {
        var isLast = index === self.report.length - 1;
        var row = [getCell(item.id || '', isLast && headerColor), getCell(item.car.name, isLast && headerColor)];

        item.points.forEach(function (point) {
          var color;
          if (point.isWeekend) {
            color = weekendBgColor;
          }
          if (isLast) {
            color = headerColor;
          }

          row.push(getCell(Number(point.value), color, 'n'));
        });
        rows.push(row);
      });

      var workbook = XLSX.utils.book_new();
      var worksheet = XLSX.utils.aoa_to_sheet(rows);

      workbook.SheetNames.push("report");
      workbook.Sheets["report"] = worksheet;

      return XLSX.writeFile(workbook, 'report.xlsx');
    };

    this.onSetFilter = function (type) {
      var filter = CarService.setFormFilter(self.filter, type);
      self.filter.from = filter.from;
      self.filter.to = filter.to;
      self.onShowReport();
    };

    this.onGoToCar = function (item) {
      $state.go('vehicles.details.now', {id: item.id}, {reload: true});
      self.onCancel();
    };

    this.onGoToCarStop = function (item, point) {
      if (point.hasData) {
        const date = moment(point.date.replace(/\./g, "-"));
        const filter = PeriodFilters.getFilter('stops');

        filter.from = date.startOf('day').toDate();
        filter.to = date.endOf("day").startOf("second").toDate();
        $state.go('vehicles.details.stops', {id: item.id}, {reload: true});

        document.querySelector('body').classList.add(withCompactModeClassName)
        $rootScope.$emit(dialogControlEvents.EXIT_FULLSCREEN_MODE_PRESSED)

        if (!isDesktopScreen()) {
          self.onCancel();
        }
      }
    };

    function parseReport(type, list, days) {
      var dates = getFormatDate(self.filter, DATE_FORMAT);
      var useDate = type === DISTANCE;
      return Promise.all(
        list.map(function (el) {
          return Promise.all(dates.map(function (date, pos) {
            var value = el.points[useDate ? date : pos];
            var point = {
              isWeekend: days[pos].isWeekend,
              date: date,
              value: value ? parseFloat(value) : 0,
              hasData: !!value
            };

            if (self.form.includeSalesManWorkingTime) {
              return BackendApi.getCarSettings({id: el.id}).then(function (settings) {
                if (settings.salesManWorkingTime) {
                  var day = (new Date(date.replaceAll('.', '-'))).getDay();
                  var dayWorkingTime = settings.salesManWorkingTime.find(function (workingTime) {
                    return workingTime.index === day;
                  });

                  if (
                    dayWorkingTime &&
                    dayWorkingTime.isActive &&
                    dayWorkingTime.intervals[0].from &&
                    dayWorkingTime.intervals[0].to
                  ) {
                    var from = new Date(date.replaceAll('.', '-'));
                    from.setHours(dayWorkingTime.intervals[0].from.getHours());
                    from.setMinutes(dayWorkingTime.intervals[0].from.getMinutes());

                    var to = new Date(date.replaceAll('.', '-'));
                    to.setHours(dayWorkingTime.intervals[0].to.getHours());
                    to.setMinutes(dayWorkingTime.intervals[0].to.getMinutes());

                    return BackendApi.getHistory(el.id, {
                      from: from,
                      to: to
                    }).then(function (result) {
                      point.value = result.summary.distanceKm;
                      return Promise.resolve(point);
                    });
                  }
                }

                return Promise.resolve(point);
              });
            }

            return Promise.resolve(point);
          })).then(function (points) {
            el.points = points;
            return Promise.resolve(el);
          });
        })
      ).then(function (list) {
        return Promise.resolve(parseReportTotal(list));
      });
    }

    function parseReportTotal(list) {
      const resultRow = [];

      const result = _.map(list, function (el) {
        el.points.push({
          value: _.reduce(el.points, function (acc, point) {
            return acc + point.value;
          }, 0)
        });
        _.each(el.points, function (point, pos) {
          resultRow[pos] = (resultRow[pos] || 0) + point.value;
          point.value = point.value.toFixed(1);
        });
        el.car = _.findWhere(allCars, {id: el.id.toString()});
        return el;
      });

      result.push({
        car: {name: 'Всего'},
        points: _.map(resultRow, function (el) {
          return {value: el.toFixed(1)};
        })
      });
      return result;
    }

    function getDays(filter) {
      return _.map(joinAllDateForPeriod(filter), function (el) {
        var d = el.day();
        return {
          isWeekend: d == 6 || d == 0,
          day: el.date(),
          formatted: el.format(DATE_FORMAT)
        };
      });
    }

    function getFormatDate(filter, format) {
      return _.map(joinAllDateForPeriod(filter), function (el) {
        return el.format(format);
      });
    }

    function joinAllDateForPeriod(options) {
      options = options || {};
      const dates = angular.copy(options);
      const from = moment(dates.from || new Date());
      const to = moment(dates.to || new Date());
      const result = [];

      while (from <= to) {
        result.push(angular.copy(from));
        from.add(1, 'days');
      }

      return result;
    }

    function render(vehiclesData) {
      // get rid of the last row with total values and extra last column for each row
      vehiclesData = vehiclesData
        .slice(0, -1)
        .map((obj) => {
          return Object.assign(
            {},
            obj,
            {
              points: obj.points.slice(0, -1)
            }
          );
        });

      // based on print on physical printer
      const columnsPerPage = 35;
      // based on print on physical printer
      const rowsPerPage = 45;

      const headerHeight = 2;
      const columnWidth = 7;
      const maxCharactersPerColumn = 5;

      // row with dates and row with total values
      const sectionExtraRowsCount = 2;
      const countOfRowsBetweenSections = 1;
      const countOfColumnsAfterSectionHeaderInFirstSection = 1;
      const minCountOfSectionsPerPage = 1//2;
      const maxCountOfVehiclesPerSection =
        Math.floor(
          (rowsPerPage - countOfRowsBetweenSections - sectionExtraRowsCount * minCountOfSectionsPerPage)
          / minCountOfSectionsPerPage
        );

      const groupsOfVehicles = [];
      for (let vehicleIndex = 0; vehicleIndex < vehiclesData.length; vehicleIndex += maxCountOfVehiclesPerSection) {
        groupsOfVehicles.push(vehiclesData.slice(vehicleIndex, vehicleIndex + maxCountOfVehiclesPerSection));
      }

      let previousSectionStartRowIndex = 1;
      let previousSectionHeight = 0;
      let section;
      let firstSectionOnPage = null;

      let allSections = [];

      groupsOfVehicles.forEach((vehicles, groupIndex) => {
        const sectionHeight = sectionExtraRowsCount + vehicles.length;

        const widthOfNameColumn = 2
          // vehicles.reduce((acc, vehicle) =>
          //   Math.max(acc, getColumnsCountForText(vehicle.car.name, maxCharactersPerColumn)), 0
          // );

        const totalValueForVehicles =
          vehicles.reduce((acc, vehicle) => acc + getVehicleTotalValue(vehicle), 0);
        const totalColumnTitle = $translate.instant(i18nKeyForTotalValuesColumn);
        const widthOfTotalColumn = Math.max(
          getColumnsCountForText(Math.round(totalValueForVehicles * 100) / 100, maxCharactersPerColumn),
          getColumnsCountForText(totalColumnTitle, maxCharactersPerColumn)
        );

        const headerWidthInFirstSection =
          widthOfNameColumn + widthOfTotalColumn + countOfColumnsAfterSectionHeaderInFirstSection;
        const maxDataItemsInFirstSection = columnsPerPage - headerWidthInFirstSection;

        const headerWidthInExtraSections = widthOfNameColumn;
        const maxDataItemsInExtraSection = columnsPerPage - headerWidthInExtraSections;

        const totalCountOfDataItems = vehicles[0].points.length;

        const countOfDataItemsInFirstSection = Math.min(maxDataItemsInFirstSection, totalCountOfDataItems);

        const vehicleNames = vehicles.map((vehicle) => vehicle.car.name);
        const totalValuesForVehicles = vehicles.map((vehicle) =>
          vehicle.points.reduce((acc, obj) => acc + parseFloat(obj.value), 0)
        );

        const getSubsetOfVehiclePoints = (vehicle, startIndex, endIndex) => {
          if (endIndex === startIndex) {
            endIndex = endIndex + 1;
          }
          return vehicle.points.slice(startIndex, endIndex);
        };

        const sections = [];
        let sectionStartRowIndex = 1;

        if (groupIndex === 0) {
          sectionStartRowIndex = 1;
        } else {
          sectionStartRowIndex = previousSectionStartRowIndex + previousSectionHeight + countOfRowsBetweenSections;
        }

        const getTotalValuesPerDay = (vehicles, startIndex, endIndex) => vehicles
          .map((vehicle) =>
            getSubsetOfVehiclePoints(vehicle, startIndex, endIndex)
          )
          .reduce((acc, dataItemsPerVehicle) => {
            dataItemsPerVehicle.forEach((obj, index) => {
              if (isNaN(acc[index])) {
                acc[index] = 0;
              }
              acc[index] += parseFloat(obj.value);
            });

            return acc;
          }, []);

        const getDataItemsPerDay = (vehicles, startIndex, endIndex) => vehicles
          .map((vehicle) =>
            getSubsetOfVehiclePoints(vehicle, startIndex, endIndex)
          )
          .reduce((acc, dataItemsPerVehicle) => {
            dataItemsPerVehicle.forEach((obj, index) => {
              if (typeof acc[index] === 'undefined') {
                acc[index] = {
                  date: moment(obj.date.replace(/\./g, '/')).toDate(),
                  items: []
                };
              }
              acc[index].items.push(Object.assign(
                {},
                obj,
                {
                  value: parseFloat(obj.value)
                }
              ));
            });

            return acc;
          }, []);

        const isSectionOnSamePage = (section) => {
          const relativeRowIndex = section.startRowIndex - firstSectionOnPage.startRowIndex;
          return (relativeRowIndex + section.height) < rowsPerPage;
        };

        // first section in group
        section = {
          isFirstSection: true,
          isFirstSectionOnPage: false,
          startRowIndex: sectionStartRowIndex,
          height: sectionHeight,
          header: {
            nameColumn: {
              width: widthOfNameColumn,
              items: vehicleNames
            },
            summaryColumn: {
              width: widthOfTotalColumn,
              items: totalValuesForVehicles
            }
          },
          dataItems: {
            startColumnIndex: headerWidthInFirstSection + 1,
            items: getDataItemsPerDay(vehicles, 0, countOfDataItemsInFirstSection),
            totalValuesPerDay: getTotalValuesPerDay(vehicles, 0, countOfDataItemsInFirstSection)
          }
        };

        if (groupIndex === 0) {
          section.isFirstSectionOnPage = true;
          firstSectionOnPage = section;
        } else if (!isSectionOnSamePage(section)) {
          section.isFirstSectionOnPage = true;
          firstSectionOnPage = section;
        }

        sections.push(section);

        previousSectionStartRowIndex = sectionStartRowIndex;
        previousSectionHeight = sectionHeight;

        // other sections
        let startIndexOfDataItem = countOfDataItemsInFirstSection;
        while (startIndexOfDataItem < totalCountOfDataItems) {
          const endIndexOfDataItem =
            Math.min(startIndexOfDataItem + maxDataItemsInExtraSection - 1, totalCountOfDataItems - 1);

          sectionStartRowIndex = previousSectionStartRowIndex + previousSectionHeight + countOfRowsBetweenSections;

          section = {
            isFirstSection: false,
            isFirstSectionOnPage: false,
            startRowIndex: sectionStartRowIndex,
            height: sectionHeight,
            header: {
              nameColumn: {
                width: widthOfNameColumn,
                items: vehicleNames
              }
            },
            dataItems: {
              startColumnIndex: headerWidthInExtraSections + 1,
              items: getDataItemsPerDay(vehicles, startIndexOfDataItem, endIndexOfDataItem + 1),
              totalValuesPerDay: getTotalValuesPerDay(vehicles, startIndexOfDataItem, endIndexOfDataItem)
            }
          };

          if (!isSectionOnSamePage(section)) {
            firstSectionOnPage = section;
            section.isFirstSectionOnPage = true;
          }

          sections.push(section);

          startIndexOfDataItem = endIndexOfDataItem + 1;
          previousSectionStartRowIndex = sectionStartRowIndex;
          previousSectionHeight = sectionHeight;
        }

        allSections = allSections.concat(sections);
      });

      const startDate = moment(vehiclesData[0].points[0].date.replace(/\./g, '/')).format('DD.MM.YYYY');
      const endDate = moment(vehiclesData[0].points.slice(-1)[0].date.replace(/\./g, '/')).format('DD.MM.YYYY');
      const headerText = `${$translate.instant('report.commonReport')} ${startDate} - ${endDate}`;

      const workbook = new ExcelJS.Workbook();
      const sheet = workbook.addWorksheet($translate.instant('report.commonReport'), {
        pageSetup: {
          paperSize: 9,
          fitToPage: true,
          fitToHeight: 0,
          fitToWidth: 1,
          orientation: 'landscape',
          // XXX "margins" breaks document for Excel 2003
          // margins: {
          //   top: 0.25,
          //   bottom: 0.25,
          //   left: 0.25,
          //   right: 0.25
          // }
        },
        headerFooter: {
          oddHeader: `&L&12${headerText}`,
          evenHeader: `&L&12${headerText}`
        }
      });

      // renderReportHeader(sheet, [
      //   moment(vehiclesData[0].points[0].date).toDate(),
      //   moment(vehiclesData[0].points.slice(-1)[0].date).toDate()
      // ]);

      allSections.forEach((section) => {
        renderSection(sheet, section);
      });

      sheet.columns.forEach((column) => {
        column.width = columnWidth;
      });

      const fileName = [
        'common-report',
        moment(vehiclesData[0].points[0].date.replace(/\./g, '/')).format('DD-MM-YYYY'),
        moment(vehiclesData[0].points.slice(-1)[0].date.replace(/\./g, '/')).format('DD-MM-YYYY')
      ].join('_');
      fileSaver.saveXLSX(fileName, workbook);
    }

    function getColumnsCountForText(text, maxCharactersPerColumn) {
      const el = document.createElement('span');
      document.body.appendChild(el);

      el.innerText = ''.padStart(maxCharactersPerColumn, '0');
      const maxAllowedWidth = el.offsetWidth;

      el.innerText = `${text}`;
      const textWidth = el.offsetWidth;

      const countOfCells = Math.round(textWidth / maxAllowedWidth);

      document.body.removeChild(el);

      return countOfCells;
    }

    function getVehicleTotalValue(vehicle) {
      return vehicle.points.reduce((acc, obj) => Math.max(acc, parseFloat(obj.value)), 0);
    }

    function renderReportHeader(sheet, dates) {
      const startDate = moment(dates[0]).format('DD.MM.YYYY');
      const endDate = moment(dates[1]).format('DD.MM.YYYY');
      const text = `Common report ${startDate} - ${endDate}`;
      const width = getColumnsCountForText(text, 5);
      sheet.getRow(1).getCell(1).value = text;
      sheet.mergeCells(1, 1, 1, width);
    }

    function renderSection(sheet, section) {
      let rowIndex;
      let columnIndex;
      let cell;

      if (section.startRowIndex > 1 && section.isFirstSectionOnPage) {
        sheet.getRow(section.startRowIndex - 1).addPageBreak();
      }

      // ====================
      // "Name" column
      // ====================
      // top cell
      columnIndex = 1;
      rowIndex = section.startRowIndex;

      sheet.getRow(rowIndex).getCell(columnIndex).value = $translate.instant('global.caption');
      sheet.mergeCells(rowIndex, columnIndex, rowIndex, columnIndex + section.header.nameColumn.width - 1);

      cell = sheet.getRow(rowIndex).getCell(columnIndex);
      xlsxHelper.applyDefaultStylesToCellInCommonReport(cell);
      xlsxHelper.styleCellAsHeader(cell, headerBgColor);

      // cells with data
      section.header.nameColumn.items.forEach((vehicleName, index) => {
        rowIndex = section.startRowIndex + index + 1;
        // sheet.getRow(rowIndex).getCell(columnIndex).value =
        //   vehicleName.length <= 11 ? vehicleName  : `${vehicleName.substring(0, 8)}...`;
        sheet.getRow(rowIndex).getCell(columnIndex).value = vehicleName
        sheet.mergeCells(rowIndex, columnIndex, rowIndex, columnIndex + section.header.nameColumn.width - 1);

        cell = sheet.getRow(rowIndex).getCell(columnIndex);
        xlsxHelper.applyDefaultStylesToCellInCommonReport(cell);

        cell.alignment = {
          horizontal: 'left'
        }
      });

      // bottom cell
      rowIndex = section.startRowIndex + section.height - 1;
      sheet.getRow(rowIndex).getCell(columnIndex).value =
        $translate.instant(i18nKeyForTotalValuesColumn);
      sheet.mergeCells(rowIndex, columnIndex, rowIndex, columnIndex + section.header.nameColumn.width - 1);

      cell = sheet.getRow(rowIndex).getCell(columnIndex);
      xlsxHelper.applyDefaultStylesToCellInCommonReport(cell);
      xlsxHelper.styleCellAsHeader(cell, headerBgColor);

      //  ===================
      //  "Total" column
      //  ===================
      if (section.isFirstSection) {
        // top cell
        rowIndex = section.startRowIndex;
        columnIndex = section.header.nameColumn.width + 1;
        sheet.getRow(rowIndex).getCell(columnIndex).value = $translate.instant(i18nKeyForTotalValuesColumn);
        sheet.mergeCells(rowIndex, columnIndex, rowIndex, columnIndex + section.header.summaryColumn.width - 1);

        cell = sheet.getRow(rowIndex).getCell(columnIndex);
        xlsxHelper.applyDefaultStylesToCellInCommonReport(cell);
        xlsxHelper.styleCellAsHeader(cell, headerBgColor);

        // cells with data
        section.header.summaryColumn.items.forEach((value, index) => {
          rowIndex = section.startRowIndex + index + 1;
          sheet.getRow(rowIndex).getCell(columnIndex).value = Math.round(value);
          sheet.mergeCells(rowIndex, columnIndex, rowIndex, columnIndex + section.header.summaryColumn.width - 1);

          cell = sheet.getRow(rowIndex).getCell(columnIndex);
          xlsxHelper.applyDefaultStylesToCellInCommonReport(cell);

          if (index % 2 === 1) {
            xlsxHelper.styleCellForDifferentiationOfLines(cell)
          }
        });

        // bottom cell
        rowIndex = section.startRowIndex + section.height - 1;
        sheet.getRow(rowIndex).getCell(columnIndex).value =
          Math.round(
            section.header.summaryColumn.items.reduce((acc, value) => acc + value, 0)
          );
        $translate.instant(i18nKeyForTotalValuesColumn);
        sheet.mergeCells(rowIndex, columnIndex, rowIndex, columnIndex + section.header.summaryColumn.width - 1);

        cell = sheet.getRow(rowIndex).getCell(columnIndex);
        xlsxHelper.applyDefaultStylesToCellInCommonReport(cell);
        xlsxHelper.styleCellAsHeader(cell, headerBgColor);
      }

      //  ===================
      //  Columns with data
      //  ===================
      section.dataItems.items.forEach((dataItem, index) => {
        columnIndex = section.dataItems.startColumnIndex + index;

        // top cell with date
        rowIndex = section.startRowIndex;
        sheet.getRow(rowIndex).getCell(columnIndex).value = dataItem.date.getDate();

        cell = sheet.getRow(rowIndex).getCell(columnIndex);
        xlsxHelper.applyDefaultStylesToCellInCommonReport(cell);
        xlsxHelper.styleCellAsHeader(cell, headerBgColor);

        // cells with data
        dataItem.items.forEach((obj, i) => {
          rowIndex = section.startRowIndex + i + 1;
          sheet.getRow(rowIndex).getCell(columnIndex).value = Math.round(obj.value);

          cell = sheet.getRow(rowIndex).getCell(columnIndex);
          xlsxHelper.applyDefaultStylesToCellInCommonReport(cell);

          if (obj.isWeekend) {
            xlsxHelper.styleCellAsWeekend(cell, weekendBgColor);
          } else if(i % 2 === 1) {
            xlsxHelper.styleCellForDifferentiationOfLines(cell)
          }
        });

        // bottom cell
        rowIndex = section.startRowIndex + section.height - 1;
        let totalValue = section.dataItems.totalValuesPerDay[index];
        if (isNaN(totalValue)) {
          totalValue = 0;
        }
        sheet.getRow(rowIndex).getCell(columnIndex).value =
          Math.round(totalValue);

        cell = sheet.getRow(rowIndex).getCell(columnIndex);
        xlsxHelper.applyDefaultStylesToCellInCommonReport(cell);
        xlsxHelper.styleCellAsHeader(cell, headerBgColor);
      });
    }
  }

  function isDesktopScreen() {
    return window.innerWidth > 480
  }
})();
