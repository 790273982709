(() => {
  angular
    .module('app.vehicles')
    .service('app.reports.fuel.reportSaver', reportSaver);

  reportSaver.$inject = [
    '$translate',
    'app.reports.fuel.reportDataBuilder',
    'app.reports.exportTypes',
    'app.reports.fileSaver',
    'app.reports.helpers.xlsx'
  ];

  function reportSaver($translate, reportDataBuilder, exportTypes, fileSaver, xlsxHelper) {
    return {
      toXLSX
    };

    function toXLSX(data) {
      const workbook = new ExcelJS.Workbook();
      const sheet = workbook.addWorksheet($translate.instant('car.fuelPrint.title'));

      renderPageHeader(sheet, data);
      xlsxHelper.renderEmptyRow(sheet);
      renderMainTableHeader(sheet, data);
      renderMainTableBody(sheet, data);
      renderMainTableFooter(sheet, data);

      fileSaver.saveXLSX(reportDataBuilder.createFileName(data), workbook);

      function renderPageHeader(sheet, data) {
        const rowsData = [
          [
            $translate.instant('report.fuel.periodStr', {
              from: data.subHeader.from,
              to: data.subHeader.to
            }),
            '',
            '',
            '',
            ''
          ],
          [
            `${$translate.instant('car.fuelPrint.carName')}: ${data.subHeader.vehicleName}`,
            '',
            '',
            '',
            ''
          ]
        ];

        rowsData.forEach((rowData) => {
          sheet.addRow(rowData);
          const rowIndex = sheet.rowCount;
          sheet.mergeCells(rowIndex, 1, rowIndex, 5);
        });
      }

      function renderMainTableHeader(sheet, data) {
        const hasOneTank = data.isOneTank;

        const rows = [
          []
        ];
        if (!hasOneTank) {
          rows.push([]);
        }

        // "Date" column
        rows[0] = rows[0].concat([
          $translate.instant('global.date')
        ]);
        if (!hasOneTank) {
          rows[1] = rows[1].concat(['']);
        }

        // "Initial fuel amount" column
        if (hasOneTank) {
          rows[0] = rows[0].concat([
            $translate.instant('car.fuelPrint.fuelOnStart')
          ]);
        } else {
          rows[0] = rows[0].concat([
            $translate.instant('car.fuelPrint.fuelOnStart'),
            '',
            ''
          ]);
          rows[1] = rows[1].concat([
            $translate.instant('report.tank'),
            $translate.instant('report.number', {
              number: 1
            }),
            $translate.instant('report.number', {
              number: 2
            })
          ]);
        }

        // "Refueled" column
        if (hasOneTank) {
          rows[0] = rows[0].concat([
            $translate.instant('report.refueled')
          ]);
        } else {
          rows[0] = rows[0].concat([
            $translate.instant('report.refueled'),
            '',
            ''
          ]);

          rows[1] = rows[1].concat([
            $translate.instant('report.tank'),
            $translate.instant('report.number', {
              number: 1
            }),
            $translate.instant('report.number', {
              number: 2
            })
          ]);
        }

        // "Discharge" column
        if (hasOneTank) {
          rows[0] = rows[0].concat([
            $translate.instant('car.fuelPrint.discharge')
          ]);
        } else {
          rows[0] = rows[0].concat([
            $translate.instant('car.fuelPrint.discharge'),
            '',
            ''
          ]);

          rows[1] = rows[1].concat([
            $translate.instant('report.tank'),
            $translate.instant('report.number', {
              number: 1
            }),
            $translate.instant('report.number', {
              number: 2
            })
          ]);
        }

        // "End fuel amount" column
        if (hasOneTank) {
          rows[0] = rows[0].concat([
            $translate.instant('car.fuelPrint.fuelOnEnd')
          ]);
        } else {
          rows[0] = rows[0].concat([
            $translate.instant('car.fuelPrint.fuelOnEnd'),
            '',
            ''
          ]);

          rows[1] = rows[1].concat([
            $translate.instant('report.tank'),
            $translate.instant('report.number', {
              number: 1
            }),
            $translate.instant('report.number', {
              number: 2
            })
          ]);
        }

        // "Fuel usage" column
        if (hasOneTank) {
          rows[0] = rows[0].concat([
            $translate.instant('car.fuelPrint.usage')
          ]);
        } else {
          rows[0] = rows[0].concat([
            $translate.instant('car.fuelPrint.usage'),
            '',
            ''
          ]);

          rows[1] = rows[1].concat([
            $translate.instant('report.tank'),
            $translate.instant('report.number', {
              number: 1
            }),
            $translate.instant('report.number', {
              number: 2
            })
          ]);
        }

        // "Mileage" column
        rows[0] = rows[0].concat([
          $translate.instant('car.mileage')
        ]);
        if (!hasOneTank) {
          rows[1] = rows[1].concat([
            ''
          ]);
        }

        // "Average fuel usage"
        rows[0] = rows[0].concat([
          $translate.instant('car.fuelPrint.averageFuelUsage')
        ]);
        if (!hasOneTank) {
          rows[1] = rows[1].concat([
            ''
          ]);
        }

        // "Engine" column
        rows[0] = rows[0].concat([
          $translate.instant('car.fuelPrint.engine')
        ]);
        if (!hasOneTank) {
          rows[1] = rows[1].concat([
            ''
          ]);
        }

        rows.forEach((rowData) => {
          const row = sheet.addRow(rowData);
          xlsxHelper.styleRow(row);
          xlsxHelper.styleRowAsHeader(row);

          row.eachCell({includeEmpty: true}, (cell) => {
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'center',
              wrapText: true
            };
          });

          if (hasOneTank) {
            row.height = 34;
          }
        });

        const firstRowIndex = hasOneTank ? sheet.rowCount : sheet.rowCount - 1;
        console.log(firstRowIndex);

        // "Date" column
        if (!hasOneTank) {
          sheet.mergeCells(firstRowIndex, 1, firstRowIndex + 1, 1);
        }
        sheet.columns[0].width = 12;

        // "Initial fuel amount" column
        if (hasOneTank) {
          sheet.columns[1].width = 14;
        } else {
          sheet.mergeCells(firstRowIndex, 2, firstRowIndex, 4);
          sheet.columns[1].width = 6;
          sheet.columns[2].width = 6;
          sheet.columns[3].width = 6;
        }

        // "Refueled" column
        if (hasOneTank) {
          sheet.columns[2].width = 13;
        } else {
          sheet.mergeCells(firstRowIndex, 5, firstRowIndex, 7);
          sheet.columns[4].width = 6;
          sheet.columns[5].width = 6;
          sheet.columns[6].width = 6;
        }

        // "Discharge" column
        if (hasOneTank) {
          sheet.columns[3].width = 10;
        } else {
          sheet.mergeCells(firstRowIndex, 8, firstRowIndex, 10);
          sheet.columns[7].width = 6;
          sheet.columns[8].width = 6;
          sheet.columns[9].width = 6;
        }

        // "End fuel amount" column
        if (hasOneTank) {
          sheet.columns[4].width = 14;
        } else {
          sheet.mergeCells(firstRowIndex, 11, firstRowIndex, 13);
          sheet.columns[10].width = 6;
          sheet.columns[11].width = 6;
          sheet.columns[12].width = 6;
        }

        // "Fuel usage" column
        if (hasOneTank) {
          sheet.columns[5].width = 10;
        } else {
          sheet.mergeCells(firstRowIndex, 14, firstRowIndex, 16);
          sheet.columns[13].width = 6;
          sheet.columns[14].width = 6;
          sheet.columns[15].width = 6;
        }

        // "Mileage" column
        if (hasOneTank) {
          sheet.columns[6].width = 10;
        } else {
          sheet.mergeCells(firstRowIndex, 17, firstRowIndex + 1, 17);
          sheet.columns[16].width = 10;
        }

        // "Average fuel usage"
        if (hasOneTank) {
          sheet.columns[7].width = 16;
        } else {
          sheet.mergeCells(firstRowIndex, 18, firstRowIndex + 1, 18);
          sheet.columns[17].width = 16;

          const cell = sheet.getRow(1).getCell(18);
          cell.alignment = Object.assign(
            (cell.alignment || {}),
            {
              wrapText: true
            }
          )
        }

        // "Engine" column
        if (hasOneTank) {
          sheet.columns[8].width = 12;
        } else {
          sheet.mergeCells(firstRowIndex, 19, firstRowIndex + 1, 19);
          sheet.columns[18].width = 12;
        }
      }

      function renderMainTableBody(sheet, data) {
        const hasOneTank = data.isOneTank;

        let rows = [];

        if (hasOneTank) {
          rows = data.list.map((obj) => {
            return [
              obj.date,
              obj.initialFuelAmountInAllTanks,
              obj.totalRefuelingInAllTanks,
              obj.totalFuelUsageWithDischargeInAllTanks,
              obj.finalFuelAmountInAllTanks,
              obj.totalFuelUsageInAllTanks,
              obj.mileage,
              obj.averageFuelUsage,
              obj.engineOperationDurationFormatted
            ];
          });
        } else {
          rows = data.list.map((obj) => {
            return [
              obj.date,
              obj.initialFuelAmountInAllTanks,
              obj.initialFuelAmountInMainTank,
              obj.initialFuelAmountInSecondTank,
              obj.totalRefuelingInAllTanks,
              obj.totalRefuelingInMainTank,
              obj.totalRefuelingInSecondTank,
              obj.totalFuelDrainInAllTanks,
              obj.totalFuelDrainInMainTank,
              obj.totalFuelDrainInSecondTank,
              obj.finalFuelAmountInAllTanks,
              obj.finalFuelAmountInMainTank,
              obj.finalFuelAmountInSecondTank,
              obj.totalFuelUsageInAllTanks,
              obj.totalFuelUsageInMainTank,
              obj.totalFuelUsageInSecondTank,
              obj.mileage,
              obj.averageFuelUsage,
              obj.engineOperationDurationFormatted
            ];
          });
        }

        rows.forEach((rowData) => {
          const row = sheet.addRow(rowData);
          xlsxHelper.styleRow(row);
        });
      }

      function renderMainTableFooter(sheet, data) {
        const summary = data.summary;
        const hasOneTank = data.isOneTank;

        let rowData = [];

        // "Total"
        rowData = rowData.concat([
          $translate.instant('car.total')
        ]);

        // skip "Initial fuel amount"
        if (hasOneTank) {
          rowData = rowData.concat(['']);
        } else {
          rowData = rowData.concat([
            '',
            '',
            ''
          ]);
        }

        // "Refueled"
        if (hasOneTank) {
          rowData = rowData.concat([
            summary.totalRefuelingInAllTanks
          ]);
        } else {
          rowData = rowData.concat([
            summary.totalRefuelingInAllTanks,
            summary.totalRefuelingInMainTank,
            summary.totalRefuelingInSecondTank
          ]);
        }

        // "Discharge"
        if (hasOneTank) {
          rowData = rowData.concat([
            summary.totalRefuelingInAllTanks
          ]);
        } else {
          rowData = rowData.concat([
            summary.totalFuelDrainInAllTanks,
            summary.totalFuelDrainInMainTank,
            summary.totalFuelDrainInSecondTank
          ]);
        }

        // skip "End fuel amount"
        if (hasOneTank) {
          rowData = rowData.concat([
            ''
          ]);
        } else {
          rowData = rowData.concat([
            '',
            '',
            ''
          ]);
        }

        // "Fuel usage"
        if (hasOneTank) {
          rowData = rowData.concat([
            summary.totalFuelUsageInAllTanks
          ]);
        } else {
          rowData = rowData.concat([
            summary.totalFuelUsageInAllTanks,
            summary.totalFuelUsageInMainTank,
            summary.totalFuelUsageInSecondTank
          ]);
        }

        // "Mileage"
        rowData = rowData.concat([
          summary.mileage
        ]);

        // "Average fuel usage"
        rowData = rowData.concat([
          summary.averageFuelUsage
        ]);

        // "Engine"
        rowData = rowData.concat([
          summary.engineOperationDurationFormatted
        ]);

        const row = sheet.addRow(rowData);

        xlsxHelper.styleRow(row);
        xlsxHelper.styleRowAsHeader(row);

        row.eachCell({includeEmpty: true}, (cell, index) => {
          cell.alignment = {
            vertical: 'middle',
            horizontal: index === 1 ? 'left' : 'right',
            wrapText: true
          };
        });

        const rowIndex = sheet.rowCount;

        // "Initial fuel amount"
        if (!hasOneTank) {
          sheet.mergeCells(rowIndex, 2, rowIndex, 4);
        }

        // "End fuel amount"
        if (!hasOneTank) {
          sheet.mergeCells(rowIndex, 11, rowIndex, 13);
        }
      }
    }
  }
})();
