(() => {
  angular
    .module('app.vehicles')
    .service('notificationSettingsApi', api);

  api.$inject = [
    'BackendApi'
  ];

  function api(backendApi) {
    return {
      fetchSettings,
      saveSettings,
      saveNotificationAboutTransitsThroughGeozonesSettings
    };

    function fetchSettings(vehicleId) {
      return backendApi
        .makeRequest({
          v: 3,
          vehicleNotificationSettingsCmd: 'get',
          vehicleId
        })
    }

    function saveSettings(vehicleId, isEnabled) {
      return backendApi
        .makeRequest({
          v: 3,
          vehicleNotificationSettingsCmd: 'set',
          vehicleId,
          isEnabled
        })
    }

    function saveNotificationAboutTransitsThroughGeozonesSettings(vehicleId, isEnabled) {
      return backendApi
        .makeRequest({
          v: 3,
          vehicleNotificationSettingsCmd: 'setNotificationAboutTransitsThroughGeozonesSettings',
          vehicleId,
          isEnabled
        })
    }
  }
})();
