(() => {
  angular
    .module('app.tokens')
    .service('app.tokens.accessByToken', accessByToken);

  accessByToken.$inject = [
    '$q',
    '$mdToast',
    'app.tokens.api',
    'ResponseParser',
    'Session'
  ];

  function accessByToken($q, $mdToast, tokenApi, ResponseParser, Session) {
    let initializingPromise = null

    const api = {
      isActive: false,
      tokenData: null,
      isAccessByToken,
      init
    }

    return api;

    function isAccessByToken() {
      return document.location.pathname === '/' && !!getTokenIdFromLocation();
    }

    function init() {
      if (initializingPromise !== null) {
        return initializingPromise
      }

      const deferred = $q.defer()
      initializingPromise = deferred.promise

      const tokenId = getTokenIdFromLocation()
      tokenApi.getToken(tokenId)
        .then((tokenData) => {
          return tokenApi.loginWithToken(tokenId)
            .then((resp) => {
              const loginData = ResponseParser.parse(resp.data)
              Session.create(null, loginData)

              api.isActive = true;
              api.tokenData = tokenData;
              deferred.resolve()
            })
        })
        .catch((e) => {
          let msg
          switch (e.status) {
            case 404:
              msg = 'Invalid token id'
              break
            default:
              msg = 'Can\'t login with token'
          }

          const toast = $mdToast.simple()
            .content(msg)
            .position('bottom left')
          $mdToast.show(toast)

          deferred.reject()
        })

      return initializingPromise
    }
  }

  function getTokenIdFromLocation() {
    return locationSearchToObject().t;
  }

  function locationSearchToObject() {
    return document.location.search
      .replace(/^\?/, '')
      .split('&')
      .reduce((acc, str) => {
        const parts = str.split('=');
        acc[parts[0]] = parts[1];
        return acc;
      }, {});
  }
})();
