(function () {
  "use strict";

  angular
    .module('app')
    .service('BackendApi', Backend);

  Backend.$inject = ['$window', '$http', '$localStorage', 'ResponseParser', 'Session', 'DateUtils', 'Utils'];

  function Backend($window, $http, $localStorage, ResponseParser, Session, DateUtils, Utils) {
    var self = this;

    // self.local = false; // emulate remote server
    self.local = $window.location.hostname === 'localhost'; // emulate remote server

    self.baseUrl = '/x.xx';
    self.lastCarId = 0;

    /**
     * @param {Object} data Request params
     * @return {Promise} Promise
     *
     * @description
     * Append data and make request.
     */
    this.makeRequest = makeRequest;

    function makeRequest(data) {
      data = data || {};
      var langMap = {
        en: 0,
        ru: 1,
        ua: 2
      };
      data.l = langMap.hasOwnProperty($localStorage.lang) ? langMap[$localStorage.lang] : 2;
      data.s = Session.id;

      let backendUrl = '/';

      try {
        const shouldUseDevBackend = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test';
        if (shouldUseDevBackend && typeof process.env.DEV_BACKEND !== 'undefined') {
          backendUrl = process.env.DEV_BACKEND;
        }
      } catch (e) {
      }

      const requestUrl = `${ backendUrl }x.xx/`;

      return $http.post(requestUrl, data)
        .then(function (resp) {
          return ResponseParser.parse(resp.data);
        });
    }

    /**
     * @description
     * Does login and returns 'user' object.
     */
    self.getProfile = function () {
      return makeRequest({
        c: 1
      })
        .then(function (result) {
          Session.create(result.session, result);
          return result;
        });
    };

    self.login = function (login, password) {
      return makeRequest({
        c: 1,
        x: login,
        y: password
      });
    };

    self.logout = function () {
      return makeRequest({c: 0});
    };

    self.addTracker = function (options) {
      options = options || {};
      return makeRequest({
        c: 19,
        x: options.x,
        y: options.y
      });
    };

    self.saveSettings = function (options) {
      options = options || {};
      return makeRequest({
        v: 3,
        settings: JSON.stringify(options)
      });
    };

    self.saveTimeoutSettings = function (options) {
      options = options || {};
      return makeRequest({
        c: 15,
        x: options.x,
        y: 2,
        i: options.id,
      });
    };

    self.saveBaterySettings = function (options) {
      options = options || {};
      return makeRequest({
        c: 15,
        x: options.x,
        y: 1,
        i: options.id,
      });
    };

    self.saveOdometer = function (carId, odometer) {
      return makeRequest({
        c: 36,
        i: carId,
        a: odometer
      });
    };

    self.changePassword = function (options) {
      options = options || {};
      return makeRequest({
        c: 20,
        x: options.x,
        y: options.y
      });
    };

    self.setIntervals = function (options) {
      options = options || {};
      var intervals = angular.copy(options);
      _.each(intervals, function (pVal) {
        _.each(pVal, function (val, key) {
          if (val instanceof Date) {
            if (key === 'to') {
              var toHour = moment(val).hour();
              if (toHour === 0) {
                val.setSeconds(val.getSeconds() - 1);
              }
            }

            pVal[key] = moment(val).format('HH:mm:ss');
          }
        });
      });
      return makeRequest({
        v: 3,
        intervals: JSON.stringify(intervals)
      });
    };

    self.setSeasons = function (options) {
      options = options || {};
      return makeRequest({
        v: 3,
        seasons: JSON.stringify({
          summer: moment(options.summer).format('YYYY.MM.DD'),
          winter: moment(options.winter).format('YYYY.MM.DD')
        })
      });
    };

    self.saveZone = function (options) {
      options = options || {};
      var pureZone = _.pick(options.zone, 'color', 'id', 'login', 'name', 'wkt', 'days');
      if (pureZone.days) {
        pureZone.days = JSON.stringify(pureZone.days);
      }
      return makeRequest({
        v: 3,
        zone: JSON.stringify(pureZone)
      });
    };

    self.deleteZone = function (options) {
      options = options || {};
      return makeRequest({
        v: 3,
        zoneId: options.id
      });
    };

    self.blockUser = function (options) {
      options = options || {};
      return makeRequest({
        v: 3,
        blockUser: options.isBlocked,
        id: options.id
      });
    };

    self.getUserPermissions = function (options) {
      options = options || {};
      return makeRequest({
        i: 'perm',
        v: 3,
        userPermissionsLogin: options.name
      });
    };

    self.setUserPermissions = function (options) {
      options = options || {};
      return makeRequest({
        v: 3,
        permissions: JSON.stringify(options.permissions),
        permissionsLogin: options.name
      });
    };

    /*self.getUserCarsSubscriptionFees (options) {
      options = options || {};
      return makeRequest({
        v: 3,
        userCarsSubscriptionFees: options.name
      });
    }*/

    /**
     * @param {Integer} id Car ID (0 for all)
     *
     * @description
     * Returns the current position of certain device or all the devices in account.
     */
    self.getCarNow = function (id) {
      self.lastCarId = id;
      return makeRequest({c: 3, i: id});
    };

    self.getCarByGroup = function (options) {
      options = options || {};
      return makeRequest({
        c: 1,
        i: options.id,
        x: ''
      });
    };

    self.getCarMegaInfo = function (id, filter) {
      return makeRequest({
        c: 1111,
        v: 3,
        mega: true,
        limit: -1,
        device: id,
        from: DateUtils.toUnix(filter.from),
        to: DateUtils.toUnix(filter.to),
        onlyCnt: 0,
        onlyData: 1
      });
    };

    /**
     * @param id
     * @param {Filter} filter period filter
     *
     * @description
     * Returns info about vehicle's stops.
     */
    self.getStops = function (id, filter) {
      var data = {
        c: 28,
        i: id,
        a: DateUtils.toUnix(filter.from),
        b: DateUtils.toUnix(filter.to)
      };

      return makeRequest(data);
    };


    self.getStopsExtended = function (id, filter) {
      var data = {
        c: 29,
        i: id,
        x: DateUtils.toUnix(filter.from),
        y: DateUtils.toUnix(filter.to)
      };

      return makeRequest(data);
    };

    self.getSensorsData = function (id, filter) {
      var data = {
        c: 30,
        i: id,
        x: DateUtils.toUnix(filter.from),
        y: DateUtils.toUnix(filter.to)
      };

      return makeRequest(data);
    };


    /**
     * @param id
     * @param filter
     *
     * @description
     * Returns history of vehicle's movements.
     */
    self.getHistory = function (id, filter) {
      var data = {
        c: 4,
        i: id,
        x: DateUtils.toUnix(filter.from),
        y: DateUtils.toUnix(filter.to)
      };

      return makeRequest(data);
    };

    self.getMileage = function (options, filter) {
      options = options || {};
      filter = filter || {};
      var req = {
        v: 3,
        distance: 1,
        device: options.id,
        from: DateUtils.toUnix(filter.from),
        to: DateUtils.toUnix(filter.to),
        limits: DateUtils.joinAllDateForPeriod(filter)
      };
      return makeRequest(req)
        .then((result) => Utils.cutMileageData(result, filter));
    };

    self.setOutputState = function (options) {
      options = options || {};

      return makeRequest({
        c: 14,
        i: options.id,
        x: options.state
      });
    };

    self.getCarSettings = function (options) {
      options = options || {};

      return makeRequest({
        i: options.id,
        c: 7
      });
    };

    self.setCarFuel = function (options) {
      options = options || {};

      return makeRequest({
        id: options.id,
        v: 3,
        fuel: options.fuel
      });
    };

    self.setCarSubscriberPlan = function (options) {
      options = options || {};

      return makeRequest({
        id: options.id,
        v: 3,
        carSubscriberPlan: options.carSubscriberPlan || 0
      });
    };

    self.setCarsSubscriberPlans = function (options) {
      options = options || {};

      return makeRequest({
        v: 3,
        carsSubscriberPlans:  JSON.stringify(_.map(options.cars, function (el) {
          return {id: el.id, subscriberPlan: el.subscriberPlan};
        }))
      });
    };

    self.getCarsSubscriberPlansData = function (options) {
      options = options || {};
      return makeRequest({
        v: 3,
        carsIdsForSubscriberPlansData: _.map(options.cars, function (el) { return el.id; }).join(",")
      });
    };

    self.getCarsSubscriberPlans = function (options) {
      options = options || {};
      return makeRequest({
        v: 3,
        carSubscriberPlansYear: options.year || (new Date()).getFullYear()
      });
    };

    self.setSalesManWorkingTime = function (options) {
      options = options || {};
      var salesManWorkingTime = '';
      options.salesManWorkingTime.forEach(function (val) {
        if (salesManWorkingTime) {
          salesManWorkingTime += '#';
        }

        salesManWorkingTime += val.index.toString() + ',' + (val.isActive ? '1' : '0');

        val.intervals.forEach(function (interval) {
          salesManWorkingTime += (',' +
            (
              (interval.from && interval.to) ?
                (
                  interval.from.toLocaleTimeString('en-GB', {
                    hour: '2-digit',
                    minute: '2-digit'
                  }) + '-' +
                  interval.to.toLocaleTimeString('en-GB', {
                    hour: '2-digit',
                    minute: '2-digit'
                  })
                ) :
              ''
            )
          );
        });
      });

      return makeRequest({
        id: options.id,
        v: 3,
        salesManWorkingTime: salesManWorkingTime
      });
    };

    self.setCarZones = function (options) {
      options = options || {};

      return makeRequest({
        id: options.id,
        v: 3,
        setCarZones: true,
        startZoneId: options.startZoneId || 0,
        endZoneId: options.endZoneId || 0
      });
    };

    self.setCargoCapacity = function (options) {
      options = options || {};

      return makeRequest({
        id: options.id,
        v: 3,
        setCargoCapacity: JSON.stringify({
          carryingCapacity: options.carryingCapacity || null,
          amount: options.amount || null
        })
      });
    };

    self.setCarImpulse = function (options) {
      options = options || {};

      return makeRequest({
        id: options.id,
        v: 3,
        setCarImpulse: options.impulse
      });
    };

    self.getCarsLogisticData = function (options) {
      options = options || {};
      return makeRequest({
        v: 3,
        carsIdsForLogisticData: _.map(options.cars, function (el) { return el.id; }).join(",")
      });
    };

    self.getUserSettings = function (options) {
      options = options || {};
      return makeRequest({
        v: 3,
        getUserSettings: options.name
      });
    };

    self.setUserSettings = function (options) {
      options = options || {};

      return makeRequest({
        v: 3,
        setUserSettings: JSON.stringify(_.pick(options, 'login', 'category', 'year', 'sequenceNumber', 'group', 'loginDescription'))
      });
    };

    self.getUsersSettingsByParams = function (options) {
      options = options || {};
      return makeRequest({
        v: 3,
        getUsersSettingsByParams: JSON.stringify({
          group: options.group,
          category: options.category
        })
      });
    };

    self.setCarSpeed = function (options) {
      options = options || {};

      return makeRequest({
        i: options.id,
        c: 8,
        x: options.x,
        z: options.z
      });
    };

    self.setCarInfo = function (options) {
      options = options || {};

      return makeRequest({
        i: options.id,
        c: 10,
        x: options.x,
        short: options.short,
        notes: options.notes
      });
    };

    self.getCarUssdHistory = function (options) {
      options = options || {};

      return makeRequest({
        i: options.id,
        c: 9
      });
    };

    self.sendCarUssd = function (options) {
      options = options || {};

      return makeRequest({
        i: options.id,
        c: options.x ? 11 : 12,
        x: options.x || ''
      });
    };

    self.removeTracker = function (options) {
      options = options || {};
      return makeRequest({
        c: 18,
        i: options.id
      });
    };

    self.addTracker = function (options) {
      options = options || {};
      return makeRequest({
        c: 19,
        i: options.id,
        y: options.password
      });
    };

    self.setMarkerFilter = function (options) {
      options = options || {};
      return makeRequest({
        id: options.id,
        v: 3,
        filterTracker: options.filterTracker
      });
    };

    self.getSchedules = function (options) {
      options = options || {};

      return makeRequest({
        i: options.id,
        v: 3,
        schedules: true
      });
    };

    self.getSchedules = function (options) {
      options = options || {};

      return makeRequest({
        i: 1388,
        v: 3,
        schedules: true
      });
    };

    self.setSchedules = function (options) {
      options = options || {};

      return makeRequest({
        v: 3,
        id: options.id,
        scheduleName: options.name,
        list: options.list.join(",")
      });
    };

    self.getSchedulesAssigned = function (options) {
      options = options || {};

      return makeRequest({
        v: 3,
        assignedSchedules: moment().unix()
      });
    };

    self.setSchedulesAssigned = function (options) {
      options = options || {};

      return makeRequest({
        v: 3,
        assignSchedule: options.assignSchedule,
        carId: options.carId,
        date: DateUtils.toUnix(options.date)
      });
    };

    self.getReport = function (options, filter) {
      options = options || {};
      filter = filter || {};

      return makeRequest({
        i: options.type != 'distance' ? 22: 33,
        v: 3,
        report: options.type,
        from: DateUtils.toUnix(filter.from),
        to: DateUtils.toUnix(filter.to),
        ids: _.map(options.cars, function (el) { return el.id; }).join(","),
        limits: DateUtils.joinAllDateForPeriod(filter)
      });
    };

    self.getCardBalance = function (options) {
      options = options || {};

      return makeRequest({
        i: 'balance',
        v: 3,
        cardBalance: options.list
      });
    };

    self.getFromMega = function (options) {
      options = options || {};

      return makeRequest({
        c: 1001,
        v: 3,
        mega: true,
        limit: -1,
        device: options.id,
        from: options.from,
        to: options.to,
        onlyCnt: options.onlyCnt,
        onlyData: options.onlyData
      });
    };

    self.getLocationsByAddress = function (query) {
      return Promise.resolve([]);
      /*return $http({
        url: 'https://nominatim.openstreetmap.org/search',
        method: 'GET',
        params: {
          format: 'json',
          q: query,
          limit: 5,
          addressdetails: 1
        }
      }).then(function (resp) {
        return resp.data;
      });*/
    };

    self.getAddressByLocation = function (options) {
      options = options || {};
      return $http({
        url: 'https://nominatim.openstreetmap.org/reverse',
        method: 'GET',
        params: {
          format: 'json',
          lat: options.lat,
          lon: options.lng
        }
      }).then(function (res) {
        return res.data.display_name;
      });
    };

    self.getRoutes = function () {
      return makeRequest({
        v: 3,
        getRoutes: true
      });
    };

    self.getRoute = function (options) {
      options = options || {};

      return makeRequest({
        v: 3,
        getRoute: options.id
      });
    };

    self.saveRoute = function (options) {
      options = options || {};

      return makeRequest({
        v: 3,
        saveRoute: JSON.stringify(_.pick(options, 'id', 'login', 'carId', 'title', 'dateTime', 'data', 'isAccepted', 'timeCorrectionInCity', 'timeCorrectionOutsideCity', 'routeComplexity'))
      });
    };

    self.deleteRoute = function (options) {
      options = options || {};
      return makeRequest({
        v: 3,
        deleteRoute: options.id
      });
    };

    self.sendAux = function (options) {
      options = options || {};
      return makeRequest({
        c: 34,
        i: options.id,
        x: options.code
      });
    };

    self.updateGpsControlSettings = (options) => {
      const vehicleId = options.vehicleId
      const isActive = options.isActive

      return makeRequest({
        c: 12,
        vehicleId,
        isActive
      })
    }

    self.updateV2FuelSettings = (options) => {
      const vehicleId = options.vehicleId
      const isActive = options.isActive

      return makeRequest({
        c: 13,
        vehicleId,
        isActive
      })
    }
  }
})();
