(() => {
  angular
    .module('app.vehicles')
    .service('VehicleSettingsToast', VehicleSettingsToast);

  VehicleSettingsToast.$inject = [
    '$mdToast',
    '$filter'
  ];

  function VehicleSettingsToast($mdToast, $filter) {
    this.successfulSaving = (trans = '') => {
      const toast = $mdToast
        .simple()
        .content($filter('translate')(trans || 'global.savedSuccess'))
        .position('bottom left');
      $mdToast.show(toast);
    }
  }
})();
