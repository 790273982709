(() => {
  angular
    .module('vehicles.commonReportDialog')
    .service('vehicles.commonReportDialog', dialog);

  dialog.$inject = [
    '$mdDialog'
  ];

  function dialog($mdDialog) {
    return {
      show
    };

    function show() {
      $mdDialog.show({
        template: `
          <md-dialog class="dialog-report">
            <common-report-dialog></common-report-dialog>
          </md-dialog>
        `,
        fullscreen: true
      });
    }
  }
})();
