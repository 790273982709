(function () {
  "use strict";

  angular
    .module('app.vehicles')
    .factory('Refresher', RefresherFactory);

  RefresherFactory.$inject = ['$log', '$interval'];

  function RefresherFactory($log, $interval) {

    var Refresher = function () {
      angular.extend(this, {
        immediate: true,
        onStart: undefined,
        onInterval: undefined,
        onStop: undefined,
        promise: undefined,
        interval: 5
      });
    };

    Refresher.prototype.init = function (customConfig) {
      if (angular.isObject(customConfig)) {
        angular.merge(this, customConfig);
      }

      $log.debug('Init refresher', this);

      return this;
    };

    Refresher.prototype.start = function () {
      if (angular.isUndefined(this.onInterval) || !angular.isFunction(this.onInterval)) {
        throw new Error('It seems Refresher has not been initialized properly!');
      }

      $log.debug('Start refresher');
      this.stop(false);

      if (angular.isDefined(this.onStart) && angular.isFunction(this.onStart)) {
        this.onStart.apply();
      }

      if (this.immediate) {
        this.onInterval.apply();
      }

      this.promise = $interval(this.onInterval, this.interval * 1000);

      return this;
    };

    Refresher.prototype.stop = function (runCallback) {
      runCallback = runCallback || true;

      if (angular.isDefined(this.promise)) {
        $log.debug('Stop refresher');
        $interval.cancel(this.promise);

        if (runCallback && angular.isDefined(this.onStop) && angular.isFunction(this.onStop)) {
          this.onStop.apply();
        }
      }

      return this;
    };

    return {
      create: function () {
        return new Refresher();
      }
    };

  }
})();
