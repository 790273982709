(() => {
  angular
    .module('app.tokens')
    .service('app.tokens.utils', utils)

  utils.$inject = [
    '$timeout'
  ]

  function utils($timeout) {
    return {
      syncChangesWithAngular
    }

    function syncChangesWithAngular(fn = () => {}) {
      $timeout(fn)
    }
  }
})();
