(function () {
  "use strict";

  angular
    .module('app.vehicles')
    .component('vehiclesAll', {
      bindings: {
        vehicles: '<'
      },
      templateUrl: 'app/vehicles/all/vehicles.all.html',
      controller: VehiclesAllCtrl,
      controllerAs: 'vm'
    });

  VehiclesAllCtrl.$inject = [
    '$scope',
    '$state',
    '$timeout',
    '$filter',
    '$localStorage',
    'BackendApi',
    'MapOsmService',
    'Refresher',
    'APP_EVENTS',
    'FuelService',
    'app.tokens.accessByToken'
  ];

  function VehiclesAllCtrl($scope, $state, $timeout, $filter, $localStorage,
    BackendApi, MapOsmService, Refresher, APP_EVENTS, FuelService, accessByToken) {
    var self = this,
      refresher = new Refresher.create(),
      limitStep = 50
    ;

    this.limitTo = limitStep;
    $scope.$root.app.title = 'page.view';
    $scope.$on(APP_EVENTS.car.updateCarList, function () {
      refresher.start();
    });
    // $log.info('VehiclesAllCtrl');
    // $log.log('- vehicles', self.vehicles);

    this.isAccessByToken = accessByToken.isActive

    this.$onInit = function () {
      this.speedCi = $filter('translate')('global.ci.mile');
      this.sort = $localStorage.listSort || {};
      refresher
        .init({
          interval: 20,
          onStart: function () {
            MapOsmService.clear();
          },
          onInterval: function () {
            BackendApi.getCarNow(0)
              .then(function (data) {
                data = data
                  .map((obj) => {
                    obj.parkingTime = obj.parkingTime.replace(/(\d+)x/g, '$1д')
                    return obj;
                  })
                self.vehicles = self.onOrderBy(data);
                MapOsmService.drawMarkersSet(self.vehicles, null, false, true);
                FuelService.setFuels(data);
              });
          },
          onStop: function () {
          }
        })
        .start();
    };

    this.getFuel = function (vehicle) {
      if ($localStorage.combineFuel && $localStorage.combineFuel[vehicle.id]) {
        return vehicle.combinedFuel;
      }
      return vehicle.fuel;
    };

    this.onSelectCar = function (car) {
      $state.go('vehicles.details.now', {id: car.id});
      $scope.$emit(APP_EVENTS.ui.openMap);
    };

    this.onSort = function (field) {
      if (this.sort.key == field) {
        this.sort.byAZ = !this.sort.byAZ;
      }
      this.sort.key = field;
      $localStorage.listSort = this.sort;
      self.vehicles = self.onOrderBy(self.vehicles);
    };

    this.onLazyLoad = function (options) {
      options = options || {};
      if (options.type == 'top') {
        this.limitTo = limitStep;
      } else if (options.type == 'next') {
        this.limitTo += limitStep;
        this.showLazyLoad = true;
        $timeout(function () { self.showLazyLoad = false; }, 1000);
      }
    };

    this.onOrderBy = function (list) {
      list = list || [];
      return $filter('orderBy')(list, this.sort.key || 'id', !this.sort.byAZ);
    };

    // reset Refresher on route change
    this.$onDestroy = function () {
      refresher.stop();
    };

  }
})();
