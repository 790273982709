(function () {
  "use strict";

  angular
    .module('app')
    .factory('Utils', Utils)
  ;

  Utils.$inject = [];

  function Utils() {
    var service = {};

    var colors = [
      '#cc0000',
      '#FD7C6E',
      '#ffba00',
      '#a5d610',
      '#00bfff',
      '#000080',
      '#990066',
      '#704214',
      '#00ffff',
      '#4F86F7',
      '#FFD3F8',
      '#C95A49',
      '#DA2647',
      '#9B7653',
      '#44D7A8',
      '#A6E7FF',
      '#6F2DA8',
      '#DA614E',
      '#253529',
      '#FFFF38',
      '#1A1110',
      '#DB91EF',
      '#B2F302',
      '#FFE4CD',
      '#214FC6',
      '#FF8866',
      '#FFD0B9',
      '#45A27D',
      '#FF5050',
      '#FFCFF1',
      '#738276',
      '#CEC8EF',
      '#FC5A8D',
      '#FF878D',
    ];

    var testsAccounts = ['zahidtest'];

    service.isTestAccount = function (loginName) {
      var isTest = false;
      for (var i = 0; i < testsAccounts.length; i++) {
        if (testsAccounts[i] === loginName) {
          isTest = true;
          break;
        }
      }

      return isTest;
    };

    service.getColors = function () {
      return colors;
    }

    service.detectmob = function (date) {
      return window.innerWidth <= 800 || window.innerHeight <= 600;
    };

    service.getRandomeColor = function () {
      var letters = '0123456789ABCDEF';
      var color = '#';
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    };

    service.isDraining = function (idle, engineOperationDuration, actualFuelUsage) {
      const expectedFuelUsageDuringStop = (idle / 3600) * engineOperationDuration;
      return actualFuelUsage > expectedFuelUsageDuringStop;
    }

    service.cutMileageData = function (result, filter) {
      // remove day that out of the range
      if (result.list.length) {
        var lastDay = result.list.pop();
        if ((new Date(lastDay.timestamp * 1000)) > filter.to) {
          _.each(['night', 'day', 'evening', 'total', 'fuel'], function (prop) {
            result.total[prop] = (Number(result.total[prop]) - Number(lastDay[prop])).toFixed(1);
          });
        } else {
          result.list.push(lastDay)
        }
      }

      return result
    }

    return service;
  }

})();
