(function () {
  "use strict";

  angular
    .module('app.logistics')
    .component('logisticsViewRoute', {
      bindings: {
        route: '<',
        cars: '<'
      },
      templateUrl: 'app/logistics/view-route/logistics.view-route.html',
      controller: LogisticsViewRouteCtrl,
      controllerAs: 'vm'
    });

  LogisticsViewRouteCtrl.$inject = [
    '$scope',
    'Session',
    '$q',
    '$mdDialog',
    '$state',
    'BackendApi',
    'MapOsmService',
    'PeriodFilters',
    'CarService',
    'ResponseParser',
    'app.tokens.accessByToken'
  ];

  function LogisticsViewRouteCtrl($scope, Session, $q, $mdDialog,
    $state, BackendApi, MapOsmService, PeriodFilters, CarService, ResponseParser, accessByToken) {
    const isAccessByToken = accessByToken.isActive

    var vm = this;
    var DEFAULT_PARKING_TIME = 10;

    vm.$onInit = function () {
      vm.isInPast = true;
      vm.routeColor = '#28a745';
      vm.notVisitedPointColor = '#F44336';
      vm.segmentColor = '#6534ff';
      vm.titleFormat = '{time}, {speed}км/ч, {stopTime}{voltage}B';
      vm.showLoader = true;
      vm.stops = [];
      vm.onWayRangeIndex = null;
      vm.control = null;
      vm.segmentControl = null;
      vm.segmentIndex = null;
      vm.missedPoints = [];
      vm.mapCenteredPoint = null;
      vm.mapCenteredStop = null;
      vm.startTime = moment.unix(vm.route.dateTime);
      vm.formattedStartTime = vm.startTime.format('DD.MM.YYYY HH:mm');
      // forwarderRoute.points
      vm.points = JSON.parse(vm.route.data);
      vm.forwarderRoutePoints = JSON.parse(vm.route.data);
      vm.car = _.find(vm.cars, function (car) {
        return vm.route.carId === +car.id;
      });
      vm.routeComplexity = Number(vm.route.routeComplexity);

      MapOsmService.clear();

      var onTripCreated = function (tripResult, markers) {
        var timeCorrectionInCity = vm.route.timeCorrectionInCity || 100;
        var timeCorrectionOutsideCity = vm.route.timeCorrectionOutsideCity || 100;
        var foundRouteDetails = tripResult.routes[0];
        var foundRouteSummary = foundRouteDetails.summary;
        var totalMinutes = foundRouteSummary.formattedTotalTime / 60;
        var correctionTime = totalMinutes > 30 ? timeCorrectionOutsideCity : timeCorrectionInCity;  // use city correction if total time less 30m
        var totalTime = moment.utc(foundRouteSummary.formattedTotalTime * 1000 * correctionTime / 100);
        var waypointsInfo = foundRouteDetails.waypointsInfo;
        var totalParkingTime = 0;
        _.each(vm.points, function (point, pointIndex) {
          // if not first and last point
          if (pointIndex !== 0 && pointIndex !== (vm.points.length - 1)) {
            totalParkingTime += _.isNumber(point.parkingTime) ? point.parkingTime : DEFAULT_PARKING_TIME;
          }
        });

        totalTime.add(totalParkingTime, 'minutes');
        vm.totalDistance = Math.round(foundRouteSummary.totalDistance / 1000);
        vm.endTime = vm.startTime
          .clone()
          .add(totalTime.date() - 1, 'days')
          .add(totalTime.hours(), 'hours')
          .add(totalTime.minutes(), 'minutes');
        vm.formattedEndTime = vm.endTime.format('DD.MM.YYYY HH:mm');
        vm.formattedTotalTime = moment.duration(+totalTime).format("h:mm");
        vm.formattedTotalDistance = Math.round(foundRouteSummary.totalDistance / 1000) + ' км.';

        var arrivalTime, departureTime;
        _.each(vm.points, function (point, index) {
          if (index === 0) {
            arrivalTime = vm.startTime.clone();
            departureTime = arrivalTime.clone();
          } else {
            var duration = moment.utc(waypointsInfo[index - 1].duration * 1000 * correctionTime / 100);
            var parkingTime = point.parkingTime || DEFAULT_PARKING_TIME;
            arrivalTime = departureTime.clone()
              .add(duration.hours(), 'hours')
              .add(duration.minutes(), 'minutes')
              .add(duration.seconds(), 'seconds');
            departureTime = arrivalTime.clone().add(parkingTime, 'minutes');
            point.formattedDurationTime = moment.duration(+duration).format('hh:mm', {trim:false});
            point.formattedParkingTime = moment.duration(parkingTime, 'minutes').format("h:mm", {trim: false});
          }
          point.arrivalTime = arrivalTime;
          point.formattedArrivalTime = arrivalTime.format('HH:mm');
          point.departureTime = departureTime;
          point.formattedDepartureTime = departureTime.format('HH:mm');
          if (markers[index]) {
            point.marker = markers[index];
          }
        });

        vm.isInPast = vm.startTime < moment.now();

        vm.showLoader = false;
        loadStops();
        $scope.$digest();
      };

      // ============
      // TO BE REPLACED
      // ============
      vm.control = MapOsmService.addRoute({
        serviceType: 'route',
        points: vm.points,
        color: vm.routeColor,
        shouldDrawMarkers: true,
        onTripCreated: onTripCreated
      });
      // ============
      // /TO BE REPLACED
      // ============
    };

    vm.$onDestroy = function () {
      if (vm.control) {
        MapOsmService.removeControl(vm.control);
      }
      if (vm.segmentControl) {
        MapOsmService.removeControl(vm.segmentControl);
      }
      MapOsmService.clear();
    };

    vm.acceptRoute = function () {
      vm.showLoader = true;
      vm.route.isAccepted = 1;
      BackendApi.saveRoute(vm.route).then(function (res) {
        vm.showLoader = false;
      });
    };

    vm.openCar = function () {
      $state.go('vehicles.details.now', {id: vm.car.id});
    };

    vm.viewPoint = function (point) {
      vm.mapCenteredStop = null;
      if (point === vm.mapCenteredPoint) {
        vm.centerMapOnPoints();
      } else {
        vm.mapCenteredPoint = point;
        MapOsmService.centerMapOnMarker(point.lat, point.lng);
      }
    };

    vm.viewStop = function (stop) {
      vm.mapCenteredPoint = null;
      if (stop === vm.mapCenteredStop) {
        vm.centerMapOnPoints();
        vm.mapCenteredStop = null;
      } else {
        vm.mapCenteredStop = stop;
        MapOsmService.centerMapOnMarker(stop.latitude, stop.longitude);
      }
    };

    vm.centerMapOnPoints = function () {
      vm.mapCenteredPoint = null;
      MapOsmService.centerMapByPoints(vm.points);
    };

    vm.showPrintDialog = function () {
      $mdDialog.show({
        controllerAs: 'vm',
        controller: RoutePrintDialogCtrl,
        templateUrl: 'app/logistics/view-route/route-print-dialog.html',
        fullscreen: true,
        locals: {
          resolveData: {
            points: vm.points,
            formattedStartTime: vm.formattedStartTime,
            formattedEndTime: vm.formattedEndTime,
            car: vm.car,
            totalDistance: vm.totalDistance,
            formattedTotalTime: vm.formattedTotalTime,
            routeComplexity: vm.routeComplexity,
          }
        }
      });
    };
    vm.editRoute = function () {
      $state.go('logistics.editRoute', {id: vm.route.id});
    };

    vm.showOnWayRange = function (stopIndex) {
      var stop = vm.stops[stopIndex];
      var point = stop.point;

      if (!point) {
        var nextStop = _.find(vm.stops, function (item, index) {
          return index > stopIndex && item.point;
        });

        if (nextStop) {
          point = nextStop.point;
        }
      }

      var segmentIndex = point ? point.routeIndex : null;

      if (vm.onWayRangeIndex === stopIndex) {
        vm.onWayRangeIndex = null;
        MapOsmService.clear();
        MapOsmService.drawMarkersSet(vm.history.points, vm.titleFormat, true);
        vm.highlightSegment(segmentIndex, true);
      } else {
        vm.showLoader = true;
        vm.onWayRangeIndex = stopIndex;
        BackendApi.getHistory(vm.route.carId, stop.onWayRange)
          .then(function (result) {
            MapOsmService.clear();
            if (result.points && result.points.length) {
              MapOsmService.drawMarkersSet(result.points, vm.titleFormat, true);
            }

            if (vm.segmentIndex === segmentIndex) {
              vm.segmentIndex = null;
            }

            vm.highlightSegment(segmentIndex, true);
            vm.showLoader = false;
          });
      }
    };

    vm.highlightSegment = function (pointIndex, hasOnWayRange) {
      if (vm.segmentControl) {
        MapOsmService.removeControl(vm.segmentControl);
        vm.segmentControl = null;
      }
      if (pointIndex) {
        if (vm.segmentIndex === pointIndex) {
          vm.segmentIndex = null;
          if (!hasOnWayRange) {
            vm.centerMapOnPoints();
          }
        } else {
          var firstPoint = vm.points[pointIndex - 1];
          var secondPoint = vm.points[pointIndex];
          var points = [firstPoint, secondPoint];

          vm.segmentIndex = pointIndex;
          vm.segmentControl = MapOsmService.addRoute({
            serviceType: 'route',
            points: points,
            color: vm.segmentColor,
            shouldDrawMarkers: false,
            onTripCreated: function () {
              if (!hasOnWayRange) {
                MapOsmService.centerMapByPoints(points);
                $scope.$digest();
              }
            }
          });
        }
      } else {
        vm.segmentIndex = null;
      }

      if (!hasOnWayRange && _.isNumber(vm.onWayRangeIndex) && vm.history) {
        vm.onWayRangeIndex = null;
        MapOsmService.clear();
        MapOsmService.drawMarkersSet(vm.history.points, vm.titleFormat, true);
      }
    }

    function loadStops() {
      if (vm.isInPast) {
        var promises = [];

        vm.filter = PeriodFilters.getFilter('stops');
        vm.filter.from = vm.startTime.startOf('day').toDate();
        vm.filter.to = vm.endTime.endOf("day").startOf("second").toDate();
        vm.showLoader = true;

        promises.push(BackendApi.getStops(vm.route.carId, vm.filter));
        promises.push(BackendApi.getHistory(vm.route.carId, vm.filter));
        $q.all(promises).then(function (res) {
          var stopsRes = res[0];

          vm.history = res[1];

          if (vm.history.points && vm.history.points.length) {
            vm.history.points = CarService.detectParking(vm.history.points, stopsRes.stops);
            MapOsmService.drawMarkersSet(vm.history.points, vm.titleFormat, true);

            var historyInfo = ResponseParser.parseSingleDeviceRoute(stopsRes, false, Session.addressType);
            vm.stops = historyInfo.stops;
            vm.formattedRealDistance = (historyInfo.summary.distanceKm || 0) + ' км.';

            _.each(vm.points, function (forwarderRoutePoint, pointIndex) {
              forwarderRoutePoint.routeIndex = pointIndex;
              if (pointIndex === 0) {
                forwarderRoutePoint.type = 'startPoint';
              } else if (pointIndex === vm.points.length - 1) {
                forwarderRoutePoint.type = 'endPoint';
              }

              if (forwarderRoutePoint.zoneCoordinates) {
                var zoneBounds = L.polygon(forwarderRoutePoint.zoneCoordinates).getBounds();
                var stopInsideForwarderRouteZone = _.find(vm.stops, function (item) {
                  if (item.latitude && item.longitude) {
                    return zoneBounds.contains(L.latLng(item.latitude, item.longitude));
                  }
                  return false;
                });

                if (stopInsideForwarderRouteZone) {
                  var address = stopInsideForwarderRouteZone[Session.addressType];
                  var pointInfo = stopInsideForwarderRouteZone.info.replace(address, forwarderRoutePoint.name);

                  if (stopInsideForwarderRouteZone.point) {
                    var stopIndex = vm.stops.indexOf(stopInsideForwarderRouteZone);
                    var copiedStop = _.clone(stopInsideForwarderRouteZone);

                    copiedStop.point = forwarderRoutePoint;
                    copiedStop.info = pointInfo;

                    vm.stops.splice(stopIndex + 1, 0, copiedStop);
                  } else {
                    stopInsideForwarderRouteZone.point = forwarderRoutePoint;
                    stopInsideForwarderRouteZone.info = pointInfo;
                  }

                  var stopTime = moment.unix(stopInsideForwarderRouteZone.timestampFrom);
                  if (stopTime > forwarderRoutePoint.arrivalTime) {
                    forwarderRoutePoint.isOutdrive = false;
                    forwarderRoutePoint.deviationTime = '-' + moment(stopTime - forwarderRoutePoint.arrivalTime).format('HH:mm');
                  } else {
                    forwarderRoutePoint.isOutdrive = true;
                    forwarderRoutePoint.deviationTime = '+' + moment(forwarderRoutePoint.arrivalTime - stopTime).format('HH:mm');
                  }
                } else {
                  vm.missedPoints.push(forwarderRoutePoint);
                  forwarderRoutePoint.isOutdrive = false;
                  vm.updateMissedPointIcon(forwarderRoutePoint);
                }
              } else {
                vm.missedPoints.push(forwarderRoutePoint);
                forwarderRoutePoint.isOutdrive = false;
                vm.updateMissedPointIcon(forwarderRoutePoint);
              }
            });
          }
          vm.showLoader = false;
        });
      }
    }

    vm.updateMissedPointIcon = function (point) {
      if (point.marker) {
        var text = null;

        switch (point.type) {
          case 'startPoint':
            text = 'S';
            break;
          case 'endPoint':
            text = 'F';
            break;
          default:
            text = point.routeIndex || '';
        }

        var icon = MapOsmService.getColoredIcon(vm.notVisitedPointColor, text);
        point.marker.setIcon(icon);
      }
    };
  }

  RoutePrintDialogCtrl.$inject = ['$element', '$filter', '$mdDialog', 'resolveData'];

  function RoutePrintDialogCtrl($element, $filter, $mdDialog, resolveData) {
    var self = this, title;

    init();

    this.onCancel = function () {
      $mdDialog.cancel();
    };

    this.onPrint = function () {
      setTimeout(function () {
        var printWindow = window.open('', title);
        printWindow.document.write($element[0].querySelector('.route-print-tpl').innerHTML);
        printWindow.document.title = [title, self.print.from, self.print.to].join(' - ');
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
      }, 1000);
    };

    this.sortList = function () {
      self.descendSorting = !self.descendSorting;
    };

    function init() {
      title = $filter('translate')('logistics.view-route.print.title');

      self.descendSorting = false;
      self.points = _.map(resolveData.points, function (item, index) {
        item.number = index;
        return item;
      });
      self.formattedStartTime = resolveData.formattedStartTime;
      self.formattedEndTime = resolveData.formattedEndTime;
      self.car = resolveData.car;
      self.totalDistance = resolveData.totalDistance;
      self.formattedTotalTime = resolveData.formattedTotalTime;
      self.hasRequestNumber = _.some(resolveData.points, 'requestNumber');
      self.hasQuantity = _.some(resolveData.points, 'quantity');
      self.hasWeight = _.some(resolveData.points, 'weight');
      self.hasNote = _.some(resolveData.points, 'note');
      self.hasSignature = _.some(resolveData.points, 'signature');
      self.hasPrice = _.some(resolveData.points, 'price');
      self.hasBarcode = _.some(resolveData.points, 'barcode');
      self.totalPrice = resolveData.points.reduce(function (partialSum, item) {
        return partialSum + Number(
          (item.price || '')
          .replace(',', '.')
          .replace(' ', '')
          .replace(' ', '')
          );
      }, 0).toFixed(2);
      self.totalWeight = resolveData.points.reduce(function (partialSum, item) {
        return partialSum + Number(
          (item.weight || '')
          .replace(',', '.')
          .replace(' ', '')
          .replace(' ', '')
          );
      }, 0).toFixed(2);
      self.routeComplexity = resolveData.routeComplexity;
    }
  }
})();
