(() => {
  angular
    .module('app.vehicles')
    .component('dayDetailsHeader', {
      controller: Ctrl,
      controllerAs: 'ctrl',
      templateUrl: 'app/vehicles/details/lib/with-fuel-report-view/fuel-report-view/day-details-header/tpl.html',
      bindings: {
        routeDetails: '<'
      }
    });

  Ctrl.$inject = [
    '$rootScope',
    'routeItemUtils',
    'fuel-consumption-metering-settings',
    'fuel-consumption-metering-settings.events',
    'app.vehicles.details.lib.fuel-calculations.fuel-calculations'
  ];

  function Ctrl($rootScope, routeItemUtils,
    fuelConsumptionMeteringSettings, fuelConsumptionMeteringSettingsEvents, fuelCalculations) {
    const self = this;
    const unsubscribe = [];

    this.$onInit = () => {
      unsubscribe.push(
        $rootScope.$on(fuelConsumptionMeteringSettingsEvents.CHANGE, onFuelConsumptionMeteringSettingsChanged)
      );
    };

    this.$onDestroy = () => {
      while (unsubscribe.length) {
        unsubscribe.pop()();
      }
    };

    this.$onChanges = () => {
      updateState();
    };

    function onFuelConsumptionMeteringSettingsChanged() {
      updateState();
    }

    function updateState() {
      const timePeriodDetails = fuelCalculations.getDetailsForTimePeriod(self.routeDetails)

      self.initialFuelLevel = timePeriodDetails.initialFuelAmountInAllTanks;
      self.finalFuelLevel = timePeriodDetails.finalFuelAmountInAllTanks;
      self.totalRefueling = timePeriodDetails.totalRefuelingInAllTanks;
      self.totalFuelDrain = timePeriodDetails.totalFuelDrainInAllTanks;
      self.totalFuelUsage = Math.round(timePeriodDetails.totalFuelUsageInAllTanks * 100) / 100;
      self.totalDistance = Math.round(timePeriodDetails.mileage * 100) / 100;
      self.averageFuelUsage = Math.round(timePeriodDetails.averageFuelUsage * 100) / 100;
    }
  }
})();
