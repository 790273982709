(() => {
  const defaultIconColor = '#808080';

  const iconIdToIconFileNameMap = {
    '1': 'hexagon',
    '2': 'circle',
    '3': 'triangle'
  };

  const getCustomIconUrl = (iconFileName) => `/assets/img/vehicle-settings/${iconFileName}.svg#Layer_1`;

  angular
    .module('app')
    .constant('customVehicleIconParameters', {
      iconIdToIconFileNameMap,
      getCustomIconUrl,
      getCustomIconUrlById: (id) => {
        const iconFileName = iconIdToIconFileNameMap[`${id}`];
        if (typeof iconFileName === 'undefined') {
          return '';
        }
        return getCustomIconUrl(iconFileName);
      },
      getStrokeWidth: (id) => {
        id = `${id}`;

        switch (id) {
          case '1':
            return 10;
          case '2':
            return 25;
          case '3':
            return 1;
          default:
            return 10;
        }
      },
      iconColors: [
        defaultIconColor,
        '#F44336',
        '#4CAF50',
        '#03A9F4',
        '#9C27B0'
      ],
      defaultIconColor
    });
})();
