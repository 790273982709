(function () {
  "use strict";

  angular
    .module('app.vehicles')
    .component('vehiclesSettings', {
      bindings: {
        settings: '<',
        carSubscriberPlans: '<'
      },
      templateUrl: 'app/vehicles/details/settings/vehicles.settings.html',
      controller: VehiclesSettingsCtrl,
      controllerAs: 'vm'
    });

  VehiclesSettingsCtrl.$inject = [
    '$scope',
    '$timeout',
    '$filter',
    '$mdToast',
    '$localStorage',
    'BackendApi',
    'APP_EVENTS',
    'MapOsmService',
    'Refresher',
    'PermissionService',
    'FuelService' ,
    'SettingsService',
    'Session'
  ];

  function VehiclesSettingsCtrl($scope, $timeout, $filter, $mdToast, $localStorage,
    BackendApi, APP_EVENTS, MapOsmService, Refresher, PermissionService, FuelService,
    SettingsService, Session) {

    this.userId = Session.user.loginName;

    var self = this,
      car = {id: $scope.$parent.$ctrl.carId},
      refresher = new Refresher.create(),
      outputsTimerId,
      ACTION_CHANGE = 'action.change'
    ;
    $scope.$root.app.title = 'page.settings';
    this.car = car;

    this.$onInit = function () {
      this.hasSecondFuelTank = _.has(this.settings.fuel, 'capacity2');
      this.isCombinedFuelTanks =
        this.hasSecondFuelTank &&
        !!($localStorage.combineFuel && $localStorage.combineFuel[car.id]);
      this.settings.subscriberPlan = this.settings.subscriberPlan || null;
      this.settings.startZoneId = this.settings.startZoneId || null;
      this.settings.endZoneId = this.settings.endZoneId || null;
      this.settings.salesManWorkingTime = this.settings.salesManWorkingTime || [1, 2, 3, 4, 5, 6, 0].map(function (i) {
        return {
          index: i,
          isActive: false,
          intervals: [{from: undefined, to: undefined}]
        };
      });
      this.isOutput = !!(this.settings.outputs & 1);
      this.isRemoteControl = this.settings.outputs & 0x10;
      this.showTimerWakeupSettings = this.settings.timerWakeup !== '';
      this.turnOnGPS = !!(this.settings.timerWakeup & 0x8000);
      this.connectToServer = !!(this.settings.timerWakeup & 0x4000);
      this.receiveSMS = !!(this.settings.timerWakeup & 0x2000);
      this.timeout = this.settings.timerWakeup & 0xF;
      this.showBaterySavingSettings = this.settings.baterySaving !== '';

      this.outputTitle = ACTION_CHANGE;
      this.zones = angular.copy(Session.user.zones);
      this.tmp = {
        types: [
          {
            id: '1',
            name: 'car.settings.car'
          },{
            id: '2',
            name: 'car.settings.specialCar'
          },{
            id: '3',
            name: 'car.settings.stationaryCar'
          }
        ],
        carTypes: [
          {
            id: '1',
            name: 'car.settings.passengerCar'
          }, {
            id: '2',
            name: 'car.settings.cargoCar'
          }
        ]
      };
      $scope.$emit(APP_EVENTS.ui.tabSelected, 'settings');
      this.perm = PermissionService.get();

      MapOsmService.clear();
      MapOsmService.onEnteredIntoNowMode();

      refresher
        .init({
          onStart: function () {
            MapOsmService.clear();
          },
          onInterval: function () {
            BackendApi.getCarNow(self.car.id)
              .then(function (data) {
                self.vehicles = data;
                MapOsmService.appendMarker(self.vehicles[0]);
                FuelService.setFuel(data[0]);
              });
          }
        })
        .start();

      FuelService.getFuel(car.id)
        .then(function (result) {
          self.isShowFuel = result;
        });
    };

    this.$onDestroy = function () {
      refresher.stop();
      MapOsmService.onExitedFromNowMode();
    };

    this.onSaveOutput = function () {
      countDownOutputsTimer(0);
      BackendApi.setOutputState(_.extend({state: +!!this.isOutput}, car))
        .then(function () {
          showToastSuccess();
        })
      ;
    };

    this.enableDeviceOutput = function () {
      this.isEnableOutput = true;
      countDownOutputsTimer(4);
    };

    this.onSaveFuel = function () {
      var fuel = this.settings.fuel;
      if (fuel.summer) {
        fuel.summer.city = fuel.summer.city || 0;
        fuel.summer.road = fuel.summer.road || 0;
      }
      if (fuel.winter) {
        fuel.winter.city = fuel.winter.city || 0;
        fuel.winter.road = fuel.winter.road || 0;
      }
      BackendApi.setCarFuel(_.extend({fuel: JSON.stringify(this.settings.fuel)}, car))
        .then(function () {
          showToastSuccess();
        })
      ;
    };

    this.onSaveSubscriberPlan = function () {
      BackendApi.setCarSubscriberPlan(_.extend({carSubscriberPlan: this.settings.subscriberPlan}, car))
        .then(function () {
          showToastSuccess();
        });
    };

    this.onSaveSalesManWorkingTime = function () {
      BackendApi.setSalesManWorkingTime(_.extend({salesManWorkingTime: this.settings.salesManWorkingTime}, car))
        .then(function () {
          showToastSuccess();
        });
    };

    this.onSaveZones = function () {
      BackendApi.setCarZones(_.extend({startZoneId: this.settings.startZoneId, endZoneId: this.settings.endZoneId}, car))
        .then(function () {
          showToastSuccess();
        });
    };

    this.onSaveCargoCapacity = function () {
      BackendApi.setCargoCapacity(_.extend({
        carryingCapacity: this.settings.cargoCapacity.carryingCapacity,
        amount: this.settings.cargoCapacity.amount
      }, car))
        .then(function () {
          showToastSuccess();
        });
    };

    this.onSaveCarImpulse = function () {
      BackendApi.setCarImpulse(_.extend({
        impulse: this.settings.impulse
      }, car))
        .then(function () {
          showToastSuccess();
        });
    };

    this.onSaveSpeed = function () {
      BackendApi.setCarSpeed(_.extend({}, this.settings.speed, car))
        .then(function () {
          showToastSuccess();
        })
      ;
    };

    this.onSaveWekupSettings = function () {
      var v = self.timeout;
      if (self.turnOnGPS) v |= 0x8000;
      if (self.connectToServer) v |= 0x4000;
      if (self.receiveSMS) v |= 0x2000;

      BackendApi.saveTimeoutSettings({x: v, id: car.id})
      .then(function () {
        showToastSuccess();
      });
    };

    this.onSaveBaterySettings = function () {
      BackendApi.saveBaterySettings({x: self.settings.baterySaving, id: car.id})
      .then(function () {
        showToastSuccess();
      });
    };

    this.onSaveInfo = function () {
      BackendApi.setCarInfo(_.extend({}, this.settings.info, car))
        .then(function () {
          showToastSuccess();
        })
      ;
    };


    this.onSaveOdometer = function () {
      BackendApi.saveOdometer(car.id, self.settings.odometer)
      .then(function () {
        showToastSuccess();
      });
    };

    this.onResetOdometer = function (ev) {
      SettingsService.showConfirmDialog(ev, {
        content: $filter('translate')('car.settings.reset.confirm')
      })
        .then(function () {
          self.settings.odometer = 0;
          self.onSaveOdometer();
        });
    };

    this.onDeleteDevice = function (ev) {
      SettingsService.showConfirmDialog(ev, {
        content: $filter('translate')('car.settings.deleteDevice.confirm')
      })
        .then(function () {
          BackendApi.removeTracker(_.extend({}, car))
            .then(function () {
              window.location = '/vehicles';
              showToastSuccess('car.settings.deleteDevice.success');
            });
        });
    };

    this.onMarkerFilter = function () {
      BackendApi.setMarkerFilter(_.extend({filterTracker: +this.isMarkerFilter}, car))
        .then(function () {
          showToastSuccess();
        })
      ;
    };

    this.onGpsControlSettingsChanged = () => {
      BackendApi
        .updateGpsControlSettings({
          vehicleId: self.car.id,
          isActive: !!self.settings.isGpsControlActive
        })
        .then(() => {
          showToastSuccess()
        })
    }

    this.onV2FuelChanged = () => {
      BackendApi
        .updateV2FuelSettings({
          vehicleId: self.car.id,
          isActive: !!self.settings.isV2FuelActive
        })
        .then(() => {
          showToastSuccess()
        })
    }

    this.onCombineFuelTanks = function () {
      if (!$localStorage.combineFuel) {
        $localStorage.combineFuel = {};
      }
      if ($localStorage.combineFuel[car.id]) {
        delete $localStorage.combineFuel[car.id];
      } else {
        $localStorage.combineFuel[car.id] = true;
      }
      self.isCombinedFuelTanks = !!$localStorage.combineFuel[car.id];
    };

    this.onShowUssdHistory = function () {
      if (this.isUssd) {
        BackendApi.getCarUssdHistory(car)
          .then(function (resp) {
            self.ussdHistoty = resp;
          })
        ;
      } else {
        self.ussdHistoty = null;
      }
    };

    this.onSendUssd = function () {
      this.showUssdLoader = true;
      refresher
        .init({
          onStart: function () {
            BackendApi.sendCarUssd(_.extend({}, self.tmp.ussd, car));
          },
          onInterval: function () {
            BackendApi.sendCarUssd(_.extend({}, car))
              .then(function (result) {
                if (result) {
                  refresher.stop();
                  self.showUssdLoader = false;
                  self.ussdHistoty = result;
                  self.isUssd = false;
                }
              });
          }
        })
        .start();
    };

    this.sendAux = function (code) {
      self.showUssdLoader = true;
      BackendApi.sendAux({
        id: car.id,
        code: code
      }).then(function (resp) {
        self.showUssdLoader = false;
      });
    }

    function showToastSuccess(trans) {
      var toast = $mdToast.simple().content($filter('translate')(trans || 'global.savedSuccess')).position('bottom left');
      $mdToast.show(toast);
    }

    function countDownOutputsTimer(val) {
      outputsTimerId && $timeout.cancel(outputsTimerId);
      if (!val) {
        self.isEnableOutput = false;
        self.outputTitle = ACTION_CHANGE;
        return;
      }
      self.outputTitle = val + ' сек.';
      val--;
      outputsTimerId = $timeout(function(){ countDownOutputsTimer(val); }, 1000);
    }

  }
})();
