(() => {
  angular
    .module('app.reports')
    .service('app.reports.helpers.pdf', create);

  create.$inject = [
    '$translate',
    'app.reports.helpers.common'
  ];

  function create($translate, common) {
    const tableHeaderStyle = {
      bold: true,
      fillColor: `#${common.tableHeaderBgColor}`,
      fillOpacity: 1
    };

    const tableHeaderForVerticalTableStyle = Object.assign(
      {},
      tableHeaderStyle, {
        alignment: 'center'
      }
    );

    const firstCellInTableFooterStyle = Object.assign(
      {},
      tableHeaderStyle,
      {
        alignment: 'left'
      }
    );

    const otherCellInTableFooterStyle = Object.assign(
      {},
      tableHeaderStyle, {
        alignment: 'right'
      }
    );

    const alignToRightStyle = {
      alignment: 'right'
    };

    return {
      pageMargins: [10, 40, 10, 22],
      defaultStyles: {
        mainTable: {
          margin: [0, 20, 0, 0],
          fontSize: 10
        },
        tableHeader: tableHeaderStyle,
        tableHeaderForVerticalTable: tableHeaderForVerticalTableStyle,
        firstCellInTableFooter: firstCellInTableFooterStyle,
        otherCellInTableFooter: otherCellInTableFooterStyle,
        alignToRight: alignToRightStyle,
        isWeekend: {
          fillColor: '#eeeeee'
        }
      },
      createPageTitle,
      createPageFooter,
      createHorizontalTable,
      createVerticalTable
    };

    function createPageTitle(text) {
      return {
        text,
        margin: [10, 10, 0, 0],
        bold: true
      };
    }

    function createPageFooter(currentPage) {
      return {
        text: `${$translate.instant('reports.page')} ${currentPage}`,
        alignment: 'right',
        fontSize: 8,
        margin: [0, 5, 10, 15]
      };
    }

    function createHorizontalTable(rowsData) {
      const columnsCount = rowsData.reduce((acc, rowData) => Math.max(rowData.length, acc), 0);

      rowsData.forEach((rowData) => {
        while (rowData.length < columnsCount) {
          rowData.push('');
        }

        rowData[0] = {
          text: rowData[0],
          style: 'tableHeader'
        };
      });

      return {
        table: {
          body: rowsData
        }
      };
    }

    function createVerticalTable(rowsData, columnWidths) {
      rowsData[0] = rowsData[0].map((cellValue) => {
        return {
          text: cellValue,
          style: 'tableHeaderForVerticalTable'
        };
      });

      rowsData
        .slice(1, -1)
        .forEach((rowData, index) => {
          rowsData[index + 1] = rowData.map((cellValue, index) => {
            return {
              text: cellValue,
              style: index < rowData.length - 1 ? 'alignToRight' : ''
            };
          });
        });

      rowsData[rowsData.length - 1] = rowsData[rowsData.length - 1].map((cellValue, index) => {
        return {
          text: cellValue,
          style: index === 0 ? 'firstCellInTableFooter' : 'otherCellInTableFooter'
        };
      });

      return {
        style: 'mainTable',
        table: {
          headerRows: 1,
          body: rowsData,
          widths: columnWidths
        }
      };
    }
  }
})();
