(function () {
  "use strict";

  angular
    .module('app')
    .constant('APP_EVENTS', {
      car: {
        carSelected: 'car-selected',
        updateCarList: 'update-car-list',
        fuelChart: 'fuel-chart',
        fuelBtn: 'fuel-btn',
        carInfoChart: 'car-info-chart'
      },
      ui: {
        tabSelected: 'tab-selected',
        openMap: 'openMap'
      }
    });
})();
