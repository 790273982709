(function () {
  "use strict";

  angular
    .module('app')
    .component('main', {
      templateUrl: 'app/main/main.html',
      controller: MainCtrl,
      controllerAs: 'vm'
    })
  ;

  MainCtrl.$inject = [
    '$rootScope',
    '$cookies',
    '$localStorage',
    '$filter',
    '$state',
    '$translate',
    '$mdMedia',
    'Session',
    'CarService',
    'MapOsmService',
    'ChartService',
    'PermissionService',
    'BackendApi',
    'APP_EVENTS',
    'vehicles.commonReportDialog',
    'MapOsmService/events',
    '$timeout',
    'Utils',
    'dialogControl/events',
    'vehicles.commonReportDialog.events',
    'app.tokens.accessByToken'
  ];

  const withUsualWidthOfMapClassName = 'with-usual-width-of-map'
  const withExtendedWidthOfMapClassName = 'with-extended-width-of-map'

  let previousChangeSizeValue = false
  let resetPreviousChangeSizeValueTimeoutId = NaN;

  const resetPreviousChangeSizeValue = () => {
    if (!isNaN(resetPreviousChangeSizeValueTimeoutId)) {
      return
    }

    resetPreviousChangeSizeValueTimeoutId = setTimeout(() => {
      previousChangeSizeValue = false
      resetPreviousChangeSizeValueTimeoutId = NaN
    }, 500)
  }

  const cancelResettingOfPreviousChangeSizeValue = () => {
    clearTimeout(resetPreviousChangeSizeValueTimeoutId)
    resetPreviousChangeSizeValueTimeoutId = NaN
  }

  function MainCtrl($rootScope, $cookies, $localStorage, $filter, $state,
    $translate, $mdMedia, Session, CarService, MapOsmService, ChartService,
    PermissionService, BackendApi, APP_EVENTS, commonReportDialog, mapEvents, $timeout, Utils,
    dialogControlEvents, commonReportDialogEvents, accessByToken) {
    const unsubscribe = []

    this.changeSize = previousChangeSizeValue

    this.isAccessWithToken = false

    var self = this,
      perm = PermissionService.get()
    ;
    var FEEDBACK_LINK = 'https://drive.google.com/open?id=1SqoPsxzGAeHEUK96ZqIMDE4h7UIwh4a91G0TaAxOSNA';

    BackendApi.getProfile()
      .then(function (result) {
        result.session || (
          // window.location = '/logout'
          $state.go('logout', null, {
            location: 'replace'
          })
        );
      });

    this.$onInit = function () {
      cancelResettingOfPreviousChangeSizeValue()

      unsubscribe.push(
        $rootScope.$on(mapEvents.MAP_CONTROLS_VISIBILITY_CHANGED, onMapControlsVisibilityChanged)
      )

      unsubscribe.push(
        $rootScope.$on(dialogControlEvents.EXIT_FULLSCREEN_MODE_PRESSED, onCommonReportDialogCollapsed)
      )

      unsubscribe.push(
        $rootScope.$on(commonReportDialogEvents.CLOSED, onCommonReportDialogClosed)
      )

      this.login = (Session.user || {}).loginName;
      this.langs = [
        {name: 'UA', code: 'ua'},
        {name: 'EN', code: 'en'},
        {name: 'RU', code: 'ru'}
      ];
      this.langs.name = $localStorage.lang || 'ua';
      this.deviceType = 'desktop';

      if ( (navigator.userAgent.match(/Android/i)
          || navigator.userAgent.match(/webOS/i)
          || navigator.userAgent.match(/iPhone/i)
          || navigator.userAgent.match(/iPad/i)
          || navigator.userAgent.match(/iPod/i)
          || navigator.userAgent.match(/BlackBerry/i)
          || navigator.userAgent.match(/Windows Phone/i)
        ) || $mdMedia('max-width: 720px'))
      {
        this.deviceType = 'mobile';
        this.hideMap = true;
      }

      this.toolbar = initToolbar();
      MapOsmService.init();

      $rootScope.$on(APP_EVENTS.ui.openMap, function () {
        this.hideMap = false;
      }.bind(this));

      self.isAccessWithToken = accessByToken.isActive

      if (accessByToken.isActive) {
        toggleMapSize(true)
      }
    };

    this.$onDestroy = () => {
      resetPreviousChangeSizeValue()
      while(unsubscribe.length) {
        unsubscribe.pop()()
      }
    }

    function onMapControlsVisibilityChanged() {
      const shouldExtendMap = Utils.detectmob() && MapOsmService.areMapControlsVisible()
      if (!shouldExtendMap) {
        return
      }

      $timeout(() => {
        toggleMapSize(true)
      })
    }

    function onCommonReportDialogCollapsed() {
      $timeout(() => {
        toggleMapSize(true)
      })
    }

    function onCommonReportDialogClosed() {
      $timeout(() => {
        toggleMapSize(false)
      })
    }

    function toggleMapSize(shouldShowWideMap = false) {
      self.changeSize = shouldShowWideMap;
      previousChangeSizeValue = shouldShowWideMap

      self.hideMap = false;
      onResize();

      document.body.classList.remove(withUsualWidthOfMapClassName)
      document.body.classList.remove(withExtendedWidthOfMapClassName)

      if (shouldShowWideMap) {
        document.body.classList.add(withExtendedWidthOfMapClassName)
      } else {
        document.body.classList.add(withUsualWidthOfMapClassName)
      }
    }

    this.onSelectLang = function (item) {
      localStorage.setItem('lang', item.code)
      $cookies.put('lang', item.code);
      $localStorage.lang = item.code;
      this.langs.name = item.name;
      $translate.use(item.code);

      $rootScope.$emit('main.langChanged', item.code);
      //location.reload();
    };

    function initToolbar() {
      const shouldShownOnlyMap = !!(accessByToken.tokenData || {}).shouldShownOnlyMap

      var actions = [
          {
            link: 'settings',
            name: $filter('translate')('page.settings'),
            message: 'Action 1',
            icon: 'settings',
            completed: true,
            error: true,
            handler: function () {}
          }, {
            link: 'logout',
            name: $filter('translate')('global.logout'),
            message: 'Action 3',
            icon: 'arrow_back',
            completed: true,
            error: true,
            handler: function () {}
          }
        ],
        toolbar = {
          buttons: [],
          menus: [{
            name: $filter('translate')('page.control'),
            icon: 'menu',
            width: '4',
            actions: actions
          }]
        };

      const toggleMapSizeBtnConfig = {
        name: $filter('translate')('global.toggleSizeTracker'),
        icon: 'settings_ethernet',
        handler: () => {
          toggleMapSize(!self.changeSize)
        }
      }

      const toggleMapBtnConfig = {
        name: $filter('translate')('global.toggleMap'),
        icon: 'map',
        handler: function () {
          self.hideMap = !self.hideMap;
          self.changeSize = false;
          previousChangeSizeValue = false
          self.hideMap || onResize();
        }
      }

      if (accessByToken.isActive) {
        if (!shouldShownOnlyMap) {
          toolbar.buttons.push(toggleMapSizeBtnConfig)
          toolbar.buttons.push(toggleMapBtnConfig)
        }
      } else {
        toolbar.buttons.push(toggleMapSizeBtnConfig)
        toolbar.buttons.push(toggleMapBtnConfig)
      }

      if (perm.schedules && !accessByToken.isActive) {
        actions.unshift({
          link: 'schedule',
          name: $filter('translate')('page.schedules'),
          icon: 'near_me',
          handler: function () {
            CarService.showSchedulesDialog();
          }
        });
      }

       if (perm.logistics && !accessByToken.isActive) {
        var logisticsBtnOld = {
          className: 'btn-action',
          link: 'logistics',
          name: $filter('translate')('page.logistics'),
          icon: 'directions_car',
          handler: function () {
            $state.go('logistics.routes');
          }
        };
        actions.unshift(logisticsBtnOld);
        toolbar.buttons.push(logisticsBtnOld);
      }

      if (perm.cardBalanceSummary && !accessByToken.isActive) {
        var cardBalanceBtn = {
          className: 'btn-action',
          link: 'cardBalance',
          name: $filter('translate')('page.cardBalance'),
          icon: 'attach_money',
          handler: function () {
            CarService.showCardBalanceDialog();
          }
        };
        actions.unshift(cardBalanceBtn);
        toolbar.buttons.push(cardBalanceBtn);
      }

      if (perm.reports && !accessByToken.isActive) {
        var reportBtn = {
          className: 'btn-action',
          link: 'report',
          name: $filter('translate')('page.reports'),
          icon: 'assessment',
          handler: function () {
            commonReportDialog.show()
          }
        };
        actions.unshift(reportBtn);
        toolbar.buttons.push(reportBtn);
      }

      if(!accessByToken.isActive) {
        toolbar.buttons.push({
          className: 'btn-action',
          name: $filter('translate')('global.feedback'),
          icon: 'feedback',
          handler: function () {
            var win = window.open(FEEDBACK_LINK, '_blank');
            win.focus();
          }
        });
      }

      return toolbar;
    }

    function onResize () {
      setTimeout(function () {
        MapOsmService.initMapSize();
        ChartService.resize();
      }, 300);
    }

  }

})();
