(() => {
  angular
    .module('app.reports')
    .service('app.reports.fileSaver', fileSaver);

  function fileSaver() {
    return {
      saveCSV,
      saveXLSX,
      savePDF,
      printPdf
    };

    /**
     * @param {string} fileName
     * @param {string} data
     */
    function saveCSV(fileName, data) {
      const fileData = new Blob([data], {
        type: 'text/csv'
      });

      saveFileUsingAnchor(`${fileName}.csv`, fileData);
    }

    /**
     * @param {string} fileName
     * @param {Object} workbook
     */
    function saveXLSX(fileName, workbook) {
      workbook.xlsx.writeBuffer()
        .then((data) => {
          const blob = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
          });

          const url = URL.createObjectURL(blob);

          const el = document.createElement('a');
          document.body.appendChild(el);
          el.href = url;
          el.target = '_blank';
          el.download = `${fileName}.xlsx`;
          el.click();
          document.body.removeChild(el);

          URL.revokeObjectURL(url);
        });
    }

    /**
     * @param {string} fileName
     * @param {Object} pdf
     */
    function savePDF(fileName, pdf) {
      pdf.download(fileName);
    }

    function printPdf(pdf) {
      const id = 'print-iframe';
      let iframe = document.querySelector(`#${id}`);
      if (iframe === null) {
        iframe = document.createElement('iframe');
        iframe.id = id;
        iframe.style.position = 'fixed';
        iframe.style.left = '-10000px';
        iframe.style.top = '-10000px';
        iframe.style.visibility = 'hidden';
        document.body.appendChild(iframe);
      }
      pdf.print({}, iframe.contentWindow);
    }

    function saveFileUsingAnchor(fileName, fileData) {
      const url = URL.createObjectURL(fileData);

      const el = document.createElement('a');
      document.body.appendChild(el);
      el.href = url;
      el.target = '_blank';
      el.download = fileName;
      el.click();
      document.body.removeChild(el);

      URL.revokeObjectURL(url);
    }
  }
})();
