(() => {
  angular
    .module('app.settings')
    .service('globalNotificationSettings.api', api);

  api.$inject = [
    'BackendApi'
  ]

  function api(backendApi) {
    return {
      fetchAllSettings,
      saveNotificationInTelegramMode,
      saveNotificationInBrowserMode,
      getGlobalTelegramUserSettings,
      updateGlobalTelegramUserSettings
    }

    function fetchAllSettings(userId) {
      return backendApi.makeRequest({
        v: 3,
        vehicleNotificationSettingsCmd: 'getAllGlobalSettings',
        userId
      })
    }

    function saveNotificationInTelegramMode(userId, isEnabled) {
      return backendApi.makeRequest({
        v: 3,
        vehicleNotificationSettingsCmd: 'setTelegramMode',
        userId,
        isEnabled
      })
    }

    function saveNotificationInBrowserMode(userId, isEnabled) {
      return backendApi.makeRequest({
        v: 3,
        vehicleNotificationSettingsCmd: 'setBrowserMode',
        userId,
        isEnabled
      })
    }

    function getGlobalTelegramUserSettings() {
      return backendApi.makeRequest({
        v: 3,
        vehicleNotificationSettingsCmd: 'getGlobalTelegramUserSettings'
      })
    }

    function updateGlobalTelegramUserSettings(newSettings) {
      return backendApi.makeRequest({
        v: 3,
        vehicleNotificationSettingsCmd: 'updateGlobalTelegramUserSettings',
        newSettings: JSON.stringify(newSettings)
      })
    }
  }
})();
