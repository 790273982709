(function () {
  "use strict";

  angular
    .module('app')
    .component('dialogControl', {
      templateUrl: 'app/component/dialogControl/dialogControl.html',
      controllerAs: 'vm',
      controller: Ctrl,
      bindings: {
        initialFullScreenMode: '<'
      },
      bindToController: true
    });

  Ctrl.$inject = [
    '$rootScope',
    'dialogControl/events'
  ]

  function Ctrl($rootScope, events) {
    var container;

    this.$onInit = function () {
      container = document.querySelector('md-dialog');

      this.isFullscreen = !!this.initialFullScreenMode;
    };

    this.onFullscreen = function () {
      $rootScope.$emit(events.ENTER_FULLSCREEN_MODE_PRESSED)
      this.isFullscreen = true;
      container.classList.add('dialog-fullscreen');
    };

    this.onFullscreenExit = function () {
      $rootScope.$emit(events.EXIT_FULLSCREEN_MODE_PRESSED)
      this.isFullscreen = false;
      container.classList.remove('dialog-fullscreen');
    };
  }
})();
