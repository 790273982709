(() => {
  angular
    .module('app.vehicles')
    .service('app.vehicles.details.lib.api', api);

  api.$inject = [
    'BackendApi'
  ];

  function api(backendApi) {
    return {
      getEventsForInterval
    };

    /**
     * @param {Object} options
     * @param {Object} options.initialDate
     * @param {Object} options.finalDate
     * @param {number | string} options.vehicleId
     */
    function getEventsForInterval(options) {
      const intervalStartDate = moment(options.initialDate);
      const intervalFinalDate = moment(options.finalDate).endOf('day');

      const daysCount = intervalFinalDate.diff(intervalStartDate, 'days');
      const filtersForEachDay = [];

      for (let i = 0; i <= daysCount; i++) {
        const dateFrom = i === 0 ? intervalStartDate : intervalStartDate.clone().add(i, 'days').startOf('day');
        const dateTo = i === daysCount ? intervalFinalDate : dateFrom.clone().endOf('day');
        filtersForEachDay.push({
          from: dateFrom.toDate(),
          to: dateTo.toDate()
        });
      }

      return Promise
        .all(filtersForEachDay.map((filterForDay) =>
          backendApi.getStops(options.vehicleId, filterForDay)));
    }
  }
})();
