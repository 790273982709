(() => {
  angular
    .module('app.reports')
    .constant('app.reports.exportTypes', {
      toCSV: 'toCSV',
      toXLSX: 'toXLSX',
      toPDF: 'toPDF',
      print: 'print',
      fuelReportToXLSX: 'fuelReportToXLSX',
      fuelReportToPDF: 'fuelReportToPDF',
      fuelReportToPrint: 'fuelReportToPrint'
    });
})();
