(() => {
  angular
    .module('app')
    .service('Auth.requireAuthHook', hook);

  hook.$inject = [
    '$transitions',
    '$cookies',
    '$state',
    'Auth',
    'app.tokens.accessByToken'
  ];

  const SESSION_COOKIE_NAME = 'MGS';

  function hook($transitions, $cookies, $state, Auth, accessByToken) {
    return {
      init
    };

    function init() {
      const redirectToLogin = function (transition) {
        if (accessByToken.isAccessByToken()) {
          return;
        }

        const sessionCookie = $cookies.get(SESSION_COOKIE_NAME);

        if (!Auth.isAuthenticated()) {
          var $state = transition.router.stateService;

          if (sessionCookie && Auth.tryToRenew(sessionCookie)) {
            return true;
          }
          return $state.target('login', undefined, {location: true});
        }
      };

      $transitions.onBefore({to: 'vehicles.**'}, redirectToLogin, {priority: 10});
      $transitions.onBefore({to: 'settings.**'}, redirectToLogin, {priority: 10});
      $transitions.onBefore({to: 'logistics.**'}, redirectToLogin, {priority: 10});
    }
  }
})();
