(() => {
  angular
    .module('app.tokens')
    .component('settingsTokens', {
      templateUrl: 'app/tokens/list-view/tpl.html',
      controller: Ctrl,
      controllerAs: 'ctrl',
      bindings: {
        vehicles: '<'
      },
      bindToController: true
    });

  Ctrl.$inject = [
    '$mdToast',
    '$mdDialog',
    '$translate',
    'app.tokens.api',
    'app.tokens.dialogs.removal-confirmation-dialog',
    'app.tokens.utils'
  ];

  const globalLoadingClassName = 'with-loading-tokens';

  function Ctrl($mdToast, $mdDialog, $translate, api, removalConfirmationDialog, utils) {
    this.allVehicles = [];

    this.tokens = [];
    let tokenVehicles = {}

    this.isDataLoading = false;
    this.isDataLoadingFailed = false;

    this.$onInit = () => {
      this.allVehicles = this.vehicles;
      this.isDataLoading = true;
      this.isDataLoadingFailed = false;
      document.body.classList.add(globalLoadingClassName);

      api.loadTokens()
        .then((data) => {
          this.tokens = data;

          tokenVehicles = data.reduce((acc, obj) => {
            acc[obj.id] = obj.vehicles.slice()
            return acc
          }, {})
        })
        .catch(() => {
          this.isDataLoadingFailed = true;
        })
        .finally(() => {
          this.isDataLoading = false;
          utils.syncChangesWithAngular(() => {
            document.body.classList.remove(globalLoadingClassName);
          });
        });
    };

    this.$onDestroy = () => {
    };

    this.onCreateBtnPressed = () => {
      api.createToken()
        .then((token) => {
          this.tokens.unshift(token);
          showNotification($translate.instant('settings.tokens.create.success'));
        })
        .catch((e) => {
          showNotification($translate.instant('settings.tokens.create.failed'));
        });
    };

    this.onCopyLinkBtnPressed = (token) => {
      const url = `${window.location.protocol}//${window.location.host}/?t=${token.id}`;
      navigator.clipboard.writeText(url)
        .then(() => {
          showNotification($translate.instant('settings.tokens.copyUrlWithToken.success'));
        })
        .catch((e) => {
          showNotification($translate.instant('settings.tokens.copyUrlWithToken.failed'));
        });
    };

    this.onDeleteBtnPressed = (token) => {
      removalConfirmationDialog.show(token)
        .then(() => {
          api.deleteToken(token)
            .then(() => {
              this.tokens = this.tokens.filter((obj) => obj.id !== token.id);
              showNotification($translate.instant('settings.tokens.delete.success'));
            })
            .catch((e) => {
              showNotification($translate.instant('settings.tokens.delete.failed'));
            });
        })
        .catch(() => {
        });
    };

    this.onTokenNameChanged = (token, newName) => {
      // TODO reset value when saving was failed
      token.name = newName;
      this.onTokenChanged(token);
    };

    this.onIsUsedForForwarderChanged = (token) => {
      const canSave = !token.isUsedForForwarder || token.vehicles.length <= 1
      if (canSave) {
        this.onTokenChanged(token)
        return
      }

      token.isUsedForForwarder = false

      const dialogOptions = $mdDialog.alert()
        .title($translate.instant('settings.tokens.canNotTurnOnForwarderModeDialogTitle'))
        .textContent($translate.instant('settings.tokens.canNotTurnOnForwarderModeDialogContent'))
        .ok($translate.instant('action.close'))
      $mdDialog.show(dialogOptions)
    }

    this.onTokenVehiclesChanged = (token) => {
      const canSave = !token.isUsedForForwarder || token.vehicles.length <= 1
      if (canSave) {
        tokenVehicles[token.id] = token.vehicles.slice()
        this.onTokenChanged(token)
        return
      }

      token.vehicles = tokenVehicles[token.id].slice()

      const dialogOptions = $mdDialog.alert()
        .title($translate.instant('settings.tokens.canNotAddVehicleDialogTitle'))
        .textContent($translate.instant('settings.tokens.canNotAddVehicleDialogContent'))
        .ok($translate.instant('action.close'))
      $mdDialog.show(dialogOptions)
    }

    this.onWithUnlimitedValidityCheckboxChanged = (token) => {
      if (token.withUnlimitedValidity) {
        token.expirationDate = null
      } else {
        token.expirationDate = new Date()
      }

      this.onTokenChanged(token)
    }

    this.onTokenChanged = (token) => {
      api.updateToken(token)
        .then(() => {
          // TODO update token with data returned from backend
          showNotification($translate.instant('settings.tokens.update.success'));
        })
        .catch(() => {
          showNotification($translate.instant('settings.tokens.update.failed'));
        });
    };

    function showNotification(text) {
      const toast = $mdToast
        .simple()
        .content(text)
        .position('bottom left');
      $mdToast.show(toast);
    }
  }
})();
