(() => {
  angular
    .module('app.vehicles')
    .service('app.vehicles.mileage.reportSaver', reportSaver);

  reportSaver.$inject = [
    '$translate',
    'app.vehicles.mileage.reportDataBuilder',
    'app.reports.exportTypes',
    'app.reports.fileSaver',
    'app.reports.converters.csv',
    'app.reports.helpers.xlsx',
    'app.reports.helpers.pdf',
    'app.reports.helpers.common',
    'app.reports.utils'
  ];

  function reportSaver($translate, reportDataBuilder, exportTypes, fileSaver,
    csvConverter, xlsxHelper, pdfHelper, commonForReports, reportUtils) {

    return {
      save
    };

    /**
     * @param {string} exportType
     * @param {Object} data
     */
    function save(exportType, data) {
      switch (exportType) {
        case exportTypes.toCSV:
          toCSV(data);
          break;
        case exportTypes.toXLSX:
          toXLSX(data);
          break;
        case exportTypes.toPDF:
          toPDF(data);
          break;
      }
    }

    function toCSV(data) {
      let rows = [];

      rows = rows.concat(getPageHeaderRows(data));
      rows = rows.concat([[]]);
      rows = rows.concat([getMainTableHeaderRows(data)]);
      rows = rows.concat(getMainTableBodyRows(data));
      rows = rows.concat([getMainTableFooterRows(data)]);

      const csvData = csvConverter.generate(rows);
      fileSaver.saveCSV(reportDataBuilder.createFileName(data), csvData);

      function getPageHeaderRows(data) {
        const headerData = reportDataBuilder.getPageHeaderData(data);

        return [
          [
            $translate.instant('reports.vehicleNumber'),
            headerData.vehicle.number,
            headerData.vehicle.id
          ],
          [
            $translate.instant('reports.dates'),
            reportDataBuilder.formatDateForPrint(headerData.report.dates[0]),
            reportDataBuilder.formatDateForPrint(headerData.report.dates[1])
          ],
          [
            $translate.instant('reports.stops.mileage'),
            headerData.report.totalMileage
          ],
          [
            $translate.instant('car.engineWork'),
            headerData.report.totalEngineWork
          ],
          [
            $translate.instant('reports.stops.fuelConsumptionShort'),
            headerData.report.totalFuelUsage
          ],
          [
            $translate.instant('reports.averageFuelUsage'),
            headerData.report.averageFuelUsage
          ]
        ];
      }

      function getMainTableHeaderRows(data) {
        return reportDataBuilder.getMainTableHeaderData(data);
      }

      function getMainTableBodyRows(data) {
        return reportDataBuilder.getMainTableBodyData(data)
          .map((obj) => {
            return obj.rowData.map((v, index) => {
              if (index === 0) {
                return reportDataBuilder.formatDateForPrint(v);
              }
              return v;
            });
          });
      }

      function getMainTableFooterRows(data) {
        return reportDataBuilder.getMainTableFooter(data);
      }
    }

    function toXLSX(data) {
      const workbook = new ExcelJS.Workbook();

      const mainTableHeaderRowIndex = 8;

      const sheet = workbook.addWorksheet($translate.instant('page.mileage'), {
        pageSetup: Object.assign(
          {},
          commonForReports.xlsxPageSetupOptions,
          {
            orientation: 'portrait',
            printTitlesRow: `${mainTableHeaderRowIndex}:${mainTableHeaderRowIndex}`
          }
        )
      });

      const areDayIntervalsHidden = reportDataBuilder.areDayIntervalsHidden(data);

      renderPageHeader(sheet, data);
      xlsxHelper.renderEmptyRow(sheet);
      renderMainTableHeader(sheet, data);
      renderMainTableBody(sheet, data);
      renderMainTableFooter(sheet, data);
      setColumnWidths(sheet, !areDayIntervalsHidden);

      fileSaver.saveXLSX(reportDataBuilder.createFileName(data), workbook);

      function renderPageHeader(sheet, data) {
        const headerData = reportDataBuilder.getPageHeaderData(data);

        const formatLastRow = () => {
          const rowIndex = sheet.rowCount;
          const row = sheet.getRow(rowIndex);
          sheet.mergeCells(rowIndex, 1, rowIndex, 3);
          sheet.mergeCells(rowIndex, 4, rowIndex, 5);
          xlsxHelper.styleRow(row);
          xlsxHelper.styleCellAsHeader(row.getCell(1));
          xlsxHelper.alignToLeft(row.getCell(1));
        };

        // ==================
        // "vehicle number" row
        // ==================
        sheet.addRow([
          // column 1
          $translate.instant('reports.vehicleNumber'),
          '',
          '',

          // column 2
          headerData.vehicle.number,
          '',

          // column 3
          headerData.vehicle.id
        ]);
        formatLastRow();

        // ==================
        // "Dates" row
        // ==================
        sheet.addRow([
          // column 1
          $translate.instant('reports.dates'),
          '',
          '',

          // column 2
          reportDataBuilder.formatDateForPrint(headerData.report.dates[0]),
          '',

          // column 3
          reportDataBuilder.formatDateForPrint(headerData.report.dates[1])
        ]);
        formatLastRow();

        // ==================
        // "Mileage" row
        // ==================
        sheet.addRow([
          // column 1
          $translate.instant('reports.stops.mileage'),
          '',
          '',

          // column 2
          headerData.report.totalMileage,
          '',

          // column 3
          ''
        ]);
        formatLastRow();

        // ==================
        // "Fuel consumption meter device" row
        // ==================
        if (headerData.report.shouldShowFuelConsumptionMeterType) {
          sheet.addRow([
            // column 1
            $translate.instant('reports.fuelConsumptionMonitoringDevice'),
            '',
            '',

            // column 2
            $translate.instant(headerData.report.fuelConsumptionMeterTypeI18nKey),
            '',

            // column 3
            ''
          ]);
          formatLastRow();
        }

        // ==================
        // "Total engine work" row
        // ==================
        sheet.addRow([
          // column 1
          $translate.instant('car.engineWork'),
          '',
          '',

          // column 2
          headerData.report.totalEngineWork,
          '',

          // column 3
          ''
        ]);
        formatLastRow();

        // ==================
        // "Total fuel usage" row
        // ==================
        sheet.addRow([
          // column 1
          $translate.instant('reports.stops.fuelConsumptionShort'),
          '',
          '',

          // column 2
          headerData.report.totalFuelUsage,
          '',

          // column 3
          ''
        ]);
        formatLastRow();

        // ==================
        // "Average fuel usage" row
        // ==================
        sheet.addRow([
          // column 1
          $translate.instant('reports.averageFuelUsage'),
          '',
          '',

          // column 2
          headerData.report.averageFuelUsage,
          '',

          // column 3
          ''
        ]);
        formatLastRow();
      }

      function renderMainTableHeader(sheet, data) {
        const headerData = reportDataBuilder.getMainTableHeaderData(data);
        const row = sheet.addRow(headerData);
        xlsxHelper.styleRow(row);
        row.height = 42;

        row.eachCell({includeEmpty: true}, (cell, columnIndex) => {
          xlsxHelper.styleCellAsHeader(cell);

          cell.alignment = Object.assign(
            cell.alignment || {},
            {
              horizontal: 'center',
              vertical: 'middle'
            }
          );

          // "Engine" column
          if (columnIndex === headerData.length - 1) {
            cell.alignment = Object.assign(
              cell.alignment || {},
              {
                wrapText: true
              }
            );
          }

          // "Fuel usage" column
          if (columnIndex === headerData.length - 3) {
            cell.alignment = Object.assign(
              cell.alignment || {},
              {
                wrapText: true
              }
            );
          }

          // "Average fuel usage" column
          if (columnIndex === headerData.length - 2) {
            cell.alignment = Object.assign(
              cell.alignment || {},
              {
                wrapText: true
              }
            );
          }

          // "Max speed" column
          if (columnIndex === headerData.length) {
            cell.alignment = Object.assign(
              cell.alignment || {},
              {
                wrapText: true
              }
            );
          }
        });
      }

      function renderMainTableBody(sheet, data) {
        const rowsData = reportDataBuilder.getMainTableBodyData(data);
        rowsData.forEach((obj) => {
          const rowData = obj.rowData.map((v, index) => {
            if (index === 0) {
              return reportDataBuilder.formatDateForPrint(v);
            }
            return v;
          });

          const row = sheet.addRow(rowData);
          xlsxHelper.styleRow(row);

          if (obj.isWeekend) {
            xlsxHelper.styleRowAsWeekend(row);
          }
        });
      }

      function renderMainTableFooter(sheet, data) {
        const rowData = reportDataBuilder.getMainTableFooter(data);
        const row = sheet.addRow(rowData);

        xlsxHelper.styleRow(row);
        row.eachCell({includeEmpty: true}, (cell, index) => {
          xlsxHelper.styleCellAsHeader(cell);

          if (index === 1) {
            xlsxHelper.alignToLeft(cell);
          }
        });
      }

      function setColumnWidths(sheet, hasDayIntervals = true) {
        // "Date" column
        sheet.columns[0].width = 13;

        // first day interval column
        sheet.columns[1].width = 12;

        let lastProcessedColumnIndex = 1;

        // other day interval columns
        if (hasDayIntervals) {
          sheet.columns[++lastProcessedColumnIndex].width = 12;
          sheet.columns[++lastProcessedColumnIndex].width = 12;
          sheet.columns[++lastProcessedColumnIndex].width = 12;
        }

        // "Fuel usage" column
        sheet.columns[++lastProcessedColumnIndex].width = 11;

        // "Average fuel usage" column
        sheet.columns[++lastProcessedColumnIndex].width = 13;

        // "Engine" column
        sheet.columns[++lastProcessedColumnIndex].width = 12;

        // "Max speed" column
        sheet.columns[++lastProcessedColumnIndex].width = 10;
      }
    }

    function toPDF(data) {
      const headerData = reportDataBuilder.getPageHeaderData(data);

      const pageTitleText = [
        `${headerData.vehicle.number} - `,
        `${$translate.instant('page.mileage')}: `,
        [
          reportDataBuilder.formatDateForPrint(headerData.report.dates[0]),
          reportDataBuilder.formatDateForPrint(headerData.report.dates[1])
        ].join(' - ')
      ].join('');

      const doc = {
        header: pdfHelper.createPageTitle(pageTitleText),
        footer: (currentPage) => pdfHelper.createPageFooter(currentPage),
        pageMargins: pdfHelper.pageMargins,
        content: [],
        styles: pdfHelper.defaultStyles
      };

      renderPageHeader(doc, data);
      renderMainTable(doc, data);

      fileSaver.savePDF(reportDataBuilder.createFileName(data), pdfMake.createPdf(doc));

      function renderPageHeader(doc, data) {
        const headerData = reportDataBuilder.getPageHeaderData(data);
        const rows = [];

        // ==================
        // "vehicle number" row
        // ==================
        rows.push([
          $translate.instant('reports.vehicleNumber'),
          headerData.vehicle.number,
          headerData.vehicle.id
        ]);

        // ==================
        // "Dates" row
        // ==================
        rows.push([
          $translate.instant('reports.dates'),
          reportDataBuilder.formatDateForPrint(headerData.report.dates[0]),
          reportDataBuilder.formatDateForPrint(headerData.report.dates[1])
        ]);

        // ==================
        // "Mileage" row
        // ==================
        rows.push([
          $translate.instant('reports.stops.mileage'),
          headerData.report.totalMileage
        ]);

        // ==================
        // "Fuel consumption meter device" row
        // ==================
        if (headerData.report.shouldShowFuelConsumptionMeterType) {
          rows.push([
            $translate.instant('reports.fuelConsumptionMonitoringDevice'),
            $translate.instant(headerData.report.fuelConsumptionMeterTypeI18nKey)
          ]);
        }

        // ==================
        // "Total engine work" row
        // ==================
        rows.push([
          $translate.instant('car.engineWork'),
          headerData.report.totalEngineWork
        ]);

        // ==================
        // "Total fuel usage" row
        // ==================
        rows.push([
          $translate.instant('reports.stops.fuelConsumptionShort'),
          headerData.report.totalFuelUsage
        ]);

        // ==================
        // "Average fuel usage" row
        // ==================
        rows.push([
          $translate.instant('reports.averageFuelUsage'),
          headerData.report.averageFuelUsage
        ]);

        doc.content.push(pdfHelper.createHorizontalTable(rows));
      }

      function renderMainTable(doc, data) {
        let rowsData = [];

        // table header
        rowsData = rowsData.concat(
          [
            reportDataBuilder.getMainTableHeaderData(data).map((cellValue) => ({
              text: cellValue,
              style: 'tableHeaderForVerticalTable'
            }))
          ]
        );

        // table body
        rowsData = rowsData.concat(
          reportDataBuilder.getMainTableBodyData(data).map((obj) => {
            return obj.rowData.map((cellValue, index) => {
              if (index === 0) {
                cellValue = reportDataBuilder.formatDateForPrint(cellValue);
              }

              let cell = {
                text: cellValue,
                style: 'alignToRight'
              };

              if (obj.isWeekend) {
                cell = Object.assign(
                  {},
                  cell,
                  {
                    fillColor: `#${commonForReports.weekendCellBgColor}`
                  }
                );
              }

              return cell;
            });
          })
        );

        // table footer
        rowsData = rowsData.concat(
          [
            reportDataBuilder.getMainTableFooter(data).map((cellValue, index) => ({
              text: cellValue,
              style: index === 0 ? 'firstCellInTableFooter' : 'otherCellInTableFooter'
            }))
          ]
        );

        const columnsWidth = [];
        for (let i = 0; i < rowsData[0].length; i++) {
          columnsWidth.push('auto');
        }

        doc.content.push({
          style: 'mainTable',
          table: {
            headerRows: 1,
            body: rowsData,
            widths: columnsWidth
          }
        });
      }
    }
  }
})();
