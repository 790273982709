(() => {
  angular
    .module('app.vehicles.details.lib.fuel-calculations')
    .service('app.vehicles.details.lib.fuel-calculations.fuel-calculations', fuelCalculations);

  fuelCalculations.$inject = [
    'Session',
    'fuel-consumption-metering-settings',
    'app.vehicles.details.lib.fuel-calculations.constants',
    'app/main/services/responseParserUtils',
    'Utils'
  ];

  const emptyResultForPeriod = {
    isEmpty: true,

    typeOfFuelLevelMeter: null,

    hasTwoTanks: false,

    startDate: null,
    endDate: null,

    mileage: 0,

    engineOperationDuration: 0,
    engineOperationDurationFormatted: '0:00',

    initialFuelAmountInMainTank: 0,
    initialFuelAmountInSecondTank: 0,
    initialFuelAmountInAllTanks: 0,

    finalFuelAmountInMainTank: 0,
    finalFuelAmountInSecondTank: 0,
    finalFuelAmountInAllTanks: 0,

    totalFuelUsageInMainTank: 0,
    totalFuelUsageInSecondTank: 0,
    totalFuelUsageInAllTanks: 0,
    totalFuelUsageWithDischargeInAllTanks: 0,

    averageFuelUsage: 0,

    totalRefuelingInMainTank: 0,
    totalRefuelingInSecondTank: 0,
    totalRefuelingInAllTanks: 0,

    totalFuelDrainInMainTank: 0,
    totalFuelDrainInSecondTank: 0,
    totalFuelDrainInAllTanks: 0
  };

  function fuelCalculations(Session, fuelConsumptionMeteringSettings, constants, responseParserUtils, Utils) {
    return {
      isIntervalWithNoGPS,
      isIntervalWithParking,
      isIntervalWithMovement,

      isImpulseSensorUsed,
      isFuelCalculatorUsed,
      isFuelLevelSensorUsed,

      getFuelAmountChangesForIntervalWithParking,
      getFuelAmountChangesForIntervalWithMovement,

      getDetailsForTimePeriod
    };

    // ======================
    // type of interval
    // ======================
    function isIntervalWithNoGPS(interval = {}) {
      return interval.status === '1';
    }

    function isIntervalWithParking(interval = {}) {
      return interval.status === '2';
    }

    function isIntervalWithMovement(interval = {}) {
      return interval.status === '3';
    }

    function isIntervalWithRefill(interval = {}) {
      return interval.status === '8';
    }

    function isIntervalWithGps(interval = {}) {
      return interval.hasGPS;
    }

    // ======================
    // type of fuel level sensor
    // ======================
    function isImpulseSensorUsed(timePeriodDetails) {
      return toNumber(timePeriodDetails.summary.impulse) > 0;
    }

    function isFuelCalculatorUsed(timePeriodDetails) {
      if (timePeriodDetails.stops.some(
        obj =>
          toNumber(obj.fuelFrom) !== 0
          ||
          toNumber(obj.fuelTo) !== 0
          ||
          toNumber(obj.secondFuelFrom) !== 0
          ||
          toNumber(obj.secondFuelTo) !== 0
      )) {
        return false;
      }
      return !isNaN(getFuelUsagePer100Km(timePeriodDetails.stops[0], timePeriodDetails.summary));
    }

    function isFuelLevelSensorUsed(timePeriodDetails) {
      return !isImpulseSensorUsed(timePeriodDetails) && !isFuelCalculatorUsed(timePeriodDetails);
    }

    function getFuelUsagePer100Km(interval, timePeriodSummaryDetails) {
      if (typeof timePeriodSummaryDetails.fuelSettings !== 'object' || typeof interval === 'undefined') {
        return NaN;
      }

      const seasonName = isSummer(moment.unix(interval.timestampFrom).toDate()) ? 'summer' : 'winter';
      const terrainType = toNumber(interval.distance) < 10 ? 'city' : 'road';

      try {
        return parseFloat(timePeriodSummaryDetails.fuelSettings[seasonName][terrainType]);
      } catch (e) {
        return NaN;
      }
    }

    function isSummer(date) {
      date = date || new Date();
      var season = Session.user.settings.seasons || {"summer": "2000.04.15", "winter": "2000.10.15"};
      var winter = new Date(season.winter);
      var summer = new Date(season.summer);
      var isWinterLessThanSummer = winter < summer;
      var isDateLessThanSummer = date < summer;
      var isDateLessThanWinter = date < winter;

      return (
          !isDateLessThanSummer
          &&
          (
            isDateLessThanWinter || isWinterLessThanSummer
          )
        )
        ||
        (
          isDateLessThanWinter && isWinterLessThanSummer
        );
    }

    // ======================
    // calculate fuel amount changes
    // ======================
    function getFuelAmountChangesForIntervalWithParking(interval = {}) {
      const mainTank = toNumber(interval.fuelTo) - toNumber(interval.fuelFrom);
      const secondTank = toNumber(interval.secondFuelTo) - toNumber(interval.secondFuelFrom);
      return {
        mainTank,
        secondTank,
        total: mainTank + secondTank
      };
    }

    function getFuelAmountChangesForIntervalWithMovement(interval, timePeriodDetails) {
      let mainTank;
      let secondTank;

      switch (true) {
        case isImpulseSensorUsed(timePeriodDetails):
          return {
            mainTank: 0,
            secondTank: 0,
            total: toNumber(interval.cnt) / 100000000
          };
        case isFuelCalculatorUsed(timePeriodDetails):
          const distance = toNumber(interval.distance);
          const fuelUsagePer100Km = getFuelUsagePer100Km(interval, timePeriodDetails.summary);
          const fuelUsage = distance / 100 * fuelUsagePer100Km;

          return {
            mainTank: 0,
            secondTank: 0,
            total: Math.round(fuelUsage * 100) / 100
          };
        case isFuelLevelSensorUsed(timePeriodDetails):
          mainTank = toNumber(interval.fuelTo) - toNumber(interval.fuelFrom);
          secondTank = toNumber(interval.secondFuelTo) - toNumber(interval.secondFuelFrom);

          return {
            mainTank,
            secondTank,
            total: mainTank + secondTank
          };
      }
    }

    // ======================
    // calculate details for time period
    // ======================
    function getDetailsForTimePeriod(timePeriodDetails) {
      switch (true) {
        case isImpulseSensorUsed(timePeriodDetails):
          return getDetailsForTimePeriodWhenImpulseSensorIsUsed(timePeriodDetails);
        case isFuelCalculatorUsed(timePeriodDetails):
          return getDetailsForTimePeriodWhenFuelCalculatorIsUsed(timePeriodDetails);
        case isFuelLevelSensorUsed(timePeriodDetails):
          return getDetailsForTimePeriodWhenFuelLevelSensorIsUsed(timePeriodDetails);
        default:
          throw new Error('Unknown fuel level sensor');
      }
    }

    function getDetailsForTimePeriodWhenImpulseSensorIsUsed(timePeriodDetails) {
      if (!isImpulseSensorUsed(timePeriodDetails)) {
        throw new Error('Can\'t calculate fuel details when impulse sensor is not used');
      }

      const allConsideredIntervals = getAllConsideredIntervals(timePeriodDetails);

      if (!allConsideredIntervals.length) {
        return getEmptyResultFromStops(timePeriodDetails.stops);
      }

      const baseDetailsForTimePeriod = getBaseDetailsForTimePeriod(timePeriodDetails);

      const intervals = allConsideredIntervals
        .filter(obj => !fuelConsumptionMeteringSettings.isExcludedEventWithControversialFuelLevel(obj));

      if (!intervals.length) {
        return baseDetailsForTimePeriod;
      }

      let totalRefuelingInMainTank = 0;
      let totalRefuelingInSecondTank = 0;

      let totalFuelDrainInMainTank = 0;
      let totalFuelDrainInSecondTank = 0;

      let totalFuelUsageInMainTank = 0;
      let totalFuelUsageInSecondTank = 0;

      let fuelAmountChangePerInterval;

      intervals.forEach((interval) => {
        switch (true) {
          case isIntervalWithParking(interval):
            fuelAmountChangePerInterval = getFuelAmountChangesForIntervalWithParking(interval);

            if (fuelAmountChangePerInterval.mainTank < 0) {
              totalFuelUsageInMainTank += Math.abs(fuelAmountChangePerInterval.mainTank);
            }

            if (fuelAmountChangePerInterval.secondTank < 0) {
              totalFuelUsageInSecondTank += Math.abs(fuelAmountChangePerInterval.secondTank);
            }
            break;
          case isIntervalWithMovement(interval):
            fuelAmountChangePerInterval =
              getFuelAmountChangesForIntervalWithMovement(interval, timePeriodDetails);

            totalFuelUsageInMainTank += fuelAmountChangePerInterval.total;
            break;
        }
      });

      const totalFuelUsageInAllTanks = totalFuelUsageInMainTank + totalFuelUsageInSecondTank;

      return Object.assign(
        {},
        baseDetailsForTimePeriod,
        {
          totalFuelUsageInMainTank,
          totalFuelUsageInSecondTank,
          totalFuelUsageInAllTanks,
          totalFuelUsageWithDischargeInAllTanks: baseDetailsForTimePeriod.initialFuelAmountInAllTanks + totalRefuelingInMainTank + totalRefuelingInSecondTank - baseDetailsForTimePeriod.finalFuelAmountInAllTanks,

          totalRefuelingInMainTank,
          totalRefuelingInSecondTank,
          totalRefuelingInAllTanks: totalRefuelingInMainTank + totalRefuelingInSecondTank,

          totalFuelDrainInMainTank,
          totalFuelDrainInSecondTank,
          totalFuelDrainInAllTanks: totalFuelDrainInMainTank + totalFuelDrainInSecondTank,

          averageFuelUsage: getAverageFuelUsage(totalFuelUsageInAllTanks, baseDetailsForTimePeriod.mileage)
        }
      );
    }

    function getDetailsForTimePeriodWhenFuelCalculatorIsUsed(timePeriodDetails) {
      if (!isFuelCalculatorUsed(timePeriodDetails)) {
        throw new Error('Can\'t calculate fuel details when fuel calculator is not used');
      }

      const allConsideredIntervals = getAllConsideredIntervals(timePeriodDetails);

      if (!allConsideredIntervals.length) {
        return getEmptyResultFromStops(timePeriodDetails.stops);
      }

      const baseDetailsForTimePeriod = getBaseDetailsForTimePeriod(timePeriodDetails);

      const intervals = allConsideredIntervals
        .filter(obj => isIntervalWithMovement(obj))
        .filter(obj => !fuelConsumptionMeteringSettings.isExcludedEventWithControversialFuelLevel(obj));

      if (!intervals.length) {
        return baseDetailsForTimePeriod;
      }

      let totalRefuelingInMainTank = 0;
      let totalRefuelingInSecondTank = 0;

      let totalFuelDrainInMainTank = 0;
      let totalFuelDrainInSecondTank = 0;

      let totalFuelUsageInMainTank = 0;
      let totalFuelUsageInSecondTank = 0;

      let fuelAmountChangePerInterval;

      intervals.forEach((interval) => {
        switch (true) {
          case isIntervalWithParking(interval):
            fuelAmountChangePerInterval = getFuelAmountChangesForIntervalWithParking(interval);

            if (fuelAmountChangePerInterval.mainTank < 0) {
              totalFuelUsageInMainTank += Math.abs(fuelAmountChangePerInterval.mainTank);
            }

            if (fuelAmountChangePerInterval.secondTank < 0) {
              totalFuelUsageInSecondTank += Math.abs(fuelAmountChangePerInterval.secondTank);
            }
            break;
          case isIntervalWithMovement(interval):
            fuelAmountChangePerInterval =
              getFuelAmountChangesForIntervalWithMovement(interval, timePeriodDetails);

            totalFuelUsageInMainTank += fuelAmountChangePerInterval.total;
            break;
        }
      });

      const totalFuelUsageInAllTanks = totalFuelUsageInMainTank + totalFuelUsageInSecondTank;

      return Object.assign(
        {},
        baseDetailsForTimePeriod,
        {
          totalFuelUsageInMainTank,
          totalFuelUsageInSecondTank,
          totalFuelUsageInAllTanks,
          totalFuelUsageWithDischargeInAllTanks: baseDetailsForTimePeriod.initialFuelAmountInAllTanks + totalRefuelingInMainTank + totalRefuelingInSecondTank - baseDetailsForTimePeriod.finalFuelAmountInAllTanks,

          totalRefuelingInMainTank,
          totalRefuelingInSecondTank,
          totalRefuelingInAllTanks: totalRefuelingInMainTank + totalRefuelingInSecondTank,

          totalFuelDrainInMainTank,
          totalFuelDrainInSecondTank,
          totalFuelDrainInAllTanks: totalFuelDrainInMainTank + totalFuelDrainInSecondTank,

          averageFuelUsage: getAverageFuelUsage(totalFuelUsageInAllTanks, baseDetailsForTimePeriod.mileage)
        }
      );
    }

    function getDetailsForTimePeriodWhenFuelLevelSensorIsUsed(timePeriodDetails) {
      if (!isFuelLevelSensorUsed(timePeriodDetails)) {
        throw new Error('Can\'t calculate fuel details when fuel level sensor is not used');
      }

      const allConsideredIntervals = getAllConsideredIntervals(timePeriodDetails, true);
      const idle = (timePeriodDetails.summary.fuelSettings || {}).idle;

      const allRefillIntervals = timePeriodDetails.stops
          .filter(obj => isIntervalWithRefill(obj));

      if (!allConsideredIntervals.length) {
        return getEmptyResultFromStops(timePeriodDetails.stops);
      }

      const baseDetailsForTimePeriod = getBaseDetailsForTimePeriod(timePeriodDetails, true);

      const intervals = allConsideredIntervals
        .filter(isIntervalWithParking)
        .filter(obj => !fuelConsumptionMeteringSettings.isExcludedEventWithControversialFuelLevel(obj));

      if (!intervals.length) {
        return baseDetailsForTimePeriod;
      }

      let totalRefuelingInMainTank = 0;
      let totalRefuelingInSecondTank = 0;

      let totalFuelDrainInMainTank = 0;
      let totalFuelDrainInSecondTank = 0;

      let fuelAmountChangePerInterval;

      intervals.forEach((interval) => {
        switch (true) {
          case isIntervalWithParking(interval):
            fuelAmountChangePerInterval = getFuelAmountChangesForIntervalWithParking(interval);
            const isMainTankDraining = Utils.isDraining(idle, interval.engine, Math.abs(fuelAmountChangePerInterval.mainTank));
            const isSecondTankDraining = Utils.isDraining(idle, interval.engine, Math.abs(fuelAmountChangePerInterval.secondTank));

            if (fuelAmountChangePerInterval.mainTank > 0) {
              totalRefuelingInMainTank += fuelAmountChangePerInterval.mainTank;
            }

            if (fuelAmountChangePerInterval.mainTank < 0 && isMainTankDraining) {
              totalFuelDrainInMainTank += Math.abs(fuelAmountChangePerInterval.mainTank);
            }

            if (fuelAmountChangePerInterval.secondTank > 0) {
              totalRefuelingInSecondTank += fuelAmountChangePerInterval.secondTank;
            }

            if (fuelAmountChangePerInterval.secondTank < 0 && isSecondTankDraining) {
              totalFuelDrainInSecondTank += Math.abs(fuelAmountChangePerInterval.secondTank);
            }
            break;
          case isIntervalWithMovement(interval):
            // XXX Do nothing by intention
            break;
        }
      });

      let totalFuelUsageInMainTank =
        baseDetailsForTimePeriod.initialFuelAmountInMainTank +
        totalRefuelingInMainTank -
        totalFuelDrainInMainTank -
        baseDetailsForTimePeriod.finalFuelAmountInMainTank;

      const totalFuelUsageInSecondTank =
        baseDetailsForTimePeriod.initialFuelAmountInSecondTank +
        totalRefuelingInSecondTank -
        totalFuelDrainInSecondTank -
        baseDetailsForTimePeriod.finalFuelAmountInSecondTank;


        let totalFuelUsageInAllTanks;
        let totalRefuelingInAllTanks;
        let totalFuelDrainInAllTanks;
        if (timePeriodDetails.summary.v2FuelSettings) {
          totalFuelUsageInMainTank = timePeriodDetails.summary.totalServerFuelUsage;
          totalFuelUsageInAllTanks = totalFuelUsageInMainTank;

          totalRefuelingInMainTank = allRefillIntervals
            .filter((interval) => interval.refill > 0)
            .reduce((acc, curr) => {
              return acc + curr.refill;
            }, 0);
            totalFuelDrainInMainTank = allRefillIntervals
            .filter((interval) => interval.refill < 0)
            .reduce((acc, curr) => {
              return acc + curr.refill;
            }, 0);
          totalRefuelingInAllTanks = totalRefuelingInMainTank;
          totalFuelDrainInAllTanks = totalFuelDrainInMainTank;
        } else {
          totalFuelUsageInMainTank += responseParserUtils.getFuelCorrection(timePeriodDetails); 
          totalFuelUsageInAllTanks = totalFuelUsageInMainTank + totalFuelUsageInSecondTank;
          totalRefuelingInAllTanks = totalRefuelingInMainTank + totalRefuelingInSecondTank;
          totalFuelDrainInAllTanks = totalFuelDrainInMainTank + totalFuelDrainInSecondTank;

          baseDetailsForTimePeriod.initialFuelAmountInMainTank = timePeriodDetails.summary.fuelStart || baseDetailsForTimePeriod.initialFuelAmountInMainTank;
          baseDetailsForTimePeriod.finalFuelAmountInMainTank = timePeriodDetails.summary.fuelEnd || baseDetailsForTimePeriod.finalFuelAmountInMainTank;
          
          baseDetailsForTimePeriod.initialFuelAmountInAllTanks = timePeriodDetails.summary.fuelStart || baseDetailsForTimePeriod.initialFuelAmountInAllTanks;
          baseDetailsForTimePeriod.finalFuelAmountInAllTanks = timePeriodDetails.summary.fuelEnd || baseDetailsForTimePeriod.finalFuelAmountInAllTanks;
        }

      return Object.assign(
        {},
        baseDetailsForTimePeriod,
        {
          totalFuelUsageInMainTank,
          totalFuelUsageInSecondTank,
          totalFuelUsageInAllTanks,
          totalFuelUsageWithDischargeInAllTanks: baseDetailsForTimePeriod.initialFuelAmountInAllTanks + totalRefuelingInMainTank + totalRefuelingInSecondTank - baseDetailsForTimePeriod.finalFuelAmountInAllTanks,

          totalRefuelingInMainTank,
          totalRefuelingInSecondTank,
          totalRefuelingInAllTanks,

          totalFuelDrainInMainTank,
          totalFuelDrainInSecondTank,
          totalFuelDrainInAllTanks,

          averageFuelUsage: getAverageFuelUsage(totalFuelUsageInAllTanks, baseDetailsForTimePeriod.mileage)
        }
      );
    }

    function getAllConsideredIntervals(timePeriodDetails, useOnlyItemsWithGps) {
      return timePeriodDetails.stops
        .filter(obj => (isIntervalWithParking(obj) || isIntervalWithMovement(obj))
                    && (!useOnlyItemsWithGps || isIntervalWithGps(obj)));
    }

    function getBaseDetailsForTimePeriod(timePeriodDetails, useOnlyItemsWithGps) {
      const allConsideredIntervals = getAllConsideredIntervals(timePeriodDetails, useOnlyItemsWithGps);

      if (!allConsideredIntervals.length) {
        return getEmptyResultFromStops(timePeriodDetails.stops);
      }

      const initialFuelAmountInMainTank = toNumber(allConsideredIntervals[0].fuelFrom);
      const initialFuelAmountInSecondTank = toNumber(allConsideredIntervals[0].secondFuelFrom);

      const finalFuelAmountInMainTank = toNumber(allConsideredIntervals.slice(-1)[0].fuelTo);
      const finalFuelAmountInSecondTank = toNumber(allConsideredIntervals.slice(-1)[0].secondFuelTo);

      return Object.assign(
        {},
        emptyResultForPeriod,
        {
          isEmpty: false,

          hasTwoTanks: checkIfHasTwoTanks(timePeriodDetails),

          typeOfFuelLevelMeter: getTypeOfFuelLevelMeter(timePeriodDetails),

          initialFuelAmountInMainTank,
          initialFuelAmountInSecondTank,
          initialFuelAmountInAllTanks: initialFuelAmountInMainTank + initialFuelAmountInSecondTank,

          finalFuelAmountInMainTank,
          finalFuelAmountInSecondTank,
          finalFuelAmountInAllTanks: finalFuelAmountInMainTank + finalFuelAmountInSecondTank,

          startDate: timestampToDate(allConsideredIntervals[0].timestampFrom),
          endDate: timestampToDate(allConsideredIntervals.slice(-1)[0].timestampTo),

          mileage: getTimePeriodMileage(timePeriodDetails),

          engineOperationDuration: getTimePeriodEngineOperationDuration(timePeriodDetails),
          engineOperationDurationFormatted: formatPeriod(getTimePeriodEngineOperationDuration(timePeriodDetails))
        }
      );
    }

    // ======================
    // utility functions
    // ======================
    function getTimePeriodMileage(timePeriodDetails) {
      //const result = getAllConsideredIntervals(timePeriodDetails)
      //  .reduce((acc, obj) => acc + toNumber(obj.distance), 0);

      //if (result > 0) {
      //  return result;
      //}

      return toNumber(timePeriodDetails.summary.distanceKm)
        || toNumber(timePeriodDetails.summary.calculatedDistanceKm)
        || 0;
    }

    function getTimePeriodEngineOperationDuration(timePeriodDetails) {
      const result = getAllConsideredIntervals(timePeriodDetails)
        .reduce((acc, obj) => acc + toNumber(obj.engine), 0);

      if (result > 0) {
        return result;
      }

      return toNumber(timePeriodDetails.summary.engine);
    }

    function getTypeOfFuelLevelMeter(timePeriodDetails) {
      switch (true) {
        case isImpulseSensorUsed(timePeriodDetails):
          return constants.typeOfFuelLevelMeter.impulseSensor;
        case isFuelCalculatorUsed(timePeriodDetails):
          return constants.typeOfFuelLevelMeter.fuelCalculator;
        case isFuelLevelSensorUsed(timePeriodDetails):
          return constants.typeOfFuelLevelMeter.fuelLevelSensor;
        default:
          return null;
      }
    }

    function getAverageFuelUsage(totalFuelUsage, mileage) {
      return toNumber(totalFuelUsage * 100 / mileage);
    }

    function checkIfHasTwoTanks(timePeriodDetails) {
      return (
        timePeriodDetails.stops.some(obj => toNumber(obj.fuelFrom) > 0 || toNumber(obj.fuelTo) > 0)
        &&
        timePeriodDetails.stops.some(obj => toNumber(obj.secondFuelFrom) > 0 || toNumber(obj.secondFuelTo) > 0)
      );
    }

    function toNumber(v) {
      v = parseFloat(v);
      if (isNaN(v) || Math.abs(v) === Infinity) {
        return 0;
      }
      return v;
    }

    function timestampToDate(timestamp) {
      timestamp = toNumber(timestamp);
      if (timestamp <= 0 || Math.round(timestamp) !== timestamp) {
        throw new Error(`[timestampToDate] Invalid value for timestamp "${timestamp}"`);
      }
      return new Date(timestamp * 1000);
    }

    function getEmptyResultFromStops(stops) {
      return Object.assign(
        {},
        emptyResultForPeriod,
        {
          startDate: timestampToDate(stops[0].timestampFrom),
        });
    }

    function formatPeriod(period) {
      var duration = moment.duration(period, 'seconds'),
        days = duration.days(),
        hours = duration.hours(),
        minutes = ('0' + duration.minutes()).slice(-2)
      ;

      return days * 24 + hours + ':' + minutes;
    }
  }
})();
