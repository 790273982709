(() => {
  angular
    .module('app.tokens')
    .service('app.tokens.api', api);

  api.$inject = [
    '$http'
  ];

  function api($http) {
    return {
      loginWithToken,
      getToken,
      loadTokens,
      createToken,
      updateToken,
      deleteToken
    };

    function loginWithToken(tokenId) {
      return $http
        .post('x.xx', {
          c: 1,
          token: tokenId
        })
    }

    function getToken(tokenId) {
      return $http
        .post('/x.xx', {
          settings_tokens: true,
          cmd: 'get',
          id: tokenId
        })
        .then((resp) => {
          return resp.data
        });
    }

    function loadTokens() {
      return $http
        .post('/x.xx', {
          settings_tokens: true,
          cmd: 'list'
        })
        .then((resp) => {
          return resp.data.map((obj) => Object.assign(obj, {
            expirationDate: !!obj.expirationDate ? new Date(obj.expirationDate) : null
          }));
        });
    }

    function createToken() {
      return $http
        .post('/x.xx', {
          settings_tokens: true,
          cmd: 'create'
        })
        .then((resp) => {
          return Object.assign(resp.data, {
            expirationDate: new Date(resp.data.expirationDate)
          });
        });
    }

    function updateToken(token) {
      const data = Object.assign(
        {},
        token,
        {
          expirationDate: !!token.expirationDate ? token.expirationDate.getTime() : 0
        }
      );

      return $http
        .post('/x.xx', {
          settings_tokens: true,
          cmd: 'update',
          id: token.id,
          data: JSON.stringify(data)
        })
        .then((resp) => {
          return Object.assign(resp.data, {
            expirationDate: new Date(resp.data.expirationDate)
          });
        });
    }

    function deleteToken(token) {
      return $http
        .post('/x.xx', {
          settings_tokens: true,
          cmd: 'delete',
          id: token.id
        })
        .then((resp) => {
          return resp.data;
        });
    }
  }
})();
