(() => {
  angular
    .module('app.reports')
    .constant('app.reports.converters.xlsx', createConverter());

  function createConverter() {
    return {
      generate
    };

    /**
     * @param {Object} workbook
     * @param {Object} metaData
     */
    function generate(workbook, metaData) {
      const defaultWorkbookProps = {
        Author: 'TransGPS',
        CreatedDate: new Date()
      };

      workbook.Props = Object.assign(defaultWorkbookProps, metaData || {});

      const ws = XLSX.utils.aoa_to_sheet(data);
      ws['B2'].s = {
        font: {
          bold: true,
          italic: true,
          sz: 40
        }
      }

      XLSX.utils.book_append_sheet(
        workbook,
        ws,
        'sheet'
      );

      return workbook;
    }
  }
})();
