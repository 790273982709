(function () {
  "use strict";

  angular
    .module('app')
    .component('fuelChart', {
      bindings: {
      },
      templateUrl: 'app/component/fuel/fuelChart.html',
      controllerAs: 'vm',
      controller: FuelChartCtrl
    });

  FuelChartCtrl.$inject = ['$element', '$rootScope', '$state', '$filter', '$q', '$localStorage', 'Session', 'BackendApi', 'LoadScriptService', 'MapOsmService', 'ChartService', 'DateUtils', 'APP_EVENTS'];

  function FuelChartCtrl($element, $rootScope, $state, $filter, $q, $localStorage, Session, BackendApi, LoadScriptService, MapOsmService, ChartService, DateUtils, APP_EVENTS) {
    var self = this;

    var chart;
    var columnHeader;
    var columns;
    var _fuelData = [];
    var fuelSettings;
    var isBarChart;
    var impulse;
    var tank1 = 'Бак #1';
    var tank2 = 'Бак #2';
    var tank12 = 'Бак #1 + Бак #2';
    var tank3 = 'Бак #3';
    var temp = 'Температура'
    var _color = {
      black: 'black',
      green: 'green',
      yellow: 'yellow',
      red: 'red',
      pink: 'ff1e56',
      coral: 'coral'
    };
    var speed = $filter('translate')('car.maxSpeed');
    var trans = {
      ru: {
        with: 'c',
        mileage: 'пробег',
        usage: 'расход',
        usageLess: 'Меньше нормы',
        usageNorm: 'В пределах нормы',
        usageNormControl: 'В пределах контроля отклонения',
        usageMore: 'Выше нормы'
      },
      ua: {
        with: 'з',
        mileage: 'пробіг',
        usage: 'витрата',
        usageLess: 'Менше норми',
        usageNorm: 'В межах норми',
        usageNormControl: 'В межах контролю відхилення',
        usageMore: 'Вище норми'
      },
      en: {
        with: 'from',
        mileage: 'mileage',
        usage: 'usage',
        usageLess: 'Less morm',
        usageNorm: 'Within the norm',
        usageNormControl: 'Within the control of deviation',
        usageMore: 'Above the norm'
      }
    };
    var transWith = getTrans('with');
    var transMileage = getTrans('mileage');
    var transUsage = getTrans('usage');
    var transUsageLess = getTrans('usageLess');
    var transUsageNorm = getTrans('usageNorm');
    var transUsageNormControl = getTrans('usageNormControl');
    var transUsageMore = getTrans('usageMore');

    var eventFuelChart = $rootScope.$on(APP_EVENTS.car.fuelChart, function (e, data) {
      if (data) {
        if (data.zoomOptions) {
          ChartService.changeZoom(data.zoomOptions.from, data.zoomOptions.to);
          return;
        }


        if (data.hasOwnProperty('show') && self.showChart != data.show) {
          toggleChart(data.show);
          if (!data.show) return;
        }
        if (data.hasOwnProperty('route') && self.showChart) {
          console.log('--------------- data.route', data.route);
          self.carName = getCarName(data.id);
          self.summary = data.route.summary;
          self.carId = data.id;

          _fuelData = [];
          fuelSettings = data.route.summary && data.route.summary.fuelSettings;
          impulse = data.route.summary && data.route.summary.impulse;
          self.needSettings = !fuelSettings;

          if (fuelSettings) {
            isBarChart = !!impulse;
            loadCharts().then(function () {
              setTimeout(function () {
                  getCarSensorsData(data.id, data.filter)
                  .then(function (result) {
                    var fuel1 = [];
                    var fuel2 = [];
                    var combinedFuel = [];
                    var speed = [];
                    var v1 = [];
                    var temp = [];

                    result.forEach(function (item) {
                      var timestamp = +item.timestampFrom * 1000;

                      fuel1.push([timestamp, +item.fuel1]);
                      fuel2.push([timestamp, +item.fuel2]);
                      (item.fuel1 && item.fuel2) && combinedFuel.push([timestamp, +item.fuel1 + +item.fuel2]);
                      speed.push([timestamp, +item.speed]);
                      v1.push([timestamp, +item.v1]);
                      temp.push([timestamp, +item.temp]);
                    });

                    var hasCombinedFuel = !!$localStorage.combineFuel && !!$localStorage.combineFuel[data.id];

                    columns = [];
                    columns.push(
                      {
                        name: "Бак 1",
                        data: fuel1,
                        visible: !hasCombinedFuel,
                        showInLegend: fuel1.length
                      },
                      {
                        name: "Бак 2",
                        data: fuel2,
                        visible: !hasCombinedFuel,
                        showInLegend: fuel2.length
                      },
                      {
                        name: "Бак 1 + Бак 2",
                        data: combinedFuel,
                        visible: hasCombinedFuel,
                        showInLegend: combinedFuel.length
                      },
                      {
                        name: "Швидкість",
                        data: speed
                      },
                      {
                        name: "Акумулятор",
                        data: v1,
                        color: '#76FF03', // Базовий колір лінії
                        tooltip: {
                          valueSuffix: ' V'
                        },
                        zones: [
                          {
                            value: 11.98, // Для рівня заряду до 20%
                            color: '#F44336'
                          },
                          {
                            value: 12.12,
                            color: '#E57373'
                          },
                          {
                            value: 12.28,
                            color: '#FFEB3B'
                          },
                          {
                            value: 12.46,
                            color: '#D4E157'
                          },
                          {
                            value: 12.7, // Для рівня заряду до 100%
                            color: '#4CAF50' // Зелений
                          }
                        ]
                      },
                      {
                        name: "Температура",
                        data: temp
                      }
                    );

                    drawChart(data, columns);
                  }, function () {
                    columns = rejectEmptyColumns(getFuelsFromRoute(data.route.stops, _fuelData));

                    drawChart(data, columns);
                  });
              }, 100);
            });
          } else {
            $element[0].querySelector('#fuel-chart').innerHTML = '';
          }
        }
      }
    });

    this.$onInit = function () {
      columnHeader = ['x', tank1, tank2, tank12, tank3, speed, temp];
    };

    this.$onDestroy = function () {
      // $rootScope.$broadcast(APP_EVENTS.car.fuelChart, {show: false});
      eventFuelChart();
    };

    this.onGoToSettings = function () {
      $state.go('vehicles.details.settings', {id: this.carId}, {reload: true});
    };

    function toggleChart (value) {
      self.showChart = value;
      if (value) {
        $element[0].classList.add('fuel-chart-container');
      } else {
        $element[0].classList.remove('fuel-chart-container');
        $element[0].querySelector('#fuel-chart').innerHTML = '';
      }
      setTimeout(MapOsmService.initMapSize, 100);
    }

    function getFuelsFromMega (id, filter, onlyCnt) {
      filter = filter || {};
      return BackendApi.getFromMega({id: id, from: DateUtils.toUnix(filter.from), to: DateUtils.toUnix(filter.to), onlyCnt: onlyCnt});
    }

    function getCarSensorsData(id, filter) {
      filter = filter || {};
      return BackendApi.getSensorsData(id, filter);
    }

    function parseFuelsFromMega (list, fuelData, settings) {
      list = list || [];

      var capacity1 = 4096 / (settings.capacity || 1);
      var capacity2 = 4096 / (settings.capacity2 || 1);

      return _.reduce(list, function (result, point) {
        var fuel1 = point.fuel1 ? (point.fuel1 / capacity1).toFixed(1) : 0;
        var fuel2 = point.fuel2 ? (point.fuel2 / capacity2).toFixed(1) : 0;


        result[0].push(moment.unix(point.timestampFrom).format('YYYY-MM-DD HH:mm'));
        result[1].push(fuel1);
        result[2].push(fuel2);
        result[3].push((+fuel1 + +fuel2).toFixed(1));
        result[4].push(point.speed);
        result[5].push(point.v1);

        return result;
      }, [[], [], [], [], [], [], []]);
    }

    function parseCarSensors (list) {
      list = list || [];

      return _.reduce(list, function (result, point) {
        result[0].push(moment.unix(point.timestampFrom).format('YYYY-MM-DD HH:mm:ss'));
        result[1].push(point.fuel1);
        result[2].push(point.fuel2);
        result[3].push((+point.fuel1 + +point.fuel2));
        result[4].push(point.speed);
        result[5].push(point.v1);
        result[6].push(point.temp);

        return result;
      }, [[], [], [], [], [], [], []]);
    }

    function parseFuel3FromMega(list, fuelData) {
      list = list || [];

      return _.reduce(list, function (result, el) {
        result[0].push(moment.unix(el.timestampFrom).format('YYYY-MM-DD HH:mm'));
        result[1].push((el.fuel3 * impulse).toFixed(1));

        fuelData.push(transWith + ': ' + moment.unix(el.timestampFrom).format('HH:mm:ss') + ', по: ' + moment.unix(el.timestampTo).format('HH:mm:ss'));

        return result;
      }, [['x'], [tank3]]);
    }

    function getFuelsFromRoute (list, fuelData) {
      return _.reduce(list, function (result, el) {
        if (el.status === 3) {
          result[0].push(moment.unix(el.timestampFrom).format('YYYY-MM-DD HH:mm'));
          result[5].push(el.maxSpeed || 0);
          result[1].push(el.fuelFrom || 0);

          if (el.hasOwnProperty('secondFuelFrom')) {
            result[2].push(el.secondFuelFrom || 0);
            result[3].push((el.fuelFrom || 0) + (el.secondFuelFrom || 0));
          }

          result[4].push(el.fuelUsage || 0);
          fuelData.push(parseFuelInfo(el));
        }
        return result;
      }, [[], [], [], [], [], []]);
    }

    function rejectEmptyColumns (list) {
      return _.reduce(list, function (result, el, pos) {
        if (_.some(el)) {
          el.unshift(columnHeader[pos]);
          result.push(el);
        }
        return result;
      }, []);
    }

    function getHiddenColumns(chart, columns) {
      if (chart) {
        var shownColumns = _.map(chart.data.shown(), function (el) { return el.id; });
        if (shownColumns.length) {
          return _.reduce(columns, function (result, el) {
            if (el[0] != 'x' && shownColumns.indexOf(el[0]) == -1) { result.push(el[0]); }
            return result;
          }, []);
        } else {
          return getDefaultColumns(columns);
        }
      } else {
        return getDefaultColumns(columns);
      }
    }

    function getDefaultColumns (columns) {
      return _.reduce(columns, function (result, el) {
        if (el[0] !== 'x' && el[0] !== tank1 && el[0] !== tank2 && el[0] !== speed) {
          result.push(el[0]);
        }

        return result;
      }, []);
    }

    function parseFuelInfo (item) {
      return fuelSettings && fuelSettings.motion ? getFuelInfo(item) : null;
    }

    function getColorBySettings (value) {
      var clr = _color.green;
      if (value >= fuelSettings.motion-1 && value <= fuelSettings.motion+1) {
        clr = _color.black;
      } else if (value > fuelSettings.motion) {
        if (fuelSettings.percent && value < (fuelSettings.motion + fuelSettings.motion*fuelSettings.percent/100)) {
          clr = _color.yellow;
        }
        clr = _color.red;
      }
      return clr;
    }

    function getPointColor (point) {
      var speedColumnIndex = getSpeedColumnIndex();

      // если не первое значение
      if (point.index > 0) {
        // если машина стоит в данный момент
        if (speedColumnIndex === null ||
          (!columns[speedColumnIndex][point.index] &&
            !columns[speedColumnIndex][point.index + 1])) {
            var pointValueRow = columns.find(function (row) { return row[0] === point.id});
            var previousValue = pointValueRow[point.index];

            // если машина стоит а расход уменьшается
            if (previousValue > point.value) {
              return _color.pink;
            }
        }
      }

      return _fuelData[point.index] && _fuelData[point.index][point.id] && _fuelData[point.index][point.id].color;
    }

    function getPointInfo (tank, indx) {
      return _fuelData[indx] && _fuelData[indx][tank] && _fuelData[indx][tank].info;
    }

    function getFuelInfo (item) {
      var fuel1 = (+item.fuelFrom - +item.fuelTo) || 0,
        fuel2 = (+item.secondFuelFrom - +item.secondFuelTo) || 0,
        color1 = getColorBySettings(fuel1 > 0 && item.fuelFrom && item.distance ? (100 * fuel1 / item.distance).toFixed(1) : 0),
        color2 = getColorBySettings(fuel2 > 0 && item.secondFuelFrom && item.distance ? (100 * fuel2 / item.distance).toFixed(1) : 0),
        color12 = getColorBySettings(fuel1 > 0 && item.fuelFrom && item.distance ? (100 * (fuel1+fuel2) / item.distance).toFixed(1) : 0),
        color3 = getColorBySettings(item.fuelUsage > 0 && item.fuelUsage && item.distance ? (100 * item.fuelUsage / item.distance).toFixed(1) : 0),
        result = {}
      ;
      result[tank1] = {
        color: color1,
        info: formatInfo(fuel1, item.distance, color1)
      };
      result[tank2] = {
        color: color2,
        info: formatInfo(fuel2, item.distance, color2)
      };
      result[tank12] = {
        color: color12,
        info: formatInfo(fuel1+fuel2, item.distance, color12)
      };
      result[tank3] = {
        color: color3,
        info: formatInfo(item.fuelUsage, item.distance, color3)
      };

      return result;
    }

    function formatInfo (fuel, distance, color) {
      var info;
      if (color == _color.green) {
        info = transUsageLess;
      } else if (color == _color.black) {
        info = transUsageNorm;
      } else if (color == _color.yellow) {
        info = transUsageNormControl;
      } else if (color == _color.red) {
        info = transUsageMore;
      }
      return transUsage + ': ' + fuel + 'л., ' + transMileage + ': ' + distance + 'км. ' + info;
    }

    function getTooltipFormat (value, ratio, tank, indx) {
      var info = getPointInfo(tank, indx);
      switch(tank) {
        case speed:
          return value + 'км.ч';
        case info:
          return value + 'л, ' + info;
        case temp:
          return value + '&deg;C';
        default:
          return value + 'л'
      }
      // return value + (speed == tank ? 'км.ч' : (info ? ('л, ' + info) : 'л'));
    }

    function getTooltipFormatFuel3 (value, ratio, tank, indx) {
      return transUsage + ': ' + value + 'л., ' + (_fuelData[indx] || '');
    }

    function drawChart (options, columns) {
      chart = ChartService.drawNew('fuel-chart', {
        time: {
          useUTC: false,
        },
        xAxis: {
          type: 'datetime',
          ordinal: false,
        },
        rangeSelector: {
            selected: 4
        },
        tooltip: {
            pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
            valueDecimals: 2,
            split: true
        },
        legend: {
          enabled: true,
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'bottom'
        },
        series: columns
      });

      /*
      var hiddenColumns = getHiddenColumns(chart, columns) || [];
      hiddenColumns.push(speed);
      chart = ChartService.draw({
        bindto: $element[0].querySelector('#fuel-chart'),
        data: {
          x: 'x',
          xFormat: '%Y-%m-%d %H:%M:%S',
          columns,
          hide: hiddenColumns,
          color: function (color, point) {
            if (typeof point == "object" && point.value && /Бак/.test(point.id)) {
              return getPointColor(point) || color;
            }

            return color;
          }
        },
        tooltip: {
          format: {
            value: getTooltipFormat
          }
        },
        axis: {
          x: {
            type: 'timeseries',
            tick: {
              format: options.showDay ? '%Y-%m-%d %H:%M:%S' : '%H:%M:%S'
            }
          }
        },
        grid: {
          x: {
            show: true
          },
          y: {
            show: true
          }
        },
        subchart: {
          show: true,
          size: {
            height: 20
          }
        },
        padding: {
          right: 20
        },
        zoom: {
          enabled: true
        }
      });*/
    }

    function drawChartBar (options) {
      ChartService.draw({
        bindto: $element[0].querySelector('#fuel-chart'),
        data: {
          x: 'x',
          xFormat: '%Y-%m-%d %H:%M',
          columns: columns,
          type: 'bar'
        },
        tooltip: {
          format: {
            value: getTooltipFormatFuel3
          }
        },
        axis: {
          x: {
            type: 'timeseries',
            tick: {
              format: options.showDay ? '%Y-%m-%d %H:%M' : '%H:%M'
            }
          }
        },
        grid: {
          x: {
            show: true
          },
          y: {
            show: true
          }
        },
        subchart: {
          show: true,
          size: {
            height: 20
          }
        },
        padding: {
          right: 20
        },
        zoom: {
          enabled: true
        }
      });
    }

    function getTrans (code) {
      var lang = Session.lang;
      return trans[lang][code];
    }

    function getCarName (id) {
      var car = _.find(Session.user.cars, function (el) {
        return el.id == id;
      }) || {};
      return car.name || id;
    }

    function loadCharts () {
      return $q.all([LoadScriptService.loadD3(), LoadScriptService.loadC3()]);
    }

    function getSpeedColumnIndex () {
      var index = null;

      for (var i = 0; i < columns.length; i++) {
        var column = columns[i];
        if (column && column.length && /Макс/.test(column[0])) {
          index = i;
        }
      }

      return index;
    }

  }

})();
