(() => {
  angular
    .module('app.reports')
    .constant('app.reports.helpers.common', {
      tableHeaderBgColor: 'dddddd',
      weekendCellBgColor: 'efefef',
      xlsxPageSetupOptions: {
        paperSize: 9,
        fitToPage: true,
        fitToHeight: 0,
        fitToWidth: 1,
        orientation: 'landscape'
      }
    });
})();
