(() => {
  angular
    .module('routeSummaryDetails')
    .component('routeSummaryDetails', {
      controller: Ctrl,
      controllerAs: 'ctrl',
      templateUrl: 'app/vehicles/details/lib/route-summary-details/tpl.html',
      bindings: {
        routeDetails: '<'
      }
    });

  Ctrl.$inject = [
    '$rootScope',
    'fuel-consumption-metering-settings',
    'fuel-consumption-metering-settings.events',
    'routeItemUtils',
    'app.fuelReport.dataBuilder',
    'app.vehicles.details.lib.fuel-calculations.fuel-calculations'
  ];

  function Ctrl($rootScope, fuelConsumptionMeteringSettings, fuelConsumptionMeteringSettingsEvents,
    routeItemUtils, fuelReportDataBuilder, fuelCalculations) {
    const self = this;
    const unsubscribe = [];

    this.$onInit = () => {
      unsubscribe.push(
        $rootScope.$on(fuelConsumptionMeteringSettingsEvents.CHANGE, update)
      );
    };

    this.$onChanges = () => {
      update();
    };

    this.$onDestroy = () => {
      while (unsubscribe.length) {
        unsubscribe.pop()();
      }
    };

    function update() {
      self.maxSpeed = routeItemUtils.getRouteMaxSpeed(self.routeDetails);

      const timePeriodDetails = fuelCalculations.getDetailsForTimePeriod(self.routeDetails);

      self.engineOperationDuration = timePeriodDetails.engineOperationDuration

      self.totalFuelUsage = Math.round(timePeriodDetails.totalFuelUsageInAllTanks * 100) / 100;

      self.averageFuelUsage = Math.round(timePeriodDetails.averageFuelUsage * 100) / 100;

      self.totalDistance = Math.round(timePeriodDetails.mileage * 100) / 100;

      self.totalInWayDuration = self.routeDetails.summary.totalWay || '';
    }
  }
})();
