(() => {
  angular
    .module('app.vehicles.details.lib.fuel-calculations')
    .constant('app.vehicles.details.lib.fuel-calculations.constants', {
      typeOfFuelLevelMeter: {
        impulseSensor: 'impulseSensor',
        fuelCalculator: 'fuelCalculator',
        fuelLevelSensor: 'fuelLevelSensor'
      }
    })
})();
