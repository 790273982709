(function () {
  "use strict";

  angular
    .module('app.vehicles')
    .service('PeriodFilters', periodFilters);

  periodFilters.$inject = [];

  /**
   * Period filters for different pages.
   */
  function periodFilters() {
    var self = this;

    /**
     * @returns {{start: Date, end: Date}}
     * @description
     * Initialize new filter for one whole day.   */
    self.init = function () {
      var mileage = new Filter(),
        report = new Filter()
      ;
      mileage.from = moment().startOf('day').set('date', 1).toDate();
      report.from = moment().startOf('day').set('date', 1).toDate();

      self.filter = {
        report: report,
        route: new Filter(),
        statistics: new Filter(),
        mileage: mileage,
        stops: new Filter()
      };
    };

    /**
     * @param name
     * @returns {Filter} Filter object.
     * @description
     * Returns filter by the route name.   */
    self.getFilter = function (name) {
      if (name in self.filter) {
        if (name === 'route' || name === 'mileage' || name === 'statistics') {
          self.filter[name].to = moment(self.filter[name].to).endOf("day").startOf("second").toDate();
        }
        return self.filter[name];
      }

      throw new Error('Bad filter name!');
    };

    self.getFullDays = function (filter) {
      var filters = [];
      var from = moment(filter.from).clone();
      var to = moment(filter.to).clone();

      var fullDays = to.diff(from, 'days');

      for (var i = 0; i <= fullDays; i++) {
        var f, t;
        if (i === 0) {
          f = from;
        } else {
          f = from.clone().add(i, 'days').startOf('day');
        }

        if (i === fullDays) {
          t = to;
        } else {
          t = f.clone().endOf('day');
        }
        filters.push({from: f.toDate(), to: t.toDate()});
      }

      return filters;
    };

    // ---
    self.init();
  }

  /**
   * @constructor
   * @description
   * Date filter.
   * Depends on moment.js
   */
  function Filter(from, to) {
    var self = this;

    self.data = {
      from: from || moment().startOf('day').toDate(),
      to: to || moment().endOf("day").startOf("second").toDate()
    };

    Object.defineProperty(self, 'from', {
      enumerable: true,
      get: function () {
        return self.data.from;
      },
      set: function (value) {
        self.data.from = value;
      }
    });

    Object.defineProperty(self, 'to', {
      enumerable: true,
      get: function () {
        return self.data.to;
      },
      set: function (value) {
        self.data.to = value;
      }
    });

    Object.defineProperty(self, 'diff', {
      enumerable: false,
      get: function () {
        return moment(self.to).diff(moment(self.from));
      }
    });
  }

  /**
   * Show all data
   * @returns {{from: Date, to: Date}|*}
   */
  Filter.prototype.show = function () {
    return this.data;
  };
})();
