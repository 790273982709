(() => {
  angular
    .module('app.tokens', [])
    .config(config)
    .run(run)

  config.$inject = [
    '$httpProvider'
  ]

  function config($httpProvider) {
    $httpProvider.interceptors.push('app.tokens.accessByTokenHttpInterceptor')
  }

  run.$inject = [
    'app.tokens.accessByTokenTransitionHook'
  ]

  function run(routerHook) {
    routerHook.init()
  }
})();
