(function () {
  "use strict";

  angular
    .module('app')
    .factory('DateUtils', DateUtils)
  ;

  DateUtils.$inject = [];

  function DateUtils() {
    var service = {};

    service.toUnix = function (date) {
      var _date = angular.copy(date || Date.now());
      return moment(_date).unix();
    };

    service.clearToDate = function (date) {
      var _date = angular.copy(date);
      return moment(_date).startOf('day');
    };

    service.joinAllDateForPeriod = function (filter) {
      filter = filter || {};
      var dates = angular.copy(filter),
        from = moment(dates.from || new Date()),
        to = moment(dates.to || new Date()),
        result = []
      ;

      while (from < to) {
        result.push(from.unix());
        from.add(1, 'days');
      }
      result.push(to.unix());

      return result.join(";");
    };

    return service;
  }

})();
