(function () {
  'use strict';

  angular
    .module('app.vehicles')
    .service('FuelReportService', FuelReportService);

  FuelReportService.$inject = [
    'Session',
    'BackendApi',
    'ResponseParser',
    'FuelUsageService',
  ];

  function FuelReportService(
    Session,
    BackendApi,
    ResponseParser,
    FuelUsageService
  ) {
    var self = this;
    self.text = '';

    self.getFuelReport = function (id, params) {
      var addrType = Session.addressType;
      var dateFormat = 'DD.MM.YYYY';
      var fromDate = moment(params.from, dateFormat).toDate();
      var toDate = moment(params.to, dateFormat).toDate();

      return BackendApi.getMileage(
        { id: id },
        { from: fromDate, to: toDate }
      )
        .then(function (dataResponse) {
          self.data = dataResponse;
          return Promise.all(
            FuelUsageService.getPeriods(fromDate, toDate, id)
          );
        })
        .then(function (listResponse) {
          self.list = _.map(listResponse, function (item) {
            return ResponseParser.parseSingleDeviceRoute(item, false, addrType);
          });
          var text = '';
          var fuels = FuelUsageService.getFuel(self.data.list, self.list);

          _.each(fuels, function (element) {
            var date = element.date;
            var totalFuelUsage = element.totalFuelUsage;
            var averageFuelUsage = element.averageFuelUsage;
            var distanceKm = element.distanceKm;
            var maxSpeedKmpH = element.maxSpeedKmpH;
            var fuelStart = element.fuelStart;
            var fuelEnd = element.fuelEnd;
            var merged = element.merged;
            var refilled = element.refilled;

            text +=
              date +
              ',' +
              id +
              ',' +
              distanceKm +
              ',' +
              totalFuelUsage +
              ',' +
              averageFuelUsage +
              ',' +
              maxSpeedKmpH +
              ',' +
              fuelStart +
              ',' +
              refilled +
              ',' +
              merged +
              ',' +
              fuelEnd +
              '\n';
          });

          return text;
        });
    };

    // TODO: Generate report with multiple car id's
    self.getFuelReportWithIds = function (params) {
      var text = '';
      var requests = [];
      var ids = _.clone(params.ids);
      ids.unshift(params.id);

      for (var id of ids) {
        requests.push(self.getFuelReport(id, params));
      };

      Promise.all(requests).then(function (reports) {
        text = reports.join();
        self.createReportFile(text, params);
      });
    };

    self.createReportFile = function (text, params) {
      var hiddenElement = document.createElement('a');
      hiddenElement.href = 'data:text/plain;charset=utf-8,' + encodeURI(text);
      hiddenElement.target = '_blank';
      hiddenElement.download =
        params.from +
        '_' +
        params.to +
        '_fuels_' +
        params.id +
        '_' +
        params.ids.join('_') +
        '.txt';
      hiddenElement.click();
    };
  }
})();
