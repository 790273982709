(function () {
  "use strict";

  angular
    .module('app.settings')
    .component('settings', {
      templateUrl: 'app/settings/settings.html',
      controller: SettingsCtrl,
      controllerAs: 'vm'
    });

  SettingsCtrl.$inject = ['$scope', '$state', 'PermissionService'];

  function SettingsCtrl($scope, $state, PermissionService) {
    $scope.$root.app.title = 'page.settings';
    // this.tabSelected = 0;
    this.tabIndex = {
      all: 1,
      zones: 2,
      users: 3,
      subscriptionFee: 4,
      tokens: 5
    };

    this.$onInit = function () {
      this.tabSelected = this.tabIndex[$state.current.name.split(".")[1]];
      this.perm = PermissionService.get();
    };
  }

})();
