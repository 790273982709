(function () {
  "use strict";

  angular
    .module('app')
    .component('colorPickerH', {
      require: {
        ngModel: '^ngModel'
      },
      bindings: {
        onChange: '='
      },
      templateUrl: 'app/common/color-picker-h/color-picker-h.html',
      controllerAs: 'vm',
      controller: ColorPickerH
    });

  ColorPickerH.$inject = [];

  function ColorPickerH() {
    var vm = this;

    vm.colors = [
      '#F44336',
      '#4CAF50',
      '#03A9F4',
      '#9C27B0'
    ];

    vm.$onInit = function () {
      vm.ngModel.$render = function () {
        vm.model = vm.ngModel.$viewValue;
      };
    };

    vm.selectColor = function (color) {
      vm.model = color;
      vm.ngModel.$setViewValue(color);
      if (vm.onChange) {
        vm.onChange(color);
      }
    };
  }
})();
