(() => {
  angular
    .module('fuel-consumption-metering-settings')
    .service('fuel-consumption-metering-settings', fuelConsumptionMeteringSettings);

  fuelConsumptionMeteringSettings.$inject = [
    '$rootScope',
    'fuel-consumption-metering-settings.events',
    'routeItemUtils'
  ];

  function fuelConsumptionMeteringSettings($rootScope, events, routeItemUtils) {
    let routeEventsMap = {};
    let idle = 1;

    const api = {
      eventsState: {},
      reset,
      setRouteDetails,
      onSettingsForEventChanged,
      onSettingsForDayChanged,
      toggleUsageOfAllControversialEvents,
      getExcludedEvents,
      areAllEventsExcluded,
      isDayWithControversialFuelConsumption,
      isEventWithControversialFuelLevel,
      isExcludedEventWithControversialFuelLevel,
      doesDayHavePartialSelectedEvents,
      areAllEventsSelected
    };

    return api;

    function reset() {
      api.eventsState = {};
    }

    function setRouteDetails(routeDetails) {
      const previousEventsState = JSON.parse(JSON.stringify(api.eventsState));
      api.eventsState = {};

      routeEventsMap = {};

      const setsOfEventsByDate = {};
      const startDateToTimestampMap = {};
      routeDetails.stops.forEach((event) => {
        const fromDate = event.fromDate;
        if (!Array.isArray(setsOfEventsByDate[fromDate])) {
          setsOfEventsByDate[fromDate] = [];
          startDateToTimestampMap[fromDate] = event.timestampFrom;
        }
        setsOfEventsByDate[fromDate].push(event);

        routeEventsMap[event.timestampFrom] = event;
      });

      idle = (routeDetails.summary.fuelSettings || {}).idle;

      const setsOfEventsWithControversialFuelLevel = Object.values(setsOfEventsByDate)
        .filter((setOfEvents) => setOfEvents.some((obj) =>
          isEventWithControversialFuelLevel(obj, routeDetails.summary))
        );

      setsOfEventsWithControversialFuelLevel.forEach((setOfEvents) => {
        const dayId = setOfEvents[0].fromDate;

        const hasPreviousStateOfDay = typeof previousEventsState[dayId] !== 'undefined';

        let previousStateOfDay;
        if (hasPreviousStateOfDay) {
          previousStateOfDay = previousEventsState[dayId];
        } else {
          previousStateOfDay = {
            isSelected: false,
            items: {}
          };
        }

        api.eventsState[dayId] = {
          isSelected: previousStateOfDay.isSelected,
          hasPartialSelectedEvents: false,
          items: {}
        };

        setOfEvents.forEach((event, index) => {
          event.isFirstEventOfDayWithControversialFuelLevel = index === 0;
          event.dayId = dayId;

          const hasFuelDrain = routeItemUtils.hasFuelDrain(event, {idle});
          const hasRefueling = routeItemUtils.hasRefueling(event);

          let isEventSelected;
          if (!hasPreviousStateOfDay) {
            if (hasRefueling && !hasFuelDrain) {
              isEventSelected = false;
            } else {
              isEventSelected = false;
            }
          } else {
            isEventSelected = !!previousStateOfDay.items[event.timestampFrom];
          }

          if (isEventWithControversialFuelLevel(event, routeDetails.summary)) {
            api.eventsState[dayId].items[event.timestampFrom] = isEventSelected;
            event.isEventWithControversialFuelLevel = true;
          } else {
            event.isEventWithControversialFuelLevel = false;
          }
        });

        api.eventsState[dayId].hasPartialSelectedEvents = doesDayHavePartialSelectedEvents(dayId);
      });

      notifyOnChange();
    }

    function onSettingsForEventChanged(routeEvent) {
      const dayId = routeEvent.dayId;
      const dayState = api.eventsState[dayId];

      switch (true) {
        case areAllEventsForDayUnselected(dayId):
          dayState.isSelected = false;
          break;
        case areAllEventsForDaySelected(dayId):
          dayState.isSelected = true;
          break;
        default:
          dayState.isSelected = true;
      }

      dayState.hasPartialSelectedEvents = doesDayHavePartialSelectedEvents(dayId);

      notifyOnChange();
    }

    function onSettingsForDayChanged(dayId) {
      const dayState = api.eventsState[dayId];

      Object.keys(dayState.items).forEach((key) => {
        dayState.items[key] = dayState.isSelected;
      });

      dayState.hasPartialSelectedEvents = false;

      notifyOnChange();
    }

    function toggleUsageOfAllControversialEvents(shouldExcludeAllControversialEvent) {
      Object.keys(api.eventsState).forEach((key) => {
        const dayState = api.eventsState[key];
        dayState.isSelected = shouldExcludeAllControversialEvent;
        dayState.hasPartialSelectedEvents = false;

        Object.keys(dayState.items).forEach((key) => {
          dayState.items[key] = shouldExcludeAllControversialEvent;
        });
      });

      notifyOnChange();
    }

    function areAllEventsExcluded() {
      return Object.values(api.eventsState)
        .every((record) => {
          return Object.values(record.items).every((v) => v === true);
        });
    }

    function getExcludedEvents() {
      return Object.values(api.eventsState)
        .reduce((acc, record) => {
          Object.keys(record.items)
            .forEach((eventTimestamp) => {
              if (record.items[eventTimestamp]) {
                acc.push(routeEventsMap[eventTimestamp]);
              }
            });
          return acc;
        }, []);
    }

    function isDayWithControversialFuelConsumption(dayId) {
      return typeof api.eventsState[dayId] !== 'undefined';
    }

    function isEventWithControversialFuelLevel(routeEvent, routeSummaryDetails) {
      const idle = routeItemUtils.toNumber((routeSummaryDetails.fuelSettings || {}).idle);

      const hasFuelDrain = routeItemUtils.hasFuelDrain(routeEvent, {idle});
      const hasRefueling = routeItemUtils.hasRefueling(routeEvent);

      return hasRefueling || hasFuelDrain;
    }

    function isExcludedEventWithControversialFuelLevel(routeItem) {
      return getExcludedEvents()
        .some((obj) => obj.timestampFrom === routeItem.timestampFrom);
    }

    function doesDayHavePartialSelectedEvents(dayId) {
      return !(areAllEventsForDayUnselected(dayId) || areAllEventsForDaySelected(dayId));
    }

    function areAllEventsForDayUnselected(dayId) {
      return Object.values(api.eventsState[dayId].items).every((v) => v === false);
    }

    function areAllEventsForDaySelected(dayId) {
      return Object.values(api.eventsState[dayId].items).every((v) => v === true);
    }

    function areAllEventsSelected() {
      return Object.keys(api.eventsState).every(areAllEventsForDaySelected);
    }

    function notifyOnChange() {
      $rootScope.$emit(events.CHANGE);
    }
  }
})();
