(function () {
  "use strict";

  angular
    .module('app.logistics', [])
    .config(LogisticsRouteConfig);

  LogisticsRouteConfig.$inject = [
    '$stateProvider'
  ];
  function LogisticsRouteConfig($stateProvider) {
    $stateProvider
      .state('logistics', {
        parent: 'main',
        redirectTo: 'logistics.routes',
        component: 'logistics',
        resolve: {}
      })
      .state('logistics.routes', {
        parent: 'logistics',
        url: '/logistics/routes',
        views: {
          routes: 'logisticsRoutes'
        },
        resolve: {
          routes: ['BackendApi', '$q', function (BackendApi, $q) {
            return BackendApi.getRoutes();
          }],
          cars: ['BackendApi', 'Session', function (BackendApi, Session) {
            Session.groupId = 0;
            return BackendApi.getCarByGroup({id: Session.groupId}).then(function (result) {
              return result.cars;
            });
          }]
        }
      })
      .state('logistics.createRoute', {
        parent: 'logistics',
        url: '/logistics/route',
        views: {
          createRoute: 'logisticsCreateRoute'
        },
        resolve: {
          cars: ['$q', 'BackendApi', 'Session', function ($q, BackendApi, Session) {
            Session.groupId = 0;
            return $q(function (resolve) {
              BackendApi.getCarByGroup({id: Session.groupId}).then(function (groupInfo) {
                BackendApi.getCarsLogisticData({cars: groupInfo.cars}).then(function (carsLogisticData) {

                  var cars = _.map(groupInfo.cars, function (car) {
                    var carLogisticData = _.find(carsLogisticData, function (item) {
                      return +item.id === +car.id;
                    });
                    if (carLogisticData) {
                      car.startZoneId = carLogisticData.startZoneId;
                      car.endZoneId = carLogisticData.endZoneId;
                      car.cargoCapacity = carLogisticData.cargoCapacity ? JSON.parse(carLogisticData.cargoCapacity) : null;

                      if (car.cargoCapacity) {
                        car.cargoCapacityString = (car.cargoCapacity.carryingCapacity || 0) + ' кг. ' + (car.cargoCapacity.amount || 0) + ' м3. ';
                      }
                    }
                    return car;
                  });
                  resolve(cars);
                });
              });
            });
          }]
        }
      })
      .state('logistics.editRoute', {
        parent: 'logistics',
        url: '/logistics/route/:id?clone',
        views: {
          editRoute: 'logisticsCreateRoute'
        },
        resolve: {
          route: ['BackendApi', '$stateParams', '$q', function (BackendApi, $stateParams, $q) {
            return BackendApi.getRoute({id: $stateParams.id});
          }],
          cars: ['$q', 'BackendApi', 'Session', function ($q, BackendApi, Session) {
            Session.groupId = 0;
            return $q(function (resolve) {
              BackendApi.getCarByGroup({id: Session.groupId}).then(function (groupInfo) {
                BackendApi.getCarsLogisticData({cars: groupInfo.cars}).then(function (carsLogisticData) {

                  var cars = _.map(groupInfo.cars, function (car) {
                    var carLogisticData = _.find(carsLogisticData, function (item) {
                      return +item.id === +car.id;
                    });
                    if (carLogisticData) {
                      car.startZoneId = carLogisticData.startZoneId;
                      car.endZoneId = carLogisticData.endZoneId;
                      car.cargoCapacity = carLogisticData.cargoCapacity ? JSON.parse(carLogisticData.cargoCapacity) : null;

                      if (car.cargoCapacity) {
                        car.cargoCapacityString = (car.cargoCapacity.carryingCapacity || 0) + ' кг. ' + (car.cargoCapacity.amount || 0) + ' м3. ';
                      }
                    }
                    return car;
                  });
                  resolve(cars);
                });
              });
            });
          }]
        }
      })
      .state('logistics.viewRoute', {
        parent: 'logistics',
        url: '/logistics/routes/:id',
        views: {
          viewRoute: 'logisticsViewRoute'
        },
        resolve: {
          route: ['BackendApi', '$stateParams', '$q', function (BackendApi, $stateParams, $q) {
            return BackendApi.getRoute({id: $stateParams.id});
          }],
          cars: ['BackendApi', 'Session', function (BackendApi, Session) {
            Session.groupId = 0;
            return BackendApi.getCarByGroup({id: Session.groupId}).then(function (result) {
              return result.cars;
            });
          }]
        }
      })
      .state('logistics.viewRouteForToken', {
        parent: 'logistics',
        views: {
          viewRoute: 'viewRouteForToken'
        },
      })
    ;
  }
})();
