(() => {
  angular
    .module('app.reports')
    .constant('app.reports.converters.csv', createConverter());

  function createConverter() {
    return {
      generate
    };

    /**
     * @param {string[][]} rows
     */
    function generate(rows) {
      const escapeQuotes = (v) => `${v}`.replace(/"/g, '""');
      const arrayToString = (arr) => arr.map((v) => `"${escapeQuotes(v)}"`).join(',');

      return rows.map((row) => arrayToString(row)).join('\n');
    }
  }
})();
