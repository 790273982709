(() => {
  angular
    .module('app.tokens')
    .service('app.tokens.accessByTokenTransitionHook', hook);

  hook.$inject = [
    '$transitions',
    '$state',
    '$location',
    'app.tokens.accessByToken',
    '$q',
    '$timeout',
    '$stateParams'
  ];

  function hook($transitions, $state, $location, accessByToken, $q, $timeout, $stateParams) {
    return {
      init
    };

    function init() {
      $transitions.onBefore(
        {
          to: (state) => {
            const skippedStateNames = [
              'login',
              'logout'
            ];
            return !skippedStateNames.includes(state.name);
          }
        },
        (transition) => {
          if (!accessByToken.isAccessByToken()) {
            return;
          }

          const targetState = transition.to()
          return accessByToken.init()
            .then(() => {
              $location
                .path('/')
                .search({
                  t: accessByToken.tokenData.id
                })
                .replace();

              const allowedRoutes = [
                'vehicles.all',
                'logistics.viewRouteForToken'
              ]

              if (allowedRoutes.includes(targetState.name)) {
                return
              }

              if (accessByToken.tokenData.isUsedForForwarder) {
                return $state.target('logistics.viewRouteForToken')
              }

              return $state.target('vehicles.all')
            })
            .catch((e) => {
              return $state.target('login', undefined, {
                location: 'replace'
              })
            });
        }
      );
    }
  }
})();
