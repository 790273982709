(function () {
  "use strict";

  angular
    .module('app')
    .service('FuelService', FuelService);

  FuelService.$inject = ['$q', 'BackendApi'];

  function FuelService($q, BackendApi) {
    var self = this, cars = {};

    this.getFuel = function(id) {
      return $q(function(resolve) {
        if (cars.hasOwnProperty(id)) {
          resolve(cars[id]);
        } else {
          BackendApi.getCarNow(id)
            .then(function (result) {
              if (result && result.length) {
                self.setFuel(result[0]);
                resolve(result[0].fuel);
              }
            });
        }
      });
    };

    this.setFuels = function(list) {
      list = list || [];
      cars = _.reduce(list, function (result, el) {
        result[el.id] = el.fuel;
        return result;
      }, {});
    };

    this.setFuel = function(item) {
      if (item && item.id) {
        cars[item.id] = item.fuel;
      }
    };
  }

})();
