(() => {
  angular
    .module('app.settings')
    .component('telegramUserSettings', {
      controller: Ctrl,
      controllerAs: 'ctrl',
      templateUrl: 'app/settings/global-notification-settings/telegram/telegram-user-settings/tpl.html',
      bindings: {}
    });

  Ctrl.$inject = [
    'globalNotificationSettings.api',
    'VehicleSettingsToast'
  ];

  function Ctrl(api, toast) {
    const self = this;
    this.userSettings = [];
    this.currentlySelectedTelegramUserIds = [];

    this.isUpdatingData = false;

    this.$onInit = () => {
      fetchSettings();
    };

    this.onUserListClosed = () => {
      const previousStateForNotificationSettings = this.userSettings
        .reduce((acc, obj) => {
          acc[obj.telegramUserId] = obj.shouldReceiveNotifications;
          return acc;
        }, {});

      const newStateForNotificationSettings = this.userSettings
        .reduce((acc, obj) => {
          acc[obj.telegramUserId] = this.currentlySelectedTelegramUserIds.includes(obj.telegramUserId);
          return acc;
        }, {});

      const hasChanges = Object.keys(newStateForNotificationSettings)
        .some((key) => newStateForNotificationSettings[key] !== previousStateForNotificationSettings[key]);
      if (!hasChanges) {
        return;
      }

      const dataToSave = Object.keys(newStateForNotificationSettings)
        .map((telegramUserId) => ({
          telegramUserId,
          shouldReceiveNotifications: newStateForNotificationSettings[telegramUserId]
        }));

      updateSettings(dataToSave);
    };

    function fetchSettings() {
      api.getGlobalTelegramUserSettings()
        .then((userSettings) => {
          self.userSettings = userSettings;
          self.currentlySelectedTelegramUserIds = userSettings
            .filter((obj) => obj.shouldReceiveNotifications)
            .map((obj) => obj.telegramUserId);
        });
    }

    function updateSettings(newSettings) {
      self.isUpdatingData = true;
      api.updateGlobalTelegramUserSettings(newSettings)
        .then(() => {
          newSettings.forEach((obj) => {
            const userData = self.userSettings
              .find((data) => data.telegramUserId === obj.telegramUserId);
            if (!userData) {
              return;
            }

            userData.shouldReceiveNotifications = obj.shouldReceiveNotifications;
          });

          toast.successfulSaving('notifications.successUpdatingOfGlobalUserSettings');
        })
        .catch(() => {
          self.currentlySelectedTelegramUserIds = self.userSettings
            .filter((obj) => obj.shouldReceiveNotifications)
            .map((obj) => obj.telegramUserId);

          toast.successfulSaving('notifications.failedUpdatingOfGlobalUserSettings');
        })
        .finally(() => {
          self.isUpdatingData = false;
        });
    }
  }
})();
