(() => {
  angular
    .module('app.vehicles')
    .factory('withFuelDrainSettings', withFuelDrainSettings);

  withFuelDrainSettings.$inject = [
    '$rootScope',
    'routeItemUtils',
    'withFuelDrainSettingsEvents',
    'fuel-drain-metering-mode-switch.events'
  ];

  function withFuelDrainSettings($rootScope, routeItemUtils,
    events, fuelDrainMeteringModeSwitchEvents, fuelDrainCheckboxEvents) {
    const unsubscribe = [];
    let ctrl;
    let itemsMap;
    let routeDetails;

    return {
      create,
      onRouteDetailsChanged,
      getSelectedEventsWithFuelDrain,
      getActualFuelDrain,
      getTotalFuelDrain,
      destroy
    };

    function create(ctrlInstance) {
      ctrl = ctrlInstance;

      unsubscribe.push(
        $rootScope.$on(fuelDrainMeteringModeSwitchEvents.fuelDrainMeteringModeChanged, (_, args) => {
          onFuelDrainMeteringModeChanged(args.isActive);
        })
      );

      ctrl.shouldShowFuelDrainSettings = false;
      ctrl.hasDatesWithFuelDrain = false;
      ctrl.fuelDrainCheckboxes = {};
      ctrl.totalDrained = 0;

      ctrl.onCheckboxForFuelDrainChanged = onCheckboxForFuelDrainChanged;
      ctrl.onCheckboxForFirstEventOfDateWithFuelDrainChanged = onCheckboxForFirstEventOfDateWithFuelDrainChanged;
    }

    function onRouteDetailsChanged(newRouteDetails) {
      routeDetails = newRouteDetails;

      ctrl.fuelDrainCheckboxes = {};
      ctrl.totalDrained = getTotalFuelDrain();

      itemsMap = {};

      const setsOfEventsByDate = {};
      routeDetails.stops.forEach((item) => {
        const fromDate = item.fromDate;
        if (!Array.isArray(setsOfEventsByDate[fromDate])) {
          setsOfEventsByDate[fromDate] = [];
        }
        setsOfEventsByDate[fromDate].push(item);

        itemsMap[item.timestampFrom] = item;
      });

      const idle = (routeDetails.summary.fuelSettings || {}).idle;

      const isEventWithControversialFuelLevel = (routeItem) =>
        routeItemUtils.hasFuelDrain(routeItem, {idle})
        ||
        routeItemUtils.hasRefueling(routeItem);

      const setsOfEventsWithControversialFuelLevel = Object.values(setsOfEventsByDate)
        .filter((setOfEvents) => {
          return setOfEvents.some(isEventWithControversialFuelLevel);
        });

      setsOfEventsWithControversialFuelLevel.forEach((setOfEvents) => {
        let firstEventTimestamp;
        setOfEvents.forEach((routeItem, index) => {
          if (index === 0) {
            // routeItem.isFirstEventOfDateWithFuelDrain = true;
            // firstEventTimestamp = routeItem.timestampFrom;

            // ctrl.fuelDrainCheckboxes[firstEventTimestamp] = {
            //   isChecked: false,
            //   items: {}
            // };
          }

          if (isEventWithControversialFuelLevel(routeItem)) {
            // ctrl.fuelDrainCheckboxes[firstEventTimestamp].items[routeItem.timestampFrom] = false;
            // routeItem.parentFuelDrainId = firstEventTimestamp;
            // routeItem.isEventWithControversialFuelLevel = true;
          } else {
            // routeItem.isEventWithControversialFuelLevel = false;
          }
        });
      });

      ctrl.hasDatesWithFuelDrain = setsOfEventsWithControversialFuelLevel.length > 0;
      if (!ctrl.hasDatesWithFuelDrain) {
        ctrl.shouldShowFuelDrainAccountingSettings = false;
        ctrl.fuelDrainCheckboxes = {};
      }

      notifyThatStateOfFuelDrainCheckboxesChanged();
    }

    function onFuelDrainMeteringModeChanged(shouldShowFuelDrainSettings) {
      ctrl.shouldShowFuelDrainSettings = shouldShowFuelDrainSettings;

      if (!shouldShowFuelDrainSettings) {
        Object.keys(ctrl.fuelDrainCheckboxes).forEach((key) => {
          const record = ctrl.fuelDrainCheckboxes[key];
          record.isChecked = false;

          Object.keys(record.items).forEach((key) => {
            record.items[key] = false;
          });
        });
      }

      notifyThatStateOfFuelDrainCheckboxesChanged();
    }

    function onCheckboxForFirstEventOfDateWithFuelDrainChanged(item) {
      // const record = ctrl.fuelDrainCheckboxes[item.timestampFrom];
      // Object.keys(record.items).forEach((key) => {
      //   record.items[key] = record.isChecked;
      // });
      //
      // notifyThatStateOfFuelDrainCheckboxesChanged();
    }

    function onCheckboxForFuelDrainChanged(item) {
      // const parentFuelDrainId = item.parentFuelDrainId;
      //
      // const areAllFuelDrainsForDateUnchecked =
      //   Object.values(ctrl.fuelDrainCheckboxes[parentFuelDrainId].items)
      //     .every((v) => v === false);
      //
      // const areAllFuelDrainsForDateChecked =
      //   Object.values(ctrl.fuelDrainCheckboxes[parentFuelDrainId].items)
      //     .every((v) => v === true);
      //
      // const parentFuelDrainState = ctrl.fuelDrainCheckboxes[parentFuelDrainId];
      //
      // itemsMap[parentFuelDrainId].hasPartialCheckedFuelDrains = false;
      // switch (true) {
      //   case areAllFuelDrainsForDateUnchecked:
      //     parentFuelDrainState.isChecked = false;
      //     break;
      //   case areAllFuelDrainsForDateChecked:
      //     parentFuelDrainState.isChecked = true;
      //     break;
      //   default:
      //     parentFuelDrainState.isChecked = true;
      //     itemsMap[parentFuelDrainId].hasPartialCheckedFuelDrains = true;
      // }
      //
      // notifyThatStateOfFuelDrainCheckboxesChanged();
    }

    function notifyThatStateOfFuelDrainCheckboxesChanged() {
      $rootScope.$emit(events.stateOfFuelDrainCheckboxesChanged, checkIfAllFuelDrainCheckboxesSelected());
    }

    function destroy() {
      while (unsubscribe.length) {
        unsubscribe.pop()();
      }
    }

    function checkIfAllFuelDrainCheckboxesSelected() {
      return Object.values(ctrl.fuelDrainCheckboxes)
        .every((record) => {
          return Object.values(record.items).every((v) => v === true);
        });
    }

    function getSelectedEventsWithFuelDrain() {
      return Object.values(ctrl.fuelDrainCheckboxes)
        .reduce((acc, record) => {
          Object.keys(record.items)
            .forEach((eventTimestamp) => {
              if (record.items[eventTimestamp]) {
                acc.push(itemsMap[eventTimestamp]);
              }
            });
          return acc;
        }, []);
    }

    function getTotalFuelDrain() {
      return routeDetails.summary.totalDrained || 0;
    }

    function getActualFuelDrain() {
      const idle = (routeDetails.summary.fuelSettings || {}).idle;

      return getSelectedEventsWithFuelDrain()
        .map((routeItem) => {
          return routeItemUtils.getFuelDrainDetails(routeItem, {idle});
        })
        .reduce((acc, obj) => acc + obj.total, 0);
    }
  }
})();
