(function () {
  "use strict";

  angular
    .module('app.vehicles')
    .component('vehiclesMileage', {
      bindings: {
        data: '<'
      },
      templateUrl: 'app/vehicles/details/mileage/vehicles.mileage.html',
      controller: VehiclesMileageCtrl,
      controllerAs: 'vm'
    });

  VehiclesMileageCtrl.$inject = ['$scope', '$log', '$filter', 'Session', 'ResponseParser', '$translate',
    'BackendApi', 'PeriodFilters', 'MapOsmService', 'APP_EVENTS', 'CarService', 'FuelUsageService',
    '$rootScope', 'app.reports.exportTypes', 'app.reports.events', 'app.vehicles.mileage.reportSaver'];

  function VehiclesMileageCtrl($scope, $log, $filter, Session, ResponseParser, $translate,
    BackendApi, PeriodFilters, MapOsmService, APP_EVENTS, CarService, FuelUsageService,
    $rootScope, exportTypes, exportBtnEvents, reportSaver) {
    var self = this,
      car = {id: $scope.$parent.$ctrl.carId}
    ;
    $scope.$root.app.title = 'page.mileage';
    this.tmp = {
      id: $scope.$parent.$ctrl.carId
    };

    self.fuelReport = {
      data: []
    };

    this.exportMenuItems = [
      'fuelCSV',
      'separator',
      {
        type: exportTypes.toCSV,
        getTitle: () => {
          return $translate.instant('reports.downloadEntityAs', {
            entityName: $translate.instant('page.mileage'),
            fileType: 'CSV'
          })
        }
      },
      {
        type: exportTypes.toXLSX,
        getTitle: () => {
          return $translate.instant('reports.downloadEntityAs', {
            entityName: $translate.instant('page.mileage'),
            fileType: 'XLSX'
          })
        }
      },
      {
        type: exportTypes.toPDF,
        getTitle: () => {
          return $translate.instant('reports.downloadEntityAs', {
            entityName: $translate.instant('page.mileage'),
            fileType: 'PDF'
          })
        }
      }
    ];

    const unsubscribe = [];

    this.shouldShowPeriodsOfDay = false;

    self.$onInit = function () {
      unsubscribe.push($rootScope.$on(exportBtnEvents.export, (event, params) => {
        this.onExportBtnPressed(params.type);
      }));

      $scope.$emit(APP_EVENTS.ui.tabSelected, 'mileage');
      MapOsmService.clear();
      self.addrType = Session.addressType;
      self.filter = PeriodFilters.getFilter('mileage');
      self.intervals = CarService.getIntervals('string');
      if (self.data.list.length) {
        bindLoader(function () {
          return self.getList().then(function () {
            self.getDaysFromIntervals();
          });
        });
      }
    };

    this.$onDestroy = function () {
      while (unsubscribe.length) {
        unsubscribe.pop()();
      }
    }

    this.onExportBtnPressed = (exportType) => {
      const currentVehicle = Session.user.cars.filter((vehicle) => `${vehicle.id}` === car.id)[0];

      let rows = self.fuelReport.data;
      if (!self.shouldShowPeriodsOfDay) {
        rows = rows.map((row) => {
          const newRow = [].concat(row);
          newRow.splice(1, 3);
          return newRow;
        });
      }

      // in order to find out the fuel sensor type
      BackendApi.getStops(car.id, {
        from: Date.now(),
        to: Date.now()
      }).then((resp) => {
        const dataForReport = {
          id: car.id,
          vehicleNumber: currentVehicle.name,
          rows,
          summary: Object.assign(
            {},
            {
              totalFuelUsage: parseFloat(self.totalFuelUsage),
              totalEngineWork: parseFloat(self.totalEngineWork)
            },
            resp.summary
          )
        };

        reportSaver.save(exportType, dataForReport);
      });
    };

    self.getReportFileName = function () {
      if (!self.data.list.length) {
        return $filter('translate')('car.noMileage');
      }

      var carName = _.find(Session.user.cars, function (car) {
        return car.id === self.tmp.id;
      }).name;
      var from = _.first(self.data.list).date;
      var to = _.last(self.data.list).date;

      return carName + '_distance_' + from + '_' + to;
    };

    self.getList = function () {
      return Promise.all(FuelUsageService.getPeriods(self.filter.from, self.filter.to, self.tmp.id)).then(function (response) {
        self.list = _.map(response, function (item) {
          return ResponseParser.parseSingleDeviceRoute(item, true, self.addrType);
        });
        self.fuels = FuelUsageService.getFuel(self.data.list, self.list);
        self.fuelReport.name = self.getReportFileName();
        self.totalFuelUsage = self.getTotal('totalFuelUsage');
        self.totalAllDay = self.getTotal('distanceKm');
        self.totalEngineWork = $filter('formatDuration')(self.getTotal('engine'));
        $scope.$apply();
      });
    };

    self.getFuelReportHeader = function () {
      return [
        $filter('translate')('global.date'),
        self.intervals.night,
        self.intervals.day,
        self.intervals.evening,
        '00:00-24:00',
        $filter('translate')('car.fuelConsumption'),
        $filter('translate')('car.averageFuelUsage'),
        $filter('translate')('car.engineWork'),
        $filter('translate')('car.maxMile')
      ];
    };
    self.getDaysFromIntervals = function () {
      self.totalDay = 0;
      self.totalEvening = 0;
      self.totalNight = 0;
      self.averageTotalFuelUsage = 0;
      self.fuelReport.data = [];
      self.fuelReport.data.push(self.getFuelReportHeader());

      _.each(self.data.list, function (item, index) {
        self.totalDay = (Number(self.totalDay) + Number(self.data.list[index].day)).toFixed(1);
        self.totalEvening = (Number(self.totalEvening) + Number(self.data.list[index].evening)).toFixed(1);
        self.totalNight = (Number(self.totalNight) + Number(self.data.list[index].night)).toFixed(1);
        var bodyInfo = [
          item.date,
          Number(self.data.list[index].night),
          Number(self.data.list[index].day),
          Number(self.data.list[index].evening),
          Number(self.fuels[item.date].distanceKm),
          Number(self.fuels[item.date].totalFuelUsage),
          Number(self.fuels[item.date].averageFuelUsage),
          $filter('formatDuration')(self.fuels[item.date].engine),
          item.maxSpeed
        ];
        self.fuelReport.data.push(bodyInfo);
        if (index === (self.data.list.length - 1)) {
          if (self.totalAllDay > 0) {
            self.averageTotalFuelUsage = ((self.totalFuelUsage / self.totalAllDay) * 100).toFixed(1);
          } else {
            self.averageTotalFuelUsage = '0.0';
          }

          var footerInfo = [
            $filter('translate')('car.total'),
            Number(self.totalNight),
            Number(self.totalDay),
            Number(self.totalEvening),
            Number(self.totalAllDay),
            Number(self.totalFuelUsage),
            Number(self.averageTotalFuelUsage),
            self.totalEngineWork,
            self.data.total.maxSpeed
          ];
          self.fuelReport.data.push(footerInfo);
          $scope.$apply();
        }
      });
    };

    self.getTotal = function (field) {
      var total = 0;
      _.each(self.fuels, function (item) {
        total += Number(item[field]);
      });

      return total.toFixed(1);
    };

    self.parseList = function (list) {
      return _.map(list, function (item) {
        return ResponseParser.parseSingleDeviceRoute(item, true, self.addrType);
      });
    };

    self.updateFilter = function (property) {
      $log.log(property, self.filter);
    };

    self.applyFilter = function () {
      self.filter.to = moment(self.filter.to).endOf("day").startOf("second").toDate();
      $log.log('applyFilter', self.filter);
      bindLoader(function () {
        return BackendApi.getMileage(car, self.filter)
          .then(function (result) {
            self.data = result;

            return !!result.list.length;
          }).then(function (hasDataList) {
            if (hasDataList) {
              return self.getList();
            }
          }).then(function () {
            if (self.data.list.length) {
              self.getDaysFromIntervals();
            }
          });
      });
    };

    self.onSetFilter = function (type) {
      var filter = CarService.setFormFilter(this.filter, type);
      this.filter.from = filter.from;
      this.filter.to = filter.to;
      this.applyFilter();
    };

    function bindLoader(func) {
      self.showLoader = true;
      func()
        .finally(function () {
          self.showLoader = false;
        });
    }

  }
})();
