(() => {
  angular
    .module('app.vehicles')
    .component('usageOfAllControversialEventsToggle', {
      controller: Ctrl,
      controllerAs: 'ctrl',
      templateUrl: 'app/vehicles/details/lib/fuel-consumption-metering-settings/usage-of-all-controversial-events-toggle/tpl.html'
    });

  Ctrl.$inject = [
    '$rootScope',
    'fuel-consumption-metering-settings',
    'fuel-consumption-metering-settings.events'
  ];

  function Ctrl($rootScope, fuelConsumptionMeteringSettings, events) {
    const self = this;
    const unsubscribe = [];

    this.isChecked = fuelConsumptionMeteringSettings.areAllEventsExcluded();

    this.$onInit = () => {
      unsubscribe.push(
        $rootScope.$on(events.CHANGE, onFuelConsumptionMeteringSettingsChanged)
      );
    };

    this.$onDestroy = () => {
      while (unsubscribe.length) {
        unsubscribe.pop()();
      }
    };

    this.onChanged = () => {
      fuelConsumptionMeteringSettings.toggleUsageOfAllControversialEvents(self.isChecked);
    };

    function onFuelConsumptionMeteringSettingsChanged() {
      self.isChecked = fuelConsumptionMeteringSettings.areAllEventsExcluded();
    }
  }
})();
