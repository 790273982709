(() => {
  angular
    .module('app.tokens')
    .service('app.tokens.dialogs.removal-confirmation-dialog', api)

  api.$inject = [
    '$mdDialog',
    '$translate'
  ]

  function api($mdDialog, $translate) {
    return {
      show
    }

    function show(token) {
      const tokenDetails = token.name.length ? `"${token.name}" (${token.id})` : `${token.id}`

      const textContent = $translate.instant('settings.tokens.confirmRemovalDialogTextContent', {
        tokenDetails
      })
      const dialog = $mdDialog.confirm()
        .title($translate.instant('settings.tokens.confirmRemovalDialogTitle'))
        .textContent(textContent)
        .ok($translate.instant('settings.tokens.confirmRemovalDialogConfirmBtnTitle'))
        .cancel($translate.instant('settings.tokens.confirmRemovalDialogCancelBtnTitle'));

      return $mdDialog.show(dialog)
    }
  }
})();
