(() => {
  angular
    .module('app.notificationWatcher')
    .service('notificationWatcher', notificationWatcher);

  notificationWatcher.$inject = [
    '$rootScope',
    '$q',
    'AUTH_EVENTS',
    'Session',
    'notificationUi'
  ];

  function notificationWatcher($rootScope, $q, authEvents, session, ui) {
    let isStarted = false;
    let socket = null;

    return {
      start
    };

    function start() {
      if (isStarted) {
        return;
      }
      isStarted = true;

      $rootScope.$on(authEvents.loginSuccess, onLogin);
      $rootScope.$on(authEvents.logoutSuccess, onLogout);
    }

    function onLogin() {
      startWebSocketAndLogin();
    }

    function onLogout() {
      clearWebSocket();
    }

    function startWebSocketAndLogin() {
      if (!!socket) {
        return;
      }

      const sendLoginMsg = () => {
        socket.send(JSON.stringify({
          type: 'login',
          payload: {
            sessionId: session.user.session
          }
        }));
      };

      const addEventListenersForLoginProcess = () => {
        socket.addEventListener('open', onOpenDuringLogin);
        socket.addEventListener('close', onCloseDuringLogin);
        socket.addEventListener('message', onMessageDuringLogin);
        socket.addEventListener('error', onErrorDuringLogin);
      };

      const removeEventListenersForLoginProcess = () => {
        socket.removeEventListener('open', onOpenDuringLogin);
        socket.removeEventListener('close', onCloseDuringLogin);
        socket.removeEventListener('message', onMessageDuringLogin);
        socket.removeEventListener('error', onErrorDuringLogin);
      };

      const onErrorDuringLogin = () => {
        removeEventListenersForLoginProcess();
        clearWebSocket();
      };

      const onCloseDuringLogin = () => {
        removeEventListenersForLoginProcess();
        clearWebSocket();
      };

      const onOpenDuringLogin = () => {
        sendLoginMsg();
      };

      const onSuccessLogin = () => {
        removeEventListenersForLoginProcess();
        runSocketInUsualMode();
      };

      const onFailedLogin = () => {
        removeEventListenersForLoginProcess();
        clearWebSocket();
      };

      const onMessageDuringLogin = (msg) => {
        try {
          const obj = JSON.parse(msg.data);
          if (obj.type !== 'login') {
            return;
          }

          const {
            payload: {
              isSuccess
            }
          } = obj;

          if (!isSuccess) {
            onFailedLogin();
          } else {
            onSuccessLogin();
          }
        } catch (e) {
          console.log('Can\'t parse WebSocket message during login', e);
          removeEventListenersForLoginProcess();
          clearWebSocket();
        }
      };

      socket = new WebSocket('ws://127.0.0.1:1111');
      addEventListenersForLoginProcess();
    }

    function runSocketInUsualMode() {
      socket.addEventListener('message', onMessageInUsualMode);
      socket.addEventListener('error', onErrorInUsualMode);
      socket.addEventListener('close', onCloseInUsualMode);
    }

    function clearWebSocket() {
      if (!socket) {
        return;
      }

      socket.removeEventListener('message', onMessageInUsualMode);
      socket.removeEventListener('error', onErrorInUsualMode);
      socket.removeEventListener('close', onCloseInUsualMode);

      socket.close();
      socket = null;
    }

    function onMessageInUsualMode(msg) {
      try {
        ui.show(JSON.parse(msg.data));
      } catch (e) {
        console.log('Failed process incoming message', e);
      }
    }

    function onCloseInUsualMode() {
      clearWebSocket();
    }

    function onErrorInUsualMode(e) {
      console.error(e);
    }
  }
})();
