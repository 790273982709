(function () {
  "use strict";

  angular
    .module('app')
    .factory('ResponseParser', responseParser);

  responseParser.$inject = [
    '$q',
    '$filter',
    '$mdToast',
    '$log',
    '$track',
    '$localStorage',
    'Session',
    'Utils',
    'app/main/services/responseParserUtils'
  ];

  function responseParser($q, $filter, $mdToast, $log, $track, $localStorage, Session, Utils, responseParserUtils) {
    var session = '';

    var trans = {
      ru: {
        error0: 'Отказано в доступе',
        noData: 'нет данных',
        noPower: 'нет питания',
        reinitModem: 'перезагрузка модема',
        noGpsSignal: 'нет сигнала GPS',
        refueling: 'Заправка',
        draining: 'Слив',
        usage: 'Расход',
        onEnter: 'сработал вход',
      },
      ua: {
        error0: 'Відмовлено в доступі',
        noData: 'немає даних',
        noPower: 'немає живлення',
        reinitModem: 'перезагрузка модема',
        noGpsSignal: 'немає сигналу GPS',
        refueling: 'Заправка',
        draining: 'Злив',
        usage: 'Витрата',
        onEnter: 'спрацював вхід',
      },
      en: {
        error0: 'Access Error',
        noData: 'no data',
        noPower: 'no power',
        reinitModem: 'modem reboot',
        noGpsSignal: 'no GPS signal',
        refueling: 'Refueling',
        draining: 'Draining',
        usage: 'Usage',
        onEnter: 'on enter',
      }
    };

    function getTrans (code) {
      var lang = Session.lang;
      return trans[lang][code];
    }

    responseParser.carResolver = {};

    /**
     *
     * @param carId
     * @returns {*}
     */
    function getCarName(carId) {
      return responseParser.carResolver[carId];
    }

    /**
     *
     * @param name
     * @param id
     * @returns {string}
     */
    function normalizeCarName(name, id) {
      if (Session.user && Utils.isTestAccount(Session.user.loginName)) {
        return getTestName(id);
      }
      return name ? name : responseParser.carResolver.hasOwnProperty(id) ? responseParser.carResolver[id] : ('#' + id);
    }

    function getTestName(id) {
      return 'TEST #' + id;
    }

    function formatSignal(input) {
      var out = '';

      if (input >= 24) {
        out = '>>>>>';
      } else if (input >= 19) {
        out = '>>>>';
      } else if (input >= 15) {
        out = '>>>';
      } else if (input >= 10) {
        out = '>>';
      } else {
        out = '>';
      }

      return out;
    }

    function formatTemperature(input) {
      return input ? ('+'+input) : '';
    }

    /**
     *
     * @param chunks
     * @returns {{loginType: string, loginName: string, permissions: null, zones: Array}}
     */
    function processLoginResponse(chunks) {
      var groups = [];
      var cars = [];
      var parts;
      var data = {
        loginType: '0',
        loginName: '',
        permissions: null,
        zones: []
      };

      responseParser.carResolver = {};

      var row = chunks.shift();
      if (row != '') {
        parts = row.split(';');
        //типы логина:
        // 3 - тестовый
        // 2 - с заводскими настройками
        // 1 - отдельное устройство
        // 0 - с правом управления
        data.loginType = parts[0];
        data.loginName = parts[1];
      }
      // список групп и ТС
      // для группы строка выглядит
      // s;id;name
      // частный случай s;0 для начала списка
      // для ТС строка выглядит
      // id;name
      while (chunks.length > 0) {
        row = chunks.shift();
        if (row == '')
          continue;
        parts = row.split(';');
        if (parts[0] == 's') {
          if (parts[1] != '0')
            groups.push({id: parts[1], name: parts[2]});
        } else if (parts[0] === 'settings') {
          data.settings = JSON.parse(parts[1]);
        } else if (parts[0] === 'zones') {
          try {
            data.zones = _.map(JSON.parse(row.split(/;(.+)/)[1]), function (el) {
              var coordinates = el.wkt.replace(/(POLYGON\(\()(.*)(\)\))/, '$2').split(",");
              delete el.wkt;
              el.coordinates = _.map(coordinates, function (el) {
                var latlng = el.split(" ");
                return [parseFloat(latlng[1]), parseFloat(latlng[0])];
              });

              if (el.days) {
                el.days = JSON.parse(el.days);
              }
              return el;
            });
          } catch (e) {
            console.log(e);
          }

        } else if (parts[0] === 'permissions') {
          data.permissions = JSON.parse(parts[1].replace('{}', '[]'));
        } else if (parts[0] === 'configurable') {
          data.configurablePermissions = JSON.parse(parts[1]);
        } else if (parts[0] === 'blocked') {
          data.blocked = parts[1] === "1";
        } else {
          var car = {id: parts[0], name: normalizeCarName(parts[1], parts[0])};
          responseParser.carResolver[car.id] = car.name;
          cars.push(car);
        }
      }
      data.cars = cars;
      data.groups = groups;
      data.session = session;
      return data;
    }

    /**
     * @private
     * @param chunks
     * @returns {Array}
     */
    function processCarDataResponse(chunks) {
      //формат ответа: одна или более строк, каждая строка - CSV, разделитель ';'
      //  0       1               2         3       4         5          6      7   8    9      10         11        12       13
      // carId;group(reserved);carName;devStatus;unknown;parkingTime;gpsStatus;lat;lon;speed;direction;signalLevel;voltage;temperature;
      //   14       15         16          17            18
      // height;timestamp;speedLimit;addressGoogle;addressYandex
      // gpsStatus - '0'/'' - нет связи, 1+ - норм
      // lat/lon - умноженные на миллион целые числа
      // signalLevel - уровнень приема GPS от 0(нет) до 24+(отлично)
      // voltage - умноженное на 100 напряжение на устройстве
      // temperature - температура в градусах цельсия
      // timestamp - время, когда получена ифнормация
      // height - высота
      // speedLimit - предел скорости устройства, после которого скорость считаем превышенной
      // parkingTime - час стоянки в секундах
      var data = [];
      var parts, i, findCar, className;
      for (i = 0; i < chunks.length; i++) {
        if (chunks[i] === '') continue;
        parts = chunks[i].split(';');

        const isGpsControlActive = parts.slice(-2)[0] === 'true'
        const isV2FuelActive = parts.slice(-1)[0] === 'true'

        const isVehicleWithControversialPosition =
          responseParserUtils.isVehicleWithControversialPositionForResponseAboutCurrentStateOfVehicle(chunks[i], isGpsControlActive)

        const vehiclePosition =
          responseParserUtils.getVehiclePositionForResponseAboutCurrentStateOfVehicle(chunks[i], isGpsControlActive)

        findCar = _.findWhere(Session.user.cars, {id: parts[0]}) || {};
        className = parts[9] > 5 ? 'moving' : 'stopped';
        if (parts[3] == '1') {
          className += parts[6] > 0 ? ' online-gps' : ' no-online-gps';
        }

        var item = {
          id: parseInt(parts[0]),
          temperaturePrio: parts[1],
          temperaturePrioView: parts[1] ? ('+'+parts[1]) : parts[1],
          name: normalizeCarName(findCar.name, parts[0]),
          short: parts[32],
          fuel: parts[2],
          combinedFuel: parts[2] ? eval(parts[2].replaceAll('-', 0)) : '',
          status: parseInt(parts[3]),
          unknown1: parts[4],
          parkingTime: formatPeriodLong(parts[5] || 0),
          showParkingTime: parts[9] == 0 && (parts[5] || 0) > 120, // if car is not moving more than 2min
          gpsStatus: parts[6],
          latitude: vehiclePosition.latitude,
          longitude: vehiclePosition.longitude,
          speed: parts[9],
          direction: parts[10],
          signalLevel: parts[11],
          signalView: formatSignal(parts[11]),
          voltage: parseInt(parts[12], 10) / 100,
          temperature: parts[13],
          temperatureView: parts[13] ? ('+'+parts[13]) : parts[13],
          height: parts[14],
          timestamp: parts[15],
          dateView: moment.unix(parts[15]).format('L LTS'),
          speedLimit: parts[16],
          addressGoogle: parts[17],
          addressYandex: parts[18],
          odometer: (Number(parts[28]) / 1000).toFixed(3),
          speedLimit2: parseInt(parts[31]) || (parseInt(parts[16], 10) + 20),
          isBlocked: parts[23] === 'blocked',
          className: className,
          isVehicleWithControversialPosition
        };

        // TODO get rid of position based data from backend
        const extraSettings = parts.length >= 32 ? parts.slice(-4) : ['', '', ''];
        item.vehicleType = extraSettings[0];
        item.vehicleIcon = extraSettings[1];
        item.vehicleIconColor = extraSettings[2];

        item.isGpsControlActive = isGpsControlActive
        item.isV2FuelActive = isV2FuelActive

        getZoneNameByLanLat(item);
        data.push(item);
      }
      // gps.events.emit(gps.events.types.carData, data);
      return data;
    }

    function isTradingAgent(timesValue = '') {
      return timesValue.split(',')
        .filter((_, index) => index % 2 !== 0)
        .some((v) => v !== '0');
    }

    /**
     *
     * @param chunks
     * @returns {points: Array}}
     */
    function processCarStopsExtendedResponse(chunks) {
      var parts, row, i, point;
      var data = {
        points: []
      };

      for (i = 0; i < chunks.length; i++) {
        row = chunks[i];
        if (row == '')
          continue;
        parts = row.split(';');

        if (parseInt(parts[0], 10) > 100) {
          point = {
            timestamp: parts[0],
            latitude: parts[3] / 600000,
            longitude: parts[4] / 600000,
            speed: parseInt(parts[1])
          };
          data.points.push(point);
        }
      }

      return data;
    }

    /**
     *
     * @param chunks
     * @returns {{summary: {}, points: Array}}
     */
    function processCarHistoryResponse(chunks) {
      var parts, row, i, point, prevPoint, distance, speed, maxSpeed = 0;
      // В старом коде эта настройка закомментирована в index.html
      // var doFilter = gps.app.getState().settings.filterMarkers == 1;
      var doFilter = 0;
      //диапазон напряжений, по которым убираем маркеры при фильтрации стоянок
      var maxVoltage = 0;
      var minVoltage = 1000;
      var avgVolgtage;
      var data = {
        summary: {},
        points: []
      };

      let vehicleExtraSettings = {}

      if (chunks.length > 0) {
        row = chunks.shift();
        parts = row.split(';');
        data.summary = {
          distanceKm: parts[0] / 10,
          maxSpeedKmpH: parts[1],
          engineOnSec: parts[2],
          speedLimit: parts[3],
          speedLimit2: parts[4]
        };

        const extraSettingsRecord = chunks
          .map(str => str.split(';'))
          .find(parts => parts[0] === 'vehicleExtraSettings')
        try {
          vehicleExtraSettings = JSON.parse(extraSettingsRecord[1])[0]
        } catch (e) {
        }

        doFilter = parts[5] === '1';

        for (i = 0; i < chunks.length; i++) {
          row = chunks[i];

          switch (true) {
            case row === '':
            case row.split(';')[0] === 'vehicleExtraSettings':
              continue;
          }

          parts = row.split(';');
          point = {
            timestamp: parts[1],
            latitude: parts[3] / 600000,
            longitude: parts[4] / 600000,
            speed: parseInt(parts[5]),
            direction: parts[6],
            signalLevel: parts[7],
            speedLimit: data.summary.speedLimit,
            speedLimit2: data.summary.speedLimit2,
            voltage: parts[8] / 100,
            gpsStatus: 1,
            status: 1
          };
          if (doFilter) {
            if (point.voltage < minVoltage) {
              minVoltage = point.voltage;
            }
            if (point.voltage > maxVoltage) {
              maxVoltage = point.voltage;
            }
          }
          data.points.push(point);
          //вычисление скорости вручную, задача #542
          if (prevPoint) {
            distance = $track.length([{latitude: prevPoint.latitude, longitude: prevPoint.longitude},
              {latitude: point.latitude, longitude: point.longitude}]);
            speed = distance * 3600 / (point.timestamp - prevPoint.timestamp);
            if (speed > maxSpeed) {
              maxSpeed = speed;
            }
          }
          prevPoint = point;

        }
        if (doFilter) {
          avgVolgtage = (maxVoltage + minVoltage) / 2;
          i = 0;
          while (i < data.points.length) {
            if (data.points[i].voltage < avgVolgtage) {
              i++;
              while (i < data.points.length && data.points[i].voltage < avgVolgtage) {
                data.points.splice(i, 1);
              }
            } else {
              i++;
            }
          }
        }
        if (maxSpeed > 0 && maxSpeed < data.summary.maxSpeedKmpH) {
          maxSpeed = Math.round(maxSpeed);
          data.summary.maxSpeedKmpH = maxSpeed;
          for (i = 0; i < data.points.length; i++) {
            if (data.points[i].speed > maxSpeed) {
              data.points[i].speed = maxSpeed;
            }
          }
        }
      }

      // gps.events.emit(gps.events.types.historyData, data);

      data.points.forEach((obj) => {
        obj.vehicleType = vehicleExtraSettings.type;
        obj.vehicleIcon = vehicleExtraSettings.icon;
        obj.vehicleIconColor = vehicleExtraSettings.color;
      })

      return data;
    }

    function processCarSettings(chunks){
      if(chunks[0] != ''){
        return {};
      }
      var parts = chunks[1].split(';');
      if (parts.length < 7) return;
      var data = {
        id: parts[0],
        simPassword: parts[1],
        simNumber: parts[3],
        softwareVersion: parts[6],
        outputs: parts[7],
        info: {
          x: Utils.isTestAccount(Session.user.loginName) ? getTestName(parts[0]) : parts[8],
          short: parts[18],
          notes: parts[25]
        },
        gpsPeriod: parts[10],
        baterySaving: parts[11],
        timerWakeup: parts[12],
        speed: {
          x: parseInt(parts[9]),
          z: parseInt(parts[16]) || (parseInt(parts[9])+20)
        },
        odometer: Number(parts[14]),
        subscriberPlan: parts[19],
        startZoneId: parts[20],
        endZoneId: parts[21],
        cargoCapacity: parts[22] ? JSON.parse(parts[22]) : {
          carryingCapacity: null,
          amount: null
        },
        impulse: Number(parts[23]),
        salesManWorkingTime: parseSalesManWorkingTime(parts[24])
      };
      if (parts.length >= 17 && parts[17].length > 0) {
        data.fuel = JSON.parse(parts[17]);
      }

      const extraSettings = parts.slice(-4)
      data.vehicleType = extraSettings[0];
      data.vehicleIcon = extraSettings[1];
      data.vehicleIconColor = extraSettings[2];

      data.isGpsControlActive = parts.slice(-2)[0] === 'true'
      data.isV2FuelActive = parts.slice(-1)[0] === 'true'

      return data;
    }

    function parseSalesManWorkingTime(str) {
      if (!str) {
        return;
      }
      var salesManWorkingTime = [];
      var chanks = str.split("#");

      chanks.forEach(function (chank) {
        var pieces = chank.split(',');
        var from;
        var to;

        if (pieces[2]) {
          var period = pieces[2].split('-');
          var f = period[0].split(':');
          var t = period[1].split(':');

          from = new Date(0);
          from.setHours(f[0]);
          from.setMinutes(f[1]);
          to = new Date(0);
          to.setHours(t[0]);
          to.setMinutes(t[1]);
        }

        salesManWorkingTime.push({
          index: Number(pieces[0]),
          isActive: pieces[1] === '1',
          intervals: [{from: from, to: to}]
        });
      });

      return salesManWorkingTime;
    }

    function processUssdHistory(chunks){
      var row, ussdCommand, answerStartPosition, response;
      var timestampLength = 10;
      var items = [];
      for(var i=chunks.length-1; i>=0; i--){
        row = chunks[i];
        if(row=='')
          continue;
        ussdCommand = row.substr(timestampLength+1,30).split(' ')[0];
        answerStartPosition = timestampLength + 2 + ussdCommand.length;
        var len = row.length;
        if(row.substr(len-4,4)=='",64')
          len -= 4;
        if(row.substr(answerStartPosition,3) == '0,"')
          answerStartPosition += 3;
        response = row.substr(answerStartPosition, len - answerStartPosition);
        items.push({
          timestamp: moment.unix(row.substr(0,10)).format('L LTS'),
          request: ussdCommand,
          response: response
        });
      }
      return items;
    }

    function processUssdResponse(chunks){
      return [{
        request: chunks[0],
        response: chunks[1]
      }];
    }

    /**
     * @private
     * @param chunks
     * @returns {Array}
     */
    function processCarFuelStopsResponse(chunks) {
      var parts, row, i, stop;
      var data = {
        summary: {},
        stops: []
      };
      var totalDistance = 0;
      let hasActiveTradingAgentStatus = false;
      let v2FuelSettings = false;
      let vehicleExtraSettings = {}

      if (chunks.length > 2) {
        for (i = 0; i < chunks.length; i++) {
          row = chunks[i];
          if (row === '')
            continue;
          parts = row.split(';');

          if (parts[0] === 'fuel') {
            data.summary.fuelSettings = JSON.parse(parts[1]);
            continue;
          }
          if (parts[0] === 'impulse') {
            data.summary.impulse = Number(parts[1]);
            continue;
          }

          if (parts[0] === 'tradingAgentWorkingTimes') {
            hasActiveTradingAgentStatus = isTradingAgent(parts[1]) || hasActiveTradingAgentStatus;
            continue;
          }

          if (parts[0] === 'v2FuelSettings') {
            v2FuelSettings = parts[1] === 'true';
            continue;
          }

          if (parts[0] === 'vehicleExtraSettings') {
            try {
              vehicleExtraSettings = JSON.parse(parts[1])[0]
            } catch (e) {
            }
            continue;
          }

          stop = {
            timestampFrom: parts[1],
            timestampTo: parts[2],
            fromDate: moment.unix(parts[1]).format('L'),
            fromTime: moment.unix(parts[1]).format('LTS'),
            toTime: moment.unix(parts[2]).format('LTS'),
            status: parts[0]
          };

          if (stop.status === '5') {
            data.summary.distanceKm = parts[3] / 10;
            data.summary.maxSpeedKmpH = parts[4];
            data.summary.engine = parts[5];
            continue;
          }
          if (stop.status === '9') {
            const fuelData = parts[6].split(',');
            data.summary.fuelStart = fuelData[1] && Number(fuelData[1]);
            data.summary.fuelEnd = fuelData[2] && Number(fuelData[2]);
            data.summary.totalServerFuelUsage = fuelData[3] && Number(fuelData[3]);
            continue;
          }

          // поки не підтримуємо
          if (['10', '11', '12', '13', '14', '15'].includes(stop.status)) {
            continue;
          }

          // заправка
          if (stop.status === '8') {
            stop.latitude = parts[3] / 600000;
            stop.longitude = parts[4] / 600000;
            stop.refill = Number(parts[6]);
            stop.addressGoogle = parts[10];
            stop.addressYandex = parts[11];
            data.stops.push(stop);
            continue;
          }

          if (stop.status === '2') {
            stop.latitude = parts[3] / 600000;
            stop.longitude = parts[4] / 600000;
            stop.engine = parts[5];
            stop.addressGoogle = parts[10];
            stop.addressYandex = parts[11];
            stop.hasGPS = !stop.addressYandex.includes('<s>GPS</s>');
          } else if (stop.status === '0') {
            stop.substatus = parts[3];
          } else {
            stop.distance = parts[3] / 10;
            stop.engine = (+stop.timestampTo) - (+stop.timestampFrom)
            stop.maxSpeed = parts[4];
            totalDistance += stop.distance;
            stop.hasGPS = true;
          }
          stop.tFrom = parts[6];
          stop.tTo = parts[7];
          stop.fuelFrom = parseInt(parts[8], 10) || '';
          stop.fuelTo = parseInt(parts[9], 10) || '';
          if (parts[12]) {
            stop.secondFuelFrom = parseInt(parts[12], 10) || '';
            stop.secondFuelTo = parseInt(parts[13], 10) || '';
          }
         // stop.fuelUsage = parts[14] ? (+parts[14] / 100000000) : 0;
          stop.fuelUsage = 0;
          stop.cnt = Number(parts[14]);
          data.stops.push(stop);
        }
      }

      if (data.summary) {
        data.summary.calculatedDistanceKm = totalDistance.toFixed(1);
      }

      data.summary.isTradingMan = hasActiveTradingAgentStatus;
      data.summary.v2FuelSettings = v2FuelSettings && data.summary.totalServerFuelUsage !== undefined;

      // ігноруємо записи про заправку
      if (!data.summary.v2FuelSettings) {
        data.stops = data.stops.filter((stop) => stop.status !== '8');
      }

      // gps.events.emit(gps.events.types.stopsData, data);

      data.stops.forEach((obj) => {
        obj.vehicleType = vehicleExtraSettings.type;
        obj.vehicleIcon = vehicleExtraSettings.icon;
        obj.vehicleIconColor = vehicleExtraSettings.color;
      })
      return data;
    }

    /**
     * @private
     * @param chunks
     * @returns {Array}
     */
    function processCarSensors(chunks) {
      console.time('parseCarSensors');
      var prevPoint,
        tekPoint,
        accuracyNumber = 1,
        speedAccuracy = 10,
        timeAccuracy = 10;
      var result = _.reduce(chunks, function (res, el) {
        if (el != '') {
          var parts = el.split(';');

          if (parts.length > 2 && parts[0].length > 5) {
            tekPoint = {
              timestampFrom: parts[0],
              v1: parts[1] / 100 || 0,
              speed: parts[2] || 0,
              temp: +parts[4] || 0,
              fuel1: parts[6],
              fuel2: parts[7],
            };

            // need to filter points if fuel changed more than 1 liter
            // or went more than 10 minutes
            // or if speed changed more thatn 10km/h
            if (
              !prevPoint
              || (tekPoint.fuel1 && Math.abs(prevPoint.fuel1 - tekPoint.fuel1) >= accuracyNumber)
              || (tekPoint.fuel2 && Math.abs(prevPoint.fuel2 - tekPoint.fuel2) >= accuracyNumber)
              || ((Number(tekPoint.timestampFrom) - Number(prevPoint.timestampFrom)) > speedAccuracy)
              || ((Number(tekPoint.speed) - Number(prevPoint.speed)) > timeAccuracy)
            ) {
              res.push(tekPoint);
              prevPoint = tekPoint;
            }
          }
        }
        return res;
      }, []);
      console.timeEnd('parseCarSensors');
      return result;
    }

    function daysNumber(interval) {
      var parts = interval.split(';');
      if (parts.length > 2) return 0;
      var start = moment(+parts[0] * 1000);
      var end = moment(+parts[1] * 1000);
      return Math.floor(moment.duration(end.diff(start)).asDays());
    }

    function getChunksInterval(data, filter) {
      var from, to;
      if (typeof filter.from === 'number') {
        from = filter.from;
        to = filter.to
      } else {
        from = +moment(filter.from).format("x");
        to = +moment(filter.to).format("x");
      }
      return _.filter(data, function (el) {
        var parts = el.split(';');
        var timestemp = +parts[0] * 1000;

        return timestemp >= from && timestemp <= to;
      })
    }

    function processCarDistanceResponse(chunks){
      try {
        var mapProp = ['night', 'day', 'evening', 'total', 'fuel'],
          total = {}, maxSpeed = 0;
          ;

        _.each(mapProp, function (el) {
          total[el] = 0;
        });

        var result = _.map(JSON.parse(chunks[0]), function (el) {
          var d = moment(el.date.replace(/\./g, "-")).day();
          if (d == 6 || d == 0) el.isWeekend = true;
          if (+el.maxSpeed > maxSpeed) maxSpeed = +el.maxSpeed;
          el.fuel && (el.fuel = el.fuel.toFixed(1));

          _.each(mapProp, function (prop) {
            if (el[prop] !== undefined) {
              total[prop] += +el[prop];
            }
          });
          total.maxSpeed = maxSpeed;
          return el;
        });

        _.each(mapProp, function (prop) {
          total[prop] = total[prop].toFixed(1);
        });

        return {
          list: result,
          total: total
        };
      } catch (e) {
        return {};
      }
    }

    function renderTemp(val){
      var temp = parseInt(val);
      return temp > 0 ? ('+' + temp): temp;
    }

    function formatPeriod(period) {
      var duration = moment.duration(period, 'seconds'),
        days = duration.days(),
        hours = duration.hours(),
        minutes = ('0' + duration.minutes()).slice(-2)
      ;

      return days * 24 + hours + ':' + minutes;
    }

    function formatPeriodLong(period) {
      var duration = moment.duration(period, 'seconds'),
        days = duration.days(),
        hours = duration.hours(),
        minutes = ('0' + duration.minutes()).slice(-2)
      ;

      if (days > 0) {
        return days + $filter('translate')('global.day') + hours + $filter('translate')('global.hours');
      }

      return hours + $filter('translate')('global.hours') + minutes + $filter('translate')('global.minutes');
    }

    function isSummer(date){
      var season = Session.user.settings.seasons || {"summer":"2000.04.15","winter":"2000.10.15"};
      var date = date || new Date();
      var winter = new Date(season.winter);
      var summer = new Date(season.summer);
      var wlts = winter < summer;
      var dlts = date < summer;
      var dltw = date < winter;
      return (!dlts && (dltw || wlts)) || (dltw && wlts);
    }

    function parseSingleDeviceRoute(route, detailed, addrType) {
      var stops = [], text = '', aditionalInfoText = '', totalHold = 0, totalWay = 0, totalUsage = 0, geoStops = [], onWay, onWayRange, address,
        powerStatus = [getTrans('noData'), getTrans('noPower'), getTrans('reinitModem')],
        transNoGpsSignal = getTrans('noGpsSignal'),
        transDraining = getTrans('draining'),
        transRefueling = getTrans('refueling'),
        transUsage = getTrans('usage'),
        transOnEnter = getTrans('onEnter')
      ;

      _.each(route.stops, function (item, pos) {
        var stopTime = '';
        var prefix = '';
        var suffix = '';
        var second = '';
        var combinedPrefix = '';
        var drained = 0;
        var hasTwoTanks = !!(item.fuelFrom && item.secondFuelFrom);
        var totalBefore, totalAfter, diferense;
        if (item.geo) {
          geoStops.push(item.geo);
        }
        onWay = '';
        onWayRange = {from: 0, to: 0};

        item.fuelFrom = item.fuelFrom || 0;
        item.fuelTo = item.fuelTo || 0;
        item.secondFuelFrom = item.secondFuelFrom || 0;
        item.secondFuelTo = item.secondFuelTo || 0;

        if (hasTwoTanks) {
          totalBefore = item.fuelFrom + item.secondFuelFrom;
          totalAfter = item.fuelTo + item.secondFuelTo;
          diferense = totalBefore - totalAfter;
        }

        switch (item.status) {
          case '0': // no-power
            text = powerStatus[item.substatus] || '';
            stopTime = formatPeriod(item.timestampTo - item.timestampFrom);
            totalHold += item.timestampTo - item.timestampFrom;
            item.className = 'no-gps';
            break;
          case '1': // no-gps
            text = transNoGpsSignal;
            item.className = 'no-gps';
            break;
          case '2':
            if (!item.latitude && !item.longitude) {
              $log.warn('wrong coordinates');
              break;
            }
            getZoneNameByLanLat(item);
            if (!detailed && pos > 0) {
              var prevStop = route.stops[pos - 1],
                fromDiff = item.timestampFrom - prevStop.timestampFrom;

              if (fromDiff > 0) {
                onWayRange.from = (parseInt(prevStop.timestampFrom, 10)/* - 60*/) * 1000;
                onWayRange.to = (parseInt(item.timestampFrom, 10)/* - 60*/) * 1000;
                onWay = formatPeriod(fromDiff);
                totalWay += fromDiff;
              }
            }
            totalHold += item.timestampTo - item.timestampFrom;
            stopTime = formatPeriod(item.timestampTo - item.timestampFrom);

            if (item.fuelFrom < item.fuelTo || (item.secondFuelFrom && item.secondFuelFrom < item.secondFuelTo)) {
              if (item.secondFuelFrom) {
                var refuelingVal2 = route.summary.v2FuelSettings ? '' :
                  ((item.secondFuelTo - item.secondFuelFrom) + 'л ');
                second = ' + ' + refuelingVal2 + '(' + item.secondFuelFrom + '>>' + item.secondFuelTo + ')';
              }
              var refuelingVal = route.summary.v2FuelSettings ? '' :
                (transRefueling + ': ' + (item.fuelTo - item.fuelFrom) + 'л ');
              prefix = '<span class="c-red">' + refuelingVal + '(' + item.fuelFrom + '>>' + item.fuelTo + ')' + second + '</span>, ';
            }
            else if (item.fuelFrom > item.fuelTo || (item.secondFuelFrom && item.secondFuelFrom > item.secondFuelTo)) {
              var idle = (route.summary.fuelSettings || {}).idle,
                  used = item.fuelFrom - item.fuelTo;
              second = '';
              if (item.secondFuelFrom) {
                second = ' + ' + (item.secondFuelFrom - item.secondFuelTo) + 'л (' + item.secondFuelFrom + '>>' + item.secondFuelTo + ')';
                used += item.secondFuelFrom - item.secondFuelTo;
              }
              if (Utils.isDraining(idle, item.engine, used)) {
                var drainingVal = route.summary.v2FuelSettings ? '' :
                  (transDraining +  ': ' + (used) + 'л ');

                prefix = '<span class="c-red">' + drainingVal + '(' + item.fuelFrom + '>>' + item.fuelTo + ')' + second + '</span>, ';
                drained = item.fuelFrom - item.fuelTo;
              } else {
                prefix = '<span class="c-green">' + transUsage + ': ' + (item.fuelFrom - item.fuelTo) + 'л (' + item.fuelFrom + '>>' + item.fuelTo + ')' + second + '</span>, ';
                totalUsage += used;
              }
            }
            else if (item.fuelFrom) {
              second = '';
              if (item.secondFuelFrom) {
                second = ' + ' + item.secondFuelFrom + 'л';
              }
              prefix = item.fuelFrom + 'л' + second + ', ';
            }
            else if (item.secondFuelFrom) {
              second = item.secondFuelFrom + 'л, ';
              prefix = second;
            }

            if (item.engine > 60) {
              item.engine = parseInt(item.engine, 10);
              suffix = '<span class="c-green"> +' + formatPeriod(item.engine) + ' ч.</span>';
            }

            if (item.tFrom) {
              suffix += ['<i>t: ', renderTemp(item.tFrom), '..', renderTemp(item.tTo), '</i>'].join("");
            }

            address = item[addrType];

            if (hasTwoTanks) {
              if (diferense === 0) {
                combinedPrefix = totalBefore + 'л, ';
              } else if (diferense > 0) {
                combinedPrefix = '<span class="c-red">' + transDraining + ': ' + -(diferense) + 'л (' + totalBefore + '>>' + totalAfter + ')</span>, ';
              } else if (diferense < 0) {
                combinedPrefix = '<span class="c-red">Заправка: +' + -(diferense) + 'л (' + totalBefore + '>>' + totalAfter + ')</span>,';
              }
              aditionalInfoText = (combinedPrefix || prefix) + '<span class="car-link">' + address + '</span>' + suffix;
            }
            text = prefix + '<span class="car-link">' + address + '</span> ' + suffix;

            break;
          case '3':
            var used = 0;
            if (item.fuelFrom) {
              totalUsage += item.fuelFrom - item.fuelTo;
              if (item.secondFuelFrom) {
                totalUsage += item.secondFuelFrom - item.secondFuelTo;
              }
            } else if (item.secondFuelTo) {
              totalUsage += item.secondFuelFrom - item.secondFuelTo;
            } else if (route.summary.impulse) {
              used = (item.cnt / 100000000);
              totalUsage += used;
              if (used >= 0.1) {
                route.summary.tc = true;
              }
            } else if (route.summary.fuelSettings && route.summary.fuelSettings.summer && route.summary.fuelSettings.winter) {
              var summer = isSummer(moment.unix(item.timestampFrom).toDate());
              var isCity = item.distance < 10;
              var usage = route.summary.fuelSettings[summer ? 'summer' : 'winter'][isCity ? 'city' : 'road'];
              used = usage * item.distance / 100;
              totalUsage += used;
              if (used >= 0.1) {
                route.summary.tc = true;
              }
            }
            if (!detailed) {
              break;
            }
            if (item.timestampTo - item.timestampFrom > 0) {
              onWayRange.from = (parseInt(item.timestampFrom, 10)/* - 60*/) * 1000;
              onWayRange.to = (parseInt(item.timestampTo, 10)/* - 60*/) * 1000;
              onWay = formatPeriod(item.timestampTo - item.timestampFrom);
              totalWay += item.timestampTo - item.timestampFrom;

              if (item.fuelFrom) {
                second = '';
                if (item.secondFuelFrom) {
                  second = ' + ' + (item.secondFuelFrom - item.secondFuelTo) + 'л (' + item.secondFuelFrom + ' >> ' + item.secondFuelTo + ')';
                }
                prefix = transUsage + ' ' + (item.fuelFrom - item.fuelTo) + 'л (' + item.fuelFrom + ' >> ' + item.fuelTo + ')' + second;
                combinedPrefix = transUsage + ' ' + diferense + 'л (' + totalBefore + '>>' + totalAfter + ')';
              } else if (route.summary.impulse) {
                if (used >= 0.1) {
                  prefix = transUsage + ' ' + used.toFixed(1) + 'л';
                  combinedPrefix = prefix;
                }
              } else if (route.summary.fuelSettings) {
                if (used >= 0.1) {
                  prefix = transUsage + ' ~' + used.toFixed(1) + 'л';
                  combinedPrefix = prefix;
                }
              } else {
                prefix = '';
                combinedPrefix = prefix;
              }

              text = item.distance + 'км, ' + item.maxSpeed + 'км/ч макс. ' + prefix;

              aditionalInfoText = item.distance + 'км, ' + item.maxSpeed + 'км/ч макс. ' + combinedPrefix;

              if (item.tFrom) {
                text += ['<i>t: ', renderTemp(item.tFrom), '..', renderTemp(item.tTo), '</i>'].join("");
                aditionalInfoText += ['<i>t: ', renderTemp(item.tFrom), '..', renderTemp(item.tTo), '</i>'].join("");
              }
            }
            break;
          case '4':
            text = [transOnEnter, ' (', item.substatus, ')'].join("");
            break;
          case '8':
            if (!item.latitude && !item.longitude) {
              $log.warn('wrong coordinates');
              break;
            }
            getZoneNameByLanLat(item);
            address = item[addrType];
            if (!detailed && pos > 0) {
              var prevStop = route.stops[pos - 1],
                fromDiff = item.timestampFrom - prevStop.timestampFrom;

              if (fromDiff > 0) {
                onWayRange.from = (parseInt(prevStop.timestampFrom, 10)/* - 60*/) * 1000;
                onWayRange.to = (parseInt(item.timestampFrom, 10)/* - 60*/) * 1000;
                onWay = formatPeriod(fromDiff);
              }
            }
            stopTime = formatPeriod(item.timestampTo - item.timestampFrom);

            if (item.refill > 0) {
              prefix = '<span class="c-red">' + transRefueling + ': ' + item.refill + 'л </span>, ';
            } else {
              prefix = '<span class="c-red">' + transDraining + ': ' + item.refill + 'л </span>, ';
            }

            text = prefix + '<span class="car-link">' + address + '</span> ';

            break;
          default:
            throw new Error('Invalid status "' + item.status + '"');
        }
        item.onWay = onWay;
        item.onWayRange = onWayRange;
        item.stopTime = stopTime;
        item.info = text;
        item.drained = drained;
        item.used = used;
        item.combinedFuelTankInfo = aditionalInfoText || text;
        if (item.status == '3') {
          detailed && stops.push(item);
        } else {
          stops.push(item);
        }
        aditionalInfoText = '';
      });

      if (route.summary.v2FuelSettings) {
        totalUsage = route.summary.totalServerFuelUsage;
      } else {
        totalUsage += responseParserUtils.getFuelCorrection(route);
      }


      if (totalUsage !== undefined) {
        route.summary.distanceKm && (route.summary.averageFuelUsage = (totalUsage * 100 / parseFloat(route.summary.distanceKm)).toFixed(1));
        route.summary.totalFuelUsage = totalUsage.toFixed(1);
      }
      route.summary.totalWay = formatPeriod(totalWay);
      route.summary.totalHold = formatPeriod(totalHold);
      route.summary.totalDrained = getTotalDrained(stops);

      return {
        summary: route.summary,
        stops: stops,
        summaries: route.summaries
      };
    }

    function getTotalDrained(stops) {
      var totalDrained = 0;
      _.each(stops, function (item) {
        if (item.drained) {
          totalDrained += item.drained;
        }
      });
      return totalDrained;
    }

    function parseCarFuels (chunks) {
      console.time('parseCarFuels');
      var prevPoint, tekPoint;
      var result = _.reduce(chunks, function (res, el) {
        if (el != '') {
          var parts = el.split(';');
          if (/f1|f2/.test(parts[13])) {
            tekPoint = {
              fuel1: /f1/.test(parts[13]) ? +parts[13].replace(/(.*<b>f1:<\/b>)(\d+)(.*)/, '$2') : 0,
              fuel2: /f2/.test(parts[13]) ? +parts[13].replace(/(.*)(<b>f2:<\/b>)(\d+)(.*)/, '$3') : 0,
              temp: /&deg/.test(parts[13]) ? +parts[13].replace(/(.*,)(-*\d+)(&deg.*)/, '$2') : 0
            };

            if (
              !prevPoint
              || (tekPoint.fuel1 && Math.abs(prevPoint.fuel1 - tekPoint.fuel1) >= 10)
              || (tekPoint.fuel2 && Math.abs(prevPoint.fuel2 - tekPoint.fuel2) >= 10)
            ) {
              var v1Raw = parts[7];

              res.push(_.extend(tekPoint, {
                speed: +parts[6] || 0,
                timestampFrom: parts[1],
                v1: v1Raw.slice(0, 2) + '.' + v1Raw.slice(2)
              }));
              prevPoint = tekPoint;
            }
          }
        }
        return res;
      }, []);
      console.timeEnd('parseCarFuels');
      return result;
    }

    function parseCarFuelsCnt (chunks) {
      var point;
      return _.reduce(chunks, function (res, el, indx, arr) {
        if (el != '') {
          if (/<b>cnt:<\/b>/.test(el)) {
            var parts = el.split(';'), fuel3;
            fuel3 = +parts[13].replace(/(.*)(<b>cnt:<\/b>)(\d+)(.*)/, '$3');
            if (point) {
              point.fuel3 += fuel3;
            } else {
              point = {
                timestampFrom: parts[1],
                fuel3: fuel3
              };
            }
            if (!/<b>cnt:<\/b>/.test(arr[indx+1])) {
              point.timestampTo = parts[1];
              res.push(point);
              point = null;
            }
          }
        }
        return res;
      }, []);
    }

    function parseCarMegaInfo (chunks) {
      var gsmList = ['-','ошибка_SIM','поиск_сети','запрет_сети','Home','Roam','GPRS','R+GPRS'];
      var rebootList = ['','volt','GPRS','reset','!','power_on','reboot','reboot'];

      return _.map(chunks, function (chunk) {
        var parts = chunk.split(';');
        var sat, dop, flags;

        if (parts[2] === '') {
          sat = 'off';
          dop = '';
        } else {
          sat = parts[3];
          dop = parts[4] / 10;
        }

        var v1 = parseInt(parts[9]);

        if (v1) {
          flags = rebootList[v1];
        } else {
          flags = '';
        }

        return {
          index: parts[0],
          timestamp: +parts[1],
          sat: sat,
          dop: dop,
          azimut: parts[5],
          kmh: parts[6],
          v1: parts[7] / 100,
          v2: parts[8] / 100,
          flags: flags,
          gsm: gsmList[parseInt(parts[10])],
          csq: parts[11],
          temperature: parts[12] + '&deg',
          extra: parts[13]
        };
      });
    }

    function pointInPoly(point, vs) {
      // ray-casting algorithm based on
      // http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html
      // vs = coordinates;
      var x = point[1], y = point[0];
      var inside = false;
      var i = 0,
        j = vs.length - 1;
      while(i<vs.length){
        var xi = vs[i][0], yi = vs[i][1];
        var xj = vs[j][0], yj = vs[j][1];

        var intersect = ((yi > y) != (yj > y))
          && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
        j=i;
        i++;
      }
      return inside;
    }

    function getZoneNameByLanLat (item) {
      var point = [item.longitude, item.latitude],
        zones = Session.user.zones;

      _.find(zones, function (el) {
        if (pointInPoly(point, el.coordinates)) {
          item.addressGoogle = item.addressYandex = el.name;
          item.geo = el;
          return true;
        }
      });
    }

    /**
     * @private
     * @param chunks
     * @returns {Array}
     */
    function processJSON(chunks) {
      return JSON.parse(chunks[0]);
    }

    function parse(text) {
      if (typeof text !== 'string') {
        return text
      }

      // Ответ от сервера: многострочный.
      // Первая строка - код ответа
      // Вторая строка - код сессии или пустая
      var chunks = text.split("\n");
      var retCode = parseInt(chunks.shift());
      var list;

      if (retCode == -1) {
        var errorText = getTrans('error' + chunks[1]);
        errorText && $mdToast.show($mdToast.simple().content($filter('translate')(errorText)).position('bottom left'));
        return $q.reject();
      }

      if (chunks.length == 0) {
        return;
      }

      if (chunks[0] != "") {
        session = chunks[0];
      }

      chunks.shift();

      switch (retCode) {
        case 0:
          return {session: ''};
          // gps.events.emit(gps.events.types.logout);
        case 1:
          return processLoginResponse(chunks);
        case 2:
          // gps.ui.msg.info('Выполнено');
          break;
        case 3:
          return processCarDataResponse(chunks);
        case 4:
          return processCarHistoryResponse(chunks);
        case 7:
          return processCarSettings(chunks);
        case 9:
          return processUssdHistory(chunks);
        case 11: return null;
        case 12:
          return [{
            request: chunks[0],
            response: chunks[1]
          }];
        case 29:
          return processCarStopsExtendedResponse(chunks);
        case 28:
          return processCarFuelStopsResponse(chunks);
        case 30:
          return processCarSensors(chunks);
        case 1001:
          return parseCarFuels(chunks);
        case 1101:
          return parseCarFuelsCnt(chunks);
        case 1111:
            return parseCarMegaInfo(chunks);
        case 1002:
        case 1003:
        case 1006:
          return JSON.parse(chunks[0]);
        case 1004:
          return parseInt(chunks[0]);
        case 1007:
          return JSON.parse(chunks[0].replace('{}','[]'));
        case 1010:
          return processCarDistanceResponse(chunks);
        case 1012:
          list = JSON.parse(chunks[0].replace('{}', '[]'));
          return _.map(list, function (el) {
            el.list = el.list.split(",");
            el.listNames = _.map(el.list, function (item) {
              var findZone = _.findWhere(Session.user.zones, {id: parseInt(item)});
              return findZone ? findZone.name : item;
            });
            return el;
          });
        case 1015:
          list = JSON.parse(chunks[0].replace('{}', '[]'));
          return _.map(list, function (el) {
            var findCar = _.findWhere(Session.user.cars, {id: el.carId.toString()}) || {name: "Все"};
            el.carName = findCar.name;
            return el;
          });
        case 1020:
          return processJSON(chunks);
        default:
      }
    }

    return {
      parse: parse,
      getChunksInterval: getChunksInterval,
      processCarSensors: processCarSensors,
      parseSingleDeviceRoute: parseSingleDeviceRoute
    };
  }
})();
