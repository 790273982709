(() => {
  angular
    .module('app.reports')
    .service('app.reports.utils', utils);

  utils.$inject = [
    '$translate',
    'routeItemUtils'
  ];

  function utils($translate, routeItemUtils) {
    return {
      formatDurationInSeconds,
      formatFuelAmount,
      renderIfNotZero,
      getFuelConsumptionMeterDetails,
      calcXLSXRowHeight
    };

    function formatDurationInSeconds(seconds) {
      const minutes = Math.floor(seconds / 60);
      if (minutes < 60) {
        if (minutes === 0) {
          return '';
        }
        return `${minutes} ${$translate.instant('global.minutes')}`;
      }

      const hours = `${Math.floor(minutes / 60)}`;
      let extraMinutes = minutes - hours * 60;
      if (extraMinutes === 0) {
        return `${hours} ${$translate.instant('global.hours')}`;
      }

      extraMinutes = `${extraMinutes}`.padStart(2, '0');
      return `${hours}:${extraMinutes} ${$translate.instant('global.hours')}`;
    }

    function formatFuelAmount(amount) {
      return Math.floor(amount * 10) / 10;
    }

    function renderIfNotZero(v) {
      return `${v}` === '0' ? '' : v;
    }

    function getFuelConsumptionMeterDetails(routeDetails) {
      const typeOfFuelConsumptionMeter = routeItemUtils.getTypeOfFuelConsumptionMeter(routeDetails);
      const shouldShowFuelConsumptionMeterType = [
        routeItemUtils.fuelConsumptionMeterTypes.IMPULSES_METER,
        routeItemUtils.fuelConsumptionMeterTypes.FUEL_FLOW_SENSOR,
        routeItemUtils.fuelConsumptionMeterTypes.FUEL_CALCULATOR
      ].includes(typeOfFuelConsumptionMeter);

      const fuelConsumptionTypeToI18nKeys = {
        [routeItemUtils.fuelConsumptionMeterTypes.FUEL_CALCULATOR]: 'reports.fuelCalculator',
        [routeItemUtils.fuelConsumptionMeterTypes.IMPULSES_METER]: 'reports.impulseSensor',
        [routeItemUtils.fuelConsumptionMeterTypes.FUEL_FLOW_SENSOR]: 'reports.fuelFlowSensor'
      };

      return {
        shouldShowFuelConsumptionMeterType,
        fuelConsumptionMeterTypeI18nKey: fuelConsumptionTypeToI18nKeys[typeOfFuelConsumptionMeter]
      };
    }

    function calcXLSXRowHeight(cells) {
      const maxCharactersPerLine = 32;
      const heightPerRow = 18;

      const getCellText = (cell) => {
        if (typeof cell === 'string') {
          return cell;
        }

        if (Array.isArray(cell.richText)) {
          return cell.richText.map(item => item.text).join(' ');
        }

        return '';
      };

      const getRowsCount = (text) => Math.ceil(text.length / maxCharactersPerLine);

      const getCellHeight = (countOfRows) => countOfRows * heightPerRow;

      const lastCellText = getCellText(cells.slice(-1)[0]);
      const countOfRows = getRowsCount(lastCellText);
      return getCellHeight(countOfRows);
    }
  }
})();
