(() => {
  angular
    .module('app.vehicles')
    .service('app.vehicles.stops.reportSaver', reportSaver);

  reportSaver.$inject = [
    '$translate',
    'app.vehicles.stops.reportDataBuilder',
    'app.reports.exportTypes',
    'app.reports.fileSaver',
    'app.reports.converters.csv',
    'app.reports.helpers.xlsx',
    'app.reports.helpers.pdf',
    'app.reports.helpers.common',
    'app.reports.utils'
  ];

  function reportSaver($translate, reportDataBuilder, exportTypes, fileSaver, csvConverter,
    xlsxHelper, pdfHelper, commonForReports, reportUtils) {

    return {
      save
    };

    /**
     * @param {string} exportType
     * @param {Object} data
     */
    function save(exportType, data) {
      switch (exportType) {
        case exportTypes.toCSV:
          toCSV(data);
          break;
        case exportTypes.toXLSX:
          toXLSX(data);
          break;
        case exportTypes.toPDF:
          toPDF(data);
          break;
      }
    }

    function toCSV(data) {
      let rows = [];
      rows = rows.concat(getPageHeaderRows(data));
      rows.push([]);
      rows.push(getMainTableHeader(data));
      rows = rows.concat(getMainTableBodyRows(data));
      rows.push(getMainTableFooterRows(data));

      const csvData = csvConverter.generate(rows);
      fileSaver.saveCSV(reportDataBuilder.createFileName(data), csvData);

      function getPageHeaderRows(data) {
        const headerData = reportDataBuilder.getPageHeaderData(data);

        const rows = [];

        // ==================
        // "vehicle number" row
        // ==================
        rows.push([
          $translate.instant('reports.vehicleNumber'),
          headerData.vehicle.number,
          headerData.vehicle.id
        ]);

        // ==================
        // "Dates" row
        // ==================
        rows.push([
          $translate.instant('reports.date'),
          reportDataBuilder.formatDateForPrint(headerData.report.date)
        ]);

        // ==================
        // "Trading agent" row
        // ==================
        if (headerData.report.isTradingMan) {
          rows.push([
            $translate.instant('reports.tradingAgent'),
            $translate.instant('reports.activeStatusOfTradingAgent')
          ]);
        }

        // ==================
        // "Fuel consumption meter device" row
        // ==================
        if (headerData.report.shouldShowFuelConsumptionMeterType) {
          rows.push([
            $translate.instant('reports.fuelConsumptionMonitoringDevice'),
            $translate.instant(headerData.report.fuelConsumptionMeterTypeI18nKey)
          ]);
        }

        // ==================
        // "Mileage" row
        // ==================
        rows.push([
          $translate.instant('reports.stops.mileage'),
          headerData.report.totalMileage
        ]);

        // ==================
        // "Max speed" row
        // ==================
        rows.push([
          $translate.instant('reports.stops.maxSpeed'),
          headerData.report.maxSpeed
        ]);

        // ==================
        // "Total fuel usage" row
        // ==================
        rows.push([
          $translate.instant('reports.stops.fuelConsumptionShort'),
          headerData.report.totalFuelUsage
        ]);

        // ==================
        // "Average fuel usage" row
        // ==================
        rows.push([
          $translate.instant('reports.averageFuelUsage'),
          headerData.report.averageFuelUsage
        ]);

        // ==================
        // "Total engine work" row
        // ==================
        rows.push([
          $translate.instant('car.engineWork'),
          reportDataBuilder.formatDurationInSeconds(headerData.report.totalEngineWork)
        ]);

        return rows;
      }

      function getMainTableHeader(data) {
        const headerData = reportDataBuilder.getMainTableHeaderData(data);

        const items = [
          $translate.instant('reports.stops.from'),
          $translate.instant('reports.stops.to'),
          $translate.instant('reports.stops.parkingTime'),
          $translate.instant('reports.stops.onWay'),
          $translate.instant('reports.stops.distance'),
          $translate.instant('reports.stops.maxSpeedShort')
        ];

        if (headerData.hasTwoTanks) {
          items.push('Fuel1');
          items.push('Fuel2');
        }

        items.push($translate.instant('reports.stops.fuelConsumptionShort'));
        items.push($translate.instant('reports.stops.address'));

        return items;
      }

      function getMainTableBodyRows(data) {
        return reportDataBuilder.getMainTableBodyData(data)
          .map((obj) => obj.rowData.map((rowDataItem) => {
            if (typeof rowDataItem.richText !== 'undefined') {
              return rowDataItem.richText.map((v) => {
                if (typeof v.text === 'string') {
                  return v.text;
                }
                return v;
              }).join('');
            }
            return rowDataItem;
          }));
      }

      function getMainTableFooterRows(data) {
        const footerData = reportDataBuilder.getMainTableFooterData(data);

        let footerCells = [
          $translate.instant('global.total'), // It's located in "From" column
          '', // "To" column, intentionally empty,
          reportDataBuilder.formatDurationInSeconds(footerData.totalParkingDuration),
          reportDataBuilder.formatDurationInSeconds(footerData.totalOnWayDuration),
          footerData.totalDistance,
          '' // max speed, intentionally empty
        ];

        if (footerData.hasTwoTanks) {
          footerCells = footerCells.concat([
            '', // "Main tank" column, intentionally empty
            '' // "Second tank" column, intentionally empty
          ]);
        }

        footerCells.push(footerData.totalFuelUsage);
        footerCells.push(''); // "Address" column, intentionally empty

        return footerCells;
      }
    }

    function toXLSX(data) {
      const workbook = new ExcelJS.Workbook();

      const mainTableHeaderRowIndex = 11;

      const sheet = workbook.addWorksheet($translate.instant('page.stops'), {
        pageSetup: Object.assign(
          {},
          commonForReports.xlsxPageSetupOptions,
          {
            orientation: 'portrait',
            printTitlesRow: `${mainTableHeaderRowIndex}:${mainTableHeaderRowIndex}`
          }
        )
      });

      renderPageHeader(sheet, data);
      xlsxHelper.renderEmptyRow(sheet);
      renderMainTableHeader(sheet, data);
      renderMainTableBody(sheet, data);
      renderMainTableFooter(sheet, data);
      setColumnsWidth(sheet, data);

      fileSaver.saveXLSX(reportDataBuilder.createFileName(data), workbook);

      function renderPageHeader(sheet, data) {
        const headerData = reportDataBuilder.getPageHeaderData(data);

        const formatLastRow = () => {
          const rowIndex = sheet.rowCount;
          const row = sheet.getRow(rowIndex);

          // column 1
          sheet.mergeCells(rowIndex, 1, rowIndex, 4);

          // column 2
          sheet.mergeCells(rowIndex, 5, rowIndex, 7);

          // column 3
          // sheet.mergeCells(rowIndex, 8, rowIndex, 9);

          xlsxHelper.styleRow(row);
          xlsxHelper.styleCellAsHeader(row.getCell(1));
          xlsxHelper.alignToLeft(row.getCell(1));
        };

        // ==================
        // "vehicle number" row
        // ==================
        sheet.addRow([
          // column 1
          $translate.instant('reports.vehicleNumber'),
          '',
          '',
          '',

          // column 2
          headerData.vehicle.number,
          '',
          '',

          // column 3
          headerData.vehicle.id
        ]);
        formatLastRow();

        // ==================
        // "Date" row
        // ==================
        sheet.addRow([
          // column 1
          $translate.instant('reports.date'),
          '',
          '',
          '',

          // column 2
          reportDataBuilder.formatDateForPrint(headerData.report.date),
          '',
          '',

          // column 3
          ''
        ]);
        formatLastRow();

        // ==================
        // "Trading agent" row
        // ==================
        if (headerData.report.isTradingMan) {
          sheet.addRow([
            // column 1
            $translate.instant('reports.tradingAgent'),
            '',
            '',
            '',

            // column 2
            $translate.instant('reports.activeStatusOfTradingAgent'),
            '',
            '',

            // column 3
            ''
          ]);
          formatLastRow();
        }

        // ==================
        // "Fuel consumption meter device" row
        // ==================
        if (headerData.report.shouldShowFuelConsumptionMeterType) {
          sheet.addRow([
            // column 1
            $translate.instant('reports.fuelConsumptionMonitoringDevice'),
            '',
            '',
            '',

            // column 2
            $translate.instant(headerData.report.fuelConsumptionMeterTypeI18nKey),
            '',
            '',

            // column 3
            ''
          ]);
          formatLastRow();

          if (['en', 'ru'].includes($translate.use())) {
            const row = sheet.getRow(sheet.rowCount);
            row.height = 30;

            const cell = row.getCell(1);
            cell.alignment = Object.assign(
              {},
              cell.alignment || {},
              {
                wrapText: true
              }
            );
          }
        }

        // ==================
        // "Mileage" row
        // ==================
        sheet.addRow([
          // column 1
          $translate.instant('reports.stops.mileage'),
          '',
          '',
          '',

          // column 2
          headerData.report.totalMileage,
          '',
          '',

          // column 3
          ''
        ]);
        formatLastRow();

        // ==================
        // "Max speed" row
        // ==================
        sheet.addRow([
          // column 1
          $translate.instant('reports.stops.maxSpeed'),
          '',
          '',
          '',

          // column 2
          headerData.report.maxSpeed,
          '',
          '',

          // column 3
          ''
        ]);
        formatLastRow();

        // ==================
        // "Total fuel usage" row
        // ==================
        sheet.addRow([
          // column 1
          $translate.instant('reports.stops.fuelConsumptionShort'),
          '',
          '',
          '',

          // column 2
          headerData.report.totalFuelUsage,
          '',
          '',

          // column 3
          ''
        ]);
        formatLastRow();

        // ==================
        // "Average fuel usage" row
        // ==================
        sheet.addRow([
          // column 1
          $translate.instant('reports.averageFuelUsage'),
          '',
          '',
          '',

          // column 2
          headerData.report.averageFuelUsage,
          '',
          '',

          // column 3
          ''
        ]);
        formatLastRow();

        // ==================
        // "Total engine work" row
        // ==================
        sheet.addRow([
          // column 1
          $translate.instant('car.engineWork'),
          '',
          '',
          '',

          // column 2
          reportDataBuilder.formatDurationInSeconds(headerData.report.totalEngineWork),
          '',
          '',

          // column 3
          ''
        ]);
        formatLastRow();
      }

      function renderMainTableHeader(sheet, data) {
        const headerData = reportDataBuilder.getMainTableHeaderData(data);

        let items = [
          $translate.instant('reports.stops.from'),
          $translate.instant('reports.stops.to'),
          $translate.instant('reports.stops.parkingTime'),
          $translate.instant('reports.stops.onWay'),
          $translate.instant('reports.stops.distance'),
          $translate.instant('reports.stops.maxSpeedShort')
        ];

        if (headerData.hasTwoTanks) {
          items.push('Fuel1');
          items.push('Fuel2');
        }

        items.push($translate.instant('reports.stops.fuelConsumptionShort'));
        items.push($translate.instant('reports.stops.address'));

        const row = sheet.addRow(items);
        xlsxHelper.styleRow(row);
        row.height = 30;

        row.eachCell({includeEmpty: true}, (cell, columnIndex) => {
          xlsxHelper.styleCellAsHeader(cell);

          cell.alignment = Object.assign(
            cell.alignment || {},
            {
              horizontal: 'center',
              vertical: 'middle'
            }
          );

          // "Total fuel usage" column
          if (columnIndex === items.length - 1) {
            cell.alignment = Object.assign(
              cell.alignment || {},
              {
                wrapText: true
              }
            );
          }

          // "On way" column
          if (columnIndex === 5) {
            cell.alignment = Object.assign(
              cell.alignment || {},
              {
                wrapText: true
              }
            );
          }

          // "Max speed" column
          const maxSpeedColumnIndex = headerData.hasTwoTanks ? items.length - 4 : items.length - 2;
          if (columnIndex === maxSpeedColumnIndex) {
            cell.alignment = Object.assign(
              cell.alignment || {},
              {
                wrapText: true
              }
            );
          }
        });
      }

      function renderMainTableBody(sheet, data) {
        const headerData = reportDataBuilder.getMainTableHeaderData(data);

        reportDataBuilder.getMainTableBodyData(data).forEach((obj) => {
          const row = sheet.addRow(obj.rowData.map(reportUtils.renderIfNotZero));
          xlsxHelper.styleRow(row);
          row.height = reportUtils.calcXLSXRowHeight(obj.rowData);

          let lastCellInRow;
          if (headerData.hasTwoTanks) {
            lastCellInRow = row.getCell(10);
          } else {
            lastCellInRow = row.getCell(8);
          }

          lastCellInRow.alignment = Object.assign(
            {},
            lastCellInRow.alignment || {},
            {
              horizontal: 'left',
              wrapText: true
            }
          );
        });
      }

      function renderMainTableFooter(sheet, data) {
        const footerData = reportDataBuilder.getMainTableFooterData(data);

        let items = [
          $translate.instant('global.total'), // It's placed in "From" column
          '', // "To" column, intentionally empty,
          reportDataBuilder.formatDurationInSeconds(footerData.totalParkingDuration),
          reportDataBuilder.formatDurationInSeconds(footerData.totalOnWayDuration),
          footerData.totalDistance,
          '' // max speed, intentionally empty
        ];

        if (footerData.hasTwoTanks) {
          items = items.concat([
            '', // "Main tank" column, intentionally empty
            '' // "Second tank" column, intentionally empty
          ]);
        }

        items.push(footerData.totalFuelUsage);

        items.push(''); // "Address" column, intentionally empty

        const row = sheet.addRow(items);

        xlsxHelper.styleRow(row);
        xlsxHelper.styleRowAsHeader(row);
        xlsxHelper.alignToLeft(row.getCell(1));
      }

      function setColumnsWidth(sheet, data) {
        const headerData = reportDataBuilder.getMainTableHeaderData(data);

        // "From" column
        sheet.columns[0].width = 9;

        // "To" column
        sheet.columns[1].width = 9;

        // "Parking time" column
        sheet.columns[2].width = 9;

        // "On way" column
        sheet.columns[3].width = 9;

        // "Distance" column
        sheet.columns[4].width = 10;

        // "Max speed" column
        sheet.columns[5].width = 8;

        const addressColumnWidth = 30;

        if (headerData.hasTwoTanks) {
          // "Tank 1" column
          sheet.columns[6].width = 6;

          // "Tank 2" column
          sheet.columns[7].width = 6;

          // "Total fuel usage" column
          sheet.columns[8].width = 10;

          // "Address" column
          sheet.columns[9].width = addressColumnWidth;
        } else {
          // "Total fuel usage" column
          sheet.columns[6].width = 10;

          // "Address" column
          sheet.columns[7].width = addressColumnWidth;
        }
      }
    }

    function toPDF(data) {
      const headerData = reportDataBuilder.getPageHeaderData(data);

      const pageTitleText = [
        `${headerData.vehicle.number} - `,
        `${$translate.instant('page.stops')}: ${reportDataBuilder.formatDateForPrint(headerData.report.date)}`
      ].join('');

      const doc = {
        header: pdfHelper.createPageTitle(pageTitleText),
        footer: (currentPage) => pdfHelper.createPageFooter(currentPage),
        pageMargins: pdfHelper.pageMargins,
        content: [],
        styles: pdfHelper.defaultStyles
      };

      renderPageHeader(doc, data);
      renderMainTable(doc, data);

      fileSaver.savePDF(reportDataBuilder.createFileName(data), pdfMake.createPdf(doc));

      function renderPageHeader(doc, data) {
        const headerData = reportDataBuilder.getPageHeaderData(data);

        const rows = [];

        // ==================
        // "vehicle number" row
        // ==================
        rows.push([
          $translate.instant('reports.vehicleNumber'),
          headerData.vehicle.number,
          headerData.vehicle.id
        ]);

        // ==================
        // "Date" row
        // ==================
        rows.push([
          $translate.instant('reports.date'),
          reportDataBuilder.formatDateForPrint(headerData.report.date)
        ]);

        // ==================
        // "Trading agent" row
        // ==================
        if (headerData.report.isTradingMan) {
          rows.push([
            $translate.instant('reports.tradingAgent'),
            $translate.instant('reports.activeStatusOfTradingAgent')
          ]);
        }

        // ==================
        // "Fuel consumption meter device" row
        // ==================
        if (headerData.report.shouldShowFuelConsumptionMeterType) {
          rows.push([
            $translate.instant('reports.fuelConsumptionMonitoringDevice'),
            $translate.instant(headerData.report.fuelConsumptionMeterTypeI18nKey)
          ]);
        }

        // ==================
        // "Mileage" row
        // ==================
        rows.push([
          $translate.instant('reports.stops.mileage'),
          headerData.report.totalMileage
        ]);

        // ==================
        // "Max speed" row
        // ==================
        rows.push([
          $translate.instant('reports.stops.maxSpeed'),
          headerData.report.maxSpeed
        ]);

        // ==================
        // "Total fuel usage" row
        // ==================
        rows.push([
          $translate.instant('reports.stops.fuelConsumptionShort'),
          headerData.report.totalFuelUsage
        ]);

        // ==================
        // "Average fuel usage" row
        // ==================
        rows.push([
          $translate.instant('reports.averageFuelUsage'),
          headerData.report.averageFuelUsage
        ]);

        // ==================
        // "Total engine work" row
        // ==================
        rows.push([
          $translate.instant('car.engineWork'),
          reportDataBuilder.formatDurationInSeconds(headerData.report.totalEngineWork)
        ]);

        doc.content.push(pdfHelper.createHorizontalTable(rows));
      }

      function renderMainTable(doc, data) {
        let rowsData = [];

        // ==================
        // table header
        // ==================
        const headerData = reportDataBuilder.getMainTableHeaderData(data);
        let headerCells = [
          $translate.instant('reports.stops.from'),
          $translate.instant('reports.stops.to'),
          $translate.instant('reports.stops.parkingTime'),
          $translate.instant('reports.stops.onWay'),
          $translate.instant('reports.stops.distance'),
          $translate.instant('reports.stops.maxSpeedShort')
        ];

        if (headerData.hasTwoTanks) {
          headerCells.push('Fuel1');
          headerCells.push('Fuel2');
        }

        headerCells.push($translate.instant('reports.stops.fuelConsumptionShort'));
        headerCells.push($translate.instant('reports.stops.address'));

        headerCells = headerCells.map((cellValue) => ({
          text: cellValue,
          style: 'tableHeader',
          alignment: 'center'
        }));

        rowsData.push(headerCells);

        // ==================
        // table body
        // ==================
        const bodyCells = [];
        const bodyData = reportDataBuilder.getMainTableBodyData(data);

        bodyData.forEach((obj) => {
          const rowData = obj.rowData.map((cellValue, columnIndex) => {
            let cell = {
              text: reportUtils.renderIfNotZero(cellValue),
              style: 'alignToRight'
            };

            if (Array.isArray(cellValue.richText)) {
              cell.text = cellValue.richText.map((v) => {
                // remove unknown for pdfmake field "font"
                delete v.font;
                return v;
              });
            }

            // last cell in row
            if (columnIndex === obj.rowData.length - 1) {
              cell = Object.assign(
                {},
                cell,
                {
                  alignment: 'left'
                }
              );
            }

            return cell;
          });
          bodyCells.push(rowData);
        });

        rowsData = rowsData.concat(bodyCells);

        // ==================
        // table footer
        // ==================
        const footerData = reportDataBuilder.getMainTableFooterData(data);

        let footerCells = [
          $translate.instant('global.total'), // It's placed in "From" column
          '', // "To" column, intentionally empty,
          reportDataBuilder.formatDurationInSeconds(footerData.totalParkingDuration),
          reportDataBuilder.formatDurationInSeconds(footerData.totalOnWayDuration),
          footerData.totalDistance,
          '' // max speed, intentionally empty
        ];

        if (footerData.hasTwoTanks) {
          footerCells = footerCells.concat([
            '', // "Main tank" column, intentionally empty
            '' // "Second tank" column, intentionally empty
          ]);
        }

        footerCells.push(footerData.totalFuelUsage);
        footerCells.push(''); // "Address" column, intentionally empty

        footerCells = footerCells.map((cellValue, columnIndex) => {
          let cell = {
            text: cellValue,
            style: 'tableHeader',
            alignment: 'right'
          };

          if (columnIndex === 0) {
            cell = Object.assign(
              {},
              cell,
              {
                alignment: 'left'
              }
            );
          }

          return cell;
        });

        rowsData.push(footerCells);

        // ==================
        // adjust columns width
        // ==================
        const columnsWidth = [
          'auto',
          'auto',
          40,
          40,
          'auto',
          40
        ];

        while (columnsWidth.length < rowsData[0].length - 1) {
          columnsWidth.push('auto');
        }
        columnsWidth.push('*'); // column with address

        doc.content.push({
          style: 'mainTable',
          table: {
            headerRows: 1,
            body: rowsData,
            widths: columnsWidth
          }
        });
      }
    }
  }
})();
