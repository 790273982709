(function () {
  "use strict";

  const events = {
    MAP_CONTROLS_VISIBILITY_CHANGED: 'MAP_CONTROLS_VISIBILITY_CHANGED'
  }

  angular
    .module('app')
    .constant('MapOsmService/events', events);

  angular
    .module('app')
    .factory('MapOsmService', MapOsmService);

  MapOsmService.$inject = [
    '$state',
    '$parse',
    '$localStorage',
    'Session',
    'BackendApi',
    'ChartService',
    'Utils',
    'vehiclesStatusWatcher',
    'customVehicleIconParameters',
    '$translate',
    '$rootScope',
    'dialogControl/events',
    'vehicles.commonReportDialog.events',
    'app.tokens.accessByToken'
  ];

  function MapOsmService($state, $parse, $localStorage, Session,
    BackendApi, ChartService, Utils, vehiclesStatusWatcher,
    customVehicleIconParameters, $translate, $rootScope,
    dialogControlEvents, commonReportDialogEvents, accessByToken) {

    const onLanguageChangedListeners = [];
    $rootScope.$on('$translateChangeEnd', updateOnLanguageChanged)

    function updateOnLanguageChanged() {
      onLanguageChangedListeners.forEach(fn => fn())
    }

    L.Map = L.Map.extend({
      openPopup: function (popup, latlng, options) { // (Popup) or (String || HTMLElement, LatLng[, Object])
        //this.closePopup();

        if (!(popup instanceof L.Popup)) {
          var content = popup;

          popup = new L.Popup(options)
            .setLatLng(latlng)
            .setContent(content);
        }
        popup._isOpen = true;

        this._popup = popup;
        return this.addLayer(popup);
      }
    });

    var lastContextEventTime = -1;
    var isFullScreen = false;

    var currentMarkers = [];
    var routeMarkers = [];
    var markerIndex = 0;
    var maxMarkers = 25;

    /**
     * массив геозон, доступных пользователю. Каждая геозона - объект вида
     * {id:1, wkt:"", owner:123, color:"#777", name:"sample"}
     * @type {Array}
     */
    var geoZones = [];
    var newZoneId = 0;
    var newZone = null;

    /**
     * Режим редактирования геозон. Работает только на странице настроек.
     * @type {boolean}
     */
    var editGeoZones = false;

    /**
     * Отображать геозоны на карте
     * @type {boolean}
     */
    var showGeoZones = false;

    /**
     * Карта спозиционирована к месту трека/авто. Выполняется при первичном получении данных после очистки.
     * @type {boolean}
     */
    var positioned = false;

    /**
     * Эта функцыя срабатывает, когда на карте была нарисована область (зона)
     * @type {function}
     */
    var onDrawCreated = null;
    var refresher = null;

    var currentPositionMarker = null;

    var displayControls = true;
    var trackCurrentPosition = false;

    var drawControl, drawnItems;
    var map;
    var icon00, icon11, icon12, icon10, icon_dir, icon_start, icon_finish;
    var osmLayer;
    var tileLayers;
    var controls = [];
    var maxPointZoom = 16;
    var polilinesLayer;
    var polilinesLayerGroup = [];
    var startFinishMarkers = [];

    var trans = {
      ru: {
        copy: 'Нажмите Ctrl + C чтобы скопировать в буфер обмена',
        controlGeozone: 'Проверить посещение геозон',
        tapInfo: 'Показать/скрыть координаты',
        trackPosition: 'Где я',
      },
      ua: {
        copy: 'Натисніть Ctrl + C щоб скопіювати в буфер обміну',
        controlGeozone: 'Перевірити відвідування геозон',
        tapInfo: 'Показати/приховати координати',
        trackPosition: 'Де я',
      },
      en: {
        copy: 'Press Ctrl + C to copy to buffer',
        controlGeozone: 'Check geozones attendence',
        tapInfo: 'Show/hide coordinates',
        trackPosition: 'Track my position'
      }
    };

    let cachedVehicleData = [];
    let cachedTitleFormatForMarkers = null;
    let cachedShowParking = false;

    let isNowModeActive = false;

    let mapControls = null;

    const shouldShowOnlyMap = accessByToken.isActive && (accessByToken.tokenData || {}).shouldShownOnlyMap
    const shouldShowOnlyShortNames = accessByToken.isActive && (accessByToken.tokenData || {}).shouldUseShortNames

    $rootScope.$on(dialogControlEvents.ENTER_FULLSCREEN_MODE_PRESSED, invalidateMapSizeWithDelay)

    $rootScope.$on(dialogControlEvents.EXIT_FULLSCREEN_MODE_PRESSED, invalidateMapSizeWithDelay)

    $rootScope.$on(commonReportDialogEvents.CLOSED, invalidateMapSizeWithDelay)

    function invalidateMapSizeWithDelay() {
      const center = map.getCenter()

      setTimeout(() => {
        map.invalidateSize()
        map.panTo(center)
      })
    }

    function getTrans(code) {
      var lang = Session.lang;
      return trans[lang][code];
    }

    function setOnDrawCreatedHandler(handler) {
      onDrawCreated = handler;
    }

    function initIcons() {
      var i;
      icon00 = L.icon({
        iconUrl: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAXUlEQVR4nO3TMQ6AIBBE0T/Gg8/Nx4oEFQxCYyH1/kc2AQFh4ewAtqdi22wrtwM/8A0giWbegW2SaAN4i5QYqhVGkTo+ASPINb4BT0grbgItpBcDKOn/ZkkpYG/mAGhZQQW7x6MjAAAAAElFTkSuQmCC',
        iconSize: [16, 16],
        iconAnchor: [8, 16]
      });
      icon10 = L.icon({
        iconUrl: '/assets/img/markers/legacy/10.png',
        iconSize: [24, 24],
        iconAnchor: [12, 24],
        imageSize: 70, // in percents
        imagePosition: 15 // in percents
      });
      icon11 = L.icon({
        iconUrl: '/assets/img/markers/legacy/11.png',
        iconSize: [16, 16],
        iconAnchor: [8, 16]
      });
      icon12 = L.icon({
        iconUrl: '/assets/img/markers/legacy/12.png',
        iconSize: [16, 16],
        iconAnchor: [8, 16]
      });
      icon_dir = [];
      for (i = 0; i < 8; i++)
        icon_dir[i] = L.icon({
          iconUrl: '/assets/img/markers/legacy/dir' + i + '.png',
          iconSize: [16, 16],
          iconAnchor: [8, 8]
        });
      for (i = 0; i < 8; i++)
        icon_dir[i + 8] = L.icon({
          iconUrl: '/assets/img/markers/legacy/rdir' + i + '.png',
          iconSize: [16, 16],
          iconAnchor: [8, 8]
        });
      for (i = 0; i < 8; i++)
        icon_dir[i + 16] = L.icon({
          iconUrl: '/assets/img/markers/legacy/trdir' + i + '.png',
          iconSize: [16, 16],
          iconAnchor: [8, 8]
        });
      icon_start = L.icon({
        iconUrl: '/assets/img/markers/start.png',
        iconSize: [32, 32],
        iconAnchor: [12, 32]
      });
      icon_finish = L.icon({
        iconUrl: '/assets/img/markers/finish.png',
        iconSize: [32, 32],
        iconAnchor: [8, 40]
      });
    }

    function initLayers() {
      var osmUrl = 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
      var osmAttrib = 'Map data © <a href="http://openstreetmap.org">OpenStreetMap</a> contributors';
      osmLayer = new L.TileLayer(osmUrl, {minZoom: 4, maxZoom: 19, attribution: osmAttrib});
      map.addLayer(osmLayer);
    }

    function addZonesToggleControl() {
      var toggle = L.control();

      const attrName = 'data-toggle-geo-zones-visibility';
      onLanguageChangedListeners.push(setTitle);

      function setTitle() {
        const el = document.querySelector(`[${attrName}]`);
        if (!el) {
          return;
        }
        el.title = $translate.instant('mapControls.toggleVisibilityOfGeoZones')
      }

      toggle.onAdd = function (map) {
        this._div = L.DomUtil.create('div');
        this._div.index = 1;
        this._div.className = 'leaflet-bar';
        this._div.innerHTML = '<a href="#">&#9715;</a>';
        this._div.setAttribute(attrName, '');

        setTitle();

        L.DomEvent.on(this._div, 'click', function (e) {
          e.preventDefault();
          showGeoZones = !showGeoZones;
          showGeoZones ? drawZones() : hideZones();
        });
        return this._div;
      };
      toggle.addTo(map);
      controls.push(toggle);
    }

    function showMapWithoutPanel() {
      const contentEl = document.getElementById('content')
      const mapEl = document.getElementById('map')

      contentEl.classList.add('hide-track')
      mapEl.classList.add('flex-100')

      map.invalidateSize();
      ChartService.resize();
    }

    function showMapWithPanel() {
      const contentEl = document.getElementById('content')
      const mapEl = document.getElementById('map')

      contentEl.classList.remove('hide-track')
      mapEl.classList.remove('flex-100')

      map.invalidateSize();
      ChartService.resize();
    }

    function addFullScreenControl() {
      var toggle = L.control();

      const attrName = 'data-toggle-fullscreen-mode';
      onLanguageChangedListeners.push(setTitle);

      function setTitle() {
        const el = document.querySelector(`[${attrName}]`);
        if (!el) {
          return;
        }
        el.title = $translate.instant('mapControls.toggleFullScreenMode')
      }

      toggle.onAdd = function (map) {
        this._div = L.DomUtil.create('div');
        this._div.index = 1;
        this._div.className = 'leaflet-bar';
        this._div.innerHTML = '<a href="#"><img src="/assets/img/fullscreen.png" style="width: 16px;height: 16px;margin-top: 4px"></a>';
        this._div.setAttribute(attrName, '');

        setTitle();

        L.DomEvent.on(toggle._div, 'click', function (e) {
          e.preventDefault();
          isFullScreen = !isFullScreen;

          if (isFullScreen) {
            showMapWithoutPanel()
          } else {
            showMapWithPanel()
          }
        });
        return this._div;
      };
      toggle.addTo(map);
      controls.push(toggle);
    }

    function addTapInfoControl() {
      var toggle = L.control({position:'topleft'});

      toggle.onAdd = function (map) {
        this._div = L.DomUtil.create('div');
        this._div.index = 9999;
        this._div.className = 'leaflet-bar';
        this._div.innerHTML = '<a href="#"><img src="/assets/img/tap.png" style="width: 16px;height: 16px;margin-top: 8px"></a>';
        this._div.title = getTrans('tapInfo');

        var options = {
          promptText: getTrans('copy')
        };

        var controlCoordinates = new L.Control.Coordinates(options);
        var displayCoordinatesOnTap = false;

        L.DomEvent.on(toggle._div, 'click', function (e) {
          e.preventDefault();

          displayCoordinatesOnTap = !displayCoordinatesOnTap;

          if(displayCoordinatesOnTap) {
            controlCoordinates.addTo(map);
          } else {
            controlCoordinates.remove();
          }


          map.on('click', function(e) { // displaying coordinates on map
            if (displayCoordinatesOnTap) {
              controlCoordinates.setCoordinates(e);
            }
          });
        });

        return this._div;
      };

      toggle.addTo(map);
      controls.push(toggle);
    }

    function addTrackPositionControl() {
      var toggle = L.control({position:'topleft'});

      toggle.onAdd = function (map) {
        this._div = L.DomUtil.create('div');
        this._div.index = 9999;
        this._div.className = 'leaflet-bar';
        this._div.innerHTML = '<a href="#"><img src="/assets/img/cursor_black.png" style="width: 16px;height: 16px;margin-top: 8px"></a>';
        this._div.title = getTrans('trackPosition');

        L.DomEvent.on(toggle._div, 'click', function (e) {
          e.preventDefault();
          trackCurrentPosition = !trackCurrentPosition;

          if (trackCurrentPosition) {
            map.locate({watch: true});
          } else {
            map.stopLocate();
            clearCurrentLocation();
          }
        });
        return this._div;
      };
      toggle.addTo(map);
      controls.push(toggle);
    }

    function addControlsVisibilityHandlerControl() {
      var toggle = L.control();

      const attrName = 'data-toggle-map-buttons-visibility';
      onLanguageChangedListeners.push(setTitle)

      function setTitle() {
        const el = document.querySelector(`[${attrName}]`);
        if (!el) {
          return;
        }
        el.title = $translate.instant('mapControls.toggleMapButtonsVisibility')
      }

      toggle.onAdd = function (map) {
        this._div = L.DomUtil.create('div');
        this._div.index = 9999;
        this._div.className = 'leaflet-bar show-hide-btn';
        this._div.innerHTML = '<a href="#"><img src="/assets/img/hide-keyboard-button.png" style="width: 16px;height: 16px;margin-top: 4px"></a>';
        this._div.setAttribute(attrName, '');

        setTitle();

        function toggleMapControlVisibility() {
          displayControls = !displayControls;
          var allControls = document.querySelectorAll('.leaflet-control:not(.show-hide-btn)');

          allControls.forEach(function (control) {
            control.setAttribute('is-visible-map-control', displayControls);
          });

          $rootScope.$emit(events.MAP_CONTROLS_VISIBILITY_CHANGED, displayControls)
        }

        // hide controls on mobile devices by default
        if (Utils.detectmob()) {
          toggleMapControlVisibility();
        }

        L.DomEvent.on(toggle._div, 'click', function (e) {
          e.preventDefault();
          toggleMapControlVisibility();
        });
        return this._div;
      };
      toggle.addTo(map);
      controls.push(toggle);
    }

    function addShortNamesToggleControl() {
      var toggle = L.control();

      const attrName = 'data-toggle-short-names-for-vehicles';
      onLanguageChangedListeners.push(setTitle)

      toggle.onAdd = function (map) {
        this._div = L.DomUtil.create('div');
        this._div.index = 1;
        this._div.className = 'leaflet-bar';
        this._div.innerHTML = '<a href="#">A</a>';
        this._div.setAttribute(attrName, '')

        setTitle();

        L.DomEvent.on(toggle._div, 'click', function (e) {
          e.preventDefault();
          var displayShortNames = +$parse('user.settings.displayShortNames')(Session);
          displayShortNames = +!displayShortNames;

          if (currentMarkers.length < 200) {//если маркеров много: то мы их уже не показываем и не будем
            _.each(currentMarkers, function (el) {
              drawMarker(null, el, null, displayShortNames);
            });
          }

          vehiclesStatusWatcher.onShowInfoForAllMarkersValueChanged(displayShortNames);

          saveSettings(function () {
            return BackendApi.saveSettings({displayShortNames: displayShortNames});
          });
        });
        return this._div;
      };
      toggle.addTo(map);
      controls.push(toggle);

      function setTitle() {
        const el = document.querySelector(`[${attrName}]`);
        if (!el) {
          return;
        }
        el.title = $translate.instant('mapControls.toggleVehicleNamesForVehicles')
      }
    }

    function addZoneNamesToggleControl() {
      var toggle = L.control();

      const attrName = 'data-toggle-visibility-of-geo-zone-names';
      onLanguageChangedListeners.push(setTitle)

      function setTitle() {
        const el = document.querySelector(`[${attrName}]`);
        if (!el) {
          return;
        }
        el.title = $translate.instant('mapControls.toggleNamesForGeoZones')
      }

      toggle.onAdd = function (map) {
        this._div = L.DomUtil.create('div');
        this._div.index = 1;
        this._div.className = 'leaflet-bar';
        this._div.innerHTML = '<a href="#">G</a>';
        this._div.setAttribute(attrName, '');

        setTitle()

        L.DomEvent.on(toggle._div, 'click', function (e) {
          e.preventDefault();
          var displayZoneNames = +$parse('user.settings.displayZoneNames')(Session);
          displayZoneNames = +!displayZoneNames;
          showGeoZones = !!displayZoneNames;
          drawZones(displayZoneNames);
          saveSettings(function () {
            return BackendApi.saveSettings({displayZoneNames: displayZoneNames});
          });
        });
        return this._div;
      };
      toggle.addTo(map);
      controls.push(toggle);
    }

    // ======================
    // custom icons for vehicles
    // ======================
    let shouldShowCustomIconsForVehicles = false;
    let toggleForCustomIconsControl = null;
    const IS_HIDDEN_MAP_CONTROL_ATTR_NAME = 'is-permanently-hidden-map-control';

    function updateVisibilityOfToggleForCustomIconsControl(hasAtLeastOneVehicleWithCustomIcon) {
      const el = toggleForCustomIconsControl.getContainer();
      if (!hasAtLeastOneVehicleWithCustomIcon) {
        el.setAttribute(IS_HIDDEN_MAP_CONTROL_ATTR_NAME, '');
      } else {
        el.removeAttribute(IS_HIDDEN_MAP_CONTROL_ATTR_NAME);
      }
    }

    function addToggleCustomIconsForVehiclesControl() {
      const attrName = 'data-toggle-custom-icons-for-vehicles';
      onLanguageChangedListeners.push(setTitle)

      toggleForCustomIconsControl = L.control();
      toggleForCustomIconsControl.onAdd = onAddedToMap;
      toggleForCustomIconsControl.addTo(map);
      controls.push(toggleForCustomIconsControl);

      function onAddedToMap() {
        const div = L.DomUtil.create('div');
        div.index = 1;
        div.className = 'leaflet-bar';
        div.innerHTML = '<a href="#">AV</a>';
        div.setAttribute(attrName, '')
        L.DomEvent.on(div, 'click', onClick);

        setTitle();

        return div;
      }

      function onClick() {
        shouldShowCustomIconsForVehicles = !shouldShowCustomIconsForVehicles;

        if (isNowModeActive) {
          clearCustomIconForExistingMarkers();

          if (shouldShowCustomIconsForVehicles && currentMarkers.length > 0) {
            const marker = currentMarkers[currentMarkers.length - 1];
            drawMarker(
              marker.item,
              marker,
              marker.extraSettings.titleFormat,
              marker.extraSettings.drawInfoWindows,
              marker.extraSettings.showParking,
              true
            );
          }
          return;
        }

        drawMarkersSet(cachedVehicleData, cachedTitleFormatForMarkers, cachedShowParking);
      }

      function setTitle() {
        const el = document.querySelector(`[${attrName}]`);
        if (!el) {
          return;
        }
        el.title = $translate.instant('mapControls.toggleCustomIconsForVehicles')
      }
    }

    // ======================
    // / custom icons for vehicles
    // ======================

    function addZoneVisitsControl() {
      var toggle = L.control();

      toggle.onAdd = function (map) {
        this._div = L.DomUtil.create('div');
        this._div.index = 1;
        this._div.className = 'leaflet-bar';
        this._div.innerHTML = '<a href="#">?</a>';
        this._div.title = getTrans('controlGeozone');

        L.DomEvent.on(toggle._div, 'click', function (e) {
          e.preventDefault();
          var from = new Date();
          from.setHours(0, 0, 0, 0);
          var to = new Date();
          to.setHours(23, 59, 0, 0);
          // gps.service.getVisitedZones(from, to, gps.app.getState().cars);
        });
        return this._div;
      };
      toggle.addTo(map);
      controls.push(toggle);
    }

    function addLayersControl(all, layerName) {
      const trafficJamsLayerId = 'data-traffic-jams'
      const hybridLayerId = 'data-hybrid'

      function updateTitle(id, i18nKey) {
        const el = document.querySelector(`[${id}]`)
        if (!el) {
          return
        }
        el.innerHTML = $translate.instant(i18nKey)
      }

      const updateTitles = () => {
        [
          () => updateTitle(trafficJamsLayerId, 'mapControls.trafficJams'),
          () => updateTitle(hybridLayerId, 'mapControls.hybrid'),
        ].forEach(fn => fn())
      }

      onLanguageChangedListeners.push(updateTitles)

      if (layerName !== 'OSM') layerName = 'OSM';
      if (all) {
        var yandexLayer = new L.Yandex();
        var googleLayer = new L.Google('ROADMAP');
        var googlePhotoLayer = new L.Google('HYBRID');
        var trafficLayer = new L.Yandex("null", {
          traffic: true,
          opacity: 0.8,
          overlay: true
        });
        var overlayLayers = {
          [`<span ${trafficJamsLayerId}></span>`]: trafficLayer
        };

        tileLayers = {
          'OSM': osmLayer,
          'Yandex': yandexLayer,
          'Google': googleLayer,
          [`<span ${hybridLayerId}></span>`]: googlePhotoLayer
        };
        // if (layerName !== 'OSM' && tileLayers[layerName]) {
        //   map.removeLayer(osmLayer);
        // }
        map.addLayer(tileLayers[layerName]);
        var control = new L.Control.Layers(tileLayers, overlayLayers);
        controls.push(control);
        map.addControl(control);
      }

      updateTitles()
    }

    function pointInPoly(point, vs) {
      // ray-casting algorithm based on
      // http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html
      vs = vs.toGeoJSON().geometry.coordinates[0];
      var x = point[1], y = point[0];
      var inside = false;
      var i = 0,
        j = vs.length - 1;
      while (i < vs.length) {
        var xi = vs[i][0], yi = vs[i][1];
        var xj = vs[j][0], yj = vs[j][1];

        var intersect = ((yi > y) != (yj > y))
          && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
        j = i;
        i++;
      }
      return inside;
    }

    function getZoneById(id) {
      return _.findWhere(geoZones, {id: id});
    }

    function attachEventHandlers() {
      map.on('draw:created', function (e) {
        console.log('--------------- draw:create', e);
        var layer = e.layer;
        console.log('--------------- layer', JSON.stringify(layer.toGeoJSON()));
        drawnItems.addLayer(layer);
        layer.editing.enable();
        cancelCurrentZone();
        newZone = layer;
        newZone.setStyle({weight: 3, color: '#990000', fillColor: '#FFFFFF', fillOpacity: 0.6});
        // gps.events.emit(gps.events.types.zoneAdded, '#FFFFFF');
        if (onDrawCreated) {
          onDrawCreated(newZone);
        }
      });
      map.on('baselayerchange', function (e) {
        // saveSettings(function () {
        //   return BackendApi.saveSettings({mapLayer: e.name});
        // });
      });
      map.on('contextmenu', function (e) {
        var time = new Date().getTime();
        if (time - lastContextEventTime < 300) {
          map.zoomOut();
        }
        lastContextEventTime = time;
      });
      /*gps.events.on(gps.events.types.visitorsData, function(data){
       var i;
       for(i=0;i<geoZones.length;i++){
       var id = geoZones[i].id;
       if(data.indexOf(id)>=0){
       geoZones[i].overlay.setStyle({fillColor:'#0000ff'});
       }else{
       geoZones[i].overlay.setStyle({fillColor:'#ff0000'});
       }
       }
       });*/
    }

    function initMapSize() {
      map.invalidateSize();
    }

    function initMap() {
      const userDataWithAccessByToken = {
        zones: []
      }
      var data = angular.copy(Session.user || userDataWithAccessByToken);
      geoZones = data.zones || [];
      /*
      {
        id: number,
        coordinates: [number, number][],
        name: string
      }[]
       */
      data.permissions && addLayersControl(data.loginType !== '4', data.settings.mapLayer);

      if (data.permissions && data.permissions.indexOf('geo-zones') != -1) {
        addZonesToggleControl();
        addZoneNamesToggleControl();
        /*if (data.permissions.indexOf('geo-visits') != -1) {
          addZoneVisitsControl();
        }*/
        if (geoZones.length < 1000) {
          showGeoZones = true;
          drawZones(false);
        }
      }
      if (data.permissions) {
        addShortNamesToggleControl();

        if (!shouldShowOnlyMap) {
          addFullScreenControl();
        }

        L.Control.measureControl().addTo(map);
        addTapInfoControl();
        addToggleCustomIconsForVehiclesControl();
        addControlsVisibilityHandlerControl();
        // addTrackPositionControl();
      }
      // gps.ui.renderZones(data.zones ? data.zones: [], data.loginName);

      initCurrentLocationListeners();

      if (shouldShowOnlyMap) {
        showMapWithoutPanel()
      }

      if (shouldShowOnlyShortNames) {

      }
    }

    function init() {
      map = L.map('osm-map-container', {closePopupOnClick: false});

      L.control.scale({imperial: false}).addTo(map);
      initLayers();
      initIcons();
      attachEventHandlers();
      initMap();
      // initRefresher();
    }

    function initRefresher() {
      refresher = new Refresher.create();
      refresher
        .init({
          onStart: function () {

          },
          onInterval: function () {

          }
        })
        .start();
    }

    function initCurrentLocationListeners() {
      map.on('locationfound', function (e) {
        clearCurrentLocation();

        if (trackCurrentPosition) {
          var radius = e.accuracy;

          var icon = L.icon({
            iconUrl: '/assets/img/markers/circle-blue.png',
            iconSize: [24, 24],
            iconAnchor: [12, 12]
          });

          currentPositionMarker = L.marker(e.latlng, {icon: icon});
          currentPositionMarker.addTo(map);
        }
      });

      map.on('locationerror', function () {
        trackCurrentPosition = false;
      });
    }

    function clearCurrentLocation() {
      if (currentPositionMarker) {
        map.removeLayer(currentPositionMarker);
        currentPositionMarker = null;
      }
    }

    function clear() {
      while (currentMarkers.length) {
        var marker = currentMarkers.pop();
        if (typeof marker.item !== 'undefined') {
          vehiclesStatusWatcher.onMarkerRemoved(marker.item.id);
        }
        map.removeLayer(marker);
      }
      markerIndex = 0;
      positioned = false;
      if (newZone !== null) {
        map.removeLayer(newZone);
        newZone = null;
      }
    }

    function refresh() {
      map.invalidateSize();
    }

    function drawTrack(xml) {
      //todo:implement
    }

    function getMarkerIcon(item, showParking, useCustomIcon = false) {
      const speed = parseInt(item.speed, 10);
      const shouldShowParkingIcon = !!((!showParking && speed === 0) || (showParking && item.isParking));
      const parkingIcon = icon12;

      var icon;

      switch (true) {
        case `${item.status}` !== '1':
          icon = icon00
          break
        case item.isVehicleWithControversialPosition:
          icon = icon10
          break
        case item.gpsStatus >= 1:
          if (shouldShowParkingIcon) {
            icon = parkingIcon;
          } else if (speed < 5) {
            icon = icon11;
          } else {
            let speedIconId;
            switch (true) {
              case speed > parseInt(item.speedLimit2, 10):
                speedIconId = 16;
                break;
              case speed > parseInt(item.speedLimit, 10):
                speedIconId = 8;
                break;
              default:
                speedIconId = 0;
            }

            icon = icon_dir[speedIconId];
          }
          break
        default:
          icon = icon10
      }

      let rotationAngle = 0;
      if (item.speed >= 5 || item.isVehicleWithControversialPosition) {
        rotationAngle = item.direction;
      }

      if (!useCustomIcon) {
        let imageSize = parseFloat(icon.options.imageSize)
        if (isNaN(imageSize)) {
          imageSize = 100
        }

        let imagePosition = parseFloat(icon.options.imagePosition)
        if (isNaN(imagePosition)) {
          imagePosition = 0
        }

        return L.divIcon({
          html: `
            <?xml version="1.0" encoding="utf-8"?>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100">
              <image
                href="${icon.options.iconUrl}"
                x="${imagePosition}%"
                y="${imagePosition}%"
                width="${imageSize}%"
                height="${imageSize}%"
                transform="rotate(${rotationAngle})"
                transform-origin="50% 50%"
              />
            </svg>
          `,
          iconSize: [icon.options.iconSize[0], icon.options.iconSize[1]],
          iconAnchor: [icon.options.iconAnchor[0], icon.options.iconAnchor[1]],
          className: 'vehicle-icon'
        });
      }

      const customIconSize = 54;

      const customIconFileName = customVehicleIconParameters.iconIdToIconFileNameMap[item.vehicleIcon];

      let iconColor = customVehicleIconParameters.defaultIconColor;
      if (customVehicleIconParameters.iconColors.includes(item.vehicleIconColor)) {
        iconColor = item.vehicleIconColor;
      }

      const strokeWidth = customVehicleIconParameters.getStrokeWidth(item.vehicleIcon);

      const imgX = (customIconSize - icon.options.iconSize[0]) / customIconSize * 50;
      const imgY = (customIconSize - icon.options.iconSize[1]) / customIconSize * 50;

      const imgWidth = icon.options.iconSize[0] / customIconSize * 100;
      const imgHeight = icon.options.iconSize[1] / customIconSize * 100;

      const iconAnchorX = customIconSize / 2;
      const iconAnchorY = customIconSize / 2 + (icon.options.iconAnchor[1] - icon.options.iconSize[1] / 2)

      return L.divIcon({
        html: `
          <?xml version="1.0" encoding="utf-8"?>
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100">
            <image
              href="${icon.options.iconUrl}"
              width="${imgWidth}%"
              height="${imgHeight}%"
              x="${imgX}"
              y="${imgY}%"
              transform="rotate(${rotationAngle})"
              transform-origin="50% 50%"
            />
            <use
              href="${customVehicleIconParameters.getCustomIconUrl(customIconFileName)}"
              stroke="${iconColor}"
              stroke-width="${strokeWidth}"
              fill="transparent"
              width="100%"
              height="100%"
              transform="rotate(${rotationAngle})"
              transform-origin="50% 50%"
            ></use>
          </svg>
       `,
        iconSize: [customIconSize, customIconSize],
        iconAnchor: [iconAnchorX, iconAnchorY],
        className: 'vehicle-icon'
      });
    }

    function drawMarker(item, marker, titleFormat, drawInfoWindows, showParking, useCustomIcon = false) {
      var position, icon, title, inHour = $localStorage.lang === 'ru' ? 'ч, ' : 'г, ';
      if (!item) {
        if (marker && marker.item) {
          item = marker.item;
        } else {
          return null;
        }
      }
      if (typeof titleFormat == 'undefined' || titleFormat === null) titleFormat = '{name}';
      position = [item.latitude, item.longitude];

      icon = getMarkerIcon(item, showParking, useCustomIcon);

      title = titleFormat
        .replace('{name}', item.short || item.name)
        .replace('{speed}', item.speed)
        .replace('{voltage}', item.voltage)
        .replace('{signal}', item.signalLevel)
        .replace('{stopTime}', item.stopTime ? (item.stopTime + inHour) : '')
        .replace('{time}', moment.unix(item.timestamp).format('DD-MM-YYYY HH:mm:ss'));

      if (marker) {
        marker.setIcon(icon);
        marker.setLatLng(position);
        marker.title = title;
      } else {
        marker = L.marker(position, {icon: icon, title: title});
        marker.addTo(map);
        marker.on('click', function (e) {
          if (e.target.item && e.target.item.id) {
            $state.go('vehicles.details.now', {id: e.target.item.id});
          } else if (L.Browser.mobile && marker.options.title) {
            // show title on mobile devices
            if (!marker.getTooltip()) {
              marker.bindTooltip(marker.options.title);
            }
            marker.closeTooltip();
            marker.openTooltip();
          }
        });
      }
      marker.item = item;
      marker.extraSettings = {
        titleFormat,
        drawInfoWindows,
        showParking
      };

      if (drawInfoWindows && (item.short || item.name)) {
        let title;
        if (shouldShowOnlyShortNames && item.short) {
          title = item.short
        } else {
          title = item.short || item.name
        }

        if (!marker.getPopup()) {
          marker.bindPopup(title, {closeButton: false, className: 'popup', autoPan: false, autoClose: false});
          marker.openPopup();
        } else {
          marker.setPopupContent(title);
        }
      } else {
        if (marker.getPopup()) {
          marker.closePopup();
          marker.unbindPopup();
        }
      }

      var zIndex;
      if (icon === icon12) {
        zIndex = 110;
      } else if (icon === icon10) {
        zIndex = 90;
      } else if (icon === icon00) {
        zIndex = 80;
      } else if (icon === icon11) {
        zIndex = 70;
      } else {
        zIndex = 100;
      }
      marker.setZIndexOffset(zIndex);

      return marker;
    }

    function clearCustomIconForExistingMarkers() {
      currentMarkers.forEach((marker) => {
        drawMarker(
          marker.item,
          marker,
          marker.extraSettings.titleFormat,
          marker.extraSettings.drawInfoWindows,
          marker.extraSettings.showParking,
          false
        );
      });
    }

    function drawMarkersSet(data, titleFormat, showParking, isDataForAllVehicles = false) {
      cachedVehicleData = data;
      cachedTitleFormatForMarkers = titleFormat;
      cachedShowParking = showParking;

      var minLat = 90,
        minLong = 180,
        maxLat = -90,
        maxLong = -180,
        bounds;

      var drawInfoWindows = data.length < 15 && +$parse('user.settings.displayShortNames')(Session);

      const dataForStatusWatcher = [];

      let hasAtLeastOneVehicleWithCustomIcon = false;

      const knownIds = {}

      data.forEach((obj) => {
        if (typeof obj.id === 'undefined') {
          return;
        }
        knownIds[obj.id] = true;
      })
      const isSetOfMarkersForDifferentVehicles = Object.keys(knownIds).length > 0;

      _.each(data, function (item, i) {
        if (!positioned) {
          minLat = Math.min(minLat, item.latitude);
          minLong = Math.min(minLong, item.longitude);
          maxLat = Math.max(maxLat, item.latitude);
          maxLong = Math.max(maxLong, item.longitude);
        }

        currentMarkers[i] = drawMarker(
          item,
          currentMarkers[i],
          titleFormat,
          drawInfoWindows,
          showParking,
          isSetOfMarkersForDifferentVehicles ?
            shouldShowCustomIconsForVehicles : shouldShowCustomIconsForVehicles && i === data.length - 1
        );

        hasAtLeastOneVehicleWithCustomIcon =
          hasAtLeastOneVehicleWithCustomIcon || checkIfHasCustomIcon(item);

        dataForStatusWatcher.push({
          vehicleData: item,
          marker: currentMarkers[i]
        });
      });

      if (isDataForAllVehicles) {
        vehiclesStatusWatcher.update(dataForStatusWatcher);
      } else {
        vehiclesStatusWatcher.stopWatching();
      }

      if (!positioned && data.length) {
        bounds = L.latLngBounds([minLat, minLong], [maxLat, maxLong]);

        map.fitBounds(bounds);

        positioned = true;
      }

      updateVisibilityOfToggleForCustomIconsControl(hasAtLeastOneVehicleWithCustomIcon);
    }

    function drawPolilines(polylinePoints, color, weight) {
      clearPolilines();
      clearStartFinishMarkers();
      appendStartFinishMarkers(polylinePoints[0], polylinePoints[polylinePoints.length - 1])
      L.marker()
      polilinesLayer = L.polyline(polylinePoints, {
        color: color,
        weight: weight,
        smoothFactor: 2
      }).addTo(map);
    }

    function drawPolilinesGroup(polylinePoints, color, weight) {
      clearStartFinishMarkers();
      polilinesLayerGroup.push(L.polyline(polylinePoints, {
          color: color,
          weight: weight,
          smoothFactor: 2
        }).addTo(map));
    }

    function clearPolilines() {
      polilinesLayer && map.removeLayer(polilinesLayer);
    }

    function clearPolilinesGroup() {
      polilinesLayerGroup.length && _.each(polilinesLayerGroup, function (poliline) { return map.removeLayer(poliline) });
      polilinesLayerGroup = [];
    }

    function appendStartFinishMarkers(start, finish) {
      startFinishMarkers = [
        L.marker(start, {icon: icon_start}).addTo(map),
        L.marker(finish, {icon: icon_finish}).addTo(map)
      ];
    }

    function clearStartFinishMarkers() {
      startFinishMarkers.length && _.each(startFinishMarkers, function (marker) { return map.removeLayer(marker) });
      startFinishMarkers = [];
    }

    // ======================
    // forwarder route for access by token
    // ======================
    const greenColor = '#28a745'
    const yellowColor = '#ffff00'
    let previousCurrentPositionMarker = null
    let isMapInitializedForTokenView = false
    let forwarderRouteMarkers = []

    function drawCurrentPositionOfVehicleForAccessByToken(vehicleStatus) {
      if (previousCurrentPositionMarker !== null) {
        map.removeLayer(previousCurrentPositionMarker);
      }

      if (!isMapInitializedForTokenView) {
        isMapInitializedForTokenView = true

        map.setZoom(15)
        map.panTo([
          vehicleStatus.latitude,
          vehicleStatus.longitude
        ])
        map.invalidateSize();
      }

      previousCurrentPositionMarker = drawMarker(
        vehicleStatus,
        null,
        null,
        false,
        false,
        shouldShowCustomIconsForVehicles
      )
    }

    function drawForwarderRouteForAccessByToken(forwarderRoutePoints, onRouteFound) {
      const routingControl = L.Routing.control({
        waypoints: forwarderRoutePoints.map(obj => L.latLng(obj.lat, obj.lng)),
        router: L.Routing.osrmv1({
          serviceUrl: '/route/v1',
          useHints: false
        }),
        lineOptions: {
          styles: [{
            color: '#28a745',
            opacity: 1,
            weight: 4
          }],
          addWaypoints: false
        },
        createMarker: function (index, wayPoint, totalLength) {
          let title
          switch (index) {
            case 0:
              title = 'S'
              break
            case totalLength - 1:
              title = 'F'
              break
            default:
              title = `${index}`
          }

          const marker = L.marker(wayPoint.latLng, {
            icon: getColoredIcon(yellowColor, title)
          })

          forwarderRouteMarkers.push({
            marker,
            title,
            zoneCoordinates: forwarderRoutePoints[index].zoneCoordinates,
            isVisited: false
          })

          return marker
        },
      })

      routingControl.addTo(map)

      routingControl.on('routesfound', (data) => {
        if (typeof onRouteFound === 'function') {
          onRouteFound(data)
        }
      })
    }

    function updateForwarderRouteMarkersForAccessByToken(visitedPoints = []) {
      visitedPoints.forEach((point) => {
        forwarderRouteMarkers.forEach((obj) => {
          if (obj.isVisited) {
            return
          }

          const zoneCoordinates = obj.zoneCoordinates[0].map(obj => [obj.lat, obj.lng])
          const isVisited = L.polygon(zoneCoordinates).contains({
            lat: point.latitude,
            lng: point.longitude
          })

          if (!isVisited) {
            return;
          }

          markMarkerOfForwarderRouteAsVisited(obj)
        })
      })
    }

    function markMarkerOfForwarderRouteAsVisited(obj) {
      obj.isVisited = true
      obj.marker.setIcon(getColoredIcon(greenColor, obj.title))
    }

    // ======================
    // /forwarder route for access by token
    // ======================

    function appendMarker(item) {
      var p = [item.latitude, item.longitude];

      if (!positioned) {
        map.setZoom(15);
        map.panTo(p);
        map.invalidateSize();
        positioned = true;
      } else {
        if (!map.getBounds().contains(p)) {
          map.panTo(p);
        }
      }

      while (currentMarkers.length >= maxMarkers) {
        const marker = currentMarkers.shift();
        map.removeLayer(marker);
      }

      clearCustomIconForExistingMarkers();

      currentMarkers.push(drawMarker(
        item,
        null,
        null,
        false,
        false,
        shouldShowCustomIconsForVehicles
      ));

      updateVisibilityOfToggleForCustomIconsControl(checkIfHasCustomIcon(item));
    }

    function checkIfHasCustomIcon(item) {
      return item.vehicleIcon !== '' && item.vehicleIcon !== '0';
    }

    function goToPlace(coords, zoom, marker) {
      if (_.isUndefined(zoom)) {
        zoom = 15
      }

      if (marker) {
        clear();
        var icon = L.icon({
          iconUrl: '/assets/img/markers/marker-icon.png',
          iconSize: [25, 41],
          iconAnchor: [12, 41]
        });
        var m = L.marker(coords, {icon: icon, bounceOnAdd: true}).addTo(map);
        currentMarkers.push(m);
      }
      map.setZoom(zoom);
      map.panTo(coords);
    }

    function showStop(latitude, longitude) {
      // clear();
      var p = [latitude, longitude];
      if (!map.getBounds().contains(p)) {
        map.panTo(p);
      }
      var icon = L.icon({
        iconUrl: '/assets/img/markers/marker-icon.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41]
      });
      var m = L.marker(p, {icon: icon, bounceOnAdd: true}).addTo(map);
      currentMarkers.push(m);
    }

    function drawZones(withNamesSettings) {
      if (typeof withNamesSettings === 'undefined') {
        withNamesSettings = +$parse('user.settings.displayZoneNames')(Session);
      }
      _.each(geoZones, function (el) {
        drawZone(el, !!withNamesSettings);
      });
    }

    function showZones () {
      if (showGeoZones) {
        drawZones();
      }
    }

    function hideZones() {
      _.each(geoZones, function (el) {
        hideZone(el);
      });
    }

    function clearZones() {
      hideZones();
      geoZones = null;
      showGeoZones = false;
    }

    function hideZone(zone) {
      if (zone.overlay) {
        if (zone.overlay._popup) {
          zone.overlay.closePopup();
          zone.overlay.unbindPopup();
        }
        map.removeLayer(zone.overlay);
        zone.overlay = undefined;
      }
    }

    function drawZone(zone, displayName) {
      var wkt, obj;
      if (zone.overlay) {
        hideZone(zone);
      }
      zone.weight = 1;
      zone.fillColor = zone.color;
      /*wkt = new Wkt.Wkt();
       wkt.read(zone.wkt);
       obj = wkt.toObject(map.defaults);
       obj.setStyle({fillColor: zone.color, fillOpacity: 0.6, opacity: 1, weight: 3, color: zone.color});*/
      // obj.addTo(map);

      var obj = L.polygon(zone.coordinates, {
        fillColor: zone.color,
        fillOpacity: 0.6,
        opacity: 1,
        weight: 3,
        color: zone.color
      }).addTo(map);
      if (displayName && (zone.name)) {
        obj.bindPopup(zone.name, {closeButton: false, className: 'popup', autoPan: false, autoClose: false});
        obj.openPopup();
      }
      zone.overlay = obj;
    }

    function showEditInterface(options) {
      // Initialise the FeatureGroup to store editable layers
      drawnItems = new L.FeatureGroup();
      if (!map) return;
      map.addLayer(drawnItems);

      // Initialise the draw control and pass it the FeatureGroup of editable layers
      drawControl = new L.Control.Draw({
        draw: {
          polyline: options.polyline && false,
          polygon: options.polygon && {
            allowIntersection: false, // Restricts shapes to simple polygons
            drawError: {
              color: '#e1e100', // Color the shape will turn when intersects
              message: '<strong>Oh snap!<strong> you can\'t draw that!' // Message that will show when intersect
            },
            shapeOptions: {
              color: '#bada55'
            }
          },
          circle: options.circle && false, // Turns off this drawing tool
          rectangle: options.rectangle && {
            shapeOptions: {
              clickable: false
            }
          },
          marker: options.marker && false
        },
        edit: {
          featureGroup: drawnItems,
          edit: false,
          remove: false
        }
      });
      map.addControl(drawControl);
    }

    function hideEditInterface() {
      drawControl && map.removeControl(drawControl);
      drawnItems && map.removeLayer(drawnItems);
    }

    function zoneBounds(coordinates) {
      var minLat = 90,
        minLong = 180,
        maxLat = -90,
        maxLong = -180,
        bounds;
      _.each(coordinates[0], function (item) {
        minLat = Math.min(minLat, item.lat);
        minLong = Math.min(minLong, item.lng);
        maxLat = Math.max(maxLat, item.lat);
        maxLong = Math.max(maxLong, item.lng);
      });
      bounds = L.latLngBounds([minLat, minLong], [maxLat, maxLong]);
      map.fitBounds(bounds, {padding: [1, 1]});
      map.panTo(bounds.getCenter());
      /*setTimeout(function () {
        map.panBy([1, 1]);
      }, 1);
      map.invalidateSize();*/
    }

    function startGeoEdit() {
      if (!editGeoZones) {
        drawZones();
        showEditInterface({
          polyline: false,
          polygon: true,
          circle: false,
          rectangle: true,
          marker: false
        });
        editGeoZones = true;
      }
    }

    function stopGeoEdit() {
      if (editGeoZones) {
        if (showGeoZones) {
          drawZones();
        } else {
          hideZones();
        }
        hideEditInterface();
        editGeoZones = false;
      }
    }

    function cancelCurrentZone() {
      if (newZone) {
        if (newZoneId !== 0) {
          newZone.setStyle({weight: 2});
          newZone.editing.disable();
        } else {
          map.removeLayer(newZone);
        }
        newZoneId = 0;
        newZone = null;
      }
    }

    function startEditZone(id) {
      cancelCurrentZone();
      var findZone = _.findWhere(geoZones, {id: id});
      if (findZone) {
        newZone = findZone;
        if (!newZone.overlay) {
          drawZone(newZone);
        }
        newZone.overlay.setStyle({strokeWeight: 3});
        newZone.overlay.editing.enable();
        newZoneId = id;
        newZone = newZone.overlay;
        zoneBounds(newZone._latlngs);
      }
    }

    function zoneColorChanged(color) {
      newZone && newZone.setStyle({fillColor: color, color: color});
    }

    function zoneDeleted(id) {
      if (newZoneId === id) {
        cancelCurrentZone();
      }
      _.find(geoZones, function (el, pos) {
        if (el.id === id) {
          hideZone(el);
          geoZones.splice(pos, 1);
          return true;
        }
      });
    }

    function zoneSaved(zone) {
      var findZone = zone.id && _.findWhere(geoZones, {id: zone.id}),
        coordinates = newZone.toGeoJSON().geometry.coordinates[0],
        wkt = 'POLYGON((' + _.map(coordinates, function (el) {
          return el.join(' ');
        }).join(",") + '))'
      ;
      coordinates = _.map(coordinates, function (el) {
        return [el[1], el[0]];
      });

      if (findZone) {
        _.extend(findZone, _.pick(zone, 'name', 'color', 'login', 'days'));
      } else {
        findZone = _.pick(zone, 'name', 'color', 'login', 'days');
        cancelCurrentZone();
      }

      findZone.wkt = wkt;
      findZone.coordinates = coordinates;

      return saveSettings(function () {
        return BackendApi.saveZone({zone: findZone}).then(function (createdZone) {
          if (!zone.id) { // add new geozone to list
            findZone.id = createdZone.id;
            geoZones.push(findZone);
            geoZones.sort(function (a, b) {
              return a.name.localeCompare(b.name);
            });
          }
          drawZone(findZone);
        });
      });
    }

    function saveSettings(func) {
      return func()
        .then(function () {
          return BackendApi.getProfile().then(function() {
            BackendApi.getCarByGroup({id: Session.groupId}).then(function (result) {
              return result.cars;
            });
          });
        });
    }

    function drawLogisticRouteMarkers(points, needToCenterMap) {

      if (!points.length) {
        return;
      }

      var pointsWithCoordinates = _.filter(points, {hasCoordinates: true});

      _.each(pointsWithCoordinates, function (point) {
        point.marker.addTo(map);
        routeMarkers.push(point.marker);
      });
      if (needToCenterMap) {
        centerMapByPoints(pointsWithCoordinates);
      }
    }

    function centerMapByPoints(points) {
      if (points.length) {
        var minLat = 90,
          minLong = 180,
          maxLat = -90,
          maxLong = -180,
          bounds;

        _.each(points, function (point) {
          if (point.lat && point.lng) {
            minLat = Math.min(minLat, point.lat);
            minLong = Math.min(minLong, point.lng);
            maxLat = Math.max(maxLat, point.lat);
            maxLong = Math.max(maxLong, point.lng);
          }
        });

        bounds = L.latLngBounds([minLat, minLong], [maxLat, maxLong]);
        map.fitBounds(bounds, {padding: [1, 1]});
        map.panTo(bounds.getCenter());
        setTimeout(function () {
          map.panBy([1, 1]);
        }, 1);
        map.invalidateSize();
      }
    }

    function centerMapOnMarker(lat, lng) {
      var latLngs = [L.latLng(lat, lng)];
      var markerBounds = L.latLngBounds(latLngs);
      map.fitBounds(markerBounds, {
        maxZoom: maxPointZoom
      });
    }
    /*
    * OPTIONS:
    * serviceType: 'route' or 'trip'
    * points: Array
    * color: string;
    * shouldDrawMarkers: boolean
    * onTripCreated?: function
    */
    function addRoute(options) {
      var waypoints = [];
      _.each(options.points, function (point) {
        waypoints.push(L.latLng(point.lat, point.lng));
      });

      var control = getRoutingControl({
        serviceType: options.serviceType,
        waypoints: waypoints,
        color: options.color,
        shouldDrawMarkers: options.shouldDrawMarkers,
        fitSelectedRoutes: options.fitSelectedRoutes,
      });

      control.on('routesfound', function (tripResult) {
        control._plan._updateMarkers();
        if (options.onTripCreated) {
          options.onTripCreated(tripResult, control._plan._markers);
        }
      });
      control.addTo(map);

      return control;
    }

    function clearRouteMarkers() {
      while (routeMarkers.length) {
        var marker = routeMarkers.pop();
        map.removeLayer(marker);
      }
    }

    function onMapClick(handler) {
      map.on('click', handler);
    }

    function offMapClick(handler) {
      map.off('click', handler);
    }

    function parseSerializedRoute(serializedRoute) {
      var route = JSON.parse(serializedRoute);
      var coordinates = [];

      _.each(route.coordinates, function (coordinate) {
        coordinates.push(parseLatLng(coordinate));
      });
      route.coordinates = coordinates;

      _.each(route.inputWaypoints, function (waypoint) {
        waypoint.latLng = parseLatLng(waypoint.latLng);
      });

      _.each(route.waypoints, function (waypoint) {
        waypoint.latLng = parseLatLng(waypoint.latLng);
      });

      return route;
    }

    function parseLatLng(obj) {
      return L.latLng(obj.lat, obj.lng)
    }

    /*
    * OPTIONS
    * serviceType: 'route' or 'trip'
    * waypoints: Array
    * color: string
    * shouldDrawMarkers: bool
    */
    function getRoutingControl(options) {
      var router = null;

      switch (options.serviceType) {
        case 'route':
          router = L.Routing.osrmv1({
            serviceUrl: '/route/v1',
            useHints: false
          });
          break;
        case 'trip':
          router = L.Routing.osrmTrip({
            serviceUrl: '/trip/v1',
            useHints: false,
            radiuses: 10000,
            approaches: 'curb',
            requestParameters: {
              source: 'first',
              destination: 'last'
            }
          });
          break;
      }

      return L.Routing.control({
        waypoints: options.waypoints,
        fitSelectedRoutes: options.fitSelectedRoutes,
        lineOptions: {
          styles: [{color: options.color, opacity: 1, weight: 4}],
          addWaypoints: false
        },
        createMarker: function (index, wayPoint, totalLength) {
          if (options.shouldDrawMarkers) {
            var icon;

            switch (index) {
              case 0: // first point in route
                icon = getColoredIcon(options.color, 'S');
                break;
              case totalLength - 1: // last point in route
                icon = getColoredIcon(options.color, 'F');
                break;
              default:
                icon = getColoredIcon(options.color, index);
                break;
            }

            return L.marker(wayPoint.latLng, {icon: icon});
          }
          return null;
        },
        router: router
      });
    }

    function removeControl(control) {
      control.remove();
    }

    function getColoredIcon(color, text) {
      color = color || '#BDBDBD';
      text = text || '';

      return L.divIcon({
        iconAnchor: [0, 24],
        labelAnchor: [-6, 0],
        popupAnchor: [0, -36],
        html: '<span class="colored-icon" style="background-color: ' + color + '"><span class="colored-icon-text">' + text + '</span></span>'
      });
    }

    function addMarkerToMap(marker) {
      marker.addTo(map);
    }

    function removeMarkerFromMap(marker) {
      map.removeLayer(marker);
    }

    function getPolygonByLatLng(lat, lng) {
      var coordinates = [];
      var latSize = 0.0004;
      var lngSize = 0.0006;

      coordinates.push([
        lat + latSize * Math.cos(0),
        lng + lngSize * Math.sin(0)
      ]);


      for (var side = 0; side < 7; side++) {
        coordinates.push([
          lat + latSize * Math.cos(side * 2 * Math.PI / 6),
          lng + lngSize * Math.sin(side * 2 * Math.PI / 6)
        ]);
      }

      return L.polygon(coordinates);
    }

    function getPolygonCoordinates(polygon) {
      return polygon.toGeoJSON().geometry.coordinates[0];
    }
    function getWkt(polygonCoordinates) {
      return 'POLYGON((' + _.map(polygonCoordinates, function (el) {
        return el.join(' ');
      }).join(",") + '))';
    }

    return {
      areMapControlsVisible: () => displayControls,
      clear: clear,
      clearZones: clearZones,
      hideZones: hideZones,
      showZones: showZones,
      setOnDrawCreatedHandler: setOnDrawCreatedHandler,
      init: init,
      refresh: refresh,
      drawTrack: drawTrack,
      drawMarkersSet: drawMarkersSet,
      appendMarker: appendMarker,
      drawZone: drawZone,
      goToPlace: goToPlace,
      showStop: showStop,
      startGeoEdit: startGeoEdit,
      stopGeoEdit: stopGeoEdit,
      cancelCurrentZone: cancelCurrentZone,
      startEditZone: startEditZone,
      zoneSaved: zoneSaved,
      zoneDeleted: zoneDeleted,
      zoneColorChanged: zoneColorChanged,
      initMapSize: initMapSize,
      drawLogisticRouteMarkers: drawLogisticRouteMarkers,
      centerMapOnMarker: centerMapOnMarker,
      addRoute: addRoute,
      clearRouteMarkers: clearRouteMarkers,
      onMapClick: onMapClick,
      offMapClick: offMapClick,
      showEditInterface: showEditInterface,
      hideEditInterface: hideEditInterface,
      parseSerializedRoute: parseSerializedRoute,
      removeControl: removeControl,
      getColoredIcon: getColoredIcon,
      addMarkerToMap: addMarkerToMap,
      removeMarkerFromMap: removeMarkerFromMap,
      centerMapByPoints: centerMapByPoints,
      getPolygonByLatLng: getPolygonByLatLng,
      getPolygonCoordinates: getPolygonCoordinates,
      drawPolilines: drawPolilines,
      drawPolilinesGroup: drawPolilinesGroup,
      clearPolilines: clearPolilines,
      clearPolilinesGroup: clearPolilinesGroup,
      clearStartFinishMarkers: clearStartFinishMarkers,
      getWkt: getWkt,
      container: "osm-map-container",
      onEnteredIntoNowMode,
      onExitedFromNowMode,
      setView() {
        map.setView([50, 30.2], 8);

        setTimeout(function () {
          map.invalidateSize();
        });
      },
      boundToPoint: (lat, lng) => {
        setTimeout(() => {
          map.setZoom(15);
          map.panTo([lat, lng]);
          map.invalidateSize();
        }, 500)
      },
      drawCurrentPositionOfVehicleForAccessByToken,
      drawForwarderRouteForAccessByToken,
      updateForwarderRouteMarkersForAccessByToken
    };

    function onEnteredIntoNowMode() {
      isNowModeActive = true;
    }

    function onExitedFromNowMode() {
      isNowModeActive = false;
    }
  }


})();
