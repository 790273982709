(() => {
  angular
    .module('app.vehicles')
    .component('notificationSettings', {
      controller: Ctrl,
      controllerAs: 'ctrl',
      templateUrl: 'app/vehicles/details/settings/notification-settings/tpl.html',
      bindings: {
        userId: '<',
        vehicleId: '<'
      }
    });

  Ctrl.$inject = [
    'notificationSettingsApi',
    'VehicleSettingsToast'
  ];

  function Ctrl(api, toast) {
    this.isSwitchNotificationsModeRequestActive = false;
    this.areNotificationsEnabled = false;
    let previousAreNotificationsEnabledValue = this.areNotificationsEnabled;

    this.$onInit = () => {
      this.fetchAllSettings();
    };

    this.areAllSettingsLoading = false;

    this.fetchAllSettings = () => {
      this.areAllSettingsLoading = true;
      api.fetchSettings(this.vehicleId)
        .then((result) => {
          this.areNotificationsEnabled = result.isEnabled;
          previousAreNotificationsEnabledValue = this.areNotificationsEnabled;

          this.shouldNotifyAboutTransitsThroughGeozones = result.shouldNotifyAboutTransitsThroughGeozones;
        })
        .catch((e) => {
          console.error('Failed fetch notification settings', e);
          toast.successfulSaving('car.settings.failedFetchingNotificationSettingsMsg');
        })
        .finally(() => {
          this.areAllSettingsLoading = false;
        });
    };

    this.onAreNotificationsEnabledChanged = () => {
      this.isSwitchNotificationsModeRequestActive = true;
      api.saveSettings(this.vehicleId, this.areNotificationsEnabled)
        .then(() => {
          previousAreNotificationsEnabledValue = this.areNotificationsEnabled;

          if (!this.areNotificationsEnabled) {
            this.shouldNotifyAboutTransitsThroughGeozones = false;
          }

          const i18nMessageKey = this.areNotificationsEnabled ?
            'car.settings.notificationsEnabledMsg' :
            'car.settings.notificationsDisabledMsg';
          toast.successfulSaving(i18nMessageKey);
        })
        .catch((e) => {
          console.error('Failed saving notification settings', e);
          this.areNotificationsEnabled = previousAreNotificationsEnabledValue;
          toast.successfulSaving('car.settings.failedSavingNotificationSettingsMsg');
        })
        .finally(() => {
          this.isSwitchNotificationsModeRequestActive = false;
        });
    };

    this.shouldNotifyAboutTransitsThroughGeozones = false;
    let previousShouldNotifyAboutTransitsThroughGeozonesValue = this.shouldNotifyAboutTransitsThroughGeozones;

    this.isToggleNotificationsAboutTransitsThroughGeozonesRequestActive = false;

    this.onShouldNotifyAboutTransitsThroughGeozonesChanged = () => {
      this.isToggleNotificationsAboutTransitsThroughGeozonesRequestActive = true

      api.saveNotificationAboutTransitsThroughGeozonesSettings(this.vehicleId,
          this.shouldNotifyAboutTransitsThroughGeozones)
        .then(() => {
          previousShouldNotifyAboutTransitsThroughGeozonesValue = this.shouldNotifyAboutTransitsThroughGeozones;

          const i18nMessageKey = this.shouldNotifyAboutTransitsThroughGeozones ?
            'car.settings.notificationAboutTransitsThroughGeozonesSettingsEnabledMsg' :
            'car.settings.notificationAboutTransitsThroughGeozonesSettingsDisabledMsg';
          toast.successfulSaving(i18nMessageKey);
        })
        .catch((e) => {
          console.error('Failed saving geozones passage notification settings', e);
          this.shouldNotifyAboutTransitsThroughGeozones = previousShouldNotifyAboutTransitsThroughGeozonesValue;
          toast.successfulSaving('car.settings.failedSavingNotificationAboutTransitsThroughGeozonesSettingsMsg');
        })
        .finally(() => {
          this.isToggleNotificationsAboutTransitsThroughGeozonesRequestActive = false
        })
    };
  }
})();
